function getStatus(params) {
  let value = params.row.product.productStatus;
  if (value === null) {
    return;
  } else {
    return params.row.product.productStatus.title;
  }
}

export default getStatus;
