import React, { useState } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import CloseIcon from "@mui/icons-material/Close";
import Slide from "@mui/material/Slide";
import { Box, Container, useTheme } from "@mui/material";
import { useTranslation } from "react-i18next";
import MultiProductIcon from "../icons/MultiProductIcon";
import StockForm from "../forms/StockForm";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const WareHouseDialog = () => {
  const theme = useTheme();
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);

  const handleClose = () => {
    setOpen(false);
  };
  return (
    <Box>
      {/* <Button
        onClick={handleClickOpen}
        startIcon={<NewProductIcon />}
        color="text"
        sx={{
          color: `${theme.palette.primary.fontColor}!important`,
          pl: 2,
          textTransform: "capitalize",
          border: "none",
          borderRadius: 0,
        }}
      >
        {t("addNewProduct")}
      </Button> */}
      <Dialog
        fullScreen
        open={open}
        onClose={handleClose}
        TransitionComponent={Transition}
        className="fs-dialog-appbar"
      >
        <AppBar sx={{ position: "relative", background: "#fff", mt: 0 }}>
          <Toolbar>
            <Typography
              sx={{
                ml: 2,
                flex: 1,
                color: `${theme.palette.primary.fontColor}!important`,
              }}
              variant="text"
              component="text"
            >
              <span
                style={{
                  textDecoration: "underline",
                  color: "rgba(85, 56, 48, 0.9)",
                }}
              >
                {t("products")}
              </span>
              <span style={{ fontWeight: "500", fontSize: "18px" }}>
                {" "}
                {">"}{" "}
              </span>
              <span style={{ fontWeight: "500" }}>Yeni Ürün Ekle</span>
            </Typography>
            <Button
              startIcon={<MultiProductIcon />}
              sx={{ textTransform: "capitalize", mr: 2 }}
              autoFocus
              color="text"
              onClick={handleClose}
            >
              {t("addMultiProduct")}
            </Button>
            <IconButton
              edge="start"
              color="text"
              onClick={handleClose}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
          </Toolbar>
        </AppBar>
        <Container maxWidth="sm">
          <StockForm />
        </Container>
      </Dialog>
    </Box>
  );
};

export default WareHouseDialog;
