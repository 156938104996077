import React from "react";

const UploadIcon = () => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_1373_2099)">
        <path
          d="M14.2004 19C16.8514 19 19.0004 16.851 19.0004 14.2C19.0004 11.5491 16.8514 9.40002 14.2004 9.40002C11.5494 9.40002 9.40039 11.5491 9.40039 14.2C9.40039 16.851 11.5494 19 14.2004 19Z"
          stroke="#453834"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M14.2002 11.8V16.6"
          stroke="#453834"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M11.7998 14.2H16.5998"
          stroke="#453834"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M7 15.4H2.2C1.88174 15.4 1.57652 15.2735 1.35147 15.0485C1.12643 14.8235 1 14.5182 1 14.2V5.79998C1 5.48172 1.12643 5.17649 1.35147 4.95145C1.57652 4.7264 1.88174 4.59998 2.2 4.59998H14.2C14.5183 4.59998 14.8235 4.7264 15.0485 4.95145C15.2736 5.17649 15.4 5.48172 15.4 5.79998V6.99998"
          stroke="#453834"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M15.0123 4.9176L12.7555 1.5344C12.6462 1.37036 12.4981 1.23579 12.3244 1.14259C12.1507 1.04938 11.9567 1.00041 11.7595 1H4.63952C4.44197 1.00001 4.24748 1.0488 4.07332 1.14202C3.89915 1.23525 3.75069 1.37003 3.64112 1.5344L1.38672 4.9176"
          stroke="#453834"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M8.2002 1V4.6"
          stroke="#453834"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_1373_2099">
          <rect width="20" height="20" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default UploadIcon;
