/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useContext } from "react";
import Cookies from "js-cookie";
import Topbar from "./global/Topbar";
import { useTranslation } from "react-i18next";
import { Box, useTheme } from "@mui/material";
import TableList from "../components/partials/TableList";
import axios from "axios";
import FbmmFilter from "../components/partials/FbmmFilter";
import { FbmmFilterContext } from "../context/FbmmFilterContext";
import FbmmToggler from "../components/partials/FbmmToggler";
import togglerStatusConfig from "../status/fbmmTogglerStatus";

import getorderInfo from "../getters/Fbmm/getOrderInfo";
import getWarehouse from "../getters/Fbmm/getWareHouse";
import getPlatform from "../getters/Fbmm/getPlatform";
import getProgress from "../getters/Fbmm/getProgress";

import { GridActionsCellItem } from "@mui/x-data-grid";
import { useNavigate } from "react-router-dom";
import FileCopyIcon from "@mui/icons-material/FileCopy";
import getOrderAdresses from "../getters/Fbmm/getOrderAdresses";
import getProductCode from "../getters/Fbmm/getProductCode";
import getCustomer from "../getters/Fbmm/getCustomer";

const Fbmm = () => {
  const navigate = useNavigate();
  const theme = useTheme();
  const { fbmmFilters } = useContext(FbmmFilterContext);
  const { t } = useTranslation();
  const [selections, setSelections] = useState([]);
  const [pageState, setPageState] = useState({
    isLoading: false,
    data: [],
    total: 0,
    page: 0,
    pageSize: 10,
  });
  const [error, setError] = useState(null);
  const [togglerStatus, setTogglerStatus] = useState([]);

  // TODO: Refactor edilecek
  // function getCountry(params) {}
  // function getShipmentType(params) {}

  const handleSelections = selectionModel => {
    setSelections(selectionModel);
    console.log(selections);
  };

  const handleClearSelections = () => {
    setSelections([]);
  };

  const fbmmStatusHandler = id => {
    return () => {
      navigate(`/fbmm-status/${id}`);
    };
  };

  const fbmmCargoHandler = id => {
    return () => {
      navigate(`/fbmm-cargo/${id}`);
    };
  };

  const fbmmColumns = [
    {
      field: "orderInfo",
      headerName: t("orderInformation"),
      renderCell: getorderInfo,
      flex: 2.6,
    },
    // {
    //   field: "products",
    //   headerName: "Ürünler",
    //   flex: 3,
    //   renderCell: getProducts,
    // },
    // {
    //   field: "barcode",
    //   headerName: "Barkod",
    //   flex: 2,
    //   renderCell: getBarcode,
    // },
    {
      field: "productCode",
      headerName: t("productCode"),
      flex: 1.5,
      renderCell: getProductCode,
    },
    {
      field: "customer",
      headerName: t("customer"),
      flex: 1.45,
      renderCell: getCustomer,
    },
    {
      field: "country",
      headerName: t("country"),
      flex: 1.25,
      renderCell: getOrderAdresses,
    },
    {
      field: "warehouse",
      headerName: t("warehouse"),
      renderCell: getWarehouse,
      flex: 1.25,
    },
    // {
    //   field: "shipmentType",
    //   headerName: "Gönderi Yöntetmi",
    //   flex: 0.5,
    // },
    {
      field: "platform",
      headerName: t("platform"),
      valueGetter: getPlatform,
    },
    {
      field: "progress",
      headerName: t("progress"),
      flex: 2,
      renderCell: getProgress,
    },
    {
      field: "actions",
      type: "actions",
      flex: 0.1,
      // headerName: t("actions"),
      getActions: params => [
        <GridActionsCellItem
          icon={<FileCopyIcon />}
          label={t("updateStatus")}
          onClick={fbmmStatusHandler(params.row.id)}
          showInMenu
        />,
        <GridActionsCellItem
          icon={<FileCopyIcon />}
          label={t("cargoInformation")}
          onClick={fbmmCargoHandler(params.row.id)}
          showInMenu
        />,
      ],
    },
  ];

  let data = "";
  let config = {
    method: "get",
    maxBodyLength: Infinity,
    url: `${process.env.REACT_APP_BASE_URL}/Order?Pagination.Page=${pageState.page}&Pagination.Size=${pageState.pageSize}`,
    headers: {
      Authorization: `Bearer ${Cookies.get("_auth")}`,
    },
    data: data,
  };

  const searchPlaceholder = t("product_search");

  //TODO: Refactor edilecek
  useEffect(() => {
    const fetchData = async () => {
      const response = await axios.request(togglerStatusConfig);
      const responseData = await response.data.items;
      setTogglerStatus(responseData);
    };
    fetchData();
  }, []);

  useEffect(() => {
    if (fbmmFilters) {
      if (fbmmFilters.status) {
        config.url = config.url + "&Status=" + fbmmFilters.status;
      }
      if (fbmmFilters.sku) {
        config.url = config.url + "&Query=" + fbmmFilters.sku;
      }
      if (fbmmFilters.productCode) {
        config.url = config.url + "&ProductCode=" + fbmmFilters.productCode;
      }
      if (fbmmFilters.orderNumber) {
        config.url = config.url + "&Query=" + fbmmFilters.orderNumber;
      }
      if (fbmmFilters.barcode) {
        config.url = config.url + "&Query=" + fbmmFilters.barcode;
      }
      if (fbmmFilters.product) {
        config.url = config.url + "&Query=" + fbmmFilters.product;
      }
      if (fbmmFilters.warehouse) {
        config.url = config.url + "&Warehouse=" + fbmmFilters.warehouse;
      }
      if (fbmmFilters.source) {
        config.url = config.url + "&Source=" + fbmmFilters.source;
      }
      if (fbmmFilters.search) {
        config.url = config.url + "&Query=" + fbmmFilters.search;
      }
      if (fbmmFilters.startDate) {
        config.url = config.url + "&StartDate=" + fbmmFilters.startDate;
      }
      if (fbmmFilters.endDate) {
        config.url = config.url + "&EndDate=" + fbmmFilters.endDate;
      }
    }

    const fetchData = async () => {
      setPageState(old => ({ ...old, isLoading: true }));
      const response = await axios.request(config);
      const responseData = await response.data.items;
      const totalCount = await response.data.totalCount;
      setPageState(old => ({
        ...old,
        isLoading: false,
        data: responseData,
        total: totalCount,
      }));
    };

    fetchData();
  }, [pageState.page, pageState.pageSize, fbmmFilters]);

  if (error) {
    setError(error);
    return <div>Error: {error.message}</div>;
  } else {
    return (
      <Box m={"20px"}>
        <Topbar title={t("orders")}></Topbar>
        <Box sx={{ background: theme.palette.primary.white }} p={5}>
          <FbmmToggler
            selectedProducts={selections}
            options={togglerStatus}
            placeholder={searchPlaceholder}
          />
          <FbmmFilter />
          <Box mt={2} sx={{ height: "100%" }}>
            <TableList
              key={pageState.data.id}
              rows={pageState.data}
              columns={fbmmColumns}
              pageState={pageState}
              selection={true}
              setPageState={setPageState}
              rowHeight={180}
              onSelectionModelChange={handleSelections}
            />
          </Box>
        </Box>
      </Box>
    );
  }
};

export default Fbmm;
