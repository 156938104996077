import React from "react";
import { Box } from "@mui/system";
import Topbar from "../pages/global/Topbar";
import { useTheme } from "@emotion/react";
import { useTranslation } from "react-i18next";


const Reports = () => {
  const theme = useTheme();
  const { t } = useTranslation();
  return (
    <Box m={"20px"}>
      <Topbar title={t("reports")} />

      <Box sx={{ background: theme.palette.primary.white }} p={5}>
        <Box mt={2} sx={{ height: "100%" }}>
          <iframe
            width="100%"
            height="1200px"
            src="https://lookerstudio.google.com/embed/reporting/82db5196-a294-4cd7-945c-136f290e3cb9/page/U4dRD"
            frameborder="0"
            style={{ border: "none" }}
            allowfullscreen
            sandbox="allow-storage-access-by-user-activation allow-scripts allow-same-origin allow-popups allow-popups-to-escape-sandbox"
          ></iframe>
        </Box>
      </Box>
    </Box>
  );
};

export default Reports;
