import React from "react";

const WriteShipmentLabelIcon = () => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_2723_2639)">
        <path
          d="M7.72901 15.651C7.49061 15.8745 7.16731 16 6.83022 16C6.49312 16 6.16982 15.8745 5.93142 15.651L1.37264 11.3778C1.2545 11.2672 1.16079 11.1357 1.09685 10.9911C1.03291 10.8464 1 10.6914 1 10.5348C1 10.3783 1.03291 10.2232 1.09685 10.0786C1.16079 9.93387 1.2545 9.80245 1.37264 9.69176L10.2716 1.34881C10.3896 1.23816 10.5299 1.1504 10.6841 1.09055C10.8385 1.0307 11.0038 0.999929 11.1708 1H15.7287C16.0659 1 16.3892 1.12557 16.6276 1.34908C16.866 1.5726 17 1.87575 17 2.19185V6.46583C16.9999 6.78171 16.8661 7.08464 16.6279 7.30807"
          stroke="#453834"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M13 4C13.5523 4 14 4 14 4C14 4 13.5523 4 13 4"
          stroke="#453834"
          strokeWidth="1.5"
        />
        <path
          d="M14 19C16.7614 19 19 16.7614 19 14C19 11.2386 16.7614 9 14 9C11.2386 9 9 11.2386 9 14C9 16.7614 11.2386 19 14 19Z"
          stroke="#453834"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M14 12V16"
          stroke="#453834"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M12 14L14 16L16 14"
          stroke="#453834"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_2723_2639">
          <rect width="20" height="20" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default WriteShipmentLabelIcon;
