import React from "react";
import { Typography, Box } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { useTranslation } from "react-i18next";

const SectionTitle = ({ title }) => {
  return (
    <Typography
      sx={{
        fontSize: "18px",
        fontWeight: "500",
        color: "#453834",
        mb: 1,
      }}
    >
      {title}
    </Typography>
  );
};

const PaymentsTable = ({ data, total }) => {
  console.log(data);
  const { t } = useTranslation();
  return (
    <Box>
      <SectionTitle title={t("paymentHistory")} />
      {/* Data Table */}
      <DataGrid
        sx={{
          borderRadius: "2px",
        }}
        rows={data}
        columns={[
          {
            field: "createdAt",
            headerName: t("paymentDate"),
            flex: 2,
            valueGetter: params =>
              new Date(params.row.createdAt).toLocaleDateString("tr-TR"),
          },
          {
            field: "description",
            headerName: t("productName"),
            flex: 2,
            valueGetter: params => params.row.description,
          },
          {
            field: "yearly",
            headerName: "Periyod",
            flex: 2,
            valueGetter: params => (params.row.yearly ? "Yıllık" : "Aylık"),
          },
          {
            field: "amount",
            headerName: t("amount"),
            flex: 2,
            valueGetter: params => `$ ${params.row.amount}`,
          },
        ]}
        autoHeight
        pageSize={5}
        rowsPerPageOptions={[5]}
        disableSelectionOnClick
      />
    </Box>
  );
};

export default PaymentsTable;
