import React, { useEffect } from "react";
import Button from "@mui/material/Button";
import Cookies from "js-cookie";
import axios from "axios";
import Xls from "../icons/Xls";
import { Box, CircularProgress } from "@mui/material";
import { MerchantFilterContext } from "../../context/MerchantFilterContext";
import { useTranslation } from "react-i18next";
function CircularIndeterminate() {
  return (
    <Box sx={{ display: "flex" }}>
      <CircularProgress
        sx={{
          color: "#282828",
          width: "14px",
          height: "14px",
          marginRight: "5px",
        }}
        color="inherit"
        size={20}
        thickness={5}
      />
    </Box>
  );
}
// :()
const DownloadMerchantExcel = () => {
  const [isDownloading, setIsDownloading] = React.useState(false);
  const { merchantFilters } = React.useContext(MerchantFilterContext);
  const { t } = useTranslation();
  let config = {
    method: "get",
    url: `${process.env.REACT_APP_BASE_URL}/Merchant/ExporttoExcelAllMerchants?Pagination.Page=0&Pagination.Size=10`,
    responseType: "blob",
    headers: {
      Authorization: `Bearer ${Cookies.get("_auth")}`,
      "Content-Type":
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    },
  };
  const handleDownloadClick = async () => {
    if (merchantFilters) {
      if (merchantFilters.search) {
        config.url = config.url + "&Query=" + merchantFilters.search;
      }
      if (merchantFilters.status) {
        config.url = config.url + "&Status=" + merchantFilters.status;
      }
      if (merchantFilters.MMUserId) {
        config.url = config.url + "&MMUserId=" + merchantFilters.MMUserId;
      }
      if (merchantFilters.isSubmerchant) {
        config.url =
          config.url + "&IsSubmerchant=" + merchantFilters.isSubmerchant;
      } else {
        config.url = config.url + "&IsSubmerchant=false";
      }
    }
    setIsDownloading(true);
    try {
      const response = await axios(config);
      if (response.status === 200) {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", `${t("records")}.xlsx`); // İndirilen dosyanın adı
        document.body.appendChild(link);
        link.click();
      } else {
        console.error("Excel dosyası indirilemedi.");
      }
    } catch (error) {
      console.error("İstek sırasında bir hata oluştu: ", error);
    }
    setIsDownloading(false);
  };

  useEffect(() => {
    if (merchantFilters) {
      if (merchantFilters.search) {
        config.url = config.url + "&Query=" + merchantFilters.search;
      }
      if (merchantFilters.status) {
        config.url = config.url + "&Status=" + merchantFilters.status;
      }
      if (merchantFilters.MMUserId) {
        config.url = config.url + "&MMUserId=" + merchantFilters.MMUserId;
      }
      if (merchantFilters.isSubmerchant) {
        config.url =
          config.url + "&IsSubmerchant=" + merchantFilters.isSubmerchant;
      } else {
        config.url = config.url + "&IsSubmerchant=false";
      }
    }
  }, [merchantFilters]);

  return (
    <div>
      <Button
        variant="text"
        onClick={handleDownloadClick}
        disabled={isDownloading}
        sx={{
          color: "#282828",
          fontSize: "14px",
          fontWeight: 500,
          display: "flex",
          alignItems: "center",
          textTransform: "capitalize",
          "&:hover": {
            backgroundColor: "transparent",
            color: "#282828",
          },
        }}
      >
        {isDownloading ? t("downloading") : t("registrationForms")}
      </Button>
    </div>
  );
};

export default DownloadMerchantExcel;
