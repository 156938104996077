import React from "react";

const ReportIcons = () => {
  return (
    <svg
      width="36"
      height="36"
      viewBox="0 0 36 36"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="36" height="36" rx="5" fill="#FAF8F8" />
      <path
        d="M22.5 9C22.5 8.60218 22.658 8.22064 22.9393 7.93934C23.2206 7.65804 23.6022 7.5 24 7.5H27C27.3978 7.5 27.7794 7.65804 28.0607 7.93934C28.342 8.22064 28.5 8.60218 28.5 9V27H29.25C29.4489 27 29.6397 27.079 29.7803 27.2197C29.921 27.3603 30 27.5511 30 27.75C30 27.9489 29.921 28.1397 29.7803 28.2803C29.6397 28.421 29.4489 28.5 29.25 28.5H6.75C6.55109 28.5 6.36032 28.421 6.21967 28.2803C6.07902 28.1397 6 27.9489 6 27.75C6 27.5511 6.07902 27.3603 6.21967 27.2197C6.36032 27.079 6.55109 27 6.75 27H7.5V22.5C7.5 22.1022 7.65804 21.7206 7.93934 21.4393C8.22064 21.158 8.60218 21 9 21H12C12.3978 21 12.7794 21.158 13.0607 21.4393C13.342 21.7206 13.5 22.1022 13.5 22.5V27H15V16.5C15 16.1022 15.158 15.7206 15.4393 15.4393C15.7206 15.158 16.1022 15 16.5 15H19.5C19.8978 15 20.2794 15.158 20.5607 15.4393C20.842 15.7206 21 16.1022 21 16.5V27H22.5V9ZM24 27H27V9H24V27ZM19.5 27V16.5H16.5V27H19.5ZM12 27V22.5H9V27H12Z"
        fill="#453834"
      />
    </svg>
  );
};

export default ReportIcons;
