import React from "react";

const PromotionCodeIconActive = () => {
  return (
    <svg
      width="36"
      height="36"
      viewBox="0 0 36 36"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="36" height="36" rx="5" fill="#FEF7F5" />
      <g clipPath="url(#clip0_3315_3195)">
        <path
          d="M27.875 17.875C27.9745 17.875 28.0698 17.8355 28.1402 17.7652C28.2105 17.6948 28.25 17.5994 28.25 17.5V15.25C28.2497 15.1213 28.2272 14.9945 28.1843 14.875H20.75H14H10.0625H5.81434C5.77215 14.9943 5.75 15.121 5.75 15.25V17.5C5.75 17.5994 5.78951 17.6948 5.85983 17.7652C5.93016 17.8355 6.02554 17.875 6.125 17.875C6.77147 17.875 7.39145 18.1318 7.84857 18.5889C8.30569 19.046 8.5625 19.666 8.5625 20.3125C8.5625 20.959 8.30569 21.5789 7.84857 22.0361C7.39145 22.4932 6.77147 22.75 6.125 22.75C6.02554 22.75 5.93016 22.7895 5.85983 22.8598C5.78951 22.9301 5.75 23.0255 5.75 23.125V25C5.75 25.3978 5.90804 25.7793 6.18934 26.0606C6.47064 26.342 6.85218 26.5 7.25 26.5H26.75C27.1478 26.5 27.5294 26.342 27.8107 26.0606C28.092 25.7793 28.25 25.3978 28.25 25V23.125C28.25 23.0255 28.2105 22.9301 28.1402 22.8598C28.0698 22.7895 27.9745 22.75 27.875 22.75C27.2285 22.75 26.6085 22.4932 26.1514 22.0361C25.6943 21.5789 25.4375 20.959 25.4375 20.3125C25.4375 19.666 25.6943 19.046 26.1514 18.5889C26.6085 18.1318 27.2285 17.875 27.875 17.875Z"
          fill="#EB603A"
          fillOpacity="0.3"
        />
        <path
          d="M28.25 15.25V17.5C28.25 17.5994 28.2105 17.6948 28.1402 17.7652C28.0698 17.8355 27.9745 17.875 27.875 17.875C27.2285 17.875 26.6085 18.1318 26.1514 18.5889C25.6943 19.046 25.4375 19.666 25.4375 20.3125C25.4375 20.959 25.6943 21.5789 26.1514 22.0361C26.6085 22.4932 27.2285 22.75 27.875 22.75C27.9745 22.75 28.0698 22.7895 28.1402 22.8598C28.2105 22.9301 28.25 23.0255 28.25 23.125V25C28.25 25.3978 28.092 25.7793 27.8107 26.0606C27.5294 26.342 27.1478 26.5 26.75 26.5H7.25C6.85218 26.5 6.47064 26.342 6.18934 26.0606C5.90804 25.7793 5.75 25.3978 5.75 25V23.125C5.75 23.0255 5.78951 22.9301 5.85983 22.8598C5.93016 22.7895 6.02554 22.75 6.125 22.75C6.77147 22.75 7.39145 22.4932 7.84857 22.0361C8.30569 21.5789 8.5625 20.959 8.5625 20.3125C8.5625 19.666 8.30569 19.046 7.84857 18.5889C7.39145 18.1318 6.77147 17.875 6.125 17.875C6.02554 17.875 5.93016 17.8355 5.85983 17.7652C5.78951 17.6948 5.75 17.5994 5.75 17.5V15.25M28.25 15.25C28.2495 15.006 28.169 14.7689 28.0209 14.575C28.1603 14.3637 28.2382 14.1178 28.2456 13.8648C28.2531 13.6117 28.19 13.3616 28.0632 13.1425L27.23 11.7014C27.2054 11.6587 27.1726 11.6213 27.1336 11.5913C27.0945 11.5612 27.05 11.5392 27.0024 11.5264C26.9548 11.5137 26.9052 11.5104 26.8564 11.5168C26.8075 11.5232 26.7604 11.5391 26.7178 11.5637C26.2297 11.8455 25.6497 11.9219 25.1054 11.7761C24.561 11.6303 24.0969 11.2742 23.8151 10.7862C23.5333 10.2981 23.4569 9.71815 23.6027 9.17379C23.7485 8.62943 24.1046 8.1653 24.5926 7.88349C24.6783 7.83334 24.7408 7.75144 24.7664 7.65555C24.7921 7.55966 24.779 7.4575 24.7299 7.37124L23.7297 5.63949C23.5908 5.4012 23.3633 5.22742 23.0969 5.15594C22.8305 5.08445 22.5466 5.12103 22.307 5.25774L6.95 14.125H6.875C6.57663 14.125 6.29048 14.2435 6.0795 14.4545C5.86853 14.6655 5.75 14.9516 5.75 15.25M28.25 15.25C28.2497 15.1213 28.2272 14.9945 28.1843 14.875H20.75H14H10.0625H5.81434C5.77215 14.9943 5.75 15.121 5.75 15.25"
          stroke="#EB603A"
          stroke-width="1.5"
        />
        <path
          d="M13.5 17C13.3674 17 13.2402 17.0301 13.1464 17.0837C13.0527 17.1373 13 17.2099 13 17.2857V18.7143C13 18.7901 13.0527 18.8627 13.1464 18.9163C13.2402 18.9699 13.3674 19 13.5 19C13.6326 19 13.7598 18.9699 13.8536 18.9163C13.9473 18.8627 14 18.7901 14 18.7143V17.2857C14 17.2099 13.9473 17.1373 13.8536 17.0837C13.7598 17.0301 13.6326 17 13.5 17Z"
          fill="#EB603A"
        />
        <path
          d="M13.5 20C13.3674 20 13.2402 20.0301 13.1464 20.0837C13.0527 20.1373 13 20.2099 13 20.2857V21.7143C13 21.7901 13.0527 21.8627 13.1464 21.9163C13.2402 21.9699 13.3674 22 13.5 22C13.6326 22 13.7598 21.9699 13.8536 21.9163C13.9473 21.8627 14 21.7901 14 21.7143V20.2857C14 20.2099 13.9473 20.1373 13.8536 20.0837C13.7598 20.0301 13.6326 20 13.5 20Z"
          fill="#EB603A"
        />
        <path
          d="M13.5 23C13.3674 23 13.2402 23.0301 13.1464 23.0837C13.0527 23.1373 13 23.2099 13 23.2857V24.7143C13 24.7901 13.0527 24.8627 13.1464 24.9163C13.2402 24.9699 13.3674 25 13.5 25C13.6326 25 13.7598 24.9699 13.8536 24.9163C13.9473 24.8627 14 24.7901 14 24.7143V23.2857C14 23.2099 13.9473 23.1373 13.8536 23.0837C13.7598 23.0301 13.6326 23 13.5 23Z"
          fill="#EB603A"
        />
      </g>
      <defs>
        <clipPath id="clip0_3315_3195">
          <rect
            width="24"
            height="24"
            fill="white"
            transform="translate(5 4)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export default PromotionCodeIconActive;
