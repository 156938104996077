import { Typography } from "@mui/material";
import React from "react";

function getOrderAdresses(params) {
  return (
    <div>
      {params.row.orderAddresses.map((order, index) => (
        <div key={index}>
          <Typography>
            {order.addressType === 1
              ? order.country !== undefined
                ? order.country.title
                : null
              : null}
          </Typography>
        </div>
      ))}
    </div>
  );
}

export default getOrderAdresses;
