import React, { useState, useEffect } from "react";
import Cookies from "js-cookie";
import Toggler from "../components/partials/Toggler";
import Topbar from "./global/Topbar";
import { useTranslation } from "react-i18next";
import { Box, Button, useTheme } from "@mui/material";
import TableList from "../components/partials/TableList";
import axios from "axios";
import NewProductIcon from "../components/icons/NewProductIcon";

const Categories = () => {
  const theme = useTheme();
  const { t } = useTranslation();
  const [pageState, setPageState] = useState({
    isLoading: false,
    data: [],
    total: 0,
    page: 0,
    pageSize: 10,
  });
  const [error, setError] = useState(null);

  const options = [
    { title: t("all"), value: 57 },
    { title: t("active"), value: 57 },
    { title: t("passive"), value: 0 },
  ];

  function getStatus(params) {
    let value = params.row.isActive;
    if (value === true) {
      return t("active");
    } else {
      return t("passive");
    }
  }

  const columns = [
    { field: "proparsId", headerName: t("proparsId"), flex: 1 },
    {
      field: "title",
      headerName: t("categoryName"),
      flex: 3,
      cellClassName: "name-column--cell",
    },
    {
      field: "isActive",
      headerName: t("status"),
      valueGetter: getStatus,
    },
  ];

  const searchPlaceholder = t("category_search");

  let data = "";
  let config = {
    method: "get",
    maxBodyLength: Infinity,
    url: `${process.env.REACT_APP_BASE_URL}/ProductCategory?Pagination.Page=${pageState.page}&Pagination.Size=${pageState.pageSize}`,
    headers: {
      Authorization: `Bearer ${Cookies.get("_auth")}`,
    },
    data: data,
  };

  useEffect(() => {
    const fetchData = async () => {
      setPageState(old => ({ ...old, isLoading: true }));
      const response = await axios.request(config);
      const responseData = await response.data.items;
      const totalCount = await response.data.totalCount;
      setPageState(old => ({
        ...old,
        isLoading: false,
        data: responseData,
        total: totalCount,
      }));
    };
    fetchData();
  }, [pageState.page, pageState.pageSize]);

  if (error) {
    setError(error);
    return <div>Error: {error.message}</div>;
  } else {
    return (
      <Box m={"20px"}>
        <Topbar title={t("categories")}>
          <Button
            startIcon={<NewProductIcon />}
            color="text"
            sx={{
              pl: 2,
              textTransform: "capitalize",
              border: "none",
              borderRadius: 0,
            }}
          >
            {t("addNewCategory")}
          </Button>
        </Topbar>
        <Box sx={{ background: theme.palette.primary.white }} p={5}>
          <Toggler options={options} placeholder={searchPlaceholder} />
          <Box mt={2} sx={{ height: "100%" }}>
            <TableList
              rows={pageState.data}
              columns={columns}
              pageState={pageState}
              setPageState={setPageState}
              rowHeight={50}
            />
          </Box>
        </Box>
      </Box>
    );
  }
};

export default Categories;
