import { createTheme } from "@mui/material";

const theme = createTheme({
  palette: {
    primary: {
      main: "#EB603A",
      white: "#fff",
      primaryRed: "#D7283E",
      secondaryRed: "#EB603A",
      primaryBlue: "#4E73CD",
      primaryGreen: "#34AE1A",
      fontColor: "#453834",
      pageBackground: "#F7F6F5",
      filtersBackground: "#F4F2F1",
      bordersColor: "rgba(85, 56, 48, 0.15)",
      shadowsColor: "rgba(85, 56, 48, 0.15);",
      tableEvenRows: "#FAF8F8",
      tableRowsHover: "#FDF4F5",
      buttonHover: "#DA5935",
      overlayColor: "rgba(0, 0, 0, 0.6);",
    },
    secondary: {
      main: "#4E73CD",
    },
    background: {
      main: "#F4F2F1",
    },
    text: {
      main: "#453834",
    },
    success: {
      main: "#34AE1A",
    },
    error: {
      main: "#D7283E",
    },
  },
  typography: {
    fontFamily: ["Roboto", "sans-serif"].join(","),
    fontSize: 14,
    h1: {
      fontFamily: ["Roboto", "sans-serif"].join(","),
      fontSize: 24,
      fontWeight: 500,
      color: "#EB603A",
    },
    h2: {
      fontFamily: ["Roboto", "sans-serif"].join(","),
      fontSize: 18,
      fontWeight: 700,
    },
    h3: {
      fontFamily: ["Roboto", "sans-serif"].join(","),
      fontSize: 14,
      fontWeight: 500,
    },
    h4: {
      fontFamily: ["Roboto", "sans-serif"].join(","),
      fontSize: 14,
      fontWeight: 700,
    },
  },
});

export default theme;
