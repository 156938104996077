import React, { useCallback, useState, useEffect, useContext } from "react";
import { loadStripe } from "@stripe/stripe-js";

import {
  EmbeddedCheckoutProvider,
  EmbeddedCheckout,
} from "@stripe/react-stripe-js";
import axios from "axios";
import { useLocation } from "react-router-dom";

const stripePromise = loadStripe(
  "pk_live_51P0QJA04TS9KAeqIjsXCGtuhNMMEoaP2gqG1FJMPW0g41wNAk795ugje78XnQz5RfsNebx7fKSDZOdOLPseToqRl00OW6FbjWx"
  //"pk_test_51PJuFoJF0DNaNSKfa9EwWRQCQ4w3TZNJUSLcnrUCyCZcTbB9xhHOROWRJ4mEpIhUI5f2QkhX9HfSv17vYwz9AJPl00ZYpjCawv"
);

const Payment = () => {
  const location = useLocation();

  const { packageData } = location.state || {};
  const { price, name, priceId, mode } = packageData;

  const fetchClientSecret = useCallback(async () => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_BASE_URL}/Stripe?UnitAmount=${
          price * 100
        }&Currency=usd&Name=${name}1&Quantity=1&PriceId=${priceId}&Mode=${mode}`,
        {
          UnitAmount: price * 100,
          Currency: "usd",
          Name: name,
          Quantity: 1,
          PriceId: priceId,
          Mode: mode,
        },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      return response.data.clientSecret;
    } catch (error) {
      console.error("Error fetching client secret:", error);
    } finally {
      console.log("Client secret fetched.");
    }
  }, []);

  const options = { fetchClientSecret };

  return (
    <div id="checkout">
      <EmbeddedCheckoutProvider stripe={stripePromise} options={options}>
        <EmbeddedCheckout />
      </EmbeddedCheckoutProvider>
    </div>
  );
};

export default Payment;
