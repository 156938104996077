import React from "react";

const PointIconForMenu = () => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_2697_4724)">
        <path
          d="M15.5 8C15.5 12.1341 12.1341 15.5 8 15.5C3.86476 15.5 0.5 12.1342 0.5 8C0.5 3.86581 3.86476 0.5 8 0.5C12.1341 0.5 15.5 3.86586 15.5 8Z"
          stroke="#453834"
        />
        <path
          d="M5.97038 8.82655C5.98924 8.84494 5.99784 8.87142 5.99339 8.89738L5.55556 11.4476C5.54434 11.513 5.61293 11.5628 5.67162 11.532L7.96278 10.3279C7.98608 10.3157 8.01392 10.3157 8.03722 10.3279L10.3284 11.532C10.3871 11.5628 10.4557 11.513 10.4444 11.4476L10.0066 8.89738C10.0022 8.87142 10.0108 8.84494 10.0296 8.82655L11.8839 7.01902C11.9314 6.97274 11.9052 6.8921 11.8396 6.88256L9.27805 6.51021C9.252 6.50643 9.22948 6.49007 9.21782 6.46646L8.07173 4.14528C8.04238 4.08584 7.95762 4.08584 7.92827 4.14528L6.78218 6.46646C6.77052 6.49007 6.748 6.50643 6.72195 6.51021L4.1604 6.88256C4.09478 6.8921 4.06859 6.97274 4.11607 7.01902L5.97038 8.82655Z"
          stroke="#453834"
        />
      </g>
      <defs>
        <clipPath id="clip0_2697_4724">
          <rect width="16" height="16" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default PointIconForMenu;
