import CheckIcon from "@mui/icons-material/Check";
import { Box, Typography, useTheme } from "@mui/material";
import React from "react";
import LabelIcon from "../../components/icons/LabelIcon";
import { useTranslation } from "react-i18next";

function getorderInfo(params) {
  const theme = useTheme();
  const { t } = useTranslation();
  return (
    <Box>
      <Typography>
        {t("order")} #:{" "}
        <a
          href={`/fbmm/${params.row.id}`}
          style={{
            color: theme.palette.secondary.main,
            textDecoration: "none",
            fontWeight: "500",
          }}
        >
          {params.row.orderNumber}
        </a>
      </Typography>
      <Typography>
        {t("status")}:{" "}
        {params.row.orderStatus.title === null
          ? "null"
          : params.row.orderStatus.title}{" "}
      </Typography>
      {/* <Typography>
        Genel Durum:{" "}
        {params.row.orderCommonStatus === null ||
        params.row.orderCommonStatus === undefined
          ? "null or undefined"
          : params.row.orderCommonStatus.title}
      </Typography> */}
      <Typography>{t("tracking")} #: {params.row.cargoNumber}</Typography>
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          gap: "5px",
        }}
      >
        {params.row.cargoNumber !== null
          ? // <Typography
            //   sx={{
            //     color: theme.palette.primary.white,
            //     background: theme.palette.primary.primaryBlue,
            //     paddingLeft: "5px",
            //     paddingRight: "5px",
            //     borderRadius: "5px",
            //     fontSize: "14px",
            //     lineHeight: "28px",
            //   }}
            // >
            //   Kargo
            // </Typography>
            ""
          : ""}
        {params.row.cargoNumber !== null &&
        params.row.isForwardToCargo === false
          ? // <Typography
            //   sx={{
            //     color: theme.palette.primary.white,
            //     background: theme.palette.primary.primaryRed,
            //     paddingLeft: "12px",
            //     paddingRight: "12px",
            //     borderRadius: "5px",
            //     fontSize: "14px",
            //     lineHeight: "28px",
            //   }}
            // >
            //   !
            // </Typography>
            ""
          : ""}
        {params.row.isManual === true ? (
          <Typography
            sx={{
              color: theme.palette.primary.white,
              background: theme.palette.primary.primaryBlue,
              paddingLeft: "8px",
              paddingRight: "8px",
              borderRadius: "5px",
              fontSize: "14px",
              lineHeight: "28px",
            }}
          >
            M
          </Typography>
        ) : (
          ""
        )}

        {params.row.cargoNumber !== null &&
        params.row.isForwardToCargo === true ? (
          <Typography
            sx={{
              color: theme.palette.primary.white,
              background: theme.palette.primary.primaryGreen,
              paddingLeft: "4px",
              paddingRight: "4px",
              borderRadius: "5px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <CheckIcon sx={{ fontSize: "18px" }} />
          </Typography>
        ) : (
          ""
        )}
      </Box>
      {params.row.orderLabels !== null
        ? params.row.orderLabels.map((label, index) => {
            return (
              <Box key={index} sx={{ mt: 1 }}>
                <LabelIcon />
              </Box>
            );
          })
        : ""}
    </Box>
  );
}

export default getorderInfo;
