import React, { useEffect, useState } from "react";
import { Box, useTheme, Typography, Button, TextField } from "@mui/material";
import Topbar from "./global/Topbar";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Cookies from "js-cookie";
import axios from "axios";
import CircularProgress from "@mui/material/CircularProgress";
// user bilgisi cekmek icin kullanilir
import { useAuthUser } from "react-auth-kit";
import NewProductIcon from "../components/icons/NewProductIcon";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { useTranslation } from "react-i18next";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

const Faq = () => {
  const [faqs, setFaqs] = useState([]);
  const theme = useTheme();
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);
  const [question, setQuestion] = useState("");
  const [answer, setAnswer] = useState("");
  const [editingFaq, setEditingFaq] = useState(null);
  const [loading, setLoading] = useState(true);
  const [selectedFaq, setSelectedFaq] = useState("");
  const [areYouSure, setAreYouSure] = useState(false);
  const [openEdit, setOpenEdit] = useState(false);
  const [expanded, setExpanded] = React.useState(false);

  const handleChange = panel => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  // user bilgisi instance olusturulur
  const auth = useAuthUser();
  // user bilgisi cekilir
  const user = auth();
  let data = "";
  let config = {
    method: "get",
    maxBodyLength: Infinity,
    url: `${process.env.REACT_APP_BASE_URL}/Faq/GetFaqS`,
    data: data,
  };

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const fetchFaqs = async () => {
    try {
      const response = await axios(config);
      setFaqs(response.data.items);
      setLoading(false);
    } catch (error) {
      console.log("error", error);
    }
  };

  const handleSaveSaq = () => {
    let data = {
      question: question,
      answer: answer,
    };

    let config = {
      method: "post",
      maxBodyLength: Infinity,
      url: `${process.env.REACT_APP_BASE_URL}/Faq/CreateFaq`,
      headers: {
        Authorization: `Bearer ${Cookies.get("_auth")}`,
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios(config)
      .then(function (response) {
        setAnswer("");
        setQuestion("");
        fetchFaqs();
        handleClose();
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const openAreYouSure = id => {
    setSelectedFaq(id);
    setAreYouSure(true);
  };

  const openEditFaq = faq => {
    setEditingFaq(faq);
    setOpenEdit(true);
  };

  const handleSaveEdit = () => {
    let data = {
      id: editingFaq.id,
      question: editingFaq.question,
      answer: editingFaq.answer,
    };

    let config = {
      method: "put",
      maxBodyLength: Infinity,
      url: `${process.env.REACT_APP_BASE_URL}/Faq/UpdateFaq/${editingFaq.id}`,
      headers: {
        Authorization: `Bearer ${Cookies.get("_auth")}`,
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios(config)
      .then(function (response) {
        fetchFaqs();
        setOpenEdit(false);
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const deleteFaq = id => {
    let config = {
      method: "delete",
      maxBodyLength: Infinity,
      url: `${process.env.REACT_APP_BASE_URL}/Faq/DeleteFaq/${id}`,
      headers: {
        Authorization: `Bearer ${Cookies.get("_auth")}`,
        "Content-Type": "application/json",
      },
    };

    axios(config)
      .then(function (response) {
        fetchFaqs();
        setAreYouSure(false);
        selectedFaq("");
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  useEffect(() => {
    fetchFaqs();
  }, []);

  if (loading)
    return (
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "90vh",
        }}
      >
        <CircularProgress />
      </Box>
    );
  return (
    <Box m={"20px"}>
      {user.role === "b2790c4b-b4a8-4254-9ab6-84c67160b0e0" ? (
        <Topbar title={t("frequentlyAskedQuestions")}>
          <Button
            onClick={() => {
              handleClickOpen();
            }}
            startIcon={<NewProductIcon />}
            color="text"
            sx={{
              color: `${theme.palette.primary.fontColor}!important`,
              pl: 3,
              textTransform: "capitalize",
              border: "none",
              borderRadius: 0,
            }}
          >
            {t("addNew")}
          </Button>
          <BootstrapDialog
            fullWidth
            maxWidth="sm"
            onClose={handleClose}
            aria-labelledby="customized-dialog-title"
            open={open}
          >
            <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
              {t("addNewFAQ")}
            </DialogTitle>
            <IconButton
              aria-label="close"
              onClick={handleClose}
              sx={{
                position: "absolute",
                right: 8,
                top: 8,
                color: theme => theme.palette.grey[500],
              }}
            >
              <CloseIcon />
            </IconButton>
            <DialogContent dividers>
              <TextField
                id="question"
                label={t("question")}
                variant="outlined"
                sx={{ mb: 3, width: "100%", borderRadius: "4px" }}
                fullWidth
                size="small"
                value={question}
                onChange={e => setQuestion(e.target.value)}
              />
              {/* <TextField
                id="answer"
                label="Cevap"
                variant="outlined"
                sx={{ mb: 3, width: "100%", borderRadius: "4px" }}
                fullWidth
                size="small"
                multiline
                value={answer}
                onChange={e => setAnswer(e.target.value)}
                rows={4}
              /> */}
              <ReactQuill theme="snow" value={answer} onChange={setAnswer} />
            </DialogContent>
            <DialogActions>
              <Button autoFocus onClick={handleSaveSaq} className="filterApply">
                {t("save")}
              </Button>
            </DialogActions>
          </BootstrapDialog>
        </Topbar>
      ) : (
        <Topbar title={t("frequentlyAskedQuestions")}></Topbar>
      )}
      <Box sx={{ background: theme.palette.primary.pageBackground }} p={5}>
        {faqs.map((faq, index) => (
          <Accordion
            expanded={expanded === `panel${index + 1}`}
            onChange={handleChange(`panel${index + 1}`)}
            key={index}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls={`panel${index + 1}bh-content`}
              id={`panel${index + 1}bh-header`}
            >
              <Typography
                sx={{
                  fontSize: 16,
                  fontWeight: 600,
                }}
              >
                {faq.question}
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography
                sx={{
                  pb: 2,
                }}
              >
                <div
                  dangerouslySetInnerHTML={{
                    __html: faq.answer,
                  }}
                ></div>
              </Typography>
            </AccordionDetails>
            {user.role === "b2790c4b-b4a8-4254-9ab6-84c67160b0e0" ? (
              <Box>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "flex-end",
                    width: "100%",
                    mb: 2,
                    gap: 2,
                    pr: 2,
                  }}
                >
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={() => openEditFaq(faq)}
                    className="filterApply"
                  >
                    {t("edit")}
                  </Button>
                  <Button
                    variant="contained"
                    color="secondary"
                    className="filterReset"
                    onClick={() => openAreYouSure(faq.id)}
                  >
                    {t("delete")}
                  </Button>
                </Box>
                <BootstrapDialog
                  onClose={() => setAreYouSure(false)}
                  aria-labelledby="customized-dialog-title"
                  open={areYouSure}
                >
                  <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
                    {t("deletionProcess")}
                  </DialogTitle>
                  <IconButton
                    aria-label="close"
                    onClick={() => setAreYouSure(false)}
                    sx={{
                      position: "absolute",
                      right: 8,
                      top: 8,
                      color: theme => theme.palette.grey[500],
                    }}
                  >
                    <CloseIcon />
                  </IconButton>
                  <DialogContent dividers>
                    <Typography>
                      {t("thisActionCannotBeUndone")} {t("areYouSureYouWantToDeleteIt")}
                    </Typography>
                  </DialogContent>
                  <DialogActions>
                    <Button
                      autoFocus
                      onClick={() => deleteFaq(selectedFaq)}
                      className="filterApply"
                    >
                      {t("delete")}
                    </Button>
                  </DialogActions>
                </BootstrapDialog>
                <BootstrapDialog
                  fullWidth
                  maxWidth="sm"
                  onClose={() => setOpenEdit(false)}
                  aria-labelledby="customized-dialog-title"
                  open={openEdit}
                >
                  <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
                    {t("editFAQ")}
                  </DialogTitle>
                  <IconButton
                    aria-label="close"
                    onClick={() => setOpenEdit(false)}
                    sx={{
                      position: "absolute",
                      right: 8,
                      top: 8,
                      color: theme => theme.palette.grey[500],
                    }}
                  >
                    <CloseIcon />
                  </IconButton>
                  <DialogContent dividers>
                    <TextField
                      id="question"
                      label={t("question")}
                      variant="outlined"
                      sx={{ mb: 3, width: "100%", borderRadius: "4px" }}
                      fullWidth
                      size="small"
                      value={(editingFaq && editingFaq.question) || ""}
                      onChange={e =>
                        setEditingFaq(prev => ({
                          ...prev,
                          question: e.target.value,
                        }))
                      }
                    />
                    <ReactQuill
                      theme="snow"
                      id="answer"
                      sx={{ mb: 3, width: "100%", borderRadius: "4px" }}
                      value={(editingFaq && editingFaq.answer) || ""}
                      onChange={e =>
                        setEditingFaq(prev => ({
                          ...prev,
                          answer: e,
                        }))
                      }
                    />
                  </DialogContent>
                  <DialogActions>
                    <Button
                      autoFocus
                      onClick={() => handleSaveEdit()}
                      className="filterApply"
                    >
                      {t("save")}
                    </Button>
                  </DialogActions>
                </BootstrapDialog>
              </Box>
            ) : null}
          </Accordion>
        ))}
      </Box>
    </Box>
  );
};

export default Faq;
