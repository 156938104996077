import React, { useContext, useState } from "react";
import { Box, Typography, Switch, Stack, Grid } from "@mui/material";
import { styled } from "@mui/material/styles";
import PackagesBox from "./PackagesBox";
import { useNavigate } from "react-router-dom";
import { UserSelectedPackageContext } from "../../context/UserSelectedPackageContext";
import Cookies from "js-cookie";
import { useTranslation } from "react-i18next";
const AntSwitch = styled(Switch)(({ theme }) => ({
  width: 28,
  height: 16,
  padding: 0,
  display: "flex",
  "&:active": {
    border: "1px solid #45383426",
    "& .MuiSwitch-thumb": {
      width: 15,
    },
    "& .MuiSwitch-switchBase.Mui-checked": {
      transform: "translateX(9px)",
      border: "1px solid #45383426",
    },
  },

  "& .MuiSwitch-switchBase": {
    padding: 2,
    "&.Mui-checked": {
      transform: "translateX(12px)",
      color: "#fff",
      "& + .MuiSwitch-track": {
        opacity: 1,
      },
    },
  },
  "& .MuiSwitch-thumb": {
    boxShadow: "0 2px 4px 0 rgb(0 35 11 / 20%)",
    width: 12,
    height: 12,
    borderRadius: 6,
    transition: theme.transitions.create(["width"], {
      duration: 200,
    }),
    backgroundColor: "#34AE1A",
  },
  "& .MuiSwitch-track": {
    border: "1px solid #45383426!important",
    borderRadius: 16 / 2,
    opacity: 1,
    backgroundColor: "#F7F6F5!important",
    boxSizing: "border-box",
  },
  "& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track": {
    backgroundColor: "#34AE1A",
    opacity: 1,
  },
  "& .Mui-checked": {
    opacity: 1,
  },
}));

const AdvertisingPackages = () => {
  const navigate = useNavigate();
  const { userSelectedPackage, setUserSelectedPackage } = useContext(
    UserSelectedPackageContext
  );
  const { t } = useTranslation();
  const [yearly, setYearly] = useState(false);
  const [selectedPackage, setSelectedPackage] = useState(null);

  const selectPackage = (packageData) => {
    setSelectedPackage(packageData);
    setUserSelectedPackage(packageData);
    Cookies.set(
      "selectedPackage",
      JSON.stringify({
        name: packageData.name,
        amount: packageData.price,
        yearly: packageData.yearly,
      })
    );
    navigate("/payment", { state: { packageData } });
  };

  const advertisingPackageData = [
    {
      id: 1,
      name: t("starterPackage"),
      regions: 1,
      marketingTypes: ["Sponsored Products"],
      returnGuarantee: "~2-3 X",
      price: yearly ? 10000 : 1000,
      yearly: yearly,
      priceId: yearly
        ? "price_1PJcAN04TS9KAeqIoYxx2hj2"
        : "price_1PJagJ04TS9KAeqIRYUP9HPd",
      mode: "subscription",
    },
    {
      id: 2,
      name: t("growthPackage"),
      regions: 2,
      marketingTypes: ["Sponsored Products", "Sponsored Brands"],
      returnGuarantee: "~2.5-3.5X",
      price: yearly ? 25000 : 2500,
      yearly: yearly,
      priceId: yearly
        ? "price_1PJcBC04TS9KAeqIVo4xZZyn"
        : "price_1PJahD04TS9KAeqIwUwUhY51",
      mode: "subscription",
    },
    {
      id: 3,
      name: t("accelerationPackage"),
      regions: 3,
      marketingTypes: ["Sponsored Products", "Sponsored Brands", "Display Ads"],
      returnGuarantee: "~3-5X",
      price: yearly ? 50000 : 5000,
      yearly: yearly,
      priceId: yearly
        ? "price_1PJcBx04TS9KAeqIhiE8Skuo"
        : "price_1PJahg04TS9KAeqIS0nncwhz",
      mode: "subscription",
    },
  ];

  return (
    <Box>
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          alignItems: "center",
          mb: 2,
        }}
      >
        <Typography
          sx={{
            fontSize: "18px",
            fontWeight: "500",
            color: "#453834",
            mb: 1,
          }}
        >
          {t("advertisingPackages")}
        </Typography>
        <Stack direction="row" spacing={1} alignItems="center">
          <Typography
            sx={{
              fontWeight: "500",
              fontSize: "14px",
            }}
          >
            {t("monthly")}
          </Typography>
          <AntSwitch
            checked={yearly}
            onChange={() => setYearly(!yearly)}
            className="ant-switch"
            inputProps={{ "aria-label": "ant design" }}
          />
          <Typography
            sx={{
              fontWeight: "500",
              fontSize: "14px",
            }}
          >
            {t("yearly")}
          </Typography>
        </Stack>
      </Box>
      <Box>
        {/* 3 col grid */}
        <Grid container spacing="20px">
          {advertisingPackageData.map((packageData, index) => {
            return (
              <Grid item xs={12} sm={4} key={index}>
                <PackagesBox
                  title={packageData.name}
                  regions={packageData.regions}
                  marketingTypes={packageData.marketingTypes}
                  returnGuarantee={packageData.returnGuarantee}
                  price={packageData.price}
                  isYearly={yearly}
                  priceId={packageData.priceId}
                  mode={packageData.mode}
                  onClick={() => selectPackage(packageData)}
                />
              </Grid>
            );
          })}
        </Grid>
      </Box>
    </Box>
  );
};

export default AdvertisingPackages;
