import { Typography } from "@mui/material";
import React from "react";

function getProductCode(params) {
  return (
    <div>
      {params.row.orderProducts.map((order, index) => (
        <div key={index} className="fbmmProdList">
          <Typography>{order.productCode}</Typography>
        </div>
      ))}
    </div>
  );
}

export default getProductCode;
