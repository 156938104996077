import React, { useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import axios from "axios";
import Cookies from "js-cookie";
import Box from "@mui/material/Box";
import FormControl from "@mui/material/FormControl";
import CustomLabel from "./form-elements/CustomLabel";
import CustomInput from "./form-elements/CustomInput";
import BoxTitle from "./form-elements/BoxTitle";
import FormGroup from "./form-elements/FormGroup";
import CustomSubmitButton from "./form-elements/CustomSubmitButton";
import { useTranslation } from "react-i18next";
import RadioGroup from "@mui/material/RadioGroup";
import Radio from "@mui/material/Radio";
import FormControlLabel from "@mui/material/FormControlLabel";

const ProductEditForm = ({ product }) => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const prod = product.productVariantInfo;
  const [responseUpdateProdInfo, setResponseUpdateProdInfo] = useState(null);
  const [sku, setSku] = useState(prod.product.sku);
  const [barcode, setBarcode] = useState(prod.barcode);
  const [title, setTitle] = useState(prod.title);
  const [link, setLink] = useState(prod.product.ink);
  const [width, setWidth] = useState(prod.product.width);
  const [length, setLength] = useState(prod.product.length);
  const [height, setHeight] = useState(prod.product.height);
  const [weight, setWeight] = useState(prod.product.weight);
  const [isBreakable, setIsBreakable] = useState(prod.product.isBreakable);
  const [isLiquid, setIsLiquid] = useState(prod.product.isLiquid);
  const [hasExpirationDate, setHasExpirationDate] = useState(
    prod.product.hasExpirationDate
  );
  const productId = useParams().id;

  const handleSubmit = event => {
    event.preventDefault();
    const prodInfo = {
      id: productId,
      sku,
      barcode,
      title,
      link,
      width,
      length,
      height,
      weight,
      isBreakable: isBreakable === "true" ? true : false,
      isLiquid: isLiquid === "true" ? true : false,
      hasExpirationDate: hasExpirationDate === "true" ? true : false,
    };

    let updateConfig = {
      method: "put",
      maxBodyLength: Infinity,
      url: `${process.env.REACT_APP_BASE_URL}/Product/${productId}`,
      headers: {
        Authorization: `Bearer ${Cookies.get("_auth")}`,
      },
      data: prodInfo,
    };

    const updateProdInfo = async () => {
      const responseUpdateProdInfo = await axios.request(updateConfig);
      setResponseUpdateProdInfo(responseUpdateProdInfo);
      navigate("/products");
    };
    updateProdInfo();
  };

  return (
    <Box
      component="form"
      sx={{
        backgroundColor: "#fff",
        p: 2,
        mt: 2,
        "& > :not(style)": { m: 1 },
      }}
      noValidate
      autoComplete="off"
    >
      <FormGroup>
        <BoxTitle title={t("sKUAndBarcode")} />
        <FormControl variant="standard">
          <CustomLabel title={t("sku")} />
          <CustomInput
            placeholder={sku}
            value={sku}
            onChange={e => setSku(e.target.value)}
          />
        </FormControl>
        <FormControl variant="standard">
          <CustomLabel title={t("barcode")} />
          <CustomInput
            placeholder={barcode}
            value={barcode}
            onChange={e => setBarcode(e.target.value)}
          />
        </FormControl>
        <FormControl variant="standard">
          <CustomLabel title={t("productName")} />
          <CustomInput
            placeholder={title}
            value={title}
            onChange={e => setTitle(e.target.value)}
          />
        </FormControl>
        <FormControl variant="standard">
          <CustomLabel title={t("productLink")} />
          <CustomInput
            placeholder={link}
            value={link}
            onChange={e => setLink(e.target.value)}
          />
        </FormControl>
      </FormGroup>
      <FormGroup>
        <BoxTitle title={t("dimensionsAndWeight")} />
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            gap: "10px",
          }}
        >
          <FormControl variant="standard" key="dimensions">
            <CustomLabel title={t("dimensions")} />
            <CustomInput
              placeholder={width.toString()}
              type="number"
              value={width}
              onChange={e => setWidth(e.target.value)}
            />
          </FormControl>
          <FormControl variant="standard" key="lenght">
            <CustomLabel title="" />
            <CustomInput
              placeholder={length.toString()}
              type="number"
              value={length}
              onChange={e => setLength(e.target.value)}
            />
          </FormControl>
          <FormControl variant="standard" key="height">
            <CustomLabel title="" />
            <CustomInput
              placeholder={height.toString()}
              type="number"
              value={height}
              onChange={e => setHeight(e.target.value)}
            />
          </FormControl>
          <FormControl variant="standard" key="weight">
            <CustomLabel title={t("weightGr")} />
            <CustomInput
              placeholder={weight.toString()}
              type="number"
              value={weight}
              onChange={e => setWeight(e.target.value)}
            />
          </FormControl>
        </Box>
      </FormGroup>
      <FormGroup>
        <BoxTitle title={t("productProperties")} />
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            columnGap: "50px",
            flexWrap: "wrap",
          }}
        >
          <FormControl sx={{ mt: 1 }} variant="standard" key="isFragile">
            <CustomLabel title={t("isFragile")} isRequired />
            <RadioGroup
              sx={{ pt: "20px" }}
              row
              aria-labelledby="demo-row-radio-buttons-group-label"
              name="row-radio-buttons-group"
              value={isBreakable}
              onChange={e => setIsBreakable(e.target.value)}
            >
              <FormControlLabel
                color="success"
                value={true}
                control={<Radio />}
                label={t("yes")}
              />
              <FormControlLabel
                value={false}
                control={<Radio />}
                label={t("no")}
              />
            </RadioGroup>
          </FormControl>
          <FormControl sx={{ mt: 1 }} variant="standard" key="isLiquid">
            <CustomLabel title={t("isLiquid")} isRequired />
            <RadioGroup
              sx={{ pt: "20px" }}
              row
              aria-labelledby="demo-row-radio-buttons-group-label"
              name="row-radio-buttons-group"
              value={isLiquid}
              onChange={e => setIsLiquid(e.target.value)}
            >
              <FormControlLabel
                color="success"
                value={true}
                control={<Radio />}
                label={t("yes")}
              />
              <FormControlLabel
                value={false}
                control={<Radio />}
                label={t("no")}
              />
            </RadioGroup>
          </FormControl>
          <FormControl sx={{ mt: 1 }} variant="standard" key="isExpirationDate">
            <CustomLabel title={t("isExpirationDate")} isRequired />
            <RadioGroup
              sx={{ pt: "20px" }}
              row
              aria-labelledby="demo-row-radio-buttons-group-label"
              name="row-radio-buttons-group"
              value={hasExpirationDate}
              onChange={e => setHasExpirationDate(e.target.value)}
            >
              <FormControlLabel
                color="success"
                value={true}
                control={<Radio />}
                label={t("yes")}
              />
              <FormControlLabel
                value={false}
                control={<Radio />}
                label={t("no")}
              />
            </RadioGroup>
          </FormControl>
        </Box>
      </FormGroup>
      <CustomSubmitButton buttonText={t("save")} onSubmitEvent={handleSubmit} />
    </Box>
  );
};

export default ProductEditForm;
