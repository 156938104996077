import React from "react";

const AddNoteIcon = () => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_2482_4721)">
        <path
          d="M7.19922 11.2C7.19922 12.2608 7.62065 13.2782 8.37079 14.0284C9.12094 14.7785 10.1384 15.2 11.1992 15.2C12.2601 15.2 13.2775 14.7785 14.0276 14.0284C14.7778 13.2782 15.1992 12.2608 15.1992 11.2C15.1992 10.1391 14.7778 9.12167 14.0276 8.37152C13.2775 7.62138 12.2601 7.19995 11.1992 7.19995C10.1384 7.19995 9.12094 7.62138 8.37079 8.37152C7.62065 9.12167 7.19922 10.1391 7.19922 11.2Z"
          stroke="#453834"
          strokeWidth="1.2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M11.1348 9.59998V12.672"
          stroke="#453834"
          strokeWidth="1.2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M9.59961 11.136H12.6716"
          stroke="#453834"
          strokeWidth="1.2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M2.5 4.5H9.5"
          stroke="#453834"
          strokeWidth="1.2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M2.5 7.5H6.5"
          stroke="#453834"
          strokeWidth="1.2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M2.5 10.5H5"
          stroke="#453834"
          strokeWidth="1.2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M5 13.5H1.5C1.23478 13.5 0.98043 13.3946 0.792893 13.2071C0.605357 13.0196 0.5 12.7652 0.5 12.5V1.5C0.5 1.23478 0.605357 0.98043 0.792893 0.792893C0.98043 0.605357 1.23478 0.5 1.5 0.5H8.586C8.85103 0.500057 9.1052 0.605321 9.29267 0.792667L11.2073 2.70733C11.3947 2.8948 11.4999 3.14897 11.5 3.414V5"
          stroke="#453834"
          strokeWidth="1.2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_2482_4721">
          <rect width="20" height="20" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default AddNoteIcon;
