/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useContext } from "react";
import Cookies from "js-cookie";
import Toggler from "../components/partials/Toggler";
import Topbar from "./global/Topbar";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import {
  Box,
  Button,
  Typography,
  useTheme,
  Alert,
  Snackbar,
} from "@mui/material";
import TableList from "../components/partials/TableList";
import axios from "axios";
import Toolbar from "@mui/material/Toolbar";
import FilterDrawer from "../components/partials/FilterDrawer";
import ProductDialog from "../components/dialogs/ProductDialog";
import { GridActionsCellItem } from "@mui/x-data-grid";
import DeleteIcon from "@mui/icons-material/Delete";
import FileCopyIcon from "@mui/icons-material/FileCopy";
import { ProductFilterContext } from "../context/ProductFilterContext";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import getBarcode from "../getters/Products/getBarcode";
import getSize from "../getters/Products/getSize";
import getSku from "../getters/Products/getSku";
import getPrice from "../getters/Products/getPrice";
import getVariants from "../getters/Products/getVariants";
import getStatus from "../getters/Products/getStatus";
import getWeight from "../getters/Products/getWeight";
import getWareHouses from "../getters/Products/getWareHouses";
import getProductName from "../getters/Products/getProductName";
import MultiProductDialog from "../components/dialogs/MultiProductDialog";
import { useAuthUser } from "react-auth-kit";
import NewProductIcon from "../components/icons/NewProductIcon";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { styled } from "@mui/material/styles";
import { Select } from "@mui/material";
import FormControl from "@mui/material/FormControl";
import MenuItem from "@mui/material/MenuItem";
import InputBase from "@mui/material/InputBase";
import { alpha } from "@mui/material/styles";
import { Divider } from "antd";

// asasdas
function EnhancedTableToolbar() {
  //const { t } = useTranslation();
  return (
    <Toolbar
      sx={{
        pl: { sm: 2 },
        pr: { xs: 1, sm: 1 },
        borderBottom: "1px solid #E0E0E0",
      }}
    >
      <Typography
        sx={{ flex: "1 1 100%" }}
        variant="body1"
        fontWeight={500}
        id="tableTitle"
        component="div"
        color="primary"
      >
        Ürünler
      </Typography>
    </Toolbar>
  );
}

const Products = () => {
  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      color: theme.palette.primary.main,
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
      borderRight: `1px solid ${theme.palette.primary.bordersColor}}`,
      borderRadius: "2px",
      "&:last-child": {
        borderRight: "none",
      },
    },
  }));

  const BootstrapSelect = styled(InputBase)(({ theme }) => ({
    "label + &": {
      marginTop: theme.spacing(3),
      color: `${alpha(theme.palette.text.main, 0.25)} 0 0 0 0.2rem`,
    },
    "& .MuiInputBase-input": {
      borderRadius: 2,
      position: "relative",
      border: "1px solid",
      fontSize: 14,
      fontStyle: "italic",
      height: "4px!important",
      width: "100%",
      padding: "10px 12px",
      lineHeight: "1",
      marginTop: "0.5rem",
      borderColor: theme.palette.primary.bordersColor,
      transition: theme.transitions.create([
        "border-color",
        "background-color",
        "box-shadow",
      ]),
      minHeight: " 0.8rem!important",
      "&:focus": {
        boxShadow: `${alpha(theme.palette.primary.main, 0.25)} 0 0 0 0.2rem`,
        borderColor: theme.palette.primary.main,
        color: theme.palette.text.main,
      },
    },
  }));

  const auth = useAuthUser();
  const navigate = useNavigate();
  const { productFilters, setProductFilters } =
    useContext(ProductFilterContext);
  const theme = useTheme();
  const [deleteItem, setDeleteItem] = useState(null);
  const [deleteItemSKU, setDeleteItemSKU] = useState(null);
  const { t } = useTranslation();
  const [pageState, setPageState] = useState({
    isLoading: false,
    data: [],
    total: 0,
    page: 0,
    pageSize: 10,
  });
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [selectionProducts, setSelectionProducts] = useState([]);
  const [openChangeProducts, setOpenChangeProducts] = useState(false);
  const [selectedProductsTableData, setSelectedProductsTableData] = useState(
    []
  );
  // const [selectedRows, setSelectedRows] = useState([]);
  const [message, setMessage] = useState("");
  const [severity, setSeverity] = useState("success");
  const [openSnack, setOpenSnack] = useState(false);
  const [loading, setLoading] = useState(false);
  const [merchants, setMerchants] = useState([]);
  const [selectedMerchant, setSelectedMerchant] = useState("");

  const handleCloseSnack = () => {
    setOpenSnack(false);
  };

  const handleOpenSnack = () => {
    setOpenSnack(true);
  };

  const handleClickChangeProducts = () => {
    if (selectionProducts.length > 0) {
      setOpenChangeProducts(true);
    } else {
      alert(t("pleaseSelectTheProductsYouWishToExchange"));
    }
  };

  const handleCloseChangeProducts = () => {
    setOpenChangeProducts(false);
  };

  const updateHandler = id => {
    return () => {
      navigate(`/product-edit/${id}`);
    };
  };

  const productStatusHandler = id => {
    return () => {
      navigate(`/product-status/${id}`);
    };
  };

  const deleteHandler = element => {
    return () => {
      setDeleteItem(element.product.id);
      setOpenDeleteDialog(true);
      setDeleteItemSKU(element.product.sku);
    };
  };

  const acceptDeleteHandler = () => {
    deleteData();
    setOpenDeleteDialog(false);
  };

  const deleteData = async () => {
    const response = await axios.request(productDeleteConfig);
    return response;
  };

  const [productStatus, setProductStatus] = useState([]);

  const [error, setError] = useState(null);

  // const [anchorEl, setAnchorEl] = useState(null);
  // const open = Boolean(anchorEl);

  // const handleClick = event => {
  //   setAnchorEl(event.currentTarget);
  // };
  const handleClose = () => {
    // setAnchorEl(null);
    setOpenDeleteDialog(false);
  };

  const handleSelections = selectionModel => {
    setSelectionProducts(selectionModel);
  };

  const handleClearSelections = () => {
    setSelectionProducts([]); // Seçili ürünleri sıfırla
    // setSelectedRows([]); // DataTable'da seçili öğeleri temizle
  };

  const columns = [
    { field: "mpn", headerName: t("sku"), flex: 3, renderCell: getSku },
    {
      field: "barcode",
      headerName: t("barcode"),
      flex: 3,
      valueGetter: getBarcode,
    },
    {
      field: "variantName",
      headerName: t("variant"),
      flex: 2,
      valueGetter: getVariants,
    },
    {
      field: "productStatusId",
      headerName: t("productStatus"),
      valueGetter: getStatus,
    },
    {
      field: "title",
      headerName: t("productName"),
      flex: 3,
      cellClassName: "name-column--cell",
      valueGetter: getProductName,
    },
    {
      field: "productSize",
      headerName: t("productSize"),
      valueGetter: getSize,
      flex: 2,
    },
    {
      field: "weight",
      headerName: t("weight"),
      valueGetter: getWeight,
      flex: 1.4,
    },
    {
      field: "warehouses",
      headerName: t("warehouses"),
      flex: 2,
      renderCell: getWareHouses,
    },
    {
      field: "price",
      headerName: t("price"),
      flex: 1.2,
      valueGetter: getPrice,
    },
    // {
    //   field: "warehouseReady",
    //   headerName: t("warehouseReady"),
    //   flex: 0,
    //   renderCell: getReadyOnWarehouse,
    // },
    {
      field: "actions",
      type: "actions",
      headerName: t("actions"),
      flex: 2,
      getActions: params => [
        <GridActionsCellItem
          icon={<FileCopyIcon />}
          label={t("updateStatus")}
          onClick={productStatusHandler(params.row.id)}
          showInMenu
        />,
        <GridActionsCellItem
          icon={<FileCopyIcon />}
          label={t("edit")}
          onClick={updateHandler(params.row.id)}
          showInMenu
        />,
        <GridActionsCellItem
          icon={<DeleteIcon />}
          label={t("delete")}
          onClick={deleteHandler(params.row)}
          showInMenu
        />,
      ],
    },
  ];

  const searchPlaceholder = t("product_search");

  let data = "";
  let config = {
    method: "get",
    maxBodyLength: Infinity,
    url: `${process.env.REACT_APP_BASE_URL}/ProductVariant?Pagination.Page=${pageState.page}&Pagination.Size=${pageState.pageSize}`,
    headers: {
      Authorization: `Bearer ${Cookies.get("_auth")}`,
    },
    data: data,
  };

  let productStatusConfig = {
    method: "get",
    maxBodyLength: Infinity,
    url: `${process.env.REACT_APP_BASE_URL}/ProductStatus/count`,
    headers: {
      Authorization: `Bearer ${Cookies.get("_auth")}`,
    },
    data: data,
  };

  let productDeleteConfig = {
    method: "delete",
    maxBodyLength: Infinity,
    url: `${process.env.REACT_APP_BASE_URL}/Product/${deleteItem}`,
    headers: {
      Authorization: `Bearer ${Cookies.get("_auth")}`,
    },
    data: data,
  };

  const fetchData = async () => {
    const response = await axios.request(productStatusConfig);
    const responseData = await response.data.items;
    setProductStatus(responseData);
  };

  const changeProductsConfig = {
    method: "put",
    maxBodyLength: Infinity,
    url: `${process.env.REACT_APP_BASE_URL}/ProductVariant/UpdateProductsmerchant/${selectedMerchant}`,
    headers: {
      Authorization: `Bearer ${Cookies.get("_auth")}`,
    },
    data: {
      productVariantIds: selectionProducts.join(","),
      merchantId: selectedMerchant,
    },
  };

  const changeProductsSubmitFromAPI = async () => {
    setLoading(true);
    try {
      const response = await axios.request(changeProductsConfig);
      setOpenChangeProducts(false);
      setMessage(t("productsWereSuccessfullyExchanged"));
      setSeverity("success");
      handleOpenSnack();
      setSelectionProducts([]);
      handleClearSelections();
      setSelectedMerchant("");
      setSelectedProductsTableData([]);
      fetchData();
      return response;
    } catch (error) {
      console.error(error);
      setOpenChangeProducts(false);
      setSelectionProducts([]);
      handleClearSelections();
      setSelectedMerchant("");
      setSelectedProductsTableData([]);
      setMessage(t("anErrorOccurredWhileExchangingProducts"));
      handleOpenSnack();
    }
    setLoading(false);
  };

  const getMerchantsFromAPI = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BASE_URL}/Merchant/GetList`,
        {
          headers: {
            Authorization: `Bearer ${Cookies.get("_auth")}`,
          },
        }
      );
      setMerchants(response.data.items);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  if (auth().role === "b2790c4b-b4a8-4254-9ab6-84c67160b0e0") {
    useEffect(() => {
      getMerchantsFromAPI();
    }, []);
  }

  useEffect(() => {
    if (selectionProducts) {
      const selectedProducts = pageState.data.filter(
        product => selectionProducts.indexOf(product.id) > -1
      );
      setSelectedProductsTableData(selectedProducts);
    }
  }, [selectionProducts]);

  useEffect(() => {
    axios
      .get(
        `${process.env.REACT_APP_BASE_URL}/ProductVariant?Pagination.Page=0&Pagination.Size=10`,
        {
          headers: {
            Authorization: `Bearer ${Cookies.get("_auth")}`,
          },
        }
      )
      .then(response => {
        console.log(":)");
      });
  }, []);

  useEffect(() => {
    if (productFilters) {
      if (productFilters.sku) {
        config.url = config.url + "&Sku=" + productFilters.sku;
      }
      if (productFilters.barcode) {
        config.url = config.url + "&Query=" + productFilters.barcode;
      }
      if (productFilters.status) {
        config.url = config.url + "&Status=" + productFilters.status;
      }
      if (productFilters.category) {
        config.url = config.url + "&CategoryList=" + productFilters.category;
      }
      if (productFilters.warehouse) {
        config.url = config.url + "&WarehouseList=" + productFilters.warehouse;
      }
      if (productFilters.search) {
        config.url = config.url + "&Query=" + productFilters.search;
      }
      if (productFilters.brandList) {
        config.url = config.url + "&BrandList=" + productFilters.brandList;
      }
      if (productFilters.merchant) {
        config.url = config.url + "&Merchant=" + productFilters.merchant;
      }
      if (productFilters.updatedAt) {
        config.url = config.url + "&UpdatedAt=" + productFilters.updatedAt;
      }
      if (productFilters.updatedAtEnd) {
        config.url =
          config.url + "&UpdatedAtEnd=" + productFilters.updatedAtEnd;
      }
    }

    const fetchData = async () => {
      setPageState(old => ({ ...old, isLoading: true }));
      const response = await axios.request(config);
      const responseData = await response.data.items;
      const totalCount = await response.data.totalCount;
      setPageState(old => ({
        ...old,
        isLoading: false,
        data: responseData,
        total: totalCount,
      }));
    };
    fetchData();
  }, [pageState.page, pageState.pageSize, productFilters]);

  useEffect(() => {
    config.url = `${process.env.REACT_APP_BASE_URL}/ProductVariant?Pagination.Page=0&Pagination.Size=10`;
    if (productFilters.sku) {
      config.url = config + "&Sku=";
    }
    if (productFilters.barcode) {
      config.url = config.url + "&Query=";
    }
    if (productFilters.status) {
      config.url = config.url + "&Status=";
    }
    if (productFilters.category) {
      config.url = config.url + "&CategoryList=";
    }
    if (productFilters.warehouse) {
      config.url = config.url + "&WarehouseList=";
    }
    if (productFilters.search) {
      config.url = config.url + "&Query=";
    }
    if (productFilters.brandList) {
      config.url = config.url + "&BrandList=";
    }
    if (productFilters.merchant) {
      config.url = config.url + "&Merchant=";
    }
    if (productFilters.updatedAt) {
      config.url = config.url + "&UpdatedAt=";
    }
    if (productFilters.updatedAtEnd) {
      config.url = config.url + "&UpdatedAtEnd=";
    }

    setProductFilters({
      sku: null,
      barcode: null,
      productName: null,
      status: null,
      category: [],
      brandList: [],
      warehouse: [],
      search: "",
      merchant: "",
      updatedAt: "",
      updatedAtEnd: "",
    });
    setProductStatus([]);
  }, []);

  if (error) {
    setError(error);
    return <div>Error: {error.message}</div>;
  } else {
    return (
      <Box m={"20px"}>
        <Topbar title={t("products")}>
          <ProductDialog />
          <MultiProductDialog refh={() => fetchData()} />
          {auth().role === "b2790c4b-b4a8-4254-9ab6-84c67160b0e0" ? (
            <Button
              onClick={handleClickChangeProducts}
              startIcon={<NewProductIcon />}
              color="text"
              sx={{
                color: `${theme.palette.primary.fontColor}!important`,
                pl: 2,
                textTransform: "capitalize",
                border: "none",
                borderRadius: 0,
              }}
            >
             {t("changeCompany")}
            </Button>
          ) : (
            <></>
          )}
        </Topbar>
        <Box sx={{ background: theme.palette.primary.white }} p={5}>
          <Toggler options={productStatus} placeholder={searchPlaceholder} />
          <FilterDrawer />
          <Box mt={2} sx={{ height: "100%" }}>
            <TableList
              key={pageState.data.id}
              rows={pageState.data}
              columns={columns}
              pageState={pageState}
              setPageState={setPageState}
              rowHeight={120}
              selection={true}
              onSelectionModelChange={handleSelections}
            />
            {/* hmhh */}
          </Box>
        </Box>
        <Dialog
          open={openDeleteDialog}
          onClose={handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">{t("deleteProduct")}</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              {deleteItem !== null ? deleteItemSKU : ""} {t("areYouSureYouWantToDeleteTheProductWithSKU")}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose}>{t("giveUp")}</Button>
            <Button onClick={acceptDeleteHandler} autoFocus>
              {t("confirm")}
            </Button>
          </DialogActions>
        </Dialog>
        <Dialog
          open={openChangeProducts}
          onClose={handleCloseChangeProducts}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          className="change-products-dialog"
        >
          <Typography
            variant="h6"
            component="div"
            color={theme.palette.primary.main}
            sx={{
              paddingX: "23px",
              pt: "20px",
              pb: "10px",
              mb: "0px",
              borderBottom: `1px solid ${theme.palette.primary.bordersColor}`,
            }}
          >
            {t("changeCompany")}
          </Typography>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              {selectedProductsTableData.length > 0 ? (
                <TableContainer
                  component={Paper}
                  sx={{
                    mb: 5,
                    border: `1px solid ${theme.palette.primary.bordersColor}`,
                    borderBottom: "none",
                    boxShadow: "none",
                  }}
                >
                  <EnhancedTableToolbar />
                  <Table aria-label="simple table">
                    <TableHead
                      sx={{
                        borderBottom: `1px solid ${theme.palette.primary.bordersColor}`,
                      }}
                    >
                      <TableRow>
                        <TableCell>{t("sku")}</TableCell>
                        <TableCell>{t("productName")}</TableCell>
                        <TableCell>{t("company")}</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {selectedProductsTableData.map((row, index) => (
                        <TableRow key={index}>
                          <StyledTableCell component="th" scope="row">
                            {row.mpn}
                          </StyledTableCell>
                          <StyledTableCell align="left">
                            {row.title}
                          </StyledTableCell>
                          <StyledTableCell align="left">
                            {
                              // match the selected merchant id with the merchant id in the merchants array and get the title of the merchant
                              merchants.find(
                                merchant =>
                                  merchant.id === row.product.merchantId
                              ) ? (
                                merchants.find(
                                  merchant =>
                                    merchant.id === row.product.merchantId
                                ).title
                              ) : (
                                <em
                                  style={{
                                    color: "currentColor",
                                    opacity: "0.42",
                                  }}
                                >
                                  {t("company")}
                                </em>
                              )
                            }
                          </StyledTableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              ) : (
                ""
              )}
            </DialogContentText>
            <Box
              sx={{
                border: `1px solid ${theme.palette.primary.bordersColor}`,
                borderRadius: "4px",
                padding: "20px",
                marginBottom: "20px",
              }}
            >
              <Typography
                variant="body1"
                component="div"
                fontWeight={500}
                color={theme.palette.primary.main}
              >
                {t("newCompany")}
              </Typography>
              <Divider
                style={{
                  marginTop: "10px",
                  marginBottom: "10px",
                }}
              />
              <Typography
                variant="body1"
                fontSize="14px"
                component="div"
                color="text"
              >
                {t("selectToWhichNewCompanyYouWantToAssignTheSelectedProducts")}
              </Typography>
              <DialogContentText id="alert-dialog-description">
                <FormControl sx={{ width: "50%" }} size="small">
                  {/* Single select */}
                  <Select
                    labelId="demo-multiple-2-checkbox-label"
                    id="demo-multiple-2-checkbox"
                    displayEmpty
                    value={selectedMerchant}
                    input={<BootstrapSelect />}
                    onChange={e => setSelectedMerchant(e.target.value)}
                    renderValue={() => (
                      <div>
                        {selectedMerchant === "" ? (
                          <em
                            style={{
                              color: "currentColor",
                              opacity: "0.42",
                            }}
                          >
                            {t("company")}
                          </em>
                        ) : (
                          merchants.find(
                            option => option.id === selectedMerchant
                          ).title
                        )}
                      </div>
                    )}
                  >
                    {merchants.map(opt => (
                      <MenuItem key={opt.id} value={opt.id}>
                        {opt.title}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </DialogContentText>
            </Box>
          </DialogContent>
          <DialogActions
            sx={{
              marginX: "15px",
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <Button className="filterReset" onClick={handleCloseChangeProducts}>
              {t("close")}
            </Button>
            <Typography
              variant="body1"
              component="div"
              color="text"
              fontSize={14}
              fontWeight={500}
            >
              {selectedProductsTableData.length} {t("quantityProduct")}
            </Typography>
            <Button
              onClick={changeProductsSubmitFromAPI}
              className="filterApply"
              autoFocus
              disabled={loading}
            >
              {loading ? t("pleaseWait") : t("changeCompany")}
            </Button>
          </DialogActions>
        </Dialog>
        {/* Snackbar success or error message */}
        <Snackbar
          open={openSnack}
          autoHideDuration={6000}
          onClose={handleCloseSnack}
          message={message}
        >
          <Alert onClose={handleCloseSnack} severity={severity}>
            {message}
          </Alert>
        </Snackbar>
      </Box>
    );
  }
};

export default Products;
