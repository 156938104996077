import React from "react";

const SendMessageIcon = () => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8.88889 12.5765C8.77493 12.5765 8.66334 12.6089 8.56715 12.67L5.04444 14.908V13.1765C5.04444 12.8451 4.77582 12.5765 4.44444 12.5765H2.66667C2.12998 12.5765 1.60782 12.351 1.21726 11.9375C0.825589 11.5228 0.6 10.9534 0.6 10.3529V2.82353C0.6 2.2231 0.825589 1.65367 1.21726 1.23897C1.60782 0.825424 2.12998 0.6 2.66667 0.6H13.3333C13.87 0.6 14.3922 0.825424 14.7827 1.23897C15.1744 1.65367 15.4 2.2231 15.4 2.82353V10.3529C15.4 10.9534 15.1744 11.5228 14.7827 11.9375C14.3922 12.351 13.87 12.5765 13.3333 12.5765H8.88889ZM4.89082 15.0056C4.89089 15.0055 4.89095 15.0055 4.89102 15.0055L4.89082 15.0056Z"
        stroke="#453834"
        strokeWidth="1.2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default SendMessageIcon;
