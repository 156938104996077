import React, { useState, useEffect, useContext } from "react";
import { Typography, Box, Divider, Grid } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { useTranslation } from "react-i18next";
import Stack from "@mui/material/Stack";
import { Check } from "@mui/icons-material";
import IconButton from "@mui/material/IconButton";
import ErrorIcon from "../icons/ErrorIcon";
const PriceCalculationToolTable = ({
  data,
  showSuccessState1,
  showSuccessState2,
  showErrorState1,
  showErrorState2,
}) => {
  const [total, setTotal] = useState(0);
  const [error, setError] = useState(null);
  const { t } = useTranslation();

  function NoRowsOverlay() {
    return (
      <Stack height="100%" alignItems="center" justifyContent="center">
        {t("noDataFound")}
      </Stack>
    );
  }

  return (
    <Box
      sx={{
        p: 2,
        border: "1px solid rgba(69, 56, 52, 0.15)",
        borderRadius: "2px",
      }}
    >
      <Typography variant="text" color="primary" sx={{ fontWeight: "bold" }}>
        {t("calculationResult")}
      </Typography>
      <Box
        variant="text"
        sx={{
          fontSize: "11px",
          fontFamily: "Roboto",
          marginTop: "5px",
        }}
      >
        {/* Sed posuere consectetur est at lobortis. Nullam id dolor id nibh
        ultricies vehicula ut id elit. Sociis natoque penatibus et magnis dis
        parturient montes, Nascetur Ridiculus mus. */}
      </Box>
      {/* Data Table */}
      {/* <Divider /> */}

      {showErrorState1 ? (
        <Box>
          <Box sx={{ border: "1px solid rgba(250, 248, 248, 1)" }}>
            <Grid
              container
              justifyContent="left"
              sx={{
                backgroundColor: "rgba(250, 248, 248, 1)",
                padding: "14px",
                fontSize: "small",
              }}
            >
              <Grid>
                <Box
                  sx={{
                    padding: "2px",
                    color: "rgba(215, 40, 62, 1) !important",
                    fontWeight: "700",
                  }}
                >
                  <Typography variant="text" sx={{}}>
                    <ErrorIcon
                      sx={{
                        marginBottom: "-1px",
                      }}
                    />
                    <span style={{ padding: "3px" }}>
                      {t("sorryYourProductCannotBeShipped")}
                    </span>
                  </Typography>
                </Box>
                <Box sx={{ padding: "2px" }}>
                  {t("accordingToYourCriteriaThereIsNoCountryYouCanShipTo")}
                </Box>
              </Grid>
            </Grid>
          </Box>
        </Box>
      ) : (
        ""
      )}

      {showErrorState2 ? (
        <Box>
          <Box sx={{ border: "1px solid rgba(250, 248, 248, 1)" }}>
            <Grid
              container
              justifyContent="left"
              sx={{
                backgroundColor: "rgba(250, 248, 248, 1)",
                padding: "14px",
                fontSize: "small",
              }}
            >
              <Grid>
                <Box
                  sx={{
                    padding: "2px",
                    color: "rgba(215, 40, 62, 1) !important",
                    fontWeight: "700",
                  }}
                >
                  <Typography variant="text" sx={{}}>
                    <ErrorIcon
                      sx={{
                        marginBottom: "-1px",
                      }}
                    />
                    <span style={{ padding: "3px" }}>
                      {t("sorryYourProductCannotBeShippedByPlane")}
                    </span>
                  </Typography>
                </Box>
              </Grid>
            </Grid>
          </Box>
        </Box>
      ) : (
        ""
      )}

      {showSuccessState1 ? (
        <Box>
          <Box sx={{ border: "1px solid rgba(250, 248, 248, 1)" }}>
            <Grid
              container
              justifyContent="left"
              sx={{
                backgroundColor: "rgba(250, 248, 248, 1)",
                padding: "14px",
                fontSize: "small",
              }}
            >
              <Grid>
                <Box
                  sx={{
                    padding: "2px",
                    color: "rgba(52, 174, 26, 1) !important",
                    fontWeight: "700",
                  }}
                >
                  <Typography variant="text" sx={{}}>
                    <Check
                      sx={{ marginBottom: "-3px", fontSize: "1rem" }}
                      color="success"
                    />
                    {t("youCanSendYourProduct")}
                  </Typography>
                </Box>
                <Box sx={{ padding: "2px" }}>
                  {t("youCanShipToTheCountriesBlaBla")}
                </Box>
              </Grid>
            </Grid>
          </Box>
          <Divider />
          <Box sx={{ border: "1px solid rgba(250, 248, 248, 1)" }}>
            <Grid
              container
              justifyContent="left"
              sx={{
                backgroundColor: "rgba(250, 248, 248, 1)",
                padding: "14px",
                fontSize: "small",
              }}
            >
              <Grid>
                <Box
                  sx={{
                    color: "rgba(52, 174, 26, 1) !important",
                    fontWeight: "700",
                  }}
                ></Box>
                <Box>
                  {t("withAmazon")} <b> {t("lowerCost")} </b>
                  {t(
                    "youCanGetInformationFromTheCustomerRepresentativeToProvideShipping"
                  )}
                </Box>
              </Grid>
            </Grid>
          </Box>
        </Box>
      ) : (
        ""
      )}
      {showSuccessState2 ? (
        <Box sx={{ border: "1px solid rgba(250, 248, 248, 1)" }}>
          <Grid
            container
            justifyContent="left"
            sx={{
              backgroundColor: "rgba(250, 248, 248, 1)",
              padding: "14px",
              fontSize: "small",
            }}
          >
            <Grid>
              <Box
                sx={{
                  padding: "2px",
                  color: "rgba(52, 174, 26, 1) !important",
                  fontWeight: "700",
                }}
              >
                <Typography variant="text" sx={{}}>
                  <Check
                    sx={{ marginBottom: "-3px", fontSize: "1rem" }}
                    color="success"
                  />
                  {t("youCanSendYourProductByAirplane")}
                </Typography>
              </Box>
              <Box sx={{ padding: "2px" }}>
                {t("youCanShipToTheCountriesListedBelowAtThePricesIndicated")}
              </Box>
            </Grid>
          </Grid>
        </Box>
      ) : (
        ""
      )}

      <DataGrid
        sx={{
          borderRadius: "2px",
          marginTop: "20px",
        }}
        rows={data}
        columns={[
          {
            field: "countryInfo",
            headerName: t("country"),
            flex: 2,
            valueGetter: (params) => params.row.countryInfo.title,
          },
          {
            field: "price",
            headerName: t("cost"),
            flex: 2,
            valueGetter: (params) => {
              if (params.row.currency.title === "Euro") {
                return params.row.price.toFixed(2) + " €";
              } else if (params.row.currency.title === "Dolar") {
                return params.row.price.toFixed(2) + " $";
              } else if (params.row.currency.title === "GBP") {
                return params.row.price.toFixed(2) + " £";
              } else {
                return params.row.price.toFixed(2) + " ₺";
              }
            },
          },
        ]}
        components={{ NoRowsOverlay }}
        autoHeight
        pageSize={10}
        initialState={{
          sorting: {
            sortModel: [{ field: "countryInfo", sort: "asc" }],
          },
        }}
        rowsPerPageOptions={[5]}
        disableSelectionOnClick
      />
    </Box>
  );
};

export default PriceCalculationToolTable;
