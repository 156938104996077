import React, { useState } from "react";
import axios, { AxiosError } from "axios";
import Avatar from "@mui/material/Avatar";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Checkbox from "@mui/material/Checkbox";
import Container from "@mui/material/Container";
import CssBaseline from "@mui/material/CssBaseline";
import FormControlLabel from "@mui/material/FormControlLabel";
import MegaLogo from "../components/icons/MegaLogo";
import TextField from "@mui/material/TextField";
import { ThemeProvider } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import { useFormik } from "formik";
import { Link, useNavigate } from "react-router-dom";
import { useSignIn } from "react-auth-kit";
import { useTheme } from "@emotion/react";
import {
  Alert,
  Grid,
  FormGroup,
  OutlinedInput,
  FormControl,
} from "@mui/material";
import LoginSlider from "../components/partials/LoginSlider";
import CustomLabel from "../components/forms/form-elements/CustomLabel";
import LoginSSSIcon from "../components/icons/LoginSSSIcon";
import { useTranslation } from "react-i18next";

import Flag from "react-world-flags";
import { useLanguage } from "../context/LanguageContext";

const SignIn = () => {
  const [error, setError] = useState("");
  const signIn = useSignIn();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { i18n } = useTranslation();
  const [lang, setLang] = useState("tr");
  const { language, changeLanguage } = useLanguage();

  const onSubmit = async (values) => {
    setError("");
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_BASE_URL}/controller/Login`,
        values,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      if (
        signIn({
          token: response.data.userInfo.token.accessToken,
          expiresIn: 899,
          tokenType: "Bearer",
          authState: {
            email: values.usernameOrEmail,
            role: response.data.userInfo.roleId,
            refreshToken: response.data.userInfo.token.refreshToken,
            merchantId: response.data.userInfo.merchantId,
            companyId: response.data.userInfo.companyId,
            nameSurname: response.data.userInfo.nameSurname,
          },
        })
      ) {
        setTimeout(() => {
          navigate("/");
        }, 1000);
      }
    } catch (err) {
      if (err && err instanceof AxiosError) {
        if (err.response) {
          setError(t("usernameOrPasswordIsIncorrect"));
        }
      } else if (err && err instanceof Error) {
        setError(t("usernameOrPasswordIsIncorrect"));
      } else {
        console.log("Error: ", err);
      }
    }
  };
  const changeLanguageHandler = () => {
    if (lang === "tr") {
      setLang("en");
      changeLanguage("en");
      i18n.changeLanguage("en");
    } else {
      setLang("tr");
      changeLanguage("tr");
      i18n.changeLanguage("tr");
    }
  };

  const formik = useFormik({
    initialValues: {
      usernameOrEmail: "",
      password: "",
    },
    onSubmit,
  });
  const theme = useTheme();
  return (
    <ThemeProvider theme={theme}>
      <Container
        component="main"
        maxWidth={false}
        sx={{
          paddingLeft: "0px!important",
          paddingRight: "0px!important",
        }}
      >
        <CssBaseline />
        <Grid container>
          <Grid
            className="loginForm"
            item
            xs={12}
            md={6}
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              flexDirection: "column",
              height: "100vh",
              width: "100%",
              overflow: "hidden",
              "@media (max-height: 960px)": {
                width: "100%!important",
                margin: "auto!important",
              },
            }}
          >
            <Box
              sx={{
                backgroundColor: "#fff",
                padding: "2em",
                display: "flex",
                marginTop: "15%!important",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                width: "100%",
                maxWidth: "400px",
                boxShadow: "0px 0px 10px 0px rgba(0,0,0,0.1)",
                pb: 0,
                pl: 0,
                pr: 0,
              }}
            >
              {/* <MegaLogo /> */}
              <img src="/logo192.png" alt="logo" width="220px" />
              {error && (
                <Box
                  sx={{
                    p: 3.5,
                    pb: 0,
                    mt: 1.5,
                    width: "100%",
                  }}
                  display="block"
                >
                  <Alert
                    severity="error"
                    sx={{
                      fontSize: "14px",
                      fontWeight: "500",
                      width: "100%",
                      textAlign: "center",
                      border: "1px solid #f44336",
                    }}
                  >
                    {error}
                  </Alert>
                </Box>
              )}
              <Box
                component="form"
                onSubmit={formik.handleSubmit}
                noValidate
                sx={{ mt: 1, width: "100%", p: 3, pt: 0 }}
              >
                <FormGroup
                  sx={{
                    width: "100%",
                  }}
                >
                  <CustomLabel title={t("username")} />
                  <FormControl
                    fullWidth
                    variant="filled"
                    sx={{ width: "100%" }}
                  >
                    <OutlinedInput
                      sx={{
                        height: "2.5em",
                        fontSize: 14,
                        WebkitTextFillColor: "#453834!important",
                        width: "100%",
                      }}
                      value={formik.values.usernameOrEmail}
                      onChange={formik.handleChange}
                      type="text"
                      autoFocus
                      required
                      id="usernameOrEmail"
                      name="usernameOrEmail"
                      className="setEmail"
                      style={{
                        background: "#fff",
                      }}
                    />
                  </FormControl>
                </FormGroup>
                <FormGroup>
                  <CustomLabel title={t("password")} />
                  <FormControl
                    fullWidth
                    variant="filled"
                    sx={{ width: "100%" }}
                  >
                    <OutlinedInput
                      sx={{
                        height: "2.5em",
                        fontSize: 14,
                        WebkitTextFillColor: "#453834!important",
                        width: "100%",
                      }}
                      value={formik.values.password}
                      onChange={formik.handleChange}
                      type="password"
                      required
                      id="password"
                      name="password"
                      className="setEmail"
                      style={{
                        background: "#fff",
                      }}
                    />
                  </FormControl>
                </FormGroup>
                {/* <TextField
                  color="primary"
                  margin="normal"
                  required
                  fullWidth
                  id="usernameOrEmail"
                  label="Email Adresi"
                  name="usernameOrEmail"
                  value={formik.values.usernameOrEmail}
                  onChange={formik.handleChange}
                  autoComplete="email"
                  autoFocus
                /> */}
                {/* <FormControlLabel
              control={<Checkbox value="remember" color="primary" />}
              label="Beni HatÄ±rla"
            /> */}

                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    mt: 2,
                  }}
                >
                  <Link to="/forget-password">
                    <Typography
                      component="h1"
                      variant="subtitle1"
                      sx={{
                        textAlign: "right",
                        color: "#453834",
                        fontSize: "14px",
                        fontWeight: "500",
                        mb: "0.7em",
                      }}
                    >
                      {t("forgotPassword")}
                    </Typography>
                  </Link>
                  <Button className="filterApply" type="submit">
                    {t("signIn")}
                  </Button>
                </Box>
              </Box>
              <Box sx={{ background: "#F4F2F1", width: "100%", p: 2 }}>
                <Typography
                  sx={{
                    textAlign: "center",
                    fontWeight: "500",
                    fontSize: "14px",
                  }}
                >
                  {t("ArentYouRegistered")}
                  <a
                    style={{
                      color: "#EB603A",
                      textDecoration: "underline",
                    }}
                    href="/signup"
                  >
                    {t("applyNow")}
                  </a>
                </Typography>
              </Box>
            </Box>
            <Box
              sx={{
                width: "100%",
                backgroundColor: "#E5E5E599",
                padding: "1.7rem 0",
              }}
            >
              <Typography
                sx={{
                  textAlign: "center",
                  fontWeight: "500",
                  fontSize: "14px",
                  mb: "1em",
                }}
                color="primary"
              >
                <Box>                  
                  <Link onClick={changeLanguageHandler}>
                    <Flag
                      code={lang === "tr" ? "tur" : "us"}
                      style={{
                        borderRadius: "3px",
                        width: "45px",
                        float: "left",
                        marginLeft: "24px",
                        marginTop: "-8px",
                      }}
                    />
                  </Link>
                </Box>

                <Box>
                  <Link
                    style={{
                      color: "#EB603A",
                      textDecoration: "none",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      flexDirection: "row",
                      gap: "0.5em",
                    }}
                    to="/faqs"
                  >
                    <LoginSSSIcon />
                    <Typography
                      sx={{
                        fontWeight: "500",
                        fontSize: "14px",
                      }}
                    >
                      {t("frequentlyAskedQuestions")}
                    </Typography>
                  </Link>
                </Box>
              </Typography>
            </Box>
          </Grid>
          <Grid
            className="loginSliderContainer"
            item
            xs={12}
            md={6}
            sx={{
              background: "url(/world-bg.jpg)",
              backgroundSize: "cover",
              backgroundPosition: "center",
              backgroundRepeat: "no-repeat",

              overflow: "hidden",
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                height: "100vh",
                overflow: "hidden",
              }}
            >
              <LoginSlider />
            </Box>
          </Grid>
        </Grid>
      </Container>
    </ThemeProvider>
  );
};

export default SignIn;
