import React, { useContext, useEffect } from "react";
import Button from "@mui/material/Button";
import Cookies from "js-cookie";
import axios from "axios";
import Xls from "../icons/Xls";
import { Box, CircularProgress } from "@mui/material";
import { FbmmFilterContext } from "../../context/FbmmFilterContext";
import Snackbar from "@mui/material/Snackbar";
import Alert from "@mui/material/Alert";
import { useTranslation } from "react-i18next";

function CircularIndeterminate() {
  return (
    <Box sx={{ display: "flex" }}>
      <CircularProgress
        sx={{
          color: "#282828",
          width: "14px",
          height: "14px",
          marginRight: "5px",
        }}
        color="inherit"
        size={20}
        thickness={5}
      />
    </Box>
  );
}
// :()
const DownloadFbmmExcel = ({ setSnackbarMessage, setOpenExcel }) => {
  const [isDownloading, setIsDownloading] = React.useState(false);
  const { fbmmFilters } = useContext(FbmmFilterContext);
  const { t } = useTranslation();
  let config = {
    method: "get",
    url: `${process.env.REACT_APP_BASE_URL}/Order/ExporttoExcelAllOrders?Pagination.Page=0&Pagination.Size=10`,
    responseType: "blob",
    headers: {
      Authorization: `Bearer ${Cookies.get("_auth")}`,
      "Content-Type":
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    },
  };
  useEffect(() => {
    if (fbmmFilters) {
      if (fbmmFilters.warehouse) {
        config.url = config.url + "&Warehouse=" + fbmmFilters.warehouse;
      }
      if (fbmmFilters.status) {
        config.url = config.url + "&Status=" + fbmmFilters.status;
      }
      if (fbmmFilters.query) {
        config.url = config.url + "&Query=" + fbmmFilters.query;
      }
      if (fbmmFilters.source) {
        config.url = config.url + "&Source=" + fbmmFilters.source;
      }
      if (fbmmFilters.updatedAt) {
        config.url = config.url + "&UpdatedAt=" + fbmmFilters.updatedAt;
      }
      if (fbmmFilters.startDate) {
        config.url = config.url + "&StartDate=" + fbmmFilters.startDate;
      }
      if (fbmmFilters.endDate) {
        config.url = config.url + "&EndDate=" + fbmmFilters.endDate;
      }
    }
  }, [fbmmFilters]);
  const handleDownloadClick = async () => {
    // Validation: The gap between two dates must be at most 30 days. And write message snack bar.

    const startDate = new Date(fbmmFilters.startDate);
    const endDate = new Date(fbmmFilters.endDate);
    const diffTime = Math.abs(endDate - startDate);
    const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));

    if (diffDays > 31) {
      setOpenExcel(true);
      setSnackbarMessage(
        "31 günlük tarih aralığında seçim yapmalısınız. Lütfen filtrelerden tarih aralığını değiştiriniz."
      );

      return;
    }

    if (fbmmFilters.startDate === null || fbmmFilters.endDate === null) {
      setOpenExcel(true);
      setSnackbarMessage("Lütfen filtrelerden tarih aralığını seçiniz");
      return;
    }

    if (fbmmFilters) {
      if (fbmmFilters.warehouse) {
        config.url = config.url + "&Warehouse=" + fbmmFilters.warehouse;
      }
      if (fbmmFilters.status) {
        config.url = config.url + "&Status=" + fbmmFilters.status;
      }
      if (fbmmFilters.query) {
        config.url = config.url + "&Query=" + fbmmFilters.query;
      }
      if (fbmmFilters.source) {
        config.url = config.url + "&Source=" + fbmmFilters.source;
      }
      if (fbmmFilters.updatedAt) {
        config.url = config.url + "&UpdatedAt=" + fbmmFilters.updatedAt;
      }
      if (fbmmFilters.startDate) {
        config.url = config.url + "&StartDate=" + fbmmFilters.startDate;
      }
      if (fbmmFilters.endDate) {
        config.url = config.url + "&EndDate=" + fbmmFilters.endDate;
      }
    }

    setIsDownloading(true);
    try {
      const response = await axios(config);
      if (response.status === 200) {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", `${t("orders")}.xlsx`); // İndirilen dosyanın adı
        document.body.appendChild(link);
        link.click();
      } else {
        setOpenExcel(true);
        setSnackbarMessage("İstek sırasında bir hata oluştu");
      }
    } catch (error) {
      setOpenExcel(true);
      setSnackbarMessage("İstek sırasında bir hata oluştu");
    }
    setIsDownloading(false);
  };

  return (
    <Box>
      <Button
        variant="text"
        onClick={handleDownloadClick}
        disabled={isDownloading}
        sx={{
          color: "#282828",
          fontSize: "14px",
          fontWeight: 500,
          display: "flex",
          alignItems: "center",
          textTransform: "capitalize",
          "&:hover": {
            backgroundColor: "transparent",
            color: "#282828",
          },
        }}
      >
        {isDownloading ? t("downloading") : t("orders")}
      </Button>
    </Box>
  );
};

export default DownloadFbmmExcel;
