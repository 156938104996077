import React from "react";

const FAQIconActive = () => {
  return (
    <svg
      width="36"
      height="36"
      viewBox="0 0 36 36"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="36" height="36" rx="5" fill="#FEF7F5" />
      <g clipPath="url(#clip0_3271_2762)">
        <path
          d="M27.1814 18.0008C27.1813 19.8163 26.6429 21.5909 25.6342 23.1005C24.6255 24.6102 23.1916 25.7869 21.5141 26.4817C19.8366 27.1766 17.9907 27.3584 16.2098 27.0042C14.429 26.6499 12.7932 25.7756 11.5092 24.4916C10.2253 23.2077 9.35095 21.5719 8.99672 19.7911C8.64249 18.0102 8.82429 16.1643 9.51915 14.4868C10.214 12.8092 11.3907 11.3754 12.9004 10.3667C14.4101 9.35796 16.1849 8.81954 18.0005 8.81946C20.4346 8.82213 22.7682 9.79026 24.4894 11.5115C26.2107 13.2327 27.1789 15.5666 27.1814 18.0008Z"
          fill="#EB603A"
          fillOpacity="0.3"
          stroke="#EB603A"
          strokeWidth="1.5"
        />
        <path
          d="M18.4609 20.7969H17.0078C17.013 20.2969 17.0573 19.888 17.1406 19.5703C17.2292 19.2474 17.3724 18.9531 17.5703 18.6875C17.7682 18.4219 18.0312 18.1198 18.3594 17.7812C18.599 17.5365 18.8177 17.3073 19.0156 17.0938C19.2188 16.875 19.3828 16.6406 19.5078 16.3906C19.6328 16.1354 19.6953 15.8307 19.6953 15.4766C19.6953 15.1172 19.6302 14.8073 19.5 14.5469C19.375 14.2865 19.1875 14.0859 18.9375 13.9453C18.6927 13.8047 18.388 13.7344 18.0234 13.7344C17.7214 13.7344 17.4349 13.7891 17.1641 13.8984C16.8932 14.0078 16.6745 14.1771 16.5078 14.4062C16.3411 14.6302 16.2552 14.9245 16.25 15.2891H14.8047C14.8151 14.7005 14.9609 14.1953 15.2422 13.7734C15.5286 13.3516 15.9141 13.0286 16.3984 12.8047C16.8828 12.5807 17.4245 12.4688 18.0234 12.4688C18.6849 12.4688 19.2474 12.5885 19.7109 12.8281C20.1797 13.0677 20.5365 13.4115 20.7812 13.8594C21.026 14.3021 21.1484 14.8281 21.1484 15.4375C21.1484 15.9062 21.0521 16.3385 20.8594 16.7344C20.6719 17.125 20.4297 17.4922 20.1328 17.8359C19.8359 18.1797 19.5208 18.5078 19.1875 18.8203C18.901 19.0859 18.7083 19.3854 18.6094 19.7188C18.5104 20.0521 18.4609 20.4115 18.4609 20.7969ZM16.9453 23.2734C16.9453 23.0391 17.0182 22.8411 17.1641 22.6797C17.3099 22.5182 17.5208 22.4375 17.7969 22.4375C18.0781 22.4375 18.2917 22.5182 18.4375 22.6797C18.5833 22.8411 18.6562 23.0391 18.6562 23.2734C18.6562 23.4974 18.5833 23.6901 18.4375 23.8516C18.2917 24.013 18.0781 24.0938 17.7969 24.0938C17.5208 24.0938 17.3099 24.013 17.1641 23.8516C17.0182 23.6901 16.9453 23.4974 16.9453 23.2734Z"
          fill="#EB603A"
        />
      </g>
      <defs>
        <clipPath id="clip0_3271_2762">
          <rect
            width="20"
            height="20"
            fill="white"
            transform="translate(8 8)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export default FAQIconActive;
