import React, { useState, useEffect, useContext } from "react";
import { Typography, Box, Divider, Popper, Paper } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { useTranslation } from "react-i18next";
import axios from "axios";
import Cookies from "js-cookie";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/styles";

const useStyles = makeStyles(() => ({
  root: {
    alignItems: "center",
    lineHeight: "24px",
    width: "100%",
    height: "100%",
    position: "relative",
    display: "flex",
    "& .cellValue": {
      whiteSpace: "nowrap",
      overflow: "hidden",
      textOverflow: "ellipsis",
    },
  },
}));

const LogsTable = () => {
  const [total, setTotal] = useState(0);
  const [error, setError] = useState(null);
  const { t } = useTranslation();
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(0);
  const [pageState, setPageState] = useState({
    isLoading: false,
    data: [],
    total: 0,
    page: 0,
    pageSize: 10,
  });

  let data = "";
  let config = {
    method: "get",
    maxBodyLength: Infinity,
    url: `${process.env.REACT_APP_BASE_URL}/Log?Pagination.Page=${pageState.page}&Pagination.Size=${pageState.pageSize}`,
    headers: {
      Authorization: `Bearer ${Cookies.get("_auth")}`,
    },
    data: data,
  };

  const fetchData = async () => {
    try {
      setPageState((old) => ({ ...old, isLoading: true }));
      const response = await axios.request(config);
      const responseDataItems = await response.data.result;
      const responseTotalItems = await response.data.totalCount;
      const newData = responseDataItems.map((item, index) => ({
        ...item,
        id: index + 1,
      }));
      setPageState((old) => ({
        ...old,
        isLoading: false,
        data: newData,
        total: responseTotalItems,
      }));
      // setData(newData);
      // setTotal(responseTotalItems);
    } catch (error) {
      setError(error);
    }
  };

  // useEffect for fetching data
  useEffect(() => {
    fetchData();
  }, [pageState.page, pageState.pageSize]);

  function isOverflown(element) {
    return (
      element.scrollHeight > element.clientHeight ||
      element.scrollWidth > element.clientWidth
    );
  }

  const GridCellExpand = React.memo(function GridCellExpand(props) {
    const { width, value } = props;
    const wrapper = React.useRef(null);
    const cellDiv = React.useRef(null);
    const cellValue = React.useRef(null);
    const [anchorEl, setAnchorEl] = React.useState(null);
    const classes = useStyles();
    const [showFullCell, setShowFullCell] = React.useState(false);
    const [showPopper, setShowPopper] = React.useState(false);

    const handleMouseEnter = () => {
      const isCurrentlyOverflown = isOverflown(cellValue.current);
      setShowPopper(isCurrentlyOverflown);
      setAnchorEl(cellDiv.current);
      setShowFullCell(true);
    };

    const handleMouseLeave = () => {
      setShowFullCell(false);
    };

    React.useEffect(() => {
      if (!showFullCell) {
        return undefined;
      }

      function handleKeyDown(nativeEvent) {
        // IE11, Edge (prior to using Bink?) use 'Esc'
        if (nativeEvent.key === "Escape" || nativeEvent.key === "Esc") {
          setShowFullCell(false);
        }
      }

      document.addEventListener("keydown", handleKeyDown);

      return () => {
        document.removeEventListener("keydown", handleKeyDown);
      };
    }, [setShowFullCell, showFullCell]);

    return (
      <div
        ref={wrapper}
        className={classes.root}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
      >
        <div
          ref={cellDiv}
          style={{
            height: 1,
            width,
            display: "block",
            position: "absolute",
            top: 0,
          }}
        />
        <div ref={cellValue} className="cellValue">
          {value}
        </div>
        {showPopper && (
          <Popper
            open={showFullCell && anchorEl !== null}
            anchorEl={anchorEl}
            style={{ width, marginLeft: -17 }}
          >
            <Paper
              elevation={1}
              style={{ minHeight: wrapper.current.offsetHeight - 3 }}
            >
              <Typography variant="body2" style={{ padding: 8 }}>
                {value}
              </Typography>
            </Paper>
          </Popper>
        )}
      </div>
    );
  });

  GridCellExpand.propTypes = {
    value: PropTypes.string.isRequired,
    width: PropTypes.number.isRequired,
  };

  function renderCellExpand(params) {
    return (
      <GridCellExpand
        value={params.value ? params.value.toString() : ""}
        width={params.colDef.computedWidth}
      />
    );
  }

  renderCellExpand.propTypes = {
    /**
     * The column of the row that the current cell belongs to.
     */
    colDef: PropTypes.object.isRequired,
    /**
     * The cell value, but if the column has valueGetter, use getValue.
     */
    value: PropTypes.oneOfType([
      PropTypes.instanceOf(Date),
      PropTypes.number,
      PropTypes.object,
      PropTypes.string,
      PropTypes.bool,
    ]),
  };

  return (
    <Box
      sx={{
        p: 2,
        border: "1px solid rgba(69, 56, 52, 0.15)",
        borderRadius: "2px",
      }}
    >
      <Typography variant="text" color="primary" sx={{ fontWeight: "bold" }}>
        {t("logList")}
      </Typography>

      {/* Data Table */}
      {/* <Divider /> */}
      <DataGrid
        sx={{
          borderRadius: "2px",
          marginTop: "2px",
        }}
        rows={pageState.data}
        columns={[
          {
            field: "Level",
            headerName: t("level"),
            valueGetter: (params) => params.row.level,

            renderCell: (params) => {
              if (params.row.level === "Warning") {
                return (
                  <Box
                    sx={{
                      color: "orange",
                      p: 3,
                    }}
                  >
                    {params.row.level}
                  </Box>
                );
              } else if (params.row.level === "Error") {
                return (
                  <Box
                    sx={{
                      color: "red",
                      p: 3,
                    }}
                  >
                    {params.row.level}
                  </Box>
                );
              } else {
                return (
                  <Box
                    sx={{
                      color: "green",
                      p: 1.5,
                    }}
                  >
                    {params.row.level}
                  </Box>
                );
              }
            },
            width: 120,
          },
          {
            field: "time_stamp",
            headerName: t("history"),
            width: 120,
            valueGetter: (params) =>
              new Date(params.row.time_stamp).toLocaleDateString("tr-TR") ===
              "Invalid Date"
                ? "YOK"
                : new Date(params.row.time_stamp).toLocaleDateString("tr-TR"),
          },
          {
            field: "user_data",
            headerName: t("userData"),
            width: 120,
            valueGetter: (params) =>
              params.row.user_data ? params.row.user_data : "Yok",
          },
          {
            field: "message",
            headerName: t("message"),
            renderCell: renderCellExpand,
            valueGetter: (params) => params.row.message,
            flex: 5,
          },
        ]}
        autoHeight
        showCellRightBorder={true}
        rowCount={pageState.total}
        defaultPageSize={[5]}
        rowsPerPageOptions={[5, 10, 20, 50]}
        pagination
        page={pageState.page}
        pageSize={pageState.pageSize}
        paginationMode="server"
        onPageChange={(newPage) => {
          setPageState((old) => ({ ...old, page: newPage }));
        }}
        onPageSizeChange={(newPageSize) =>
          setPageState((old) => ({ ...old, pageSize: newPageSize }))
        }
        disableSelectionOnClick
      />
    </Box>
  );
};

export default LogsTable;
