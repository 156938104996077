import React, { useContext } from "react";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { alpha, styled } from "@mui/material/styles";
import InputBase from "@mui/material/InputBase";
import { Typography, useTheme } from "@mui/material";

// import { ProductFilterContext } from "../../../context/ProductFilterContext";
import { FbmmFilterContext } from "../../../context/FbmmFilterContext";

const BootstrapInput = styled(InputBase)(({ theme }) => ({
  "label + &": {
    marginTop: theme.spacing(3),
    color: `${alpha(theme.palette.text.main, 0.25)} 0 0 0 0.2rem`,
  },
  "& .MuiInputBase-input": {
    borderRadius: 2,
    position: "relative",
    border: "1px solid",
    fontSize: 14,
    fontStyle: "italic",
    height: "12px",
    width: "100%",
    padding: "10px 12px",
    marginTop: "0.5rem",
    lineHeight: "12px",
    borderColor: theme.palette.primary.bordersColor,
    transition: theme.transitions.create([
      "border-color",
      "background-color",
      "box-shadow",
    ]),
    "&:focus": {
      boxShadow: `${alpha(theme.palette.primary.main, 0.25)} 0 0 0 0.2rem`,
      borderColor: theme.palette.primary.main,
      color: theme.palette.text.main,
    },
  },
  "& .MuiSelect-select": {
    minHeight: "12px!important",
    height: "12px!important",
  },
}));

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const CustomFbmmMultiSelect = ({
  placeholder,
  isRequired,
  options,
  className,
}) => {
  const { fbmmFilters, setFbmmFilters } = useContext(FbmmFilterContext);
  const theme = useTheme();

  const handleChange = event => {
    // setProductFilters(event.target.value)
    setFbmmFilters({ ...fbmmFilters, warehouse: event.target.value });
  };

  return (
    <FormControl sx={{ m: 0, flex: 1 }} size="small" className={className}>
      <Typography
        sx={{
          fontSize: "13px",
          fontWeight: "500",
          color: theme.palette.text.main,
          mt: "4px",
        }}
      >
        {placeholder}
        {isRequired && (
          <span
            style={{
              color: theme.palette.primary.primaryRed,
              marginLeft: "0.5rem",
            }}
          >
            *
          </span>
        )}
      </Typography>
      <Select
        labelId="demo-multiple-name-label"
        id="demo-multiple-name"
        multiple
        value={fbmmFilters.warehouse}
        onChange={handleChange}
        input={<BootstrapInput label="Name" />}
        MenuProps={MenuProps}
      >
        {options.map(opt => (
          <MenuItem key={opt.id} value={opt.id}>
            {opt.title}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default CustomFbmmMultiSelect;
