import React from "react";
import { useTheme } from "@mui/material";
import { Tooltip, Box } from "@mui/material";
import ProparsIcon from "../../components/icons/ProparsIcon";
function getSku(params) {
  const theme = useTheme();
  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "flex-start",
        gap: "0.5rem",
      }}
    >
      {params.row.proparsLiquidStockId > 0 && <ProparsIcon />}
      <Tooltip title={params.row.product.sku} placement="top">
        <a
          href={`/products/${params.row.id}`}
          style={{
            color: theme.palette.secondary.main,
            textDecoration: "none",
            fontWeight: "500",
          }}
        >
          {params.row.product.sku}
        </a>
      </Tooltip>
    </Box>
  );
}

export default getSku;
