import React from "react";

const PaymentsIcon = () => {
  return (
    <svg
      width="36"
      height="36"
      viewBox="0 0 36 36"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="36" height="36" rx="5" fill="#FAF8F8" />
      <g clipPath="url(#clip0_2214_3774)">
        <path
          d="M21.6674 21.6667C25.7175 21.6667 29.0007 18.3835 29.0007 14.3334C29.0007 10.2833 25.7175 7 21.6674 7C17.6172 7 14.334 10.2833 14.334 14.3334C14.334 18.3835 17.6172 21.6667 21.6674 21.6667Z"
          stroke="#453834"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M12.5 20.0457C12.5 20.3077 12.5786 20.5637 12.7255 20.7807C12.8724 20.9977 13.081 21.1657 13.3243 21.263L15.3424 22.0706C15.625 22.1844 15.8593 22.3929 16.005 22.6604C16.1507 22.928 16.1988 23.2379 16.1409 23.537C16.0831 23.8361 15.9231 24.1058 15.6882 24.2998C15.4532 24.4937 15.1582 24.6 14.8535 24.6002H12.8667"
          stroke="#453834"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M14.334 26.0668V24.6001"
          stroke="#453834"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M11.4307 14.9366C10.3304 15.4098 9.36271 16.1452 8.61224 17.0786C7.86177 18.012 7.35136 19.1151 7.1256 20.2913C6.89984 21.4675 6.96561 22.6812 7.31716 23.8261C7.66872 24.9711 8.29535 26.0125 9.1423 26.8593C9.98924 27.7062 11.0307 28.3327 12.1757 28.6842C13.3207 29.0356 14.5343 29.1013 15.7105 28.8754C16.8867 28.6495 17.9897 28.139 18.9231 27.3884C19.8564 26.6378 20.5918 25.6701 21.0648 24.5698"
          stroke="#453834"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M23.1331 11.4H21.1453C20.8406 11.4002 20.5455 11.5064 20.3106 11.7004C20.0757 11.8944 19.9156 12.1641 19.8578 12.4632C19.8 12.7623 19.8481 13.0722 19.9938 13.3398C20.1395 13.6073 20.3737 13.8158 20.6564 13.9295L22.6745 14.7372C22.9571 14.8509 23.1914 15.0594 23.3371 15.327C23.4828 15.5945 23.5309 15.9044 23.473 16.2035C23.4152 16.5027 23.2551 16.7723 23.0202 16.9663C22.7853 17.1603 22.4903 17.2665 22.1856 17.2667H20.1997"
          stroke="#453834"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M21.666 11.4V9.93335"
          stroke="#453834"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M21.666 18.7334V17.2667"
          stroke="#453834"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_2214_3774">
          <rect
            width="24"
            height="24"
            fill="white"
            transform="translate(6 6)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export default PaymentsIcon;
