import React, { useState } from "react";
import {
  Box,
  Grid,
  Stack,
  Typography,
  Button,
  FormControl,
  FormGroup,
  useTheme,
  Container,
  OutlinedInput,
  Alert,
} from "@mui/material";
import axios, { AxiosError } from "axios";
import CustomLabel from "../components/forms/form-elements/CustomLabel";
import CheckIcon from "@mui/icons-material/Check";
import { Form } from "react-bootstrap";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import InputAdornment from "@mui/material/InputAdornment";
import IconButton from "@mui/material/IconButton";
import { useLocation, useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { useSignIn } from "react-auth-kit";
import { useTranslation } from "react-i18next";

const UpdatePassword = () => {
  const params = window.location.search;
  const urlParams = new URLSearchParams(params);
  const navigate = useNavigate();
  const theme = useTheme();
  const { t } = useTranslation();
  const [dots, setDots] = useState(1);
  const [activeStep, setActiveStep] = useState(0);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [skipped, setSkipped] = useState(new Set());
  const [isLoaded, setIsLoaded] = useState(false);
  const [error, setError] = useState("");
  const [password, setPassword] = useState("");
  const [passwordConfirm, setPasswordConfirm] = useState("");
  const [isSuccess, setIsSuccess] = useState(false); // [1
  const [requestError, setRequestError] = useState(null);
  const [showPassword, setShowPassword] = React.useState(false);
  const [updateErrors, setUpdateErrors] = useState({
    password: "",
    passwordConfirm: "",
  });
  const handleClickShowPassword = () => setShowPassword(show => !show);

  const handleMouseDownPassword = event => {
    event.preventDefault();
  };

  const getLoadingText = () => {
    const dotString = ".".repeat(dots);
    return `${dotString}`;
  };

  const passwordChange = e => {
    setPassword(e.target.value);
    validateField("password", e.target.value);
  };

  const passwordConfirmChange = e => {
    setPasswordConfirm(e.target.value);
    validateField("passwordConfirm", e.target.value);
  };

  const validateField = (fieldName, value) => {
    const newErrors = { ...updateErrors };

    if (fieldName === "password") {
      const minLength = 8;
      const hasUpperCase = /[A-Z]/.test(value);
      const hasLowerCase = /[a-z]/.test(value);
      const hasDigit = /\d/.test(value);

      if (value.length < minLength) {
        newErrors.password = t("passwordMustBeAtLeastEightcharacters");
      } else if (!hasUpperCase) {
        newErrors.password = t("thePasswordMustContainAtLeastOneUppercaseLetter");
      } else if (!hasLowerCase) {
        newErrors.password = t("thePasswordMustContainAtLeastOneLowercaseLetter");
      } else if (!hasDigit) {
        newErrors.password = t("thePasswordMustContainAtLeastOneNumber");
      } else {
        newErrors.password = "";
      }
    }

    if (fieldName === "passwordConfirm") {
      newErrors.passwordConfirm =
        value !== password ? t("passwordsDoNotMatch") : "";
    }

    setUpdateErrors(newErrors);
  };

  const handlePaste = event => {
    event.preventDefault(); // YapÄ±ÅtÄ±rma iÅlemini engelle
    alert("Copy-paste yapılamaz.");
  };

  const handleSubmit = async e => {
    e.preventDefault();
    setIsLoaded(false);
    const response = await axios.post(
      `${process.env.REACT_APP_BASE_URL}/controller/update-password`,
      {
        password: password,
        passwordConfirm: passwordConfirm,
        resetToken: urlParams.get("token"),
        userId: urlParams.get("userId"),
      }
    );
    if (response.status === 200) {
      setIsSuccess(true);
      setIsSubmitted(true);
      setTimeout(() => {
        navigate("/login");
      }, 1000);
    } else {
      console.log("error");
    }
  };

  return (
    <Container
      component="main"
      maxWidth="xs"
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100vh",
        overflow: "auto",
      }}
    >
      <Form
        method="post"
        onSubmit={handleSubmit}
        style={{ width: "100%", background: "#fff" }}
      >
        <Box
          sx={{
            mb: 1,
            pb: 1,
            pt: 0,
            "& > :not(style)": { m: 1 },
          }}
        >
          <Box
            sx={{
              justifyContent: "center",
              display: "flex",
              pt: 6,
              pb: 1,
              backgroundColor: "#fff",
            }}
          >
            <img src="/LogoRegister.png" width="230px" />
          </Box>
          <Box sx={{ backgroundColor: "#fff" }}>
            {isSubmitted === false ? (
              <Box
                sx={{
                  backgroundColor: "#fff",
                  p: 2,
                  paddingBottom: "0px!important",
                  "& > :not(style)": { m: 1 },
                }}
                noValidate
                autoComplete="off"
              >
                <Box sx={{ background: "#F7F6F5", mb: "10px!important" }}>
                  <Typography variant="body2" sx={{ p: 2, fontSize: "13px" }}>
                    {t("pleaseSetYourNewPasswordAccordingToTheFollowingRules")}:
                    <ul style={{ paddingInlineStart: "10px" }}>
                      <li>{t("passwordMustBeAtLeastEightcharacters")}</li>
                      <li>{t("yourPasswordMustContainAtLeastOneLetterAndOneNumber")}</li>
                      <li>
                        {t("yourPasswordMustContainAtLeastOneUppercaseAndOneLowercaseLetter")}
                      </li>
                    </ul>
                  </Typography>
                </Box>
                <FormGroup>
                  <Grid container spacing={2} sx={{ mb: 1 }}>
                    <Grid item xs={12} sm={12}>
                      <CustomLabel title={t("newPassword")} />
                      <FormControl variant="filled" sx={{ width: "100%" }}>
                        <OutlinedInput
                          sx={{
                            height: "2.5em",
                            height: "2.5em",
                            borderColor: theme.palette.primary.bordersColor,
                            fontSize: 14,
                          }}
                          id="filled-adornment-password"
                          value={password}
                          onChange={passwordChange}
                          type={showPassword ? "text" : "password"}
                          endAdornment={
                            <InputAdornment position="end">
                              <IconButton
                                aria-label="toggle password visibility"
                                onClick={handleClickShowPassword}
                                onMouseDown={handleMouseDownPassword}
                                edge="end"
                              >
                                {showPassword ? (
                                  <VisibilityOff />
                                ) : (
                                  <Visibility />
                                )}
                              </IconButton>
                            </InputAdornment>
                          }
                        />
                        {updateErrors.password && (
                          <span className="error-message">
                            {updateErrors.password}
                          </span>
                        )}
                      </FormControl>
                      <CustomLabel title={t("newPasswordRepeat")} />
                      <FormControl variant="filled" sx={{ width: "100%" }}>
                        <OutlinedInput
                          sx={{
                            height: "2.5em",
                            height: "2.5em",
                            borderColor: theme.palette.primary.bordersColor,
                            fontSize: 14,
                          }}
                          id="filled-adornment-re-password"
                          value={passwordConfirm}
                          onChange={passwordConfirmChange}
                          onPaste={handlePaste}
                          type={showPassword ? "text" : "password"}
                          endAdornment={
                            <InputAdornment position="end">
                              <IconButton
                                aria-label="toggle password visibility"
                                onClick={handleClickShowPassword}
                                onMouseDown={handleMouseDownPassword}
                                edge="end"
                              >
                                {showPassword ? (
                                  <VisibilityOff />
                                ) : (
                                  <Visibility />
                                )}
                              </IconButton>
                            </InputAdornment>
                          }
                        />
                        {updateErrors.passwordConfirm && (
                          <span className="error-message">
                            {updateErrors.passwordConfirm}
                          </span>
                        )}
                      </FormControl>
                    </Grid>
                  </Grid>
                </FormGroup>
              </Box>
            ) : (
              ""
            )}

            {isSuccess === true && isSubmitted === true ? (
              <Box
                sx={{ pb: 0, mt: 1.5, ml: "1.5em", mr: "1.5em  " }}
                display="block"
              >
                <Alert
                  severity="success"
                  sx={{
                    border: "2px solid",
                    borderColor: theme.palette.success.main,
                    fontSize: "14px",
                    fontWeight: "500",
                  }}
                >
                  {t("yourPasswordHasBeenSuccessfullyUpdated")}
                  {getLoadingText()}
                </Alert>
              </Box>
            ) : (
              ""
            )}
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                pb: 3,
                mt: 3,
                ml: "1.5em",
                mr: "1.5em  ",
              }}
            >
              <Box sx={{ flex: "1 1 auto" }} />
              {isSubmitted === false ? (
                <Button
                  variant="contained"
                  type="submit"
                  className="filterApply"
                  disabled={
                    password === "" ||
                    updateErrors.password ||
                    passwordConfirm === "" ||
                    updateErrors.passwordConfirm ||
                    setIsLoaded === true
                      ? true
                      : false
                  }
                >
                 {t("update")}
                </Button>
              ) : (
                ""
              )}
            </Box>
          </Box>
        </Box>
      </Form>
    </Container>
  );
};

export default UpdatePassword;
