import React from "react";

const LogsIcon = () => {
  return (
    <svg
      width="36"
      height="36"
      viewBox="0 0 36 36"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="36" height="36" rx="5" fill="#FAF8F8" />
      <path
        d="M7 26V25C7 23.1435 7.7375 21.363 9.05025 20.0503C10.363 18.7375 12.1435 18 14 18C15.8565 18 17.637 18.7375 18.9497 20.0503C20.2625 21.363 21 23.1435 21 25V26"
        stroke="#453834"
        stroke-width="1.5"
        stroke-linecap="round"
      />
      <path
        d="M20.4648 16.5377C21.4025 15.5531 22.6743 15 24.0004 15C25.3265 15 26.5982 15.5531 27.5359 16.5377C28.4736 17.5223 29.0004 18.8576 29.0004 20.25V21"
        stroke="#453834"
        stroke-width="1.5"
        stroke-linecap="round"
      />
      <path
        d="M14 18C15.0609 18 16.0783 17.5786 16.8284 16.8284C17.5786 16.0783 18 15.0609 18 14C18 12.9391 17.5786 11.9217 16.8284 11.1716C16.0783 10.4214 15.0609 10 14 10C12.9391 10 11.9217 10.4214 11.1716 11.1716C10.4214 11.9217 10 12.9391 10 14C10 15.0609 10.4214 16.0783 11.1716 16.8284C11.9217 17.5786 12.9391 18 14 18ZM24 15C24.7956 15 25.5587 14.6839 26.1213 14.1213C26.6839 13.5587 27 12.7956 27 12C27 11.2044 26.6839 10.4413 26.1213 9.87868C25.5587 9.31607 24.7956 9 24 9C23.2044 9 22.4413 9.31607 21.8787 9.87868C21.3161 10.4413 21 11.2044 21 12C21 12.7956 21.3161 13.5587 21.8787 14.1213C22.4413 14.6839 23.2044 15 24 15Z"
        stroke="#453834"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};

export default LogsIcon;
