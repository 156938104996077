import React, { useContext, useEffect } from "react";
import Button from "@mui/material/Button";
import Cookies from "js-cookie";
import axios from "axios";
import Xls from "../icons/Xls";
import { Box, CircularProgress } from "@mui/material";
import { ProductFilterContext } from "../../context/ProductFilterContext";
import { useTranslation } from "react-i18next";

function CircularIndeterminate() {
  return (
    <Box sx={{ display: "flex" }}>
      <CircularProgress
        sx={{
          color: "#282828",
          width: "14px",
          height: "14px",
          marginRight: "5px",
        }}
        color="inherit"
        size={20}
        thickness={5}
      />
    </Box>
  );
}

const DownloadExcel = () => {
  const [isDownloading, setIsDownloading] = React.useState(false);
  const { productFilters } = useContext(ProductFilterContext);
  const { t } = useTranslation();

  let config = {
    method: "get",
    url: `${process.env.REACT_APP_BASE_URL}/ProductVariant/ExporttoExcelAllVariants?Pagination.Page=0&Pagination.Size=10`,
    responseType: "blob",
    headers: {
      Authorization: `Bearer ${Cookies.get("_auth")}`,
      "Content-Type":
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    },
  };

  useEffect(() => {
    if (productFilters) {
      if (productFilters.sku) {
        config.url = config.url + "&Sku=" + productFilters.sku;
      }
      if (productFilters.barcode) {
        config.url = config.url + "&Query=" + productFilters.barcode;
      }
      if (productFilters.status) {
        config.url = config.url + "&Status=" + productFilters.status;
      }
      if (productFilters.category) {
        config.url = config.url + "&CategoryList=" + productFilters.category;
      }
      if (productFilters.warehouse) {
        config.url = config.url + "&WarehouseList=" + productFilters.warehouse;
      }
      if (productFilters.search) {
        config.url = config.url + "&Query=" + productFilters.search;
      }
      if (productFilters.brandList) {
        config.url = config.url + "&BrandList=" + productFilters.brandList;
      }
      if (productFilters.merchant) {
        config.url = config.url + "&Merchant=" + productFilters.merchant;
      }
      if (productFilters.updatedAt) {
        config.url = config.url + "&UpdatedAt=" + productFilters.updatedAt;
      }
      if (productFilters.updatedAtEnd) {
        config.url =
          config.url + "&UpdatedAtEnd=" + productFilters.updatedAtEnd;
      }
    }
  }, [productFilters]);

  const handleDownloadClick = async () => {
    if (productFilters) {
      if (productFilters.sku) {
        config.url = config.url + "&Sku=" + productFilters.sku;
      }
      if (productFilters.barcode) {
        config.url = config.url + "&Query=" + productFilters.barcode;
      }
      if (productFilters.status) {
        config.url = config.url + "&Status=" + productFilters.status;
      }
      if (productFilters.category) {
        config.url = config.url + "&CategoryList=" + productFilters.category;
      }
      if (productFilters.warehouse) {
        config.url = config.url + "&WarehouseList=" + productFilters.warehouse;
      }
      if (productFilters.search) {
        config.url = config.url + "&Query=" + productFilters.search;
      }
      if (productFilters.brandList) {
        config.url = config.url + "&BrandList=" + productFilters.brandList;
      }
      if (productFilters.merchant) {
        config.url = config.url + "&Merchant=" + productFilters.merchant;
      }
      if (productFilters.updatedAt) {
        config.url = config.url + "&UpdatedAt=" + productFilters.updatedAt;
      }
      if (productFilters.updatedAtEnd) {
        config.url =
          config.url + "&UpdatedAtEnd=" + productFilters.updatedAtEnd;
      }
    }
    setIsDownloading(true);
    try {
      const response = await axios(config);
      if (response.status === 200) {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", "dosya.xlsx"); // İndirilen dosyanın adı
        document.body.appendChild(link);
        link.click();
      } else {
        console.error("Excel dosyası indirilemedi.");
      }
    } catch (error) {
      console.error("İstek sırasında bir hata oluştu: ", error);
    }
    setIsDownloading(false);
  };

  return (
    <div>
      <Button
        className="filterReset"
        startIcon={isDownloading ? <CircularIndeterminate /> : <Xls />}
        onClick={handleDownloadClick}
        variant="contained"
        sx={{
          boxShadow: "none",
          border: "1px solid #45383426",
          width: "140px",
        }}
        disabled={isDownloading}
      >
        {isDownloading ? "İndiriliyor" : t("export")}
      </Button>
    </div>
  );
};

export default DownloadExcel;
