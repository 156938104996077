import Cookies from "js-cookie";
import React, { useEffect, useState } from "react";

// Components
import { Modal } from "../common";
import { Box, Button, Typography, useTheme } from "@mui/material";

// Utils
import { createMerchantPayment, postIyzipayCheckoutDetail } from "../api";
import axios from "axios";
import { useAuthUser } from "react-auth-kit";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

const IyzicoResult = () => {
  const auth = useAuthUser();
  const merchantId = auth().merchantId;
  const theme = useTheme();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [iyzicoResult, setiyzicoResult] = useState({
    message: "",
    success: null,
  });


  useEffect(() => {
    const query = new URLSearchParams(window.location.search)
    const iyzicoPaymentData = query.get("result");

    if (!iyzicoPaymentData) {
      navigate("/payments");
      return;
    }

    if (iyzicoPaymentData == "success") {
      iyzicoResult.success = true;
      iyzicoResult.message = "Ödemeniz tamamlandı."
      const paymentData = Cookies.get("iyzicoPayment");
      const { token, description, conversationId, amount } =
      JSON.parse(paymentData);

      
        createMerchantPayment({
          id: merchantId,
          body: {
            amount,
            description,
            merchantId,
            currency: "usd",
            paymentStatus: true,
            yearly: false,
          },
        });
        
      setIsModalOpen(true);

    } else {
      iyzicoResult.success = false;
      iyzicoResult.message = "Ödemeniz sırasında hata oluştu, tekrar deneyiniz."
      setIsModalOpen(true);
    }



    //Cookies.remove("iyzicoPayment");

  }, []);

  const onModalClose = () => {
    navigate("/payments");
    setIsModalOpen(false);
  };

  return (
    <>
      <Modal
        open={isModalOpen}
        onClose={onModalClose}
        title={t("paymentResult")}
      >
        <Box
          sx={{
            mt: 0.5,
            fontWeight: "700",
          }}
        >
          <Typography
            variant="body1"
            gutterBottom
            style={{ color: `${iyzicoResult.success ? "green" : "red"}` }}
          >
            {iyzicoResult.message}
          </Typography>
          <Button
            style={{
              marginTop: "10px",
              border: `2px solid ${theme.palette.primary.main}`,
            }}
            onClick={onModalClose}
          >
            {t("ok")}
          </Button>
        </Box>
      </Modal>
    </>
  );
};
export default IyzicoResult;
