import React, { useContext } from "react";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { alpha, styled } from "@mui/material/styles";
import InputBase from "@mui/material/InputBase";
import { Typography, useTheme, ListItemText, Checkbox } from "@mui/material";
import { StockFilterContext } from "../../../context/StockFilterContext";
import { useTranslation } from "react-i18next";

const BootstrapSelect = styled(InputBase)(({ theme }) => ({
  "label + &": {
    marginTop: theme.spacing(3),
    color: `${alpha(theme.palette.text.main, 0.25)} 0 0 0 0.2rem`,
  },
  "& .MuiInputBase-input": {
    borderRadius: 2,
    position: "relative",
    border: "1px solid",
    fontSize: 14,
    fontStyle: "italic",
    height: "4px!important",
    width: "100%",
    padding: "10px 12px",
    lineHeight: "1",
    marginTop: "0.5rem",
    borderColor: theme.palette.primary.bordersColor,
    transition: theme.transitions.create([
      "border-color",
      "background-color",
      "box-shadow",
    ]),
    minHeight: " 0.8rem!important",
    "&:focus": {
      boxShadow: `${alpha(theme.palette.primary.main, 0.25)} 0 0 0 0.2rem`,
      borderColor: theme.palette.primary.main,
      color: theme.palette.text.main,
    },
  },
}));

const CustomStockMultiSelect = ({
  placeholder,
  isRequired,
  options,
  className,
}) => {
  const { stockFilters, setStockFilters } = useContext(StockFilterContext);
  const theme = useTheme();
  const { t } = useTranslation();
  const handleChange = event => {
    // setProductFilters(event.target.value)
    setStockFilters({ ...stockFilters, category: event.target.value });
  };

  return (
    <FormControl sx={{ m: 0, flex: 1 }} size="small" className={className}>
      <Typography
        sx={{
          fontSize: "13px",
          fontWeight: "500",
          color: theme.palette.text.main,
          mt: "4px",
        }}
      >
        {placeholder}
        {isRequired && (
          <span
            style={{
              color: theme.palette.primary.primaryRed,
              marginLeft: "0.5rem",
            }}
          >
            *
          </span>
        )}
      </Typography>
      {/* <Select
        labelId="demo-multiple-name-label"
        id="demo-multiple-name"
        multiple
        value={productFilters.category}
        onChange={handleChange}
        input={<BootstrapInput label="Name" />}
        MenuProps={MenuProps}
      >
        {options.map((opt) => (
          <MenuItem key={opt.id} value={opt.id}>
            {opt.title}
          </MenuItem>
        ))}
      </Select> */}
      <Select
        labelId="demo-multiple-checkbox-label"
        id="demo-multiple-checkbox"
        multiple
        displayEmpty
        value={stockFilters.category}
        input={<BootstrapSelect />}
        onChange={handleChange}
        renderValue={selected => (
          <div>
            {selected.length === 0 ? (
              <em
                style={{
                  color: "currentColor",
                  opacity: "0.42",
                }}
              >
               {t("selectCategory")}
              </em>
            ) : (
              selected.map((id, index) => {
                const selectedItem = options.find(item => item.id === id);
                return (
                  <span key={selectedItem.id}>
                    {selectedItem.title}
                    {index !== selected.length - 1 ? ", " : ""}
                  </span>
                );
              })
            )}
          </div>
        )}
        // Eğer MenuItem içinde Checkbox kullanıyorsanız, bu kısımda Checkbox yer almalıdır
        // Checkbox checked özelliğini category içindeki değerlere göre ayarlamalısınız
      >
        {options.map(opt => (
          <MenuItem key={opt.id} value={opt.id}>
            <Checkbox
              size="small"
              checked={stockFilters.category.includes(opt.id)}
            />
            <ListItemText primary={opt.title} />
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default CustomStockMultiSelect;
