import React from "react";

const AccountManagerIcon = () => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0.800781 11.3601C0.801268 10.6459 0.978729 9.94301 1.3173 9.31418C1.65587 8.68535 2.14499 8.15023 2.74094 7.75665C3.33688 7.36307 4.02107 7.12331 4.73233 7.0588C5.44359 6.9943 6.15975 7.10706 6.81678 7.387"
        stroke="#453834"
        strokeWidth="1.2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M2.48047 3.44005C2.48047 4.14022 2.75861 4.81171 3.25371 5.30681C3.7488 5.80191 4.4203 6.08005 5.12047 6.08005C5.82064 6.08005 6.49213 5.80191 6.98723 5.30681C7.48233 4.81171 7.76047 4.14022 7.76047 3.44005C7.76047 2.73988 7.48233 2.06838 6.98723 1.57329C6.49213 1.07819 5.82064 0.800049 5.12047 0.800049C4.4203 0.800049 3.7488 1.07819 3.25371 1.57329C2.75861 2.06838 2.48047 2.73988 2.48047 3.44005Z"
        stroke="#453834"
        strokeWidth="1.2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <g clipPath="url(#clip0_2882_2735)">
        <path
          d="M13.9556 10.8636C13.9556 12.656 12.6404 14.3682 10.836 14.8671C10.7979 14.8776 10.7576 14.8776 10.7196 14.8671C8.91513 14.3682 7.6 12.656 7.6 10.8636V9.21681C7.6 9.05438 7.69822 8.90809 7.84855 8.84659L10.6263 7.71022C10.7234 7.67052 10.8322 7.67052 10.9292 7.71022L13.707 8.84659C13.8573 8.90809 13.9556 9.05438 13.9556 9.21681V10.8636Z"
          stroke="#453834"
          strokeWidth="1.2"
        />
        <path
          d="M9.80516 11.2714C9.82289 11.2896 9.83094 11.3151 9.82685 11.3402L9.63684 12.5041C9.62608 12.57 9.69604 12.6193 9.75449 12.587L10.7389 12.043C10.7629 12.0297 10.7922 12.0297 10.8163 12.043L11.8006 12.587C11.8591 12.6193 11.929 12.57 11.9183 12.5041L11.7283 11.3402C11.7242 11.3151 11.7322 11.2896 11.75 11.2714L12.5578 10.4434C12.6032 10.3968 12.5769 10.3183 12.5126 10.3084L11.4036 10.1389C11.3773 10.1349 11.3546 10.118 11.3433 10.0938L10.85 9.04315C10.8212 8.98182 10.7339 8.98182 10.7051 9.04315L10.2118 10.0938C10.2005 10.118 10.1779 10.1349 10.1515 10.1389L9.04254 10.3084C8.97818 10.3183 8.9519 10.3968 8.99737 10.4434L9.80516 11.2714Z"
          stroke="#453834"
          strokeWidth="0.6"
        />
      </g>
      <defs>
        <clipPath id="clip0_2882_2735">
          <rect
            width="7.55556"
            height="8.5"
            fill="white"
            transform="translate(7 7)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export default AccountManagerIcon;
