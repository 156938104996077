import React, { useState, useContext } from "react";
import {
  Box,
  IconButton,
  useTheme,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  MenuItem,
  Select,
  FormControl,
  Typography,
  Menu,
  ListItemText,
} from "@mui/material";
import InputBase from "@mui/material/InputBase";
import SearchIcon from "../icons/SearchIcon";
import { FbmmFilterContext } from "../../context/FbmmFilterContext";
import DownloadFbmmExcel from "./DownloadFbmmExcel";
import DownloadFbmmExcelVineOrder from "./DownloadFbmmExcelVineOrder";
import KuryeIcon from "../icons/KuryeIcon";
import axios from "axios";
import Cookies from "js-cookie";
import { useAuthUser } from "react-auth-kit";
import CloseIcon from "@mui/icons-material/Close";
import Check from "@mui/icons-material/Check";
import Xls from "../icons/Xls";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { useTranslation } from "react-i18next";
import Snackbar from "@mui/material/Snackbar";
import Alert from "@mui/material/Alert";

const FbmmToggler = ({ options, placeholder, selectedProducts }) => {
  const theme = useTheme();
  const auth = useAuthUser();
  const merchantId = auth().merchantId;
  const [successDialog, setSuccessDialog] = useState(false);
  const [errorDialog, setErrorDialog] = useState(false);
  const [showDialog, setShowDialog] = useState(false);
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [sendingEmail, setSendingEmail] = useState(false);
  const [fbmmSearch, setFbmmSearch] = useState("");
  const { fbmmFilters, setFbmmFilters } = useContext(FbmmFilterContext);
  const [timer, setTimer] = useState(5);
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const [snackbarMessage, setSnackbarMessage] = React.useState("");
  const [openExcel, setOpenExcel] = React.useState(false);
  const { t } = useTranslation();

  const handleCloseExcel = () => {
    setOpenExcel(false);
  };

  const tick = () => {
    const interval = setInterval(() => {
      setTimer((timer) => timer - 1);
    }, 1000);
    setTimeout(() => {
      clearInterval(interval);
      setShowDialog(false);
      setSuccessDialog(false);
      setErrorDialog(false);
      setTimer(5);
    }, 5000);
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    setFbmmFilters({ ...fbmmFilters, search: fbmmSearch });
  };

  const handleMenuItemClick = (event, index, id) => {
    setSelectedIndex(index);
    setFbmmFilters({ ...fbmmFilters, status: id });
  };

  const handleChange = (event) => {
    setSelectedIndex(event.target.value);
    setFbmmFilters({ ...fbmmFilters, status: event.target.value });
  };

  let config = {
    method: "post",
    maxBodyLength: Infinity,
    url: `${process.env.REACT_APP_BASE_URL}/Order/CallCourierForOrder?id=${merchantId}`,
    headers: {
      Authorization: `Bearer ${Cookies.get("_auth")}`,
      "Content-Type": "application/json-patch+json",
    },
    data: {
      orders: selectedProducts,
    },
  };

  const handleSendEmail = () => {
    setSendingEmail(true);
    axios
      .request(config)
      .then((response) => {
        setSendingEmail(false);
        setSuccessDialog(true);
        setShowDialog(true);
        tick();
      })
      .catch((error) => {
        setSendingEmail(false);
        setErrorDialog(true);
        setShowDialog(true);
        tick();
      });
  };

  const calculateTotalCount = (options) => {
    return options.reduce((total, option) => total + option.count, 0);
  };

  const handleSearchChange = (event) => {
    if (
      (event.nativeEvent.inputType === "deleteContentBackward" &&
        fbmmSearch.length === 1) ||
      event.nativeEvent.inputType === "deleteSoftLineBackward" ||
      event.nativeEvent.inputType === "deleteByCut"
    ) {
      setFbmmFilters({ ...fbmmFilters, search: "" });
    }

    setFbmmSearch(event.target.value);
  };

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "space-between",
        alignItems: "flex-start",
        flexDirection: "column",
      }}
    >
      <Box>
        <Typography
          variant="h6"
          sx={{
            ml: 1,
            fontSize: "16px",
            fontWeight: 500,
          }}
          color="primary"
          component="div"
        >
          {t("status")}:
        </Typography>
      </Box>
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "space-between",
          width: "100%",
          height: "100%",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "flex-start",
            alignItems: "center",
            gap: "10px",
          }}
        >
          <Box>
            <FormControl sx={{ m: 1, minWidth: 120 }}>
              <Select
                value={selectedIndex}
                onChange={handleChange}
                displayEmpty
                size="small"
                sx={{
                  width: "240px",
                  borderRadius: "0px",
                  "& .MuiSelect-select": {
                    borderRadius: "0px",
                    display: "flex!important",
                    justifyContent: "space-between!important",
                  },
                  "& .MuiSelect-icon": {
                    top: "unset",
                  },
                }}
              >
                <MenuItem
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                  }}
                  value={0}
                >
                  <span>{t("all")}</span>
                  <span>
                    {calculateTotalCount(options) === 0
                      ? "0"
                      : calculateTotalCount(options)}
                  </span>
                </MenuItem>
                {options.map((option, index) => (
                  <MenuItem
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                    }}
                    key={index}
                    value={option.id}
                    onClick={(event) =>
                      handleMenuItemClick(event, index, option.id)
                    }
                  >
                    <span>{option.title} </span> <span>{option.count}</span>
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Box>
          <Box
            display="flex"
            sx={{
              border: 1,
              ml: 2,
              borderColor: theme.palette.primary.bordersColor,
              borderRadius: "2px",
              height: "40px",
            }}
          >
            <IconButton
              onClick={handleSubmit}
              type="button"
              size="small"
              sx={{ ml: 1, p: 0 }}
            >
              <SearchIcon />
            </IconButton>
            <InputBase
              sx={{
                flex: 2,
                p: 0,
                m: 0,
                ml: 1,
                fontStyle: "italic",
                fontSize: "14px!important",
              }}
              placeholder={placeholder}
              size="small"
              className="search"
              value={fbmmSearch}
              onChange={handleSearchChange}
              onKeyPress={(event) => {
                if (event.key === "Enter") {
                  setFbmmFilters({ ...fbmmFilters, search: fbmmSearch });
                }
              }}
            />
          </Box>
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "flex-end",
            alignItems: "center",
            gap: "10px",
          }}
        >
          <Button
            className="filterReset"
            startIcon={sendingEmail ? null : <KuryeIcon />}
            onClick={handleSendEmail}
            disabled={sendingEmail || selectedProducts.length === 0}
            variant="contained"
            sx={{
              boxShadow: "none",
              border: "1px solid #45383426",
              width: selectedProducts.length > 0 ? "220px" : "140px",
              cursor: selectedProducts.length > 0 ? "pointer" : "not-allowed",
            }}
          >
            {sendingEmail
              ? "İşleniyor..."
              : selectedProducts.length > 0
              ? t("callCourier") +
                " " +
                "(" +
                selectedProducts.length +
                ` ${t("order")})`
              : t("callCourier")}
          </Button>

          <Box>
            <Snackbar
              anchorOrigin={{ vertical: "top", horizontal: "center" }}
              open={openExcel}
              autoHideDuration={6000}
              onClose={handleCloseExcel}
            >
              <Alert
                onClose={handleCloseExcel}
                severity="error"
                variant="filled"
                sx={{ width: "100%" }}
              >
                {snackbarMessage}
              </Alert>
            </Snackbar>
          </Box>
          <Button
            id="basic-button"
            startIcon={<Xls />}
            aria-controls={open ? "basic-menu" : undefined}
            aria-haspopup="true"
            aria-expanded={open ? "true" : undefined}
            onClick={handleClick}
            endIcon={<KeyboardArrowDownIcon />}
            className="filterReset"
            variant="contained"
          >
            {t("export")}
          </Button>
          <Menu
            id="basic-menu"
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            MenuListProps={{
              "aria-labelledby": "basic-button",
            }}
          >
            <MenuItem
              onClick={handleClose}
              sx={{
                color: `${theme.palette.primary.fontColor}!important`,
                pl: 2,
                textTransform: "capitalize",
                border: "none",
                borderRadius: 0,
              }}
            >
              <ListItemText>
                <DownloadFbmmExcel
                  setOpenExcel={setOpenExcel}
                  setSnackbarMessage={setSnackbarMessage}
                />
              </ListItemText>
            </MenuItem>
            <MenuItem
              onClick={handleClose}
              sx={{
                color: `${theme.palette.primary.fontColor}!important`,
                pl: 2,
                textTransform: "capitalize",
                border: "none",
                borderRadius: 0,
              }}
            >
              <ListItemText>
                <DownloadFbmmExcelVineOrder
                  setOpenExcel={setOpenExcel}
                  setSnackbarMessage={setSnackbarMessage}
                />
              </ListItemText>
            </MenuItem>
          </Menu>
        </Box>
      </Box>
      <Dialog
        open={showDialog}
        fullWidth={true}
        maxWidth={"sm"}
        onClose={() => setShowDialog(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "space-between",
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "flex-start",
                alignItems: "flex-start",
              }}
            >
              {successDialog ? (
                <Check sx={{ color: theme.palette.success.main }} />
              ) : errorDialog ? (
                <Check sx={{ color: theme.palette.error.main }} />
              ) : (
                ""
              )}
              <Typography
                variant="h6"
                sx={{
                  ml: 1,
                  fontSize: "18px",
                  fontWeight: 500,
                  color: theme.palette.success.main,
                }}
              >
                {successDialog
                  ? "Kurye Talebiniz Alındı."
                  : errorDialog
                  ? "Hata"
                  : ""}
              </Typography>
            </Box>
            <IconButton
              aria-label="close"
              onClick={() => setShowDialog(false)}
              sx={{
                position: "absolute",
                right: "10px",
                top: "10px",
              }}
            >
              <CloseIcon />
            </IconButton>
          </Box>
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {successDialog
              ? " Talebiniz iletilmiştir. Teşekkür ederiz."
              : errorDialog
              ? "Kurye talebiniz alınamadı. Lütfen tekrar deneyin."
              : ""}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            className="filterApply"
            onClick={() => setShowDialog(false)}
            color="primary"
          >
            Tamam {timer > 0 ? `(${timer})` : ""}
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default FbmmToggler;
