import React from "react";
import { Container, Typography, Box, Link } from "@mui/material";

const LongDistanceSellingAgreement = () => {
  return (
    <Container maxWidth="md">
      <Box sx={{ mt: 4 }}>
        <Typography variant="h6" align="center" mb={3} gutterBottom>
          MESAFELİ HİZMET SATIŞI SÖZLEŞMESİ
        </Typography>
        <Typography variant="body1" gutterBottom>
          İşbu Mesafeli Hizmet Satışı Sözleşmesi ("<strong>Sözleşme</strong>"),
          İstanbul Ticaret Sicili Müdürlüğü'ne 378544-5 Ticaret Sicil
          numarasıyla kayıtlı ve kayıtlı adresi Burhaniye Mah.{" "}
          <Link href="#">
            Nagehan Sk. A Blok No: 4a İç Kapı No: 1 Üsküdar / İstanbul
          </Link>{" "}
          olan Mega Merchant E-Ticaret Satış ve Pazarlama Anonim Şirketi ("
          <strong>Mega Merchant</strong>") ile Alıcı arasında belirtilen hüküm
          ve şartlar çerçevesinde elektronik ortamda kurulmuştur.
        </Typography>
        <Typography variant="body1" gutterBottom>
          Taraflar bundan sonra birlikte "<strong>Taraflar</strong>" ve ayrı
          ayrı "<strong>Taraf</strong>" olarak anılacaktır.
        </Typography>
        <Typography variant="h6" gutterBottom>
          TANIMLAR
        </Typography>
        <Typography variant="body1" gutterBottom>
          "<strong>Omega Platformu</strong>", Mega Merchant’a ait{" "}
          <Link href="http://www.omega.themegamerchant.com">
            www.omega.themegamerchant.com
          </Link>{" "}
          adlı internet sitesini ifade eder.
        </Typography>
        <Typography variant="body1" gutterBottom>
          "<strong>Alıcı</strong>", Sözleşme’nin tarafı olarak Hizmeti
          elektronik ortamda “Satın Al” seçeneğini seçerek Hizmet’i satın alan
          ve Hizmet’ten yararlanan kişiyi ifade eder.
        </Typography>
        <Typography variant="body1" gutterBottom>
          "<strong>Hizmet</strong>", Ücret karşılığında Mega Merchant tarafından
          Alıcı'ya sunulan veya sunulması taahhüt edilen işleri ifade eder.
        </Typography>
        <Typography variant="body1" gutterBottom>
          "<strong>Hizmet Periyodu</strong>", Hizmet Bedeli'nin Alıcı tarafından
          aylık veya yıllık şekilde, düzenli olarak otomatik ödemelerle
          gerçekleştirildiği dönemleri ifade eder.
        </Typography>
        <Typography variant="body1" gutterBottom>
          "<strong>Hizmet Süresi</strong>", Mega Merchant'ın işbu Sözleşme'nin
          imza/onay tarihinden itibaren Hizmet’i sona erdirdiği ana kadar geçen
          süreyi ifade eder.
        </Typography>
        <Typography variant="body1" gutterBottom>
          "<strong>Hizmet Bedeli</strong>", Hizmet karşılığı ödenen Madde 2’de
          detayları düzenlenen bedeli ifade eder.
        </Typography>
        <Typography variant="h6" gutterBottom>
          1. SÖZLEŞMENİN KONUSU VE KAPSAMI
        </Typography>
        <Typography variant="body1" gutterBottom>
          1.1. İşbu Sözleşme, Alıcı’nın Omega Platformu üzerinden elektronik
          ortamda kredi kartı aracılığıyla veya sair şekillerde elektronik ortam
          üzerinden ödeme yaparak satın aldığı, Omega Platformu’nda nitelikleri
          haiz ve satış fiyatı belirtilen Hizmet’in satışı ve teslimi ile ilgili
          olarak Tarafların hak ve yükümlülüklerinin saptanmasıdır.
        </Typography>
        <Typography variant="body1" gutterBottom>
          1.2. Mega Merchant işbu Sözleşme ile Hizmet’i Alıcı'ya doğru ve
          gereğine uygun şekilde yerine getirmekle Alıcı ise Hizmet karşılığı
          Hizmet Bedeli'ni ödemekle yükümlüdür.
        </Typography>
        <Typography variant="h6" gutterBottom>
          2. HİZMET BEDELİ
        </Typography>
        <Typography variant="body1" gutterBottom>
          2.1. Sözleşme konusu Hizmet karşılığı ödenecek Hizmet Bedeli Mega
          Merchant tarafından belirlenmekte ve Omega Platformu’nda Hizmet’e
          ilişkin diğer detayların yer aldığı satın alma sayfasında
          belirtilmektedir. Hizmet Bedeli ödemesi tek seferlik olabileceği gibi
          aylık ve yıllık periyotlar halinde de gerçekleştirilebilmektedir. Her
          periyot başlangıcında Alıcı ödeme yapmak zorundadır, ödeme yapılmayan
          durumlarda Hizmet süresi yenilenmez, Sözleşme kendiliğinden sona ermiş
          kabul edilir. Bu durumda Mega Merchant’ın Hizmet’i yerine getirme
          yükümlülüğü de ortadan kalkar.
        </Typography>
        <Typography variant="body1" gutterBottom>
          2.2. Mega Merchant, 213 Sayılı Vergi Usul Kanunu Madde 230 ve 3065
          sayılı Katma Değer Vergisi Kanunu Madde 8 uyarınca satılan ürün ve
          hizmetle ilgili fatura düzenlemek zorundadır. Bu anlamda Mega
          Merchant, Alıcı'ya sunduğu Hizmet'e ilişkin fatura kesmeyi kabul ve
          taahhüt eder.
        </Typography>
        <Typography variant="body1" gutterBottom>
          2.3. Alıcı, Hizmet Bedeli'ni banka kayıtlarında iptal ederse ve
          ödemeden vazgeçilirse, Mega Merchant’ın Hizmet’i teslim yükümlülüğü
          sona erer. Bu süre zarfında Hizmet’e başlanmış ya da Hizmet planlaması
          yapılmış olması halinde Mega Merchant ödemenin tamamını veya bir
          kısmını tamamen kendi takdirinde olmak kaydıyla Alıcı'dan talep etme
          hakkını saklı tutar.
        </Typography>

        {/* page 2 */}
        <Typography variant="h6" gutterBottom>
          3. TARAFARIN HAK, BEYAN VE YÜKÜMLÜLÜKLERİ
        </Typography>
        <Typography variant="body1" gutterBottom>
          3.1. Alıcı, işbu Sözleşme’de belirtilen satışa konu Hizmet’in temel
          nitelikleri, satış fiyatı, adedi, ödeme şekli, termin tarihi ve
          benzeri satışa konu hizmet ile ilgili tüm bilgiler ile iptal ve iade
          koşulları konusunda bilgi sahibi olduğunu, bu bilgileri elektronik
          ortamda kendi iradesiyle teyit ettiğini ve sonrasında satın aldığını
          kabul, beyan ve taahhüt etmektedir.
        </Typography>
        <Typography variant="body1" gutterBottom>
          3.2. Hizmet’in süresi ve ifa usulü tamamen Mega Merchant’ın takdirinde
          olup Mega Merchant, Alıcı nezdinde Hizmet’in uygulanabilirliği sona
          erdiğinde Hizmet’i sonlandırma hakkına sahiptir.
        </Typography>
        <Typography variant="body1" gutterBottom>
          3.3. Alıcı, Hizmet sona erdikten sonra ya da henüz bitmeden dilediği
          zaman tamamen kendi inisiyatifine bağlı olacak şekilde Omega
          Platformu’ndan istediği başka bir Hizmeti daha eş zamanlı olarak
          alabilir.
        </Typography>
        <Typography variant="body1" gutterBottom>
          3.4. Satın alınan Hizmet yalnızca Alıcı tarafından veya Mega Merchant
          tarafından Alıcı lehine kullanılabilir. Hizmet’i kullanım hakkı üçüncü
          şahıs ve kurumlara devredilemez.
        </Typography>
        <Typography variant="body1" gutterBottom>
          3.5. Alıcı, kredi kartının, ilgili ülke mevzuatına göre yasal biçimde
          kendisine verilmiş geçerli bir kredi kartı ve benzeri ödeme
          metotlarına ilişkin bir ödeme aracı olduğunu; bu kredi kartını veya
          diğer kartları veya diğer ödeme yöntemlerini herhangi bir yasadışı
          eylem sonucunda elde etmediğini kabul ve beyan eder.
        </Typography>
        <Typography variant="body1" gutterBottom>
          3.6. İşbu Sözleşme kapsamında Alıcı’nın özel istek ve talepleri ile
          Alıcı ve markasına özel hale getirilen Hizmetler söz konusu olduğundan
          kural olarak Hizmet’in iptali ve Hizmet Bedeli’nin iadesi mümkün
          değildir. Ancak istisnai olarak Alıcı, Hizmet’i satın alma talebini
          oluşturduktan sonra Mega Merchant ilgili Hizmet’in ifasına
          başlandığına dair e-posta gönderilmeden önce talebinden vazgeçmek
          hakkına sahiptir. Alıcı bu talebini e-posta yolu ile yazılı olarak
          Mega Merchant’a zamanında bildirdiği takdirde ödemiş olduğu Hizmet
          Bedeli kendisine iade edilir. İade sırasında herhangi bir masraf
          çıkması halinde Alıcı bu masraflara katlanmakla yükümlüdür.
        </Typography>

        {/* page three */}
        <Typography variant="h6" gutterBottom>
          4. KİŞİSEL VERİLERİN KORUNMASI
        </Typography>
        <Typography variant="body1" gutterBottom>
          Mega Merchant, işbu Sözleşme kapsamındaki kişisel verileri sadece{" "}
          Hizmet’in sunulması amacıyla sınırlı olarak ve 6698 sayılı Kişisel
          Verilerin Korunması Kanunu’na, ikincil mevzuata ve Kişisel Verileri
          Koruma Kurulu kararlarına uygun olarak işleyecektir.
        </Typography>
        <Typography variant="h6" gutterBottom>
          5. CAYMA HAKKI
        </Typography>
        <Typography variant="body1" gutterBottom>
          İşbu Sözleşme kapsamında, Taraflar 6102 sayılı Türk Ticaret Kanunu’nu
          uyarınca tacir veya esnaf olduğundan ve Sözleşme konusu işin “ticari
          iş” olduğundan, Sözleşme 6102 sayılı Türk Ticaret Kanunu ve 6098
          sayılı Borçlar Kanunu’nun Genel Hükümlerine tabidir. Bu kapsamda,
          Alıcı, Omega Platformu üzerinden yalnızca tacir ve esnaflara yönelik
          bir hizmet sağladığını ve bu çerçevede, işbu Sözleşme kapsamında doğan
          ilişkinin 6502 sayılı Tüketicinin Korunması Hakkında Kanun kapsamında
          olmadığını kabul, beyan ve taahhüt etmektedir. Bu kapsamda, Alıcı,
          kendisi bakımından, tüketici mevzuatı hükümleri anlamında ‘tüketici’
          tanımı söz konusu olmadığını, Alıcı belirtilen mevzuat uyarınca
          tüketicilere tanınan başta cayma hakkı olmak üzere hak ve yetkilerden
          faydalanma hakkını haiz olmadığını bildiğini gayrikabili rücu olarak
          kabul, beyan ve taahhüt etmektedir.
        </Typography>
        <Typography variant="h6" gutterBottom>
          6. SÖZLEŞME SÜRESİ
        </Typography>
        <Typography variant="body1" gutterBottom>
          Sözleşme’nin süresi Hizmet’in Omega Platformu üzerinden ödeme
          kanallarının kullanılması suretiyle Hizmet Bedeli’nin ödenmesi ile
          başlar ve Mega Merchant tarafından Hizmet’in gerçekleştirilmesiyle
          sona erer.
        </Typography>
        <Typography variant="h6" gutterBottom>
          7. DİĞER HÜKÜMLER
        </Typography>
        <Typography variant="body1" gutterBottom>
          7.1. Alıcı, işbu Sözleşme’den doğabilecek ihtilaflarda Mega
          Merchant’ın resmi defter ve ticari kayıtları, kendi veri tabanında
          veya sunucularında tuttuğu e-arşiv kayıtları, elektronik bilgiler ve
          bilgisayar kayıtları dahil olmak üzere Mega Merchant nezdinde bulunan
          her türlü kayıt ve belgenin bağlayıcı, kesin ve münhasır delil teşkil
          edeceğini ve işbu maddenin 6100 sayılı Hukuk Muhakemeleri Kanunu’nun
          193. Maddesi anlamında delil sözleşmesi niteliğinde olduğunu kabul,
          beyan ve taahhüt eder.
        </Typography>
        <Typography variant="body1" gutterBottom>
          7.2. İşbu Sözleşme’nin herhangi bir hükmünün veya Sözleşme’de yer alan
          herhangi bir ifadenin geçersizliği, yasaya aykırılığı ve uygulanamaz
          olması, Sözleşme’nin geri kalan hükümlerinin yürürlüğünü ve
          geçerliliğini etkilemeyecektir.
        </Typography>
        <Typography variant="body1" gutterBottom>
          7.3. Alıcı, işbu Sözleşme’yi ve/veya işbu Sözleşme’den doğan
          haklarını, menfaatlerini ve/veya yükümlülüklerini önceden Mega
          Merchant’ın yazılı muvafakatini almaksızın, hiçbir şekilde üçüncü
          kişilere devir veya temlik edemez. Bununla birlikte Mega Merchant,
          işbu Sözleşme’yi ve/veya işbu Sözleşme’den doğan haklarını,
          menfaatlerini, alacaklarını ve/veya yükümlülüklerini, önceden
          Alıcı’nın yazılı iznini almaksızın, hâkim şirketlerine, bağlı
          şirketlerine, iştiraklerine, iş ortaklarına ve/veya üçüncü kişilere
          devir ve temlik edebilir.
        </Typography>
        <Typography variant="body1" gutterBottom>
          7.4. İşbu Sözleşme’nin yorumlanmasında ve uygulanmasında Türk Hukuku
          geçerli olacaktır. Taraflar, işbu Sözleşme’den doğabilecek her türlü
          uyuşmazlıkların çözümlenmesinde İstanbul Çağlayan Mahkemeleri ve İcra
          Müdürlükleri yetkili olacağını kabul ederler.
        </Typography>
        <Typography variant="body1" gutterBottom>
          7.5. şbu Sözleşme 7 (yedi) madde ve 4 (dört) sayfadan ibaret olarak
          Alıcı tarafından her bir hükmü okunarak ve bütünüyle anlaşılarak
          elektronik ortamda onaylanmak suretiyle imzalanmış olup, onaylandığı
          an itibarıyla yürürlüğe girmiştir.
        </Typography>
      </Box>
    </Container>
  );
};

export default LongDistanceSellingAgreement;
