import React from "react";
import { Box, Typography } from "@mui/material";

function getWareHouses(params) {
  let value = params.row.productWarehouses;
  if (value.length === 0) {
    return null;
  } else {
    return (
      <Box>
        {value.map((warehouse) => (
          <div className="fbmmProdList" key={warehouse.warehouseId}>
            <Typography key={warehouse.warehouseId}>
              {warehouse.warehouse.title}
            </Typography>
          </div>
        ))}
      </Box>
    );
  }
}

export default getWareHouses;
