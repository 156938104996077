import React from "react";

const PriceCalculationToolIcon = () => {
  return (
    <svg
      width="36"
      height="36"
      viewBox="0 0 36 36"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="36" height="36" rx="5" fill="#FAF8F8" />
      <g clip-path="url(#clip0_3312_2890)">
        <path
          d="M23.7323 10.2667H12.2656V16.1333H23.7323V10.2667Z"
          stroke="#453834"
          stroke-width="1.5"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M27.7344 28.7556V7.24444C27.7344 6.70443 27.2986 6.26666 26.761 6.26666L9.24104 6.26666C8.70348 6.26666 8.26771 6.70443 8.26771 7.24444V28.7556C8.26771 29.2956 8.70348 29.7333 9.24104 29.7333H26.761C27.2986 29.7333 27.7344 29.2956 27.7344 28.7556Z"
          stroke="#453834"
          stroke-width="1.5"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M13.2 21.7333C13.8627 21.7333 14.4 21.1961 14.4 20.5333C14.4 19.8706 13.8627 19.3333 13.2 19.3333C12.5373 19.3333 12 19.8706 12 20.5333C12 21.1961 12.5373 21.7333 13.2 21.7333Z"
          fill="#453834"
        />
        <path
          d="M18.0008 21.7333C18.6635 21.7333 19.2008 21.1961 19.2008 20.5333C19.2008 19.8706 18.6635 19.3333 18.0008 19.3333C17.338 19.3333 16.8008 19.8706 16.8008 20.5333C16.8008 21.1961 17.338 21.7333 18.0008 21.7333Z"
          fill="#453834"
        />
        <path
          d="M22.8016 21.7333C23.4643 21.7333 24.0016 21.1961 24.0016 20.5333C24.0016 19.8706 23.4643 19.3333 22.8016 19.3333C22.1388 19.3333 21.6016 19.8706 21.6016 20.5333C21.6016 21.1961 22.1388 21.7333 22.8016 21.7333Z"
          fill="#453834"
        />
        <path
          d="M13.2 26.5334C13.8627 26.5334 14.4 25.9961 14.4 25.3334C14.4 24.6706 13.8627 24.1334 13.2 24.1334C12.5373 24.1334 12 24.6706 12 25.3334C12 25.9961 12.5373 26.5334 13.2 26.5334Z"
          fill="#453834"
        />
        <path
          d="M18.0008 26.5334C18.6635 26.5334 19.2008 25.9961 19.2008 25.3334C19.2008 24.6706 18.6635 24.1334 18.0008 24.1334C17.338 24.1334 16.8008 24.6706 16.8008 25.3334C16.8008 25.9961 17.338 26.5334 18.0008 26.5334Z"
          fill="#453834"
        />
        <path
          d="M22.8016 26.5334C23.4643 26.5334 24.0016 25.9961 24.0016 25.3334C24.0016 24.6706 23.4643 24.1334 22.8016 24.1334C22.1388 24.1334 21.6016 24.6706 21.6016 25.3334C21.6016 25.9961 22.1388 26.5334 22.8016 26.5334Z"
          fill="#453834"
        />
      </g>
      <defs>
        <clipPath id="clip0_3312_2890">
          <rect
            width="22"
            height="26"
            fill="white"
            transform="translate(7 5)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export default PriceCalculationToolIcon;
