import React from "react";

const StockIconActive = () => {
  return (
    <svg
      width="36"
      height="36"
      viewBox="0 0 36 36"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="36" height="36" rx="5" fill="#FEF7F5" />
      <g clipPath="url(#clip0_191_586)">
        <path
          d="M18.0047 23.0333H12.8736C12.6792 23.0333 12.4928 23.1106 12.3553 23.248C12.2179 23.3855 12.1406 23.5719 12.1406 23.7663V28.1644C12.1406 28.3588 12.2179 28.5453 12.3553 28.6827C12.4928 28.8202 12.6792 28.8974 12.8736 28.8974H17.2717C17.4661 28.8974 17.6526 28.8202 17.79 28.6827C17.9275 28.5453 18.0047 28.3588 18.0047 28.1644V23.0333Z"
          stroke="#EB603A"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M23.136 23.0333H18.0049V28.1644C18.0049 28.3588 18.0821 28.5453 18.2196 28.6827C18.357 28.8202 18.5435 28.8974 18.7379 28.8974H23.136C23.3304 28.8974 23.5168 28.8202 23.6543 28.6827C23.7917 28.5453 23.869 28.3588 23.869 28.1644V23.7663C23.869 23.5719 23.7917 23.3855 23.6543 23.248C23.5168 23.1106 23.3304 23.0333 23.136 23.0333Z"
          stroke="#EB603A"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M20.2033 17.1693H15.8053C15.6109 17.1693 15.4244 17.2465 15.287 17.3839C15.1495 17.5214 15.0723 17.7079 15.0723 17.9023V23.0333H20.9364V17.9023C20.9364 17.7079 20.8591 17.5214 20.7217 17.3839C20.5842 17.2465 20.3977 17.1693 20.2033 17.1693Z"
          stroke="#EB603A"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M29.0001 28.8974V13.6508C29.0002 13.385 28.928 13.1242 28.7913 12.8963C28.6547 12.6684 28.4586 12.4819 28.2241 12.3568L18.6949 7.27457C18.4827 7.16146 18.2459 7.10229 18.0054 7.10229C17.7649 7.10229 17.5281 7.16146 17.3159 7.27457L7.78676 12.3568C7.55208 12.4817 7.3558 12.6682 7.21894 12.8961C7.08207 13.124 7.00977 13.3849 7.00977 13.6508V28.8974"
          fill="#EB603A"
          fillOpacity="0.3"
        />
        <path
          d="M29.0001 28.8974V13.6508C29.0002 13.385 28.928 13.1242 28.7913 12.8963C28.6547 12.6684 28.4586 12.4819 28.2241 12.3568L18.6949 7.27457C18.4827 7.16146 18.2459 7.10229 18.0054 7.10229C17.7649 7.10229 17.5281 7.16146 17.3159 7.27457L7.78676 12.3568C7.55208 12.4817 7.3558 12.6682 7.21894 12.8961C7.08207 13.124 7.00977 13.3849 7.00977 13.6508V28.8974"
          stroke="#EB603A"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_191_586">
          <rect
            width="24"
            height="24"
            fill="white"
            transform="translate(6 6)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export default StockIconActive;
