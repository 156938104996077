import React from "react";

const MessageFIleIcon = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M5.48585 1.71387C4.94027 1.71387 4.41702 1.9306 4.03123 2.31639C3.64545 2.70218 3.42871 3.22542 3.42871 3.77101V20.2282C3.42871 20.7737 3.64545 21.297 4.03123 21.6828C4.41702 22.0686 4.94027 22.2853 5.48585 22.2853H18.5144C19.06 22.2853 19.5833 22.0686 19.969 21.6828C20.3548 21.297 20.5716 20.7737 20.5716 20.2282V9.92358C20.5716 9.29872 20.323 8.69958 19.8816 8.2573L13.9407 2.31644C13.5549 1.93064 13.0317 1.71389 12.4861 1.71387H5.48585ZM4.71443 3.77101C4.71443 3.56641 4.7957 3.3702 4.94037 3.22553C5.08504 3.08086 5.28126 2.99958 5.48585 2.99958H12.0001V8.57101C12.0001 9.02567 12.1808 9.4617 12.5022 9.78319C12.8237 10.1047 13.2598 10.2853 13.7144 10.2853H19.2859V20.2282C19.2859 20.4327 19.2046 20.629 19.0599 20.7736C18.9152 20.9183 18.719 20.9996 18.5144 20.9996H5.48585C5.28126 20.9996 5.08504 20.9183 4.94037 20.7736C4.7957 20.629 4.71443 20.4327 4.71443 20.2282V3.77101ZM18.8059 8.99958H13.7144C13.6008 8.99958 13.4918 8.95443 13.4114 8.87406C13.331 8.79368 13.2859 8.68467 13.2859 8.57101V3.47958L18.8059 8.99958Z"
        fill="#4E73CD"
      />
    </svg>
  );
};

export default MessageFIleIcon;
