import React from "react";

const CloseIconCustom = () => {
  return (
    <svg
      width="28"
      height="28"
      viewBox="0 0 28 28"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="28" height="28" rx="2" fill="#F7F6F5" />
      <rect
        x="0.5"
        y="0.5"
        width="27"
        height="27"
        rx="1.5"
        stroke="#453834"
        strokeOpacity="0.15"
      />
      <g clipPath="url(#clip0_2493_5781)">
        <path
          d="M8.375 19.624L19.625 8.37402"
          stroke="#453834"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M19.625 19.624L8.375 8.37402"
          stroke="#453834"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_2493_5781">
          <rect
            width="14"
            height="14"
            fill="white"
            transform="translate(7 7)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export default CloseIconCustom;
