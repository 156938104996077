import React, { useEffect } from "react";
import { useSignOut } from "react-auth-kit";
import { useTranslation } from "react-i18next";
import {
  Box,
  IconButton,
  Menu,
  useTheme,
  MenuItem,
  Badge,
} from "@mui/material";
import Avatar from "@mui/material/Avatar";
import ListItemIcon from "@mui/material/ListItemIcon";
import Divider from "@mui/material/Divider";
import Typography from "@mui/material/Typography";
import Tooltip from "@mui/material/Tooltip";
import PersonAdd from "@mui/icons-material/PersonAdd";
import Settings from "@mui/icons-material/Settings";
import Logout from "@mui/icons-material/Logout";
import MailIcon from "@mui/icons-material/Mail";

import NotificationAddOutlinedIcon from "@mui/icons-material/NotificationsOutlined";
import LogoutIcon from "@mui/icons-material/Logout";
import { useState } from "react";
import NewMailIcon from "../../components/icons/NewMailIcon";
import NewNotificationIcon from "../../components/icons/NewNotificationIcon";
import NewDownloadIcon from "../../components/icons/NewDownloadIcon";
import NewMessageNotificationIcon from "../../components/icons/NewMessageNotificationIcon";
import FAQIcon from "../../components/icons/FAQIcon";
import { useNavigate } from "react-router-dom";
import { useAuthUser } from "react-auth-kit";
import axios from "axios";
import Cookies from "js-cookie";

const ITEM_HEIGHT = 48;

const Topbar = ({ title, children }) => {
  const navigate = useNavigate();
  const signOut = useSignOut();
  const [messages, setMessages] = useState([]);
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const theme = useTheme();
  const user = useAuthUser();
  const logout = () => {
    signOut();
  };

  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = lang => {
    setAnchorEl(null);
  };

  let merchantId = user().merchantId;

  const getUserMessages = async () => {
    let config = {
      method: "get",
      maxBodyLength: Infinity,
      url: `${process.env.REACT_APP_BASE_URL}/Merchant/GetMerchantMessageByMerchantId/${merchantId}`,
      headers: {
        Authorization: `Bearer ${Cookies.get("_auth")}`,
      },
    };
    try {
      const response = await axios(config);
      setMessages(response.data.items);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getUserMessages();
  }, []);

  return (
    <Box display="flex" justifyContent="space-between" mt={0}>
      <Box display="flex" sx={{ alignItems: "center" }} mt={0}>
        <h1 style={theme.typography.h1}>{title}</h1>
        {children}
      </Box>
      <Box
        display="flex"
        alignItems="center"
        sx={{
          gap: 1,
        }}
      >
        <React.Fragment>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              gap: 1,
            }}
          >
            {/* <Tooltip title="Mesajlar">
              <IconButton
                onClick={handleClick}
                size="small"
                aria-controls={open ? "account-menu" : undefined}
                aria-haspopup="true"
                aria-expanded={open ? "true" : undefined}
              >
                <Badge
                  className="messageBadge"
                  color="secondary"
                  badgeContent={{ messages }.messages.length}
                >
                  <NewMailIcon />
                </Badge>
              </IconButton>
            </Tooltip> */}
          </Box>
          {/* <Menu
            id="long-menu"
            anchorEl={anchorEl}
            // id="account-menu"
            open={open}
            sx={{
              width: "400px!important",
            }}
            onClose={handleClose}
            onClick={handleClose}
            PaperProps={{
              elevation: 0,
              sx: {
                maxHeight: ITEM_HEIGHT * 9,
                width: "400px!important",
                "& .MuiMenu-list": {
                  paddingTop: "0px!important",
                },
              },
            }}
            transformOrigin={{ horizontal: "right", vertical: "top" }}
            anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
          >
            <Box>
              <Typography
                variant="subtitle2"
                sx={{
                  paddingY: 1,
                  background: "#F7F6F5",
                  paddingX: 1,
                }}
                color="primary"
              >
                Mesaj Kutusu ({messages.length})
              </Typography>
            </Box>
            <Divider />
            {messages &&
              messages.map((message, index) => (
                <MenuItem key={index} onClick={handleClose}>
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      mt: 1,
                      width: "400px!important",
                    }}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                        justifyContent: "flex-start",
                        gap: 1,
                        mb: 1,
                      }}
                    >
                      <Box
                        sx={{
                          width: "10px",
                          height: "10px",
                          borderRadius: "50%",
                          backgroundColor: "#EB603A",
                        }}
                      ></Box>
                      <Typography
                        sx={{
                          color: "#453834",
                          fontSize: "14px",
                          fontWeight: "500",
                        }}
                      >
                        {message.createdUser.nameSurname}
                      </Typography>
                      <Typography
                        sx={{
                          color: "#453834",
                          opacity: 0.6,
                          fontStyle: "italic",
                          fontSize: "14px",
                        }}
                      >
                        from Papatya
                      </Typography>
                    </Box>
                    <Typography
                      sx={{
                        color: "#453834",
                        maxWidth: "400px",
                        lineClamp: 2,
                        whiteSpace: "pre-wrap",
                        fontSize: "14px",
                        mb: 1,
                        maxLines: 2,
                      }}
                    >
                      {message.messageBody}
                    </Typography>
                    <Typography
                      sx={{
                        color: "#453834",
                        opacity: 0.6,
                        fontSize: "14px",
                      }}
                    >
                      {new Date(message.createdAt).toLocaleDateString("tr-TR")}
                    </Typography>
                  </Box>
                </MenuItem>
              ))}
          </Menu> */}
        </React.Fragment>
        <IconButton>
          <NewNotificationIcon />
        </IconButton>
        <IconButton onClick={logout}>
          <LogoutIcon />
        </IconButton>
      </Box>
    </Box>
  );
};

export default Topbar;
