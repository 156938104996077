import React from "react";

import {
  CssBaseline,
  ThemeProvider,
  Typography,
  createTheme,
} from "@mui/material";
import { SettingsForm } from "../components/SettingsForm";
import { useTranslation } from "react-i18next";

const theme = createTheme({
  palette: {
    primary: {
      main: "#EB603A", // Color for titles and buttons
    },
    secondary: {
      main: "#9e9e9e", // Color for placeholders and labels
    },
  },
  typography: {
    h5: {
      fontWeight: 700,
    },
    h6: {
      fontWeight: 500,
    },
  },
});

function PaymentInformation() {
  const { t } = useTranslation();

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Typography
        variant="subtitle1"
        gutterBottom
        color="primary"
        margin="24px"
        marginBottom="12px"
        fontWeight="500"
        fontSize="24px"
      >
        {t("paymentInfo")}
      </Typography>
      <SettingsForm />
    </ThemeProvider>
  );
}

export default PaymentInformation;
