import React, { useState, useContext } from "react";
import { Box, IconButton, useTheme } from "@mui/material";
import InputBase from "@mui/material/InputBase";
import SearchIcon from "../icons/SearchIcon";
import { ProductFilterContext } from "../../context/ProductFilterContext";
import DownloadExcel from "./DownloadExcel";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import FormControl from "@mui/material/FormControl";
import Typography from "@mui/material/Typography";

const Toggler = ({ options, placeholder, counts }) => {
  const theme = useTheme();
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [productSearch, setProductSearch] = useState("");
  // const [toggleId, setToggleId] = useState("");
  const { productFilters, setProductFilters } =
    useContext(ProductFilterContext);

  const handleChange = event => {
    setSelectedIndex(event.target.value);
    setProductFilters({ ...productFilters, status: event.target.value });
  };

  const calculateTotalCount = options => {
    let total = 0;
    options.map(option => {
      total += option.count;
    });
    return total;
  };

  const handleSubmit = event => {
    event.preventDefault();
    setProductFilters({ ...productFilters, search: productSearch });
  };

  const handleMenuItemClick = (event, index, id) => {
    setSelectedIndex(index);
    // setToggleId(id);
    setProductFilters({ ...productFilters, status: id });
  };

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "space-between",
        alignItems: "flex",
        flexDirection: "column",
      }}
    >
      <Box>
        <Typography
          variant="h6"
          sx={{
            ml: 1,
            fontSize: "16px",
            fontWeight: 500,
          }}
          color="primary"
          component="div"
        >
          Durum:
        </Typography>
      </Box>
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "space-between",
          width: "100%",
          height: "100%",
        }}
      >
        <Box
          sx={{
            display: "flex",
            height: "100%",
            alignItems: "center",
            flexDirection: "row",
          }}
        >
          <Box>
            <FormControl sx={{ m: 1, minWidth: 120 }}>
              <Select
                value={selectedIndex}
                onChange={handleChange}
                displayEmpty
                size="small"
                sx={{
                  width: "240px",
                  borderRadius: "0px",
                  "& .MuiSelect-select": {
                    borderRadius: "0px",
                    display: "flex!important",
                    justifyContent: "space-between!important",
                  },
                  "& .MuiSelect-icon": {
                    top: "unset",
                  },
                }}
              >
                <MenuItem
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                  }}
                  value={0}
                >
                  <span>Tümü</span>
                  <span>
                    {calculateTotalCount(options) === 0
                      ? "0"
                      : calculateTotalCount(options)}
                  </span>
                </MenuItem>
                {options.map((option, index) => (
                  <MenuItem
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                    }}
                    key={index}
                    value={option.id}
                    onClick={event =>
                      handleMenuItemClick(
                        event,
                        option.count,
                        option.id,
                        option.title
                      )
                    }
                  >
                    <span>{option.title} </span> <span>{option.count}</span>
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Box>
          <Box
            display="flex"
            sx={{
              border: 1,
              ml: 2,
              borderColor: theme.palette.primary.bordersColor,
              borderRadius: "2px",
              height: "40px",
            }}
          >
            <IconButton
              onClick={handleSubmit}
              type="button"
              size="small"
              sx={{ ml: 1, p: 0 }}
            >
              <SearchIcon />
            </IconButton>
            <InputBase
              sx={{
                flex: 2,
                p: 0,
                m: 0,
                ml: 1,
                fontStyle: "italic",
                fontSize: "14px!important",
              }}
              placeholder={placeholder}
              size="small"
              className="search"
              onKeyUp={e => {
                if (productSearch.length === 0) {
                  setProductFilters({ ...productFilters, search: "" });
                  handleSubmit(e);
                }
              }}
              onKeyPress={e => {
                if (e.key === "Enter") {
                  handleSubmit(e);
                }
              }}
              value={productSearch}
              onChange={e => setProductSearch(e.target.value)}
            />
          </Box>
        </Box>
        <Box>
          <DownloadExcel />
        </Box>
      </Box>
    </Box>
  );
};

export default Toggler;
