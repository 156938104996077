import React from "react";

const KuryeIcon = () => {
  return (
    <svg
      width="26"
      height="20"
      viewBox="0 0 26 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.7182 3.21031C2.7182 2.49529 3.31759 1.92648 3.87966 1.92648H17.0899C17.652 1.92648 18.2514 2.49529 18.2514 3.21031V5.42712C17.9608 5.43636 17.67 5.54599 17.4494 5.77457C17.2209 6.0113 17.1242 6.31658 17.1242 6.60896V6.60901L17.1238 11.794C17.1238 11.9321 17.0119 12.044 16.8738 12.044H10.1295H2.7182V3.21031ZM2.71791 14.1177H2.7182V13.544H10.1295H16.8738C17.8403 13.544 18.6238 12.7606 18.6238 11.7941L18.6242 6.92648H19.9616C20.1819 6.92648 20.4678 7.03047 20.806 7.26627L22.8453 9.37901C23.0776 9.61974 23.1776 9.86179 23.1776 10.2422V16.1013C23.1467 16.5204 22.8108 16.8019 22.5082 16.8019H20.5884V18.3019H22.5082C23.7045 18.3019 24.616 17.2935 24.6766 16.1647L24.6776 16.1447V16.1246V10.2422C24.6776 9.4981 24.4436 8.87509 23.9245 8.33728L21.8372 6.17465L21.7913 6.12714L21.7378 6.08837C21.2635 5.74436 20.6487 5.42648 19.9616 5.42648H19.7514V3.21031C19.7514 1.75591 18.5667 0.426483 17.0899 0.426483H3.87966C2.40284 0.426483 1.2182 1.75591 1.2182 3.21031V13.1928C1.21792 13.204 1.21777 13.2153 1.21777 13.2265V13.2266L1.2182 16.1246V16.1247C1.21826 17.2922 2.15544 18.3019 3.34589 18.3019H5.1828V16.8019H3.34589C3.03344 16.8019 2.7182 16.5142 2.7182 16.1246V16.1245L2.71791 14.1177Z"
        fill="#453834"
      />
      <path
        d="M17.0579 17.6471L8.23438 17.6471"
        stroke="#453834"
        strokeWidth="1.5"
      />
      <path
        d="M23.5288 10.5883H17.6465V6.88239C17.6465 6.3301 18.0942 5.88239 18.6465 5.88239H19.3433C19.7958 5.88239 20.0786 5.98581 20.3614 6.24438L23.0763 8.77831C23.3592 9.03688 23.5288 9.39887 23.5288 9.70915V10.5883Z"
        stroke="#453834"
      />
      <path
        d="M18.8067 15.519C17.814 15.519 17.042 16.2911 17.042 17.2837C17.042 18.2764 17.814 19.0485 18.8067 19.0485C19.7993 19.0485 20.5714 18.2764 20.5714 17.2837C20.5714 16.2911 19.7993 15.519 18.8067 15.519Z"
        stroke="#453834"
        strokeWidth="1.5"
      />
      <path
        d="M6.47076 15.2942C5.47811 15.2942 4.70605 16.0662 4.70605 17.0589C4.70605 18.0515 5.47811 18.8236 6.47076 18.8236C7.46341 18.8236 8.23547 18.0515 8.23547 17.0589C8.23547 16.0662 7.46341 15.2942 6.47076 15.2942Z"
        stroke="#453834"
        strokeWidth="1.5"
      />
    </svg>
  );
};

export default KuryeIcon;
