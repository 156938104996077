import React, { useState, useEffect } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import CloseIcon from "@mui/icons-material/Close";
import Slide from "@mui/material/Slide";
import { Box, Container, useTheme } from "@mui/material";
import Cookies from "js-cookie";
import { useNavigate, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import CircularProgress from "@mui/material/CircularProgress";
import axios from "axios";
import ProductEditForm from "../components/forms/ProductEditForm";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const ProductUpdate = () => {
  const navigate = useNavigate();
  const theme = useTheme();
  const { t } = useTranslation();
  const [open, setOpen] = useState(true);
  let [product, setProduct] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState("");
  const productId = useParams().id;

  let data = "";
  let config = {
    method: "get",
    maxBodyLength: Infinity,
    url: `${process.env.REACT_APP_BASE_URL}/ProductVariant/${productId}`,
    headers: {
      Authorization: `Bearer ${Cookies.get("_auth")}`,
    },
    data: data,
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios(config);
        setProduct(response.data);
        setLoading(false);
      } catch (error) {
        setError(error);
      }
    };
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    navigate("/products");
  };

  if (loading)
    return (
      <Box sx={{ display: "flex" }}>
        <CircularProgress />
      </Box>
    );
  if (error) return <div>Error...</div>;
  return (
    <Box>
      <Dialog
        fullScreen
        open={open}
        onClose={handleClose}
        TransitionComponent={Transition}
        className="fs-dialog-appbar"
      >
        <AppBar sx={{ position: "relative", background: "#fff", mt: 0 }}>
          <Toolbar>
            <Typography
              sx={{
                ml: 2,
                flex: 1,
                color: `${theme.palette.primary.fontColor}!important`,
              }}
              variant="text"
            >
              <span
                style={{
                  textDecoration: "underline",
                  color: "rgba(85, 56, 48, 0.9)",
                }}
              >
                {t("products")}
              </span>
              <span style={{ fontWeight: "500", fontSize: "18px" }}>
                {" "}
                {">"}{" "}
              </span>
              <span style={{ fontWeight: "500" }}>{t("editProduct")}</span>
            </Typography>
            <IconButton
              edge="start"
              color="text"
              onClick={handleClose}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
          </Toolbar>
        </AppBar>
        <Container maxWidth="sm">
          <ProductEditForm product={product} />
        </Container>
      </Dialog>
    </Box>
  );
};

export default ProductUpdate;
