import React from "react";

const PointIcon = () => {
  return (
    <svg
      width="14"
      height="14"
      viewBox="0 0 14 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_2697_4730)">
        <path
          d="M13.5 7C13.5 10.5829 10.5829 13.5 7 13.5C3.41619 13.5 0.5 10.5829 0.5 7C0.5 3.4171 3.41619 0.5 7 0.5C10.5829 0.5 13.5 3.41714 13.5 7Z"
          stroke="#EB603A"
        />
        <path
          d="M4.97038 7.82655C4.98924 7.84494 4.99784 7.87142 4.99339 7.89738L4.55556 10.4476C4.54434 10.513 4.61293 10.5628 4.67162 10.532L6.96278 9.32791C6.98608 9.31567 7.01392 9.31567 7.03722 9.32791L9.32837 10.532C9.38707 10.5628 9.45566 10.513 9.44444 10.4476L9.00661 7.89738C9.00216 7.87142 9.01076 7.84494 9.02962 7.82655L10.8839 6.01902C10.9314 5.97274 10.9052 5.8921 10.8396 5.88256L8.27805 5.51021C8.252 5.50643 8.22948 5.49007 8.21782 5.46646L7.07173 3.14528C7.04238 3.08584 6.95762 3.08584 6.92827 3.14528L5.78218 5.46646C5.77052 5.49007 5.748 5.50643 5.72195 5.51021L3.1604 5.88256C3.09478 5.8921 3.06859 5.97274 3.11607 6.01902L4.97038 7.82655Z"
          stroke="#EB603A"
        />
      </g>
      <defs>
        <clipPath id="clip0_2697_4730">
          <rect width="14" height="14" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default PointIcon;
