import React from "react";

const SearchIcon = () => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_191_470)">
        <path
          d="M1.75021 10.9718C2.47961 12.6881 3.86092 14.0443 5.59028 14.7421C7.31964 15.4399 9.25537 15.4222 10.9717 14.6928C12.6879 13.9634 14.0442 12.5821 14.742 10.8527C15.4398 9.12338 15.4221 7.18764 14.6927 5.47137C13.9633 3.75509 12.582 2.39886 10.8526 1.70103C9.12325 1.0032 7.18751 1.02094 5.47123 1.75034C3.75496 2.47974 2.39873 3.86106 1.7009 5.59041C1.00307 7.31977 1.02081 9.25551 1.75021 10.9718V10.9718Z"
          stroke="#453834"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M13.1934 13.1928L18.6467 18.6469"
          stroke="#453834"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_191_470">
          <rect width="20" height="20" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default SearchIcon;
