import React, { useState } from "react";
import { ThemeProvider } from "@mui/material";
import theme from "./theme";
import { CssBaseline } from "@mui/material";
import { Route, Routes, Navigate } from "react-router-dom";
import Sidebar from "./pages/global/Sidebar";
import { RequireAuth, useAuthUser, useIsAuthenticated } from "react-auth-kit";
import { ProductFilterContext } from "./context/ProductFilterContext";
import { LanguageProvider } from "./context/LanguageContext";
import {
  SignIn,
  Products,
  Dashboard,
  ProductDetail,
  Categories,
  Fbmm,
  Stocks,
  Warehouses,
  NotFound,
} from "./pages";
import ProductUpdate from "./pages/ProductUpdate";
import FbmmDetail from "./pages/FbmmDetail";
import { FbmmFilterContext } from "./context/FbmmFilterContext";
import ProductStatusUpdate from "./pages/ProductStatusUpdate";
import FbmmStatusUpdate from "./pages/FbmmStatusUpdate";
import FbmmCargoUpdate from "./pages/FbmmCargoUpdate";
import StockUpdate from "./pages/StockUpdate";
import { StockFilterContext } from "./context/StockFilterContext";
import Signup from "./pages/Signup";
import Merchants from "./pages/Merchants";
import ResetPassword from "./pages/ResetPassword";
import UserDashboard from "./pages/UserDashboard";
import ForgetPassword from "./pages/ForgetPassword";
import UpdatePassword from "./pages/UpdatePassword";
import { MerchantFilterContext } from "./context/MerchantFilterContext";
import { UserSelectedPackageContext } from "./context/UserSelectedPackageContext";
import EditMerchant from "./pages/EditMerchant";
import Faq from "./pages/Faq";
import Faqs from "./pages/Faqs";
import Reports from "./pages/Reports";
import Payment from "./pages/Payment";
import Return from "./pages/Return";
import Payments from "./pages/Payments";
import Looker from "./pages/Looker";
import PromotionCode from "./pages/PromotionCode";
import Logs from "./pages/Logs";
import PriceCalculationTool from "./pages/PriceCalculationTool";
import PaymentInformation from "./pages/PaymentInformation";
import Unsubscribe from "./pages/Unsubscribe";
import Packages from "./pages/Packages";
import Package from "./pages/Package";
import IyzicoResult from "./pages/IyzicoResult";
//set new url
function App() {
  const [productFilters, setProductFilters] = useState({
    sku: null,
    barcode: null,
    productName: null,
    status: null,
    category: [],
    brandList: [],
    warehouse: [],
    search: "",
    merchant: "",
    updatedAt: "",
    updatedAtEnd: "",
  });
  const [stockFilters, setStockFilters] = useState({
    sku: null,
    barcode: null,
    stockName: null,
    status: null,
    category: [],
    warehouse: [],
    search: "",
    stockAmountStart: null,
    stockAmountEnd: null,
  });

  const [fbmmFilters, setFbmmFilters] = useState({
    sku: null,
    barcode: null,
    productName: null,
    status: null,
    orderNumber: null,
    warehouse: [],
    source: [],
    productCode: null,
    search: "",
    startDate: null,
    endDate: null,
  });

  const [merchantFilters, setMerchantFilters] = useState({
    status: null,
    search: "",
    isSubmerchant: false,
    MMUserId: null,
    accountManager: null,
  });

  const [userSelectedPackage, setUserSelectedPackage] = useState({
    id: null,
    marketingTypes: [],
    name: "",
    price: 0,
    regions: 0,
    returnGuarantee: "",
    yearly: false,
  });

  const PrivateRoute = ({ Component }) => {
    const isAuthenticated = useIsAuthenticated();
    const auth = isAuthenticated();
    return auth ? <Component /> : <Navigate to="/login" />;
  };

  const user = useAuthUser();
  const isAuthenticated = useIsAuthenticated();
  const auth = isAuthenticated();

  // if (auth && user().role === "b2790c4b-b4a8-4254-9ab6-84c67160b0e0") {
  //   return (

  //   );
  // } else if (auth && user().role === "342267ab-f007-4928-8568-40c896951afa") {
  //   return (
  //     <ThemeProvider theme={theme}>
  //       <CssBaseline />
  //       <div className="app">
  //         <Sidebar />
  //         <main className={auth ? "content" : "no-cont"}>
  //           <Routes>
  //             <Route
  //               exact={true}
  //               path="/"
  //               element={<PrivateRoute Component={UserDashboard} />}
  //             ></Route>
  //           </Routes>
  //         </main>
  //       </div>
  //     </ThemeProvider>
  //   );
  // } else {
  //   return (
  //     <ThemeProvider theme={theme}>
  //       <CssBaseline />
  //       <div className="app">
  //         <main className={auth ? "content" : "no-cont"}>
  //           <Routes>
  //             <Route exact={true} path="/" element={<SignIn />} />
  //             <Route path="/signup" element={<Signup />} />
  //             <Route path="/set-password/" element={<ResetPassword />} />
  //             <Route path="/login" element={<SignIn />} />
  //           </Routes>
  //         </main>
  //       </div>
  //     </ThemeProvider>
  //   );
  // }

  return (
    <ThemeProvider theme={theme}>
      <LanguageProvider>
        <CssBaseline />
        <div className="app">
          <Sidebar />
          <main className={auth ? "content" : "no-cont"}>
            <Routes>
              <Route
                path="/"
                element={
                  <RequireAuth loginPath="/login">
                    <MerchantFilterContext.Provider
                      value={{ merchantFilters, setMerchantFilters }}
                    >
                      <Dashboard />
                    </MerchantFilterContext.Provider>
                  </RequireAuth>
                }
              />
              <Route
                path="/reports"
                element={
                  <RequireAuth loginPath="/login">
                    <Reports />
                  </RequireAuth>
                }
              />
              <Route
                path="/merchant-reports"
                element={
                  <RequireAuth loginPath="/login">
                    <Looker />
                  </RequireAuth>
                }
              />
              <Route
                path="/products"
                element={
                  <RequireAuth loginPath="/login">
                    <ProductFilterContext.Provider
                      value={{ productFilters, setProductFilters }}
                    >
                      <Products />
                    </ProductFilterContext.Provider>
                  </RequireAuth>
                }
              />
              <Route
                path="/products/:id"
                element={
                  <RequireAuth loginPath="/login">
                    <ProductFilterContext.Provider
                      value={{ productFilters, setProductFilters }}
                    >
                      <ProductDetail />
                    </ProductFilterContext.Provider>
                  </RequireAuth>
                }
              />
              <Route
                path="/product-edit/:id"
                element={
                  <RequireAuth loginPath="/login">
                    <ProductUpdate />
                  </RequireAuth>
                }
              />
              <Route
                path="/product-status/:id"
                element={
                  <RequireAuth loginPath="/login">
                    <ProductStatusUpdate />
                  </RequireAuth>
                }
              />
              <Route
                path="/categories"
                element={
                  <RequireAuth loginPath="/login">
                    <Categories />
                  </RequireAuth>
                }
              />
              <Route
                path="/warehouses"
                element={
                  <RequireAuth loginPath="/login">
                    <Warehouses />
                  </RequireAuth>
                }
              />
              <Route
                path="/stocks"
                element={
                  <RequireAuth loginPath="/login">
                    <StockFilterContext.Provider
                      value={{ stockFilters, setStockFilters }}
                    >
                      <Stocks />
                    </StockFilterContext.Provider>
                  </RequireAuth>
                }
              />
              <Route
                path="/stock-update/:id"
                element={
                  <RequireAuth loginPath="/login">
                    <StockUpdate />
                  </RequireAuth>
                }
              />
              <Route
                path="/merchants/:id"
                element={
                  <RequireAuth loginPath="/login">
                    <Merchants />
                  </RequireAuth>
                }
              />
              <Route
                path="/edit-merchant/:id"
                element={
                  <RequireAuth loginPath="/login">
                    <EditMerchant />
                  </RequireAuth>
                }
              />
              <Route
                path="/fbmm"
                element={
                  <RequireAuth loginPath="/login">
                    <FbmmFilterContext.Provider
                      value={{ fbmmFilters, setFbmmFilters }}
                    >
                      <Fbmm />
                    </FbmmFilterContext.Provider>
                  </RequireAuth>
                }
              />
              <Route
                path="/fbmm/:id"
                element={
                  <RequireAuth loginPath="/login">
                    <FbmmDetail />
                  </RequireAuth>
                }
              />
              <Route
                path="/iyzico-request-result"
                element={
                  <RequireAuth loginPath="/login">
                    <IyzicoResult />
                  </RequireAuth>
                }
              />
              <Route
                path="/fbmm-status/:id"
                element={
                  <RequireAuth loginPath="/login">
                    <FbmmStatusUpdate />
                  </RequireAuth>
                }
              />
              <Route
                path="/fbmm-cargo/:id"
                element={
                  <RequireAuth loginPath="/login">
                    <FbmmCargoUpdate />
                  </RequireAuth>
                }
              />

              <Route
                path="/payment"
                element={
                  <RequireAuth loginPath="/payment">
                    <UserSelectedPackageContext.Provider
                      value={{ userSelectedPackage, setUserSelectedPackage }}
                    >
                      <Payment />
                    </UserSelectedPackageContext.Provider>
                  </RequireAuth>
                }
              />
              <Route
                path="/payments"
                element={
                  <RequireAuth loginPath="/payments">
                    <UserSelectedPackageContext.Provider
                      value={{ userSelectedPackage, setUserSelectedPackage }}
                    >
                      <Payments />
                    </UserSelectedPackageContext.Provider>
                  </RequireAuth>
                }
              />
              <Route path="/unsubscribe" element={<Unsubscribe />} />
              <Route
                path="/promotion-code"
                element={
                  <RequireAuth loginPath="/login">
                    <PromotionCode />
                  </RequireAuth>
                }
              />
              <Route
                path="/logs"
                element={
                  <RequireAuth loginPath="/login">
                    <Logs />
                  </RequireAuth>
                }
              />
              <Route
                path="/payment-information"
                element={
                  <RequireAuth loginPath="/payment-information">
                    <PaymentInformation />
                  </RequireAuth>
                }
              />
              <Route
                path="/pct"
                element={
                  <RequireAuth loginPath="/login">
                    <PriceCalculationTool />
                  </RequireAuth>
                }
              />
              <Route
                path="/faq"
                element={
                  <RequireAuth loginPath="/login">
                    <Faq />
                  </RequireAuth>
                }
              />
              <Route
                path="/packages"
                element={
                  <RequireAuth loginPath="/login">
                    <Packages />
                  </RequireAuth>
                }
              />
              <Route
                path="/package/:id?"
                element={
                  <RequireAuth loginPath="/login">
                    <Package />
                  </RequireAuth>
                }
              />
              <Route
                path="/return"
                element={
                  <RequireAuth loginPath="/return">
                    <UserSelectedPackageContext.Provider
                      value={{ userSelectedPackage }}
                    >
                      <Return />
                    </UserSelectedPackageContext.Provider>
                  </RequireAuth>
                }
              />
              <Route path="/signup" element={<Signup />} />
              <Route path="/faqs" element={<Faqs />} />
              <Route exact={true} path="/login" element={<SignIn />} />
              <Route path="/set-password" element={<ResetPassword />} />
              <Route path="/forget-password" element={<ForgetPassword />} />
              <Route path="/update-password" element={<UpdatePassword />} />
              <Route path="*" element={<NotFound />} />
            </Routes>
          </main>
        </div>
      </LanguageProvider>
    </ThemeProvider>
  );
}

export default App;
