import React from "react";
import Box from "@mui/material/Box";
import FormControl from "@mui/material/FormControl";
import CustomLabel from "./form-elements/CustomLabel";
import CustomInput from "./form-elements/CustomInput";
import BoxTitle from "./form-elements/BoxTitle";
import FormGroup from "./form-elements/FormGroup";
import CustomSubmitButton from "./form-elements/CustomSubmitButton";
import { useTranslation } from "react-i18next";

const StockForm = () => {
  const { t } = useTranslation();
  return (
    <Box
      component="form"
      sx={{
        backgroundColor: "#fff",
        p: 2,
        mt: 2,
        "& > :not(style)": { m: 1 },
      }}
      noValidate
      autoComplete="off"
    >
      <FormGroup>
        <BoxTitle title={t("skuAndBarcode")} />
        <FormControl variant="standard">
          <CustomLabel title={t("sku")} isRequired />
          <CustomInput placeholder={t("sku")} />
        </FormControl>
        <FormControl variant="standard">
          <CustomLabel title={t("quantity")} isRequired />
          <CustomInput placeholder={t("quantity")} />
        </FormControl>
        <FormControl variant="standard">
          <CustomLabel title={t("shelf")} isRequired />
          <CustomInput placeholder={t("shelf")} />
        </FormControl>
        <FormControl variant="standard">
          <CustomLabel title={t("note")} isRequired />
          <CustomInput placeholder={t("note")} isMultiline />
        </FormControl>
      </FormGroup>
      <CustomSubmitButton buttonText={t("save")} />
    </Box>
  );
};

export default StockForm;
