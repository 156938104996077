import React from "react";

const LabelIcon = () => {
  return (
    <svg
      width="29"
      height="29"
      viewBox="0 0 29 29"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="29" height="29" rx="2" fill="#E5E5E5" fillOpacity="0.6" />
      <g clipPath="url(#clip0_1_4)">
        <path
          d="M11.6613 21.5638C11.3633 21.8431 10.9591 22 10.5378 22C10.1164 22 9.71228 21.8431 9.41428 21.5638L3.71579 16.2223C3.56813 16.0839 3.45099 15.9197 3.37107 15.7389C3.29114 15.558 3.25 15.3643 3.25 15.1685C3.25 14.9728 3.29114 14.779 3.37107 14.5982C3.45099 14.4173 3.56813 14.2531 3.71579 14.1147L14.8395 3.68602C14.987 3.5477 15.1624 3.438 15.3552 3.36318C15.5481 3.28837 15.7548 3.24991 15.9635 3.25H21.6609C22.0823 3.25 22.4865 3.40696 22.7845 3.68635C23.0825 3.96575 23.25 4.34469 23.25 4.73981V10.0823C23.2499 10.4771 23.0826 10.8558 22.7849 11.1351"
          stroke="#453834"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M18.25 7C18.9403 7 19.5 7 19.5 7C19.5 7 18.9403 7 18.25 7"
          stroke="#453834"
        />
        <path
          d="M19.5 25.75C22.9518 25.75 25.75 22.9518 25.75 19.5C25.75 16.0482 22.9518 13.25 19.5 13.25C16.0482 13.25 13.25 16.0482 13.25 19.5C13.25 22.9518 16.0482 25.75 19.5 25.75Z"
          stroke="#453834"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M19.5 17V22"
          stroke="#453834"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M17 19.5L19.5 22L22 19.5"
          stroke="#453834"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_1_4">
          <rect
            width="25"
            height="25"
            fill="white"
            transform="translate(2 2)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export default LabelIcon;
