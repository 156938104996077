import React from "react";

const ProparsIcon = () => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="16" height="16" rx="2" fill="#EB603A" />
      <path
        d="M5.2642 13.4545V4.45455H6.2358V5.49432H6.35511C6.42898 5.38068 6.53125 5.2358 6.66193 5.05966C6.79545 4.88068 6.9858 4.72159 7.23295 4.58239C7.48295 4.44034 7.82102 4.36932 8.24716 4.36932C8.7983 4.36932 9.28409 4.5071 9.70455 4.78267C10.125 5.05824 10.4531 5.44886 10.6889 5.95455C10.9247 6.46023 11.0426 7.05682 11.0426 7.74432C11.0426 8.4375 10.9247 9.03835 10.6889 9.54688C10.4531 10.0526 10.1264 10.4446 9.70881 10.723C9.29119 10.9986 8.80966 11.1364 8.2642 11.1364C7.84375 11.1364 7.5071 11.0668 7.25426 10.9276C7.00142 10.7855 6.80682 10.625 6.67045 10.446C6.53409 10.2642 6.42898 10.1136 6.35511 9.99432H6.26989V13.4545H5.2642ZM6.25284 7.72727C6.25284 8.22159 6.32528 8.65767 6.47017 9.03551C6.61506 9.41051 6.8267 9.70455 7.10511 9.91761C7.38352 10.1278 7.72443 10.233 8.12784 10.233C8.5483 10.233 8.89915 10.1222 9.1804 9.90057C9.46449 9.67614 9.67756 9.375 9.8196 8.99716C9.96449 8.61648 10.0369 8.19318 10.0369 7.72727C10.0369 7.26705 9.96591 6.85227 9.82386 6.48295C9.68466 6.1108 9.47301 5.81676 9.18892 5.60085C8.90767 5.3821 8.55398 5.27273 8.12784 5.27273C7.71875 5.27273 7.375 5.37642 7.09659 5.58381C6.81818 5.78835 6.60795 6.07528 6.46591 6.4446C6.32386 6.81108 6.25284 7.23864 6.25284 7.72727Z"
        fill="white"
      />
    </svg>
  );
};

export default ProparsIcon;
