import Cookies from "js-cookie";
import {
  Box,
  Divider,
  IconButton,
  Typography,
  Paper,
  Button,
  Chip,
  LinearProgress,
  useTheme,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  styled,
  CircularProgress,
  Alert,
} from "@mui/material";
import axios from "axios";
import { useAuthUser } from "react-auth-kit";
import React, { useCallback, useEffect, useState } from "react";
import FormGroup from "./form-elements/FormGroup";
import BoxTitle from "./form-elements/BoxTitle";
import { Download } from "@mui/icons-material";
import { useDropzone } from "react-dropzone";
import { Link, useNavigate } from "react-router-dom";
import SyncIcon from "@mui/icons-material/Sync";
import TableList from "../partials/TableList";
import CloseIcon from "@mui/icons-material/Close";
import DescriptionIcon from "@mui/icons-material/Description";
import { useTranslation } from "react-i18next";
const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

function BootstrapDialogTitle(props) {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: theme => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
}

const uploadStatus = [
  {
    id: "99d90c85-7368-4b8f-ae7c-f96e0d655ae1",
    title: "Yeni",
  },
  {
    id: "6e8db3de-e1d6-40fe-a222-583ec3972512",
    title: "İşleniyor",
  },
  {
    id: "734520c6-1225-4e47-b122-f8886a2544ad",
    title: "Tamamlandı",
  },
  {
    id: "be217795-88d2-4a4f-9a9b-8e7c8ae4c9bb",
    title: "Hata İle Tamamlandı",
  },
];

const MultiProductForm = props => {
  const [isFileUploading, setIsFileUploading] = useState(false);
  const theme = useTheme();
  const { funcs } = props;
  const navigate = useNavigate();
  const auth = useAuthUser();
  const { t } = useTranslation();
  const [progress, setProgress] = useState(0);
  const [uploadedFile, setUploadedFile] = useState(null);
  const [uploadError, setUploadError] = useState(null);
  const [successMessage, setSuccessMessage] = useState("");
  const [isSnackbarOpen, setSnackbarOpen] = useState(false);
  const [open, setOpen] = useState(false);
  const [isSuccess, setSuccess] = useState(false);
  const [activeRow, setActiveRow] = useState([]);
  const [activeRowData, setActiveRowData] = useState([]);
  const [activeRowDataError, setActiveRowDataError] = useState([]);
  const [activeRowDataLoading, setActiveRowDataLoading] = useState(false);
  const [pageState, setPageState] = useState({
    isLoading: false,
    data: [],
    total: 0,
    page: 0,
    pageSize: 5,
  });
  const onDrop = useCallback(async acceptedFiles => {
    if (acceptedFiles.length === 1) {
      setUploadedFile(acceptedFiles[0]);
    } else {
      setUploadError(t("youCanOnlyUploadOneFile"));
    }
  }, []);

  const dataColumns = [
    {
      field: "fileOrginalName",
      headerName: t("productName"),
      width: 230,
      filterable: false,
    },
    {
      field: "success",
      headerName: t("success"),
      align: "center",
      width: 60,
      filterable: false,
      renderCell: params => {
        return (
          <Typography variant="body2" color={theme.palette.success.main}>
            {params.value}
          </Typography>
        );
      },
    },
    {
      field: "fail",
      headerName: t("incorrect"),
      align: "center",
      width: 60,
      filterable: false,
      renderCell: params => {
        return (
          <Typography variant="body2" color={theme.palette.error.main}>
            {params.value}
          </Typography>
        );
      },
    },
    {
      field: "productBulkFileStatusId",
      headerName: t("status"),
      filterable: false,
      width: 140,
      renderCell: params => {
        return (
          <Typography variant="body2">
            <Button
              color="text"
              sx={{
                textTransform: "capitalize",
                color: theme.palette.primary.primaryBlue,
              }}
              onClick={() => handleClickOpen(params.row)}
            >
              {
                uploadStatus.find(
                  status => status.id === params.row.productBulkFileStatusId
                ).title
              }
            </Button>
          </Typography>
        );
      },
    },
  ];

  const { getRootProps, getInputProps, isDragActive, isDragReject } =
    useDropzone({
      onDrop,
    });

  const handleDeleteFile = () => {
    setUploadedFile(null);
    setUploadError(null);
  };

  const closeAndRedirect = () => {
    navigate("/products");
    funcs();
  };

  const handleSubmitFile = async e => {
    e.preventDefault();
    setIsFileUploading(true);
    if (uploadedFile) {
      const formData = new FormData();
      formData.append("file", uploadedFile);
      try {
        const res = await axios.post(
          `${process.env.REACT_APP_BASE_URL}/Product/Upload?Id=${
            auth().merchantId
          }`,
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
              Authorization: `Bearer ${Cookies.get("_auth")}`,
            },
            onUploadProgress: progressEvent => {
              const percentCompleted = Math.round(
                (progressEvent.loaded * 100) / progressEvent.total
              );
              setProgress(percentCompleted);
            },
          }
        );
        if (res.status === 200) {
          setSuccessMessage(t("theFileHasBeenUploadedSuccessfull"));
          setSnackbarOpen(true);
          setProgress(0);
          setUploadedFile(null);
          setSuccess(true);
        }
      } catch (error) {
        setUploadError(t("anErrorOccurredWhileUploadingTheFile"));
        console.log(error);
      }
      setProgress(0);
      setUploadedFile(null);
    }
  };

  const handleClickOpen = file => {
    setOpen(true);
    setActiveRow(file);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const merchantId = auth().merchantId;

  let bulkUploadFilesData = "";
  let bulkUploadFilesDataCOnfig = {
    method: "get",
    maxBodyLength: Infinity,
    url: `${process.env.REACT_APP_BASE_URL}/Product/GetProductBulkUploadFiles?Pagination.Page=${pageState.page}&Pagination.Size=${pageState.pageSize}&MerchantId=${merchantId}`,
    headers: {
      Authorization: `Bearer ${Cookies.get("_auth")}`,
    },
    data: bulkUploadFilesData,
  };

  let _activeRowData = "";
  let activeRowDataConfig = {
    method: "get",
    maxBodyLength: Infinity,
    url: `${process.env.REACT_APP_BASE_URL}/Product/GetProductBulkUploadFileLog/${activeRow.id}`,
    headers: {
      Authorization: `Bearer ${Cookies.get("_auth")}`,
    },
    data: _activeRowData,
  };

  const fetchBulkUploadFilesData = async () => {
    setPageState(old => ({ ...old, isLoading: true }));
    const response = await axios.request(bulkUploadFilesDataCOnfig);
    const responseData = await response.data.items;
    const totalCount = await response.data.totalCount;
    setPageState(old => ({
      ...old,
      isLoading: false,
      data: responseData,
      total: totalCount,
    }));
  };

  useEffect(() => {
    fetchBulkUploadFilesData();
  }, [pageState.page, pageState.pageSize, uploadedFile]);

  const fetchActiveRowData = async () => {
    setActiveRowDataLoading(true);
    const response = await axios.request(activeRowDataConfig);
    const responseData = await response.data.items;
    setActiveRowData(responseData);
    setActiveRowDataLoading(false);
  };

  const fetchActiveRowDataError = async () => {
    const getErrorsAndSuccess = await axios.request(activeRowDataConfig);
    const getErrorsAndSuccessData = await getErrorsAndSuccess.data.items;
    setActiveRowDataError(getErrorsAndSuccessData);
  };

  useEffect(() => {
    fetchActiveRowData();
  }, [activeRow]);

  // useEffect(() => {
  //   fetchActiveRowDataError();
  // }, [activeRowData]);
  return (
    <Box sx={{ width: "100%", background: "#fff", mt: 3, p: 2, mb: "50px" }}>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
        }}
      >
        <Typography variant="h2" sx={{ mb: 1, mt: 2 }} color="primary">
          {t("bulkProductTransactions")}
        </Typography>
        <Typography variant="body1">
          {t("youCanUseTheSampleTemplateInTheDownloadFileSectionToAddBulkProducts")}
        </Typography>
        <Typography variant="body1">
          <ul style={{ paddingInlineStart: "20px" }}>
            <li>
              {t("youCanUploadProductInformationBlaBla")}
            </li>
            <li>
              {t("youCanFindDetailedInformationInTheInfoTab")}
            </li>
          </ul>
        </Typography>
      </Box>
      <Box
        sx={{
          backgroundColor: "#fff",
        }}
      >
        <FormGroup className="pb5">
          <BoxTitle
            isDivider={false}
            style={{ fontSize: "14px" }}
            title="1. Dosya İndir"
          />
          <Typography variant="body2" sx={{ mt: 1, mb: 1 }}>
            {t("youCanDownloadTheSampleTemplateFromThisSection")}
          </Typography>
          <Divider />
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
            }}
          >
            <Typography sx={{ fontWeight: "500", mt: "10px" }} variant="body2">
              {t("megaSampleExcellTemplate")}
            </Typography>
            <IconButton
              edge="start"
              color="text"
              aria-label="upload picture"
              href="/urun-ekleme.xlsx"
              download
            >
              <Download />
            </IconButton>
          </Box>
        </FormGroup>
        <FormGroup className="pb5 mt10">
          <BoxTitle
            isDivider={false}
            style={{ fontSize: "14px" }}
            title={t("secondUploadFile")}
          />
          <Typography variant="body2" sx={{ mt: 1, mb: 1 }}>
            {t("youCanAddTheFileYouEditedFromThisSection")}
          </Typography>
          <Divider />
          {uploadError && (
            <Box
              sx={{
                pb: 0,
                mt: 1.5,
                ml: "1.5em",
                mr: "1.5em  ",
                width: "100%",
              }}
              display="block"
            >
              <Alert
                severity="error"
                sx={{
                  fontSize: "14px",
                  fontWeight: "500",
                  width: "100%",
                  textAlign: "center",
                  border: "1px solid #f44336",
                }}
              >
                {uploadError}
              </Alert>
            </Box>
          )}
          <Box sx={{ width: "100%", background: "#fff", mt: 3, mb: "10px" }}>
            <Box>
              <Paper
                elevation={2}
                sx={{
                  padding: 3,
                  textAlign: "center",
                  border: "2px dashed #ddd",
                  borderRadius: "8px",
                }}
              >
                <div
                  {...getRootProps()}
                  className={`dropzone ${isDragActive ? "active" : ""} ${
                    isDragReject ? "reject" : ""
                  }`}
                >
                  <input {...getInputProps()} />
                  <Typography variant="h6" gutterBottom>
                    {t("fileUpload")}
                  </Typography>
                  <Typography variant="body2" color="textSecondary">
                    {t("dragYourFilesHereOrClickToSelectThem")}
                  </Typography>
                </div>

                {uploadedFile && (
                  <div className="uploaded-file">
                    <Chip
                      label={uploadedFile.name}
                      onDelete={handleDeleteFile}
                      sx={{ margin: "0.2rem" }}
                    />
                  </div>
                )}
                {progress > 0 && (
                  <LinearProgress
                    variant="determinate"
                    value={progress}
                    sx={{ marginTop: 2 }}
                  />
                )}
              </Paper>
            </Box>
          </Box>
        </FormGroup>
        <FormGroup className="pb5 mt10">
          <BoxTitle
            isDivider={false}
            style={{ fontSize: "14px" }}
            title="3. İşlenen Dosyalar"
          />
          <Typography variant="body2" sx={{ mt: 1, mb: 1 }}>
            {t("yourProcessWillContinueInTheBackgroundYouCanCloseThePage")}
          </Typography>
          <Divider />
          <TableList
            className="upload-list"
            defaultPageSize={[5]}
            style={{ overFlow: "hidden!important" }}
            key={pageState.data.id}
            rows={pageState.data}
            columns={dataColumns}
            pageState={pageState}
            setPageState={setPageState}
            rowHeight={40}
            isToolbar={false}
          />
        </FormGroup>

        {successMessage && (
          <Box>
            {
              <Typography
                sx={{
                  color: "#4caf50",
                  fontWeight: "500",
                  mt: 2,
                  mb: 2,
                  display: isSuccess ? "block" : "none",
                }}
                variant="body2"
              >
                {successMessage}
              </Typography>
            }
          </Box>
        )}
        <Divider />
        <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
          {!isSuccess ? (
            <Button
              className="filterApply"
              sx={{ mt: 2 }}
              onClick={handleSubmitFile}
              disabled={!uploadedFile}
            >
              {t("load")}
            </Button>
          ) : (
            <Button
              className="filterApply"
              onClick={closeAndRedirect}
              sx={{ mt: 2 }}
            >
              {t("close")}
            </Button>
          )}
        </Box>
      </Box>
      <Box>
        <BootstrapDialog
          onClose={handleClose}
          aria-labelledby="customized-dialog-title"
          open={open}
          maxWidth="md"
        >
          <BootstrapDialogTitle
            id="customized-dialog-title"
            onClose={handleClose}
            color="primary"
            style={{ marginBottin: "0!important" }}
          >
            {t("processDetails")}
            <Typography variant="body2" sx={{ color: theme.palette.text.main }}>
              {t("theDetailsOfTheFileYouHaveUploadedAreBelow")}
            </Typography>
          </BootstrapDialogTitle>

          <DialogContent dividers>
            <Box>
              <FormGroup className="pb-0">
                <Typography
                  variant="body2"
                  color={{ color: theme.palette.primary.main }}
                  sx={{ fontWeight: "500" }}
                >
                  {t("processedFile")}
                </Typography>
                <Divider />
                <Box
                  sx={{
                    paddingX: 2,
                    paddingY: 1,
                    mt: 2,
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    border: `1.5px solid ${theme.palette.text.main}`,
                    borderRadius: "4px",
                  }}
                >
                  <DescriptionIcon />
                  <Typography variant="body2" sx={{ ml: 1, fontWeight: "500" }}>
                    {activeRow.fileOrginalName}
                  </Typography>
                </Box>
                <Box sx={{ display: "flex", flexDirection: "row", mt: 1 }}>
                  <Box sx={{ display: "flex", flexDirection: "column" }}>
                    <Typography
                      variant="body2"
                      sx={{ mt: 2, fontWeight: "500" }}
                    >
                      {t("additionDate")}
                    </Typography>
                    <Typography variant="body1">25.11.2020 22:56</Typography>
                  </Box>
                  <Divider
                    orientation="vertical"
                    variant="middle"
                    sx={{ marginX: 2 }}
                    flexItem
                  />
                  <Box sx={{ display: "flex", flexDirection: "column" }}>
                    <Typography
                      variant="body2"
                      sx={{ mt: 2, fontWeight: "500" }}
                    >
                      {t("uploadDate")}
                    </Typography>
                    <Typography variant="body1" sx={{ mb: 2 }}>
                      25.11.2020 22:56
                    </Typography>
                  </Box>
                  <Divider
                    orientation="vertical"
                    variant="middle"
                    sx={{ marginX: 2 }}
                    flexItem
                  />
                  <Box sx={{ display: "flex", flexDirection: "column" }}>
                    <Typography
                      variant="body2"
                      sx={{ mt: 2, fontWeight: "500" }}
                    >
                      {t("totalProduct")}
                    </Typography>
                    <Typography variant="body1" sx={{ mb: 2 }}>
                      {activeRow.success + activeRow.fail}
                    </Typography>
                  </Box>
                  <Divider
                    orientation="vertical"
                    variant="middle"
                    sx={{ marginX: 2 }}
                    flexItem
                  />
                  <Box sx={{ display: "flex", flexDirection: "column" }}>
                    <Typography
                      variant="body2"
                      sx={{ mt: 2, fontWeight: "500" }}
                    >
                      {t("success")}
                    </Typography>
                    <Typography
                      variant="body1"
                      sx={{ mb: 2, color: theme.palette.success.main }}
                    >
                      {activeRow.success}
                    </Typography>
                  </Box>
                  <Divider
                    orientation="vertical"
                    variant="middle"
                    sx={{ marginX: 2 }}
                    flexItem
                  />
                  <Box sx={{ display: "flex", flexDirection: "column" }}>
                    <Typography
                      variant="body2"
                      sx={{ mt: 2, fontWeight: "500" }}
                    >
                      {t("incorrect")}
                    </Typography>
                    <Typography
                      variant="body1"
                      sx={{ mb: 2, color: theme.palette.error.main }}
                    >
                      {activeRow.fail}
                    </Typography>
                  </Box>
                </Box>
              </FormGroup>
            </Box>
            {activeRowDataLoading ? (
              <Box
                sx={{
                  mt: 2,
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <CircularProgress />
              </Box>
            ) : (
              <TableContainer
                sx={{
                  marginTop: "10px!important",
                  boxShadow: "none!important",
                  borderBottom: "none!important",
                  borderRadius: "2!important",
                }}
                component={Paper}
              >
                <Table sx={{ minWidth: 600 }} aria-label="simple table">
                  <TableHead sx={{ border: "1px solid #e0e0e0" }}>
                    <TableRow>
                      <TableCell align="left">{t("sku")}</TableCell>
                      <TableCell align="left">{t("errorField")}</TableCell>
                      <TableCell align="left">{t("message")}</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {activeRowData.map(row => (
                      <TableRow className="stock-table" key={row.sku}>
                        <TableCell>{row.sku}</TableCell>
                        <TableCell align="left"> {row.field}</TableCell>
                        <TableCell align="left">{row.errorMessage}</TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            )}
          </DialogContent>
          <DialogActions sx={{ justifyContent: "flex-end" }}>
            <Button autoFocus onClick={handleClose} className="filterApply">
              {t("close")}
            </Button>
          </DialogActions>
        </BootstrapDialog>
      </Box>
    </Box>
  );
};

export default MultiProductForm;
