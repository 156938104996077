import React from "react";

const PaymentInformationIcon = ({ width = 16, height = 16 }) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M13.344 1.33337C12.602 1.33337 12 3.12404 12 5.33337H13.344C13.992 5.33337 14.3153 5.33337 14.516 5.11004C14.716 4.88604 14.6813 4.59137 14.612 4.00271C14.4267 2.44671 13.9293 1.33337 13.344 1.33337Z"
      stroke="#453834"
      stroke-width="1.2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M12.0007 5.36937V12.4307C12.0007 13.438 12.0007 13.942 11.6927 14.1407C11.1893 14.4647 10.4113 13.7847 10.02 13.538C9.69665 13.334 9.53532 13.2327 9.35598 13.2267C9.16198 13.22 8.99732 13.318 8.64798 13.538L7.37398 14.3414C7.02998 14.558 6.85865 14.6667 6.66732 14.6667C6.47598 14.6667 6.30398 14.558 5.96065 14.3414L4.68732 13.538C4.36332 13.334 4.20198 13.2327 4.02265 13.2267C3.82865 13.22 3.66398 13.318 3.31465 13.538C2.92332 13.7847 2.14532 14.4647 1.64132 14.1407C1.33398 13.942 1.33398 13.4387 1.33398 12.4307V5.36937C1.33398 3.46671 1.33398 2.51604 1.91998 1.92471C2.50532 1.33337 3.44865 1.33337 5.33398 1.33337H13.334"
      stroke="#453834"
      stroke-width="1.2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M8 10H7.6C7.38783 10 7.18434 9.89464 7.03431 9.70711C6.88429 9.51957 6.8 9.26522 6.8 9V6.5C6.8 6.36739 6.75786 6.24022 6.68284 6.14645C6.60783 6.05268 6.50609 6 6.4 6H6"
      stroke="#453834"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M6.5 3.22217C6.35166 3.22217 6.20666 3.26615 6.08332 3.34857C5.95999 3.43098 5.86386 3.54811 5.80709 3.68516C5.75032 3.8222 5.73547 3.973 5.76441 4.11849C5.79335 4.26397 5.86478 4.39761 5.96967 4.5025C6.07456 4.60739 6.2082 4.67882 6.35368 4.70776C6.49917 4.7367 6.64997 4.72184 6.78701 4.66508C6.92406 4.60831 7.04119 4.51218 7.1236 4.38885C7.20601 4.26551 7.25 4.1205 7.25 3.97217C7.25 3.77326 7.17098 3.58249 7.03033 3.44184C6.88968 3.30119 6.69891 3.22217 6.5 3.22217Z"
      fill="#453834"
    />
  </svg>
);

export default PaymentInformationIcon;
