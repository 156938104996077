import axios from "axios";
import Cookies from "js-cookie";

const API_BASE_URL = process.env.REACT_APP_BASE_URL;

const apiService = async ({
  endpoint,
  query = "",
  method,
  body = null,
  headers = {},
}) => {
  try {
    const response = await axios({
      url: `${API_BASE_URL}${endpoint}${query}`,
      method,
      headers: { Authorization: `Bearer ${Cookies.get("_auth")}`, ...headers },
      data: body,
    });
    return response.data;
  } catch (error) {
    console.error("API call error:", error);
    throw error;
  }
};

export default apiService;
