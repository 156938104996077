import React from "react";

const MegaLogo = () => {
  return (
    <svg
      width="36"
      height="29"
      viewBox="0 0 36 29"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_475_3961)">
        <path
          d="M21.7761 1.48147L11.0091 7.60707L13.4743 9.00994L23.0099 3.58552C24.705 2.62036 25.1893 2.92176 25.1893 5.14505V24.7411L30.0589 27.5118C34.7484 30.1784 35.9374 28.9797 35.9374 23.8478V9.97743C35.9374 4.84534 34.7484 3.64546 30.0589 6.31385L29.0409 6.89281V9.69856L31.2916 8.41739C32.9878 7.4521 33.471 7.75453 33.4722 9.97743V23.8478C33.471 26.0711 32.9878 26.3719 31.2916 25.4072L27.6545 23.3388V5.14505C27.6545 1.7782 27.1434 0.104095 25.4668 0.104095C24.5881 0.104095 23.389 0.564212 21.7761 1.48147ZM0.0849609 9.97743V23.8478C0.0849609 28.9797 1.27335 30.1784 5.96281 27.5118L10.8329 24.7411V13.4668L8.36719 12.0639V23.3388L4.72962 25.4072C3.03394 26.3719 2.55004 26.0711 2.55004 23.8478V9.97743C2.55004 7.75453 3.03394 7.4521 4.72962 8.41739L18.0111 15.9734L23.8029 12.6779V9.87269L18.0111 13.1677L5.96281 6.31385C4.35017 5.39621 3.15114 4.93596 2.27255 4.93596C0.596056 4.9357 0.0849609 6.61006 0.0849609 9.97743Z"
          fill="url(#paint0_linear_475_3961)"
        />
      </g>
      <defs>
        <linearGradient
          id="paint0_linear_475_3961"
          x1="18.0112"
          y1="0.104095"
          x2="18.0112"
          y2="28.8888"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#EB603A" />
          <stop offset="1" stopColor="#D7283E" />
        </linearGradient>
        <clipPath id="clip0_475_3961">
          <rect width="36" height="29" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default MegaLogo;
