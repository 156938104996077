import React from "react";

const FbmmIcon = () => {
  return (
    <svg
      width="36"
      height="36"
      viewBox="0 0 36 36"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="36" height="36" rx="5" fill="#FAF8F8" />
      <g clipPath="url(#clip0_191_594)">
        <path
          d="M11.9307 25.7743H21.9668C22.3253 25.7744 22.6721 25.6436 22.9461 25.405C23.2199 25.1666 23.403 24.8359 23.463 24.4714L26.1306 8.30191C26.1908 7.93768 26.374 7.60715 26.6479 7.36887C26.9217 7.13059 27.2685 6.99992 27.6268 7H28.6222"
          stroke="#453834"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M13.8276 28.904C13.6181 28.904 13.4482 28.7289 13.4482 28.5128C13.4482 28.2968 13.6181 28.1216 13.8276 28.1216"
          stroke="#453834"
          strokeWidth="1.5"
        />
        <path
          d="M13.8271 28.904C14.0367 28.904 14.2065 28.7289 14.2065 28.5128C14.2065 28.2968 14.0367 28.1216 13.8271 28.1216"
          stroke="#453834"
          strokeWidth="1.5"
        />
        <path
          d="M21.4145 28.904C21.205 28.904 21.0352 28.7289 21.0352 28.5128C21.0352 28.2968 21.205 28.1216 21.4145 28.1216"
          stroke="#453834"
          strokeWidth="1.5"
        />
        <path
          d="M21.4141 28.904C21.6236 28.904 21.7934 28.7289 21.7934 28.5128C21.7934 28.2968 21.6236 28.1216 21.4141 28.1216"
          stroke="#453834"
          strokeWidth="1.5"
        />
        <path
          d="M24.0223 21.0802H11.8112C11.1345 21.0801 10.4773 20.8468 9.94392 20.4173C9.41054 19.988 9.03165 19.387 8.86744 18.7101L7.40061 12.6595C7.37262 12.5441 7.37051 12.4237 7.39444 12.3073C7.41837 12.191 7.46772 12.0818 7.53872 11.988C7.60972 11.8943 7.70051 11.8185 7.8042 11.7664C7.90788 11.7142 8.02172 11.6872 8.13706 11.6873H25.5711"
          stroke="#453834"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M11.9307 14.8209V17.1681"
          stroke="#453834"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M16.4824 14.8209V17.1681"
          stroke="#453834"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M21.0352 14.8209V17.1681"
          stroke="#453834"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_191_594">
          <rect
            width="24"
            height="24"
            fill="white"
            transform="translate(6 6)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export default FbmmIcon;
