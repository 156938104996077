import React from "react";

const DashboardIcon = () => {
  return (
    <svg
      width="36"
      height="36"
      viewBox="0 0 36 36"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="36" height="36" rx="5" fill="#FAF8F8" />
      <path
        d="M9.90234 19.9089V28.0093H15.7936V22.1181C15.7936 21.7275 15.9488 21.3529 16.225 21.0767C16.5012 20.8005 16.8758 20.6453 17.2664 20.6453H18.7392C19.1298 20.6453 19.5045 20.8005 19.7807 21.0767C20.0569 21.3529 20.212 21.7275 20.212 22.1181V28.0093H26.1033V19.9089"
        stroke="#453834"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M6.95703 18.436L16.9614 8.43171C17.0981 8.29485 17.2605 8.18628 17.4393 8.1122C17.618 8.03813 17.8096 8 18.0031 8C18.1966 8 18.3882 8.03813 18.567 8.1122C18.7457 8.18628 18.9081 8.29485 19.0449 8.43171L29.0492 18.436"
        stroke="#453834"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default DashboardIcon;
