import React, { useEffect, useState } from "react";
import { putControllerUnsubscribe } from "../api";
import { useNavigate, useSearchParams } from "react-router-dom";
import { Modal } from "../common";
// Icons
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CancelIcon from "@mui/icons-material/Cancel";
import { useTranslation } from "react-i18next";
import { Box, Typography } from "@mui/material";

const Unsubscribe = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [controllerUnsubscribeResult, setControllerUnsubscribeResult] =
    useState({
      success: null,
      message: "",
    });
  const [searchParams, setSearchParams] = useSearchParams();
  const code = searchParams.get("code");
  const email = searchParams.get("mail");

  useEffect(() => {
    const body = { email, code };
    putControllerUnsubscribe({ body })
      .then((response) => {
        setControllerUnsubscribeResult({
          success: true,
          message: "Eposta listesinden çıktınız.",
        });
      })
      .catch((error) => {
        setControllerUnsubscribeResult({
          success: false,
          message: t("anErrorOccurred"),
        });
      })
      .finally(() => setIsModalOpen(true));
  }, []);
  return (
    <Modal
      onClose={() => {
        navigate("/login");
        setIsModalOpen(false);
      }}
      open={isModalOpen}
      title={t("Eposta listesi")}
    >
      <Box
        p={2}
        pl={0}
        color={`${controllerUnsubscribeResult.success ? "#1eb51e" : "red"}`}
        display="flex"
        alignItems="center"
        justifyContent="flex-start"
      >
        {controllerUnsubscribeResult.success ? (
          <CheckCircleIcon />
        ) : (
          <CancelIcon />
        )}
        <Typography
          variant="body1"
          sx={{
            ml: 1,
            fontWeight: "500",

            color: `${controllerUnsubscribeResult.success ? "#1eb51e" : "red"}`,
          }}
        >
          {controllerUnsubscribeResult.message}
        </Typography>
      </Box>
    </Modal>
  );
};

export default Unsubscribe;
