import React from "react";

const FilterIcon = () => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M18.3421 2.59607C18.3471 2.51976 18.3364 2.44324 18.3106 2.37123C18.2849 2.29922 18.2447 2.23325 18.1925 2.1774C18.1402 2.12154 18.0771 2.07698 18.007 2.04647C17.9369 2.01596 17.8612 2.00014 17.7848 2H2.1738C2.0973 2.00003 2.02162 2.01578 1.95145 2.04625C1.88128 2.07672 1.81811 2.12128 1.76586 2.17716C1.71362 2.23304 1.6734 2.29905 1.6477 2.37111C1.622 2.44317 1.61138 2.51973 1.61648 2.59607C1.74752 4.42078 2.47172 6.15257 3.67864 7.52738C4.88557 8.90218 6.50899 9.84456 8.30136 10.2108V17.5685C8.3014 17.6747 8.33169 17.7787 8.38869 17.8682C8.44568 17.9578 8.52702 18.0293 8.62318 18.0744C8.71935 18.1194 8.82635 18.1361 8.93166 18.1225C9.03696 18.1089 9.13623 18.0656 9.21781 17.9977L11.4531 16.135C11.5161 16.0826 11.5668 16.017 11.6015 15.9428C11.6363 15.8687 11.6543 15.7877 11.6542 15.7058V10.2108C13.4472 9.84525 15.0714 8.90315 16.2789 7.52828C17.4864 6.1534 18.211 4.42123 18.3421 2.59607V2.59607Z"
        stroke="#453834"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default FilterIcon;
