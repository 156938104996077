import React, { useState } from "react";
import IconButton from "@mui/material/IconButton";
import ImageListItem from "@mui/material/ImageListItem";
import Tooltip from "@mui/material/Tooltip";

import {
  Box,
  Typography,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Divider,
  Button,
  useTheme,
} from "@mui/material";
import { Check } from "@mui/icons-material";
import { useTranslation } from "react-i18next";
import NewAdvertisingPackagesTooltipIcon from "../icons/NewAdvertisingPackagesTooltipIcon";
import { Modal } from "../../common";
import LongDistanceSellingAgreement from "../Agreements/LongDistanceSellingAgreement";

const PackagesBox = ({
  title,
  regions,
  marketingTypes,
  returnGuarantee,
  url,
  price,
  isYearly,
  onClick,
}) => {
  const theme = useTheme();

  const { t } = useTranslation();
  const [isModalOpen, setIsModalOpen] = useState(false);
  return (
    <>
      <Box
        sx={{
          borderRadius: "2px",
          border: "1px solid #45383426",
          p: 2,
          mb: 2,
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Typography
          color="primary"
          variant="body"
          sx={{
            fontSize: "14px",
            fontWeight: "bold",
          }}
        >
          {title}
        </Typography>
        <List dense>
          <ListItem
            sx={{
              padding: "0px!important",
              margin: "0px!important",
            }}
          >
            <ListItemIcon
              sx={{
                minWidth: "auto",
                marginRight: "10px",
              }}
            >
              <Check color="success" />
            </ListItemIcon>
            <ListItemText primary={`${regions} Bölge`} />
          </ListItem>
        </List>
        <Divider
          sx={{
            width: "100%!important",
            margin: "10px 0",
          }}
        />
        <Typography
          color="text"
          variant="body"
          sx={{
            fontSize: "14px",
            fontWeight: "600",
          }}
        >
          {t("marketingTypes")}
        </Typography>
        <List
          sx={{
            height: "110px",
          }}
          dense
        >
          {marketingTypes.map((marketingType, index) => (
            <ListItem
              key={index}
              sx={{
                padding: "0px!important",
                margin: "0px!important",
              }}
            >
              <ListItemIcon
                sx={{
                  minWidth: "auto",
                  marginRight: "10px",
                }}
              >
                <Check color="success" />
              </ListItemIcon>
              <ListItemText primary={marketingType} />
              {marketingType === "Sponsored Products" && (
                <Tooltip
                  PopperProps={{
                    sx: {
                      ".MuiTooltip-tooltip": {
                        bgcolor: "transparent",
                      },
                    },
                  }}
                  title={
                    <Box
                      sx={{
                        width: "300% !important",
                        height: "300% !important",
                      }}
                    >
                      <ImageListItem>
                        <img
                          src="/AdvertisingTooltipImage/products44.png"
                          alt=""
                        />
                      </ImageListItem>
                    </Box>
                  }
                >
                  <IconButton>
                    <NewAdvertisingPackagesTooltipIcon />
                  </IconButton>
                </Tooltip>
              )}
              {marketingType === "Sponsored Brands" && (
                <Tooltip
                  PopperProps={{
                    sx: {
                      ".MuiTooltip-tooltip": {
                        bgcolor: "transparent",
                      },
                    },
                  }}
                  title={
                    <Box
                      sx={{
                        width: "300% !important",
                        height: "300% !important",
                      }}
                    >
                      <ImageListItem>
                        <img
                          src="/AdvertisingTooltipImage/brands44.png"
                          alt=""
                        />
                      </ImageListItem>
                    </Box>
                  }
                >
                  <IconButton>
                    <NewAdvertisingPackagesTooltipIcon />
                  </IconButton>
                </Tooltip>
              )}
              {marketingType === "Display Ads" && (
                <Tooltip
                  PopperProps={{
                    sx: {
                      ".MuiTooltip-tooltip": {
                        bgcolor: "transparent",
                      },
                    },
                  }}
                  title={
                    <Box
                      sx={{
                        width: "300% !important",
                        height: "300% !important",
                      }}
                    >
                      <ImageListItem>
                        <img
                          src="/AdvertisingTooltipImage/display44.png"
                          alt=""
                        />
                      </ImageListItem>
                    </Box>
                  }
                >
                  <IconButton>
                    <NewAdvertisingPackagesTooltipIcon />
                  </IconButton>
                </Tooltip>
              )}
            </ListItem>
          ))}
        </List>
        <Divider
          sx={{
            width: "100%!important",
            margin: "10px 0",
          }}
        />
        <Typography
          color="text"
          variant="body"
          sx={{
            fontSize: "14px",
            fontWeight: "600",
          }}
        >
          {t("returnGuarantee")}
        </Typography>
        <Typography
          color="primary"
          variant="body"
          sx={{
            fontWeight: "700",
            fontSize: "14px",
          }}
        >
          {returnGuarantee}
        </Typography>
        <Divider
          sx={{
            width: "100%!important",
            margin: "10px 0",
          }}
        />
        <Typography
          color="text"
          variant="body"
          sx={{
            fontSize: "14px",
            fontWeight: "600",
          }}
        >
          $ {price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".")} /{" "}
          {isYearly ? "Yıl" : "Ay"}
        </Typography>
        <Button
          variant="contained"
          onClick={() => setIsModalOpen(true)}
          className="filterApply"
          sx={{
            color: "#fff",
            backgroundColor: "#453834",
            borderRadius: "2px",
            textTransform: "capitalize",
            mt: 1,
            boxShadow: "none",
          }}
        >
          {t("buy")}
        </Button>
      </Box>

      <Modal
        open={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        title="MESAFELİ HİZMET SATIŞI SÖZLEŞMESİ"
      >
        <LongDistanceSellingAgreement />
        <Box
          sx={{
            mt: 2,
            textAlign: "end",
            fontWeight: "700",
          }}
        >
          <Button
            style={{ border: `2px solid ${theme.palette.primary.main}` }}
            onClick={() => onClick()}
          >
            Okudum Kabul Ediyorum
          </Button>
        </Box>
      </Modal>
    </>
  );
};

export default PackagesBox;
