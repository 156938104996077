import axios from "axios";
import React, { useEffect, useState } from "react";
import Cookies from "js-cookie";
import { useParams } from "react-router-dom";
import {
  Box,
  Divider,
  Grid,
  Typography,
  useTheme,
  Paper,
  Button,
} from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";
import { useTranslation } from "react-i18next";
import Topbar from "./global/Topbar";
import { styled } from "@mui/material/styles";
import FbmmDetailTab from "../components/partials/FbmmDetailTab";
import { useNavigate } from "react-router-dom";
import DialogTitle from "@mui/material/DialogTitle";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import DialogActions from "@mui/material/DialogActions";
import Dialog from "@mui/material/Dialog";
import NewProductIcon from "../components/icons/NewProductIcon";
import { useAuthUser } from "react-auth-kit";
import WriteShipmentLabelIcon from "../components/icons/WriteShipmentLabelIcon";

const AddLinksDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
    width: "700px!important",
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

const FbmmDetail = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const theme = useTheme();
  const orderId = useParams().id;
  const [order, setOrder] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState("");
  const [openStickerModal, setOpenStickerModal] = useState(false);
  const [orderTicket, setOrderTicket] = useState(null);
  const auth = useAuthUser();

  const closeStickerModalHandler = () => {
    setOpenStickerModal(false);
  };

  const openStickerModalHandler = () => {
    setOpenStickerModal(true);
  };

  const printPdf = url => {
    const pdfWindow = window.open();
    pdfWindow.document.write(
      `<iframe src="${url}" frameborder="0" style="width:100%; height:100%;"></iframe>`
    );
    pdfWindow.document.close();
    pdfWindow.focus();
  };

  const handleBack = () => navigate("/fbmm");

  const Item = styled(Paper)(() => ({
    backgroundColor: "transparent",
    paddingLeft: "10px  ",
    paddingRight: "10px",
    paddingTop: "10px",
    boxShadow: "none",
    border: `1px solid ${theme.palette.primary.bordersColor}}`,
    borderRadius: "2px",
  }));

  let data = "";
  let config = {
    method: "get",
    maxBodyLength: Infinity,
    url: `${process.env.REACT_APP_BASE_URL}/Order/${orderId}`,
    headers: {
      Authorization: `Bearer ${Cookies.get("_auth")}`,
    },
    data: data,
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios(config);
        setLoading(false);
        setOrder(response.data.orderInfo);
        setOrderTicket(response.data.orderInfo.orderLabels[0]);
      } catch (error) {
        setError(error);
      }
    };
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (loading)
    return (
      <Box sx={{ display: "flex" }}>
        <CircularProgress />
      </Box>
    );
  if (error) return <div>Error...</div>;
  return (
    <Box m={"20px"}>
      <Topbar title={t("orderDetail")}>
        <Box>
          <Button
            onClick={openStickerModalHandler}
            startIcon={<WriteShipmentLabelIcon />}
            color="text"
            sx={{
              color: `${theme.palette.primary.fontColor}!important`,
              pl: 3,
              mt: 0.5,
              textTransform: "capitalize",
              border: "none",
              borderRadius: 0,
            }}
          >
            {t("printShippingLabel")}
          </Button>
        </Box>
      </Topbar>
      <Button onClick={handleBack} className="filterApply">
        {t("goBack")}
      </Button>
      <Box>
        <Grid
          container
          spacing={0}
          sx={{
            background: theme.palette.primary.white,
            mt: 1,
            paddingLeft: 1,
            paddingRight: 1,
            borderRadius: 2,
          }}
        >
          <Grid item xs={12} sm={12} md={5} sx={{ p: "10px 3px" }}>
            <Item>
              <Typography
                variant="text"
                color="primary"
                sx={{ fontWeight: "bold" }}
              >
                {t("orderInfo")}
              </Typography>
              <Divider
                variant="middle"
                sx={{
                  margin: "10px 0px",
                  borderColor: `rgba(85, 56, 48, 0.09)`,
                }}
              />
              <Box>
                <Grid container>
                  <Grid item sm={6}>
                    {t("orderNumber")}
                  </Grid>
                  <Grid item sm={6}>
                    {order.orderNumber}
                  </Grid>
                </Grid>
              </Box>
              <Divider
                variant="middle"
                sx={{
                  margin: "10px 0px",
                  borderColor: `rgba(85, 56, 48, 0.09)`,
                }}
              />
              <Box>
                <Grid container sx={{ pb: 1 }}>
                  <Grid item sm={6}>
                    {t("platformNo")}
                  </Grid>
                  <Grid item sm={6}></Grid>
                </Grid>
              </Box>
              <Divider
                variant="middle"
                sx={{
                  margin: "10px 0px",
                  borderColor: `rgba(85, 56, 48, 0.09)`,
                }}
              />
              <Box>
                <Grid container sx={{ pb: 1 }}>
                  <Grid item sm={6}>
                    {t("referenceNo")}
                  </Grid>
                  <Grid item sm={6}>
                    {order.proparsId}
                  </Grid>
                </Grid>
              </Box>
              <Divider
                variant="middle"
                sx={{
                  margin: "10px 0px",
                  borderColor: `rgba(85, 56, 48, 0.09)`,
                }}
              />
              <Box>
                <Grid container sx={{ pb: 1 }}>
                  <Grid item sm={6}>
                    {t("orderStatus")}
                  </Grid>
                  <Grid item sm={6}>
                    {order.orderFBMMStatus !== null
                      ? order.orderFBMMStatus
                      : "null"}
                  </Grid>
                </Grid>
              </Box>
              <Divider
                variant="middle"
                sx={{
                  margin: "10px 0px",
                  borderColor: `rgba(85, 56, 48, 0.09)`,
                }}
              />
              <Box>
                <Grid container sx={{ pb: 1 }}>
                  <Grid item sm={6}>
                    {t("platform")}
                  </Grid>
                  <Grid item sm={6}>
                    {order.source}
                  </Grid>
                </Grid>
              </Box>
              <Divider
                variant="middle"
                sx={{
                  margin: "10px 0px",
                  borderColor: `rgba(85, 56, 48, 0.09)`,
                }}
              />
              {/* <Box>
                <Grid container sx={{ pb: 1 }}>
                  <Grid item sm={6}>
                    {t("entegrator")}
                  </Grid>
                  <Grid item sm={6}>
                    Propars
                  </Grid>
                </Grid>
              </Box>
              <Divider
                variant="middle"
                sx={{
                  margin: "10px 0px",
                  borderColor: `rgba(85, 56, 48, 0.09)`,
                }}
              /> */}
              <Box>
                <Grid container sx={{ pb: 1 }}>
                  <Grid item sm={6}>
                    {t("warehouse")}
                  </Grid>
                  <Grid item sm={6}>
                    {order.orderProducts.map(item => item.warehouseId)}
                  </Grid>
                </Grid>
              </Box>
            </Item>
            <Item style={{ marginTop: "10px!important" }}>
              <Typography
                variant="text"
                color="primary"
                sx={{ fontWeight: "bold" }}
              >
                {t("shipmentInfo")}
              </Typography>
              <Divider
                variant="middle"
                sx={{
                  margin: "10px 0px",
                  borderColor: `rgba(85, 56, 48, 0.09)`,
                }}
              />
              <Box>
                <Grid container>
                  <Grid item sm={6}>
                    {t("shipmentMethod")}
                  </Grid>
                  <Grid item sm={6}></Grid>
                </Grid>
              </Box>
              <Divider
                variant="middle"
                sx={{
                  margin: "10px 0px",
                  borderColor: `rgba(85, 56, 48, 0.09)`,
                }}
              />
              <Box>
                <Grid container>
                  <Grid item sm={6}>
                    {t("shipmentType")}
                  </Grid>
                  <Grid item sm={6}></Grid>
                </Grid>
              </Box>
              <Divider
                variant="middle"
                sx={{
                  margin: "10px 0px",
                  borderColor: `rgba(85, 56, 48, 0.09)`,
                }}
              />
              {/* <Box>
                <Grid container>
                  <Grid item sm={6}>
                    {t("shipmentInsurance")}
                  </Grid>
                  <Grid item sm={6}>
                    ?
                  </Grid>
                </Grid>
              </Box>
              <Divider
                variant="middle"
                sx={{
                  margin: "10px 0px",
                  borderColor: `rgba(85, 56, 48, 0.09)`,
                }}
              /> */}
              <Box>
                <Grid container sx={{ pb: 1 }}>
                  <Grid item sm={6}>
                    {t("trackingNo")}
                  </Grid>
                  <Grid item sm={6}>
                    {order.trackingNumber !== null
                      ? order.trackingNumber
                      : "null"}
                  </Grid>
                </Grid>
              </Box>
            </Item>
            <Item style={{ marginTop: "10px!important" }}>
              <Typography
                variant="text"
                color="primary"
                sx={{ fontWeight: "bold" }}
              >
                {t("buyerInfo")}
              </Typography>
              <Divider
                variant="middle"
                sx={{
                  margin: "10px 0px",
                  borderColor: `rgba(85, 56, 48, 0.09)`,
                }}
              />
              <Box>
                <Grid container>
                  <Grid item sm={6}>
                    {t("buyerName")}
                  </Grid>
                  <Grid item sm={6}>
                    {order.customer.fullName !== "null"
                      ? order.customer.fullName
                      : "null"}
                  </Grid>
                </Grid>
              </Box>
              <Divider
                variant="middle"
                sx={{
                  margin: "10px 0px",
                  borderColor: `rgba(85, 56, 48, 0.09)`,
                }}
              />
              <Box>
                <Grid container>
                  <Grid item sm={6}>
                    {t("country")}
                  </Grid>
                  <Grid item sm={6}>
                    {order.orderAddresses.map(address => {
                      if (address.addressType === 1) {
                        return address.country.title;
                      }
                    })}
                  </Grid>
                </Grid>
              </Box>
              <Divider
                variant="middle"
                sx={{
                  margin: "10px 0px",
                  borderColor: `rgba(85, 56, 48, 0.09)`,
                }}
              />
              <Box>
                <Grid container>
                  <Grid item sm={6}>
                    {t("region")}
                  </Grid>
                  <Grid item sm={6}></Grid>
                </Grid>
              </Box>
              <Divider
                variant="middle"
                sx={{
                  margin: "10px 0px",
                  borderColor: `rgba(85, 56, 48, 0.09)`,
                }}
              />
              <Box>
                <Grid container>
                  <Grid item sm={6}>
                    {t("city")}
                  </Grid>
                  <Grid item sm={6}>
                    {order.orderAddresses.map(address => {
                      if (address.addressType === 1) {
                        return address.city.title;
                      }
                    })}
                  </Grid>
                </Grid>
              </Box>
              <Divider
                variant="middle"
                sx={{
                  margin: "10px 0px",
                  borderColor: `rgba(85, 56, 48, 0.09)`,
                }}
              />
              <Box>
                <Grid container>
                  <Grid item sm={6}>
                    {t("address")}
                  </Grid>
                  <Grid item sm={6}>
                    {order.orderAddresses.map(address => {
                      if (address.addressType === 1) {
                        return address.address;
                      }
                    })}
                  </Grid>
                </Grid>
              </Box>
              <Divider
                variant="middle"
                sx={{
                  margin: "10px 0px",
                  borderColor: `rgba(85, 56, 48, 0.09)`,
                }}
              />
              <Divider
                variant="middle"
                sx={{
                  margin: "10px 0px",
                  borderColor: `rgba(85, 56, 48, 0.09)`,
                }}
              />
              <Box>
                <Grid container>
                  <Grid item sm={6}>
                    {t("zipCode")}
                  </Grid>
                  <Grid item sm={6}>
                    {order.orderAddresses.map(address => {
                      if (address.addressType === 1) {
                        return address.postalCode;
                      }
                    })}
                  </Grid>
                </Grid>
              </Box>
              <Divider
                variant="middle"
                sx={{
                  margin: "10px 0px",
                  borderColor: `rgba(85, 56, 48, 0.09)`,
                }}
              />
              <Box>
                <Grid container>
                  <Grid item sm={6}>
                    {t("phone")}
                  </Grid>
                  <Grid item sm={6}>
                    {order.customer.phoneNumber !== null
                      ? order.customer.phoneNumber
                      : "null"}
                  </Grid>
                </Grid>
              </Box>
              <Divider
                variant="middle"
                sx={{
                  margin: "10px 0px",
                  borderColor: `rgba(85, 56, 48, 0.09)`,
                }}
              />
              <Box>
                <Grid container>
                  <Grid item sm={6}>
                    {t("email")}
                  </Grid>
                  <Grid item sm={6} sx={{ lineBreak: "anywhere", pb: 1 }}>
                    {order.customer.email !== null
                      ? order.customer.email
                      : "null"}
                  </Grid>
                </Grid>
              </Box>
            </Item>
            {/* <Item style={{ marginTop: "10px!important" }}>
              <Typography
                variant="text"
                color="primary"
                sx={{ fontWeight: "bold" }}
              >
                {t("orderCost")}
              </Typography>
              <Divider
                variant="middle"
                sx={{
                  margin: "10px 0px",
                  borderColor: `rgba(85, 56, 48, 0.09)`,
                }}
              />
              <Box>
                <Grid container>
                  <Grid item sm={6}>
                    {t("total")}
                  </Grid>
                  <Grid item sm={6}>
                    {order.grandTotal !== null ? order.grandTotal : "null"}
                  </Grid>
                </Grid>
              </Box>
              <Divider
                variant="middle"
                sx={{
                  margin: "10px 0px",
                  borderColor: `rgba(85, 56, 48, 0.09)`,
                }}
              />
              <Box>
                <Grid container>
                  <Grid item sm={6}>
                    {t("billingWeight")}
                  </Grid>
                  <Grid item sm={6}>
                    ?
                  </Grid>
                </Grid>
              </Box>
              <Divider
                variant="middle"
                sx={{
                  margin: "10px 0px",
                  borderColor: `rgba(85, 56, 48, 0.09)`,
                }}
              />
              <Box>
                <Grid container>
                  <Grid item sm={6}>
                    {t("volume")}
                  </Grid>
                  <Grid item sm={6} sx={{ pb: 1 }}>
                    ?
                  </Grid>
                </Grid>
              </Box>
            </Item> */}
          </Grid>
          <Grid item xs={12} sm={12} md={7} sx={{ p: "10px 3px" }}>
            <Item>
              <Typography
                variant="text"
                color="primary"
                sx={{ fontWeight: "bold" }}
              >
                {t("processHistory")}
              </Typography>
              <FbmmDetailTab order={order} />
            </Item>
          </Grid>
        </Grid>
      </Box>
      <Box
        className="DeleteNoteDialog"
        sx={{
          width: "560px!important",
          overflowX: "hidden",
        }}
      >
        <AddLinksDialog
          onClose={closeStickerModalHandler}
          aria-labelledby="customized-dialog-title"
          open={openStickerModal}
          style={{
            width: "560px!important",
            overflowX: "hidden",
          }}
        >
          <DialogTitle
            sx={{ m: 0, p: 2, color: theme.palette.primary.main }}
            id="customized-dialog-title"
          >
            {t("shippingLabel")}
          </DialogTitle>
          <IconButton
            aria-label="close"
            onClick={closeStickerModalHandler}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: theme => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
          <Divider />
          <Box
            component="form"
            sx={{
              backgroundColor: "#fff",
              width: "560px!important",
              overflowX: "hidden",
            }}
            noValidate
            autoComplete="off"
          >
            <Box
              sx={{
                width: "560px!important",
                overflowX: "hidden",
                paddingTop: "20px",
                paddingLeft: "20px",
                paddingRight: "20px",
                marginBottom: "20px",
              }}
            >
              {orderTicket !== null && orderTicket !== undefined ? (
                <embed
                  src={
                    orderTicket !== null && orderTicket !== undefined
                      ? orderTicket.labelUrl
                      : null
                  }
                  width="100%"
                  height="600px"
                  style={{ border: "none" }}
                ></embed>
              ) : (
                t("shippingLabelNotFound")
              )}
            </Box>
          </Box>
          <Divider />
          <DialogActions
            sx={{ display: "flex", justifyContent: "space-between" }}
          >
            <Button
              className="filterReset"
              autoFocus
              onClick={closeStickerModalHandler}
              // disabled={
              //   addLinksTitle === "" || addLinksUrl === "" ? true : false
              // }
            >
              {t("close")}
            </Button>

            {orderTicket !== null && orderTicket !== undefined ? (
              <Button
                className="filterApply"
                autoFocus
                onClick={() => printPdf(orderTicket.labelUrl)}
                // disabled={
                //   addLinksTitle === "" || addLinksUrl === "" ? true : false
                // }
              >
                {t("print")}
              </Button>
            ) : null}
          </DialogActions>
        </AddLinksDialog>
      </Box>
    </Box>
  );
};

export default FbmmDetail;
