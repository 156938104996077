import React, { useEffect, useState, useContext } from "react";
import Topbar from "./global/Topbar";
import { Box, useTheme, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import Cookies from "js-cookie";
import TableList from "../components/partials/TableList";
import axios from "axios";
import Button from "@mui/material/Button";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import PropTypes from "prop-types";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { GridActionsCellItem } from "@mui/x-data-grid";
import { useNavigate } from "react-router-dom";
import FileCopyIcon from "@mui/icons-material/FileCopy";
import { StockFilterContext } from "../context/StockFilterContext";
import StockFilter from "../components/partials/StockFilter";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

function BootstrapDialogTitle(props) {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: theme => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
}

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

const Stocks = () => {
  const navigate = useNavigate();
  const { stockFilters } = useContext(StockFilterContext);
  const theme = useTheme();
  const { t } = useTranslation();
  const [pageState, setPageState] = useState({
    isLoading: false,
    data: [],
    total: 0,
    page: 0,
    pageSize: 10,
  });
  const [error, setError] = useState(null);
  const [open, setOpen] = React.useState(false);
  const [activeRow, setActiveRow] = useState([]);

  const handleClickOpen = product => {
    setOpen(true);
    setActiveRow(product);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const stockStatusHandler = id => {
    return () => {
      navigate(`/stock-update/${id}`);
    };
  };

  function getSku(params) {
    return (
      <Button
        style={{
          color: theme.palette.secondary.main,
          textDecoration: "none",
          fontWeight: "500",
        }}
        onClick={() => handleClickOpen(params.row)}
      >
        {params.row.mpn}
      </Button>
    );
  }
  function getBarcode(params) {
    return params.row.barcode;
  }
  function getProductName(params) {
    return params.row.product.title;
  }

  function getWareHouses(params) {
    let value = params.row.productWarehouses;
    if (value.length === 0) {
      return "[ ]";
    } else {
      return (
        <Box>
          {value.map((warehouse, index) => (
            <div key={index} className="fbmmProdList">
              <Typography key={warehouse.warehouseId}>
                {warehouse.warehouse.title}
              </Typography>
            </div>
          ))}
        </Box>
      );
    }
  }

  function getAmountOnTheRoad(params) {
    let value = params.row.productWarehouses;
    if (value.length === 0) {
      return "[ ]";
    } else {
      return (
        <Box>
          {value.map((warehouse, index) => (
            <div key={index} className="fbmmProdList">
              <Typography key={index}>{warehouse.amountOnTheRoad}</Typography>
            </div>
          ))}
        </Box>
      );
    }
  }

  function getVariantName(params) {
    return params.row.title;
  }

  function getAmountPendingForShipping(params) {
    let value = params.row.productWarehouses;
    if (value.length === 0) {
      return "[ ]";
    } else {
      return (
        <Box>
          {value.map((warehouse, index) => (
            <div key={index} className="fbmmProdList">
              <Typography key={index}>
                {warehouse.amountPendingForShipping}
              </Typography>
            </div>
          ))}
        </Box>
      );
    }
  }

  function getAmount(params) {
    let value = params.row.productWarehouses;
    if (value.length === 0) {
      return "[ ]";
    } else {
      return (
        <Box>
          {value.map((warehouse, index) => (
            <div key={index} className="fbmmProdList">
              <Typography key={index}>{warehouse.amount}</Typography>
            </div>
          ))}
        </Box>
      );
    }
  }

  const columns = [
    { field: "sku", headerName: t("sku"), flex: 1, renderCell: getSku },
    {
      field: "barcode",
      headerName: t("barcode"),
      flex: 1,
      valueGetter: getBarcode,
    },
    {
      field: "productName",
      headerName: t("productName"),
      flex: 3,
      valueGetter: getProductName,
    },
    {
      field: "variantName",
      headerName: t("variantName"),
      flex: 3,
      valueGetter: getVariantName,
    },
    {
      field: "wareHouse",
      headerName: t("warehouse"),
      flex: 2,
      renderCell: getWareHouses,
    },
    // {
    //   field: "inShipment",
    //   headerName: t("inShipment"),
    //   flex: 1,
    //   renderCell: getAmountOnTheRoad,
    // },
    {
      field: "readyForSale",
      headerName: t("readyForSale"),
      flex: 1,
      renderCell: getAmount,
    },
    // {
    //   field: "awaitingForShipping",
    //   headerName: t("awaitingForShipping"),
    //   flex: 1,
    //   renderCell: getAmountPendingForShipping,
    // },
    {
      field: "actions",
      type: "actions",
      flex: 0.5,
      headerName: t("actions"),
      getActions: params => [
        <GridActionsCellItem
          icon={<FileCopyIcon />}
          label={t("update")}
          onClick={stockStatusHandler(params.row.id)}
          showInMenu
        />,
      ],
    },
  ];

  let data = "";
  let config = {
    method: "get",
    maxBodyLength: Infinity,
    url: `${process.env.REACT_APP_BASE_URL}/ProductVariant/GetAllStocks?Pagination.Page=${pageState.page}&Pagination.Size=${pageState.pageSize}`,
    headers: {
      Authorization: `Bearer ${Cookies.get("_auth")}`,
    },
    data: data,
  };

  useEffect(() => {
    if (stockFilters) {
      if (stockFilters.sku) {
        config.url = config.url + "&Sku=" + stockFilters.sku;
      }
      if (stockFilters.barcode) {
        config.url = config.url + "&Barcode=" + stockFilters.barcode;
      }
      if (stockFilters.status) {
        config.url = config.url + "&Status=" + stockFilters.status;
      }
      if (stockFilters.category) {
        config.url = config.url + "&Category=" + stockFilters.category;
      }
      if (stockFilters.warehouse) {
        config.url = config.url + "&Warehouse=" + stockFilters.warehouse;
      }
      if (stockFilters.stockName) {
        config.url = config.url + "&query=" + stockFilters.stockName;
      }
      if (stockFilters.search) {
        config.url = config.url + "&query=" + stockFilters.search;
      }
      if (stockFilters.stockAmountStart) {
        config.url =
          config.url + "&StockAmountStart=" + stockFilters.stockAmountStart;
      }
      if (stockFilters.stockAmountEnd) {
        config.url =
          config.url + "&StockAmountEnd=" + stockFilters.stockAmountEnd;
      }
    }

    const fetchData = async () => {
      setPageState(old => ({ ...old, isLoading: true }));
      const response = await axios.request(config);
      const responseData = await response.data.items;
      const totalCount = await response.data.totalCount;
      setPageState(old => ({
        ...old,
        isLoading: false,
        data: responseData,
        total: totalCount,
      }));
    };
    fetchData();
  }, [pageState.page, pageState.pageSize, stockFilters]);
  if (error) {
    setError(error);
    return <div>Error: {error.message}</div>;
  } else {
    return (
      <Box m={"20px"}>
        <Topbar title={t("stockManagement")}></Topbar>
        <Box sx={{ background: theme.palette.primary.white }} p={5}>
          <StockFilter />
          <Box mt={2} sx={{ height: "100%" }}>
            <TableList
              key={pageState.data.id}
              rows={pageState.data}
              columns={columns}
              pageState={pageState}
              setPageState={setPageState}
              rowHeight={150}
            />
            <BootstrapDialog
              onClose={handleClose}
              aria-labelledby="customized-dialog-title"
              open={open}
              maxWidth="md"
            >
              <BootstrapDialogTitle
                id="customized-dialog-title"
                onClose={handleClose}
                color="primary"
              >
                {t("shipmentDetail")}
              </BootstrapDialogTitle>
              <DialogContent dividers>
                <TableContainer
                  sx={{
                    boxShadow: "none!important",
                    borderBottom: "none!important",
                    borderRadius: "0!important",
                  }}
                  component={Paper}
                >
                  <Table sx={{ minWidth: 650 }} aria-label="simple table">
                    <TableHead sx={{ border: "1px solid #e0e0e0" }}>
                      <TableRow>
                        <TableCell align="left">{t("requestNo")}</TableCell>
                        <TableCell align="left">{t("status")}</TableCell>
                        <TableCell align="left">{t("sku")}</TableCell>
                        <TableCell align="left">{t("arrivalWarehouse")}</TableCell>
                        <TableCell align="left">{t("numberOfProducts")}</TableCell>
                        <TableCell align="left">{t("updatedDate")}</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      <TableRow className="stock-table" key={activeRow.sku}>
                        <TableCell>{activeRow.mpn}</TableCell>
                        <TableCell align="left">{activeRow.sku}</TableCell>
                        <TableCell align="left">{activeRow.sku}</TableCell>
                        <TableCell align="left">{activeRow.sku}</TableCell>
                        <TableCell align="left">{activeRow.sku}</TableCell>
                        <TableCell align="left">{activeRow.sku}</TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </TableContainer>
              </DialogContent>
              <DialogActions sx={{ justifyContent: "flex-start" }}>
                <Button autoFocus onClick={handleClose} className="filterReset">
                  {t("close")}
                </Button>
              </DialogActions>
            </BootstrapDialog>
          </Box>
        </Box>
      </Box>
    );
  }
};

export default Stocks;
