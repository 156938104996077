import React, { useState, useEffect, useContext } from "react";

import Topbar from "./global/Topbar";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import CustomLabel from "../components/forms/form-elements/CustomLabel";
import {
  Box,
  Typography,
  Grid,
  Button,
  Divider,
  IconButton,
} from "@mui/material";
import axios from "axios";
import Cookies from "js-cookie";
import { useAuthUser } from "react-auth-kit";
import { useTheme } from "@emotion/react";
import { useTranslation } from "react-i18next";
import PromotionsTable from "../components/Promotions/PromotionsTable";
import TextField from "@mui/material/TextField";
import Checkbox from "@mui/material/Checkbox";
import { alpha, styled } from "@mui/material/styles";
import InputBase from "@mui/material/InputBase";
import dayjs from "dayjs";
import LoadingButton from "@mui/lab/LoadingButton";
import InsertDriveFileOutlinedIcon from "@mui/icons-material/InsertDriveFileOutlined";
import NoteAddOutlinedIcon from "@mui/icons-material/NoteAddOutlined";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import CloseIcon from "@mui/icons-material/Close";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import NewProductIcon from "../components/icons/NewProductIcon";

const BootstrapInput = styled(InputBase)(({ theme }) => ({
  "label + &": {
    marginTop: theme.spacing(3),
    color: `${alpha(theme.palette.text.main, 0.25)} 0 0 0 0.2rem`,
  },
  "& .MuiInputBase-input": {
    borderRadius: 2,
    position: "relative",
    border: "1px solid",
    fontSize: 14,
    fontStyle: "italic",
    height: "12px",
    width: "100%",
    padding: "10px 12px",
    marginTop: "0.5rem",
    borderColor: theme.palette.primary.bordersColor,
    transition: theme.transitions.create([
      "border-color",
      "background-color",
      "box-shadow",
    ]),
    // "&:focus": {
    //   boxShadow: `${alpha(theme.palette.primary.main, 0.25)} 0 0 0 0.2rem`,
    //   borderColor: theme.palette.primary.main,
    //   color: theme.palette.text.main,
    // },
    // "& .MuiFormControlLabel-root": {
    //   fontFamily: "Roboto, sans-serif",
    //   fontWeight: "500",
    // },
  },
}));
const ChangeStatusDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

const MyButton = styled(Button)({
  fontFamily: "Roboto, sans-serif",
  fontWeight: "500",
});

const PromotionCode = () => {
  const theme = useTheme();
  const { t } = useTranslation();

  const [open, setOpen] = React.useState(false);

  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [isOneTimeUse, setIsOneTimeUse] = useState(false);
  const [total, setTotal] = useState(0);
  const [error, setError] = useState(null);
  const [modalCode, setModalCode] = useState("");
  const [modalDatePicker, setModalDatePicker] = useState("");
  const [datePicker, setDatePicker] = useState("");

  const [inputsCheckbox, setInputsCheckbox] = useState({
    uppercase: false,
    lowercase: false,
    digit: false,
  });
  const [inputs, setInputs] = useState({ length: "", count: "" });
  const [files, setFiles] = useState([]);

  const handlerModalCode = (e) => {
    if (e.target.value.length === 0) {
      alert("lütfen bir sayını giriniz");
      return;
    } else if (e.target.value.length > 25) {
      alert("Kod sayısı 25 karakterden az olmalı!!!");
      return;
    } else {
      setModalCode(e.target.value);
    }
  };
  const handlerModalDatePicker = (newValue) => {
    setModalDatePicker(`${newValue.$y}-${newValue.$M + 1}-${newValue.$D}`);
  };

  const handlerDatePicker = (newValue) => {
    setDatePicker(`${newValue.$y}-${newValue.$M + 1}-${newValue.$D}`);
  };

  const handleChangeInputs = (e) => {
    if (e.target.name == "count" && e.target.value > 250) {
      alert("lütfen adet sayısını 250 den az giriniz");
    } else if (e.target.name == "length" && e.target.value > 25) {
      alert("lütfen karakter sayısını 25 den az giriniz");
    } else {
      setInputs({ ...inputs, [e.target.name]: e.target.value });
    }
  };
  const handleChangeInputsCheckbox = (e) => {
    setInputsCheckbox({ ...inputsCheckbox, [e.target.name]: e.target.checked });
  };

  const handleReset = () => {
    setInputsCheckbox({
      uppercase: false,
      lowercase: false,
      digit: false,
    });
    setInputs({ length: "", count: "" });
    setDatePicker("");
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleModal = async () => {
    if (modalCode.length === 0) {
      alert("Lütfen kod alanını doldurunuz!!!");
      return;
    }
    try {
      let data = {
        code: modalCode,
        isOneTimeUse: isOneTimeUse,
        expireDate: modalDatePicker,
      };
      let config2 = {
        method: "post",
        maxBodyLength: Infinity,
        url: `${process.env.REACT_APP_BASE_URL}/PromoCode`,
        responseType: "blob",
        headers: {
          Authorization: `Bearer ${Cookies.get("_auth")}`,
        },
        data: data,
      };

      const response = await axios.request(config2).catch((error) => {
        if (error.response.status === 409) {
          alert("Yazdığınız 'KOD' mevcuttur. Başka 'KOD' yazınız...");
        } else {
          alert(
            "İstek sırasında bir hata oluştu.Lütfen daha sonra tekrar deneyiniz !!!..."
          );
        }
      });

      if (response.status === 200) {
        setModalCode("");
        setModalDatePicker("");
        setIsOneTimeUse("");
        setOpen(false);
      } else if (response.status === 409) {
        alert("İstek sırasında bir hata oluştu");
      } else {
        alert("İstek sırasında bir hata oluştu");
      }
    } catch (error) {
      alert(
        "İstek sırasında bir hata oluştu.Lütfen daha sonra tekrar deneyiniz !!!..."
      );
    }
  };

  const handleGenerate = async () => {
    if (
      (inputsCheckbox.uppercase === false &&
        inputsCheckbox.lowercase === false &&
        inputsCheckbox.digit === false) ||
      inputs.count.length === 0 ||
      inputs.length.length === 0
    ) {
      alert(
        "Lütfen  en az bir tane kod çeşitini giriniz.Karakter ve adet alanlarını kesinlikle doldurunuz!!!"
      );
      return;
    }

    setLoading(true);
    let data = {
      count: inputs.count,
      length: inputs.length,
      useUpperCase: inputsCheckbox.uppercase,
      useLowerCase: inputsCheckbox.lowercase,
      useDigits: inputsCheckbox.digit,
      isOneTimeUse: true,
      expireDate: datePicker,
    };

    let config = {
      method: "post",
      maxBodyLength: Infinity,
      url: `${process.env.REACT_APP_BASE_URL}/PromoCode/Generate`,
      responseType: "blob",
      headers: {
        Authorization: `Bearer ${Cookies.get("_auth")}`,
      },
      data: data,
    };

    const response = await axios.request(config);

    if (response.status === 200) {
      const fileName = "downloaded_file.xlsx";
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const fileObject = {
        name: fileName,
        url: url,
      };
      setLoading(false);
      setFiles([fileObject]);
    } else {
      alert(
        "İstek sırasında bir hata oluştu.Lütfen daha sonra tekrar deneyiniz !!!..."
      );
    }
  };

  return (
    <Box m={"20px"}>
      <Topbar title={t("promoCodes")}>
        <Button
          startIcon={<NewProductIcon />}
          color="text"
          sx={{
            color: `${theme.palette.primary.fontColor}!important`,
            ml: 2,
            textTransform: "capitalize",
            border: "none",
            borderRadius: 0,
          }}
          onClick={() => setOpen(true)}
        >
          {t("addPromoCode")}
        </Button>
        <Box className="adminDialog">
          <ChangeStatusDialog
            onClose={handleClose}
            open={open}
            aria-labelledby="customized-dialog-title"
            maxWidth="sm"
            fullWidth
          >
            <DialogTitle
              sx={{ m: 0, p: 2, color: theme.palette.primary.main }}
              id="customized-dialog-title"
            >
              {t("addPromoCode")}
            </DialogTitle>
            <IconButton
              aria-label="close"
              onClick={handleClose}
              sx={{
                position: "absolute",
                right: 8,
                top: 8,
                color: (theme) => theme.palette.grey[500],
              }}
            >
              <CloseIcon />
            </IconButton>
            <DialogContent>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  marginX: "5px",
                  marginBottom: "5px",
                  p: 2,
                  border: "1px solid rgba(69, 56, 52, 0.15)",
                  borderRadius: "2px",
                  paddingBottom: "initial",
                }}
              >
                <Typography
                  sx={{
                    mt: 1,
                    mb: 0,
                    fontFamily: "Roboto, sans-serif",
                    width: "50%",
                  }}
                  variant="body2"
                  fontWeight="500"
                  color="text"
                >
                  {t("promoCode")}
                </Typography>
                <BootstrapInput
                  sx={{ width: "50%" }}
                  label="Kod"
                  value={modalCode}
                  name="length"
                  onChange={handlerModalCode}
                  placeholder="Kod yazınız.Ör : 2131231"
                  margin="normal"
                />

                <Typography
                  sx={{
                    mt: 1,
                    mb: 1,
                    fontFamily: "Roboto, sans-serif",
                  }}
                  variant="body2"
                  fontWeight="500"
                  color="text"
                >
                  {t("howLongIsTheCodeValid")}
                </Typography>
                <Box
                  sx={{
                    marginTop: "-13px",
                  }}
                >
                  <LocalizationProvider
                    localeText="tr"
                    dateAdapter={AdapterDayjs}
                  >
                    <DemoContainer components={["DatePicker", "DatePicker"]}>
                      <DatePicker
                        sx={{
                          width: "50%",
                          marginTop: "",
                        }}
                        className="customDatePicker"
                        format="YYYY-MM-DD"
                        defaultValue={dayjs().locale("tr")}
                        value={modalDatePicker}
                        onChange={(newValue) =>
                          handlerModalDatePicker(newValue)
                        }
                      />
                    </DemoContainer>
                  </LocalizationProvider>
                </Box>
                <FormControlLabel
                  sx={{
                    mt: 2,
                    mb: 2,
                    fontWeight: "500 !important",
                    marginTop: "0px",
                  }}
                  style={{ fontWeight: "500 !important" }}
                  variant="body2"
                  fontWeight="500"
                  color="text"
                  control={
                    <Checkbox
                      style={{
                        fontFamily: "Roboto, sans-serif",
                        fontWeight: "500 !important",
                      }}
                      fontWeight="500"
                      variant="outlined"
                      color="success"
                      name="lowercase"
                      checked={isOneTimeUse}
                      onChange={(e) => setIsOneTimeUse(e.target.checked)}
                    />
                  }
                  label={t("singleUse")}
                />
              </Box>
            </DialogContent>
            <DialogActions
              sx={{
                display: "flex",
                justifyContent: "space-end",
                marginTop: "-15px",
                marginRight: "12px",
              }}
            >
              <Button className="filterApply" onClick={handleModal} autoFocus>
                {t("save")}
              </Button>
            </DialogActions>
          </ChangeStatusDialog>
        </Box>
      </Topbar>

      <Box mt={2} sx={{ height: "100%", backgroundColor: "#fff", p: 3 }}>
        <Grid container spacing="20px">
          <Grid item xs={12} sm={4}>
            <Box
              sx={{
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <Box
                sx={{
                  p: 2,
                  border: "1px solid rgba(69, 56, 52, 0.15)",
                  borderRadius: "2px",
                }}
              >
                <Typography
                  variant="text"
                  color="primary"
                  sx={{ fontWeight: "bold" }}
                >
                  {t("generateBulkPromotionCode")}
                </Typography>
                <Divider />
                <Box sx={{ marginTop: "10px" }}>
                  <Box>
                    <FormControlLabel
                      sx={{
                        fontSize: "14px",
                        fontFamily: "Roboto, sans-serif",
                      }}
                      control={
                        <Checkbox
                          variant="outlined"
                          color="success"
                          name="uppercase"
                          size="small"
                          checked={inputsCheckbox.uppercase}
                          onChange={handleChangeInputsCheckbox}
                        />
                      }
                      label={t("itContainCapitalLetter")}
                    />
                    <FormControlLabel
                      sx={{
                        float: "right",
                        marginRight: "100px",
                        fontSize: "14px",
                        fontFamily: "Roboto, sans-serif",
                      }}
                      control={
                        <Checkbox
                          fontWeight="500"
                          color="success"
                          variant="outlined"
                          name="lowercase"
                          size="small"
                          checked={inputsCheckbox.lowercase}
                          onChange={handleChangeInputsCheckbox}
                        />
                      }
                      label={t("itContainSmallLetter")}
                    />
                  </Box>
                  <Box sx={{ marginTop: "3px" }}>
                    <FormControlLabel
                      sx={{
                        fontSize: "14px",
                        fontFamily: "Roboto, sans-serif",
                        marginBottom: "5px",
                        marginTop: " -10px",
                      }}
                      control={
                        <Checkbox
                          variant="outlined"
                          color="success"
                          name="digit"
                          size="small"
                          checked={inputsCheckbox.digit}
                          onChange={handleChangeInputsCheckbox}
                        />
                      }
                      label={t("containsNumber")}
                    />
                  </Box>
                </Box>
                <Divider sx={{ marginTop: "10px" }} />

                <Box sx={{ marginTop: "30px" }}>
                  <Typography
                    sx={{
                      mt: 1,
                      mb: 1,
                    }}
                    variant="body2"
                    fontWeight="500"
                    color="text"
                  >
                    {t("howManyCharactersShouldTheCodeHave")}
                  </Typography>
                  <BootstrapInput
                    label={t("howManyCharactersShouldTheCodeHave")}
                    value={inputs.length}
                    name="length"
                    variant="outlined"
                    sx={{
                      marginTop: "-25px !important",
                      width: "50%",
                      borderRadius: "4px",
                    }}
                    size="small"
                    onChange={handleChangeInputs}
                    placeholder={t("writeTheCharacterEx")}
                    margin="normal"
                    inputProps={{
                      maxLength: 2,
                    }}
                  />
                </Box>
                <Typography
                  sx={{
                    mt: 1,
                    mb: 1,
                  }}
                  variant="body2"
                  fontWeight="500"
                  color="text"
                >
                  {t("howManyPiecesOfCodeShouldBeProduced")}
                </Typography>
                {/* <CustomLabel title="Kod kaç adet üretilsin?" /> */}
                <BootstrapInput
                  label={t("howManyPiecesOfCodeShouldBeProduced")}
                  inputProps={{
                    maxLength: 3,
                  }}
                  variant="outlined"
                  sx={{
                    marginTop: "-25px !important",
                    width: "50%",
                    borderRadius: "4px",
                  }}
                  size="small"
                  value={inputs.count}
                  name="count"
                  onChange={handleChangeInputs}
                  placeholder={t("writeTheNumberOfPiecesEx")}
                  margin="normal"
                />
                <Typography
                  sx={{
                    mt: 1,
                    mb: 1,
                  }}
                  variant="body2"
                  fontWeight="500"
                  color="text"
                >
                  {t("howLongIsTheCodeValid")}
                </Typography>
                <Box
                  sx={{
                    marginTop: "-13px",
                  }}
                >
                  <LocalizationProvider
                    localeText="tr"
                    dateAdapter={AdapterDayjs}
                  >
                    <DemoContainer components={["DatePicker", "DatePicker"]}>
                      <DatePicker
                        sx={{
                          width: "50%",
                        }}
                        className="customDatePicker"
                        defaultValue={dayjs().locale("tr")}
                        value={datePicker}
                        format="YYYY-MM-DD"
                        onChange={(newValue) => handlerDatePicker(newValue)}
                      />
                    </DemoContainer>
                  </LocalizationProvider>
                </Box>
              </Box>
              <Grid
                container
                spacing={4}
                justifyContent="space-between"
                marginTop="1px"
              >
                <Grid item>
                  <Button className="filterReset" onClick={handleReset}>
                    {t("clear")}
                  </Button>
                </Grid>
                <Grid item>
                  <Button
                    sx={{
                      float: "right",
                    }}
                    className="filterApply"
                    onClick={handleGenerate}
                  >
                    {t("produce")}
                  </Button>
                </Grid>
              </Grid>
            </Box>
            {loading ? (
              <Box sx={{ p: 2, border: "1px solid grey", marginTop: "25px" }}>
                <Grid container spacing={3} justifyContent="left">
                  <Grid item>
                    <InsertDriveFileOutlinedIcon
                      sx={{ fontSize: 30, marginTop: "inherit" }}
                    />
                  </Grid>
                  <Grid item marginLeft="inherit" marginTop="6px">
                    <Box>{t("creating")}...</Box>
                  </Grid>
                  <Grid item>
                    <LoadingButton
                      sx={{
                        color: "pink",
                        "&.Mui-disabled": {
                          border: "0px",
                          margin: "-80px",
                        },
                      }}
                      variant="outlined"
                      loading={loading}
                      disabled
                    >
                      <span>{t("creating")}</span>
                    </LoadingButton>
                  </Grid>
                </Grid>
              </Box>
            ) : (
              <Box>
                <div>
                  <ul>
                    {files.map((file, index) => (
                      <li key={index}>
                        <a href={file.url} download={file.name}>
                          {file.name}
                        </a>
                        <Button
                          href={file.url}
                          download={file.name}
                          variant="contained"
                          color="primary"
                          sx={{
                            float: "right",
                          }}
                        >
                          {t("download")}
                        </Button>
                      </li>
                    ))}
                  </ul>
                </div>
              </Box>
            )}
          </Grid>
          <Grid item xs={12} sm={8}>
            <PromotionsTable
              data={data}
              total={total}
              open={open}
              files={files}
            />
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};

export default PromotionCode;
