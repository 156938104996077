import React, { useState, useEffect, useContext } from "react";
import { Typography, Box, Divider } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { useTranslation } from "react-i18next";
import axios from "axios";
import Cookies from "js-cookie";

const SectionTitle = ({ title }) => {
  return (
    <Typography color="primary" variant="h3" gutterBottom>
      {title}
    </Typography>
  );
};
const PromotionsTable = ({ open, files }) => {
  const [total, setTotal] = useState(0);
  const [error, setError] = useState(null);
  const [data, setData] = useState([]);
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(20);

  const { t } = useTranslation();

  let config = {
    method: "get",
    maxBodyLength: Infinity,
    url: `${process.env.REACT_APP_BASE_URL}/PromoCode?Pagination.Page=${page}&Pagination.Size=${pageSize}`,
    headers: {
      Authorization: `Bearer ${Cookies.get("_auth")}`,
    },
    data: data,
  };

  const fetchData = async () => {
    try {
      const response = await axios.request(config);
      const responseDataItems = await response.data.items;
      const responseTotalItems = await response.data.totalCount;

      setData(responseDataItems);
      setTotal(responseTotalItems);
    } catch (error) {
      setError(error);
    }
  };

  // useEffect for fetching data
  useEffect(() => {
    fetchData();
  }, [open, files]);

  return (
    <Box
      sx={{
        p: 2,
        border: "1px solid rgba(69, 56, 52, 0.15)",
        borderRadius: "2px",
      }}
    >
      <Typography variant="text" color="primary" sx={{ fontWeight: "bold" }}>
        {t("promotionCodesList")}
      </Typography>

      {/* Data Table */}
      {/* <Divider /> */}
      <DataGrid
        sx={{
          borderRadius: "2px",
          marginTop: "2px",
        }}
        rows={data}
        columns={[
          {
            field: "code",
            headerName: t("code"),
            flex: 2,
            valueGetter: (params) => params.row.code,
          },
          {
            field: "expireDate",
            headerName: t("endDate"),
            flex: 2,
            valueGetter: (params) =>
              new Date(params.row.expireDate).toLocaleDateString("tr-TR") ===
              "Invalid Date"
                ? t("none2")
                : new Date(params.row.expireDate).toLocaleDateString("tr-TR"),
          },
          {
            field: "isOneTimeUse",
            headerName: t("singleUse"),
            flex: 2,
            valueGetter: (params) =>
              params.row.isOneTimeUse ? t("yes") : t("no"),
          },
          {
            field: "isUsed",
            headerName: t("hasItBeenUsed"),
            flex: 2,
            valueGetter: (params) => (params.row.isUsed ? t("yes") : t("no")),
          },
        ]}
        autoHeight
        pageSize={5}
        rowsPerPageOptions={[5]}
        disableSelectionOnClick
      />
    </Box>
  );
};

export default PromotionsTable;
