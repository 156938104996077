import Cookies from "js-cookie";
let data = "";

let togglerStatusConfig = {
  method: "get",
  maxBodyLength: Infinity,
  url: `${process.env.REACT_APP_BASE_URL}/OrderFBMMStatus/count`,
  headers: {
    Authorization: `Bearer ${Cookies.get("_auth")}`,
  },
  data: data,
};

export default togglerStatusConfig;
