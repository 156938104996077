import React from "react";

const PriceCalculationToolIconActice = () => {
  return (
    <svg
      width="36"
      height="36"
      viewBox="0 0 36 36"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="36" height="36" rx="5" fill="#FEF7F5" />
      <g clip-path="url(#clip0_3100_5945)">
        <path
          d="M23.7342 10.2666H12.2676V16.1333H23.7342V10.2666Z"
          stroke="#EB603A"
          stroke-width="1.5"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M27.7324 28.7555V7.24438C27.7324 6.70437 27.2966 6.2666 26.7591 6.2666L9.23909 6.2666C8.70153 6.2666 8.26575 6.70437 8.26575 7.24438V28.7555C8.26575 29.2955 8.70153 29.7333 9.23909 29.7333H26.7591C27.2966 29.7333 27.7324 29.2955 27.7324 28.7555Z"
          fill="#EB603A"
          fill-opacity="0.3"
          stroke="#EB603A"
          stroke-width="1.5"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M13.2 21.7333C13.8627 21.7333 14.4 21.196 14.4 20.5333C14.4 19.8705 13.8627 19.3333 13.2 19.3333C12.5373 19.3333 12 19.8705 12 20.5333C12 21.196 12.5373 21.7333 13.2 21.7333Z"
          fill="#EB603A"
        />
        <path
          d="M18.0008 21.7333C18.6635 21.7333 19.2008 21.196 19.2008 20.5333C19.2008 19.8705 18.6635 19.3333 18.0008 19.3333C17.338 19.3333 16.8008 19.8705 16.8008 20.5333C16.8008 21.196 17.338 21.7333 18.0008 21.7333Z"
          fill="#EB603A"
        />
        <path
          d="M22.7996 21.7333C23.4624 21.7333 23.9996 21.196 23.9996 20.5333C23.9996 19.8705 23.4624 19.3333 22.7996 19.3333C22.1369 19.3333 21.5996 19.8705 21.5996 20.5333C21.5996 21.196 22.1369 21.7333 22.7996 21.7333Z"
          fill="#EB603A"
        />
        <path
          d="M13.2 26.5333C13.8627 26.5333 14.4 25.996 14.4 25.3333C14.4 24.6706 13.8627 24.1333 13.2 24.1333C12.5373 24.1333 12 24.6706 12 25.3333C12 25.996 12.5373 26.5333 13.2 26.5333Z"
          fill="#EB603A"
        />
        <path
          d="M18.0008 26.5333C18.6635 26.5333 19.2008 25.996 19.2008 25.3333C19.2008 24.6706 18.6635 24.1333 18.0008 24.1333C17.338 24.1333 16.8008 24.6706 16.8008 25.3333C16.8008 25.996 17.338 26.5333 18.0008 26.5333Z"
          fill="#EB603A"
        />
        <path
          d="M22.7996 26.5333C23.4624 26.5333 23.9996 25.996 23.9996 25.3333C23.9996 24.6706 23.4624 24.1333 22.7996 24.1333C22.1369 24.1333 21.5996 24.6706 21.5996 25.3333C21.5996 25.996 22.1369 26.5333 22.7996 26.5333Z"
          fill="#EB603A"
        />
      </g>
      <defs>
        <clipPath id="clip0_3100_5945">
          <rect
            width="22"
            height="26"
            fill="white"
            transform="translate(7 5)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export default PriceCalculationToolIconActice;
