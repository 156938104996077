import React from "react";
import {
  Box,
  Typography,
  Divider,
  MenuItem,
  Select,
  RadioGroup,
  Radio,
} from "@mui/material";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";

import { alpha, styled } from "@mui/material/styles";
import InputBase from "@mui/material/InputBase";
import { useTheme } from "@emotion/react";

import { useTranslation } from "react-i18next";
import CustomLabel from "../forms/form-elements/CustomLabel";

const BootstrapInput = styled(InputBase)(({ theme }) => ({
  "label + &": {
    marginTop: theme.spacing(3),
    color: `${alpha(theme.palette.text.main, 0.25)} 0 0 0 0.2rem`,
  },
  "& .MuiInputBase-input": {
    borderRadius: 2,
    position: "relative",
    border: "1px solid",
    fontSize: 14,
    fontStyle: "italic",
    height: "12px",
    width: "100%",
    padding: "10px 12px",
    marginTop: "0.5rem",
    borderColor: theme.palette.primary.bordersColor,
    transition: theme.transitions.create([
      "border-color",
      "background-color",
      "box-shadow",
    ]),
  },
}));
const Delivery = ({
  handleChangeWillBeSent,
  willBeSent,
  handleChange,
  countryOfDistributionList,
  willBeDelivered,
  showWillBeSent,
  toBeDelivered,
  showCountryWithWarehouseList,
  countryWithWareHouseList,
  handleChangeWillBeSentCountry,
  willBeSentCountry,
  isShippingMethod,
  setIsShippingMethod,
  handleIsShippingMethod,
}) => {
  const theme = useTheme();
  const { t } = useTranslation();
  return (
    <div>
      <Box
        sx={{
          p: 2,
          border: "1px solid rgba(69, 56, 52, 0.15)",
          borderRadius: "2px",
        }}
      >
        <Typography variant="text" color="primary" sx={{ fontWeight: "bold" }}>
          {t("delivery")}
        </Typography>
        <Box
          variant="text"
          sx={{
            fontSize: "11px",
            fontFamily: "Roboto",
            marginTop: "5px",
          }}
        >
          {/* Nullam id dolor id nibh ultricies vehicula ut id elit. */}
        </Box>
        <Divider sx={{ marginTop: "10px" }} />

        <Box sx={{ width: "50%" }}>
          <Typography
            sx={{
              mt: 1,
              mb: 1,
            }}
            variant="body2"
            fontWeight="500"
            color="text"
          >
            {t("toWhomwillTheProductBeDelivered")}
          </Typography>
          <Select
            labelId="demo-multiple-name-label"
            id="currencyId"
            input={<BootstrapInput label="Currency" />}
            size="medium"
            displayEmpty
            fullWidth="50%"
            value={willBeDelivered}
            onChange={handleChange}
          >
            <MenuItem disabled value="">
              <em>{t("select")}</em>
            </MenuItem>
            {toBeDelivered.map((opt, index) => (
              <MenuItem key={index} value={opt.id}>
                {opt.title}
              </MenuItem>
            ))}
          </Select>
        </Box>

        {showWillBeSent && showCountryWithWarehouseList === false ? (
          <Box sx={{ width: "50%" }}>
            <Typography
              sx={{
                mt: 1,
                mb: 1,
              }}
              variant="body2"
              fontWeight="500"
              color="text"
            >
              {t("whichCountryWillItBeSentTo")}
            </Typography>
            <Select
              labelId="demo-multiple-name-label"
              id="currency2Id"
              input={<BootstrapInput label="Currency" />}
              size="medium"
              displayEmpty
              fullWidth="50%"
              value={willBeSent}
              onChange={handleChangeWillBeSent}
            >
              <MenuItem disabled value="">
                <em>{t("select")}</em>
              </MenuItem>
              {countryOfDistributionList.map((opt) => (
                <MenuItem key={opt.id} value={opt.country.id}>
                  {opt.country.title}
                </MenuItem>
              ))}
            </Select>
          </Box>
        ) : (
          ""
        )}
        {showCountryWithWarehouseList && showWillBeSent === false ? (
          <Box sx={{ width: "50%" }}>
            <Typography
              sx={{
                mt: 1,
                mb: 1,
              }}
              variant="body2"
              fontWeight="500"
              color="text"
            >
              {t("whichCountryWillItBeSentTo")}
            </Typography>
            <Select
              labelId="demo-multiple-name-label"
              id="currency3Id"
              input={<BootstrapInput label="Currency11" />}
              size="medium"
              displayEmpty
              fullWidth="50%"
              value={willBeSentCountry}
              onChange={handleChangeWillBeSentCountry}
            >
              <MenuItem disabled value="">
                <em>{t("select")}</em>
              </MenuItem>
              {countryWithWareHouseList.map((opt, index) => (
                <MenuItem key={index} value={opt.id}>
                  {opt.title}
                </MenuItem>
              ))}
            </Select>
          </Box>
        ) : (
          ""
        )}
        {willBeDelivered === 1 || willBeDelivered === 2 ? (
          <Box>
            <FormControl variant="standard" key="isShippingMethod">
              <CustomLabel title={t("shippingMethod")} isRequired />
              <RadioGroup
                sx={{ pt: "35px", ml: "10px" }}
                row
                aria-labelledby="demo-row-radio-buttons-group-label"
                name="row-radio-buttons-group"
                value={isShippingMethod}
                onChange={handleIsShippingMethod}
              >
                <FormControlLabel
                  color="success"
                  className={
                    isShippingMethod === true
                      ? "custom-radio-checked"
                      : "custom-radio"
                  }
                  value={true}
                  control={
                    <Radio
                      sx={{
                        color: "#45383426",
                        "&.Mui-checked": {
                          color: "#34AE1A",
                        },
                      }}
                    />
                  }
                  label={
                    <Typography
                      fontWeight="500"
                      color={
                        isShippingMethod === true
                          ? theme.palette.success.main
                          : "#453834"
                      }
                      fontSize="14px"
                    >
                      {t("shipmentByParcel")}
                    </Typography>
                  }
                />
                <FormControlLabel
                  color="success"
                  className={
                    isShippingMethod === false
                      ? "custom-radio-checked"
                      : "custom-radio"
                  }
                  value={false}
                  control={
                    <Radio
                      sx={{
                        color: "#45383426",
                        "&.Mui-checked": {
                          color: "#34AE1A",
                        },
                      }}
                    />
                  }
                  label={
                    <Typography
                      fontWeight="500"
                      color={
                        isShippingMethod === false
                          ? theme.palette.success.main
                          : "#453834"
                      }
                      fontSize="14px"
                    >
                      {t("shipmentByPallet")}
                    </Typography>
                  }
                />
              </RadioGroup>
            </FormControl>
          </Box>
        ) : (
          ""
        )}
      </Box>
    </div>
  );
};

export default Delivery;
