import React from "react";
import { alpha, styled } from "@mui/material/styles";
import InputBase from "@mui/material/InputBase";

const BootstrapInput = styled(InputBase)(({ theme }) => ({
  "label + &": {
    marginTop: theme.spacing(3),
    color: `${alpha(theme.palette.text.main, 0.25)} 0 0 0 0.2rem`,
  },
  "& .MuiInputBase-input": {
    borderRadius: 2,
    position: "relative",
    border: "1px solid",
    fontSize: 14,
    fontStyle: "italic",
    height: "12px",
    width: "100%",
    padding: "10px 12px",
    marginTop: "0.5rem",
    borderColor: theme.palette.primary.bordersColor,
    transition: theme.transitions.create([
      "border-color",
      "background-color",
      "box-shadow",
    ]),
    "&:focus": {
      boxShadow: `${alpha(theme.palette.primary.main, 0.25)} 0 0 0 0.2rem`,
      borderColor: theme.palette.primary.main,
      color: theme.palette.text.main,
    },
  },
}));
const CustomInput = ({
  placeholder,
  required,
  isMultiline,
  onChange,
  isLenght,

  classname,
}) => {
  return (
    <BootstrapInput
      className={classname}
      placeholder={placeholder}
      required={required}
      multiline={isMultiline}
      onChange={(e) => onChange(e)}
      // isLenght={isLenght} //bunun icin henuz bir mantik dusunmedim(uzerindeki labelda karakter sayisi gosterecek)
    />
  );
};

export default CustomInput;
