import axios from "axios";
import React, { useEffect, useState, useCallback } from "react";
import Cookies from "js-cookie";
import Link from "@mui/material/Link";
import { useSignOut, useSignIn } from "react-auth-kit";
import { useParams, useNavigate, Form } from "react-router-dom";
import {
  Box,
  Divider,
  Grid,
  Typography,
  useTheme,
  Paper,
  Button,
  Chip,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  IconButton,
  Checkbox,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  MenuItem,
  FormControl,
  Select as MuiSelect,
  LinearProgress,
  Snackbar,
  TextField,
  Avatar,
  ListItemText,
  Radio,
  FormControlLabel,
  RadioGroup,
  Menu,
  ListItemIcon,
  Tabs,
  Tab,
} from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";
import Topbar from "./global/Topbar";
import DeleteIcon from "@mui/icons-material/Delete";
import CloseIcon from "@mui/icons-material/Close";
import { useDropzone } from "react-dropzone";
import BoxTitle from "../components/forms/form-elements/BoxTitle";
import FormGroup from "../components/forms/form-elements/FormGroup";
import { alpha, styled } from "@mui/material/styles";
import InputBase from "@mui/material/InputBase";
import CustomLabel from "../components/forms/form-elements/CustomLabel";
import ContractIcon from "../components/icons/ContractIcon";
import UploadIcon from "../components/icons/UploadIcon";
import SubmerchantIcon from "../components/icons/SubmerchantIcon";
import SendPassword from "../components/icons/SendPassword";
import { AddLink, Cookie, InfoRounded, InfoTwoTone } from "@mui/icons-material";
import GroupAddIcon from "@mui/icons-material/GroupAdd";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import PropTypes from "prop-types";
import { DataGrid } from "@mui/x-data-grid";
import { GridActionsCellItem } from "@mui/x-data-grid";
import FileCopyIcon from "@mui/icons-material/FileCopy";
import MessageFIleIcon from "../components/icons/MessageFIleIcon";
import SendMessage from "../components/partials/SendMessage";
import AddLinkIcon from "../components/icons/AddLinkIcon";
import AddMMUserIcon from "../components/icons/AddMMUserIcon";
import AddNoteIcon from "../components/icons/AddNoteIcon";
import SendMessageIcon from "../components/icons/SendMessageIcon";
import SendPassWordIcon from "../components/icons/SendPassWordIcon";
import MuiAlert from "@mui/material/Alert";
import Select, {
  components,
  PlaceholderProps,
  MultiValueGenericProps,
  MultiValueProps,
  OnChangeValue,
  OptionProps,
  SingleValueProps,
  MenuProps as SelectMenuProps,
} from "react-select";
import PointIcon from "../components/icons/PointIcon";
import PointIconForMenu from "../components/icons/PointIconForMenu";
import { Alert, AlertTitle } from "@mui/lab";
import AccountManagerIcon from "../components/icons/AccountManagerIcon";
import { useTranslation } from "react-i18next";

function ExpandableCell({ value }) {
  const [expanded, setExpanded] = React.useState(false);

  return (
    <div
      style={{
        lineBreak: "anywhere",
      }}
    >
      {expanded ? value : value.slice(0, 200)}&nbsp;
      {value.length > 200 && (
        // eslint-disable-next-line jsx-a11y/anchor-is-valid
        <Link
          type="button"
          component="button"
          sx={{ fontSize: "inherit" }}
          onClick={() => setExpanded(!expanded)}
        >
          {expanded ? "Daha Az Göster" : "Devamını Oku"}
        </Link>
      )}
    </div>
  );
}

const Placeholder = props => {
  return <components.Placeholder {...props} />;
};

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ pt: 2 }}>{children}</Box>}
    </div>
  );
}

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

const typoCssProps = {
  fontWeight: "500",
  fontSize: "14px",
};

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const notes = [
  // {
  //   id: "1",
  //   title: "Not 1",
  //   description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
  //   date: "12.12.2021",
  // },
  // {
  //   id: "2",
  //   title: "Not 2",
  //   description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
  //   date: "12.12.2021",
  // },
  // {
  //   id: "3",
  //   title: "Not 3",
  //   description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
  //   date: "12.12.2021",
  // },
];

const messages = [
  // {
  //   sender: "Mike",
  //   merchantName: "Papatya",
  //   date: "12.12.2021 15:30",
  //   avatar: "https://picsum.photos/200",
  //   message:
  //     "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
  // },
  // {
  //   sender: "Sinem",
  //   merchantName: "Mega Merchant",
  //   date: "12.12.2021 15:30",
  //   avatar: "https://picsum.photos/200",
  //   message:
  //     "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
  //   files: [
  //     {
  //       name: "İster Dokümanı.pdf",
  //       url: "https://picsum.photos/200",
  //     },
  //     {
  //       name: "İster Dokümanı.pdf",
  //       url: "https://picsum.photos/200",
  //     },
  //   ],
  // },
  // {
  //   sender: "Mike",
  //   merchantName: "Papatya",
  //   date: "12.12.2021 15:30",
  //   avatar: "https://picsum.photos/200",
  //   message:
  //     "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
  // },
];

const currencies = [
  {
    id: "914228f0-2483-461d-a473-ab2c975f8760",
    name: "TRY",
  },
  {
    id: "447cb078-b48a-48c4-bdb6-e7ffc5ddf51e",
    name: "EEK",
  },
  {
    id: "30bf82ac-5add-4345-b5d2-b841e002e328",
    name: "USD",
  },
  {
    id: "89e774ed-b6e2-40e2-a4bf-5c418d57fff1",
    name: "AUD",
  },
  {
    id: "2b207dbc-7ff3-4b36-be83-231672622957",
    name: "EUR",
  },
  {
    id: "16c46384-0864-4b77-9f03-8fd2461ec0f1",
    name: "GBP",
  },
  {
    id: "31b8bc45-2659-41be-96fd-c15e25d76bfa",
    name: "CHF",
  },
  {
    id: "afd00d04-9c4c-465a-9a57-c373f5a2c59d",
    name: "SEK",
  },
  {
    id: "3cfcb4a4-04b1-4c22-9dd3-09936f733802",
    name: "CAD",
  },
  {
    id: "d518297f-bf03-4417-b3cd-3734ce69d8df",
    name: "SAR",
  },
  {
    id: "372e4436-9526-4a6d-9d07-f459d46a3cbf",
    name: "RUB",
  },
  {
    id: "b945bd24-319b-4b6d-b994-96c0d2fb7ef4",
    name: "AED",
  },
  {
    id: "0e866ced-4a20-469d-a186-93b44f753397",
    name: "INR",
  },
  {
    id: "28fa4b2e-a878-44bc-a5be-59dabc18b916",
    name: "PLN",
  },
];

const ChangeStatusDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

const AddLinksDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
    width: "700px!important",
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

const ChangeSubmerchantDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

function createChangeStatusData(name, status) {
  return { name, status };
}

const BootstrapSelect = styled(InputBase)(({ theme }) => ({
  "label + &": {
    marginTop: theme.spacing(3),
    color: `${alpha(theme.palette.text.main, 0.25)} 0 0 0 0.2rem`,
  },
  "& .MuiInputBase-input": {
    borderRadius: 2,
    position: "relative",
    border: "1px solid",
    fontSize: 14,
    fontStyle: "italic",
    height: "4px!important",
    width: "100%",
    padding: "10px 12px",
    lineHeight: "1",
    marginTop: "0.5rem",
    borderColor: theme.palette.primary.bordersColor,
    transition: theme.transitions.create([
      "border-color",
      "background-color",
      "box-shadow",
    ]),
    minHeight: " 0.8rem!important",
    "&:focus": {
      boxShadow: `${alpha(theme.palette.primary.main, 0.25)} 0 0 0 0.2rem`,
      borderColor: theme.palette.primary.main,
      color: theme.palette.text.main,
    },
  },
}));

const BootstrapInput = styled(InputBase)(({ theme }) => ({
  "label + &": {
    marginTop: theme.spacing(3),
    color: `${alpha(theme.palette.text.main, 0.25)} 0 0 0 0.2rem`,
  },
  "& .MuiInputBase-input": {
    borderRadius: 2,
    position: "relative",
    border: "1px solid",
    fontSize: 14,
    fontStyle: "italic",
    height: "12px",
    width: "100%",
    padding: "10px 12px",
    marginTop: "0.5rem",
    borderColor: theme.palette.primary.bordersColor,
    transition: theme.transitions.create([
      "border-color",
      "background-color",
      "box-shadow",
    ]),
    "&:focus": {
      boxShadow: `${alpha(theme.palette.primary.main, 0.25)} 0 0 0 0.2rem`,
      borderColor: theme.palette.primary.main,
      color: theme.palette.text.main,
    },
  },
}));

const merchantPlatforms = [
  {
    title: "Amazon",
    value: "ccc4c07e-335d-456c-bb63-9dfae9c19201",
  },
  {
    title: "Etsy",
    value: "db98e497-dede-4761-a11a-bb4a0aa78d14",
  },
  {
    title: "Walmart",
    value: "51fe386b-035e-4b07-a743-ce0d9182f08d",
  },
  {
    title: "Ebay",
    value: "df4ad832-58be-4991-bd20-66e230b42cc0",
  },
  {
    title: "Otto",
    value: "f913c1f8-6b4f-4503-a6dd-3252b47eec7f",
  },
  {
    title: "Joom",
    value: "8192490a-1ddf-4738-8cdc-a5da148e9f91",
  },
  { title: "Jumia", value: "a15b0e98-304e-4259-8a3b-2153e31bb8c2" },
  {
    title: "Allegro",
    value: "ce66d37b-7b36-4538-9306-60654b11ff43",
  },
  {
    title: "Noon",
    value: "692adc3f-ca78-49d0-8001-6248a9851370",
  },
  {
    title: "Ozon",
    value: "4d67083c-8e8f-490a-a65c-55b36ad74d28",
  },
  {
    title: "Ali Express",
    value: "a76f300c-4e22-49c3-95eb-5542cc10e554",
  },
  {
    title: "Tiktok Shop",
    value: "592afc6c-37aa-4dfa-af7d-0cf5095c7543",
  },
  {
    title: "Wayfair",
    value: "850ce092-fee7-45b0-9fc9-e138d5ea1561",
  },
  {
    title: "Shopify",
    value: "923bcfa5-6da6-44ea-9145-49ab2e7d2c52",
  },
];

const paymentOptions = [
  { title: "Peşin", value: "d03b4721-1d6a-4f16-aaa7-b2faf83b8f05" },
  { title: "Çek", value: "e7eb806b-5ce9-4288-a5b6-daf13394c47c" },
  { title: "Havale / EFT", value: "08b28ca6-85f8-4e0e-b9b7-5f3cec3dfbf1" },
];

const Merchants = () => {
  const signOut = useSignOut();
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [anchorElMessageBox, setAnchorElMessegeBox] = React.useState(null);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [anchorEls, setAnchorEls] = useState(null);
  const { t } = useTranslation();
  const notesMenuOpen = Boolean(anchorEls);
  // const [notesMenuOpen, setNotesMenuOpen] = useState(false);
  const open = Boolean(anchorEl);
  const openMessageBox = Boolean(anchorElMessageBox);
  const [messages, setMessages] = useState([]);
  const handleClickMessageBox = event => {
    setAnchorElMessegeBox(event.currentTarget);
  };

  const handleCloseMessageBox = () => {
    setAnchorElMessegeBox(null);
  };

  const sendMessage = () => {
    // if this function is called ? re render the messages}

    getUserMessages();
  };

  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const navigate = useNavigate();
  const merchantID = useParams().id;
  const theme = useTheme();
  const signIn = useSignIn();
  const [notes, setNotes] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const [merchant, setMerchant] = useState({});
  const [status, setStatus] = useState("");
  const [openStatus, setOpenStatus] = useState(false);
  const [openFileInsert, setOpenFileInsert] = useState(false);
  const [fileRows, setFileRows] = useState([]);
  const [uploadedFiles, setUploadedFiles] = useState([]);
  const [progress, setProgress] = useState(0);
  const [uploadError, setUploadError] = useState(null);
  const [message, setMessage] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const [isSnackbarOpen, setSnackbarOpen] = useState(false);
  const [aggrementModal, setAggrementModal] = useState(false);
  const [aggrementData, setAggrementData] = useState([]);
  const [getMerchantMarketPlaceList, setGetMerchantMarketPlaceList] = useState(
    []
  );
  const [testUserDialog, setTestUserDialog] = useState(false);
  const [point, setPoint] = useState(0);
  const [openPoint, setOpenPoint] = useState(false);
  const [newNote, setNewNote] = useState("");
  const [addLinksTitle, setAddLinksTitle] = useState("");
  const [addLinksUrl, setAddLinksUrl] = useState("");
  const [selectedMerchantMarketPlaceList, setSelectedMerchantMarketPlaceList] =
    useState([]);
  const [productBrands, setProductBrands] = useState([]);
  const [openSubmerchant, setOpenSubmerchant] = useState(false);
  const [openLinks, setOpenLinks] = useState(false);
  const [openNotesModal, setOpenNotesModal] = useState(false);
  const [openNotesDeleteModal, setOpenNotesDeleteModal] = useState(false);
  const [countryData, setCountryData] = useState([]);
  const [annualServiceFee, setAnnualServiceFee] = useState(null);
  const [salesCommission, setSalesCommission] = useState(null);
  const [advertisingCommission, setAdvertisingCommission] = useState(null);
  const [incentiveCommission, setIncentiveCommission] = useState(null);
  const [paymentTerm, setPaymentTerm] = useState(null);
  const [paymentMethodId, setPaymentMethodId] = useState("");
  const [merchantPlatformId, setMerchantPlatformId] = useState([]);
  const [selectedCountries, setSelectedCountries] = useState([]);
  const [linksErrors, setLinksErrors] = useState({});
  const [merchantBusinessTypeId, setMerchantBusinessTypeId] = useState(
    "e2b9d968-881b-42e2-90f2-223dc64c52fe"
  );
  const [merchantExporterId, setMerchantExporterId] = useState(
    "8fa4962a-a67f-423a-b487-5b63cb38bcb8"
  );
  const [submerchantButtonLoading, setSubmerchantButtonLoading] =
    useState(false);
  const [creatingNote, setCreatingNote] = useState(false);
  const [currency, setCurrency] = useState("");
  const [merchantPlatformList, setMerchantPlatformList] = useState([]);
  const [links, setLinks] = useState([]);
  const [merchantList, setMerchantList] = useState([]);
  const [setLinksDialog, setSetLinksDialog] = useState(false);
  const [mmUserList, setMMUserList] = useState([]);
  const [selectedMMUser, setSelectedMMUser] = useState("");
  const [selectedMMAccountManager, setSelectedMMAccountManager] = useState("");
  const [mmUserDialog, setMMUserDialog] = useState(false);
  const [mmAccountManagerDialog, setMMAccountManagerDialog] = useState(false);
  const [openSendMessageDialog, setOpenSendMessageDialog] = useState(false);
  const [selectedNote, setSelectedNote] = useState("");
  const [openNotesUpdateModal, setOpenNotesUpdateModal] = useState(false);
  const [selectedNoteContent, setSelectedNoteContent] = useState("");
  const [testUserIsSubmerchant, setTestUserIsSubmerchant] = useState(false);
  const [testUserSelectedProductBrands, setTestUserSelectedProductBrands] =
    useState([]);
  const [testUserSelectedMerchant, setTestUserSelectedMerchant] = useState("");
  const [submitLoading, setSubmitLoading] = useState(false);
  const [loginLoading, setLoginLoading] = useState(false);
  const [submitSuccess, setSubmitSuccess] = useState(false);
  const [userName, setUserName] = useState("mmuser");
  const [loginDialog, setLoginDialog] = useState(false);
  const [merchantBrands, setMerchantBrands] = useState([]);

  const getUserMessages = async () => {
    let config = {
      method: "get",
      maxBodyLength: Infinity,
      url: `${process.env.REACT_APP_BASE_URL}/Merchant/GetMerchantMessageByMerchantId/${merchantID}`,
      headers: {
        Authorization: `Bearer ${Cookies.get("_auth")}`,
      },
    };
    try {
      const response = await axios(config);
      console.log(response.data.items);
      setMessages(response.data.items);
    } catch (error) {
      console.log(error);
    }
  };

  // get country data function
  const getCountryData = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BASE_URL}/Country?Pagination.Page=0&Pagination.Size=100`
      );
      setCountryData(response.data.items);
    } catch (error) {}
  };

  const handleOpenSendMessageDialog = () => {
    setOpenSendMessageDialog(true);
    handleClose();
  };

  const handleCloseSendMessageDialog = () => {
    setOpenSendMessageDialog(false);
  };

  const onDrop = useCallback(async acceptedFiles => {
    const validFiles = [];
    const invalidFiles = [];

    for (const file of acceptedFiles) {
      validFiles.push({
        name: file.name,
        data: file,
      });
    }

    setUploadedFiles(prevFiles => [...prevFiles, ...validFiles]);
  }, []);

  const handleMenuItemClick = (event, index, id) => {
    setSelectedIndex(index);
  };

  const openNotesUpdateModalHandler = (id, content) => {
    setSelectedNoteContent(content);
    setSelectedNote(id);
    setOpenNotesUpdateModal(true);
  };

  const closeNotesUpdateModalHandler = () => {
    setOpenNotesUpdateModal(false);
  };

  const handleMerchantPlatformListChange = event => {
    const value = event.target.value;
    setMerchantPlatformList(prevMerchantPlatformList => {
      if (prevMerchantPlatformList.includes(value)) {
        return prevMerchantPlatformList.filter(item => item !== value);
      } else {
        return [...prevMerchantPlatformList, value];
      }
    });
  };
  const { getRootProps, getInputProps, isDragAccept, isDragReject } =
    useDropzone({
      onDrop,
    });

  const submitAggrement = e => {
    e.preventDefault();
    const data = {
      id: merchantID,
      annualServiceFee: annualServiceFee,
      salesCommission: salesCommission,
      advertisingCommission: advertisingCommission,
      incentiveCommission: incentiveCommission,
      paymentTerm: paymentTerm,
      paymentMethodId: paymentMethodId,
      merchantPlatformList: merchantPlatformId,
      merchantCountryList: selectedCountries,
      merchantBusinessTypeId: merchantBusinessTypeId,
      merchantExporterId: merchantExporterId,
      merchantMarketPlaceList: merchantPlatformList,
      currencyId: currency,
    };

    let config = {
      method: "put",
      maxBodyLength: Infinity,
      url: `${process.env.REACT_APP_BASE_URL}/Merchant/UpdateContractDetail/${merchantID}`,
      headers: {
        Authorization: `Bearer ${Cookies.get("_auth")}`,
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios(config)
      .then(response => {
        setSuccessMessage(t("theContractHasBeenSuccessfullyUpdated"));
        fetchMerchantData();
        setSnackbarOpen(true);
        setAggrementModal(false);
      })
      .catch(error => {
        setError(error);
      });
  };

  const handleDeleteFile = fileName => {
    // DosyayÄ± listeden kaldÄ±r
    setUploadedFiles(prevFiles =>
      prevFiles.filter(file => file.name !== fileName)
    );
  };

  const handleCloseSnackbar = () => {
    setSnackbarOpen(false);
  };

  const openNotesModalHandler = () => {
    setOpenNotesModal(true);
  };

  const closeNotesModalHandler = () => {
    setOpenNotesModal(false);
  };

  const openNotesDeleteModalHandler = id => {
    setSelectedNote(id);
    setOpenNotesDeleteModal(true);
  };

  const closeNotesDeleteModalHandler = () => {
    setOpenNotesDeleteModal(false);
  };

  const getLinksFromAPI = async () => {
    let config = {
      method: "get",
      maxBodyLength: Infinity,
      url: `${process.env.REACT_APP_BASE_URL}/Merchant/GetMerchantLinkByMerchantId/${merchantID}`,
      headers: {
        Authorization: `Bearer ${Cookies.get("_auth")}`,
      },
    };
    const response = await axios(config);
    setLinks(response.data);
  };

  const handleChangeNote = event => {
    setNewNote(event.target.value);
  };

  const handleSentNote = () => {
    setCreatingNote(true);
    let data = {
      merchantId: merchantID,
      content: newNote,
    };

    let config = {
      method: "post",
      maxBodyLength: Infinity,
      url: `${process.env.REACT_APP_BASE_URL}/Merchant/CreateMerchantNote/${merchantID}`,
      headers: {
        Authorization: `Bearer ${Cookies.get("_auth")}`,
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios(config)
      .then(response => {
        getNotesFromAPI();
        setSuccessMessage(t("noteSuccessfullyAdded"));
        setSnackbarOpen(true);
        setOpenNotesModal(false);
        setCreatingNote(false);
      })
      .catch(error => {
        setError(error);
      });
  };

  const handlePaymentTermChange = event => {
    // const validValue = event.target.value.replace(/[^0-9]/g, "");
    // setPaymentTerm(validValue);
    let inputValue = event.target.value;
    inputValue = inputValue.replace(/[-.]/g, "");
    inputValue = inputValue.replace(/[^0-9]/g, "");
    setPaymentTerm(inputValue);
  };

  const handleChangeSetLinksTitle = event => {
    setAddLinksTitle(event.target.value);
    validateFieldsFromLinks(event.target.name, event.target.value);
  };

  const handleChangeSetLinksUrl = event => {
    setAddLinksUrl(event.target.value);
    validateFieldsFromLinks(event.target.name, event.target.value);
  };

  useEffect(() => {
    const getSubmerchantDataFromAPI = async () => {
      let config = {
        method: "get",
        maxBodyLength: Infinity,
        url: `${process.env.REACT_APP_BASE_URL}/Merchant/GetSubMerchantProductBrands/${merchantID}`,
        headers: {
          Authorization: `Bearer ${Cookies.get("_auth")}`,
        },
      };
      const response = await axios(config);
      response.data.items.sort((a, b) =>
        a.title.localeCompare(b.title, "tr-TR", { sensitivity: "base" })
      );
      setGetMerchantMarketPlaceList(response.data.items);
    };
    const getMerchantBrands = async () => {
      let config = {
        method: "get",
        maxBodyLength: Infinity,
        url: `${process.env.REACT_APP_BASE_URL}/Merchant/GetSubMerchantBrandByMerchantId/${merchantID}`,
        headers: {
          Authorization: `Bearer ${Cookies.get("_auth")}`,
        },
      };
      const response = await axios(config);
      setMerchantBrands(response.data.items);
    };

    getUserMessages();
    getSubmerchantDataFromAPI();
    getMerchantBrands();
  }, []);

  const handleSubmitFile = async e => {
    e.preventDefault(); // Formun sayfa yenilemesini engellemek iÃ§in

    setProgress(0);

    const formData = new FormData();
    uploadedFiles.forEach(file => {
      formData.append("files", file.data, file.name);
    });

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_BASE_URL}/Merchant/Upload?Id=${merchantID}`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${Cookies.get("_auth")}`,
          },
          onUploadProgress: progressEvent => {
            const percentCompleted = Math.round(
              (progressEvent.loaded * 100) / progressEvent.total
            );
            setProgress(percentCompleted);
          },
        }
      );

      if (response.status === 200) {
        fetchFileData();
        setSuccessMessage(t("filesUploadedSuccessfully"));
        setSnackbarOpen(true);
        setUploadedFiles([]);
        setOpenFileInsert(false);
        setProgress(0);
      } else {
        console.error(t("anErrorOccurredWhileUploadingFiles"));
      }
    } catch (error) {
      console.error(t("anErrorOccurredWhileUploadingFiles"), error);
    }
  };

  const handlePointOpen = () => {
    setOpenPoint(true);
    handleClose();
  };

  const handlePointClose = () => {
    setOpenPoint(false);
  };

  const handleTestUserOpen = () => {
    setTestUserDialog(true);
    handleClose();
  };

  const handleTestUserClose = () => {
    setTestUserDialog(false);
  };

  function sortCountryDataWithTurkeyFirst(countryData) {
    const turkeyId = "f30b42ee-edb6-41b8-a819-565e867041d7";
    const sortedCountryData = [...countryData];
    const turkeyIndex = sortedCountryData.findIndex(
      item => item.id === turkeyId
    );

    if (turkeyIndex !== -1) {
      const turkey = sortedCountryData.splice(turkeyIndex, 1)[0];
      sortedCountryData.unshift(turkey);
    }

    return sortedCountryData;
  }

  const resetSubmerchant = () => {
    setSelectedMerchantMarketPlaceList([]);
  };

  const handleCountryChange = event => {
    setSelectedCountries(event.target.value);
  };

  const selectedSubmerchantChange = event => {
    setProductBrands(event.target.value);
  };

  const handleBack = () => navigate("/");

  const handleClickCloseStatus = () => {
    setOpenStatus(false);
  };
  const handleClickOpenStatus = () => {
    setOpenStatus(true);
    handleClose();
  };

  const handleAggrementModalClose = () => {
    setAggrementModal(false);
  };

  const handleAggrementModalOpen = () => {
    setAggrementModal(true);
    handleClose();
  };

  const handleChangeStatus = e => {
    setStatus(e.target.value);
  };

  const handleTestUserIsSubmerchant = e => {
    setTestUserIsSubmerchant(e.target.checked);
    setTestUserSelectedProductBrands([]);
    setTestUserSelectedMerchant("");
  };

  const noteColumns = [
    {
      field: "content",
      headerName: "Not",
      width: 400,
      flex: 1,
      renderCell: params => <ExpandableCell {...params} />,
    },
    {
      field: "createdAt",
      headerName: t("history"),
      width: 200,
      flex: 0.5,
      valueGetter: params => {
        return new Date(params.row.createdAt).toLocaleDateString("tr-TR");
      },
    },
    {
      field: "actions",
      type: "actions",
      headerName: "",
      getActions: params => [
        <GridActionsCellItem
          icon={<FileCopyIcon />}
          label={t("edit")}
          onClick={() =>
            openNotesUpdateModalHandler(params.row.id, params.row.content)
          }
          showInMenu
        />,
        <GridActionsCellItem
          icon={<DeleteIcon />}
          label={t("delete")}
          onClick={() => openNotesDeleteModalHandler(params.row.id)}
          showInMenu
        />,
      ],
    },
  ];

  const getNotesFromAPI = async () => {
    let config = {
      method: "get",
      maxBodyLength: Infinity,
      url: `${process.env.REACT_APP_BASE_URL}/Merchant/GetMerchantNoteByMerchantId/${merchantID}`,
      headers: {
        Authorization: `Bearer ${Cookies.get("_auth")}`,
      },
    };
    const response = await axios(config);
    setNotes(response.data.items);
  };

  const changeStatusFromAPI = () => {
    let data = {
      id: merchantID,
      statusId: status,
    };
    let config = {
      method: "put",
      maxBodyLength: Infinity,
      url: `${process.env.REACT_APP_BASE_URL}/Merchant/UpdateMerchantStatus/${merchantID}`,
      headers: {
        Authorization: `Bearer ${Cookies.get("_auth")}`,
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios(config)
      .then(response => {
        setSuccessMessage(t("theStatusHasBeenSuccessfullyChanged"));
        fetchMerchantData();
        setSnackbarOpen(true);
        setOpenStatus(false);
      })
      .catch(error => {
        setError(error);
      });
  };

  const changePointFromAPI = () => {
    let data = {
      id: merchantID,
      point: Number(point),
    };
    let config = {
      method: "put",
      maxBodyLength: Infinity,
      url: `${process.env.REACT_APP_BASE_URL}/Merchant/UpdateMerchant/${merchantID}`,
      headers: {
        Authorization: `Bearer ${Cookies.get("_auth")}`,
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios(config)
      .then(response => {
        setSuccessMessage(t("theScoreWasSuccessfullyChanged"));
        fetchMerchantData();
        setSnackbarOpen(true);
        setOpenPoint(false);
      })
      .catch(error => {
        setError(t("anErrorOccurredWhileChangingTheScore"));
      });
  };

  const addLinksFromAPI = () => {
    let data = {
      title: addLinksTitle,
      path: addLinksUrl,
      merchantId: merchantID,
    };
    let config = {
      method: "post",
      maxBodyLength: Infinity,
      url: `${process.env.REACT_APP_BASE_URL}/Merchant/CreateMerchantLink/${merchantID}`,
      headers: {
        Authorization: `Bearer ${Cookies.get("_auth")}`,
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios(config)
      .then(response => {
        setSuccessMessage(t("linkAddedSuccessfully"));
        fetchMerchantData();
        setSnackbarOpen(true);
        setOpenLinks(false);
        getLinksFromAPI();
      })
      .catch(error => {
        setError(error);
      });
  };

  const validateFieldsFromLinks = (fieldName, value) => {
    const newErrors = { ...linksErrors };

    if (fieldName === "title") {
      if (value.length === 0) {
        newErrors.title = t("theHeaderFieldCannotBeLeftEmpty");
      } else if (value.length < 3) {
        newErrors.title = t("theTitleFieldMustBeAtLeastThreeCharacters");
      } else {
        newErrors.title = "";
      }
    }
    if (fieldName === "path") {
      if (value.length === 0) {
        newErrors.path = t("theUrlFieldCannotBeLeftEmpty");
      } else if (value.length < 3) {
        newErrors.path = t("theUrlFieldMustBeAtLeastThreeCharacters");
      } else {
        newErrors.path = "";
      }
    }
    setLinksErrors(newErrors);
  };

  const changeSubMerchantFromAPI = () => {
    let data = {
      merchantId: merchantID,
      productBrands: selectedMerchantMarketPlaceList,
    };

    let config = {
      method: "put",
      maxBodyLength: Infinity,
      url: `${process.env.REACT_APP_BASE_URL}/Merchant/UpdateSubMerchant/${merchantID}`,
      headers: {
        Authorization: `Bearer ${Cookies.get("_auth")}`,
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios(config)
      // if data is exist
      .then(response => {
        setSubmerchantButtonLoading(true);
        // fetchMerchantData();
        console.log("response", response);
        console.log("data", config);
        setSuccessMessage(t("SubMerchantIsDone"));
        setSnackbarOpen(true);
        setOpenSubmerchant(false);
      })
      .catch(error => {
        setError(error);
      })
      .finally(() => {
        setSubmerchantButtonLoading(false);
      });
  };

  const updateMMUserFromAPI = () => {
    let data = {
      merchantId: merchantID,
      userId: selectedMMUser,
    };
    let config = {
      method: "put",
      maxBodyLength: Infinity,
      url: `${process.env.REACT_APP_BASE_URL}/Merchant/UpdateMMUser/${merchantID}`,
      headers: {
        Authorization: `Bearer ${Cookies.get("_auth")}`,
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios(config)
      .then(response => {
        setSuccessMessage(t("responsibleSuccessfullyAdded"));
        fetchMerchantData();
        setSnackbarOpen(true);
        setMMUserDialog(false);
        setMMAccountManagerDialog(false);
      })
      .catch(error => {
        setError(error);
      });
  };

  const updateMMAccountManagerFromAPI = () => {
    let data = {
      merchantId: merchantID,
      userId: selectedMMAccountManager,
    };
    let config = {
      method: "put",
      maxBodyLength: Infinity,
      url: `${process.env.REACT_APP_BASE_URL}/Merchant/UpdateMMAccountManager/${merchantID}`,
      headers: {
        Authorization: `Bearer ${Cookies.get("_auth")}`,
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios(config)
      .then(response => {
        setSuccessMessage(t("accountManagerAddedSuccessfully"));
        fetchMerchantData();
        setSnackbarOpen(true);
        setMMAccountManagerDialog(false);
      })
      .catch(error => {
        setError(error);
      });
  };

  const handleFileInsertClose = () => {
    setOpenFileInsert(false);
  };
  const handleFileInsertOpen = () => {
    setOpenFileInsert(true);
    handleClose();
  };

  const handleSubmerchantClose = () => {
    setOpenSubmerchant(false);
  };
  const handleSubmerchantOpen = () => {
    setOpenSubmerchant(true);
    handleClose();
  };

  const handleLinksClose = () => {
    setOpenLinks(false);
  };
  const handleLinksOpen = () => {
    setOpenLinks(true);
    handleClose();
  };

  const handleMMUserOpen = () => {
    setMMUserDialog(true);
    handleClose();
  };
  const handleMMUserClose = () => {
    setMMUserDialog(false);
  };

  const handleMMAccountManagerOpen = () => {
    setMMAccountManagerDialog(true);
    handleClose();
  };

  const handleCloseMMAccountManagerClose = () => {
    setMMAccountManagerDialog(false);
  };

  const handleDeleteNote = async () => {
    let config = {
      method: "delete",
      maxBodyLength: Infinity,
      url: `${process.env.REACT_APP_BASE_URL}/Merchant/DeleteMerchantNote/${selectedNote}`,
      headers: {
        Authorization: `Bearer ${Cookies.get("_auth")}`,
      },
    };
    await axios(config)
      .then(response => {
        getNotesFromAPI();
        setSuccessMessage(t("noteDeletedSuccessfully"));
        setSnackbarOpen(true);
        setOpenNotesDeleteModal(false);
      })
      .catch(error => {
        setError(error);
      });
  };

  // confirm delete note

  const confirmDeleteLink = async id => {
    let isConfirmed = window.confirm(t("areYouSureYouWantToDeleteTheNote"));

    if (isConfirmed) {
      let config = {
        method: "delete",
        maxBodyLength: Infinity,
        url: `${process.env.REACT_APP_BASE_URL}/Merchant/DeleteMerchantLink/${id}`,
        headers: {
          Authorization: `Bearer ${Cookies.get("_auth")}`,
        },
      };

      await axios(config)
        .then(response => {
          getLinksFromAPI();
          setSuccessMessage(t("linkSuccessfullyDeleted"));
          setSnackbarOpen(true);
        })
        .catch(error => {
          setError(error);
        });
    }
  };

  const handleUpdateNote = async () => {
    let data = {
      id: selectedNote,
      content: selectedNoteContent,
    };
    let config = {
      method: "put",
      maxBodyLength: Infinity,
      url: `${process.env.REACT_APP_BASE_URL}/Merchant/UpdateMerchantNote/${selectedNote}`,
      headers: {
        Authorization: `Bearer ${Cookies.get("_auth")}`,
        "Content-Type": "application/json",
      },
      data: data,
    };
    await axios(config)
      .then(response => {
        getNotesFromAPI();
        setSuccessMessage(t("noteSuccessfullyUpdated"));
        setSnackbarOpen(true);
        setOpenNotesUpdateModal(false);
      })
      .catch(error => {
        setError(error);
      });
  };

  const Item = styled(Paper)(() => ({
    backgroundColor: "transparent",
    paddingLeft: "10px",
    paddingRight: "10px",
    paddingTop: "10px",
    boxShadow: "none",
    border: `1px solid ${theme.palette.primary.bordersColor}}`,
    borderRadius: "2px",
  }));

  //fetch file data

  const getMerchants = useCallback(async () => {
    let config = {
      method: "get",
      maxBodyLength: Infinity,
      url: `${process.env.REACT_APP_BASE_URL}/Merchant/GetList`,
      headers: {
        Authorization: `Bearer ${Cookies.get("_auth")}`,
      },
    };
    const response = await axios(config);
    setMerchantList(response.data.items);
  }, []);

  const getMMUserList = useCallback(async () => {
    let config = {
      method: "get",
      maxBodyLength: Infinity,
      url: `${process.env.REACT_APP_BASE_URL}/controller/GetMMUsers`,
      headers: {
        Authorization: `Bearer ${Cookies.get("_auth")}`,
      },
    };
    const response = await axios(config);
    setMMUserList(response.data.users);
  }, []);

  const fetchFileData = useCallback(async () => {
    let fileData = "";
    let config = {
      method: "get",
      maxBodyLength: Infinity,
      url: `${process.env.REACT_APP_BASE_URL}/Merchant/GetMerchantDocuments/${merchantID}`,
      headers: {
        Authorization: `Bearer ${Cookies.get("_auth")}`,
      },
      data: fileData,
    };
    const response = await axios(config);
    setFileRows(response.data);
  }, []);

  useEffect(() => {
    fetchFileData();
    getCountryData();
  }, [fetchFileData]);

  let data = "";
  let config = {
    method: "get",
    maxBodyLength: Infinity,
    url: `${process.env.REACT_APP_BASE_URL}/Merchant/${merchantID}`,
    headers: {
      Authorization: `Bearer ${Cookies.get("_auth")}`,
    },
    data: data,
  };

  const handleSendPassword = () => {
    let firstData = {
      id: merchantID,
      statusId: "ccd7369f-59c5-4ca1-a31a-e0f034e84095",
    };

    let secondData = {
      id: merchantID,
      statusId: "00cf6a78-ec6c-4c0c-ab8d-931a0c2f8947",
    };

    let firstConfig = {
      method: "put",
      maxBodyLength: Infinity,
      url: `${process.env.REACT_APP_BASE_URL}/Merchant/UpdateMerchantStatus/${merchantID}`,
      headers: {
        Authorization: `Bearer ${Cookies.get("_auth")}`,
        "Content-Type": "application/json",
      },
      data: firstData,
    };
    let secondConfig = {
      method: "put",
      maxBodyLength: Infinity,
      url: `${process.env.REACT_APP_BASE_URL}/Merchant/UpdateMerchantStatus/${merchantID}`,
      headers: {
        Authorization: `Bearer ${Cookies.get("_auth")}`,
        "Content-Type": "application/json",
      },
      data: secondData,
    };

    axios(firstConfig)
      .then(response => {
        axios(secondConfig).then(response => {
          setSuccessMessage(t("passwordSent"));
          fetchMerchantData();
          setSnackbarOpen(true);
          handleClose();
        });
      })
      .catch(error => {
        setError(error);
      });
  };

  // TODO: merchant bilgileri Ã§ekilecek

  // fetch merchant data function

  const fetchMerchantData = useCallback(async () => {
    try {
      setLoading(true);
      const response = await axios(config);
      setMerchant(response.data.merchantInfo);
      setStatus(response.data.merchantInfo.merchantStatus.id);
      setLoading(false);
    } catch (error) {
      setError(true);
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    fetchMerchantData();
    getMerchants();
    getLinksFromAPI();
    getMMUserList();
    getNotesFromAPI();
  }, []);

  const options = [
    {
      id: "e2b9d968-881b-42e2-90f2-223dc64c52fe",
      name: "Perakende",
      title: "Perakende",
    },
    {
      id: "8fa4962a-a67f-423a-b487-5b63cb38bcb8",
      name: "Toptan",
      title: "Toptan",
    },
  ];

  const handleTabChange = (event, newValue) => {
    setSelectedIndex(newValue);
  };

  const merchantOptions = getMerchantMarketPlaceList.map(option => {
    return {
      value: option.id,
      label: option.title,
    };
  });

  const deleteDocument = async id => {
    let status = window.confirm(t("areYouSureYouWantToDeleteIt"));

    let data = {
      id: id,
    };
    if (status) {
      let updateConfig = {
        method: "put",
        maxBodyLength: Infinity,
        url: `${process.env.REACT_APP_BASE_URL}/Merchant/DeleteMerchantDocument/${id}`,
        headers: {
          Authorization: `Bearer ${Cookies.get("_auth")}`,
        },
        data: data,
      };
      const response = await axios(updateConfig);
      console.log(response);
      setSuccessMessage(t("fileDeletedSuccessfully"));
      fetchFileData();
    }
  };

  const handleTestUserSubmit = () => {
    setSubmitLoading(true);
    let data = {
      password: "ff6Ydf99bn",
      isSubMerchant: testUserIsSubmerchant,
      merchantId: testUserSelectedMerchant,
    };

    let config = {
      method: "put",
      maxBodyLength: Infinity,
      url: `${process.env.REACT_APP_BASE_URL}/controller/UpdateTestUser/`,
      headers: {
        Authorization: `Bearer ${Cookies.get("_auth")}`,
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios(config)
      .then(response => {
        setSuccessMessage(t("testUserSuccessfullyUpdated"));
        setSnackbarOpen(true);
        setSubmitLoading(false);
        setSubmitSuccess(true);
      })
      .catch(error => {
        setError(error);
      });
  };

  const handleChangeUser = async event => {
    event.preventDefault();
    // delete cookies manually
    setLoginLoading(true);
    Cookies.remove("_auth");
    Cookies.remove("_auth_state");
    Cookies.remove("_auth_storage");
    Cookies.remove("_auth_type");

    //

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_BASE_URL}/controller/Login`,
        {
          usernameOrEmail: userName,
          password: "ff6Ydf99bn",
        },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      if (
        signIn({
          token: response.data.userInfo.token.accessToken,
          expiresIn: 899,
          tokenType: "Bearer",
          authState: {
            email: userName,
            role: response.data.userInfo.roleId,
            refreshToken: response.data.userInfo.token.refreshToken,
            merchantId: response.data.userInfo.merchantId,
            companyId: response.data.userInfo.companyId,
            nameSurname: response.data.userInfo.nameSurname,
          },
        })
      ) {
        setLoginDialog(true);
        setLoginLoading(false);

        setTimeout(() => {
          navigate("/");
        }, 3000);
      }
    } catch (err) {
      if (err) {
        if (err.response) {
          setError(t("usernameOrPasswordIsIncorrect"));
        }
      } else if (err && err instanceof Error) {
        setError(t("usernameOrPasswordIsIncorrect"));
      } else {
        setError(err.message);
      }
    }
  };

  if (loading)
    return (
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "90vh",
        }}
      >
        <CircularProgress />
      </Box>
    );
  if (error) return <div>Error...</div>;
  return (
    <Box m={"20px"}>
      <Topbar title={t("registrationDetails")} style={{}}>
        <Box>
          <Button
            id="basic-button"
            aria-controls={open ? "basic-menu" : undefined}
            aria-haspopup="true"
            aria-expanded={open ? "true" : undefined}
            onClick={handleClick}
            endIcon={<KeyboardArrowDownIcon />}
            className="filterReset"
            sx={{
              ml: 2,
              color: `${theme.palette.primary.fontColor}!important`,
              textTransform: "capitalize",
              border: `1px solid ${theme.palette.primary.bordersColor}`,
              borderRadius: 0,
              backgroundColor: `${theme.palette.primary.white}!important`,
            }}
          >
            Tüm Aksiyonlar
          </Button>
          <Menu
            id="basic-menu"
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            MenuListProps={{
              "aria-labelledby": "basic-button",
            }}
          >
            <MenuItem
              onClick={handleClickOpenStatus}
              sx={{
                color: `${theme.palette.primary.fontColor}!important`,
                pl: 2,
                textTransform: "capitalize",
                border: "none",
                borderRadius: 0,
              }}
            >
              <ListItemIcon>
                <ContractIcon />
              </ListItemIcon>
              <ListItemText> {t("changeStatus")}</ListItemText>
            </MenuItem>
            <MenuItem
              onClick={handleAggrementModalOpen}
              sx={{
                color: `${theme.palette.primary.fontColor}!important`,
                pl: 2,
                textTransform: "capitalize",
                border: "none",
                borderRadius: 0,
              }}
            >
              <ListItemIcon>
                <ContractIcon />
              </ListItemIcon>
              <ListItemText>{t("editContractDetails")}</ListItemText>
            </MenuItem>
            <MenuItem
              onClick={handleFileInsertOpen}
              sx={{
                color: `${theme.palette.primary.fontColor}!important`,
                pl: 2,
                textTransform: "capitalize",
                border: "none",
                borderRadius: 0,
              }}
            >
              <ListItemIcon>
                <UploadIcon />
              </ListItemIcon>
              <ListItemText>{t("addFile")}</ListItemText>
            </MenuItem>
            <MenuItem
              onClick={handleSubmerchantOpen}
              sx={{
                color: `${theme.palette.primary.fontColor}!important`,
                pl: 2,
                textTransform: "capitalize",
                border: "none",
                borderRadius: 0,
              }}
            >
              <ListItemIcon>
                <SubmerchantIcon />
              </ListItemIcon>
              <ListItemText> {t("makeSubmerchant")}</ListItemText>
            </MenuItem>

            {merchant.merchantStatus.id ===
              "00cf6a78-ec6c-4c0c-ab8d-931a0c2f8947" && (
              <MenuItem
                onClick={handleSendPassword}
                sx={{
                  color: `${theme.palette.primary.fontColor}!important`,
                  pl: 2,
                  textTransform: "capitalize",
                  border: "none",
                  borderRadius: 0,
                }}
              >
                <ListItemIcon>
                  <SendPassWordIcon />
                </ListItemIcon>
                <ListItemText> {t("sendPassword")}</ListItemText>
              </MenuItem>
            )}
            <MenuItem
              onClick={handleLinksOpen}
              sx={{
                color: `${theme.palette.primary.fontColor}!important`,
                pl: 2,
                textTransform: "capitalize",
                border: "none",
                borderRadius: 0,
              }}
            >
              <ListItemIcon>
                <AddLinkIcon />
              </ListItemIcon>
              <ListItemText> {t("addConnection")}</ListItemText>
            </MenuItem>
            <MenuItem
              onClick={openNotesModalHandler}
              sx={{
                color: `${theme.palette.primary.fontColor}!important`,
                pl: 2,
                textTransform: "capitalize",
                border: "none",
                borderRadius: 0,
              }}
            >
              <ListItemIcon>
                <AddNoteIcon />
              </ListItemIcon>
              <ListItemText> {t("addNote")}</ListItemText>
            </MenuItem>
            <MenuItem
              onClick={handleMMUserOpen}
              sx={{
                color: `${theme.palette.primary.fontColor}!important`,
                pl: 2,
                textTransform: "capitalize",
                border: "none",
                borderRadius: 0,
              }}
            >
              <ListItemIcon>
                <AddMMUserIcon />
              </ListItemIcon>
              <ListItemText>{t("addResponsible")}</ListItemText>
            </MenuItem>
            <MenuItem
              onClick={handleMMAccountManagerOpen}
              sx={{
                color: `${theme.palette.primary.fontColor}!important`,
                pl: 2,
                textTransform: "capitalize",
                border: "none",
                borderRadius: 0,
              }}
            >
              <ListItemIcon>
                <AccountManagerIcon />
              </ListItemIcon>
              <ListItemText> {t("addAccountManager")}</ListItemText>
            </MenuItem>
            <MenuItem
              onClick={handleOpenSendMessageDialog}
              sx={{
                color: `${theme.palette.primary.fontColor}!important`,
                pl: 2,
                textTransform: "capitalize",
                border: "none",
                borderRadius: 0,
              }}
            >
              <ListItemIcon>
                <SendMessageIcon />
              </ListItemIcon>
              <ListItemText> {t("sendMessage")}</ListItemText>
            </MenuItem>
            <MenuItem
              onClick={handlePointOpen}
              sx={{
                color: `${theme.palette.primary.fontColor}!important`,
                pl: 2,
                textTransform: "capitalize",
                border: "none",
                borderRadius: 0,
              }}
            >
              <ListItemIcon>
                <PointIconForMenu />
              </ListItemIcon>
              <ListItemText> {t("givePoints")}</ListItemText>
            </MenuItem>
          </Menu>
        </Box>
      </Topbar>
      <Button onClick={handleBack} className="filterApply">
        {t("goBack")}
      </Button>
      {/* sadasd */}
      <Box>
        <Grid
          container
          spacing={0}
          sx={{
            background: theme.palette.primary.white,
            mt: 1,
            paddingLeft: 1,
            paddingRight: 1,
            borderRadius: 2,
          }}
        >
          <Grid item xs={12} sm={12} md={5} sx={{ p: "10px 3px" }}>
            <Item>
              <Typography
                variant="text"
                color="primary"
                sx={{ fontWeight: "bold" }}
              >
                {t("companyInfo")}
              </Typography>
              <Divider
                variant="middle"
                sx={{
                  margin: "10px 0px",
                  borderColor: `rgba(85, 56, 48, 0.09)`,
                }}
              />
              <Box>
                <Grid container>
                  <Grid item sm={12}>
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "flex-start",
                        alignItems: "center",
                        gap: "10px",
                      }}
                    >
                      {merchant.users && merchant.users[0].isSubMerchant ? (
                        merchant.users[0].isSubMerchant === true ? (
                          <Chip
                            sx={{
                              borderRadius: "5px",
                              backgroundColor: "#F7F6F5",
                            }}
                            label={
                              <Typography
                                variant="text"
                                color="text"
                                sx={{
                                  fontWeight: "500",
                                  display: "flex",
                                  alignItems: "center",
                                  gap: "10px",
                                }}
                              >
                                <SubmerchantIcon /> SubMerchant
                              </Typography>
                            }
                          />
                        ) : (
                          ""
                        )
                      ) : null}
                      {merchant.point && merchant.point > 0 ? (
                        <Chip
                          sx={{
                            borderRadius: "5px",
                            backgroundColor: "#FEF7F5",
                          }}
                          label={
                            <Typography
                              variant="text"
                              color="primary"
                              sx={{
                                fontWeight: "500",
                                display: "flex",
                                alignItems: "center",
                                gap: "10px",
                              }}
                            >
                              <PointIcon /> {merchant.point} {t("score")}
                            </Typography>
                          }
                        />
                      ) : null}
                    </Box>
                  </Grid>
                </Grid>
              </Box>
              <Divider
                variant="middle"
                sx={{
                  margin: "10px 0px",
                  borderColor: `rgba(85, 56, 48, 0.09)`,
                }}
              />
              <Box>
                <Grid container>
                  <Grid item sm={6}>
                    <Typography>{t("companyName")}</Typography>
                  </Grid>
                  <Grid item sm={6}>
                    <Typography sx={typoCssProps}>{merchant.title}</Typography>
                  </Grid>
                </Grid>
              </Box>
              <Divider
                variant="middle"
                sx={{
                  margin: "10px 0px",
                  borderColor: `rgba(85, 56, 48, 0.09)`,
                }}
              />
              <Box>
                <Grid container>
                  <Grid item sm={6}>
                    <Typography>{t("website")}</Typography>
                  </Grid>
                  <Grid item sm={6}>
                    <Typography sx={typoCssProps}>
                      {merchant.webAddress}
                    </Typography>
                  </Grid>
                </Grid>
              </Box>
              <Divider
                variant="middle"
                sx={{
                  margin: "10px 0px",
                  borderColor: `rgba(85, 56, 48, 0.09)`,
                }}
              />
              <Box>
                <Grid container>
                  <Grid item sm={6}>
                    <Typography>{t("theCategory")}</Typography>
                  </Grid>
                  <Grid item sm={6}>
                    <Typography sx={typoCssProps}>
                      {merchant.merchantCategories.map((item, index) => (
                        <span key={index}>
                          {index === merchant.merchantCategories.length - 1
                            ? item.title
                            : item.title + ", "}
                        </span>
                      ))}
                    </Typography>
                  </Grid>
                </Grid>
              </Box>
              <Divider
                variant="middle"
                sx={{
                  margin: "10px 0px",
                  borderColor: `rgba(85, 56, 48, 0.09)`,
                }}
              />
              <Box>
                <Grid container>
                  <Grid item sm={6}>
                    <Typography> {t("yourCompany")}</Typography>
                  </Grid>
                  <Grid item sm={6}>
                    <Typography sx={typoCssProps}>
                      {merchant.merchantType.title &&
                        merchant.merchantType.title}
                    </Typography>
                  </Grid>
                </Grid>
              </Box>
              <Divider
                variant="middle"
                sx={{
                  margin: "10px 0px",
                  borderColor: `rgba(85, 56, 48, 0.09)`,
                }}
              />
              <Box>
                <Grid container sx={{ pb: 1 }}>
                  <Grid item sm={6}>
                    <Typography>{t("yourBrandOwnership")}</Typography>
                  </Grid>
                  <Grid item sm={6}>
                    <Typography sx={typoCssProps}>
                      {merchant.merchantOwnerOfBrand.title &&
                        merchant.merchantOwnerOfBrand.title}
                    </Typography>
                  </Grid>
                </Grid>
              </Box>
            </Item>
            <Item style={{ marginTop: "10px" }}>
              <Typography
                variant="text"
                color="primary"
                sx={{ fontWeight: "bold" }}
              >
                {t("registrationStatus")}
              </Typography>
              <Divider
                variant="middle"
                sx={{
                  margin: "10px 0px",
                  borderColor: `rgba(85, 56, 48, 0.09)`,
                }}
              />
              <Box>
                <Grid container>
                  <Grid item sm={6}>
                    <Typography>{t("status")}</Typography>
                  </Grid>
                  <Grid item sm={6} sx={{ pb: 1 }}>
                    <Chip
                      sx={{ borderRadius: "5px", color: "#fff" }}
                      size="small"
                      label={merchant.merchantStatus.title}
                      color={
                        merchant &&
                        merchant.merchantStatus.id ===
                          "00be09db-05e4-4b98-b1d4-3b81d40be94d"
                          ? "success"
                          : "error"
                      }
                    />
                  </Grid>
                </Grid>
              </Box>
            </Item>
            <Item style={{ marginTop: "10px" }}>
              <Typography
                variant="text"
                color="primary"
                sx={{ fontWeight: "bold" }}
              >
                {t("contactInformation")}
              </Typography>
              <Divider
                variant="middle"
                sx={{
                  margin: "10px 0px",
                  borderColor: `rgba(85, 56, 48, 0.09)`,
                }}
              />
              <Box>
                <Grid container>
                  <Grid item sm={6}>
                    <Typography>{t("nameSurname")}</Typography>
                  </Grid>
                  <Grid item sm={6}>
                    <Typography sx={typoCssProps}>
                      {merchant.users[0].nameSurname}
                    </Typography>
                  </Grid>
                </Grid>
              </Box>
              <Divider
                variant="middle"
                sx={{
                  margin: "10px 0px",
                  borderColor: `rgba(85, 56, 48, 0.09)`,
                }}
              />
              <Box>
                <Grid container>
                  <Grid item sm={6}>
                    <Typography>{t("phone")}</Typography>
                  </Grid>
                  <Grid item sm={6}>
                    <Typography sx={typoCssProps}>
                      {merchant.phoneNumber}
                    </Typography>
                  </Grid>
                </Grid>
              </Box>
              <Divider
                variant="middle"
                sx={{
                  margin: "10px 0px",
                  borderColor: `rgba(85, 56, 48, 0.09)`,
                }}
              />
              <Box>
                <Grid container>
                  <Grid item sm={6}>
                    <Typography>{t("email")}</Typography>
                  </Grid>
                  <Grid item sm={6} sx={{ pb: 1 }}>
                    <Typography sx={typoCssProps}>
                      {merchant.users[0].email}
                    </Typography>
                  </Grid>
                </Grid>
              </Box>
            </Item>
            <Item style={{ marginTop: "10px" }}>
              <Typography
                variant="text"
                color="primary"
                sx={{ fontWeight: "bold" }}
              >
                {t("details")}
              </Typography>
              <Divider
                variant="middle"
                sx={{
                  margin: "10px 0px",
                  borderColor: `rgba(85, 56, 48, 0.09)`,
                }}
              />
              <Box>
                <Grid container>
                  <Grid item sm={6}>
                    <Typography>{t("originOfProducts")}</Typography>
                  </Grid>
                  <Grid item sm={6}>
                    <Typography sx={typoCssProps}>
                      {merchant.productOrigins &&
                        merchant.productOrigins.map((item, index) => (
                          <span key={index}>
                            {index === merchant.productOrigins.length - 1
                              ? item.country.title
                              : item.country.title + ", "}
                          </span>
                        ))}
                    </Typography>
                  </Grid>
                </Grid>
              </Box>
              <Divider
                variant="middle"
                sx={{
                  margin: "10px 0px",
                  borderColor: `rgba(85, 56, 48, 0.09)`,
                }}
              />
              <Box>
                <Grid container>
                  <Grid item sm={6}>
                    <Typography>{t("numberOfProducts")}</Typography>
                  </Grid>
                  <Grid item sm={6}>
                    <Typography sx={typoCssProps}>
                      {merchant.merchantProductCount &&
                        merchant.merchantProductCount.title}
                    </Typography>
                  </Grid>
                </Grid>
              </Box>
              <Divider
                variant="middle"
                sx={{
                  margin: "10px 0px",
                  borderColor: `rgba(85, 56, 48, 0.09)`,
                }}
              />
              <Box>
                <Grid container>
                  <Grid item sm={6}>
                    <Typography>{t("doYouDoECommerce")}</Typography>
                  </Grid>
                  <Grid item sm={6}>
                    <Typography sx={typoCssProps}>
                      {merchant.isEcommerce === true ? "Evet" : "Hayır"}
                    </Typography>
                  </Grid>
                </Grid>
              </Box>

              {merchant.isEcommerce === true ? (
                <Box>
                  {" "}
                  <Divider
                    variant="middle"
                    sx={{
                      margin: "10px 0px",
                      borderColor: `rgba(85, 56, 48, 0.09)`,
                    }}
                  />
                  <Box>
                    <Grid container style={{ lineBreak: "anywhere!important" }}>
                      <Grid item sm={6}>
                        <Typography noWrap>{t("eCommerceStoreLink")}</Typography>
                      </Grid>
                      <Grid item sm={6}>
                        {merchant.merchnantEcommerceUrls.map((item, index) => (
                          <Typography
                            sx={{
                              width: "320px",
                              textOverflow: "ellipsis",
                              whiteSpace: "nowrap",
                              overflow: "hidden",
                            }}
                          >
                            <a
                              href={`${item.title}`}
                              target="_blank"
                              key={index}
                              style={{
                                textDecoration: "none",
                                color: `${theme.palette.primary.main}
                              `,
                              }}
                            >
                              {
                                item.title
                                  .replace("www.", "")
                                  .replace(/(http(s)?:\/\/)?(www\.)?/g, "")
                                  .split("/")[0]
                                  .split(".")[0]
                              }
                            </a>
                          </Typography>
                        ))}
                      </Grid>
                    </Grid>
                  </Box>
                </Box>
              ) : null}
              <Divider
                variant="middle"
                sx={{
                  margin: "10px 0px",
                  borderColor: `rgba(85, 56, 48, 0.09)`,
                }}
              />
              <Box>
                <Grid container>
                  <Grid item sm={6}>
                    <Typography>{t("doYouExport")}</Typography>
                  </Grid>
                  <Grid item sm={6}>
                    <Typography sx={typoCssProps}>
                      {merchant.isExport === true ? "Evet" : "Hayır"}
                    </Typography>
                  </Grid>
                </Grid>
              </Box>
              <Divider
                variant="middle"
                sx={{
                  margin: "10px 0px",
                  borderColor: `rgba(85, 56, 48, 0.09)`,
                }}
              />
              <Box>
                <Grid container>
                  <Grid item sm={6}>
                    <Typography>{t("integrationUsage")}</Typography>
                  </Grid>
                  <Grid item sm={6} sx={{ pb: 1 }}>
                    <Typography sx={typoCssProps}>
                      {merchant.isIntegration === true ? "Evet" : "Hayır"}
                    </Typography>
                  </Grid>
                </Grid>
              </Box>
            </Item>
            {/* Sozlesme detaylari */}
            <Item style={{ marginTop: "10px" }}>
              <Typography
                variant="text"
                color="primary"
                sx={{ fontWeight: "bold" }}
              >
                {t("contractDetails")}
              </Typography>
              <Divider
                variant="middle"
                sx={{
                  margin: "10px 0px",
                  marginBottom: "20px",
                  borderColor: `rgba(85, 56, 48, 0.09)`,
                }}
              />
              <Typography color="primary" sx={{ fontSize: "16px" }}>
                {t("businessModel")}
              </Typography>

              <Divider
                variant="middle"
                sx={{
                  margin: "10px 0px",
                  borderColor: `rgba(85, 56, 48, 0.09)`,
                }}
              />
              <Box>
                <Grid container>
                  <Grid item sm={6}>
                    <Typography>{t("businessModel")}</Typography>
                  </Grid>
                  <Grid item sm={6}>
                    {merchant.merchantBusinessTypeId ? (
                      <Typography sx={typoCssProps}>
                        {merchant.merchantBusinessType.id &&
                          merchant.merchantBusinessType.title}{" "}
                        <span>
                          {merchant.merchantBusinessType.id ===
                          "3552203d-03a5-4d39-92b8-410d3e581b40"
                            ? merchant.merchantMarketPlaces.map(
                                (item, index) => (
                                  <span key={index}>
                                    (
                                    {index ===
                                    merchant.merchantMarketPlaces.length - 1
                                      ? item.title
                                      : item.title}
                                    )
                                  </span>
                                )
                              )
                            : ""}
                        </span>
                      </Typography>
                    ) : (
                      ""
                    )}
                  </Grid>
                </Grid>
              </Box>
              <Divider
                variant="middle"
                sx={{
                  margin: "10px 0px",
                  borderColor: `rgba(85, 56, 48, 0.09)`,
                }}
              />
              <Box>
                <Grid container>
                  <Grid item sm={6}>
                    <Typography>{t("exporter")}</Typography>
                  </Grid>
                  <Grid item sm={6}>
                    {merchant.merchantExporter ? (
                      <Typography sx={typoCssProps}>
                        {merchant.merchantExporter.id &&
                          merchant.merchantExporter.title}
                      </Typography>
                    ) : (
                      ""
                    )}
                  </Grid>
                </Grid>
              </Box>
              <Divider
                variant="middle"
                sx={{
                  margin: "10px 0px",
                  borderColor: `rgba(85, 56, 48, 0.09)`,
                }}
              />
              <Box>
                <Grid container>
                  <Grid item sm={6}>
                    <Typography>{t("platforms")}</Typography>
                  </Grid>
                  <Grid item sm={6}>
                    <Typography sx={typoCssProps}>
                      {merchant.platforms.length > 0 &&
                        merchant.platforms.map((item, index) => (
                          <span key={index}>
                            {index === merchant.platforms.length - 1
                              ? item.title
                              : item.title + ", "}
                          </span>
                        ))}
                    </Typography>
                  </Grid>
                </Grid>
              </Box>
              <Divider
                variant="middle"
                sx={{
                  margin: "10px 0px",
                  borderColor: `rgba(85, 56, 48, 0.09)`,
                }}
              />
              <Box>
                <Grid container>
                  <Grid item sm={6}>
                    <Typography>{t("countries")}</Typography>
                  </Grid>
                  <Grid item sm={6}>
                    <Typography sx={typoCssProps}>
                      {merchant.countries.length > 0 &&
                        merchant.countries.map((item, index) => (
                          <span key={index}>
                            {index === merchant.countries.length - 1
                              ? item.title
                              : item.title + ", "}
                          </span>
                        ))}
                    </Typography>
                  </Grid>
                </Grid>
              </Box>
              {/* ... */}
              <Divider
                variant="middle"
                sx={{
                  margin: "10px 0px",
                  marginBottom: "20px",
                  borderColor: `rgba(85, 56, 48, 0.09)`,
                }}
              />
              <Typography color="primary" sx={{ fontSize: "16px" }}>
               {t("financialDetails")}
              </Typography>
              <Divider
                variant="middle"
                sx={{
                  margin: "10px 0px",
                  borderColor: `rgba(85, 56, 48, 0.09)`,
                }}
              />
              <Box>
                <Grid container>
                  <Grid item sm={6}>
                    <Typography>{t("advertisingBudget")}</Typography>
                  </Grid>
                  <Grid item sm={6}>
                    {merchant.annualServiceFee ? (
                      <Typography sx={typoCssProps}>
                        {merchant.annualServiceFee && merchant.annualServiceFee}{" "}
                        {merchant.currency && merchant.currency.title}
                      </Typography>
                    ) : (
                      ""
                    )}
                  </Grid>
                </Grid>
              </Box>
              <Divider
                variant="middle"
                sx={{
                  margin: "10px 0px",
                  borderColor: `rgba(85, 56, 48, 0.09)`,
                }}
              />
              <Box>
                <Grid container>
                  <Grid item sm={6}>
                    <Typography>{t("salesCommission")}</Typography>
                  </Grid>
                  <Grid item sm={6}>
                    <Typography sx={typoCssProps}>
                      {/* %{merchant.salesCommission && merchant.salesCommission} */}
                      {merchant.salesCommission ? (
                        <Typography sx={typoCssProps}>
                          %
                          {merchant.salesCommission && merchant.salesCommission}{" "}
                          {merchant.currency && merchant.currency.name}
                        </Typography>
                      ) : (
                        ""
                      )}
                    </Typography>
                  </Grid>
                </Grid>
              </Box>
              <Divider
                variant="middle"
                sx={{
                  margin: "10px 0px",
                  borderColor: `rgba(85, 56, 48, 0.09)`,
                }}
              />
              <Box>
                <Grid container>
                  <Grid item sm={6}>
                    <Typography>{t("advertisingCommission")}</Typography>
                  </Grid>
                  <Grid item sm={6}>
                    <Typography sx={typoCssProps}>
                      {/* %
                      {merchant.advertisingCommission &&
                        merchant.advertisingCommission} */}
                      {merchant.advertisingCommission ? (
                        <Typography sx={typoCssProps}>
                          %
                          {merchant.advertisingCommission &&
                            merchant.advertisingCommission}
                        </Typography>
                      ) : (
                        ""
                      )}
                    </Typography>
                  </Grid>
                </Grid>
              </Box>
              <Divider
                variant="middle"
                sx={{
                  margin: "10px 0px",
                  borderColor: `rgba(85, 56, 48, 0.09)`,
                }}
              />
              <Box>
                <Grid container>
                  <Grid item sm={6}>
                    <Typography>{t("incentiveCommission")}</Typography>
                  </Grid>
                  <Grid item sm={6}>
                    <Typography sx={typoCssProps}>
                      {/* %
                      {merchant.incentiveCommission &&
                        merchant.incentiveCommission} */}
                      {merchant.incentiveCommission ? (
                        <Typography sx={typoCssProps}>
                          %
                          {merchant.incentiveCommission &&
                            merchant.incentiveCommission}
                        </Typography>
                      ) : (
                        ""
                      )}
                    </Typography>
                  </Grid>
                </Grid>
              </Box>
              <Divider
                variant="middle"
                sx={{
                  margin: "10px 0px",
                  borderColor: `rgba(85, 56, 48, 0.09)`,
                }}
              />
              <Box>
                <Grid container>
                  <Grid item sm={6}>
                    <Typography>{t("maturity")}</Typography>
                  </Grid>
                  <Grid item sm={6}>
                    <Typography sx={typoCssProps}>
                      {/* {merchant.paymentTerm && merchant.paymentTerm} GÃ¼n
                       */}
                      {merchant.paymentTerm ? (
                        <Typography sx={typoCssProps}>
                          {merchant.paymentTerm && merchant.paymentTerm} Gün
                        </Typography>
                      ) : (
                        ""
                      )}
                    </Typography>
                  </Grid>
                </Grid>
              </Box>
              <Divider
                variant="middle"
                sx={{
                  margin: "10px 0px",
                  borderColor: `rgba(85, 56, 48, 0.09)`,
                }}
              />
              <Box>
                <Grid container>
                  <Grid item sm={6}>
                    <Typography>{t("paymentMethod")}</Typography>
                  </Grid>
                  <Grid item sm={6} sx={{ pb: 1 }}>
                    {merchant.paymentMethod ? (
                      <Typography sx={typoCssProps}>
                        {merchant.paymentMethod.title &&
                          merchant.paymentMethod.title}
                      </Typography>
                    ) : (
                      ""
                    )}
                  </Grid>
                </Grid>
              </Box>
            </Item>
          </Grid>
          <Grid item xs={12} sm={12} md={7} sx={{ p: "10px 3px" }}>
            <Item>
              <Typography
                variant="text"
                color="primary"
                sx={{ fontWeight: "bold" }}
              >
                {t("attachments")}
              </Typography>
              <Box>
                <Box>
                  <Tabs
                    value={selectedIndex}
                    onChange={handleTabChange}
                    aria-label="basic tabs example"
                    className="merchantDetailTabContainer"
                    sx={{
                      mt: 1,
                    }}
                  >
                    <Tab
                      sx={{
                        textTransform: "capitalize",
                        color: theme.palette.text.primary,
                        border: "none",
                        border: "1px solid #45383426",
                        pt: 2,
                      }}
                      className="merchantDetailTab"
                      label={t("files")}
                      {...a11yProps(0)}
                      icon={
                        fileRows.length > 0 ? (
                          <Box
                            sx={{
                              width: "10px",
                              height: "10px",
                              borderRadius: "50%",
                              backgroundColor: "#EB603A",
                            }}
                          ></Box>
                        ) : (
                          ""
                        )
                      }
                      iconPosition="start"
                      iconSize="small"
                    ></Tab>
                    <Tab
                      sx={{
                        textTransform: "capitalize",
                        color: theme.palette.text.primary,
                        border: "1px solid #45383426",
                        borderLeft: "none",
                        pt: 2,
                      }}
                      className="merchantDetailTab"
                      label={t("links")}
                      icon={
                        links.length > 0 ? (
                          <Box
                            sx={{
                              width: "10px",
                              height: "10px",
                              borderRadius: "50%",
                              backgroundColor: "#EB603A",
                            }}
                          ></Box>
                        ) : (
                          ""
                        )
                      }
                      {...a11yProps(1)}
                      iconPosition="start"
                      iconSize="small"
                    >
                      {" "}
                    </Tab>
                    <Tab
                      sx={{
                        textTransform: "capitalize",
                        color: theme.palette.text.primary,
                        border: "1px solid #45383426",
                        borderLeft: "none",
                        pt: 2,
                      }}
                      className="merchantDetailTab"
                      label={t("notes")}
                      {...a11yProps(2)}
                    />
                    <Tab
                      sx={{
                        textTransform: "capitalize",
                        color: theme.palette.text.primary,
                        border: "1px solid #45383426",
                        borderLeft: "none",
                        pt: 2,
                      }}
                      className="merchantDetailTab"
                      label={t("messages")}
                      icon={
                        messages && messages.length > 0 ? (
                          <Box
                            sx={{
                              width: "10px",
                              height: "10px",
                              borderRadius: "50%",
                              backgroundColor: "#EB603A",
                            }}
                          ></Box>
                        ) : (
                          ""
                        )
                      }
                      iconPosition="start"
                      {...a11yProps(3)}
                    />

                    <Tab
                      sx={{
                        textTransform: "capitalize",
                        color: theme.palette.text.primary,
                        border: "1px solid #45383426",
                        borderLeft: "none",
                        pt: 2,
                      }}
                      className="merchantDetailTab"
                      label={t("brands")}
                      icon={
                        merchantBrands.length > 0 ? (
                          <Box
                            sx={{
                              width: "10px",
                              height: "10px",
                              borderRadius: "50%",
                              backgroundColor: "#EB603A",
                            }}
                          ></Box>
                        ) : (
                          ""
                        )
                      }
                      {...a11yProps(4)}
                      iconPosition="start"
                      iconSize="small"
                    >
                      {" "}
                    </Tab>
                  </Tabs>
                </Box>
                <CustomTabPanel value={selectedIndex} index={0}>
                  <TableContainer
                    sx={{
                      boxShadow: "none!important",
                      borderBottom: "none!important",
                      borderRadius: "0!important",
                      paddingBottom: "10px",
                    }}
                    component={Paper}
                  >
                    <Table sx={{ minWidth: 650 }} aria-label="simple table">
                      <TableHead sx={{ border: "1px solid #e0e0e0" }}>
                        <TableRow>
                          <TableCell align="left">{t("fileName")}</TableCell>
                          {/* <TableCell align="left">YÃ¼klenme Tarihi</TableCell> */}
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {/* TODO: map edilecek */}
                        {fileRows.map(row => (
                          <TableRow className="stock-table" key={row.id}>
                            <TableCell
                              sx={{
                                paddingTop: "5px!important",
                                paddingBottom: "5px!important",
                              }}
                              width="70%"
                              align="left"
                            >
                              <Typography>
                                <a
                                  href={row.path + "/" + row.fileName}
                                  target="_blank"
                                  style={{
                                    color: theme.palette.secondary.main,
                                    textDecoration: "none",
                                    fontWeight: "500",
                                  }}
                                >
                                  {row.fileOrginalName}
                                </a>
                              </Typography>
                            </TableCell>
                            {/* <TableCell
                          sx={{
                            paddingTop: "5px!important",
                            paddingBottom: "5px!important",
                          }}
                          width="20%"
                          align="left"
                        >
                          Date bekleniyor
                        </TableCell>
*/}
                            <TableCell
                              sx={{
                                paddingTop: "5px!important",
                                paddingBottom: "5px!important",
                              }}
                              width="5%"
                              align="center"
                            >
                              <IconButton
                                onClick={() => deleteDocument(row.id)}
                                aria-label="delete"
                                size="small"
                              >
                                <DeleteIcon />
                              </IconButton>
                            </TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </CustomTabPanel>
                <CustomTabPanel value={selectedIndex} index={1}>
                  <TableContainer
                    sx={{
                      boxShadow: "none!important",
                      borderBottom: "none!important",
                      borderRadius: "0!important",
                      paddingBottom: "10px",
                    }}
                    component={Paper}
                  >
                    <Table sx={{ minWidth: 650 }} aria-label="simple table">
                      <TableHead sx={{ border: "1px solid #e0e0e0" }}>
                        <TableRow>
                          <TableCell align="left">{t("linkName")}</TableCell>
                          {/* delete  baglanti*/}
                          <TableCell align="center">{t("delete")}</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {/* TODO: map edilecek */}
                        {links.map(row => (
                          <TableRow className="stock-table" key={row.id}>
                            <TableCell
                              sx={{
                                paddingTop: "5px!important",
                                paddingBottom: "5px!important",
                              }}
                              width="70%"
                              align="left"
                            >
                              <Typography>
                                <a
                                  href={row.path}
                                  target="_blank"
                                  style={{
                                    color: theme.palette.secondary.main,
                                    textDecoration: "none",
                                    fontWeight: "500",
                                  }}
                                >
                                  {row.title}
                                </a>
                              </Typography>
                            </TableCell>
                            <TableCell
                              sx={{
                                paddingTop: "5px!important",
                                paddingBottom: "5px!important",
                              }}
                              width="5%"
                              align="center"
                            >
                              <IconButton
                                onClick={() => confirmDeleteLink(row.id)}
                                aria-label="delete"
                                size="small"
                              >
                                <DeleteIcon />
                              </IconButton>
                            </TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </CustomTabPanel>
                <CustomTabPanel value={selectedIndex} index={2}>
                  <DataGrid
                    className="dataGrid-note"
                    sortable={false}
                    columns={noteColumns}
                    rows={notes}
                    getRowHeight={() => "auto"}
                    getEstimatedRowHeight={() => 100}
                    pageSize={5}
                    rowsPerPageOptions={[5]}
                    sx={{
                      borderColor: theme.palette.primary.bordersColor,
                      height: "100%",
                      "& .Mui-selected": {
                        background: `${theme.palette.primary.tableEvenRows}!important`,
                      },
                      "& .MuiDataGrid-row:hover": {
                        background: `${theme.palette.primary.tableEvenRows}!important`,
                      },
                      "& .MuiDataGrid-cell:focus": {
                        outline: `transparent!important`,
                      },
                      "& .MuiDataGrid-cell:focus-within": {
                        outline: `transparent!important`,
                      },
                      "& .MuiDataGrid-columnHeader:focus": {
                        outline: `transparent!important`,
                      },
                      "& .MuiDataGrid-columnHeader:focus-within": {
                        outline: `transparent!important`,
                      },
                      "&.MuiDataGrid-root--densityCompact .MuiDataGrid-cell": {
                        py: 1,
                      },
                      "&.MuiDataGrid-root--densityStandard .MuiDataGrid-cell": {
                        py: "15px",
                      },
                      "&.MuiDataGrid-root--densityComfortable .MuiDataGrid-cell":
                        {
                          py: "22px",
                        },
                    }}
                    autoHeight
                    showCellRightBorder={true}
                    disableSelectionOnClick
                    rowHeight={45}
                  />
                </CustomTabPanel>
                <CustomTabPanel value={selectedIndex} index={3}>
                  <TableContainer
                    sx={{
                      boxShadow: "none!important",
                      borderBottom: "none!important",
                      borderRadius: "0!important",
                      paddingBottom: "10px",
                    }}
                    component={Paper}
                  >
                    <Table sx={{ minWidth: 650 }} aria-label="simple table">
                      <TableHead sx={{ border: "1px solid #e0e0e0" }}>
                        <TableRow>
                          <TableCell align="left">{t("messages")}</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {/* TODO: map edilecek */}
                        {messages &&
                          messages.map(row => (
                            <TableRow className="stock-table" key={row.id}>
                              <TableCell
                                sx={{
                                  paddingTop: "5px!important",
                                  paddingBottom: "5px!important",
                                }}
                                width="100%"
                                align="left"
                              >
                                <Box>
                                  <Box
                                    sx={{
                                      display: "flex",
                                      flexDirection: "row",
                                      justifyContent: "space-between",
                                      alignItems: "center",
                                      paddingBottom: "10px",
                                      paddingTop: "10px",
                                    }}
                                  >
                                    <Box
                                      sx={{
                                        display: "flex",
                                        flexDirection: "row",
                                        alignItems: "center",
                                      }}
                                    >
                                      <Avatar
                                        src={`${row.avatar}`}
                                        sx={
                                          row.createdUser.roleName ===
                                          "MM Admin"
                                            ? {
                                                backgroundColor: "#EB603A1e",
                                                color: "#EB603A",
                                                width: 36,
                                                height: 36,
                                              }
                                            : {
                                                backgroundColor: "#486ABD1e",
                                                color: "#486ABD",
                                                width: 36,
                                                height: 36,
                                              }
                                        }
                                      >
                                        <Typography
                                          sx={{
                                            fontSize: "1.3rem",
                                          }}
                                        >
                                          {row.createdUser.nameSurname[0].toUpperCase()}
                                        </Typography>
                                      </Avatar>
                                      <Typography
                                        sx={{
                                          ml: 1,
                                          display: "inline-block",
                                          fontWeight: "500",
                                          fontSize: "14px",
                                        }}
                                      >
                                        {row.createdUser.nameSurname}
                                      </Typography>
                                      <Typography
                                        sx={{
                                          display: "inline-block",
                                          fontStyle: "italic",
                                          fontSize: "14px",
                                          ml: 1,
                                          mr: 1,
                                          color: `#453834 `,
                                          opacity: "0.6",
                                        }}
                                      >
                                        -{" "}
                                        {row.createdUser.roleName === "MM Admin"
                                          ? "MegaMerchant"
                                          : merchant && merchant.title}
                                      </Typography>
                                    </Box>
                                    <Typography>
                                      {row.createdAt
                                        ? new Date(
                                            row.createdAt
                                          ).toLocaleString("tr-TR")
                                        : ""}
                                    </Typography>
                                  </Box>
                                  <Typography>{row.messageBody}</Typography>
                                  <Box
                                    sx={{
                                      display: "flex",
                                      flexDirection: "row",
                                      justifyContent: "flex-start",
                                      gap: "10px",
                                      alignItems: "center",
                                      paddingTop: "20px",
                                      paddingBottom: "10px",
                                    }}
                                  >
                                    {row.attachments !== null &&
                                    row.attachments !== undefined
                                      ? row.attachments.map((item, index) => (
                                          <Box
                                            sx={{
                                              display: "flex",
                                              flexDirection: "row",
                                              alignItems: "center",
                                              border: "1px solid #45383426",
                                              padding: "10px 5px",
                                              marginBottom: "10px",
                                              borderRadius: "4px",
                                              width: "180px",
                                              gap: "5px",
                                              justifyContent: "center",
                                            }}
                                            key={index}
                                          >
                                            <MessageFIleIcon />
                                            <a
                                              href={`https://mmfilestorageaccount.blob.core.windows.net/${item.path}/${item.title}`}
                                              target="_blank"
                                              rel="noopener noreferrer"
                                              style={{
                                                color:
                                                  theme.palette.secondary.main,
                                                textDecoration: "none",
                                                fontWeight: "500",
                                                cursor: "pointer",
                                              }}
                                            >
                                              {item.fileOrginalName}
                                            </a>
                                          </Box>
                                        ))
                                      : null}
                                  </Box>
                                </Box>
                              </TableCell>
                            </TableRow>
                          ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </CustomTabPanel>
                <CustomTabPanel value={selectedIndex} index={4}>
                  <TableContainer
                    sx={{
                      boxShadow: "none!important",
                      borderBottom: "none!important",
                      borderRadius: "0!important",
                      paddingBottom: "10px",
                    }}
                    component={Paper}
                  >
                    <Table sx={{ minWidth: 650 }} aria-label="simple table">
                      <TableHead sx={{ border: "1px solid #e0e0e0" }}>
                        <TableRow>
                          <TableCell align="left">{t("brand")}</TableCell>
                          {/* <TableCell align="left">YÃ¼klenme Tarihi</TableCell> */}
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {/* TODO: map edilecek */}
                        {merchantBrands.map(row => (
                          <TableRow className="stock-table" key={row.id}>
                            <TableCell
                              sx={{
                                paddingTop: "5px!important",
                                paddingBottom: "5px!important",
                              }}
                              width="70%"
                              align="left"
                            >
                              <Typography>{row.title}</Typography>
                            </TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </CustomTabPanel>
              </Box>
            </Item>
          </Grid>
        </Grid>
      </Box>
      <Box className="statusDialog">
        <ChangeStatusDialog
          onClose={handleClickCloseStatus}
          aria-labelledby="customized-dialog-title"
          open={openStatus}
        >
          <DialogTitle
            sx={{ m: 0, p: 2, color: theme.palette.primary.main }}
            id="customized-dialog-title"
          >
            {t("changeStatus")}
          </DialogTitle>
          <IconButton
            aria-label="close"
            onClick={handleClickCloseStatus}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: theme => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
          <DialogContent dividers>
            <TableContainer
              sx={{
                boxShadow: "none!important",
                borderBottom: "none!important",
                borderRadius: "0!important",
                paddingBottom: "10px",
              }}
              component={Paper}
            >
              <Table sx={{ minWidth: 450 }} aria-label="simple table">
                <TableHead sx={{ border: "1px solid #e0e0e0" }}>
                  <TableRow>
                    <TableCell align="left">{t("companyName")}</TableCell>
                    <TableCell align="left">{t("status")}</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow className="stock-table">
                    <TableCell align="left" sx={{ width: "225px" }}>
                      {merchant.title}
                    </TableCell>
                    <TableCell align="left" sx={{ padding: "1px" }}>
                      <FormControl sx={{ m: 1, width: "225px" }} size="small">
                        <MuiSelect
                          id="demo-select-small"
                          value={status}
                          onChange={handleChangeStatus}
                        >
                          <MenuItem value="00cf6a78-ec6c-4c0c-ab8d-931a0c2f8947">
                            {t("waitingForDocument")}
                          </MenuItem>
                          <MenuItem value="ccd7369f-59c5-4ca1-a31a-e0f034e84095">
                            {t("waitingForCall")}
                          </MenuItem>
                          <MenuItem value="f91813a0-ed69-4d5d-9eb4-6584c492767c">
                            {t("duringTheContractProcess")}
                          </MenuItem>
                          <MenuItem value="d0ea0878-17a3-46fc-ae32-92f9b267e37e">
                            {t("notReachable")}
                          </MenuItem>
                          <MenuItem value=" c3c5a1d3-1b1c-49ae-8e14-aef577d224f7">
                            {t("transferredToPropars")}
                          </MenuItem>
                          <MenuItem value="00be09db-05e4-4b98-b1d4-3b81d40be94d">
                            {t("approved")}
                          </MenuItem>
                          <MenuItem value="8939351a-3d65-4167-96ca-0751cf2bcca9">
                            {t("onSale")}
                          </MenuItem>
                          <MenuItem value="03943ab7-f01d-4135-b488-d58f9f7d066e">
                            {t("onHold")}
                          </MenuItem>
                          <MenuItem value="4e495a9c-36a0-42b0-95f1-b1ee4038b7f4">
                            {t("banned")}
                          </MenuItem>
                        </MuiSelect>
                      </FormControl>
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
          </DialogContent>
          <DialogActions
            sx={{ display: "flex", justifyContent: "space-between" }}
          >
            <Button className="filterReset" onClick={handleClickCloseStatus}>
              {t("close")}
            </Button>
            <Button
              className="filterApply"
              autoFocus
              onClick={changeStatusFromAPI}
            >
              {t("save")}
            </Button>
          </DialogActions>
        </ChangeStatusDialog>
      </Box>
      <Box className="statusDialog">
        <ChangeStatusDialog
          onClose={handleClickCloseStatus}
          aria-labelledby="customized-dialog-title"
          open={loginDialog}
        >
          <DialogContent dividers>
            <Box
              sx={{
                width: "480px",
                paddingX: "10px",
                paddingY: "50px",
              }}
            >
              <Alert severity="success">
                <AlertTitle> {t("pleaseWait")}</AlertTitle>
                {t("loggingInToMerchantAccount")}...
              </Alert>
              <LinearProgress
                sx={{
                  marginTop: "20px",
                  width: "100%",
                }}
                color="success"
              />
            </Box>
          </DialogContent>
        </ChangeStatusDialog>
      </Box>
      <Box className="aggrementDialog">
        <Dialog
          onClose={handleAggrementModalClose}
          aria-labelledby="customized-dialog-title"
          open={aggrementModal}
          maxWidth="sm"
          fullWidth={true}
          scroll="body"
        >
          <Grid container spacing={2}>
            <Grid item xs={12} sm={12}>
              <Box>
                <DialogTitle
                  sx={{
                    m: 0,
                    p: 2,
                    color: theme.palette.primary.main,
                    ml: 1,
                    mr: 1,
                    pb: 0.5,
                  }}
                  id="customized-dialog-title"
                >
                  {t("contractDetails")}
                </DialogTitle>
              </Box>
              <Divider />
              <Box sx={{ p: 2, ml: 1, mr: 1, pt: 1, pb: 0 }}>
                <Typography>
                  {t("determineTheWorkingModelAndFinancialDetailsAgreedWithYourBusinessPartner")}
                </Typography>
              </Box>
            </Grid>
          </Grid>
          <IconButton
            aria-label="close"
            onClick={handleAggrementModalClose}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: theme => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
          <DialogContent sx={{ paddingTop: "5px" }}>
            <Box
              component="form"
              sx={{
                backgroundColor: "#fff",
              }}
              noValidate
              autoComplete="off"
            >
              <Box>
                <FormGroup style={{ marginBottom: "10px" }}>
                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={12}>
                      <BoxTitle title="İş Modeli" />
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      sm={12}
                      className="no-padding"
                      sx={{ mb: 1 }}
                    >
                      <FormControl variant="standard" key="ourBrand">
                        <CustomLabel title="Firmanız" />
                        <RadioGroup
                          sx={{ pt: "35px", ml: "10px" }}
                          row
                          aria-labelledby="demo-row-radio-buttons-group-label"
                          name="row-radio-buttons-group"
                          value={merchantBusinessTypeId}
                          onChange={e =>
                            setMerchantBusinessTypeId(e.target.value)
                          }
                        >
                          <FormControlLabel
                            color="success"
                            className={
                              merchantBusinessTypeId ===
                              "e2b9d968-881b-42e2-90f2-223dc64c52fe"
                                ? "custom-radio-checked"
                                : "custom-radio"
                            }
                            value={"e2b9d968-881b-42e2-90f2-223dc64c52fe"}
                            control={
                              <Radio
                                sx={{
                                  color: "#45383426",
                                  "&.Mui-checked": {
                                    color: "#34AE1A",
                                  },
                                }}
                              />
                            }
                            label={
                              <Typography
                                fontWeight="500"
                                color={
                                  merchantBusinessTypeId ===
                                  "e2b9d968-881b-42e2-90f2-223dc64c52fe"
                                    ? theme.palette.success.main
                                    : "#453834"
                                }
                                fontSize="14px"
                              >
                                {t("sellPay")}
                              </Typography>
                            }
                          />
                          <FormControlLabel
                            className={
                              merchantBusinessTypeId ===
                              "769989ae-a2ba-4262-81be-b0be7022c789"
                                ? "custom-radio-checked"
                                : "custom-radio"
                            }
                            value={"769989ae-a2ba-4262-81be-b0be7022c789"}
                            control={
                              <Radio
                                sx={{
                                  color: "#45383426",
                                  "&.Mui-checked": {
                                    color: "#34AE1A",
                                  },
                                }}
                              />
                            }
                            label={
                              <Typography
                                fontWeight="500"
                                color={
                                  merchantBusinessTypeId ===
                                  "769989ae-a2ba-4262-81be-b0be7022c789"
                                    ? theme.palette.success.main
                                    : "#453834"
                                }
                                fontSize="14px"
                              >
                                {t("finalPurchase")}
                              </Typography>
                            }
                          />
                          <FormControlLabel
                            className={
                              merchantBusinessTypeId ===
                              "3552203d-03a5-4d39-92b8-410d3e581b40"
                                ? "custom-radio-checked"
                                : "custom-radio"
                            }
                            value={"3552203d-03a5-4d39-92b8-410d3e581b40"}
                            control={
                              <Radio
                                sx={{
                                  color: "#45383426",
                                  "&.Mui-checked": {
                                    color: "#34AE1A",
                                  },
                                }}
                              />
                            }
                            label={
                              <Typography
                                fontWeight="500"
                                color={
                                  merchantBusinessTypeId ===
                                  "3552203d-03a5-4d39-92b8-410d3e581b40"
                                    ? theme.palette.success.main
                                    : "#453834"
                                }
                                fontSize="14px"
                              >
                                {t("marketplace")}
                              </Typography>
                            }
                          />
                        </RadioGroup>
                      </FormControl>
                    </Grid>
                    {merchantBusinessTypeId ===
                      "3552203d-03a5-4d39-92b8-410d3e581b40" && (
                      <Grid
                        item
                        xs={12}
                        sm={12}
                        className="no-padding"
                        sx={{ ml: "-5px" }}
                      >
                        <FormControl variant="standart">
                          <CustomLabel
                            className="mlabel"
                            title={t("marketplace")}
                          />
                          <Box
                            sx={{
                              display: "flex",
                              flexDirection: "row",
                              mt: 4,
                              ml: 0,
                            }}
                          >
                            <FormControlLabel
                              control={
                                <Checkbox
                                  checked={merchantPlatformList.includes(
                                    "6f9df587-b069-443c-a838-10db42ee2e1c"
                                  )}
                                  onChange={handleMerchantPlatformListChange}
                                  color="success"
                                  value="6f9df587-b069-443c-a838-10db42ee2e1c"
                                />
                              }
                              label={
                                <Typography
                                  variant="inherit"
                                  sx={{
                                    fontWeight: merchantPlatformList.includes(
                                      "6f9df587-b069-443c-a838-10db42ee2e1c"
                                    )
                                      ? 500
                                      : 500,
                                    color: merchantPlatformList.includes(
                                      "6f9df587-b069-443c-a838-10db42ee2e1c"
                                    )
                                      ? "#34AE1A"
                                      : "inherit",
                                    fontSize: "14px",
                                  }}
                                >
                                  {t("advertisement")}
                                </Typography>
                              }
                              sx={{
                                border: merchantPlatformList.includes(
                                  "6f9df587-b069-443c-a838-10db42ee2e1c"
                                )
                                  ? "1px solid #34AE1A"
                                  : "1px solid #45383426",
                                borderRadius: "3px",
                                backgroundColor: merchantPlatformList.includes(
                                  "6f9df587-b069-443c-a838-10db42ee2e1c"
                                )
                                  ? "#43BD230D"
                                  : "#fff",
                                margin: "4px",
                                display: "flex",
                                paddingRight: "10px",
                                height: "35px",
                                "& .MuiSvgIcon-root": {
                                  color: merchantPlatformList.includes(
                                    "6f9df587-b069-443c-a838-10db42ee2e1c"
                                  )
                                    ? "#34AE1A"
                                    : "#45383426",
                                },
                              }}
                            />
                            <FormControlLabel
                              control={
                                <Checkbox
                                  checked={merchantPlatformList.includes(
                                    "a409ace8-3031-433c-9b88-797e4a57c2f5"
                                  )}
                                  onChange={handleMerchantPlatformListChange}
                                  color="success"
                                  value="a409ace8-3031-433c-9b88-797e4a57c2f5"
                                />
                              }
                              label={
                                <Typography
                                  variant="inherit"
                                  sx={{
                                    fontWeight: merchantPlatformList.includes(
                                      "a409ace8-3031-433c-9b88-797e4a57c2f5"
                                    )
                                      ? 500
                                      : 500,
                                    color: merchantPlatformList.includes(
                                      "a409ace8-3031-433c-9b88-797e4a57c2f5"
                                    )
                                      ? "#34AE1A"
                                      : "inherit",
                                    fontSize: "14px",
                                  }}
                                >
                                  {t("fullFilment")}
                                </Typography>
                              }
                              sx={{
                                border: merchantPlatformList.includes(
                                  "a409ace8-3031-433c-9b88-797e4a57c2f5"
                                )
                                  ? "1px solid #34AE1A"
                                  : "1px solid #45383426",
                                borderRadius: "3px",
                                backgroundColor: merchantPlatformList.includes(
                                  "a409ace8-3031-433c-9b88-797e4a57c2f5"
                                )
                                  ? "#43BD230D"
                                  : "#fff",
                                margin: "4px",
                                display: "flex",
                                paddingRight: "10px",
                                height: "35px",
                                "& .MuiSvgIcon-root": {
                                  color: merchantPlatformList.includes(
                                    "a409ace8-3031-433c-9b88-797e4a57c2f5"
                                  )
                                    ? "#34AE1A"
                                    : "#45383426",
                                },
                              }}
                            />
                            <FormControlLabel
                              control={
                                <Checkbox
                                  checked={merchantPlatformList.includes(
                                    "5563188d-35ba-4a48-92dc-c0c715033291"
                                  )}
                                  onChange={handleMerchantPlatformListChange}
                                  color="success"
                                  value="5563188d-35ba-4a48-92dc-c0c715033291"
                                />
                              }
                              label={
                                <Typography
                                  variant="inherit"
                                  sx={{
                                    fontWeight: merchantPlatformList.includes(
                                      "5563188d-35ba-4a48-92dc-c0c715033291"
                                    )
                                      ? 500
                                      : 500,
                                    color: merchantPlatformList.includes(
                                      "5563188d-35ba-4a48-92dc-c0c715033291"
                                    )
                                      ? "#34AE1A"
                                      : "inherit",
                                    fontSize: "14px",
                                  }}
                                >
                                  {t("incentive")}
                                </Typography>
                              }
                              sx={{
                                border: merchantPlatformList.includes(
                                  "5563188d-35ba-4a48-92dc-c0c715033291"
                                )
                                  ? "1px solid #34AE1A"
                                  : "1px solid #45383426",
                                borderRadius: "3px",
                                backgroundColor: merchantPlatformList.includes(
                                  "5563188d-35ba-4a48-92dc-c0c715033291"
                                )
                                  ? "#43BD230D"
                                  : "#fff",
                                margin: "4px",
                                display: "flex",
                                paddingRight: "10px",
                                height: "35px",
                                "& .MuiSvgIcon-root": {
                                  color: merchantPlatformList.includes(
                                    "5563188d-35ba-4a48-92dc-c0c715033291"
                                  )
                                    ? "#34AE1A"
                                    : "#45383426",
                                },
                              }}
                            />
                          </Box>
                        </FormControl>
                      </Grid>
                    )}

                    <Grid
                      item
                      xs={12}
                      sm={12}
                      className="no-padding"
                      sx={{ mb: 1 }}
                    >
                      <FormControl variant="standard" key="ourBrand">
                        <CustomLabel title="İhracatçı" />
                        <RadioGroup
                          sx={{ pt: "35px", ml: "10px" }}
                          row
                          aria-labelledby="demo-row-radio-buttons-group-label"
                          name="row-radio-buttons-group"
                          value={merchantExporterId}
                          onChange={e => setMerchantExporterId(e.target.value)}
                        >
                          <FormControlLabel
                            color="success"
                            className={
                              merchantExporterId ===
                              "88711742-1ede-479d-8c8d-b9b27b4f2079"
                                ? "custom-radio-checked"
                                : "custom-radio"
                            }
                            value={"88711742-1ede-479d-8c8d-b9b27b4f2079"}
                            control={
                              <Radio
                                sx={{
                                  color: "#45383426",
                                  "&.Mui-checked": {
                                    color: "#34AE1A",
                                  },
                                }}
                              />
                            }
                            label={
                              <Typography
                                fontWeight="500"
                                color={
                                  merchantExporterId ===
                                  "88711742-1ede-479d-8c8d-b9b27b4f2079"
                                    ? theme.palette.success.main
                                    : "#453834"
                                }
                                fontSize="14px"
                              >
                                {t("company")}
                              </Typography>
                            }
                          />
                          <FormControlLabel
                            className={
                              merchantExporterId ===
                              "8fa4962a-a67f-423a-b487-5b63cb38bcb8"
                                ? "custom-radio-checked"
                                : "custom-radio"
                            }
                            value={"8fa4962a-a67f-423a-b487-5b63cb38bcb8"}
                            control={
                              <Radio
                                sx={{
                                  color: "#45383426",
                                  "&.Mui-checked": {
                                    color: "#34AE1A",
                                  },
                                }}
                              />
                            }
                            label={
                              <Typography
                                fontWeight="500"
                                color={
                                  merchantExporterId ===
                                  "8fa4962a-a67f-423a-b487-5b63cb38bcb8"
                                    ? theme.palette.success.main
                                    : "#453834"
                                }
                                fontSize="14px"
                              >
                                Mega Merchant
                              </Typography>
                            }
                          />
                        </RadioGroup>
                      </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={6} className="no-padding">
                      <FormControl className="signup-fc">
                        <Typography
                          sx={{
                            fontSize: "13px",
                            fontWeight: "500",
                            color: theme.palette.text.main,
                            mt: "4px",
                          }}
                        >
                          {t("platforms")}
                        </Typography>
                        <MuiSelect
                          labelId="demo-multiple-checkbox-label"
                          id="demo-multiple-checkbox"
                          multiple
                          required
                          displayEmpty
                          value={merchantPlatformId}
                          input={<BootstrapSelect />}
                          onChange={e => setMerchantPlatformId(e.target.value)}
                          renderValue={selected => (
                            <div>
                              {selected.length === 0 ? (
                                <em
                                  style={{
                                    color: "currentColor",
                                    opacity: "0.42",
                                  }}
                                >
                                  {t("select")}
                                </em>
                              ) : (
                                selected.map((value, index) => {
                                  const selectedItem = merchantPlatforms.find(
                                    item => item.value === value
                                  );
                                  return (
                                    <span key={selectedItem.value}>
                                      {selectedItem.title}
                                      {index !== selected.length - 1
                                        ? ", "
                                        : ""}
                                    </span>
                                  );
                                })
                              )}
                            </div>
                          )}
                        >
                          {merchantPlatforms.map(opt => (
                            <MenuItem
                              key={opt.value}
                              value={opt.value}
                              sx={{
                                paddignTop: "0px!important",
                                paddingBottom: "0px!important",
                              }}
                            >
                              <Checkbox
                                size="small"
                                checked={merchantPlatformId.includes(opt.value)}
                              />
                              <ListItemText primary={opt.title} />
                            </MenuItem>
                          ))}
                        </MuiSelect>
                      </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={6} className="no-padding">
                      <FormControl className="signup-fc">
                        <Typography
                          sx={{
                            fontSize: "13px",
                            fontWeight: "500",
                            color: theme.palette.text.main,
                            mt: "4px",
                          }}
                        >
                          {t("countries")}
                        </Typography>
                        {/* <Select
                          labelId="demo-multiple-2-checkbox-label"
                          id="demo-multiple-2-checkbox"
                          displayEmpty
                          multiple
                          value={selectedCountries}
                          input={<BootstrapSelect />}
                          onChange={handleCountryChange}
                          renderValue={(selected) => (
                            <div>
                              {selected.length === 0 ? (
                                <em
                                  style={{
                                    color: "currentColor",
                                    opacity: "0.42",
                                  }}
                                >
                                  Ãlke SeÃ§iniz
                                </em>
                              ) : (
                                selected.map((id) => {
                                  const selectedItem = countryData.find(
                                    (item) => item.id === id
                                  );
                                  return (
                                    <span key={selectedItem.id}>
                                      {selectedItem.title},{" "}
                                    </span>
                                  );
                                })
                              )}
                            </div>
                          )}
                        >
                          {countryData.map((opt) => (
                            <MenuItem
                              key={opt.id}
                              value={opt.id}
                              sx={{
                                paddignTop: "0px!important",
                                paddingBottom: "0px!important",
                              }}
                            >
                              <Checkbox
                                size="small"
                                checked={selectedCountries.indexOf(opt.id) > -1}
                              />
                              <ListItemText primary={opt.title} />
                            </MenuItem>
                          ))}
                        </Select> */}
                        <MuiSelect
                          labelId="demo-multiple-2-checkbox-label"
                          id="demo-multiple-2-checkbox"
                          displayEmpty
                          multiple
                          value={selectedCountries}
                          input={<BootstrapSelect />}
                          onChange={handleCountryChange}
                          renderValue={selected => (
                            <div>
                              {selected.length === 0 ? (
                                <em
                                  style={{
                                    color: "currentColor",
                                    opacity: "0.42",
                                  }}
                                >
                                  {t("countries")}
                                </em>
                              ) : (
                                selected.map((id, index) => {
                                  const selectedItem = countryData.find(
                                    item => item.id === id
                                  );
                                  return (
                                    <span key={selectedItem.id}>
                                      {selectedItem.title}
                                      {index !== selected.length - 1
                                        ? ", "
                                        : ""}
                                    </span>
                                  );
                                })
                              )}
                            </div>
                          )}
                        >
                          {sortCountryDataWithTurkeyFirst(countryData).map(
                            opt => (
                              <MenuItem
                                key={opt.id}
                                value={opt.id}
                                sx={{
                                  paddignTop: "0px!important",
                                  paddingBottom: "0px!important",
                                }}
                              >
                                <Checkbox
                                  size="small"
                                  checked={
                                    selectedCountries.indexOf(opt.id) > -1
                                  }
                                />
                                <ListItemText primary={opt.title} />
                              </MenuItem>
                            )
                          )}
                        </MuiSelect>
                      </FormControl>
                    </Grid>
                  </Grid>
                </FormGroup>
              </Box>
              <Box sx={{ mt: 2 }}>
                <FormGroup>
                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={12} className="no-padding-title">
                      <BoxTitle title={t("financialDetails")} />
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      sm={6}
                      style={{ paddingTop: "2px!important" }}
                      className="no-padding"
                    >
                      <FormControl
                        variant="standard"
                        className="signup-fc"
                        key={"annualServiceFee"}
                      >
                        <CustomLabel title={t("advertisingBudget")} />
                        <BootstrapInput
                          type="number"
                          placeholder={t("advertisingBudget")}
                          onChange={e => setAnnualServiceFee(e.target.value)}
                          value={annualServiceFee}
                        />
                      </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={6} className="no-padding">
                      <FormControl
                        sx={{ m: 0, flex: 1 }}
                        size="small"
                        className="filter-select"
                      >
                        <Typography
                          sx={{
                            fontSize: "13px",
                            fontWeight: "500",
                            color: theme.palette.text.main,
                            mt: "6px",
                            mb: "-2px",
                          }}
                        >
                          {t("currency")}
                        </Typography>
                        <MuiSelect
                          labelId="demo-multiple-name-label"
                          id="currencyId"
                          value={currency}
                          input={<BootstrapInput label={t("currency")} />}
                          onChange={e => setCurrency(e.target.value)}
                          size="small"
                          displayEmpty
                          MenuProps={MenuProps}
                          renderValue={() => (
                            <div>
                              {currency === "" ? (
                                <em
                                  style={{
                                    color: "currentColor",
                                    opacity: "0.42",
                                  }}
                                >
                                  {t("select")}
                                </em>
                              ) : (
                                currencies.find(
                                  option => option.id === currency
                                ).name
                              )}
                            </div>
                          )}
                        >
                          {currencies.map(opt => (
                            <MenuItem key={opt.id} value={opt.id}>
                              {opt.name}
                            </MenuItem>
                          ))}
                        </MuiSelect>
                      </FormControl>
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      sm={6}
                      style={{ paddingTop: "2px!important" }}
                      className="no-padding"
                    >
                      <FormControl
                        variant="standard"
                        className="signup-fc"
                        key={"salesCommission"}
                      >
                        <CustomLabel title={t("salesCommission")+" (%)"} />
                        <BootstrapInput
                          type="number"
                          placeholder={t("salesCommission")+" (%)"}
                          onChange={e => setSalesCommission(e.target.value)}
                          value={salesCommission}
                        />
                      </FormControl>
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      sm={6}
                      style={{ paddingTop: "2px!important" }}
                      className="no-padding"
                    >
                      <FormControl
                        variant="standard"
                        className="signup-fc"
                        key={"advertisingCommission"}
                      >
                        <CustomLabel title={t("advertisingCommission")+" (%)"} />
                        <BootstrapInput
                          type="number"
                          placeholder={t("advertisingCommission")+" (%)"}
                          onChange={e =>
                            setAdvertisingCommission(e.target.value)
                          }
                          value={advertisingCommission}
                        />
                      </FormControl>
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      sm={6}
                      style={{ paddingTop: "2px!important" }}
                      className="no-padding"
                    >
                      <FormControl
                        variant="standard"
                        className="signup-fc"
                        key={"incentiveCommission"}
                      >
                        <CustomLabel title={t("incentiveCommission")+" (%)"} />
                        <BootstrapInput
                          type="number"
                          placeholder={t("incentiveCommission")+" (%)"}
                          onChange={e => setIncentiveCommission(e.target.value)}
                          value={incentiveCommission}
                        />
                      </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={6} className="no-padding">
                      <FormControl
                        variant="standard"
                        className="signup-fc"
                        key={"paymentTerm"}
                      >
                        <CustomLabel title={t("maturityDays")} />
                        <BootstrapInput
                          type="text"
                          placeholder={t("maturityDays")}
                          onChange={handlePaymentTermChange}
                          value={paymentTerm}
                        />
                      </FormControl>
                    </Grid>

                    <Grid item xs={12} sm={6} className="no-padding">
                      <FormControl
                        sx={{ m: 0, flex: 1 }}
                        size="small"
                        className="filter-select"
                      >
                        <Typography
                          sx={{
                            fontSize: "13px",
                            fontWeight: "500",
                            color: theme.palette.text.main,
                            mt: "6px",
                            mb: "-2px",
                          }}
                        >
                          {t("paymentMethod")}
                        </Typography>
                        <MuiSelect
                          labelId="demo-multiple-name-label"
                          id="paymentMethodId"
                          value={paymentMethodId}
                          input={<BootstrapInput label="Payment Option" />}
                          onChange={e => setPaymentMethodId(e.target.value)}
                          size="small"
                          displayEmpty
                          MenuProps={MenuProps}
                          renderValue={() => (
                            <div>
                              {paymentMethodId === "" ? (
                                <em
                                  style={{
                                    color: "currentColor",
                                    opacity: "0.42",
                                  }}
                                >
                                  {t("select")}
                                </em>
                              ) : (
                                paymentOptions.find(
                                  option => option.value === paymentMethodId
                                ).title
                              )}
                            </div>
                          )}
                        >
                          {paymentOptions.map(opt => (
                            <MenuItem key={opt.value} value={opt.value}>
                              {opt.title}
                            </MenuItem>
                          ))}
                        </MuiSelect>
                      </FormControl>
                    </Grid>
                  </Grid>
                </FormGroup>
              </Box>
            </Box>
          </DialogContent>
          <DialogActions
            sx={{
              display: "flex",
              justifyContent: "space-between",
              ml: 2,
              mr: 2,
            }}
          >
            <Button className="filterReset" onClick={handleAggrementModalClose}>
              {t("close")}
            </Button>
            <Button className="filterApply" autoFocus onClick={submitAggrement}>
              {t("save")}
            </Button>
          </DialogActions>
        </Dialog>
      </Box>
      <Box className="fileDialog">
        <Dialog
          maxWidth="md"
          fullWidth={true}
          open={openFileInsert}
          onClose={handleFileInsertClose}
        >
          <DialogTitle
            sx={{ m: 0, p: 2, pl: 2, color: theme.palette.primary.main }}
            id="customized-dialog-title"
          >
            {t("addFile")}
          </DialogTitle>
          <IconButton
            aria-label="close"
            onClick={handleFileInsertClose}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: theme => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
          <Divider />
          <DialogContent>
            <Box p={3} pt={0} pb={0}>
              <ul style={{ paddingInlineStart: "10px" }}>
                <li>
                  {t("taxCertificate")} <span style={{ color: "#D7283E" }}>*</span>
                </li>
                <li>
                  {t("signatureCircular")} <span style={{ color: "#D7283E" }}>*</span>
                </li>
                <li>
                  {t("tradeRegistryGazette")}
                  <span style={{ color: "#D7283E" }}> *</span>
                </li>
                <li>{t("trademarkRegistrationCertificate")}</li>
                <li>{t("foreignRegistrationCertificate")}</li>
              </ul>
            </Box>
            <Box p={3}>
              <Paper
                elevation={3}
                sx={{
                  padding: 3,
                  textAlign: "center",
                  border: "2px dashed #ddd",
                  borderRadius: "8px",
                }}
              >
                <div
                  {...getRootProps()}
                  className={`dropzone ${isDragAccept ? "active" : ""} ${
                    isDragReject ? "reject" : ""
                  }`}
                >
                  <input {...getInputProps()} />
                  <Typography variant="h6" gutterBottom>
                    {t("fileUpload")}
                  </Typography>
                  <Typography variant="body2" color="textSecondary">
                    {t("dragYourFilesHereOrClickToSelectThem")}
                  </Typography>
                </div>
                {uploadError && <div>{uploadError}</div>}
                {uploadedFiles.length > 0 && (
                  <div className="uploaded-files">
                    {uploadedFiles.map((file, index) => (
                      <Chip
                        key={index}
                        label={file.name}
                        onDelete={() => handleDeleteFile(file.name)}
                        sx={{ margin: "0.2rem" }}
                      />
                    ))}
                  </div>
                )}
                {progress > 0 && (
                  <LinearProgress
                    variant="determinate"
                    value={progress}
                    sx={{ marginTop: 2 }}
                  />
                )}
                <Button
                  variant="contained"
                  color="primary"
                  type="submit"
                  disabled={progress > 0 || uploadedFiles.length === 0}
                  onClick={handleSubmitFile}
                  sx={{ marginTop: 2 }}
                >
                  {t("send")}
                </Button>
              </Paper>
            </Box>
          </DialogContent>
        </Dialog>
        <Snackbar
          open={isSnackbarOpen}
          autoHideDuration={6000}
          onClose={handleCloseSnackbar}
          message={successMessage}
        />
      </Box>
      <Box className="statusDialog">
        <ChangeStatusDialog
          fullWidth={true}
          maxWidth="sm"
          keepMounted
          onClose={handleSubmerchantClose}
          open={openSubmerchant}
        >
          <DialogTitle
            sx={{ m: 0, p: 2, color: theme.palette.primary.main }}
            id="customized-dialog-title"
          >
            {t("makeSubmerchant")}
          </DialogTitle>
          <IconButton
            aria-label="close"
            onClick={handleSubmerchantClose}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: theme => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
          <DialogContent dividers>
            <TableContainer
              sx={{
                boxShadow: "none!important",
                borderBottom: "none!important",
                borderRadius: "0!important",
                paddingBottom: "10px",
              }}
              component={Paper}
            >
              <Table sx={{ minWidth: 450 }} aria-label="simple table">
                <TableHead sx={{ border: "1px solid #e0e0e0" }}>
                  <TableRow>
                    <TableCell align="left">{t("companyName")}</TableCell>
                    <TableCell align="left">{t("brand")}</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow className="stock-table">
                    <TableCell align="left" sx={{ width: "225px" }}>
                      {merchant.title}
                    </TableCell>
                    <TableCell align="left" sx={{ padding: "1px" }}>
                      <FormControl sx={{ m: 1, width: "360px" }} size="small">
                        <MuiSelect
                          labelId="demo-multiple-2-checkbox-label"
                          id="demo-multiple-2-checkbox"
                          displayEmpty
                          multiple
                          value={selectedMerchantMarketPlaceList}
                          input={<BootstrapSelect />}
                          onChange={e =>
                            setSelectedMerchantMarketPlaceList(e.target.value)
                          }
                          renderValue={selected => (
                            <div>
                              {selected.length === 0 ? (
                                <em
                                  style={{
                                    color: "currentColor",
                                    opacity: "0.42",
                                  }}
                                >
                                  {t("brands")}
                                </em>
                              ) : (
                                selected.map((id, index) => {
                                  const selectedItem =
                                    getMerchantMarketPlaceList.find(
                                      item => item.id === id
                                    );
                                  return (
                                    <span key={selectedItem.id}>
                                      {selectedItem.title}
                                      {index !== selected.length - 1
                                        ? ", "
                                        : ""}
                                    </span>
                                  );
                                })
                              )}
                            </div>
                          )}
                        >
                          {getMerchantMarketPlaceList.map(opt => (
                            <MenuItem
                              key={opt.id}
                              value={opt.id}
                              sx={{
                                paddignTop: "0px!important",
                                paddingBottom: "0px!important",
                              }}
                            >
                              <Checkbox
                                size="small"
                                checked={
                                  selectedMerchantMarketPlaceList.indexOf(
                                    opt.id
                                  ) > -1
                                }
                              />
                              <ListItemText primary={opt.title} />
                            </MenuItem>
                          ))}
                        </MuiSelect>
                      </FormControl>
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
          </DialogContent>
          <DialogActions
            sx={{ display: "flex", justifyContent: "space-between" }}
          >
            <Button className="filterReset" onClick={resetSubmerchant}>
              {t("reset")}
            </Button>
            <Button
              disabled={submerchantButtonLoading}
              className="filterApply"
              autoFocus
              onClick={changeSubMerchantFromAPI}
            >
              {t("save")}
            </Button>
          </DialogActions>
        </ChangeStatusDialog>
      </Box>
      <Box className="pointDialog">
        <ChangeStatusDialog
          fullWidth={true}
          maxWidth="sm"
          keepMounted
          onClose={handlePointClose}
          open={openPoint}
        >
          <DialogTitle
            sx={{ m: 0, p: 2, color: theme.palette.primary.main }}
            id="customized-dialog-title"
          >
           {t("givePoints")}
          </DialogTitle>
          <IconButton
            aria-label="close"
            onClick={handlePointClose}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: theme => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
          <DialogContent dividers>
            <TableContainer
              sx={{
                boxShadow: "none!important",
                borderBottom: "none!important",
                borderRadius: "0!important",
                paddingBottom: "10px",
              }}
              component={Paper}
            >
              <Table sx={{ minWidth: 450 }} aria-label="simple table">
                <TableHead sx={{ border: "1px solid #e0e0e0" }}>
                  <TableRow>
                    <TableCell align="left">{t("companyName")}</TableCell>
                    <TableCell align="left">{t("score")}</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow className="stock-table">
                    <TableCell align="left" sx={{ width: "225px" }}>
                      {merchant.title}
                    </TableCell>
                    <TableCell align="left" sx={{ padding: "1px" }}>
                      <FormControl sx={{ m: 1, width: "360px" }} size="small">
                        <BootstrapInput
                          type="number"
                          placeholder={t("score")}
                          onChange={e => setPoint(e.target.value)}
                          value={point}
                        />
                      </FormControl>
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
          </DialogContent>
          <DialogActions
            sx={{ display: "flex", justifyContent: "space-between" }}
          >
            <Button className="filterReset" onClick={handlePointClose}>
              {t("close")}
            </Button>
            <Button
              className="filterApply"
              autoFocus
              onClick={changePointFromAPI}
            >
              {t("save")}
            </Button>
          </DialogActions>
        </ChangeStatusDialog>
      </Box>
      <Box
        className="linkDialog"
        sx={{
          width: "470px!important",
          overflowX: "hidden",
        }}
      >
        <ChangeStatusDialog
          onClose={handleMMUserClose}
          aria-labelledby="customized-dialog-title"
          open={mmUserDialog}
        >
          <DialogTitle
            sx={{ m: 0, p: 2, color: theme.palette.primary.main }}
            id="customized-dialog-title"
          >
            {t("addResponsible")}
          </DialogTitle>
          <IconButton
            aria-label="close"
            onClick={handleMMUserClose}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: theme => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
          <DialogContent s dividers>
            <Box>
              <FormControl sx={{ m: 1, width: "460px" }} size="small">
                <MuiSelect
                  labelId="demo-multiple-name-label"
                  id="currencyId"
                  value={selectedMMUser}
                  input={<BootstrapInput label="mmUser" />}
                  onChange={e => setSelectedMMUser(e.target.value)}
                  size="small"
                  displayEmpty
                  MenuProps={MenuProps}
                  renderValue={() => (
                    <div>
                      {selectedMMUser === "" ? (
                        <em
                          style={{
                            color: "currentColor",
                            opacity: "0.42",
                          }}
                        >
                          {t("select")}
                        </em>
                      ) : (
                        mmUserList.find(option => option.id === selectedMMUser)
                          .nameSurname
                      )}
                    </div>
                  )}
                >
                  {mmUserList.map(opt => (
                    <MenuItem key={opt.id} value={opt.id}>
                      {opt.nameSurname}
                    </MenuItem>
                  ))}
                </MuiSelect>
              </FormControl>
            </Box>
          </DialogContent>
          <DialogActions sx={{ display: "flex", justifyContent: "space-end" }}>
            <Button
              className="filterApply"
              autoFocus
              onClick={updateMMUserFromAPI}
            >
              {t("save")}
            </Button>
          </DialogActions>
        </ChangeStatusDialog>
      </Box>
      <Box
        className="linkDialog"
        sx={{
          width: "470px!important",
          overflowX: "hidden",
        }}
      >
        <ChangeStatusDialog
          onClose={handleCloseMMAccountManagerClose}
          aria-labelledby="customized-dialog-title"
          open={mmAccountManagerDialog}
        >
          <DialogTitle
            sx={{ m: 0, p: 2, color: theme.palette.primary.main }}
            id="customized-dialog-title"
          >
            {t("addAccountManager")}
          </DialogTitle>
          <IconButton
            aria-label="close"
            onClick={handleCloseMMAccountManagerClose}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: theme => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
          <DialogContent s dividers>
            <Box>
              <FormControl sx={{ m: 1, width: "460px" }} size="small">
                <MuiSelect
                  labelId="demo-multiple-name-label"
                  id="currencyId"
                  value={selectedMMAccountManager}
                  input={<BootstrapInput label="mmUser" />}
                  onChange={e => setSelectedMMAccountManager(e.target.value)}
                  size="small"
                  displayEmpty
                  MenuProps={MenuProps}
                  renderValue={() => (
                    <div>
                      {selectedMMAccountManager === "" ? (
                        <em
                          style={{
                            color: "currentColor",
                            opacity: "0.42",
                          }}
                        >
                          {t("select")}
                        </em>
                      ) : (
                        mmUserList.find(
                          option => option.id === selectedMMAccountManager
                        ).nameSurname
                      )}
                    </div>
                  )}
                >
                  {mmUserList.map(opt => (
                    <MenuItem key={opt.id} value={opt.id}>
                      {opt.nameSurname}
                    </MenuItem>
                  ))}
                </MuiSelect>
              </FormControl>
            </Box>
          </DialogContent>
          <DialogActions sx={{ display: "flex", justifyContent: "space-end" }}>
            <Button
              className="filterApply"
              autoFocus
              onClick={updateMMAccountManagerFromAPI}
            >
              {t("save")}
            </Button>
          </DialogActions>
        </ChangeStatusDialog>
      </Box>
      <Box
        className="linkDialog"
        sx={{
          width: "470px!important",
          overflowX: "hidden",
        }}
      >
        <ChangeStatusDialog
          onClose={handleTestUserClose}
          aria-labelledby="customized-dialog-title"
          open={testUserDialog}
        >
          <DialogTitle
            sx={{ m: 0, p: 2, color: theme.palette.primary.main }}
            id="customized-dialog-title"
          >
            {t("testUser")}
          </DialogTitle>
          <IconButton
            aria-label="close"
            onClick={handleTestUserClose}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: theme => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
          <DialogContent dividers>
            <Box
              sx={{
                border: "1px solid #e0e0e0",
                borderRadius: "4px",
                pt: 1,
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  marginX: "10px",
                  width: "420px",
                }}
              >
                <Typography variant="body" fontWeight="500" color="primary">
                  {t("companyBrandSelection")}
                </Typography>
                <FormControl sx={{ width: "100%" }} size="small">
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={testUserIsSubmerchant}
                        onChange={handleTestUserIsSubmerchant}
                        color="success"
                      />
                    }
                    label="Submerchant"
                  />
                </FormControl>
              </Box>
              <Divider
                sx={{
                  width: "95%",
                  marginX: "auto",
                  marginY: "10px",
                }}
              />
              {testUserIsSubmerchant ? (
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    marginX: "10px",
                    marginBottom: "15px",
                  }}
                >
                  <Typography fontSize="14px" variant="body" fontWeight="500">
                    {t("brand")}
                  </Typography>
                  <MuiSelect
                    labelId="demo-multiple-2-checkbox-label"
                    id="demo-multiple-2-checkbox"
                    displayEmpty
                    multiple
                    value={testUserSelectedProductBrands}
                    input={<BootstrapSelect />}
                    onChange={e =>
                      setTestUserSelectedProductBrands(e.target.value)
                    }
                    renderValue={selected => (
                      <div>
                        {selected.length === 0 ? (
                          <em
                            style={{
                              color: "currentColor",
                              opacity: "0.42",
                            }}
                          >
                            {t("brands")}
                          </em>
                        ) : (
                          selected.map((id, index) => {
                            const selectedItem =
                              getMerchantMarketPlaceList.find(
                                item => item.id === id
                              );
                            return (
                              <span key={selectedItem.id}>
                                {selectedItem.title}
                                {index !== selected.length - 1 ? ", " : ""}
                              </span>
                            );
                          })
                        )}
                      </div>
                    )}
                  >
                    {getMerchantMarketPlaceList.map(opt => (
                      <MenuItem
                        key={opt.id}
                        value={opt.id}
                        sx={{
                          paddignTop: "0px!important",
                          paddingBottom: "0px!important",
                        }}
                      >
                        <Checkbox
                          size="small"
                          checked={
                            testUserSelectedProductBrands.indexOf(opt.id) > -1
                          }
                        />
                        <ListItemText primary={opt.title} />
                      </MenuItem>
                    ))}
                  </MuiSelect>
                  <FormControl
                    sx={{ width: "100%" }}
                    size="small"
                  ></FormControl>
                </Box>
              ) : (
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    marginX: "10px",
                    marginBottom: "15px",
                  }}
                >
                  <Typography fontSize="14px" variant="body" fontWeight="500">
                    {t("company")}
                  </Typography>
                  <FormControl sx={{ width: "100%" }} size="small">
                    {/* Single select */}
                    <MuiSelect
                      labelId="demo-multiple-2-checkbox-label"
                      id="demo-multiple-2-checkbox"
                      displayEmpty
                      value={testUserSelectedMerchant}
                      input={<BootstrapSelect />}
                      onChange={e =>
                        setTestUserSelectedMerchant(e.target.value)
                      }
                      renderValue={() => (
                        <div>
                          {testUserSelectedMerchant === "" ? (
                            <em
                              style={{
                                color: "currentColor",
                                opacity: "0.42",
                              }}
                            >
                              {t("company")}
                            </em>
                          ) : (
                            merchantList.find(
                              option => option.id === testUserSelectedMerchant
                            ).title
                          )}
                        </div>
                      )}
                    >
                      {merchantList.map(opt => (
                        <MenuItem key={opt.id} value={opt.id}>
                          {opt.title}
                        </MenuItem>
                      ))}
                    </MuiSelect>
                  </FormControl>
                </Box>
              )}
            </Box>
          </DialogContent>
          <DialogActions sx={{ display: "flex", justifyContent: "space-end" }}>
            {submitSuccess ? (
              <Button
                className="filterApply"
                onClick={handleChangeUser}
                autoFocus
              >
                {t("signIn")}
              </Button>
            ) : (
              <Button
                className="filterApply"
                onClick={handleTestUserSubmit}
                autoFocus
                disabled={submitLoading}
              >
                {t("save")}
              </Button>
            )}
          </DialogActions>
        </ChangeStatusDialog>
      </Box>
      <Box
        className="linkDialog"
        sx={{
          width: "560px!important",
          overflowX: "hidden",
        }}
      >
        <AddLinksDialog
          onClose={handleLinksClose}
          aria-labelledby="customized-dialog-title"
          open={openLinks}
          style={{
            width: "560px!important",
            overflowX: "hidden",
          }}
        >
          <DialogTitle
            sx={{ m: 0, p: 2, color: theme.palette.primary.main }}
            id="customized-dialog-title"
          >
            {t("addConnection")}
          </DialogTitle>
          <IconButton
            aria-label="close"
            onClick={handleLinksClose}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: theme => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
          <Box
            component="form"
            sx={{
              backgroundColor: "#fff",
              width: "560px!important",
              overflowX: "hidden",
            }}
            noValidate
            autoComplete="off"
          >
            <Box
              sx={{
                width: "560px!important",
                overflowX: "hidden",
              }}
            >
              <FormGroup>
                <Grid container spacing={2}>
                  <Grid
                    item
                    xs={12}
                    sm={12}
                    style={{ paddingTop: "2px!important" }}
                    className="no-padding"
                  >
                    <FormControl
                      variant="standard"
                      className="signup-fc"
                      key={"1"}
                    >
                      <CustomLabel title={t("linkName")} />
                      <BootstrapInput
                        type="text"
                        name="title"
                        placeholder={t("linkName")}
                        onChange={handleChangeSetLinksTitle}
                        value={addLinksTitle}
                      />
                      {linksErrors.title && (
                        <span className="error-message">
                          {linksErrors.title}
                        </span>
                      )}
                    </FormControl>
                    <FormControl
                      variant="standard"
                      className="signup-fc"
                      key={"2"}
                    >
                      <CustomLabel title={t("connectionLink")} />
                      <BootstrapInput
                        type="text"
                        name="path"
                        placeholder={t("connectionLink")}
                        onChange={handleChangeSetLinksUrl}
                        value={addLinksUrl}
                      />
                      {linksErrors.path && (
                        <span className="error-message">
                          {linksErrors.path}
                        </span>
                      )}
                    </FormControl>
                  </Grid>
                </Grid>
              </FormGroup>
            </Box>
          </Box>
          <DialogActions sx={{ display: "flex", justifyContent: "flex-end" }}>
            <Button
              className="filterApply"
              autoFocus
              onClick={addLinksFromAPI}
              disabled={
                addLinksTitle === "" || addLinksUrl === "" ? true : false
              }
            >
              {t("save")}
            </Button>
          </DialogActions>
        </AddLinksDialog>
      </Box>

      <Box
        className="linkDialog"
        sx={{
          width: "560px!important",
          overflowX: "hidden",
        }}
      >
        <AddLinksDialog
          onClose={closeNotesModalHandler}
          aria-labelledby="customized-dialog-title"
          open={openNotesModal}
          style={{
            width: "560px!important",
            overflowX: "hidden",
          }}
        >
          <DialogTitle
            sx={{ m: 0, p: 2, color: theme.palette.primary.main }}
            id="customized-dialog-title"
          >
            {t("addNote")}
          </DialogTitle>
          <IconButton
            aria-label="close"
            onClick={closeNotesModalHandler}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: theme => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
          <Box
            component="form"
            sx={{
              backgroundColor: "#fff",
              width: "560px!important",
              overflowX: "hidden",
            }}
            noValidate
            autoComplete="off"
          >
            <Box
              sx={{
                width: "560px!important",
                overflowX: "hidden",
              }}
            >
              <FormGroup>
                <Grid container spacing={2}>
                  <Grid
                    item
                    xs={12}
                    sm={12}
                    style={{ paddingTop: "2px!important" }}
                    className="no-padding"
                  >
                    <FormControl
                      variant="standard"
                      className="signup-fc"
                      key={"1"}
                    >
                      <BootstrapInput
                        type="textarea"
                        name="title"
                        multiline
                        rows={4}
                        placeholder={"Placeholder"}
                        onChange={handleChangeNote}
                        value={newNote}
                      />
                      {/* {linksErrors.title && (
                        <span className="error-message">
                          {linksErrors.title}
                        </span>
                      )} */}
                    </FormControl>
                  </Grid>
                </Grid>
              </FormGroup>
            </Box>
          </Box>
          <DialogActions sx={{ display: "flex", justifyContent: "flex-end" }}>
            <Button
              className="filterApply"
              autoFocus
              onClick={handleSentNote}
              disabled={creatingNote}
            >
              {t("save")}
            </Button>
          </DialogActions>
        </AddLinksDialog>
      </Box>
      <Box
        className="DeleteNoteDialog"
        sx={{
          width: "560px!important",
          overflowX: "hidden",
        }}
      >
        <AddLinksDialog
          onClose={closeNotesDeleteModalHandler}
          aria-labelledby="customized-dialog-title"
          open={openNotesDeleteModal}
          style={{
            width: "560px!important",
            overflowX: "hidden",
          }}
        >
          <DialogTitle
            sx={{ m: 0, p: 2, color: theme.palette.primary.main }}
            id="customized-dialog-title"
          >
           {t("deleteNote")}
          </DialogTitle>
          <IconButton
            aria-label="close"
            onClick={closeNotesDeleteModalHandler}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: theme => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
          <Divider />
          <Box
            component="form"
            sx={{
              backgroundColor: "#fff",
              width: "560px!important",
              overflowX: "hidden",
            }}
            noValidate
            autoComplete="off"
          >
            <Box
              sx={{
                width: "560px!important",
                overflowX: "hidden",
                paddingTop: "20px",
                paddingLeft: "20px",
                paddingRight: "20px",
                marginBottom: "20px",
              }}
            >
              <Typography
                sx={{
                  fontSize: "16px",
                  fontWeight: "400",
                  color: theme.palette.text.main,
                  mt: "4px",
                }}
              >
                {t("areYouSureYouWantToDeleteTheNote")}
              </Typography>
            </Box>
          </Box>
          <Divider />
          <DialogActions
            sx={{ display: "flex", justifyContent: "space-between" }}
          >
            <Button
              className="filterReset"
              autoFocus
              onClick={closeNotesDeleteModalHandler}
              // disabled={
              //   addLinksTitle === "" || addLinksUrl === "" ? true : false
              // }
            >
              {t("close")}
            </Button>
            <Button
              className="filterApply"
              autoFocus
              onClick={handleDeleteNote}
              // disabled={
              //   addLinksTitle === "" || addLinksUrl === "" ? true : false
              // }
            >
              {t("delete")}
            </Button>
          </DialogActions>
        </AddLinksDialog>
      </Box>
      <Box
        className="UpdateNoteDialog"
        sx={{
          width: "560px!important",
          overflowX: "hidden",
        }}
      >
        <AddLinksDialog
          onClose={closeNotesUpdateModalHandler}
          aria-labelledby="customized-dialog-title"
          open={openNotesUpdateModal}
          style={{
            width: "560px!important",
            overflowX: "hidden",
          }}
        >
          <DialogTitle
            sx={{ m: 0, p: 2, color: theme.palette.primary.main }}
            id="customized-dialog-title"
          >
            {t("editNote")}
          </DialogTitle>
          <IconButton
            aria-label="close"
            onClick={closeNotesUpdateModalHandler}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: theme => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
          <Divider />
          <Box
            component="form"
            sx={{
              backgroundColor: "#fff",
              width: "560px!important",
              overflowX: "hidden",
            }}
            noValidate
            autoComplete="off"
          >
            <Box
              sx={{
                width: "560px!important",
                overflowX: "hidden",
                paddingTop: "20px",
                paddingLeft: "20px",
                paddingRight: "20px",
                marginBottom: "20px",
              }}
            >
              <FormControl variant="standard" className="signup-fc" key={"1"}>
                <BootstrapInput
                  type="textarea"
                  name="title"
                  multiline
                  rows={4}
                  placeholder={"Placeholder"}
                  onChange={e => setSelectedNoteContent(e.target.value)}
                  value={selectedNoteContent}
                />
              </FormControl>
            </Box>
          </Box>
          <Divider />
          <DialogActions
            sx={{ display: "flex", justifyContent: "space-between" }}
          >
            <Button
              className="filterReset"
              autoFocus
              onClick={closeNotesUpdateModalHandler}
              // disabled={
              //   addLinksTitle === "" || addLinksUrl === "" ? true : false
              // }
            >
              {t("close")}
            </Button>
            <Button
              className="filterApply"
              autoFocus
              onClick={handleUpdateNote}
              // disabled={
              //   addLinksTitle === "" || addLinksUrl === "" ? true : false
              // }
            >
              {t("update")}
            </Button>
          </DialogActions>
        </AddLinksDialog>
      </Box>

      <Box>
        <SendMessage
          sendMessage={sendMessage}
          merchant={merchant}
          open={openSendMessageDialog}
          onClose={handleCloseSendMessageDialog}
        />
      </Box>
      <Snackbar
        open={isSnackbarOpen}
        autoHideDuration={6000}
        onClose={handleCloseSnackbar}
        message={successMessage}
      />
    </Box>
  );
};

export default Merchants;
