function getPrice(params) {
  let value = params.row.productWarehouses;

  if (value.length === 0) {
    return null;
  } else {
    return value[0].price;
  }
}

export default getPrice;
