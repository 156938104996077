import React from "react";

const NewAdvertisingPackagesTooltipIcon = () => {
  return (
    <svg
      width="15"
      height="14"
      viewBox="0 0 15 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g opacity="0.6" clip-path="url(#clip0_3521_3366)">
        <path
          d="M9.16055 9.52003H8.74055C8.51777 9.52003 8.30411 9.43153 8.14658 9.274C7.98905 9.11647 7.90055 8.90281 7.90055 8.68003V6.58003C7.90055 6.46864 7.8563 6.36181 7.77753 6.28305C7.69877 6.20428 7.59194 6.16003 7.48055 6.16003H7.06055"
          stroke="#453834"
          stroke-width="1.2"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M7.69047 4.05994C7.64893 4.05994 7.60833 4.07225 7.5738 4.09533C7.53926 4.1184 7.51235 4.1512 7.49645 4.18957C7.48056 4.22795 7.4764 4.27017 7.4845 4.31091C7.49261 4.35164 7.51261 4.38906 7.54198 4.41843C7.57135 4.4478 7.60876 4.4678 7.6495 4.4759C7.69024 4.484 7.73246 4.47985 7.77083 4.46395C7.8092 4.44806 7.842 4.42114 7.86508 4.38661C7.88815 4.35207 7.90047 4.31147 7.90047 4.26994C7.90047 4.21424 7.87834 4.16083 7.83896 4.12144C7.79958 4.08206 7.74616 4.05994 7.69047 4.05994Z"
          stroke="#453834"
          stroke-width="1.2"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M1.59961 6.99995C1.59961 8.67082 2.26336 10.2732 3.44484 11.4547C4.62632 12.6362 6.22875 13.3 7.89961 13.3C9.57047 13.3 11.1729 12.6362 12.3544 11.4547C13.5359 10.2732 14.1996 8.67082 14.1996 6.99995C14.1996 5.32909 13.5359 3.72666 12.3544 2.54518C11.1729 1.3637 9.57047 0.699951 7.89961 0.699951C6.22875 0.699951 4.62632 1.3637 3.44484 2.54518C2.26336 3.72666 1.59961 5.32909 1.59961 6.99995V6.99995Z"
          stroke="#453834"
          stroke-width="1.2"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_3521_3366">
          <rect
            width="14"
            height="14"
            fill="white"
            transform="translate(0.900391)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export default NewAdvertisingPackagesTooltipIcon;
