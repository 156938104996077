import {
  Box,
  Grid,
  Stack,
  Typography,
  Button,
  FormControl,
  FormGroup,
  useTheme,
  Container,
  OutlinedInput,
  Alert,
} from "@mui/material";
import React, { useState, useEffect } from "react";
import axios, { AxiosError } from "axios";
import CustomLabel from "../components/forms/form-elements/CustomLabel";
import CheckIcon from "@mui/icons-material/Check";
import { Form } from "react-bootstrap";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import InputAdornment from "@mui/material/InputAdornment";
import IconButton from "@mui/material/IconButton";
import { useLocation, useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { useSignIn } from "react-auth-kit";
import { useTranslation } from "react-i18next";

const ForgetPassword = () => {
  const navigate = useNavigate();
  const theme = useTheme();
  const { t } = useTranslation();
  const [dots, setDots] = useState(1);
  const [activeStep, setActiveStep] = useState(0);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [skipped, setSkipped] = useState(new Set());
  const [isLoaded, setIsLoaded] = useState(false);
  const [error, setError] = useState("");
  const [email, setEmail] = useState("");
  const [isSuccess, setIsSuccess] = useState(false); // [1
  const [requestError, setRequestError] = useState(null);
  const [updateErrors, setUpdateErrors] = useState({
    email: "",
  });

  const getLoadingText = () => {
    const dotString = ".".repeat(dots);
    return `${dotString}`;
  };

  const emailChange = e => {
    setEmail(e.target.value);
    validateField("email", e.target.value);
  };

  const validateField = (fieldName, value) => {
    const newErrors = { ...updateErrors };

    if (fieldName === "email") {
      newErrors.email = !value.match(/^\S+@\S+\.\S+$/)
        ? t("enterAvalidEmailAddress")
        : "";
    }
    setUpdateErrors(newErrors);
  };
  //

  const handleSubmit = async e => {
    e.preventDefault();
    setIsLoaded(false);
    const response = await axios.post(
      `${process.env.REACT_APP_BASE_URL}/controller/password-reset`,
      {
        email: email,
      }
    );
    if (response.status === 200) {
      setIsSuccess(true);
      setIsSubmitted(true);
    } else {
      console.log("error");
    }
  };
  return (
    <Container
      component="main"
      maxWidth="xs"
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        overflow: "hidden",
        height: "90vh",
      }}
    >
      <Form
        method="post"
        onSubmit={handleSubmit}
        style={{ width: "100%", background: "#fff" }}
      >
        <Box
          sx={{
            mb: 1,
            pb: 1,
            pt: 0,
            "& > :not(style)": { m: 1 },
          }}
        >
          <Box
            sx={{
              justifyContent: "center",
              display: "flex",
              pt: 6,
              pb: 1,
              backgroundColor: "#fff",
            }}
          >
            <img src="/LogoRegister.png" width="230px" />
          </Box>
          <Box sx={{ backgroundColor: "#fff" }}>
            {isSubmitted === false ? (
              <Box
                sx={{
                  backgroundColor: "#fff",
                  p: 2,
                  paddingBottom: "0px!important",
                  "& > :not(style)": { m: 1 },
                }}
                noValidate
                autoComplete="off"
              >
                <Box sx={{ background: "#F7F6F5", mb: "10px!important" }}>
                  <Typography variant="body2" sx={{ p: 2, fontSize: "13px" }}>
                    {t("toRenewYourPasswordYouNeedToEnterYourEmailAddressRegisteredInYourOMegaAccount")} {t("alinkToRenewYourPasswordWillBeSentToYourRegisteredEmailAddress")}
                  </Typography>
                </Box>
                <FormGroup>
                  <Grid container spacing={2} sx={{ mb: 1 }}>
                    <Grid item xs={12} sm={12}>
                      <CustomLabel title={t("emailAddress")} />
                      <FormControl
                        variant="filled"
                        key={"email"}
                        sx={{ width: "100%" }}
                      >
                        <OutlinedInput
                          sx={{
                            height: "2.5em",
                            fontSize: 14,
                            WebkitTextFillColor: "#453834!important",
                          }}
                          value={email}
                          onChange={emailChange}
                          type="text"
                          className="setEmail"
                          style={{
                            background: "#F7F6F5",
                          }}
                        />
                        {updateErrors.email && (
                          <span className="error-message">
                            {updateErrors.email}
                          </span>
                        )}
                      </FormControl>
                    </Grid>
                  </Grid>
                </FormGroup>
              </Box>
            ) : (
              ""
            )}

            {isSuccess === true && isSubmitted === true ? (
              <Box
                sx={{ pb: 0, mt: 1.5, ml: "1.5em", mr: "1.5em  " }}
                display="block"
              >
                <Alert
                  severity="success"
                  sx={{
                    border: "2px solid",
                    borderColor: theme.palette.success.main,
                    fontSize: "14px",
                    fontWeight: "500",
                  }}
                >
                  {t("yourPasswordRenewalLinkHasBeenSentToYourEmailAddressRegisteredInOurSystem")}
                  {getLoadingText()}
                </Alert>
              </Box>
            ) : (
              ""
            )}
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                pb: 3,
                mt: 3,
                ml: "1.5em",
                mr: "1.5em  ",
              }}
            >
              <Box sx={{ flex: "1 1 auto" }} />
              {isSubmitted === false ? (
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                    width: "100%",
                  }}
                >
                  <Button
                    sx={{
                      boxShadow: "none!important",
                      border: "1px solid #ddd!important",
                    }}
                    onClick={() => {
                      navigate("/login");
                    }}
                    variant="contained"
                    className="filterReset"
                  >
                    {t("goBack")}
                  </Button>
                  <Button
                    variant="contained"
                    type="submit"
                    className="filterApply"
                    disabled={
                      email === "" || updateErrors.email || setIsLoaded === true
                        ? true
                        : false
                    }
                  >
                    {t("resetPassword")}
                  </Button>
                </Box>
              ) : (
                ""
              )}
            </Box>
          </Box>
        </Box>
      </Form>
    </Container>
  );
};

export default ForgetPassword;
