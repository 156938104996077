import React from "react";
import InputLabel from "@mui/material/InputLabel";
import { useTheme } from "@mui/material";

const CustomLabel = ({ title, isRequired, htmlFor, className }) => {
  const theme = useTheme();
  return (
    <InputLabel
      className={className}
      sx={{
        fontSize: "1.1rem",
        fontWeight: "500",
        color: theme.palette.text.main,
        mt: 1,
      }}
      shrink
      htmlFor={htmlFor}
    >
      {title}
      {isRequired && (
        <span
          style={{
            color: theme.palette.primary.primaryRed,
            marginLeft: "0.5rem",
          }}
        >
          *
        </span>
      )}
    </InputLabel>
  );
};

export default CustomLabel;
