import React from "react";

const ProductIconActive = () => {
  return (
    <svg
      width="36"
      height="36"
      viewBox="0 0 36 36"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="36" height="36" rx="5" fill="#FEF7F5" />
      <g clipPath="url(#clip0_65_1572)">
        <path
          d="M29 8.5075V14.7993C29.0001 15.5992 28.6825 16.3664 28.1171 16.9321L16.4918 28.5585C16.2089 28.8413 15.8253 29.0001 15.4254 29.0001C15.0254 29.0001 14.6418 28.8413 14.359 28.5585L7.44167 21.6402C7.15889 21.3573 7.00004 20.9737 7.00004 20.5738C7.00004 20.1738 7.15889 19.7902 7.44167 19.5074L19.067 7.88203C19.6328 7.31662 20.4 6.99905 21.1998 6.99915H27.4917C27.8917 6.99915 28.2753 7.15806 28.5582 7.44093C28.8411 7.7238 29 8.10746 29 8.5075Z"
          fill="#EB603A"
          fillOpacity="0.3"
          stroke="#EB603A"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M23.7207 13.7867C22.8876 13.7867 22.2123 13.1114 22.2123 12.2784C22.2123 11.4453 22.8876 10.77 23.7207 10.77C24.5537 10.77 25.229 11.4453 25.229 12.2784C25.229 13.1114 24.5537 13.7867 23.7207 13.7867Z"
          fill="#FEF7F5"
          stroke="#EB603A"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_65_1572">
          <rect
            width="24"
            height="24"
            fill="white"
            transform="matrix(-1 0 0 1 30 6)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export default ProductIconActive;
