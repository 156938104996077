import React, { useRef } from "react";
import { Box, Typography, IconButton } from "@mui/material";
import AliceCarousel from "react-alice-carousel";
import "react-alice-carousel/lib/alice-carousel.css";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import YouTube from "react-youtube";

const LoginSlider = () => {
  const carouselRef = useRef(null);
  const slidePrev = () => {
    if (carouselRef.current) {
      carouselRef.current.slidePrev();
    }
  };

  const slideNext = () => {
    if (carouselRef.current) {
      carouselRef.current.slideNext();
    }
  };

  const FirstSlider = () => {
    return (
      <Box
        sx={{
          width: "100%",
        }}
        data-value="0"
      >
        {/* Title */}
        {/* Subtitle */}
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            width: "90%",
            margin: "auto",
            mt: "2em",
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "space-around",
              width: "100%",
            }}
          >
            {/* <IconButton onClick={slidePrev}>
              <ArrowBackIosIcon />
            </IconButton> */}
            <Box
              sx={{
                width: "100%",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Typography
                variant="body1"
                component="h2"
                sx={{
                  color: "#453834",
                  fontWeight: "600",
                  textAlign: "center",
                }}
              >
                1: Başvuru
              </Typography>
              <Typography
                variant="body1"
                component="h3"
                sx={{
                  color: "#453834",
                  fontWeight: "500",
                  textAlign: "center",
                  height: "100px",
                  width: "80%",
                  pt: "2em",
                }}
              >
                E-İhracata Hazırlık Analiz Formunu doldurup kayıt olun ve Omega
                Paneliniz açılsın.
              </Typography>
            </Box>
            {/* <IconButton onClick={slideNext}>
              <ArrowForwardIosIcon />
            </IconButton> */}
          </Box>
        </Box>
        {/* Icon */}
        <Box
          sx={{
            mt: "2em",
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "space-between",
            width: "100%",
          }}
        >
          <Box
            sx={{
              marginLeft: "-60px",
              width: "124px",
            }}
          ></Box>
          <img src="/logos/1.png" alt="logo" />
          <img src="/logos/1-next.png" alt="logo" />
        </Box>
      </Box>
    );
  };
  const SecondSlider = () => {
    return (
      <Box
        sx={{
          width: "100%",
        }}
        data-value="1"
      >
        {/* Title */}
        {/* Subtitle */}
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            width: "90%",
            margin: "auto",
            mt: "2em",
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "space-around",
              width: "100%",
            }}
          >
            {/* <IconButton onClick={slidePrev}>
              <ArrowBackIosIcon />
            </IconButton> */}
            <Box
              sx={{
                width: "100%",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Typography
                variant="body1"
                component="h2"
                sx={{
                  color: "#453834",
                  fontWeight: "600",
                  textAlign: "center",
                }}
              >
                2: Ürün Kayıt
              </Typography>
              <Typography
                variant="body1"
                component="h3"
                sx={{
                  color: "#453834",
                  fontWeight: "500",
                  textAlign: "center",
                  height: "100px",
                  width: "80%",
                  pt: "2em",
                }}
              >
                Satışa sunmak istediğiniz, güvendiğiniz ürünleri "Basit Ürün
                Kayıt Formu" ile kolayca yükleyin.
              </Typography>
            </Box>
            {/* <IconButton onClick={slideNext}>
              <ArrowForwardIosIcon />
            </IconButton> */}
          </Box>
        </Box>
        {/* Icon */}
        <Box
          sx={{
            mt: "2em",
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "space-between",
            width: "100%",
          }}
        >
          <img src="/logos/2-back.png" alt="logo" />
          <img src="/logos/2.png" alt="logo" />
          <img src="/logos/2-next.png" alt="logo" />
        </Box>
      </Box>
    );
  };
  const ThirdSlider = () => {
    return (
      <Box
        sx={{
          width: "100%",
        }}
        data-value="2"
      >
        {/* Title */}
        {/* Subtitle */}
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            width: "90%",
            margin: "auto",
            mt: "2em",
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "space-around",
              width: "100%",
            }}
          >
            {/* <IconButton onClick={slidePrev}>
              <ArrowBackIosIcon />
            </IconButton> */}
            <Box
              sx={{
                width: "100%",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Typography
                variant="body1"
                component="h2"
                sx={{
                  color: "#453834",
                  fontWeight: "600",
                  textAlign: "center",
                }}
              >
                3: Pazar Analizi
              </Typography>
              <Typography
                variant="body1"
                component="h3"
                sx={{
                  color: "#453834",
                  fontWeight: "500",
                  textAlign: "center",
                  height: "100px",
                  width: "80%",
                  pt: "2em",
                }}
              >
                Pazar analizini yapalım ve birlikte ticari koşulları
                belirleyelim.
              </Typography>
            </Box>
            {/* <IconButton onClick={slideNext}>
              <ArrowForwardIosIcon />
            </IconButton> */}
          </Box>
        </Box>
        {/* Icon */}
        <Box
          sx={{
            mt: "2em",
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "space-between",
            width: "100%",
          }}
        >
          <img src="/logos/3-back.png" alt="logo" />
          <img src="/logos/3.png" alt="logo" />
          <img src="/logos/3-next.png" alt="logo" />
        </Box>
      </Box>
    );
  };
  const FourthSlider = () => {
    return (
      <Box
        sx={{
          width: "100%",
        }}
        data-value="3"
      >
        {/* Title */}
        {/* Subtitle */}
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            width: "90%",
            margin: "auto",
            mt: "2em",
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "space-around",
              width: "100%",
            }}
          >
            {/* <IconButton onClick={slidePrev}>
              <ArrowBackIosIcon />
            </IconButton> */}
            <Box
              sx={{
                width: "100%",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Typography
                variant="body1"
                component="h2"
                sx={{
                  color: "#453834",
                  fontWeight: "600",
                  textAlign: "center",
                }}
              >
                4: Evrak Süreci
              </Typography>
              <Typography
                variant="body1"
                component="h3"
                sx={{
                  color: "#453834",
                  fontWeight: "500",
                  textAlign: "center",
                  height: "100px",
                  width: "80%",
                  pt: "2em",
                }}
              >
                Gerekli evrakları yükleyin, sözleşmenizi oluşturalım, panelinize
                ekleyelim.
              </Typography>
            </Box>
            {/* <IconButton onClick={slideNext}>
              <ArrowForwardIosIcon />
            </IconButton> */}
          </Box>
        </Box>
        {/* Icon */}
        <Box
          sx={{
            mt: "2em",
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "space-between",
            width: "100%",
          }}
        >
          <img src="/logos/4-back.png" alt="logo" />
          <img src="/logos/4.png" alt="logo" />
          <img src="/logos/4-next.png" alt="logo" />
        </Box>
      </Box>
    );
  };
  const FifthSlider = () => {
    return (
      <Box
        sx={{
          width: "100%",
        }}
        data-value="4"
      >
        {/* Title */}
        {/* Subtitle */}
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            width: "90%",
            margin: "auto",
            mt: "2em",
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "space-around",
              width: "100%",
            }}
          >
            {/* <IconButton onClick={slidePrev}>
              <ArrowBackIosIcon />
            </IconButton> */}
            <Box
              sx={{
                width: "100%",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Typography
                variant="body1"
                component="h2"
                sx={{
                  color: "#453834",
                  fontWeight: "600",
                  textAlign: "center",
                }}
              >
                5: Lansman
              </Typography>
              <Typography
                variant="body1"
                component="h3"
                sx={{
                  color: "#453834",
                  fontWeight: "500",
                  textAlign: "center",
                  height: "100px",
                  width: "80%",
                  pt: "2em",
                }}
              >
                Sözleşme imzasını takiben lansman süreciniz başlasın!
              </Typography>
            </Box>
            {/* <IconButton onClick={slideNext}>
              <ArrowForwardIosIcon />
            </IconButton> */}
          </Box>
        </Box>
        {/* Icon */}
        <Box
          sx={{
            mt: "2em",
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "space-between",
            width: "100%",
          }}
        >
          <img src="/logos/5-back.png" alt="logo" />
          <img src="/logos/5.png" alt="logo" />
          <Box
            sx={{
              width: "60px",
            }}
          ></Box>
        </Box>
      </Box>
    );
  };

  const SixthSlider = () => {
    return (
      <Box
        sx={{
          width: "100%",
        }}
        data-value="4"
      >
        {/* Title */}
        {/* Subtitle */}
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            width: "90%",
            margin: "auto",
            mt: "2em",
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "space-around",
              width: "100%",
            }}
          >
            {/* <IconButton onClick={slidePrev}>
              <ArrowBackIosIcon />
            </IconButton> */}
            <Box
              sx={{
                width: "100%",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Typography
                variant="body1"
                component="h2"
                sx={{
                  color: "#453834",
                  fontWeight: "600",
                  textAlign: "center",
                }}
              >
                MegaMerchant B2B Satıcı Portalı OMEGA ile Tanışın !
              </Typography>

              <Box sx={{ width: "500px", textAlign: "center" }} mt={4}>
                <YouTube
                  videoId="3M6LrSkC_O0"
                  opts={{ width: "75%", playerVars: { autoplay: 1 } }}
                />
              </Box>
            </Box>
            {/* <IconButton onClick={slideNext}>
              <ArrowForwardIosIcon />
            </IconButton> */}
          </Box>
        </Box>
        {/* Icon */}
      </Box>
    );
  };

  const items = [
    //<SixthSlider />,
    <FirstSlider />,
    <SecondSlider />,
    <ThirdSlider />,
    <FourthSlider />,
    <FifthSlider />,
  ];

  return (
    <Box
      className="login-slider"
      sx={{
        width: "100%",
        height: "100%",
        display: "flex",
        flexDirection: "column",
        justifyContent: "flex-start",
        alignItems: "center",
      }}
    >
      <Box
        sx={{
          width: "100%",
          height: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "column",
        }}
      >
        <Typography
          variant="h1"
          component="h1"
          sx={{
            color: "#D7283E",
            fontWeight: "700",
            textAlign: "center",
            mb: "2rem",
          }}
        >
          MegaMerchant ile Dünyaya Açılın!
        </Typography>
        <AliceCarousel
          ref={carouselRef}
          disableButtonsControls
          autoPlayInterval={5000}
          infinite
          autoPlay
          items={items}
        />
        <Box
          sx={{
            mt: "2em",
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "space-between",
            width: "100%",
            position: "relative",
            top: "-18.5rem",
          }}
        >
          <IconButton
            sx={{
              marginLeft: "1rem",
            }}
            onClick={slidePrev}
          >
            <ArrowBackIosIcon />
          </IconButton>
          <IconButton
            sx={{
              marginRight: "1rem",
            }}
            onClick={slideNext}
          >
            <ArrowForwardIosIcon />
          </IconButton>
        </Box>

        <Box
          sx={{
            width: "100%",
            marginTop: "-80px",
          }}
        >
          <img
            src="/logos/yol.png"
            alt="logo"
            style={{
              width: "100%",
            }}
          />
        </Box>
        {/* <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-around",
          alignItems: "center",
          gap: "1rem",
          width: "90%",
          flexWrap: "wrap",
          mb: "2rem",
          mt: "2rem",
        }}
      >
        <img
          src="/login-logos/amazon_logo.png"
          alt="logo"
          style={{
            width: "50px",
          }}
        />
        <img
          src="/login-logos/walmart_logo.svg.png"
          alt="logo"
          style={{
            width: "50px",
          }}
        />
        <img
          src="/login-logos/ebay_logo.png"
          alt="logo"
          style={{
            width: "50px",
          }}
        />
        <img
          src="/login-logos/etsy_logo.png"
          alt="logo"
          style={{
            width: "50px",
          }}
        />
        <img
          src="/login-logos/otto_logo.png"
          alt="logo"
          style={{
            width: "50px",
          }}
        />
        <img
          src="/login-logos/joom_logo.png"
          alt="logo"
          style={{
            width: "50px",
          }}
        />
        <img
          src="/login-logos/shopify_logo.png"
          alt="logo"
          style={{
            width: "50px",
          }}
        />

        <img
          src="/login-logos/zalando_logo.png"
          alt="logo"
          style={{
            width: "50px",
          }}
        />

        <img
          src="/login-logos/allegro_logo.png"
          alt="logo"
          style={{
            width: "50px",
          }}
        />

        <img
          src="/login-logos/ozon_logo.png"
          alt="logo"
          style={{
            width: "50px",
          }}
        />
        <img
          src="/login-logos/AliExpress_logo.png"
          alt="logo"
          style={{
            width: "50px",
          }}
        />
      </Box> */}
      </Box>
      <Box
        sx={{
          width: "100%",
          backgroundColor: "#f7f6f560",
          padding: "1rem 0",
        }}
      >
        <img
          src="/logos/brands.png"
          alt="logo"
          style={{
            width: "100%",
          }}
        />
      </Box>
    </Box>
  );
};

export default LoginSlider;
