import apiService from "./apiService";
import {
  UPDATE_IYZICO_PACKAGE,
  DELETE_IYZICO_PACKAGE,
  CREATE_IYZICO_PACKAGE,
  GET_IYZICO_PACKAGES,
  GET_IYZICO_PACKAGE,
  PUT_CONTROLLER_UNSUBSCRIBE,
  POST_IYZIPAY,
  POST_IYZIPAY_CHECKOUT_DETAIL,
  POST_CREATE_MERCHANT_PAYMENT,
} from "./endpoints.js";

const getIyzicoPackages = () =>
  apiService({ endpoint: GET_IYZICO_PACKAGES, method: "GET" });

const getIyzicoPackage = ({ id }) =>
  apiService({
    endpoint: GET_IYZICO_PACKAGE.replace("{id}", id),
    method: "GET",
  });

const updateIyzicoPackage = ({ id, body }) =>
  apiService({
    endpoint: UPDATE_IYZICO_PACKAGE.replace("{id}", id),
    method: "PUT",
    body,
  });

const deleteIyzicoPackage = ({ id }) =>
  apiService({
    endpoint: DELETE_IYZICO_PACKAGE.replace("{id}", id),
    method: "DELETE",
  });

const createIyzicoPackage = ({ body }) =>
  apiService({ endpoint: CREATE_IYZICO_PACKAGE, method: "POST", body });

const postIyzipay = ({ query }) =>
  apiService({ endpoint: POST_IYZIPAY, method: "POST", query });

const postIyzipayCheckoutDetail = ({ body }) =>
  apiService({ endpoint: POST_IYZIPAY_CHECKOUT_DETAIL, method: "POST", body });

const createMerchantPayment = ({ body, id }) =>
  apiService({
    endpoint: POST_CREATE_MERCHANT_PAYMENT.replace("{id}", id),
    method: "POST",
    body,
  });

const putControllerUnsubscribe = ({ body }) =>
  apiService({
    endpoint: PUT_CONTROLLER_UNSUBSCRIBE,
    method: "PUT",
    body,
  });

export {
  postIyzipay,
  getIyzicoPackages,
  getIyzicoPackage,
  createIyzicoPackage,
  updateIyzicoPackage,
  deleteIyzicoPackage,
  putControllerUnsubscribe,
  createMerchantPayment,
  postIyzipayCheckoutDetail,
};
