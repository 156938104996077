import Topbar from "./global/Topbar";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import CustomLabel from "../components/forms/form-elements/CustomLabel";
import { alpha, styled } from "@mui/material/styles";
import InputBase from "@mui/material/InputBase";
import { useTheme } from "@emotion/react";
import { useTranslation } from "react-i18next";
import {
  Box,
  Typography,
  Grid,
  Button,
  Divider,
  IconButton,
  RadioGroup,
  Radio,
} from "@mui/material";
import axios from "axios";
import React, { useState, useEffect } from "react";
import Cookies from "js-cookie";

const BootstrapInput = styled(InputBase)(({ theme }) => ({
  "label + &": {
    marginTop: theme.spacing(3),
    color: `${alpha(theme.palette.text.main, 0.25)} 0 0 0 0.2rem`,
  },
  "& .MuiInputBase-input": {
    borderRadius: 2,
    position: "relative",
    border: "1px solid",
    fontSize: 14,
    fontStyle: "italic",
    height: "12px",
    width: "100%",
    padding: "10px 12px",
    marginTop: "0.5rem",
    borderColor: theme.palette.primary.bordersColor,
    transition: theme.transitions.create([
      "border-color",
      "background-color",
      "box-shadow",
    ]),
    // "&:focus": {
    //   boxShadow: `${alpha(theme.palette.primary.main, 0.25)} 0 0 0 0.2rem`,
    //   borderColor: theme.palette.primary.main,
    //   color: theme.palette.text.main,
    // },
    // "& .MuiFormControlLabel-root": {
    //   fontFamily: "Roboto, sans-serif",
    //   fontWeight: "500",
    // },
  },
}));
const ProductFeatures = ({
  length,
  height,
  weight,
  width,
  handleWidth,
  handleWeight,
  handleHeight,
  handleLength,
  isFlammableOrExplosive,
  setIsFlammableOrExplosive,
  handleIsFlammableOrExplosive,
}) => {
  const theme = useTheme();
  const { t } = useTranslation();
  
  return (
    <Box
      sx={{
        marginTop: "15px",
        p: 2,
        border: "1px solid rgba(69, 56, 52, 0.15)",
        borderRadius: "2px",
      }}
    >
      <Typography variant="text" color="primary" sx={{ fontWeight: "bold" }}>
        {t("packageSizeSpecifications")}
      </Typography>
      <Box
        variant="text"
        sx={{
          fontSize: "11px",
          fontFamily: "Roboto",
          marginTop: "5px",
        }}
      >
        {/* Nullam id dolor id nibh ultricies vehicula ut id elit. */}
      </Box>
      <Divider sx={{ marginTop: "10px" }} />

      {/* Boyut ve Agirlik */}
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          gap: "10px",
          mt: 1,
        }}
      >
        <FormControl variant="standard" key="dimensions">
          <CustomLabel title={t("dimensions") + "(cm)"} isRequired />
          <BootstrapInput
            placeholder={t("width")}
            type="number"
            onChange={(e) => handleWidth(e)}
            value={width}
          />
        </FormControl>
        <FormControl variant="standard" key="length">
          <CustomLabel title="" />
          <BootstrapInput
            placeholder={t("lenght")}
            type="number"
            onChange={(e) => handleLength(e)}
            value={length}
          />
        </FormControl>
        <FormControl variant="standard" key="height">
          <CustomLabel title="" />
          <BootstrapInput
            placeholder={t("height")}
            type="number"
            onChange={(e) => handleHeight(e)}
            value={height}
          />
        </FormControl>
        <FormControl variant="standard" key="weight">
          <CustomLabel title={t("weightGr")} isRequired />
          <BootstrapInput
            type="number"
            placeholder={t("weight")}
            onChange={(e) => handleWeight(e)}
            value={weight}
          />
        </FormControl>
      </Box>

      <Box>
        <FormControl variant="standard" key="isFlammableOrExplosive">
          <CustomLabel title={t("flammableOrExplosiveProduct")} isRequired />
          <RadioGroup
            sx={{ pt: "35px", ml: "10px" }}
            row
            aria-labelledby="demo-row-radio-buttons-group-label"
            name="row-radio-buttons-group"
            value={isFlammableOrExplosive}
            onChange={
              handleIsFlammableOrExplosive
              // (e) => setIsFlammableOrExplosive(e.target.value)
            }
          >
            <FormControlLabel
              color="success"
              className={
                isFlammableOrExplosive === true
                  ? "custom-radio-checked"
                  : "custom-radio"
              }
              value={true}
              control={
                <Radio
                  sx={{
                    color: "#45383426",
                    "&.Mui-checked": {
                      color: "#34AE1A",
                    },
                  }}
                />
              }
              label={
                <Typography
                  fontWeight="500"
                  color={
                    isFlammableOrExplosive === true
                      ? theme.palette.success.main
                      : "#453834"
                  }
                  fontSize="14px"
                >
                  {t("yes")}
                </Typography>
              }
            />
            <FormControlLabel
              color="success"
              className={
                isFlammableOrExplosive === false
                  ? "custom-radio-checked"
                  : "custom-radio"
              }
              value={false}
              control={
                <Radio
                  sx={{
                    color: "#45383426",
                    "&.Mui-checked": {
                      color: "#34AE1A",
                    },
                  }}
                />
              }
              label={
                <Typography
                  fontWeight="500"
                  color={
                    isFlammableOrExplosive === false
                      ? theme.palette.success.main
                      : "#453834"
                  }
                  fontSize="14px"
                >
                  {t("no")}
                </Typography>
              }
            />
          </RadioGroup>
        </FormControl>
      </Box>
    </Box>
  );
};

export default ProductFeatures;
