import React from "react";

const LoginSSSIcon = () => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_2848_2753)">
        <path
          d="M19.9314 10C19.9314 11.9641 19.349 13.8841 18.2578 15.5171C17.1666 17.1502 15.6157 18.423 13.8011 19.1747C11.9866 19.9263 9.98986 20.1229 8.06352 19.7398C6.13718 19.3566 4.36772 18.4108 2.97891 17.022C1.5901 15.6332 0.644302 13.8637 0.26113 11.9374C-0.122043 10.011 0.0746153 8.01433 0.826236 6.19976C1.57786 4.38518 2.85068 2.83424 4.48375 1.74306C6.11682 0.651875 8.03679 0.069458 10.0009 0.069458C12.6338 0.0722384 15.158 1.11938 17.0198 2.98112C18.8815 4.84286 19.9286 7.36712 19.9314 10ZM18.4037 10C18.4037 8.3381 17.9108 6.71351 16.9875 5.33168C16.0642 3.94985 14.7519 2.87285 13.2165 2.23686C11.6811 1.60087 9.99155 1.43447 8.36157 1.75869C6.73159 2.08292 5.23436 2.8832 4.05921 4.05835C2.88406 5.2335 2.08377 6.73073 1.75955 8.36071C1.43533 9.99069 1.60173 11.6802 2.23772 13.2156C2.8737 14.751 3.95071 16.0634 5.33254 16.9867C6.71437 17.91 8.33896 18.4028 10.0009 18.4028C12.2287 18.4003 14.3645 17.5142 15.9397 15.9389C17.515 14.3636 18.4011 12.2278 18.4037 10Z"
          fill="#EB603A"
        />
        <path
          d="M10.4609 12.7969H9.00781C9.01302 12.2969 9.05729 11.888 9.14062 11.5703C9.22917 11.2474 9.3724 10.9531 9.57031 10.6875C9.76823 10.4219 10.0312 10.1198 10.3594 9.78125C10.599 9.53646 10.8177 9.30729 11.0156 9.09375C11.2188 8.875 11.3828 8.64062 11.5078 8.39062C11.6328 8.13542 11.6953 7.83073 11.6953 7.47656C11.6953 7.11719 11.6302 6.80729 11.5 6.54688C11.375 6.28646 11.1875 6.08594 10.9375 5.94531C10.6927 5.80469 10.388 5.73438 10.0234 5.73438C9.72135 5.73438 9.4349 5.78906 9.16406 5.89844C8.89323 6.00781 8.67448 6.17708 8.50781 6.40625C8.34115 6.63021 8.25521 6.92448 8.25 7.28906H6.80469C6.8151 6.70052 6.96094 6.19531 7.24219 5.77344C7.52865 5.35156 7.91406 5.02865 8.39844 4.80469C8.88281 4.58073 9.42448 4.46875 10.0234 4.46875C10.6849 4.46875 11.2474 4.58854 11.7109 4.82812C12.1797 5.06771 12.5365 5.41146 12.7812 5.85938C13.026 6.30208 13.1484 6.82812 13.1484 7.4375C13.1484 7.90625 13.0521 8.33854 12.8594 8.73438C12.6719 9.125 12.4297 9.49219 12.1328 9.83594C11.8359 10.1797 11.5208 10.5078 11.1875 10.8203C10.901 11.0859 10.7083 11.3854 10.6094 11.7188C10.5104 12.0521 10.4609 12.4115 10.4609 12.7969ZM8.94531 15.2734C8.94531 15.0391 9.01823 14.8411 9.16406 14.6797C9.3099 14.5182 9.52083 14.4375 9.79688 14.4375C10.0781 14.4375 10.2917 14.5182 10.4375 14.6797C10.5833 14.8411 10.6562 15.0391 10.6562 15.2734C10.6562 15.4974 10.5833 15.6901 10.4375 15.8516C10.2917 16.013 10.0781 16.0938 9.79688 16.0938C9.52083 16.0938 9.3099 16.013 9.16406 15.8516C9.01823 15.6901 8.94531 15.4974 8.94531 15.2734Z"
          fill="#EB603A"
        />
      </g>
      <defs>
        <clipPath id="clip0_2848_2753">
          <rect width="20" height="20" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default LoginSSSIcon;
