import React from "react";
import { Box, Typography, Select, InputBase } from "@mui/material";
import Topbar from "./global/Topbar";
import { useTheme } from "@emotion/react";
import { useAuthUser } from "react-auth-kit";
import { alpha, styled } from "@mui/material/styles";
import { FormGroup, MenuItem, FormControl } from "@mui/material";
import Snackbar from "@mui/material/Snackbar";
import Alert from "@mui/material/Alert";
import axios from "axios";
import Cookies from "js-cookie";
import { useTranslation } from "react-i18next";

const Looker = () => {
  const theme = useTheme();
  const user = useAuthUser();
  const { t } = useTranslation();
  const [reportUrl, setReportUrl] = React.useState([]);
  const merchantId = user().merchantId;
  const [selected, setSelected] = React.useState("null");
  const [error, setError] = React.useState("");
  const [open, setOpen] = React.useState(false);

  const BootstrapSelect = styled(InputBase)(({ theme }) => ({
    "label + &": {
      marginTop: theme.spacing(3),
      color: `${alpha(theme.palette.text.main, 0.25)} 0 0 0 0.2rem`,
    },
    "& .MuiInputBase-input": {
      backgroundColor: theme.palette.primary.white,
      borderRadius: 2,
      position: "relative",
      border: "1px solid",
      fontSize: 14,
      fontStyle: "italic",
      height: "4px!important",
      width: "100%",
      padding: "10px 12px",
      lineHeight: "1",
      marginTop: "0.5rem",
      borderColor: theme.palette.primary.bordersColor,
      transition: theme.transitions.create([
        "border-color",
        "background-color",
        "box-shadow",
      ]),
      minHeight: " 0.8rem!important",
      "&:focus": {
        boxShadow: `${alpha(theme.palette.primary.main, 0.25)} 0 0 0 0.2rem`,
        borderColor: theme.palette.primary.main,
        color: theme.palette.text.main,
      },
    },
  }));

  let data = "";
  let config = {
    method: "get",
    maxBodyLength: Infinity,
    url: `${process.env.REACT_APP_BASE_URL}/Merchant/GetMerchantDashboardLinkByMerchantId/${merchantId}`,
    headers: {
      Authorization: `Bearer ${Cookies.get("_auth")}`,
    },
    data: data,
  };

  const getReports = async () => {
    //get reports from api, if error show error message with snackbar and alert
    try {
      const response = await axios(config);
      setReportUrl(response.data.items);
      console.log("response", response.data);
    } catch (error) {
      if (error.response.status === 401) {
        setError(t("youAreNotAuthorized"));
      } else {
        setError(t("thereWasAnErrorFetchingReportsPleaseTryAgain"));
      }
      setOpen(true);
    }
  };

  React.useEffect(() => {
    getReports();
  }, []);

  return (
    <Box m={"20px"}>
      <Topbar title={t("reports")} />

      {error && (
        <Snackbar
          open={open}
          autoHideDuration={6000}
          onClose={() => setOpen(false)}
        >
          <Alert
            onClose={() => setOpen(false)}
            severity="error"
            sx={{ width: "100%" }}
          >
            {error}
          </Alert>
        </Snackbar>
      )}

      {/* {selected.length > 0  && ( */}
      {reportUrl && (
        <Box sx={{ background: theme.palette.primary.white }} p={5}>
          {reportUrl.length > 0 ? (
            <Box
              sx={{
                width: "560px",
                border: `1px solid ${theme.palette.primary.bordersColor}`,
                borderRadius: 2,
                padding: "20px",
                background: "#F7F6F5",
              }}
            >
              <Typography
                variant="h4"
                sx={{ color: theme.palette.primary.text, fontWeight: "500" }}
              >
                {t("selectTheBrandYearMatchWhoseReportsYouWantToReview")}
              </Typography>

              <FormGroup>
                <FormControl
                  variant="standard"
                  className="personelSelect"
                  sx={{ width: "300px" }}
                >
                  <Select
                    labelId="demo-multiple-2-checkbox-label"
                    id="demo-multiple-2-checkbox"
                    displayEmpty
                    input={<BootstrapSelect />}
                    // select object
                    value={selected}
                    onChange={e => setSelected(e.target.value)}
                    renderValue={selected => {
                      if (selected === "null") {
                        return t("selectReport");
                      } else {
                        return `${selected.brand} - ${selected.year}`;
                      }
                    }}
                  >
                    {reportUrl.map((item, index) => (
                      <MenuItem key={index} value={item}>
                        {item.brand} - {item.year}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </FormGroup>
            </Box>
          ) : (
            <Box
              sx={{
                width: "560px",
                border: `1px solid ${theme.palette.primary.bordersColor}`,
                borderRadius: 2,
                padding: "20px",
                background: "#F7F6F5",
              }}
            >
              <Typography
                variant="h4"
                sx={{ color: theme.palette.primary.text, fontWeight: "500" }}
              >
               {t("youDoNotHaveAReportYet")}
              </Typography>
            </Box>
          )}
          {selected && (
            <Box mt={2} sx={{ height: "100%" }}>
              <iframe
                width="100%"
                height="1200px"
                src={selected.dashBoardUrl}
                frameborder="0"
                style={{ border: "none" }}
                allowfullscreen
                sandbox="allow-storage-access-by-user-activation allow-scripts allow-same-origin allow-popups allow-popups-to-escape-sandbox"
              ></iframe>
            </Box>
          )}
        </Box>
      )}
    </Box>
  );
};

export default Looker;
