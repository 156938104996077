import {
  Box,
  Grid,
  Stack,
  Typography,
  Button,
  FormControl,
  FormGroup,
  useTheme,
  CircularProgress,
  Container,
  OutlinedInput,
  Alert,
} from "@mui/material";
import React, { useState, useEffect } from "react";
import axios, { AxiosError } from "axios";
import CustomLabel from "../components/forms/form-elements/CustomLabel";
import CheckIcon from "@mui/icons-material/Check";
import { Form } from "react-bootstrap";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import InputAdornment from "@mui/material/InputAdornment";
import IconButton from "@mui/material/IconButton";
import { useLocation, useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { useSignIn } from "react-auth-kit";
import { useTranslation } from "react-i18next";

const ResetPassword = () => {
  const params = window.location.search;
  const signIn = useSignIn();
  const navigate = useNavigate();
  const theme = useTheme();
  const { t } = useTranslation();
  const [dots, setDots] = useState(1);
  const [activeStep, setActiveStep] = useState(0);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [skipped, setSkipped] = useState(new Set());
  const [isLoaded, setIsLoaded] = useState(false);
  const [error, setError] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [isSuccess, setIsSuccess] = useState(false); // [1
  const [passwordConfirm, setPasswordConfirm] = useState("");
  const [requestError, setRequestError] = useState(null);
  const [showPassword, setShowPassword] = React.useState(false);
  const [updateErrors, setUpdateErrors] = useState({
    email: "",
    password: "",
    passwordConfirm: "",
  });

  const urlParams = new URLSearchParams(params);
  const emailParam = urlParams.get("email").replace(/ /g, "+");

  const getLoadingText = () => {
    const dotString = ".".repeat(dots);
    return `${dotString}`;
  };

  const validateFields = (fieldName, value) => {
    const newErrors = { ...updateErrors };

    if (fieldName === "email") {
      newErrors.email = !value.match(/^\S+@\S+\.\S+$/)
        ? t("enterAvalidEmailAddress")
        : "";
    }

    if (fieldName === "password") {
      const minLength = 8;
      const hasUpperCase = /[A-Z]/.test(value);
      const hasLowerCase = /[a-z]/.test(value);
      const hasDigit = /\d/.test(value);

      if (value.length < minLength) {
        newErrors.password = t("passwordMustBeAtLeastEightcharacters");
      } else if (!hasUpperCase) {
        newErrors.password = t("thePasswordMustContainAtLeastOneUppercaseLetter");
      } else if (!hasLowerCase) {
        newErrors.password = t("thePasswordMustContainAtLeastOneLowercaseLetter");
      } else if (!hasDigit) {
        newErrors.password = t("thePasswordMustContainAtLeastOneNumber");
      } else {
        newErrors.password = "";
      }
    }

    if (fieldName === "passwordConfirm") {
      newErrors.passwordConfirm =
        value !== password ? t("passwordsDoNotMatch") : "";
    }
    setUpdateErrors(newErrors);
  };

  useEffect(() => {
    const intervalId = setInterval(() => {
      setDots(prevDots => (prevDots === 3 ? 0 : prevDots + 1));
    }, 500); // 500 milisaniyede bir deÄiÅiklik yapabilirsiniz.

    return () => clearInterval(intervalId);
  }, []);

  const emailChangeHandler = e => {
    setEmail(emailParam);
    validateFields("email", e.target.value);
  };

  const passwordChangeHandler = e => {
    setPassword(e.target.value);
    validateFields("password", e.target.value);
  };

  const passwordConfirmChangeHandler = e => {
    setPasswordConfirm(e.target.value);
    validateFields("passwordConfirm", e.target.value);
  };

  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const token = searchParams.get("token");
  const tokenEmail = searchParams.get("token");
  const encodedToken = encodeURIComponent(token)
    .replace(/%2B/g, "+")
    .replace(/%2F/g, "/")
    .replace(/%20/g, "+")
    .replace(/%3D/g, "=");

  const isStepSkipped = step => {
    return skipped.has(step);
  };

  const handlePaste = event => {
    event.preventDefault(); // YapÄ±ÅtÄ±rma iÅlemini engelle
    alert("Copy-paste yapılamaz.");
  };

  const handleClickShowPassword = () => setShowPassword(show => !show);

  const handleMouseDownPassword = event => {
    event.preventDefault();
  };

  const handleNext = () => {
    let newSkipped = skipped;
    if (isStepSkipped(activeStep)) {
      newSkipped = new Set(newSkipped.values());
      newSkipped.delete(activeStep);
    }

    setActiveStep(prevActiveStep => prevActiveStep + 1);
    setSkipped(newSkipped);
  };

  const handleBack = () => {
    setActiveStep(prevActiveStep => prevActiveStep - 1);
  };

  const formData = {
    token: encodedToken,
    email: emailParam,
    password: password,
  };

  const loginToDashboard = async values => {
    setError("");
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_BASE_URL}/controller/Login`,
        values,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      if (
        signIn({
          token: response.data.userInfo.token.accessToken,
          expiresIn: 899,
          tokenType: "Bearer",
          authState: {
            email: emailParam,
            role: response.data.userInfo.roleId,
            refreshToken: response.data.userInfo.token.refreshToken,
            merchantId: response.data.userInfo.merchantId,
            companyId: response.data.userInfo.companyId,
          },
        })
      ) {
        setTimeout(() => {
          navigate("/");
        }, 1000);
      }
    } catch (err) {
      if (err && err instanceof AxiosError) {
        if (err.response) {
          setError(err.response.data.message);
        }
      } else if (err && err instanceof Error) {
        setError(err.message);
      } else {
        console.log("Error: ", err);
      }
    }
  };

  const handleSubmit = async e => {
    e.preventDefault();
    setIsSubmitted(true);
    const response = await axios.post(
      `${process.env.REACT_APP_BASE_URL}/controller/set-password`,
      formData
    );

    if (response.status === 200 && response.data.succeeded === true) {
      setIsLoaded(true);
      setIsSuccess(true);
      setTimeout(() => {
        loginToDashboard({ usernameOrEmail: emailParam, password: password });
      }, 3000);
    }
  };

  return (
    <Container
      component="main"
      maxWidth="xs"
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        overflow: "auto",
        height: "100vh",
      }}
    >
      <Form method="post" onSubmit={handleSubmit} style={{ width: "100%" }}>
        {activeStep === 2 ? (
          <Box
            sx={{
              mb: 1,
              pb: 1,
              pt: 0,
              "& > :not(style)": { m: 1 },
            }}
          >
            {isLoaded === true ? (
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <CircularProgress />
              </Box>
            ) : (
              <Grid container spacing={2}>
                {requestError !== 400 ? (
                  <Grid item xs={12} sm={12}>
                    <Stack direction="row" alignItems="center" gap={1}>
                      <CheckIcon color="success" />
                      <Typography
                        variant="h6"
                        fontWeight="bold"
                        color={theme.palette.success.main}
                      >
                        {t("thankYouForYourApplicationRequest")}
                      </Typography>
                    </Stack>
                    <Typography>
                      {t("weHaveReceivedYourRegistrationFormWeWillContactYouAsSoonasPossible")}
                    </Typography>
                  </Grid>
                ) : (
                  <Grid item xs={12} sm={12}>
                    <Stack direction="row" alignItems="center" gap={1}>
                      <CheckIcon color="warning" />
                      <Typography
                        variant="h6"
                        fontWeight="bold"
                        color={theme.palette.primary.primaryRed}
                      >
                        {t("anErrorOccurred")}
                      </Typography>
                    </Stack>
                    <Typography>
                      {t("weDidNotReceiveYourRegistrationFormPleaseTryAgain")}
                    </Typography>
                  </Grid>
                )}
              </Grid>
            )}
          </Box>
        ) : (
          <React.Fragment>
            <Box
              sx={{
                justifyContent: "center",
                display: "flex",
                pt: 6,
                pb: 1,
                backgroundColor: "#fff",
              }}
            >
              <img src="/LogoRegister.png" width="230px" />
            </Box>
            <Box sx={{ backgroundColor: "#fff" }}>
              {activeStep === 0 && (
                <Box
                  sx={{
                    backgroundColor: "#fff",
                    p: 2,
                    paddingBottom: "0px!important",
                    "& > :not(style)": { m: 1 },
                  }}
                  noValidate
                  autoComplete="off"
                >
                  <Box sx={{ background: "#F7F6F5", mb: "10px!important" }}>
                    <Typography variant="body2" sx={{ p: 2, fontSize: "13px" }}>
                      {t("toCreateThePasswordForYourOMegaAccountYouMustEnterTheEmailAddressYouUsedDuringRegistration")}
                    </Typography>
                  </Box>
                  <FormGroup>
                    <Grid container spacing={2} sx={{ mb: 1 }}>
                      <Grid item xs={12} sm={12}>
                        <CustomLabel title={t("emailAddressUsedForRegistration")} />
                        <FormControl variant="filled" sx={{ width: "100%" }}>
                          <OutlinedInput
                            sx={{
                              height: "2.5em",
                              fontSize: 14,
                              WebkitTextFillColor: "#453834!important",
                            }}
                            value={emailParam}
                            onChange={emailChangeHandler}
                            type="text"
                            readOnly
                            inputProps={{
                              readOnly: true,
                            }}
                            className="setEmail"
                            style={{
                              background: "#F7F6F5",
                            }}
                          />
                          {updateErrors.email && (
                            <span className="error-message">
                              {updateErrors.email}
                            </span>
                          )}
                        </FormControl>
                      </Grid>
                    </Grid>
                  </FormGroup>
                </Box>
              )}
              {activeStep === 1 && (
                <Box
                  sx={{
                    backgroundColor: "#fff",
                    p: 2,
                    paddingBottom: "0px!important",
                    "& > :not(style)": { m: 1 },
                  }}
                  noValidate
                  autoComplete="off"
                >
                  <Box sx={{ background: "#F7F6F5", mb: "10px!important" }}>
                    <Typography variant="body2" sx={{ p: 2, fontSize: "13px" }}>
                      {t("pleaseSetYourPasswordAccordingToTheFollowingRules")}:
                      <ul style={{ paddingInlineStart: "10px" }}>
                        <li>{t("passwordMustBeAtLeastEightcharacters")}</li>
                        <li>{t("thePasswordMustContainAtLeastOneNumber")}</li>
                        <li>
                          {t("yourPasswordMustContainAtLeastOneUppercaseAndOneLowercaseLetter")}
                        </li>
                      </ul>
                    </Typography>
                  </Box>
                  <FormGroup>
                    <Grid item xs={12} sm={12}>
                      <CustomLabel title={t("newPassword")} />
                      <FormControl variant="filled" sx={{ width: "100%" }}>
                        <OutlinedInput
                          sx={{
                            height: "2.5em",
                            height: "2.5em",
                            borderColor: theme.palette.primary.bordersColor,
                            fontSize: 14,
                          }}
                          id="filled-adornment-password"
                          value={password}
                          onChange={passwordChangeHandler}
                          type={showPassword ? "text" : "password"}
                          endAdornment={
                            <InputAdornment position="end">
                              <IconButton
                                aria-label="toggle password visibility"
                                onClick={handleClickShowPassword}
                                onMouseDown={handleMouseDownPassword}
                                edge="end"
                              >
                                {showPassword ? (
                                  <VisibilityOff />
                                ) : (
                                  <Visibility />
                                )}
                              </IconButton>
                            </InputAdornment>
                          }
                        />
                        {updateErrors.password && (
                          <span className="error-message">
                            {updateErrors.password}
                          </span>
                        )}
                      </FormControl>
                      <CustomLabel title={t("newPasswordRepeat")} />
                      <FormControl variant="filled" sx={{ width: "100%" }}>
                        <OutlinedInput
                          sx={{
                            height: "2.5em",
                            height: "2.5em",
                            borderColor: theme.palette.primary.bordersColor,
                            fontSize: 14,
                          }}
                          id="filled-adornment-re-password"
                          value={passwordConfirm}
                          onPaste={handlePaste}
                          onChange={passwordConfirmChangeHandler}
                          type={showPassword ? "text" : "password"}
                          endAdornment={
                            <InputAdornment position="end">
                              <IconButton
                                aria-label="toggle password visibility"
                                onClick={handleClickShowPassword}
                                onMouseDown={handleMouseDownPassword}
                                edge="end"
                              >
                                {showPassword ? (
                                  <VisibilityOff />
                                ) : (
                                  <Visibility />
                                )}
                              </IconButton>
                            </InputAdornment>
                          }
                        />
                        {updateErrors.passwordConfirm && (
                          <span className="error-message">
                            {updateErrors.passwordConfirm}
                          </span>
                        )}
                      </FormControl>
                    </Grid>
                  </FormGroup>
                </Box>
              )}
              {isSuccess === true ? (
                <Box
                  sx={{ pb: 0, mt: 1.5, ml: "1.5em", mr: "1.5em  " }}
                  display="block"
                >
                  <Alert
                    severity="success"
                    sx={{
                      border: "2px solid",
                      borderColor: theme.palette.success.main,
                      fontSize: "14px",
                      fontWeight: "500",
                    }}
                  >
                    {t("yourPasswordhasBeenSaved")} {t("youAreBeingRedirected")}
                    {getLoadingText()}
                  </Alert>
                </Box>
              ) : (
                ""
              )}
              <Box>
                <Typography
                  variant="body2"
                  sx={{
                    pl: 3.2,
                    pr: 3,
                    fontSize: "13px",
                    color: theme.palette.primary.primaryGray,
                  }}
                >
                  {t("doYouAlreadyHaveAnAccount")}{" "}
                  <a
                    href="/login"
                    style={{
                      color: theme.palette.primary.main,
                      fontWeight: 600,
                    }}
                  >
                    {t("signIn")}
                  </a>
                </Typography>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  pb: 3,
                  mt: 3,
                  ml: "1.5em",
                  mr: "1.5em  ",
                }}
              >
                {activeStep !== 0 && (
                  <Button
                    color="inherit"
                    disabled={activeStep === 0}
                    onClick={handleBack}
                    sx={{ mr: 1 }}
                    className="filterReset"
                  >
                    {t("goBack")}
                  </Button>
                )}
                <Box sx={{ flex: "1 1 auto" }} />
                {activeStep === 0 && (
                  <Button
                    variant="contained"
                    onClick={handleNext}
                    className="filterApply"
                  >
                    {t("continue")}
                  </Button>
                )}
                {activeStep >= 1 && (
                  <Button
                    variant="contained"
                    type="submit"
                    className="filterApply"
                    disabled={
                      password === "" ||
                      passwordConfirm === "" ||
                      updateErrors.password !== "" ||
                      updateErrors.passwordConfirm !== "" ||
                      isSubmitted === true
                    }
                  >
                    {t("save")}
                  </Button>
                )}
              </Box>
            </Box>
          </React.Fragment>
        )}
      </Form>
    </Container>
  );
};

export default ResetPassword;
