import React, { useEffect, useState } from "react";

// Components
import { Container, Button, Box, Grid, Typography } from "@mui/material";
import EmailPreferences from "./EmailPreferences";
import BillingInfo from "./BillingInfo";
import AddressInfo from "./AddressInfo";
import { Modal } from "../../common/index";

// Utils
import { useTranslation } from "react-i18next";
import { useAuthUser } from "react-auth-kit";
import Cookies from "js-cookie";
import axios from "axios";

// Icons
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CancelIcon from "@mui/icons-material/Cancel";
import { useNavigate, useSearchParams } from "react-router-dom";

const SettingsForm = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const auth = useAuthUser();
  const merchantId = auth().merchantId;
  const [searchParams, setSearchParams] = useSearchParams();
  const comeFrom = searchParams.get("comeFrom") || "";
  const [userId, setUserId] = useState("");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [sumbitResult, setSubmitResult] = useState({
    success: null,
    message: "",
  });
  const [paymentDetailData, setPaymentDetailData] = useState({
    buyerName: "",
    buyerSurname: "",
    email: "",
    tckn: "",
    address: "",
    city: "",
    country: "",
    contactFullName: "",
    merchantId,
  });

  const onCleanHandler = () => {
    setPaymentDetailData((prevState) => ({
      buyerName: "",
      buyerSurname: "",
      email: "",
      tckn: "",
      address: "",
      city: "",
      country: "",
      contactFullName: "",
      merchantId,
    }));
  };

  const onSubmitHandler = async () => {
    if (Object.values(paymentDetailData).every(Boolean)) {
      const headers = {
        Authorization: `Bearer ${Cookies.get("_auth")}`,
      };

      // The id property will be available if in get method, the data was available.
      // so we can notice if we have to call create service or update service.
      if (userId) {
        const response = await axios.put(
          `${process.env.REACT_APP_BASE_URL}/Payment/UpdatePaymentDetail/${userId}`,
          paymentDetailData,
          {
            headers,
          }
        );

        setSubmitResult({
          success: response.status === 200,
          message:
            response.status === 200
              ? t("paymentInformationSubmitted")
              : t("paymentInformationNotSubmitted"),
        });
        setIsModalOpen(true);
      } else {
        const response = await axios.post(
          `${process.env.REACT_APP_BASE_URL}/Payment/CreatePaymentDetail`,
          paymentDetailData,
          {
            headers,
          }
        );
        setSubmitResult({
          success: response.status === 200,
          message:
            response.status === 200
              ? t("paymentInformationSubmitted")
              : t("paymentInformationNotSubmitted"),
        });
        setIsModalOpen(true);
      }
    }
  };

  useEffect(() => {
    const fetchPaymentDetail = async () => {
      const fetchedData = await axios.get(
        `${process.env.REACT_APP_BASE_URL}/Payment/GetPaymentDetailByMerchantId/${merchantId}`,
        {
          headers: {
            Authorization: `Bearer ${Cookies.get("_auth")}`,
          },
        }
      );

      if (fetchedData.status === 200 && fetchedData.data) {
        const { data } = fetchedData;
        if (data.paymentDetailInfo) {
          setPaymentDetailData(data.paymentDetailInfo);
          setUserId(data.paymentDetailInfo.id);
        }
      } else console.log("Something went wrong!");
    };
    fetchPaymentDetail();
  }, []);

  return (
    <Container maxWidth="100%">
      <Grid
        spacing={3}
        xs={12}
        sx={{ backgroundColor: "#fff", width: "100%", padding: "24px" }}
      >
        <Grid xs={6} maxWidth={"50%"} item>
          <Grid item xs={12} marginBottom="12px">
            <BillingInfo
              paymentDetailData={paymentDetailData}
              setPaymentDetailData={setPaymentDetailData}
            />
          </Grid>
          <Grid item xs={12}>
            <AddressInfo
              paymentDetailData={paymentDetailData}
              setPaymentDetailData={setPaymentDetailData}
            />
          </Grid>
          <Grid
            item
            xs={12}
            sx={{ display: "flex", justifyContent: "space-between" }}
          >
            <Button
              onClick={onCleanHandler}
              color="secondary"
              style={{
                backgroundColor: "#F7F6F5",
                color: "#453834",
                fontWeight: "500",
                fontSize: "14px",
                border: "1px solid #45383426",
              }}
            >
              {t("clear")}
            </Button>
            <Button
              onClick={onSubmitHandler}
              variant="contained"
              style={{
                backgroundColor: "#EB603A",
                fontSize: "14px",
                padding: "8px",
                cursor: `${
                  Object.values(paymentDetailData).every(Boolean)
                    ? "pointer"
                    : "not-allowed"
                }`,
                opacity: `${
                  Object.values(paymentDetailData).every(Boolean) ? "1" : "0.8"
                }`,
              }}
            >
              {t("update")}
            </Button>
          </Grid>
          <Grid item xs={12}>
            <Box></Box>
          </Grid>
          {/* <Grid item xs={12}>
            <EmailPreferences
              paymentDetailData={paymentDetailData}
              setPaymentDetailData={setPaymentDetailData}
            />
          </Grid> */}
        </Grid>
      </Grid>
      <Modal
        onClose={() => {
          setIsModalOpen(false);
          if (comeFrom === "packages-list") navigate("/payments");
        }}
        open={isModalOpen}
        title={t("billingInformations")}
      >
        <Box
          p={2}
          color={`${sumbitResult.success ? "#1eb51e" : "red"}`}
          display="flex"
          alignItems="center"
          justifyContent="flex-start"
        >
          {sumbitResult.success ? <CheckCircleIcon /> : <CancelIcon />}
          <Typography
            variant="body1"
            sx={{
              ml: 1,
              fontWeight: "500",

              color: `${sumbitResult.success ? "#1eb51e" : "red"}`,
            }}
          >
            {sumbitResult.message}
          </Typography>
        </Box>
      </Modal>
    </Container>
  );
};

export default SettingsForm;
