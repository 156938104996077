/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import Cookies from "js-cookie";
import Topbar from "./global/Topbar";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Box, Button, useTheme, Alert, Snackbar } from "@mui/material";
import TableList from "../components/partials/TableList";
import axios from "axios";
import { GridActionsCellItem } from "@mui/x-data-grid";
import DeleteIcon from "@mui/icons-material/Delete";
import FileCopyIcon from "@mui/icons-material/FileCopy";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { useAuthUser } from "react-auth-kit";
import NewProductIcon from "../components/icons/NewProductIcon";
import { deleteIyzicoPackage } from "../api";

const Packages = () => {
  const theme = useTheme();
  const auth = useAuthUser();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [deleteItemId, setDeleteItemId] = useState("");
  const [pageState, setPageState] = useState({
    isLoading: false,
    data: [],
    total: 0,
    page: 0,
    pageSize: 10,
  });
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [message, setMessage] = useState("");
  const [severity, setSeverity] = useState("success");
  const [openSnack, setOpenSnack] = useState(false);
  const [error, setError] = useState(null);

  const handleCloseSnack = () => {
    setOpenSnack(false);
  };

  const updateHandler = (id) => {
    navigate(`/package/${id}`);
  };

  const deleteHandler = (element) => {
    setDeleteItemId(element.id);
    setOpenDeleteDialog(true);
  };

  const handleClose = () => {
    setOpenDeleteDialog(false);
  };

  const acceptDeleteHandler = () => {
    deleteIyzicoPackage({ id: deleteItemId });
    setOpenDeleteDialog(false);
    fetchData();
  };

  const columns = [
    {
      field: "title",
      headerName: t("Paket Adı"),
      flex: 2,
    },
    {
      field: "advantages",
      headerName: t("Paket İçeriği"),
      flex: 4,
      renderCell: ({ row }) => {
        return <Box overflow={"auto"}>{row.advantages}</Box>;
      },
    },
    {
      field: "price",
      headerName: t("price"),
      flex: 1,
      renderCell: ({ row }) => {
        return <Box fontWeight={"700"}>$ {`${row.price}`}</Box>;
      },
    },
    {
      field: "actions",
      type: "actions",
      flex: 1,
      getActions: (params) => [
        <GridActionsCellItem
          icon={<FileCopyIcon />}
          label={t("edit")}
          onClick={() => updateHandler(params.row.id)}
          showInMenu
        />,
        <GridActionsCellItem
          icon={<DeleteIcon />}
          label={t("delete")}
          onClick={() => deleteHandler(params.row)}
          showInMenu
        />,
      ],
    },
  ];

  let data = "";
  let config = {
    method: "get",
    maxBodyLength: Infinity,
    url: `${process.env.REACT_APP_BASE_URL}/Payment/GetIyzicoPackages?Pagination.Page=${pageState.page}&Pagination.Size=${pageState.pageSize}`,
    headers: {
      Authorization: `Bearer ${Cookies.get("_auth")}`,
    },
    data: data,
  };
  const fetchData = async () => {
    setPageState((old) => ({ ...old, isLoading: true }));
    const response = await axios.request(config);
    const responseData = await response.data.items;
    const totalCount = await response.data.totalCount;
    setPageState((old) => ({
      ...old,
      isLoading: false,
      data: responseData,
      total: totalCount,
    }));
  };

  useEffect(() => {
    fetchData();
  }, [pageState.page, pageState.pageSize]);

  if (error) {
    setError(error);
    return <div>Error: {error.message}</div>;
  } else {
    return (
      <Box m={"20px"}>
        <Topbar title={t("packages")}>
          <Button
            onClick={() => navigate("/package")}
            startIcon={<NewProductIcon />}
            color="text"
            sx={{
              color: `${theme.palette.primary.fontColor}!important`,
              pl: 2,
              textTransform: "capitalize",
              border: "none",
              borderRadius: 0,
            }}
          >
            {t("addNewPackage")}
          </Button>
        </Topbar>
        <Box sx={{ background: theme.palette.primary.white }} p={5}>
          <Box mt={2} sx={{ height: "100%" }}>
            <TableList
              key={pageState.data.id}
              rows={pageState.data}
              columns={columns}
              pageState={pageState}
              setPageState={setPageState}
              rowHeight={120}
            />
          </Box>
        </Box>
        <Dialog
          open={openDeleteDialog}
          onClose={handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">
            {t("deletePackage")}
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              {t("areYouSureYouWantToDeleteThePackage")}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose}>{t("giveUp")}</Button>
            <Button onClick={acceptDeleteHandler} autoFocus>
              {t("confirm")}
            </Button>
          </DialogActions>
        </Dialog>

        <Snackbar
          open={openSnack}
          autoHideDuration={6000}
          onClose={handleCloseSnack}
          message={message}
        >
          <Alert onClose={handleCloseSnack} severity={severity}>
            {message}
          </Alert>
        </Snackbar>
      </Box>
    );
  }
};

export default Packages;
