import {
  Box,
  useTheme,
  Button,
  IconButton,
  InputBase,
  Drawer,
  Typography,
  Stack,
  FormControl,
  Select as MuiSelect,
  MenuItem,
  Checkbox,
  ListItemText,
  TextField,
} from "@mui/material";
import React, { useContext, useState, useEffect } from "react";
import Cookies from "js-cookie";
import { useAuthUser } from "react-auth-kit";
import { ProductFilterContext } from "../../context/ProductFilterContext";
import FilterIcon from "../icons/FilterIcon";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import CloseIcon from "@mui/icons-material/Close";
import { alpha, styled } from "@mui/material/styles";
import axios from "axios";
import dayjs from "dayjs";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { useTranslation } from "react-i18next";

import Select, { components } from "react-select";

const BootstrapInput = styled(InputBase)(({ theme }) => ({
  "label + &": {
    color: `${alpha(theme.palette.text.main, 0.25)} 0 0 0 0.2rem`,
  },
  "& .MuiInputBase-input": {
    borderRadius: 2,
    position: "relative",
    border: "1px solid",
    fontSize: 14,
    fontStyle: "italic",
    height: "12px",
    width: "100%",
    padding: "10px 12px",
    marginTop: "0.5rem",
    borderColor: theme.palette.primary.bordersColor,
    transition: theme.transitions.create([
      "border-color",
      "background-color",
      "box-shadow",
    ]),
    "&:focus": {
      boxShadow: `${alpha(theme.palette.primary.main, 0.25)} 0 0 0 0.2rem`,
      borderColor: theme.palette.primary.main,
      color: theme.palette.text.main,
    },
  },
}));

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const BootstrapSelect = styled(InputBase)(({ theme }) => ({
  "label + &": {
    marginTop: theme.spacing(3),
    color: `${alpha(theme.palette.text.main, 0.25)} 0 0 0 0.2rem`,
  },
  "& .MuiInputBase-input": {
    borderRadius: 2,
    position: "relative",
    border: "1px solid",
    fontSize: 14,
    fontStyle: "italic",
    height: "4px!important",
    width: "100%",
    padding: "10px 12px",
    lineHeight: "1",
    marginTop: "0.5rem",
    borderColor: theme.palette.primary.bordersColor,
    transition: theme.transitions.create([
      "border-color",
      "background-color",
      "box-shadow",
    ]),
    minHeight: " 0.8rem!important",
    "&:focus": {
      boxShadow: `${alpha(theme.palette.primary.main, 0.25)} 0 0 0 0.2rem`,
      borderColor: theme.palette.primary.main,
      color: theme.palette.text.main,
    },
  },
}));

const FilterDrawer = () => {
  const auth = useAuthUser();
  const { t } = useTranslation();
  const theme = useTheme();
  const [state, setState] = useState({
    left: false,
  });
  const [startValue, setStartValue] = useState(null);
  const [endValue, setEndValue] = useState(null);

  const { productFilters, setProductFilters } =
    useContext(ProductFilterContext);
  const [productSearch, setProductSearch] = useState("");
  const [filters, setFilters] = useState({});

  const [merchantMarketPlaceList, setMerchantMarketPlaceList] = useState([]);
  const [selectedMerchantMarketPlaceList, setSelectedMerchantMarketPlaceList] =
    useState([]);
  const [categories, setCategories] = useState([]);
  const [selectedCategories, setSelectedCategories] = useState([]);

  const [warehouses, setWarehouses] = useState([]);
  const [selectedWarehouses, setSelectedWarehouses] = useState([]);

  const [merchant, setMercjant] = useState([]);
  const [selectedMerchants, setSelectedMerchants] = useState("");

  const handleChange = event => {
    const name = event.target.name;
    const value = event.target.value;
    setFilters(values => ({ ...values, [name]: value }));
  };

  const handleSubmit = event => {
    event.preventDefault();
    setProductFilters({
      ...productFilters,
      ...filters,
      search: productSearch,
      updatedAt: startValue,
      updatedAtEnd: endValue,
    });
    setState({ ...state, left: false });
  };

  const handleChangeStartDate = newValue => {
    setStartValue(newValue.format("YYYY-MM-DD"));
  };

  const handleChangeEndDate = newValue => {
    setEndValue(newValue.format("YYYY-MM-DD"));
  };

  const Placeholder = props => {
    return <components.Placeholder {...props} />;
  };

  const options = merchant.map(option => {
    return {
      value: option.id,
      label: option.title,
    };
  });

  const brandsOptions = merchantMarketPlaceList.map(option => {
    return {
      value: option.id,
      label: option.title,
    };
  });
  const handleFilterReset = event => {
    event.preventDefault();
    setProductFilters({
      sku: null,
      barcode: null,
      productName: null,
      status: null,
      category: [],
      warehouse: [],
      brandList: [],
      merchant: "",
      updatedAt: null,
      updatedAtEnd: null,
    });
    setState({ ...state, left: false });

    setFilters({
      sku: null,
      barcode: null,
      productName: null,
      status: null,
      category: [],
      warehouse: [],
      brandList: [],
      merchant: "",
      updatedAt: null,
      updatedAtEnd: null,
    });
    setStartValue(null);
    setEndValue(null);
    setSelectedMerchantMarketPlaceList([]);
    setSelectedCategories([]);
    setSelectedWarehouses([]);
    setSelectedMerchants("");
  };

  const resetFilterBarcode = event => {
    event.preventDefault();
    setProductFilters({ ...productFilters, barcode: null });
    setFilters({ ...filters, barcode: null });
  };

  const resetFilterSku = event => {
    event.preventDefault();
    setProductFilters({ ...productFilters, sku: null });
    setFilters({ ...filters, sku: null });
  };

  const resetFilterBrand = event => {
    event.preventDefault();
    setProductFilters({ ...productFilters, brandList: [] });
    setFilters({ ...filters, brandList: [] });
    setSelectedMerchantMarketPlaceList([]);
  };

  const resetFilterCategory = event => {
    event.preventDefault();
    setProductFilters({ ...productFilters, category: [] });
    setFilters({ ...filters, category: [] });
    setSelectedCategories([]);
  };

  const resetFilterWarehouse = event => {
    event.preventDefault();
    setProductFilters({ ...productFilters, warehouse: [] });
    setFilters({ ...filters, warehouse: [] });
    setSelectedWarehouses([]);
  };

  const resetFilterMerchant = event => {
    event.preventDefault();
    setProductFilters({ ...productFilters, merchant: "" });
    setFilters({ ...filters, merchant: "" });
    setSelectedMerchants("");
  };

  const resetFilterUpdatedAt = event => {
    event.preventDefault();
    setStartValue(null);
    setEndValue(null);
    setProductFilters({
      ...productFilters,
      updatedAt: null,
      updatedAtEnd: null,
    });
    setFilters({ ...filters, updatedAt: null, updatedAtEnd: null });
    setStartValue(dayjs().locale("tr").format("YYYY-MM-DD"));
    setEndValue(dayjs().locale("tr").format("YYYY-MM-DD"));
  };

  const handleChangeBrand = event => {
    setSelectedMerchantMarketPlaceList(event.target.value);
    setProductFilters({
      ...productFilters,
      brandList: event.target.value,
    });
    setFilters({ ...filters, brandList: event.target.value });
  };

  const handleChangeCategory = event => {
    setSelectedCategories(event.target.value);
    setProductFilters({
      ...productFilters,
      category: event.target.value,
    });
    setFilters({ ...filters, category: event.target.value });
  };

  const handleChangeWarehouse = event => {
    setSelectedWarehouses(event.target.value);
    setProductFilters({
      ...productFilters,
      warehouse: event.target.value,
    });
    setFilters({ ...filters, warehouse: event.target.value });
  };

  const handleChangeMerchant = event => {
    setSelectedMerchants(event.target.value);
    setProductFilters({
      ...productFilters,
      merchant: event.target.value,
    });
    setFilters({ ...filters, merchant: event.target.value });
  };

  const toggleDrawer = (anchor, open) => event => {
    if (
      event &&
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setState({ ...state, [anchor]: open });
  };

  let data = "";
  let configWarehouses = {
    method: "get",
    maxBodyLength: Infinity,
    url: `${process.env.REACT_APP_BASE_URL}/Warehouse?IsCountry=true`,
    headers: {
      Authorization: `Bearer ${Cookies.get("_auth")}`,
    },
    data: data,
  };

  useEffect(() => {
    const fetchWarehouses = async () => {
      const responseWarehouses = await axios.request(configWarehouses);
      const responseWarehousesData = await responseWarehouses.data.items;
      setWarehouses(responseWarehousesData);
    };

    fetchWarehouses();
  }, []);

  if (auth().role === "b2790c4b-b4a8-4254-9ab6-84c67160b0e0") {
    useEffect(() => {
      const getSubmerchantDataFromAPI = async () => {
        let config = {
          method: "get",
          maxBodyLength: Infinity,
          url: `${
            process.env.REACT_APP_BASE_URL
          }/Merchant/GetSubMerchantProductBrands/${auth().merchantId}`,
          headers: {
            Authorization: `Bearer ${Cookies.get("_auth")}`,
          },
        };
        const response = await axios(config);
        setMerchantMarketPlaceList(response.data.items);
      };
      getSubmerchantDataFromAPI();
    }, []);
  }

  useEffect(() => {
    let categoryData = "";
    const configCategories = {
      method: "get",
      maxBodyLength: Infinity,
      url: `${process.env.REACT_APP_BASE_URL}/ProductCategory?MerchantId=${
        auth().merchantId
      }`,
      headers: {
        Authorization: `Bearer ${Cookies.get("_auth")}`,
      },
      categoryData: categoryData,
    };

    const fetchCategories = async () => {
      const responseCategories = await axios.request(configCategories);
      const responseCategoriesData = await responseCategories.data.items;
      setCategories(responseCategoriesData);
    };

    fetchCategories();
  }, []);

  if (auth().role === "b2790c4b-b4a8-4254-9ab6-84c67160b0e0") {
    useEffect(() => {
      let merchantData = "";
      const configMerchants = {
        method: "get",
        maxBodyLength: Infinity,
        url: `${process.env.REACT_APP_BASE_URL}/Merchant/GetList`,
        headers: {
          Authorization: `Bearer ${Cookies.get("_auth")}`,
        },
        merchantData: merchantData,
      };
      // fixed
      // eslint

      const fetchMerchants = async () => {
        const responseMerchants = await axios.request(configMerchants);
        const responseMerchantsData = await responseMerchants.data.items;
        setMercjant(responseMerchantsData);
      };

      fetchMerchants();
    }, []);
  }

  useEffect(() => {
    setProductFilters({
      ...productFilters,
      merchant: selectedMerchants.id === null ? "" : selectedMerchants.id,
    });
    setFilters({
      ...filters,
      merchant: selectedMerchants.id === null ? "" : selectedMerchants.id,
    });
  }, [merchant]);

  const list = anchor => (
    <Box
      sx={{ width: anchor === "top" || anchor === "bottom" ? "auto" : 368 }}
      role="presentation"
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Typography variant="h1" m={2}>
          {t("filters")}
        </Typography>
        <IconButton onClick={toggleDrawer(anchor, false)}>
          <CloseIcon sx={{ mr: 2 }} />
        </IconButton>
      </Box>

      <Box mt={2} ml={1}>
        <Accordion
          sx={{
            boxShadow: "none!important",
            border: "none!important",
            position: "unset",
          }}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
            className="panella-header"
          >
            <Typography
              sx={{
                fontWeight: "500",
                color: theme.palette.text.main,
                fontSize: "14px",
              }}
            >
              SKU
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <BootstrapInput
              name="sku"
              value={filters.sku}
              onChange={handleChange}
            />
          </AccordionDetails>
        </Accordion>
        <Accordion
          sx={{
            boxShadow: "none!important",
            border: "none!important",
            position: "unset",
          }}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel5a-content"
            id="panel5a-header"
            className="panella-header"
          >
            <Typography
              sx={{
                fontWeight: "500",
                color: theme.palette.text.main,
                fontSize: "14px",
              }}
            >
              {t("warehouse")}
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <FormControl className="signup-fc">
              <MuiSelect
                labelId="demo-multiple-checkbox-label"
                id="demo-multiple-checkbox"
                multiple
                displayEmpty
                value={selectedWarehouses}
                input={<BootstrapSelect />}
                onChange={handleChangeWarehouse}
                renderValue={selected => (
                  <div>
                    {selected.length === 0 ? (
                      <em
                        style={{
                          color: "currentColor",
                          opacity: "0.42",
                        }}
                      >
                        {t("selectWarehouse")}
                      </em>
                    ) : (
                      selected.map((id, index) => {
                        const selectedItem = warehouses.find(
                          item => item.id === id
                        );
                        return (
                          <span key={selectedItem.id}>
                            {selectedItem.title}
                            {index !== selected.length - 1 ? ", " : ""}
                          </span>
                        );
                      })
                    )}
                  </div>
                )}
              >
                {warehouses.map(opt => (
                  <MenuItem
                    key={opt.id}
                    value={opt.id}
                    sx={{
                      paddignTop: "0px!important",
                      paddingBottom: "0px!important",
                    }}
                  >
                    <Checkbox
                      size="small"
                      checked={selectedWarehouses.includes(opt.id)}
                    />
                    <ListItemText primary={opt.title} />
                  </MenuItem>
                ))}
              </MuiSelect>
            </FormControl>
          </AccordionDetails>
        </Accordion>
        <Accordion
          sx={{
            boxShadow: "none!important",
            border: "none!important",
            position: "unset",
          }}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel3a-content"
            id="panel3a-header"
            className="panella-header"
          >
            <Typography
              sx={{
                fontWeight: "500",
                color: theme.palette.text.main,
                fontSize: "14px",
              }}
            >
              {t("category")}
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <FormControl className="signup-fc">
              <MuiSelect
                labelId="demo-multiple-checkbox-label"
                id="demo-multiple-checkbox"
                multiple
                required
                displayEmpty
                value={selectedCategories}
                input={<BootstrapSelect />}
                onChange={handleChangeCategory}
                renderValue={selected => (
                  <div>
                    {selected.length === 0 ? (
                      <em
                        style={{
                          color: "currentColor",
                          opacity: "0.42",
                        }}
                      >
                        {t("selectCategory")}
                      </em>
                    ) : (
                      selected.map((id, index) => {
                        const selectedItem = categories.find(
                          item => item.id === id
                        );
                        return (
                          <span key={selectedItem.id}>
                            {selectedItem.title}
                            {index !== selected.length - 1 ? ", " : ""}
                          </span>
                        );
                      })
                    )}
                  </div>
                )}
              >
                {categories.map(opt => (
                  <MenuItem
                    key={opt.id}
                    value={opt.id}
                    sx={{
                      paddignTop: "0px!important",
                      paddingBottom: "0px!important",
                    }}
                  >
                    <Checkbox
                      size="small"
                      checked={selectedCategories.includes(opt.id)}
                    />
                    <ListItemText primary={opt.title} />
                  </MenuItem>
                ))}
              </MuiSelect>
            </FormControl>
          </AccordionDetails>
        </Accordion>
        <Accordion
          sx={{
            boxShadow: "none!important",
            border: "none!important",
            position: "unset",
          }}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel2a-content"
            id="panel2a-header"
            className="panella-header"
          >
            <Typography
              sx={{
                fontWeight: "500",
                color: theme.palette.text.main,
                fontSize: "14px",
              }}
            >
              {t("brand")}
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <FormControl className="signup-fc">
              <Select
                styles={{
                  control: (provided, state) => ({
                    ...provided,
                    marginTop: "10px",
                    maxWidth: "400px",
                    border: "1px solid #E5E5E5",
                    boxShadow: "none",
                    borderRadius: "0px",
                    "&:hover": {
                      border: "1px solid #E5E5E5",
                    },
                  }),
                }}
                components={{ Placeholder }}
                placeholder={t("brandsAll")}
                isMulti
                onChange={e => {
                  if (e !== null) {
                    setSelectedMerchantMarketPlaceList(e);
                    setProductFilters({
                      ...productFilters,
                      brandList: e.map(item => item.value),
                    });
                    setFilters({
                      ...filters,
                      brandList: e.map(item => item.value),
                    });
                  } else {
                    setSelectedMerchantMarketPlaceList([]);
                    setProductFilters({
                      ...productFilters,
                      brandList: [],
                    });
                    setFilters({
                      ...filters,
                      brandList: [],
                    });
                  }
                }}
                value={selectedMerchantMarketPlaceList}
                options={brandsOptions}
                isClearable={true}
              />
            </FormControl>
          </AccordionDetails>
        </Accordion>
        {auth().role === "b2790c4b-b4a8-4254-9ab6-84c67160b0e0" ? (
          <Accordion
            sx={{
              boxShadow: "none!important",
              border: "none!important",
              position: "unset",
            }}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel12a-content"
              id="panel12a-header"
              className="panella-header"
            >
              <Typography
                sx={{
                  fontWeight: "500",
                  color: theme.palette.text.main,
                  fontSize: "14px",
                }}
              >
                {t("company")}
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <FormControl className="signup-fc">
                <Select
                  styles={{
                    control: (provided, state) => ({
                      ...provided,
                      marginTop: "10px",
                      maxWidth: "400px",
                      border: "1px solid #E5E5E5",
                      boxShadow: "none",
                      borderRadius: "0px",
                      "&:hover": {
                        border: "1px solid #E5E5E5",
                      },
                    }),
                  }}
                  components={{ Placeholder }}
                  placeholder={t("exCompaniesAll")}
                  onChange={e => {
                    if (e !== null) {
                      setSelectedMerchants(e.value);
                      setProductFilters({
                        ...productFilters,
                        merchant: e.value,
                      });
                      setFilters({
                        ...filters,
                        merchant: e.value,
                      });
                    } else {
                      setSelectedMerchants("");
                      setProductFilters({
                        ...productFilters,
                        merchant: "",
                      });
                      setFilters({
                        ...filters,
                        merchant: "",
                      });
                    }
                  }}
                  value={
                    merchant.find(item => item.id === selectedMerchants)
                      ? {
                          value: merchant.find(
                            item => item.id === selectedMerchants
                          ).id,
                          label: merchant.find(
                            item => item.id === selectedMerchants
                          ).title,
                        }
                      : null
                  }
                  options={options}
                  isClearable={true}
                />
              </FormControl>
            </AccordionDetails>
          </Accordion>
        ) : null}
        {auth().role === "b2790c4b-b4a8-4254-9ab6-84c67160b0e0" ? (
          <Accordion
            sx={{
              boxShadow: "none!important",
              border: "none!important",
              position: "unset",
            }}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel44a-content"
              id="panel4a-header"
              className="panella-header"
            >
              <Typography
                sx={{
                  fontWeight: "500",
                  color: theme.palette.text.main,
                  fontSize: "14px",
                }}
              >
                {t("updateDate")}
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DemoContainer
                  components={["DatePicker", "DatePicker"]}
                  sx={{ display: "flex", flexDirection: "column!important" }}
                >
                  <DatePicker
                    className="customDatePicker"
                    label={t("startDate")}
                    value={dayjs(startValue)}
                    onChange={newValue => handleChangeStartDate(newValue)}
                    renderInput={props => (
                      <TextField {...props} helperText={null} />
                    )}
                    inputFormat="DD-MM-YYYY"
                    format="DD-MM-YYYY"
                  />
                  <DatePicker
                    className="customDatePicker"
                    sx={{
                      marginLeft: "0px!important",
                      marginTop: "10px!important",
                      // overflow: "hidden!important",
                    }}
                    label={t("endDate")}
                    value={dayjs(endValue)}
                    onChange={newValue => handleChangeEndDate(newValue)}
                    renderInput={props => (
                      <TextField {...props} helperText={null} />
                    )}
                    inputFormat="DD-MM-YYYY"
                    format="DD-MM-YYYY"
                  />
                </DemoContainer>
              </LocalizationProvider>
            </AccordionDetails>
          </Accordion>
        ) : null}
      </Box>
      <Box ml={2} position="absolute" bottom="20px">
        <Stack direction="row" spacing={16}>
          <Button onClick={handleFilterReset} className="filterReset">
            {t("reset")}
          </Button>
          <Button onClick={handleSubmit} className="filterApply">
            {t("applyFilters")}
          </Button>
        </Stack>
      </Box>
    </Box>
  );

  return (
    <Box>
      <Box
        sx={{
          background: theme.palette.primary.filtersBackground,
          mt: 2,
          display: "flex",
          alignItems: "center",
          gap: "10px",
          justifyContent: "flex-start",
        }}
      >
        <Button
          onClick={toggleDrawer("left", true)}
          startIcon={<FilterIcon />}
          endIcon={<ExpandMoreIcon />}
          color="text"
          sx={{
            mt: 1,
            mb: 1,
            p: 1,
            pl: 2,
            textTransform: "capitalize",
            border: "none",
            borderRight: "1px solid",
            borderRadius: 0,
            borderColor: theme.palette.primary.bordersColor,
          }}
        >
          {t("filters")}
        </Button>
        <Box sx={{ flexGrow: 1, display: "contents" }}>
          {productFilters.barcode !== null && (
            <Button
              onClick={resetFilterBarcode}
              endIcon={<CloseIcon />}
              color="text"
              sx={{
                mt: 1,
                mb: 1,
                pl: 2,
                textTransform: "capitalize",
                border: "1px solid",
                borderRadius: 0,
                background: "#fff",
                borderColor: theme.palette.primary.bordersColor,
              }}
            >
              {t("barcode")}: {productFilters.barcode}
            </Button>
          )}
        </Box>
        <Box sx={{ flexGrow: 1, display: "contents" }}>
          {productFilters.sku !== null && (
            <Button
              onClick={resetFilterSku}
              endIcon={<CloseIcon />}
              color="text"
              sx={{
                mt: 1,
                mb: 1,
                pl: 2,
                textTransform: "capitalize",
                border: "1px solid",
                borderRadius: 0,
                background: "#fff",
                borderColor: theme.palette.primary.bordersColor,
              }}
            >
              SKU: {productFilters.sku}
            </Button>
          )}
        </Box>
        <Box sx={{ flexGrow: 1, display: "contents" }}>
          {productFilters.brandList.length > 0 &&
            (productFilters.brandList.length === 36 ? null : (
              <Button
                onClick={resetFilterBrand}
                endIcon={<CloseIcon />}
                color="text"
                sx={{
                  mt: 1,
                  mb: 1,
                  pl: 2,
                  textTransform: "capitalize",
                  border: "1px solid",
                  borderRadius: 0,
                  background: "#fff",
                  borderColor: theme.palette.primary.bordersColor,
                }}
              >
                {productFilters.brandList.length} Marka Seçili
              </Button>
            ))}
        </Box>
        <Box sx={{ flexGrow: 1, display: "contents" }}>
          {productFilters.warehouse.length > 0 && (
            <Button
              onClick={resetFilterWarehouse}
              endIcon={<CloseIcon />}
              color="text"
              sx={{
                mt: 1,
                mb: 1,
                pl: 2,
                textTransform: "capitalize",
                border: "1px solid",
                borderRadius: 0,
                background: "#fff",
                borderColor: theme.palette.primary.bordersColor,
              }}
            >
              {productFilters.warehouse.length} Depo Seçili
            </Button>
          )}
        </Box>
        <Box sx={{ flexGrow: 1, display: "contents" }}>
          {productFilters.category.length > 0 && (
            <Button
              onClick={resetFilterCategory}
              endIcon={<CloseIcon />}
              color="text"
              sx={{
                mt: 1,
                mb: 1,
                pl: 2,
                textTransform: "capitalize",
                border: "1px solid",
                borderRadius: 0,
                background: "#fff",
                borderColor: theme.palette.primary.bordersColor,
              }}
            >
              {productFilters.category.length} {t("categorySelected")}
            </Button>
          )}
        </Box>
        <Box sx={{ flexGrow: 1, display: "contents" }}>
          {productFilters.merchant !== undefined &&
          productFilters.merchant !== "" &&
          productFilters.merchant !== null ? (
            <Button
              onClick={resetFilterMerchant}
              endIcon={<CloseIcon />}
              color="text"
              sx={{
                mt: 1,
                mb: 1,
                pl: 2,
                textTransform: "capitalize",
                border: "1px solid",
                borderRadius: 0,
                background: "#fff",
                borderColor: theme.palette.primary.bordersColor,
              }}
            >
              {t("company")}:{" "}
              {merchant.find(item => item.id === productFilters.merchant).title}
            </Button>
          ) : (
            ""
          )}
        </Box>
      </Box>

      <Drawer
        anchor="left"
        open={state["left"]}
        onClose={toggleDrawer("left", false)}
      >
        {list("left")}
      </Drawer>
    </Box>
  );
};

export default FilterDrawer;
