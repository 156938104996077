import React from "react";

const Xls = () => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_2138_1745)">
        <path
          d="M4.69231 19H2.23077C1.90435 19 1.5913 18.8736 1.36048 18.6485C1.12967 18.4235 1 18.1183 1 17.8V2.2C1 1.88174 1.12967 1.57652 1.36048 1.35147C1.5913 1.12643 1.90435 1 2.23077 1H10.952C11.2782 1.00007 11.591 1.12639 11.8217 1.3512L16.6398 6.0488C16.8704 6.27376 16.9999 6.57876 17 6.8968V10.6"
          stroke="#453834"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M17 7H12.2C11.8817 7 11.5765 6.87357 11.3515 6.64853C11.1264 6.42348 11 6.11826 11 5.8V1"
          stroke="#453834"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M7 13L9 19"
          stroke="#453834"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M9 13L7 19"
          stroke="#453834"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M12 13V17.8C12 18.1183 12.1054 18.4235 12.2929 18.6485C12.4804 18.8736 12.7348 19 13 19H14"
          stroke="#453834"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M19 13H18C17.7348 13 17.4804 13.1264 17.2929 13.3515C17.1054 13.5765 17 13.8817 17 14.2C17 16 19 16 19 17.8C19 18.1183 18.8946 18.4235 18.7071 18.6485C18.5196 18.8736 18.2652 19 18 19H17"
          stroke="#453834"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_2138_1745">
          <rect width="20" height="20" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default Xls;
