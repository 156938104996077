import { Box, useTheme } from "@mui/material";
import React from "react";

const FormGroup = ({ children, className }) => {
  const theme = useTheme();
  return (
    <Box
      className={className}
      sx={{
        border: "1px solid",
        display: "flex",
        flexDirection: "column",
        p: 2,
        borderRadius: "2px",
        borderColor: theme.palette.primary.bordersColor,
      }}
    >
      {children}
    </Box>
  );
};

export default FormGroup;
