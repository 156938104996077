import {
  Box,
  useTheme,
  Button,
  IconButton,
  InputBase,
  Drawer,
  Typography,
  Stack,
} from "@mui/material";
import React, { useContext, useState, useEffect } from "react";
import Cookies from "js-cookie";
import { FbmmFilterContext } from "../../context/FbmmFilterContext";
import FilterIcon from "../icons/FilterIcon";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import CloseIcon from "@mui/icons-material/Close";
import { alpha, styled } from "@mui/material/styles";
import axios from "axios";
import CustomFbmmMultiSelect from "../forms/form-elements/CustomFbmmMultiSelect";
import CustomFbmmSourceSelect from "../forms/form-elements/CustomFbmmSourceSelect";
import dayjs from "dayjs";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { set } from "date-fns";
import { useTranslation } from "react-i18next";

const BootstrapInput = styled(InputBase)(({ theme }) => ({
  "label + &": {
    color: `${alpha(theme.palette.text.main, 0.25)} 0 0 0 0.2rem`,
  },
  "& .MuiInputBase-input": {
    borderRadius: 2,
    position: "relative",
    border: "1px solid",
    fontSize: 14,
    fontStyle: "italic",
    height: "12px",
    width: "100%",
    padding: "10px 12px",
    marginTop: "0.5rem",
    borderColor: theme.palette.primary.bordersColor,
    transition: theme.transitions.create([
      "border-color",
      "background-color",
      "box-shadow",
    ]),
    "&:focus": {
      boxShadow: `${alpha(theme.palette.primary.main, 0.25)} 0 0 0 0.2rem`,
      borderColor: theme.palette.primary.main,
      color: theme.palette.text.main,
    },
  },
}));

const FbmmFilter = () => {
  const theme = useTheme();
  const { t } = useTranslation();
  const [state, setState] = useState({
    left: false,
  });

  const [startValue, setStartValue] = useState(dayjs().locale("tr"));
  const [endValue, setEndValue] = useState(dayjs().locale("tr"));

  const { fbmmFilters, setFbmmFilters } = useContext(FbmmFilterContext);
  const [filters, setFilters] = useState({});

  const [warehouses, setWarehouses] = useState([]);
  const [categories, setCategories] = useState([]);

  const handleChangeStartDate = (newValue) => {
    setStartValue(newValue);
    setFbmmFilters({
      ...fbmmFilters,
      startDate: `${newValue.$y}-${newValue.$M + 1}-${newValue.$D}`,
    });
  };

  const handleChangeEndDate = (newValue) => {
    setEndValue(newValue);
    setFbmmFilters({
      ...fbmmFilters,
      endDate: `${newValue.$y}-${newValue.$M + 1}-${newValue.$D}`,
    });
  };

  const source = [
    "amazon",
    "amazontr",
    "amazonde",
    "amazonuk",
    "cs",
    "ebay",
    "etsy",
    "gg",
    "hb",
    "n11",
    "rakuten",
    "sp",
    "store",
    "ticimax",
    "website",
  ];

  const handleChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    setFilters((values) => ({ ...values, [name]: value }));
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    setFbmmFilters({ ...fbmmFilters, ...filters });
    setState({ ...state, left: false });
  };

  const handleFilterReset = (event) => {
    event.preventDefault();
    setFbmmFilters({
      sku: null,
      barcode: null,
      orderNumber: null,
      product: null,
      status: null,
      warehouse: [],
      productCode: null,
      search: "",
      source: [],
      startDate: null,
      endDate: null,
    });
    setState({ ...state, left: false });

    setFilters({
      sku: null,
      barcode: null,
      orderNumber: null,
      product: null,
      status: null,
      warehouse: [],
      productCode: null,
      search: "",
      source: [],
      startDate: null,
      endDate: null,
    });
  };

  const toggleDrawer = (anchor, open) => (event) => {
    if (
      event &&
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setState({ ...state, [anchor]: open });
  };

  useEffect(() => {
    let wareHouseData = "";
    const configWarehouses = {
      method: "get",
      maxBodyLength: Infinity,
      url: `${process.env.REACT_APP_BASE_URL}/Warehouse?Pagination.Page=0&Pagination.Size=100&isCountry=true`,
      headers: {
        Authorization: `Bearer ${Cookies.get("_auth")}`,
      },
      wareHouseData: wareHouseData,
    };

    const fetchWarehouses = async () => {
      const responseWarehouses = await axios.request(configWarehouses);
      const responseWarehousesData = await responseWarehouses.data.items;
      setWarehouses(responseWarehousesData);
    };

    fetchWarehouses();
  }, []);

  useEffect(() => {
    let categoryData = "";
    const configCategories = {
      method: "get",
      maxBodyLength: Infinity,
      url: `${process.env.REACT_APP_BASE_URL}/ProductCategory?Pagination.Page=0&Pagination.Size=100`,
      headers: {
        Authorization: `Bearer ${Cookies.get("_auth")}`,
      },
      categoryData: categoryData,
    };

    const fetchCategories = async () => {
      const responseCategories = await axios.request(configCategories);
      const responseCategoriesData = await responseCategories.data.items;
      setCategories(responseCategoriesData);
    };

    fetchCategories();
  }, []);

  const list = (anchor) => (
    <Box
      sx={{ width: anchor === "top" || anchor === "bottom" ? "auto" : 368 }}
      role="presentation"
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Typography variant="h1" m={2}>
          {t("filters")}
        </Typography>
        <IconButton onClick={toggleDrawer(anchor, false)}>
          <CloseIcon sx={{ mr: 2 }} />
        </IconButton>
      </Box>
      <Box mt={2} ml={1}>
        <Accordion
          sx={{
            boxShadow: "none!important",
            border: "none!important",
            position: "unset",
          }}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
            className="panella-header"
          >
            <Typography
              sx={{
                fontWeight: "500",
                color: theme.palette.text.main,
                fontSize: "14px",
              }}
            >
              SKU
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <BootstrapInput
              name="sku"
              value={filters.sku}
              onChange={handleChange}
            />
          </AccordionDetails>
        </Accordion>
        <Accordion
          sx={{
            boxShadow: "none!important",
            border: "none!important",
            position: "unset",
          }}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel41a-content"
            id="panel41a-header"
            className="panella-header"
          >
            <Typography
              sx={{
                fontWeight: "500",
                color: theme.palette.text.main,
                fontSize: "14px",
              }}
            >
              {t("productCode")}
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <BootstrapInput
              name="productCode"
              value={filters.productCode}
              onChange={handleChange}
            />
          </AccordionDetails>
        </Accordion>
        <Accordion
          sx={{
            boxShadow: "none!important",
            border: "none!important",
            position: "unset",
          }}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
            className="panella-header"
          >
            <Typography
              sx={{
                fontWeight: "500",
                color: theme.palette.text.main,
                fontSize: "14px",
              }}
            >
              {t("orderNumber")}
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <BootstrapInput
              name="orderNumber"
              value={filters.orderNumber}
              onChange={handleChange}
            />
          </AccordionDetails>
        </Accordion>
        <Accordion
          sx={{
            boxShadow: "none!important",
            border: "none!important",
            position: "unset",
          }}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel2a-content"
            id="panel2a-header"
            className="panella-header"
          >
            <Typography
              sx={{
                fontWeight: "500",
                color: theme.palette.text.main,
                fontSize: "14px",
              }}
            >
              {t("warehouse")}
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <CustomFbmmMultiSelect
              className={"filter-select"}
              options={warehouses}
            />
          </AccordionDetails>
        </Accordion>
        <Accordion
          sx={{
            boxShadow: "none!important",
            border: "none!important",
            position: "unset",
          }}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel3a-content"
            id="panel3a-header"
            className="panella-header"
          >
            <Typography
              sx={{
                fontWeight: "500",
                color: theme.palette.text.main,
                fontSize: "14px",
              }}
            >
              {t("source")}
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <CustomFbmmSourceSelect
              className={"filter-select"}
              options={source}
            />
          </AccordionDetails>
        </Accordion>
        <Accordion
          sx={{
            boxShadow: "none!important",
            border: "none!important",
            position: "unset",
          }}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel2a-content"
            id="panel2a-header"
            className="panella-header"
          >
            <Typography
              sx={{
                fontWeight: "500",
                color: theme.palette.text.main,
                fontSize: "14px",
              }}
            >
              {t("history")}
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <LocalizationProvider localeText="tr" dateAdapter={AdapterDayjs}>
              <DemoContainer
                components={["DatePicker", "DatePicker"]}
                sx={{ display: "flex", flexDirection: "column!important" }}
              >
                <DatePicker
                  className="customDatePicker"
                  label={t("startDate")}
                  defaultValue={dayjs().locale("tr")}
                  value={startValue}
                  onChange={(newValue) => handleChangeStartDate(newValue)}
                  format="DD/MM/YYYY"
                />
                <DatePicker
                  className="customDatePicker"
                  sx={{
                    marginLeft: "0px!important",
                    marginTop: "10px!important",
                    // overflow: "hidden!important",
                  }}
                  label={t("endDate")}
                  defaultValue={dayjs().locale("tr")}
                  value={endValue}
                  onChange={(newValue) => handleChangeEndDate(newValue)}
                  format="DD/MM/YYYY"
                />
              </DemoContainer>
            </LocalizationProvider>
          </AccordionDetails>
        </Accordion>
      </Box>
      <Box ml={2} position="absolute" bottom="20px">
        <Stack direction="row" spacing={16}>
          <Button onClick={handleFilterReset} className="filterReset">
            {t("reset")}
          </Button>
          <Button onClick={handleSubmit} className="filterApply">
            {t("applyFilters")}
          </Button>
        </Stack>
      </Box>
    </Box>
  );

  const resetFilterSku = () => {
    setFbmmFilters({ ...fbmmFilters, sku: null });
    setFilters({ ...filters, sku: null });
  };

  const resetFilterProductCode = () => {
    setFbmmFilters({ ...fbmmFilters, productCode: null });
    setFilters({ ...filters, productCode: null });
  };

  const resetFilterOrderNumber = () => {
    setFbmmFilters({ ...fbmmFilters, orderNumber: null });
    setFilters({ ...filters, orderNumber: null });
  };

  const resetFilterWarehouse = () => {
    setFbmmFilters({ ...fbmmFilters, warehouse: [] });
    setFilters({ ...filters, warehouse: [] });
  };

  const resetFilterSource = () => {
    setFbmmFilters({ ...fbmmFilters, source: [] });
    setFilters({ ...filters, source: [] });
  };

  const resetFilterStartDate = () => {
    setFbmmFilters({ ...fbmmFilters, startDate: null });
    setStartValue("");
  };

  const resetFilterEndDate = () => {
    setFbmmFilters({ ...fbmmFilters, endDate: null });
    setEndValue("");
  };

  return (
    <Box>
      <Box
        sx={{
          background: theme.palette.primary.filtersBackground,
          mt: 2,
          display: "flex",
          alignItems: "center",
          gap: "10px",
          justifyContent: "flex-start",
        }}
      >
        <Button
          onClick={toggleDrawer("left", true)}
          startIcon={<FilterIcon />}
          endIcon={<ExpandMoreIcon />}
          color="text"
          sx={{
            mt: 1,
            mb: 1,
            p: 1,
            pl: 2,
            textTransform: "capitalize",
            border: "none",
            borderRight: "1px solid",
            borderRadius: 0,
            borderColor: theme.palette.primary.bordersColor,
          }}
        >
          {t("filters")}
        </Button>
        <Box sx={{ flexGrow: 1, display: "contents" }}>
          {fbmmFilters.sku !== null && (
            <Button
              onClick={resetFilterSku}
              endIcon={<CloseIcon />}
              color="text"
              sx={{
                mt: 1,
                mb: 1,
                pl: 2,
                textTransform: "capitalize",
                border: "1px solid",
                borderRadius: 0,
                background: "#fff",
                borderColor: theme.palette.primary.bordersColor,
              }}
            >
              SKU: {fbmmFilters.sku}
            </Button>
          )}
        </Box>

        <Box sx={{ flexGrow: 1, display: "contents" }}>
          {fbmmFilters.productCode !== null && (
            <Button
              onClick={resetFilterProductCode}
              endIcon={<CloseIcon />}
              color="text"
              sx={{
                mt: 1,
                mb: 1,
                pl: 2,
                textTransform: "capitalize",
                border: "1px solid",
                borderRadius: 0,
                background: "#fff",
                borderColor: theme.palette.primary.bordersColor,
              }}
            >
              {t("productCode")}: {fbmmFilters.productCode}
            </Button>
          )}
        </Box>

        <Box sx={{ flexGrow: 1, display: "contents" }}>
          {fbmmFilters.orderNumber !== null && (
            <Button
              onClick={resetFilterOrderNumber}
              endIcon={<CloseIcon />}
              color="text"
              sx={{
                mt: 1,
                mb: 1,
                pl: 2,
                textTransform: "capitalize",
                border: "1px solid",
                borderRadius: 0,
                background: "#fff",
                borderColor: theme.palette.primary.bordersColor,
              }}
            >
              {t("orderNumber")}: {fbmmFilters.orderNumber}
            </Button>
          )}
        </Box>

        <Box sx={{ flexGrow: 1, display: "contents" }}>
          {fbmmFilters.warehouse.length > 0 && (
            <Button
              onClick={resetFilterWarehouse}
              endIcon={<CloseIcon />}
              color="text"
              sx={{
                mt: 1,
                mb: 1,
                pl: 2,
                textTransform: "capitalize",
                border: "1px solid",
                borderRadius: 0,
                background: "#fff",
                borderColor: theme.palette.primary.bordersColor,
              }}
            >
              {t("warehouse")}: {fbmmFilters.warehouse.length} {t("quantity")}
            </Button>
          )}
        </Box>
        <Box sx={{ flexGrow: 1, display: "contents" }}>
          {fbmmFilters.source.length > 0 && (
            <Button
              onClick={resetFilterSource}
              endIcon={<CloseIcon />}
              color="text"
              sx={{
                mt: 1,
                mb: 1,
                pl: 2,
                textTransform: "capitalize",
                border: "1px solid",
                borderRadius: 0,
                background: "#fff",
                borderColor: theme.palette.primary.bordersColor,
              }}
            >
              {t("source")}: {fbmmFilters.source.length} {t("quantity")}
            </Button>
          )}
        </Box>
        <Box sx={{ flexGrow: 1, display: "contents" }}>
          {fbmmFilters.startDate !== null && (
            <Button
              onClick={resetFilterStartDate}
              endIcon={<CloseIcon />}
              color="text"
              sx={{
                mt: 1,
                mb: 1,
                pl: 2,
                textTransform: "capitalize",
                border: "1px solid",
                borderRadius: 0,
                background: "#fff",
                borderColor: theme.palette.primary.bordersColor,
              }}
            >
              {t("startDate")}:{" "}
              {fbmmFilters.startDate !== null
                ? dayjs(fbmmFilters.startDate).format("DD/MM/YYYY")
                : dayjs().format("DD/MM/YYYY")}
            </Button>
          )}
        </Box>
        <Box sx={{ flexGrow: 1, display: "contents" }}>
          {fbmmFilters.endDate !== null && (
            <Button
              onClick={resetFilterEndDate}
              endIcon={<CloseIcon />}
              color="text"
              sx={{
                mt: 1,
                mb: 1,
                pl: 2,
                textTransform: "capitalize",
                border: "1px solid",
                borderRadius: 0,
                background: "#fff",
                borderColor: theme.palette.primary.bordersColor,
              }}
            >
              {t("endDate")}:{" "}
              {fbmmFilters.endDate !== null
                ? dayjs(fbmmFilters.endDate).format("DD/MM/YYYY")
                : dayjs().format("DD/MM/YYYY")}
            </Button>
          )}
        </Box>
      </Box>
      <Drawer
        anchor="left"
        open={state["left"]}
        onClose={toggleDrawer("left", false)}
      >
        {list("left")}
      </Drawer>
    </Box>
  );
};

export default FbmmFilter;
