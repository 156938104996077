import React, { useState, useContext } from "react";
import Button from "@mui/material/Button";
import ButtonGroup from "@mui/material/ButtonGroup";
import Badge from "@mui/material/Badge";
import { Box, IconButton, useTheme, Typography } from "@mui/material";
import InputBase from "@mui/material/InputBase";
import SearchIcon from "../icons/SearchIcon";
import { MerchantFilterContext } from "../../context/MerchantFilterContext";
import DownloadMerchantExcel from "./DownloadMerchantExcel";
import LockOpenIcon from "@mui/icons-material/LockOpen";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import FormHelperText from "@mui/material/FormHelperText";
import {
  ArrowDropDown,
  SupervisedUserCircle,
  VerifiedUser,
} from "@mui/icons-material";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import Menu from "@mui/material/Menu";
import ListItemText from "@mui/material/ListItemText";
import DownloadMerchantExcelWithSubmerchant from "./DownloadMerchantExcelWithSubmerchant";
import Xls from "../icons/Xls";
import { useTranslation } from "react-i18next";

const MerchantToggler = ({
  options,
  placeholder,
  userLoginModal,
  createAdminModal,
  counts,
}) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const [openStatus, setOpenStatus] = useState(false);
  const { t } = useTranslation();

  const handleClickCloseStatus = () => {
    setOpenStatus(false);
  };
  const handleClickOpenStatus = () => {
    setOpenStatus(true);
    handleClose();
  };

  const handleChange = (event) => {
    setSelectedIndex(event.target.value);
    setMerchantFilters({ ...merchantFilters, status: event.target.value });
  };

  const theme = useTheme();
  const [selectedIndex, setSelectedIndex] = useState(0);

  const [merchantSearch, setMerchantSearch] = useState("");
  const { merchantFilters, setMerchantFilters } = useContext(
    MerchantFilterContext
  );

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    setMerchantFilters({ ...merchantFilters, search: merchantSearch });
  };

  const handleMenuItemClick = (event, index, id) => {
    setSelectedIndex(index);
    // setToggleId(id);
    setMerchantFilters({ ...merchantFilters, status: id });
  };
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "flex-start",
      }}
    >
      <Box>
        <Typography
          variant="h6"
          sx={{
            ml: 1,
            fontSize: "16px",
            fontWeight: 500,
          }}
          color="primary"
          component="div"
        >
          {t("status")}:
        </Typography>
      </Box>
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "space-between",
          width: "100%",
          height: "100%",
        }}
      >
        <Box sx={{ display: "flex", height: "100%", alignItems: "center" }}>
          <Box>
            <FormControl sx={{ m: 1, minWidth: 120 }}>
              <Select
                value={selectedIndex}
                onChange={handleChange}
                displayEmpty
                size="small"
                sx={{
                  width: "240px",
                  borderRadius: "0px",
                  "& .MuiSelect-select": {
                    borderRadius: "0px",
                    display: "flex!important",
                    justifyContent: "space-between!important",
                  },
                  "& .MuiSelect-icon": {
                    top: "unset",
                  },
                }}
              >
                <MenuItem
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                  }}
                  value={0}
                >
                  <span>{t("all")}</span>
                  <span>{counts.total}</span>
                </MenuItem>
                {options.map((option, index) => (
                  <MenuItem
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                    }}
                    key={index}
                    value={option.id}
                    onClick={(event) =>
                      handleMenuItemClick(
                        event,
                        option.count,
                        option.id,
                        option.title
                      )
                    }
                  >
                    <span>{option.title} </span> <span>{option.count}</span>
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Box>
          <Box
            display="flex"
            sx={{
              border: 1,
              ml: 2,
              borderColor: theme.palette.primary.bordersColor,
              borderRadius: "2px",
              height: "40px",
            }}
          >
            <IconButton
              onClick={handleSubmit}
              type="button"
              size="small"
              sx={{ ml: 1, p: 0 }}
            >
              <SearchIcon />
            </IconButton>
            <InputBase
              sx={{
                flex: 2,
                p: 0,
                m: 0,
                ml: 1,
                fontStyle: "italic",
                fontSize: "14px!important",
              }}
              placeholder={t("searchForRecords")}
              size="small"
              className="search"
              onKeyUp={(e) => {
                if (merchantSearch.length === 0) {
                  setMerchantFilters({ ...merchantFilters, search: "" });
                  handleSubmit(e);
                }
              }}
              onKeyPress={(e) => {
                if (e.key === "Enter") {
                  handleSubmit(e);
                }
              }}
              value={merchantSearch}
              onChange={(e) => setMerchantSearch(e.target.value)}
            />
          </Box>
        </Box>
        <Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "space-between",
              width: "100%",
              height: "100%",
              gap: 2,
            }}
          >
            <Button
              className="filterReset"
              startIcon={<SupervisedUserCircle />}
              variant="contained"
              sx={{
                boxShadow: "none",
                border: "1px solid #45383426",
                width: "160px",
              }}
              onClick={createAdminModal}
            >
              {t("addMMAdmin")}
            </Button>
            <Button
              className="filterReset"
              startIcon={<LockOpenIcon />}
              variant="contained"
              sx={{
                boxShadow: "none",
                border: "1px solid #45383426",
                width: "130px",
              }}
              onClick={userLoginModal}
            >
              {t("companyLogin")}
            </Button>
            {/* //Dropdown menu */}

            <Button
              id="basic-button"
              startIcon={<Xls />}
              aria-controls={open ? "basic-menu" : undefined}
              aria-haspopup="true"
              aria-expanded={open ? "true" : undefined}
              onClick={handleClick}
              endIcon={<KeyboardArrowDownIcon />}
              className="filterReset"
              variant="contained"
              sx={{
                boxShadow: "none",
                border: "1px solid #45383426",
                width: "150px",
              }}
            >
              {t("export")}
            </Button>
            <Menu
              id="basic-menu"
              anchorEl={anchorEl}
              open={open}
              onClose={handleClose}
              MenuListProps={{
                "aria-labelledby": "basic-button",
              }}
            >
              <MenuItem
                onClick={handleClickOpenStatus}
                sx={{
                  color: `${theme.palette.primary.fontColor}!important`,
                  pl: 2,
                  textTransform: "capitalize",
                  border: "none",
                  borderRadius: 0,
                }}
              >
                <ListItemText>
                  <DownloadMerchantExcel />
                </ListItemText>
              </MenuItem>
              <MenuItem
                onClick={handleClickOpenStatus}
                sx={{
                  color: `${theme.palette.primary.fontColor}!important`,
                  pl: 2,
                  textTransform: "capitalize",
                  border: "none",
                  borderRadius: 0,
                }}
              >
                <ListItemText>
                  <DownloadMerchantExcelWithSubmerchant />
                </ListItemText>
              </MenuItem>
            </Menu>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default MerchantToggler;
