import React, { useState, useEffect } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import Grid from "@mui/material/Grid";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import CloseIcon from "@mui/icons-material/Close";
import Slide from "@mui/material/Slide";
import {
  Container,
  useTheme,
  Stack,
  DialogTitle,
  Divider,
  DialogContent,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import { alpha, styled } from "@mui/material/styles";
import InputBase from "@mui/material/InputBase";
import { Form, Spinner } from "react-bootstrap";
import axios from "axios";
import FormGroup from "../components/forms/form-elements/FormGroup";
import BoxTitle from "../components/forms/form-elements/BoxTitle";
import FormControl from "@mui/material/FormControl";
import CustomLabel from "../components/forms/form-elements/CustomLabel";
import Box from "@mui/material/Box";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import InputMask from "react-input-mask";
import StepLabel from "@mui/material/StepLabel";
import RadioGroup from "@mui/material/RadioGroup";
import Radio from "@mui/material/Radio";
import FormControlLabel from "@mui/material/FormControlLabel";
import CheckIcon from "@mui/icons-material/Check";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import CircularProgress from "@mui/material/CircularProgress";
import Checkbox from "@mui/material/Checkbox";
import ListItemText from "@mui/material/ListItemText";
import { Link } from "react-router-dom";
import ReactGA from "react-ga";

import Flag from "react-world-flags";
import { useLanguage } from "../context/LanguageContext";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from "@mui/material";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});
const steps = ["1", "2", "3"];

const BootstrapInput = styled(InputBase)(({ theme }) => ({
  "label + &": {
    marginTop: theme.spacing(3),
    color: `${alpha(theme.palette.text.main, 0.25)} 0 0 0 0.2rem`,
  },
  "& .MuiInputBase-input": {
    borderRadius: 2,
    position: "relative",
    border: "1px solid",
    fontSize: 14,
    fontStyle: "italic",
    height: "12px",
    width: "100%",
    padding: "10px 12px",
    marginTop: "0.5rem",
    borderColor: theme.palette.primary.bordersColor,
    transition: theme.transitions.create([
      "border-color",
      "background-color",
      "box-shadow",
    ]),
    "&:focus": {
      boxShadow: `${alpha(theme.palette.primary.main, 0.25)} 0 0 0 0.2rem`,
      borderColor: theme.palette.primary.main,
      color: theme.palette.text.main,
    },
  },
}));
const BootstrapPhoneInput = styled(InputBase)(({ theme }) => ({
  "label + &": {
    marginTop: theme.spacing(3),
    color: `${alpha(theme.palette.text.main, 0.25)} 0 0 0 0.2rem`,
  },
  "& .MuiInputBase-input": {
    borderRadius: "0 2px 2px 0",
    position: "relative",
    border: "1px solid",
    fontSize: 14,
    fontStyle: "italic",
    height: "12px",
    width: "100%",
    padding: "10px 12px",
    marginTop: "0.5rem",
    borderColor: theme.palette.primary.bordersColor,
    transition: theme.transitions.create([
      "border-color",
      "background-color",
      "box-shadow",
    ]),
    "&:focus": {
      boxShadow: `${alpha(theme.palette.primary.main, 0.25)} 0 0 0 0.2rem`,
      borderColor: theme.palette.primary.main,
      color: theme.palette.text.main,
    },
  },
}));

const BootstrapNationInput = styled(InputBase)(({ theme }) => ({
  "label + &": {
    marginTop: theme.spacing(3),
    color: `${alpha(theme.palette.text.main, 0.25)} 0 0 0 0.2rem`,
  },
  "& .MuiInputBase-input": {
    borderRadius: "2px 0 0 2px",
    position: "relative",
    border: "1px solid",
    borderRight: "none",
    fontSize: 14,
    fontStyle: "italic",
    height: "12px",
    width: "100%",
    padding: "10px 12px",
    marginTop: "0.5rem",
    borderColor: theme.palette.primary.bordersColor,
    transition: theme.transitions.create([
      "border-color",
      "background-color",
      "box-shadow",
    ]),
    "&:focus": {
      boxShadow: `${alpha(theme.palette.primary.main, 0.25)} 0 0 0 0.2rem`,
      borderColor: theme.palette.primary.main,
      color: theme.palette.text.main,
    },
  },
}));

const BootstrapSelect = styled(InputBase)(({ theme }) => ({
  "label + &": {
    marginTop: theme.spacing(3),
    color: `${alpha(theme.palette.text.main, 0.25)} 0 0 0 0.2rem`,
  },
  "& .MuiInputBase-input": {
    borderRadius: 2,
    position: "relative",
    border: "1px solid",
    fontSize: 14,
    fontStyle: "italic",
    height: "4px!important",
    width: "100%",
    padding: "10px 12px",
    lineHeight: "1",
    marginTop: "0.5rem",
    borderColor: theme.palette.primary.bordersColor,
    transition: theme.transitions.create([
      "border-color",
      "background-color",
      "box-shadow",
    ]),
    minHeight: " 0.8rem!important",
    "&:focus": {
      boxShadow: `${alpha(theme.palette.primary.main, 0.25)} 0 0 0 0.2rem`,
      borderColor: theme.palette.primary.main,
      color: theme.palette.text.main,
    },
  },
}));

const Signup = () => {
  const [registrationSuccess, setRegistrationSuccess] = useState(false);
  const [open] = React.useState(true);
  const theme = useTheme();
  const { t } = useTranslation();
  const { i18n } = useTranslation();
  const [lang, setLang] = useState("tr");
  const { language, changeLanguage } = useLanguage();
  const [isLoaded, setIsLoaded] = useState(true);
  const [activeStep, setActiveStep] = useState(0);
  const [skipped, setSkipped] = useState(new Set());
  const isStepSkipped = (step) => {
    return skipped.has(step);
  };
  const [openPolicy, setOpenPolicy] = useState(false);
  const [openEmailConsentText, setOpenEmailConsentText] = useState(false);
  const [openCookie, setOpenCookie] = useState(false);
  const [requestError, setRequestError] = useState(null);

  //form states
  const [formErrors, setFormErrors] = useState({});
  const [fullName, setFullName] = useState("");
  const [phone, setPhone] = useState("");
  const [countryCode, setCountryCode] = useState("");
  const [email, setEmail] = useState("");
  const [emailRepeat, setEmailRepeat] = useState("");
  const [brandName, setBrandName] = useState("");
  const [website, setWebsite] = useState("");
  const [category, setCategory] = useState([]);
  const [privacies, setPrivacies] = useState(true);
  const [merchantType, setMerchantType] = useState(
    "0184a67c-b078-44e1-93a9-fb7cada8eb1f"
  );
  const [merchantOwnerOfBrand, setMerchantOwnerOfBrand] = useState(
    "203bcd82-2402-4a14-9932-45b8ac421ff3"
  );
  const [merchantProductCount, setMerchantProductCount] = useState(
    "608cc678-ce70-44c3-ab68-475df4de7602"
  );
  const [isEcommerce, setIsEcommerce] = useState(false);
  const [isExport, setIsExport] = useState(false);
  const [isIntegration, setIsIntegration] = useState(false);
  const [merchnantEcommerceUrls, setMerchnantEcommerceUrls] = useState([]);
  const [productOrigin, setProductOrigin] = useState([]);
  const [pageLoading, setPageLoading] = useState(false);
  const [countryData, setCountryData] = useState([]);
  const [errorMessage, setErrorMessage] = useState(null);
  const [validationStates, setValidationStates] = useState([true]);
  const [promotionCode, setPromotionCode] = useState("");
  const validateField = (fieldName, value) => {
    const newErrors = { ...formErrors };

    // İsim ve Soyisim validasyonu
    if (fieldName === "fullName") {
      // Giriş değerindeki sayıları temizle
      value = value.replace(/[0-9]/g, "");
      if (value.trim() === "") {
        newErrors.fullName = t("theNameCannotBeEmpty");
      } else if (value.length < 3) {
        newErrors.fullName = t("theNameMustBeAtLeastThreeCharacters");
      } else if (value.length > 74) {
        newErrors.fullName = t("theNameMustBeMaximumSeventyFiveCharacters");
      } else {
        newErrors.fullName = "";
      }
    }

    if (fieldName === "phone") {
      if (value.length < 10) {
        newErrors.phone = t("thePhoneMustBeAtLeastTenCharacters");
      } else {
        newErrors.phone = "";
      }
    }
    if (fieldName === "countryCode") {
      if (value.length < 2) {
        newErrors.countryCode = t("countryCodeIsRequired");
      } else {
        newErrors.countryCode = "";
      }
    }
    if (fieldName === "email") {
      newErrors.email = !value.match(/^\S+@\S+\.\S+$/)
        ? t("enterAvalidEmailAddress")
        : "";
    }

    if (fieldName === "email") {
      newErrors.email =
        value.length > 74
          ? t("theEmailMustHaveaMaximumOfSeventyFiveCharacters")
          : "";
    }

    if (fieldName === "email") {
      newErrors.emailRepeat =
        value !== emailRepeat ? t("emailAddressesDoNotMatch") : "";
    }

    if (fieldName === "emailRepeat") {
      newErrors.emailRepeat =
        value !== email ? t("emailAddressesDoNotMatch") : "";
    }

    if (fieldName === "emailRepeat") {
      newErrors.email =
        value.length > 74
          ? t("theEmailMustHaveaMaximumOfSeventyFiveCharacters")
          : "";
    }

    if (fieldName === "emailRepeat") {
      newErrors.emailRepeat =
        value !== email ? t("emailAddressesDoNotMatch") : "";
    }

    if (fieldName === "brandName") {
      newErrors.brandName =
        value.trim() === "" ? t("theCompanyNameCannotBeEmpty") : "";
    }

    if (fieldName === "brandName") {
      newErrors.brandName =
        value.length > 74
          ? t("companyNameShouldBeMaximumSeventyFiveCharacters")
          : "";
    }

    if (fieldName === "brandName") {
      newErrors.brandName =
        value.length < 3 ? t("companyNameMustBeAtLeastThreeCharacters") : "";
    }

    if (fieldName === "website") {
      newErrors.website =
        value.length > 74
          ? t("theWebsiteShouldHaveAMaximumOfSeventyFiveCharacters")
          : "";
    }

    if (fieldName === "category") {
      newErrors.category =
        value.length < 1 ? t("selectAtLeastOneCategory") : "";
    }

    if (fieldName === "productOrigin") {
      newErrors.productOrigin =
        value.length < 1 ? t("selectAtLeastOneProductOrigin") : "";
    }

    if (fieldName === "privacies") {
      newErrors.privacies =
        value === false ? t("youMustAcceptTheConfidentialityAgreement") : "";
    }

    setFormErrors(newErrors);
  };
  const unmask = (maskedValue) => maskedValue.replace(/[^0-9]/g, "");

  const changeLanguageHandler = () => {
    if (lang === "tr") {
      setLang("en");
      changeLanguage("en");
      i18n.changeLanguage("en");
    } else {
      setLang("tr");
      changeLanguage("tr");
      i18n.changeLanguage("tr");
    }
  };

  useEffect(() => {
    if (registrationSuccess) {
      // replace url and replace Page title
      window.history.replaceState(null, null, "/signup/complete");
      document.title = t("registrationSuccessful") + " - MegaMerchant";
      ReactGA.event({
        category: "Signup",
        action: "Signup",
        label: "User registered",
        value: true,
      });
      ReactGA.pageview("/ssignup/complete");
      return () => {
        ReactGA.pageview("/signup/complete");
      };
    }
  }, [registrationSuccess]);

  const fullNameChange = (event) => {
    const validValue = event.target.value.replace(
      /[^A-Za-zğüşıöçĞÜŞİÖÇ\s]/g,
      ""
    );

    // max 75 karakter
    if (validValue.length === 75) {
      // set error message
      validateField("fullName", event.target.value);
      return false;
    }
    setFullName(validValue);
    validateField("fullName", event.target.value);
  };

  const emailChange = (event) => {
    // max 75 karakter
    if (event.target.value.length === 75) {
      // set error message
      validateField("email", event.target.value);
      return false;
    }
    setEmail(event.target.value);
    validateField("email", event.target.value);
  };

  const emailRepeatChange = (event) => {
    if (event.target.value.length === 75) {
      // set error message
      validateField("emailRepeat", event.target.value);
      return false;
    }
    setEmailRepeat(event.target.value);
    validateField("emailRepeat", event.target.value);
  };

  const handleWebSiteChange = (event) => {
    // max 75 karakter
    if (event.target.value.length === 75) {
      // set error message
      validateField("website", event.target.value);
      return false;
    }
    setWebsite(event.target.value);
    validateField("website", event.target.value);
  };

  const phoneChange = (event) => {
    // const unmaskedPhone = event.target.value.replace(/[^0-9]/g, ""); // Maskeden arÄ±ndÄ±rÄ±lmÄ±Å deÄeri al
    // setPhone(event.target.value); // State'i gÃ¼ncelle
    // validateField("phone", unmaskedPhone);
    const maskedValue = event.target.value;
    const unmaskedValue = unmask(maskedValue);
    setPhone(maskedValue); // MaskelenmiÅ deÄeri gÃ¼ncelle
    validateField("phone", unmaskedValue); // Validasyonu yap
  };

  const handlePromotionCodeChange = (event) => {
    setPromotionCode(event.target.value);
  };

  const changeCountryCode = (event) => {
    // setCountryCode(event.target.value);
    const maskedValue = event.target.value;
    const unmaskedValue = maskedValue.replace(/[^0-9]/g, "");
    // validateField("nationCode", event.target.value);
    setCountryCode(maskedValue);
    validateField("countryCode", unmaskedValue);
  };
  const brandNameChange = (event) => {
    // max 75 karakter
    if (event.target.value.length === 75) {
      // set error message
      validateField("brandName", event.target.value);
      return false;
    }
    setBrandName(event.target.value);
    validateField("brandName", event.target.value);
  };

  const handleCategoryChange = (event) => {
    setCategory(event.target.value);
    validateField("category", event.target.value);
  };

  const handleProductOrigin = (event) => {
    setProductOrigin(event.target.value);
    validateField("productOrigin", event.target.value);
  };

  const handlePrivacies = (event) => {
    setPrivacies(event.target.checked);
    validateField("privacies", event.target.checked);
  };

  let addEcommerceFields = (e) => {
    e.preventDefault();
    if (e.key === "Enter") {
      return false;
    }
    if (merchnantEcommerceUrls.every((url) => url.trim() !== "")) {
      setMerchnantEcommerceUrls([...merchnantEcommerceUrls, ""]);
    }
  };

  let removeEcommerceFields = (index) => {
    if (merchnantEcommerceUrls.length < 2) {
      return false;
    } else if (merchnantEcommerceUrls.length > 75) {
      // set error message
      return false;
    } else {
      const values = [...merchnantEcommerceUrls];
      values.splice(index, 1);
      setMerchnantEcommerceUrls(values);
    }
  };

  const handleNext = () => {
    let newSkipped = skipped;
    if (isStepSkipped(activeStep)) {
      newSkipped = new Set(newSkipped.values());
      newSkipped.delete(activeStep);
    }

    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    setSkipped(newSkipped);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleOpenPolicy = () => {
    setOpenPolicy(true);
  };

  const handleOpenEmailConsentText = () => {
    setOpenEmailConsentText(true);
  };

  const handleCloseEmailConsentText = () => {
    setOpenEmailConsentText(false);
  };

  const handleClosePolicy = () => {
    setOpenPolicy(false);
  };

  const handleOpenCookie = () => {
    setOpenCookie(true);
  };

  const handleCloseCookie = () => {
    setOpenCookie(false);
  };

  function sortCountryDataWithTurkeyFirst(countryData) {
    const turkeyId = "f30b42ee-edb6-41b8-a819-565e867041d7";
    const sortedCountryData = [...countryData];
    const turkeyIndex = sortedCountryData.findIndex(
      (item) => item.id === turkeyId
    );

    if (turkeyIndex !== -1) {
      const turkey = sortedCountryData.splice(turkeyIndex, 1)[0];
      sortedCountryData.unshift(turkey);
    }

    return sortedCountryData;
  }

  const options = [
    {
      id: "42d05c20-d106-4919-94e7-bfb345b874a3",
      title: t("electricKitchenAppliances"),
      value: "42d05c20-d106-4919-94e7-bfb345b874a3",
    },
    {
      id: "2a2b4892-7907-44db-8b10-79b03dc1a8b8",
      title: t("electronicsComputer"),
      value: "2a2b4892-7907-44db-8b10-79b03dc1a8b8",
    },
    {
      id: "33be39bb-7d71-4823-8c48-a04f1d3ef576",
      title: t("homeTextiles"),
      value: "33be39bb-7d71-4823-8c48-a04f1d3ef576",
    },
    {
      id: "86d8e1bb-1e5b-41e2-83d7-dabf1ab54b62",
      title: t("clothing"),
      value: "86d8e1bb-1e5b-41e2-83d7-dabf1ab54b62",
    },
    {
      id: "f781c9d2-d352-4524-81c2-152d023c6a1c",
      title: t("foodProductsAndaSupplements"),
      value: "f781c9d2-d352-4524-81c2-152d023c6a1c",
    },
    {
      id: "48b024ee-9d1e-4d1e-83c9-05acf11ac5df",
      title: t("fastMovingConsumerGoods"),
      value: "48b024ee-9d1e-4d1e-83c9-05acf11ac5df",
    },
    {
      id: "bf4980df-c62b-49d3-a17a-bb1c70cbf434",
      title: t("hobbiesAndEntertainmentProducts"),
      value: "bf4980df-c62b-49d3-a17a-bb1c70cbf434",
    },
    {
      id: "d6303117-f198-47f9-af83-4af99458d42d",
      title: t("cosmeticsHealth"),
      value: "d6303117-f198-47f9-af83-4af99458d42d",
    },
    {
      id: "8c9cb67e-7b4b-49aa-9f39-f8d3af05cfec",
      title: t("machineParts"),
      value: "8c9cb67e-7b4b-49aa-9f39-f8d3af05cfec",
    },
    {
      id: "fb841e94-22f6-4512-9f22-fec93b6e8616",
      title: t("furnitureDecoration"),
      value: "fb841e94-22f6-4512-9f22-fec93b6e8616",
    },
    {
      id: "e0b3d64e-042e-4009-9efa-1ffc85c205ef",
      title: t("jewelryAndAccessories"),
      value: "e0b3d64e-042e-4009-9efa-1ffc85c205ef",
    },
    {
      id: "bc29cc49-978f-4dab-bd14-aac14490356d",
      title: t("officeSuppliesAndStationery"),
      value: "bc29cc49-978f-4dab-bd14-aac14490356d",
    },
    {
      id: "b6e70ae3-c6ea-4ebf-82dc-d26b95668ee7",
      title: t("automotive"),
      value: "b6e70ae3-c6ea-4ebf-82dc-d26b95668ee7",
    },
    {
      id: "ac6039b8-4b55-447e-b968-edfe2ac13448",
      title: t("toy"),
      value: "ac6039b8-4b55-447e-b968-edfe2ac13448",
    },
    {
      id: "09432459-b407-4018-8f4f-80da23070a22",
      title: t("petShop"),
      value: "09432459-b407-4018-8f4f-80da23070a22",
    },
    {
      id: "5ece11ae-a8b1-448e-ae28-6baef40cf6fe",
      title: t("sporOutdoor"),
      value: "5ece11ae-a8b1-448e-ae28-6baef40cf6fe",
    },
    {
      id: "5d78da72-a035-4a69-a564-fb95d4d6bdfd",
      title: t("buildingMarket"),
      value: "5d78da72-a035-4a69-a564-fb95d4d6bdfd",
    },
    {
      id: "788435f7-2508-412c-8f22-f56946e6dcd2",
      title: t("glassware"),
      value: "788435f7-2508-412c-8f22-f56946e6dcd2",
    },
    {
      id: "2e39504a-594d-4b41-bcf7-72b653616b06",
      title: t("other"),
      value: "2e39504a-594d-4b41-bcf7-72b653616b06",
    },
    {
      id: "493fd4bc-7736-48c3-a279-c3a9002b247c",
      title: t("motherBabyProducts"),
      value: "493fd4bc-7736-48c3-a279-c3a9002b247c",
    },
    {
      id: "ef61882f-4e65-49c0-a674-abb5bd06877c",
      title: t("shoesAndBags"),
      value: "ef61882f-4e65-49c0-a674-abb5bd06877c",
    },
    {
      id: "30766d72-0e74-4a3e-af15-f5cfcd043432",
      title: t("garden"),
      value: "30766d72-0e74-4a3e-af15-f5cfcd043432",
    },
  ];

  const handlePaste = (event) => {
    event.preventDefault(); // YapÄ±ÅtÄ±rma iÅlemini engelle
    alert("Copy-paste yapılamaz.");
  };
  const submitHandler = async (e) => {
    e.preventDefault();
    if (e.key === "Enter") {
      e.preventDefault();
      return false;
    }

    let newSkipped = skipped;
    if (isStepSkipped(activeStep)) {
      newSkipped = new Set(newSkipped.values());
      newSkipped.delete(activeStep);
    }

    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    setSkipped(newSkipped);
    const data = {
      nameSurname: fullName,
      email: email,
      phoneNumber: phone,
      webAddress: website,
      merchantCategoryList: category,
      merchantTypeId: merchantType,
      password: "",
      passwordConfirm: "",
      merchantOwnerOfBrandId: merchantOwnerOfBrand,
      merchantProductCountId: merchantProductCount,
      isEcommerce: Boolean(isEcommerce),
      isExport: Boolean(isExport),
      isIntegration: Boolean(isIntegration),
      merchnantEcommerceUrlList: merchnantEcommerceUrls,
      productOriginList: productOrigin,
      username: email,
      title: brandName,
      // if promotion code is not empty, add it to the data
      ...(promotionCode && { promoCode: promotionCode }),
    };

    let config = {
      method: "post",
      maxBodyLength: Infinity,
      url: `${process.env.REACT_APP_BASE_URL}/Merchant`,
      data: data,
    };
    try {
      console.log("data", data);
      const response = await axios(config);
      setRequestError(response.status);
      setIsLoaded(false);
      setRegistrationSuccess(true);
    } catch (error) {
      setRequestError(error.response.status);
      setErrorMessage(error.response.data.message);
      setIsLoaded(false);
    }
  };

  // get country data function
  const getCountryData = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BASE_URL}/Country?Pagination.Page=0&Pagination.Size=100`
      );
      setCountryData(response.data.items);
    } catch (error) {}
  };

  // fetch country data
  useEffect(() => {
    getCountryData();
    setPageLoading(false);
  }, []);

  useEffect(() => {
    if (isEcommerce) {
      setMerchnantEcommerceUrls([""]);
    }
  }, [isEcommerce]);

  if (pageLoading) return <Spinner />;
  return (
    <div>
      <Dialog
        fullScreen
        open={open}
        TransitionComponent={Transition}
        className="signup-div"
      >
        <AppBar sx={{ position: "relative", background: "#fff", mt: 0 }}>
          <Toolbar>
            <Typography
              sx={{
                ml: 2,
                flex: 1,
                color: `${theme.palette.primary.fontColor}!important`,
              }}
            >
              <span>
                <Link
                  to="/"
                  style={{
                    textDecoration: "underline",
                    color: "rgba(85, 56, 48, 0.9)",
                  }}
                >
                  {t("home")}
                </Link>
              </span>
              <span style={{ fontWeight: "500", fontSize: "18px" }}>
                {" "}
                {">"}{" "}
              </span>
              <span style={{ fontWeight: "500" }}>{t("registrationForm")}</span>
            </Typography>
            <IconButton
              edge="start"
              color="text"
              onClick={() => window.history.back()}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
          </Toolbar>
        </AppBar>
        <Container maxWidth="md" className="signup-container">
          <Box
            sx={{ width: "100%", background: "#fff", mt: 3, p: 2, mb: "50px" }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                alignItems: "end",
                marginLeft: "2em",
              }}
            >
              {activeStep !== 3 && (
                <Typography variant="h1">{t("registrationForm")}</Typography>
              )}
              {activeStep !== 3 && (
                <Stepper
                  activeStep={activeStep}
                  sx={{
                    width: "250px",
                    marginLeft: "15px",
                    marginRight: "15px",
                    pt: 5,
                  }}
                >
                  {steps.map((label, index) => {
                    const stepProps = {};
                    const labelProps = {};
                    if (isStepSkipped(index)) {
                      stepProps.completed = false;
                    }

                    return (
                      <Step key={label} {...stepProps} sx={{ color: "red" }}>
                        <StepLabel {...labelProps}></StepLabel>
                      </Step>
                    );
                  })}
                </Stepper>
              )}
            </Box>
            {activeStep === 0 && (
              <Box
                sx={{
                  marginLeft: "2em",
                  marginRight: "2em",
                  marginTop: "1em",
                }}
              >
                <Typography
                  sx={{
                    fontSize: "14px",
                    color: theme.palette.text.main,
                  }}
                >
                  {t(
                    "youCanOpenYourAccountForOMegaTheB2BPortalOfMegaMerchantTurkeysFirstEexportConsortium"
                  )}
                </Typography>
              </Box>
            )}
            {activeStep === 1 && (
              <Box
                sx={{
                  marginLeft: "2em",
                  marginRight: "2em",
                  marginTop: "1em",
                }}
              >
                <Typography
                  sx={{
                    fontSize: "14px",
                    color: theme.palette.text.main,
                  }}
                >
                  {t("endToEndEexportBlaBla")}
                </Typography>
              </Box>
            )}
            <Form onSubmit={submitHandler} method="post">
              {activeStep === 3 ? (
                <Box
                  sx={{
                    mb: 1,
                    pb: 1,
                    pt: 0,
                    "& > :not(style)": { m: 1 },
                  }}
                >
                  {isLoaded === true ? (
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <CircularProgress />
                    </Box>
                  ) : (
                    <Grid container spacing={2}>
                      {requestError === 200 ? (
                        <Grid item xs={12} sm={12}>
                          <Stack direction="row" alignItems="center" gap={1}>
                            <CheckIcon color="success" />
                            <Typography
                              variant="h6"
                              fontWeight="bold"
                              color={theme.palette.success.main}
                            >
                              {t("thankYouForYourApplicationRequest")}
                            </Typography>
                          </Stack>
                          <Typography
                            sx={{ paddingLeft: "1rem", paddingRight: "1rem" }}
                          >
                            {t("weReceivedYourApplicationBlaBla")}
                            <br />
                            {t(
                              "weWillContactYouWithinFourtyEightHoursAfterReviewingYourInformationWeWillGetThere"
                            )}
                          </Typography>
                          <Typography
                            sx={{ marginTop: "2rem", paddingLeft: "1rem" }}
                          >
                            {t("regards")},
                            <br />
                            MegaMerchant
                            <br />
                            T.C. {t("ministryOfTrade")}
                            <br />
                            {t("eExportConsortium")}
                          </Typography>
                        </Grid>
                      ) : (
                        <Grid item xs={12} sm={12}>
                          <Stack direction="row" alignItems="center" gap={1}>
                            <CheckIcon color="warning" />
                            <Typography
                              variant="h6"
                              fontWeight="bold"
                              color={theme.palette.primary.primaryRed}
                            >
                              {t("anErrorOccurred")}
                            </Typography>
                          </Stack>
                          <Typography>{errorMessage}</Typography>
                        </Grid>
                      )}
                    </Grid>
                  )}
                </Box>
              ) : (
                <React.Fragment>
                  <Box>
                    {activeStep === 0 && (
                      <Box
                        sx={{
                          backgroundColor: "#fff",
                          p: 2,

                          "& > :not(style)": { m: 1 },
                        }}
                        noValidate
                        autoComplete="off"
                      >
                        <FormGroup>
                          <BoxTitle title={t("contactInformation")} />
                          <Grid container spacing={2} sx={{ mb: 1 }}>
                            <Grid item xs={12} sm={6}>
                              <FormControl
                                variant="standard"
                                key={"fullName"}
                                className="signup-fc"
                              >
                                <CustomLabel
                                  title={t("nameSurname")}
                                  isRequired
                                />
                                <BootstrapInput
                                  placeholder={t("nameSurname")}
                                  value={fullName}
                                  onChange={fullNameChange}
                                  className={
                                    formErrors.fullName ? "error" : "  "
                                  }
                                />
                                {formErrors.fullName && (
                                  <span className="error-message">
                                    {formErrors.fullName}
                                  </span>
                                )}
                              </FormControl>
                            </Grid>
                            <Grid item xs={12} sm={6}>
                              <Box>
                                <Grid container>
                                  <Grid item xs={12} sm={3}>
                                    {" "}
                                    <FormControl
                                      variant="standard"
                                      className="signup-fc"
                                      // sx={{ width: "100px!important" }}
                                    >
                                      <CustomLabel
                                        title={t("phone")}
                                        isRequired
                                      />
                                      <InputMask
                                        mask="+999"
                                        maskChar=" "
                                        value={countryCode}
                                        onChange={changeCountryCode}
                                      >
                                        {() => (
                                          <BootstrapNationInput
                                            placeholder={t("countryCode")}
                                          />
                                        )}
                                      </InputMask>
                                      {formErrors.countryCode && (
                                        <span className="error-message">
                                          {formErrors.countryCode}
                                        </span>
                                      )}
                                    </FormControl>
                                  </Grid>
                                  <Grid item xs={12} sm={9}>
                                    <FormControl
                                      variant="standard"
                                      className="signup-fc"
                                      sx={{ width: "100%!important" }}
                                    >
                                      <CustomLabel />
                                      <InputMask
                                        mask="(999)-999-9999"
                                        maskChar=" "
                                        value={phone}
                                        onChange={phoneChange}
                                      >
                                        {() => (
                                          <BootstrapPhoneInput
                                            placeholder={t("phoneNumber")}
                                            style={{
                                              width: "100%",
                                              borderRadius:
                                                "0 2px 2px 0!important",
                                            }}
                                          />
                                        )}
                                      </InputMask>
                                      {formErrors.phone && (
                                        <span className="error-message">
                                          {formErrors.phone}
                                        </span>
                                      )}
                                    </FormControl>
                                  </Grid>
                                </Grid>
                              </Box>
                            </Grid>
                          </Grid>
                          <Grid container spacing={2}>
                            <Grid item xs={12} sm={6}>
                              <FormControl
                                variant="standard"
                                key={"email"}
                                className="signup-fc"
                              >
                                <CustomLabel title={t("eMail")} isRequired />
                                <BootstrapInput
                                  placeholder={t("eMail")}
                                  value={email}
                                  onChange={emailChange}
                                />
                                {formErrors.email && (
                                  <span className="error-message">
                                    {formErrors.email}
                                  </span>
                                )}
                              </FormControl>
                            </Grid>
                            <Grid item xs={12} sm={6}>
                              <FormControl
                                variant="standard"
                                key={"emailRepeat"}
                                className="signup-fc"
                              >
                                <CustomLabel
                                  title={t("eMailRepeat")}
                                  isRequired
                                />
                                <BootstrapInput
                                  className="signup-fc"
                                  placeholder={t("eMailRepeat")}
                                  value={emailRepeat}
                                  onChange={emailRepeatChange}
                                  onPaste={handlePaste}
                                />
                                {
                                  <span className="error-message">
                                    {formErrors.emailRepeat}
                                  </span>
                                }
                              </FormControl>
                            </Grid>
                            <Grid item xs={12} sm={12}>
                              <Box
                                sx={{
                                  background: "#F7F6F5",
                                  display: "flex",
                                  flexDirection: "row",
                                  alignItems: "center",
                                }}
                              >
                                <FormControlLabel
                                  sx={{ ml: 1, pt: 0.5, pb: 0.5, mr: 0 }}
                                  value="end"
                                  control={
                                    <Checkbox
                                      defaultChecked={privacies}
                                      onChange={handlePrivacies}
                                    />
                                  }
                                />
                                <Typography>
                                  {t("whenYouContinue")}{" "}
                                  <a
                                    style={{
                                      fontStyle: "italic",
                                      color: "rgb(78, 115, 205)",
                                      fontWeight: "500",
                                      cursor: "pointer",
                                    }}
                                    onClick={handleOpenPolicy}
                                  >
                                    {t("TermsOfUseAndPrivacyPolicy")}
                                  </a>
                                  {", "}
                                  <a
                                    style={{
                                      fontStyle: "italic",
                                      color: "rgb(78, 115, 205)",
                                      fontWeight: "500",
                                      cursor: "pointer",
                                    }}
                                    onClick={handleOpenCookie}
                                  >
                                    {t("cookiePolicy")}
                                  </a>
                                  ,{" "}
                                  <a
                                    style={{
                                      fontStyle: "italic",
                                      color: "rgb(78, 115, 205)",
                                      fontWeight: "500",
                                      cursor: "pointer",
                                    }}
                                    onClick={handleOpenEmailConsentText}
                                  >
                                    {t(
                                      "explicitConsentTextForSendingSmsAndEMailMessages"
                                    )}
                                  </a>{" "}
                                  {t("youAcceptIt")}
                                </Typography>
                              </Box>
                              <Box sx={{ ml: "2em", mr: "2em" }}>
                                {formErrors.privacies && (
                                  <span className="error-message">
                                    {formErrors.privacies}
                                  </span>
                                )}
                              </Box>
                            </Grid>
                          </Grid>
                        </FormGroup>
                      </Box>
                    )}
                    {activeStep === 1 && (
                      <Box
                        sx={{
                          backgroundColor: "#fff",
                          p: 2,
                          "& > :not(style)": { m: 1 },
                        }}
                        noValidate
                        autoComplete="off"
                      >
                        <FormGroup>
                          <BoxTitle title={t("companyInfo")} />
                          <Grid container spacing={2} sx={{ mb: 1 }}>
                            <Grid item xs={12} sm={12}>
                              <FormControl
                                variant="standard"
                                key={"brandName"}
                                className="signup-fc"
                              >
                                <CustomLabel
                                  title={t("companyName")}
                                  isRequired
                                />
                                <BootstrapInput
                                  placeholder={t("companyName")}
                                  value={brandName}
                                  onChange={brandNameChange}
                                />
                                {
                                  <span className="error-message">
                                    {formErrors.brandName}
                                  </span>
                                }
                              </FormControl>
                            </Grid>
                          </Grid>
                          <Grid container spacing={2}>
                            <Grid item xs={12} sm={6}>
                              <FormControl
                                variant="standard"
                                key={"website"}
                                className="signup-fc"
                              >
                                <CustomLabel title={t("webAddress")} />
                                <BootstrapInput
                                  placeholder={t("webAddress")}
                                  value={website}
                                  onChange={handleWebSiteChange}
                                />
                                {
                                  <span className="error-message">
                                    {formErrors.website}
                                  </span>
                                }
                              </FormControl>
                            </Grid>
                            <Grid item xs={12} sm={6}>
                              <FormControl className="signup-fc">
                                <Typography
                                  sx={{
                                    fontSize: "13px",
                                    fontWeight: "500",
                                    color: theme.palette.text.main,
                                    mt: "4px",
                                  }}
                                >
                                  {t("category")}
                                  <span
                                    style={{
                                      color: "rgb(215, 40, 62)",
                                      marginLeft: "0.5rem",
                                    }}
                                  >
                                    *
                                  </span>
                                </Typography>
                                <Select
                                  labelId="demo-multiple-checkbox-label"
                                  id="demo-multiple-checkbox"
                                  multiple
                                  required
                                  displayEmpty
                                  value={category}
                                  input={<BootstrapSelect />}
                                  onChange={handleCategoryChange}
                                  renderValue={(selected) => (
                                    <div>
                                      {selected.length === 0 ? (
                                        <em
                                          style={{
                                            color: "currentColor",
                                            opacity: "0.42",
                                          }}
                                        >
                                          {t("selectCategory")}
                                        </em>
                                      ) : (
                                        selected.map((id, index) => {
                                          const selectedItem = options.find(
                                            (item) => item.id === id
                                          );
                                          return (
                                            <span key={selectedItem.id}>
                                              {selectedItem.title}
                                              {index !== selected.length - 1
                                                ? ", "
                                                : ""}
                                            </span>
                                          );
                                        })
                                      )}
                                    </div>
                                  )}
                                >
                                  {options
                                    .sort((a, b) => {
                                      const titleA = a.title.toLowerCase();
                                      const titleB = b.title.toLowerCase();
                                      return titleA.localeCompare(titleB);
                                    })
                                    .map((opt) => (
                                      <MenuItem
                                        key={opt.value}
                                        value={opt.value}
                                        sx={{
                                          paddignTop: "0px!important",
                                          paddingBottom: "0px!important",
                                        }}
                                      >
                                        <Checkbox
                                          size="small"
                                          checked={category.includes(opt.value)}
                                        />
                                        <ListItemText primary={opt.title} />
                                      </MenuItem>
                                    ))}
                                </Select>
                              </FormControl>
                            </Grid>
                          </Grid>
                          <Grid container spacing={2}>
                            <Grid item xs={12} sm={6}>
                              <FormControl
                                sx={{ mt: 1 }}
                                variant="standard"
                                key="merchantType"
                              >
                                <CustomLabel
                                  title={t("yourCompany")}
                                  isRequired
                                />
                                <RadioGroup
                                  sx={{ pt: "35px", ml: "10px" }}
                                  row
                                  aria-labelledby="demo-row-radio-buttons-group-label"
                                  name="row-radio-buttons-group"
                                  value={merchantType}
                                  onChange={(e) =>
                                    setMerchantType(e.target.value)
                                  }
                                >
                                  <FormControlLabel
                                    color="success"
                                    value={
                                      "2b355710-3b9e-43e5-a394-40ef09b85a86"
                                    }
                                    control={
                                      <Radio
                                        sx={{
                                          color: "#45383426",
                                          "&.Mui-checked": {
                                            color: "#34AE1A",
                                          },
                                        }}
                                      />
                                    }
                                    className={
                                      merchantType ===
                                      "2b355710-3b9e-43e5-a394-40ef09b85a86"
                                        ? "custom-radio-checked"
                                        : "custom-radio"
                                    }
                                    label={
                                      <Typography
                                        fontWeight="500"
                                        color={
                                          merchantType ===
                                          "2b355710-3b9e-43e5-a394-40ef09b85a86"
                                            ? theme.palette.success.main
                                            : "#453834"
                                        }
                                        fontSize="14px"
                                      >
                                        {t("producer")}
                                      </Typography>
                                    }
                                  />
                                  <FormControlLabel
                                    value={
                                      "3ed05c84-dbac-4d05-a9b5-494c006baeca"
                                    }
                                    control={
                                      <Radio
                                        sx={{
                                          color: "#45383426",
                                          "&.Mui-checked": {
                                            color: "#34AE1A",
                                          },
                                        }}
                                      />
                                    }
                                    label={
                                      <Typography
                                        fontWeight="500"
                                        color={
                                          merchantType ===
                                          "3ed05c84-dbac-4d05-a9b5-494c006baeca"
                                            ? theme.palette.success.main
                                            : "#453834"
                                        }
                                        fontSize="14px"
                                      >
                                        {t("distributor")}
                                      </Typography>
                                    }
                                    className={
                                      merchantType ===
                                      "3ed05c84-dbac-4d05-a9b5-494c006baeca"
                                        ? "custom-radio-checked"
                                        : "custom-radio"
                                    }
                                  />
                                  <FormControlLabel
                                    value={
                                      "0184a67c-b078-44e1-93a9-fb7cada8eb1f"
                                    }
                                    control={
                                      <Radio
                                        sx={{
                                          color: "#45383426",
                                          "&.Mui-checked": {
                                            color: "#34AE1A",
                                          },
                                        }}
                                      />
                                    }
                                    label={
                                      <Typography
                                        fontWeight="500"
                                        color={
                                          merchantType ===
                                          "0184a67c-b078-44e1-93a9-fb7cada8eb1f"
                                            ? theme.palette.success.main
                                            : "#453834"
                                        }
                                        fontSize="14px"
                                      >
                                        {t("none")}
                                      </Typography>
                                    }
                                    className={
                                      merchantType ===
                                      "0184a67c-b078-44e1-93a9-fb7cada8eb1f"
                                        ? "custom-radio-checked"
                                        : "custom-radio"
                                    }
                                  />
                                </RadioGroup>
                              </FormControl>
                            </Grid>
                            <Grid item xs={12} sm={6}>
                              <FormControl
                                sx={{ mt: 1 }}
                                variant="standard"
                                key="ourBrand"
                              >
                                <CustomLabel
                                  title={t("yourBrandOwnership")}
                                  isRequired
                                />
                                <RadioGroup
                                  sx={{ pt: "35px", ml: "10px" }}
                                  row
                                  aria-labelledby="demo-row-radio-buttons-group-label"
                                  name="row-radio-buttons-group"
                                  value={merchantOwnerOfBrand}
                                  onChange={(e) =>
                                    setMerchantOwnerOfBrand(e.target.value)
                                  }
                                >
                                  <FormControlLabel
                                    value={
                                      "54e45b3b-7092-4804-99e5-763f84b96c01"
                                    }
                                    control={
                                      <Radio
                                        sx={{
                                          color: "#45383426",
                                          "&.Mui-checked": {
                                            color: "#34AE1A",
                                          },
                                        }}
                                      />
                                    }
                                    className={
                                      merchantOwnerOfBrand ===
                                      "54e45b3b-7092-4804-99e5-763f84b96c01"
                                        ? "custom-radio-checked"
                                        : "custom-radio"
                                    }
                                    label={
                                      <Typography
                                        fontWeight="500"
                                        color={
                                          merchantOwnerOfBrand ===
                                          "54e45b3b-7092-4804-99e5-763f84b96c01"
                                            ? theme.palette.success.main
                                            : "#453834"
                                        }
                                        fontSize="14px"
                                      >
                                        {t("registered")}
                                      </Typography>
                                    }
                                  />
                                  <FormControlLabel
                                    value={
                                      "91ed6ce2-5b46-4d14-9a6e-53f687963ede"
                                    }
                                    className={
                                      merchantOwnerOfBrand ===
                                      "91ed6ce2-5b46-4d14-9a6e-53f687963ede"
                                        ? "custom-radio-checked"
                                        : "custom-radio"
                                    }
                                    control={
                                      <Radio
                                        sx={{
                                          color: "#45383426",
                                          "&.Mui-checked": {
                                            color: "#34AE1A",
                                          },
                                        }}
                                      />
                                    }
                                    label={
                                      <Typography
                                        fontWeight="500"
                                        color={
                                          merchantOwnerOfBrand ===
                                          "91ed6ce2-5b46-4d14-9a6e-53f687963ede"
                                            ? theme.palette.success.main
                                            : "#453834"
                                        }
                                        fontSize="14px"
                                      >
                                        {t("inApplication")}
                                      </Typography>
                                    }
                                  />
                                  <FormControlLabel
                                    value={
                                      "203bcd82-2402-4a14-9932-45b8ac421ff3"
                                    }
                                    className={
                                      merchantOwnerOfBrand ===
                                      "203bcd82-2402-4a14-9932-45b8ac421ff3"
                                        ? "custom-radio-checked"
                                        : "custom-radio"
                                    }
                                    control={
                                      <Radio
                                        sx={{
                                          color: "#45383426",
                                          "&.Mui-checked": {
                                            color: "#34AE1A",
                                          },
                                        }}
                                      />
                                    }
                                    label={
                                      <Typography
                                        fontWeight="500"
                                        color={
                                          merchantOwnerOfBrand ===
                                          "03bcd82-2402-4a14-9932-45b8ac421ff3"
                                            ? theme.palette.success.main
                                            : "#453834"
                                        }
                                        fontSize="14px"
                                      >
                                        {t("none2")}
                                      </Typography>
                                    }
                                  />
                                </RadioGroup>
                              </FormControl>
                            </Grid>
                          </Grid>
                        </FormGroup>
                      </Box>
                    )}
                    {activeStep === 2 && (
                      <Box
                        sx={{
                          backgroundColor: "#fff",
                          p: 2,
                          "& > :not(style)": { m: 1 },
                        }}
                        noValidate
                        autoComplete="off"
                      >
                        <FormGroup>
                          <BoxTitle
                            title={t("details")}
                            style={{
                              marginBottom: "10px",
                            }}
                          />
                          <Grid container spacing={2} sx={{ mb: 2 }}>
                            <Grid item xs={12} sm={12}>
                              <FormControl
                                variant="standard"
                                key={"productOrigin"}
                                className="signup-fc"
                                sx={{ mt: 1 }}
                              >
                                <Typography
                                  sx={{
                                    fontSize: "13px",
                                    fontWeight: "500",
                                    color: theme.palette.text.main,
                                    mt: "4px",
                                  }}
                                >
                                  {t("originOfProducts")}
                                  <span
                                    style={{
                                      color: "rgb(215, 40, 62)",
                                      marginLeft: "0.5rem",
                                    }}
                                  >
                                    *
                                  </span>
                                </Typography>
                                <Select
                                  labelId="demo-multiple-2-checkbox-label"
                                  id="demo-multiple-2-checkbox"
                                  required
                                  displayEmpty
                                  multiple
                                  value={productOrigin}
                                  input={<BootstrapSelect />}
                                  onChange={handleProductOrigin}
                                  renderValue={(selected) => (
                                    <div>
                                      {selected.length === 0 ? (
                                        <em
                                          style={{
                                            color: "currentColor",
                                            opacity: "0.42",
                                          }}
                                        >
                                          {t("selectProductOrigin")}
                                        </em>
                                      ) : (
                                        selected.map((id, index) => {
                                          const selectedItem = countryData.find(
                                            (item) => item.id === id
                                          );
                                          return (
                                            <span key={selectedItem.id}>
                                              {selectedItem.title}{" "}
                                              {index !== selected.length - 1
                                                ? ", "
                                                : ""}
                                            </span>
                                          );
                                        })
                                      )}
                                    </div>
                                  )}
                                >
                                  {sortCountryDataWithTurkeyFirst(
                                    countryData
                                  ).map((opt) => (
                                    <MenuItem
                                      key={opt.id}
                                      value={opt.id}
                                      sx={{
                                        paddignTop: "0px!important",
                                        paddingBottom: "0px!important",
                                      }}
                                    >
                                      <Checkbox
                                        size="small"
                                        checked={
                                          productOrigin.indexOf(opt.id) > -1
                                        }
                                      />
                                      <ListItemText primary={opt.title} />
                                    </MenuItem>
                                  ))}
                                </Select>
                              </FormControl>
                            </Grid>
                            <Grid item xs={12} sm={12}>
                              <FormControl variant="standard" key="ourBrand">
                                <CustomLabel
                                  title={t("numberOfProducts")}
                                  isRequired
                                />
                                <RadioGroup
                                  sx={{ pt: "35px", ml: "10px" }}
                                  row
                                  aria-labelledby="demo-row-radio-buttons-group-label"
                                  name="row-radio-buttons-group"
                                  value={merchantProductCount}
                                  onChange={(e) =>
                                    setMerchantProductCount(e.target.value)
                                  }
                                >
                                  <FormControlLabel
                                    color="success"
                                    className={
                                      merchantProductCount ===
                                      "608cc678-ce70-44c3-ab68-475df4de7602"
                                        ? "custom-radio-checked"
                                        : "custom-radio"
                                    }
                                    value={
                                      "608cc678-ce70-44c3-ab68-475df4de7602"
                                    }
                                    control={
                                      <Radio
                                        sx={{
                                          color: "#45383426",
                                          "&.Mui-checked": {
                                            color: "#34AE1A",
                                          },
                                        }}
                                      />
                                    }
                                    label={
                                      <Typography
                                        fontWeight="500"
                                        color={
                                          merchantProductCount ===
                                          "608cc678-ce70-44c3-ab68-475df4de7602"
                                            ? theme.palette.success.main
                                            : "#453834"
                                        }
                                        fontSize="14px"
                                      >
                                        1-10
                                      </Typography>
                                    }
                                  />
                                  <FormControlLabel
                                    className={
                                      merchantProductCount ===
                                      "2273b998-14b2-4ec4-9514-f8c3ec2c29c5"
                                        ? "custom-radio-checked"
                                        : "custom-radio"
                                    }
                                    value={
                                      "2273b998-14b2-4ec4-9514-f8c3ec2c29c5"
                                    }
                                    control={
                                      <Radio
                                        sx={{
                                          color: "#45383426",
                                          "&.Mui-checked": {
                                            color: "#34AE1A",
                                          },
                                        }}
                                      />
                                    }
                                    label={
                                      <Typography
                                        fontWeight="500"
                                        color={
                                          merchantProductCount ===
                                          "2273b998-14b2-4ec4-9514-f8c3ec2c29c5"
                                            ? theme.palette.success.main
                                            : "#453834"
                                        }
                                        fontSize="14px"
                                      >
                                        10-100
                                      </Typography>
                                    }
                                  />
                                  <FormControlLabel
                                    className={
                                      merchantProductCount ===
                                      "b6bfe65b-8889-4de3-a162-7250c34027d3"
                                        ? "custom-radio-checked"
                                        : "custom-radio"
                                    }
                                    value={
                                      "b6bfe65b-8889-4de3-a162-7250c34027d3"
                                    }
                                    control={
                                      <Radio
                                        sx={{
                                          color: "#45383426",
                                          "&.Mui-checked": {
                                            color: "#34AE1A",
                                          },
                                        }}
                                      />
                                    }
                                    label={
                                      <Typography
                                        fontWeight="500"
                                        color={
                                          merchantProductCount ===
                                          "b6bfe65b-8889-4de3-a162-7250c34027d3"
                                            ? theme.palette.success.main
                                            : "#453834"
                                        }
                                        fontSize="14px"
                                      >
                                        100-1000
                                      </Typography>
                                    }
                                  />
                                  <FormControlLabel
                                    className={
                                      merchantProductCount ===
                                      "4eef6c70-2d34-4b82-94c9-4d3d171ef4e7"
                                        ? "custom-radio-checked"
                                        : "custom-radio"
                                    }
                                    value={
                                      "4eef6c70-2d34-4b82-94c9-4d3d171ef4e7"
                                    }
                                    control={
                                      <Radio
                                        sx={{
                                          color: "#45383426",
                                          "&.Mui-checked": {
                                            color: "#34AE1A",
                                          },
                                        }}
                                      />
                                    }
                                    label={
                                      <Typography
                                        fontWeight="500"
                                        color={
                                          merchantProductCount ===
                                          "4eef6c70-2d34-4b82-94c9-4d3d171ef4e7"
                                            ? theme.palette.success.main
                                            : "#453834"
                                        }
                                        fontSize="14px"
                                      >
                                        1000+
                                      </Typography>
                                    }
                                  />
                                </RadioGroup>
                              </FormControl>
                            </Grid>
                            <Grid item xs={12} className="last-grid-signup">
                              <Grid container spacing={2}>
                                <Grid item xs={12} sm={4}>
                                  <FormControl
                                    variant="standard"
                                    key="isEcommerce"
                                  >
                                    <CustomLabel
                                      title={t("doYouDoECommerce")}
                                      isRequired
                                    />
                                    <RadioGroup
                                      sx={{ pt: "35px", ml: "10px" }}
                                      row
                                      aria-labelledby="demo-row-radio-buttons-group-label"
                                      name="row-radio-buttons-group"
                                      value={isEcommerce}
                                      onChange={() =>
                                        setIsEcommerce(!isEcommerce)
                                      }
                                    >
                                      <FormControlLabel
                                        value={true}
                                        control={
                                          <Radio
                                            sx={{
                                              color: "#45383426",
                                              "&.Mui-checked": {
                                                color: "#34AE1A",
                                              },
                                            }}
                                          />
                                        }
                                        label={
                                          <Typography
                                            fontWeight="500"
                                            color={
                                              isEcommerce === true
                                                ? theme.palette.success.main
                                                : "#453834"
                                            }
                                            fontSize="14px"
                                          >
                                            {t("yes")}
                                          </Typography>
                                        }
                                        className={
                                          isEcommerce === true
                                            ? "custom-radio-checked"
                                            : "custom-radio"
                                        }
                                      />
                                      <FormControlLabel
                                        value={false}
                                        control={
                                          <Radio
                                            sx={{
                                              color: "#45383426",
                                              "&.Mui-checked": {
                                                color: "#34AE1A",
                                              },
                                            }}
                                          />
                                        }
                                        label={
                                          <Typography
                                            fontWeight="500"
                                            color={
                                              isEcommerce === false
                                                ? theme.palette.success.main
                                                : "#453834"
                                            }
                                            fontSize="14px"
                                          >
                                            {t("no")}
                                          </Typography>
                                        }
                                        className={
                                          isEcommerce === false
                                            ? "custom-radio-checked"
                                            : "custom-radio"
                                        }
                                      />
                                    </RadioGroup>
                                  </FormControl>
                                </Grid>
                                <Grid item xs={12} sm={4}>
                                  <FormControl
                                    variant="standard"
                                    key="isExport"
                                  >
                                    <CustomLabel
                                      title={t("doYouExport")}
                                      isRequired
                                    />
                                    <RadioGroup
                                      sx={{ pt: "35px", ml: "10px" }}
                                      row
                                      aria-labelledby="demo-row-radio-buttons-group-label"
                                      name="row-radio-buttons-group"
                                      value={isExport}
                                      onChange={(e) =>
                                        setIsExport(e.target.value)
                                      }
                                    >
                                      <FormControlLabel
                                        value={true}
                                        control={
                                          <Radio
                                            sx={{
                                              color: "#45383426",
                                              "&.Mui-checked": {
                                                color: "#34AE1A",
                                              },
                                            }}
                                          />
                                        }
                                        label={
                                          <Typography
                                            fontWeight="500"
                                            color={
                                              isExport === true
                                                ? theme.palette.success.main
                                                : "#453834"
                                            }
                                            fontSize="14px"
                                          >
                                            {t("yes")}
                                          </Typography>
                                        }
                                        className={
                                          isExport === true
                                            ? "custom-radio-checked"
                                            : "custom-radio"
                                        }
                                      />
                                      <FormControlLabel
                                        value={false}
                                        control={
                                          <Radio
                                            sx={{
                                              color: "#45383426",
                                              "&.Mui-checked": {
                                                color: "#34AE1A",
                                              },
                                            }}
                                          />
                                        }
                                        label={
                                          <Typography
                                            fontWeight="500"
                                            color={
                                              isExport === false
                                                ? theme.palette.success.main
                                                : "#453834"
                                            }
                                            fontSize="14px"
                                          >
                                            {t("no")}
                                          </Typography>
                                        }
                                        className={
                                          isExport === false
                                            ? "custom-radio-checked"
                                            : "custom-radio"
                                        }
                                      />
                                    </RadioGroup>
                                  </FormControl>
                                </Grid>
                                <Grid item xs={12} sm={4}>
                                  <FormControl
                                    variant="standard"
                                    key="ourBrand"
                                  >
                                    <CustomLabel
                                      title={t("integrationUsage")}
                                      isRequired
                                    />
                                    <RadioGroup
                                      sx={{ pt: "35px", ml: "10px" }}
                                      row
                                      aria-labelledby="demo-row-radio-buttons-group-label"
                                      name="row-radio-buttons-group"
                                      value={isIntegration}
                                      onChange={(e) =>
                                        setIsIntegration(e.target.value)
                                      }
                                    >
                                      <FormControlLabel
                                        value={true}
                                        control={
                                          <Radio
                                            sx={{
                                              color: "#45383426",
                                              "&.Mui-checked": {
                                                color: "#34AE1A",
                                              },
                                            }}
                                          />
                                        }
                                        label={
                                          <Typography
                                            fontWeight="500"
                                            color={
                                              isIntegration === true
                                                ? theme.palette.success.main
                                                : "#453834"
                                            }
                                            fontSize="14px"
                                          >
                                            {t("yes")}
                                          </Typography>
                                        }
                                        className={
                                          isIntegration === true
                                            ? "custom-radio-checked"
                                            : "custom-radio"
                                        }
                                      />
                                      <FormControlLabel
                                        value={false}
                                        control={
                                          <Radio
                                            sx={{
                                              color: "#45383426",
                                              "&.Mui-checked": {
                                                color: "#34AE1A",
                                              },
                                            }}
                                          />
                                        }
                                        label={
                                          <Typography
                                            fontWeight="500"
                                            color={
                                              isIntegration === false
                                                ? theme.palette.success.main
                                                : "#453834"
                                            }
                                            fontSize="14px"
                                          >
                                            {t("no")}
                                          </Typography>
                                        }
                                        className={
                                          isIntegration === false
                                            ? "custom-radio-checked"
                                            : "custom-radio"
                                        }
                                      />
                                    </RadioGroup>
                                  </FormControl>
                                </Grid>
                                {isEcommerce && (
                                  <Grid item xs={12} sm={6}>
                                    <Typography
                                      sx={{
                                        fontSize: "13px",
                                        fontWeight: "500",
                                        color: theme.palette.text.main,
                                      }}
                                    >
                                      {t("eCommerceStoreLinks")}
                                    </Typography>

                                    {merchnantEcommerceUrls.map(
                                      (ecommerceUrl, index) => (
                                        <FormControl
                                          variant="standard"
                                          key={`eCommerceLinks-${index}}`}
                                          className="signup-fc"
                                        >
                                          <div
                                            key={`ecommerceUrl-${index}`}
                                            className="ecommerce-url-div"
                                          >
                                            <BootstrapInput
                                              className="signup-input"
                                              placeholder={t(
                                                "eCommerceStoreLink"
                                              )}
                                              value={ecommerceUrl}
                                              onChange={(e) => {
                                                let values = [
                                                  ...merchnantEcommerceUrls,
                                                ];
                                                values[index] = e.target.value;
                                                setMerchnantEcommerceUrls(
                                                  values
                                                );
                                              }}
                                            />
                                            {index !== 0 && (
                                              <IconButton
                                                aria-label="delete"
                                                onClick={() =>
                                                  removeEcommerceFields(index)
                                                }
                                              >
                                                <CloseIcon />
                                              </IconButton>
                                            )}
                                          </div>
                                        </FormControl>
                                      )
                                    )}

                                    <Button
                                      onClick={addEcommerceFields}
                                      size="small"
                                      sx={{
                                        textTransform: "capitalize",
                                        fontSize: "12px",
                                      }}
                                      color="secondary"
                                    >
                                      +{t("addLink")}
                                    </Button>
                                  </Grid>
                                )}
                              </Grid>
                            </Grid>
                          </Grid>
                        </FormGroup>
                        <FormGroup>
                          <BoxTitle
                            title={t("promotionCode")}
                            style={{
                              marginBottom: "10px",
                            }}
                          />
                          <Grid container spacing={2} sx={{ mb: 2 }}>
                            <Grid item xs={12} sm={12}>
                              <FormControl
                                variant="standard"
                                key={"promotionCode"}
                                className="signup-fc"
                                sx={{ mt: 1 }}
                              >
                                <Typography
                                  sx={{
                                    fontSize: "13px",
                                    fontWeight: "500",
                                    color: theme.palette.text.main,
                                    mt: "4px",
                                  }}
                                >
                                  {t("ifYouHaveAPromoCodeYouCanEnterIt")}
                                </Typography>
                                <BootstrapInput
                                  placeholder={t("promotionCode")}
                                  value={promotionCode}
                                  onChange={handlePromotionCodeChange}
                                />
                              </FormControl>
                            </Grid>
                          </Grid>
                        </FormGroup>
                      </Box>
                    )}
                  </Box>

                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      pt: 2,
                      ml: "1.5em",
                      mr: "1.5em  ",
                    }}
                  >
                    {activeStep !== 0 && (
                      <Button
                        color="inherit"
                        disabled={activeStep === 0}
                        onClick={handleBack}
                        sx={{ mr: 1 }}
                        className="filterReset"
                      >
                        {t("goBack")}
                      </Button>
                    )}
                    <Box sx={{ flex: "1 1 auto" }} />

                    {activeStep < 2 && (
                      <Button
                        onClick={handleNext}
                        variant="contained"
                        type="button"
                        className="filterApply"
                        disabled={
                          activeStep === 0 &&
                          (fullName === "" ||
                            phone === "" ||
                            countryCode === "" ||
                            email === "" ||
                            emailRepeat === "" ||
                            formErrors.fullName ||
                            formErrors.email ||
                            formErrors.phone ||
                            formErrors.countryCode ||
                            formErrors.emailRepeat ||
                            formErrors.privacies)
                            ? true
                            : false ||
                              (activeStep === 1 &&
                                (brandName === "" ||
                                  category.length === 0 ||
                                  merchantType === "" ||
                                  merchantOwnerOfBrand === "" ||
                                  formErrors.brandName ||
                                  formErrors.category))
                            ? true
                            : false
                        }
                      >
                        {t("continue")}
                      </Button>
                    )}
                    {activeStep >= 2 && (
                      <Button
                        variant="contained"
                        type="submit"
                        className="filterApply"
                        disabled={
                          (activeStep === 2 &&
                            (productOrigin.length === 0 ||
                              formErrors.productOrigin)) ||
                          (activeStep === 2 &&
                            isEcommerce &&
                            merchnantEcommerceUrls.length === 0) ||
                          (isEcommerce &&
                            merchnantEcommerceUrls.some((url) => url === ""))
                        }
                      >
                        {t("send")}
                      </Button>
                    )}
                  </Box>
                </React.Fragment>
              )}
            </Form>
          </Box>
        </Container>

        <Box>
          <Link onClick={changeLanguageHandler}>
            <Flag
              code={lang === "tr" ? "tur" : "us"}
              style={{
                borderRadius: "3px",
                width: "45px",
                float: "left",
                marginLeft: "24px",
                marginTop: "240px",
              }}
            />
          </Link>
        </Box>
        <Box className="policyDialog">
          <Dialog
            maxWidth="md"
            fullWidth={true}
            open={openPolicy}
            onClose={handleClosePolicy}
          >
            <DialogTitle
              sx={{ m: 0, p: 2, pl: 2, color: theme.palette.primary.main }}
              id="customized-dialog-title"
            >
              Kullanım Koşulları ve Gizlilik İlkesi
            </DialogTitle>
            <IconButton
              aria-label="close"
              onClick={handleClosePolicy}
              sx={{
                position: "absolute",
                right: 8,
                top: 8,
                color: (theme) => theme.palette.grey[500],
              }}
            >
              <CloseIcon />
            </IconButton>
            <Divider />
            <DialogContent>
              <Box p={2} pt={0} pb={0}>
                <Typography variant="body2">
                  MegaMerchant’a Hoş Geldiniz!
                </Typography>
                <Typography variant="body2" sx={{ mt: 1 }}>
                  FBMM hizmeti ve MegaMerchant hizmetleri size Mega Merchant
                  E-Ticaret Satış ve Pazarlama Anonim Şirketi tarafından sunulan
                  MegaMerchant Ürünlerinden biridir. Bu nedenle bu Kullanım
                  Koşulları, sizinle Mega Merchant E-Ticaret Satış ve Pazarlama
                  Anonim Şirketi arasında bir sözleşme teşkil eder.
                </Typography>
                <Typography variant="body2" sx={{ mt: 2, fontWeight: "500" }}>
                  MegaMerchant Hizmetleri
                </Typography>
                <Typography variant="body2" sx={{ mt: 1 }}>
                  Hizmete, MegaMerchant misyonunu ileri taşımak için
                  sağladığımız tüm MegaMerchant ürün, özellik, uygulama, hizmet,
                  teknoloji ve yazılımları dahildir. Hizmet, aşağıdaki
                  unsurlardan oluşmaktadır ve Çalışma Şartları ile Modelleri ile
                  belirlenen sınırlarda bu hizmetler sunulmaktadır:
                </Typography>
                <Typography variant="body2" sx={{ mt: 1 }}>
                  E-ihracat sürecine başlanabilmesi için kullanıcılara teknik ve
                  regulatif desteklerin sağlanması, kullanıcılara kullanılan
                  modele göre sipariş karşılama hizmetlerinin sunulması, bu
                  hizmetlerin sunulabilmesi için gerekli depo ve sipariş
                  karşılama yazılımlarının kullanımı, kullanıcılara FBMM
                  tarifelerinin sunulması, alınan hizmetin niteliğine göre
                  kullanıcılara ihracat sürecinde destek olunması, kullanıcının
                  ürünlerinin yurtdışı depolara sevki için destek sağlanması
                  veya bu sürecin yönetilmesi, yerine göre kullanıcının
                  ürünlerinin yurtdışı depolarında depolanması, modele göre son
                  tüketiciden gelen siparişlere ilişkin sipariş karşılama ve
                  iade kabul hizmetlerinin sunulması, ürün listelemesi ve içerik
                  yönetimi bakımından kullanıcılara çeşitli desteklerin
                  sunulması veya bu süreçlerin tamamının yönetilmesi,
                  kullanıcılara pazara giriş raporu hazırlanması, yurtdışı
                  pazaryerlerinde gerçekleştirilecek satışlar için doğru pazarda
                  doğru ürünle ilerlenebilmesi bakımından teknik analizlerin
                  gerçekleştirilmesi, duruma göre kullanıcının verilerinin API
                  ile alınarak yurtdışı pazaryerlerinde ürünlerinin satışa
                  açılması, kullanıcının kullandığı teknik altyapı ile yurtdışı
                  pazaryerlerinin entegre edilmesi, kullanıcının yurtdışı
                  pazaryerlerinde MegaMerchant kanalı ile veya doğrudan satışa
                  başlamasının akabinde ve satış sürecinin tamamında ticari veya
                  teknik desteklerin sağlanması, kullanıcının tüm bu süreci
                  takip edebilmesi için kendisine çeşitli arayüzlerin sunulması,
                  kullanıcıya yurtdışı pazaryerlerinde marka yönetimi, marka
                  koruma, reklam yönetimi, reklam optimizasyonu gibi hizmetlerin
                  sunulması, satış sürecinin başlamasının akabinde pazar takip
                  ve içerik geliştirme hizmetlerinin sunulması, gerekirse çekim
                  ve video içeriklerin oluşturulması, bu içeriklerin güncel
                  tutulması, ürünlerin vine sistemine kaydedilmesi, ürünlere
                  ilişkin listelemelerin düzenli olarak optimize edilmesi,
                  premium A+ içeriklerin oluşturulması, içeriklerin çeşitli
                  dillere tercüme edilmesi, ürünlerin satışı için ücretli
                  kampanyalara katılım sağlanması gibi hizmetleri kapsar.
                </Typography>
                <Typography variant="body2" sx={{ mt: 1 }}>
                  MegaMerchant Hizmetleri'ni kullanarak, bu koşullarla bağlı
                  olmayı kabul etmektesiniz. Lütfen MegaMerchant Hizmetleri'ni
                  kullanmadan önce bu koşulları dikkatli bir şekilde okuyunuz.
                </Typography>
                <Typography variant="body2" sx={{ mt: 1 }}>
                  Büyüyen kullanıcılarımıza sürekli hizmet sunmaya devam
                  etmemize yardımcı olan teknolojileri geliştirmek ve
                  kullanmaktayız. Kullanıcılarımız için bilgileri düzenlemek ve
                  analiz etmek, Hizmetimizde önemli bir rol oynar. Hizmetimizin
                  önemli bir parçası, son derece geniş bir küresel topluluk için
                  Hizmetimizi devasa bir ölçekte kişiselleştirmemize, korumamıza
                  ve geliştirmemize yardımcı olan modern teknolojiler oluşturmak
                  ve kullanmaktır. Sanal zeka ve makine öğrenimi gibi
                  teknolojiler, bize Hizmetimizin genelinde karmaşık işlemler
                  uygulayabilme olanağı verir. Otomatikleştirilmiş teknolojiler
                  de Hizmetimizin işlevselliğini ve bütünlüğünü sağlamamıza
                  yardımcı olur.
                </Typography>
                <Typography variant="body2" sx={{ mt: 1 }}>
                  MegaMerchant; daha iyi, daha emniyetli ve daha güvenli
                  hizmetler sunmak amacıyla sizin hakkınızda sahip olduğumuz
                  bilgiler (Gizlilik İlkesinden daha fazla bilgi alabilirsiniz)
                  dahil olmak üzere bilgi, teknoloji, sistem ve istatistik
                  paylaşımı yapan MegaMerchant Şirketlerinin bir parçasıdır.
                  Ayrıca kullandığınız MegaMerchant Ürünleri arasında etkileşim
                  kurabileceğiniz yollar sunuyoruz ve MegaMerchant Ürünleri
                  arasında kusursuz ve tutarlı bir deneyim sağlamak için
                  sistemler tasarladık.
                </Typography>
                <Typography variant="body2" sx={{ mt: 1 }}>
                  Küresel Hizmetimizi gerçekleştirebilmek için verileri dünyanın
                  dört bir yanındaki (ikamet ettiğiniz ülke haricindeki ülkeler
                  de dahil olmak üzere) sistemlerimize dağıtarak buralarda
                  saklamamız gerekmektedir. Bu global altyapının kullanılması,
                  Hizmetlerimizi sağlamak için gerekli ve zorunludur. Bu altyapı
                  MegaMerchant E-Ticaret Satış ve Pazarlama, The Mega Merchant
                  Holdings Limited veya iştiraklerinin mülkiyetinde bulunabilir
                  veya bu şirketler tarafından işletilebilir, devredilebilir.
                </Typography>
                <Typography variant="body2" sx={{ mt: 1 }}>
                  MegaMerchant Ürünlerinden, themegamerchant.com’dan ve üçüncü
                  taraf ortaklardan aldığımız verileri, size anlamlı
                  bulacağınızı düşündüğümüz reklamlar, teklifler ve diğer
                  sponsorlu içerikleri göstermek için kullanabiliyoruz.
                  Topladığımız bilgileri, Hizmetimiz üzerinde çalışmalar
                  gerçekleştirmek ve hem Hizmetimizi iyileştirecek hem de
                  topluluğumuzun refahına katkıda bulunacak araştırmalarda
                  başkalarıyla işbirliği yapmak için kullanıyoruz.
                </Typography>
                <Typography variant="body2" sx={{ mt: 2, fontWeight: "500" }}>
                  Verdiğiniz Taahhütler
                </Typography>
                <Typography variant="body2" sx={{ mt: 1 }}>
                  Bizim Hizmeti sağlama yönündeki taahhüdümüze karşılık olarak
                  sizin de bize aşağıdaki taahhütleri vermeniz gerekmektedir.
                </Typography>
                <Typography variant="body2" sx={{ mt: 1 }}>
                  Yürürlükteki yasalar gereği Hizmetimizin herhangi bir
                  parçasını almanızın veya geçerli bir engelli taraf listesinde
                  yer alıyorsanız ödemeyle ilişkili Hizmetlerle etkileşimde
                  bulunmanızın yasaklanmamış olması gerekir. Hesabınızın daha
                  önce yasaları veya herhangi bir ilkemizi ihlal etmeniz
                  nedeniyle kapatılmamış olması gerekir. Başkasının kimliğine
                  bürünemez veya doğru olmayan bilgiler veremezsiniz. Hizmetin
                  arzulanan çalışma biçimine müdahale edecek veya olumsuz etkide
                  bulunacak herhangi bir şey yapamazsınız. Yetkisiz yollarla
                  hesap oluşturma, bilgilere erişme veya bilgi toplama
                  girişiminde bulunamazsınız. Açık iznimiz olmadan otomatik
                  olarak hesaplar oluşturmak veya bilgi toplamak da buna
                  dahildir.
                </Typography>
                <Typography variant="body2" sx={{ mt: 1 }}>
                  Yasalara aykırı, yanıltıcı veya dolandırıcılık amaçlı herhangi
                  bir şey yapamaz, yasadışı veya yetkisiz amaçlarla herhangi bir
                  eylem gerçekleştiremezsiniz.
                </Typography>
                <Typography variant="body2" sx={{ mt: 1 }}>
                  Markalı ürünler satmaktaysanız bu konuda fikri ve sınai haklar
                  mevzuatına uygun hareket etmeniz gerekir. MegaMerchant fikri
                  hak ihlalleri konusunda bir sorumluluk üstlenmez.
                </Typography>
                <Typography variant="body2" sx={{ mt: 1 }}>
                  Bizden veya Hizmetlerimizden elde edilen hiçbir hesap veya
                  veriyi satamaz, lisanslayamaz veya satın alamazsınız. Buna
                  hesabınızın herhangi bir kısmını (kullanıcı adınız dahil)
                  satın alma, satma veya aktarma girişimleri; diğer
                  kullanıcıların giriş bilgilerini isteme veya toplama dahildir.
                </Typography>
                <Typography variant="body2" sx={{ mt: 1 }}>
                  İzin olmadan bir başkasının özel veya gizli bilgilerini
                  paylaşamaz veya fikri mülkiyet hakları (ör. telif hakkı
                  ihlali, ticari marka ihlali, taklit veya korsan ürünler) dahil
                  olmak üzere bir başkasının haklarını ihlal edecek herhangi bir
                  eylemde bulunamazsınız.
                </Typography>
                <Typography variant="body2" sx={{ mt: 1 }}>
                  Başka bir kişinin eserlerini telif hakkına ilişkin istisna
                  veya kısıtlamalar ile yürürlükteki yasalar kapsamındaki ilgili
                  haklar kapsamında kullanabilirsiniz. Yayımladığınız veya
                  paylaştığınız içeriklerin gerekli tüm haklarına sahip
                  olduğunuzu veya bu hakları aldığınızı beyan edersiniz. Fikri
                  mülkiyet haklarınızı ihlal ettiğini düşündüğünüz içerikleri
                  bize bildirmelisiniz.
                </Typography>
                <Typography variant="body2" sx={{ mt: 1 }}>
                  Ürünlerimizi veya bileşenlerini değiştiremez, çevirisini
                  yapamaz, türevlerini oluşturamaz veya ürünlerimize veya
                  bileşenlerine ters mühendislik işlemi uygulamazsınız.
                </Typography>
                <Typography variant="body2" sx={{ mt: 1 }}>
                  Önceden yazılı onayımız olmadan kullanıcı adınızda bir domain
                  adı veya internet adresi (URL) kullanamazsınız.
                </Typography>
                <Typography variant="body2" sx={{ mt: 2, fontWeight: "500" }}>
                  Bize Verdiğiniz İzinler
                </Typography>
                <Typography variant="body2" sx={{ mt: 1 }}>
                  Aramızdaki sözleşme kapsamında, bize Hizmeti sağlamak için
                  ihtiyaç duyduğumuz izinleri de vermektesiniz.
                </Typography>
                <Typography variant="body2" sx={{ mt: 1 }}>
                  İçerikleriniz üzerinde mülkiyet iddia etmemekteyiz, ancak bize
                  içerikleri kullanmamız için bir lisans vermektesiniz.
                </Typography>
                <Typography variant="body2" sx={{ mt: 1 }}>
                  İçerikleriniz üzerindeki haklarınız hakkında hiçbir şey
                  değişmeyecektir. Hizmette veya Hizmet üzerinden paylaştığınız
                  içerikler üzerinde mülkiyet iddia etmeyiz ve içeriklerinizi
                  istediğiniz yerde başkalarıyla paylaşabilirsiniz. Bununla
                  birlikte, Hizmeti sunmak için sizden belirli yasal izinler
                  (“lisans” olarak bilinir) almamız gerekir. Fikri mülkiyet
                  hakları kapsamındaki içerikleri (fotoğraflar veya videolar
                  gibi) Hizmette veya Hizmetle bağlantılı şekilde
                  paylaştığınızda, yayınladığınızda veya yüklediğinizde;
                  içeriklerinizi barındırmamız, kullanmamız, dağıtmamız,
                  değiştirmemiz, çalıştırmamız, kopyalamamız, herkese açık
                  olarak sunmamız veya göstermemiz, çevirisini yapmamız ve
                  türevlerini oluşturmamız için bize münhasır olmayan, telifsiz,
                  devredilebilir, alt lisanslanabilir ve uluslararası bir lisans
                  vermiş olursunuz. Bu lisans, içerikleriniz sistemlerimizden
                  silindiğinde sona erer. İçeriklerinizi teker teker veya
                  hesabınızı silerek tek seferde silebilirsiniz. Bilgileri nasıl
                  kullandığımızı öğrenmek ve içeriklerinizi kontrol etme veya
                  silme hakkında bilgi almak için Gizlilik İlkesi’ni gözden
                  geçirin.
                </Typography>
                <Typography variant="body2" sx={{ mt: 1 }}>
                  Hizmet güncellemelerini cihazınıza indirebileceğimizi ve
                  yükleyebileceğimizi kabul edersiniz.
                </Typography>
                <Typography variant="body2" sx={{ mt: 2, fontWeight: "500" }}>
                  Saklı Tuttuğumuz İlave Haklar
                </Typography>
                <Typography variant="body2" sx={{ mt: 1 }}>
                  Hesabınız için bir kullanıcı adı veya benzer bir tanımlayıcı
                  seçerseniz uygun veya gerekli gördüğümüz takdirde (ör. Başka
                  birinin fikri mülkiyet haklarını ihlal ediyorsa veya başka bir
                  kullanıcının kimliğine bürünüyorsa) bunları değiştirebiliriz.
                </Typography>
                <Typography variant="body2" sx={{ mt: 1 }}>
                  Sahip olduğumuz fikri mülkiyet hakları kapsamındaki içerikleri
                  kullandığınızda ve bunları Hizmetimizde erişilebilir
                  kıldığınızda (örneğin oluşturduğunuz veya paylaştığınız
                  içeriklere eklemeniz için sağladığımız görseller, tasarımlar,
                  videolar veya sesler), sahip olduğumuz içeriğe ait tüm haklar
                  bize ait olur ve söz konusu içerik hakkında size bir hak
                  tanınmaz.
                </Typography>
                <Typography variant="body2" sx={{ mt: 1 }}>
                  Fikri mülkiyetimizi, ticari markalarımızı ya da benzer
                  markaları sadece bizden önceden yazılı izin alarak
                  kullanabilirsiniz.
                </Typography>
                <Typography variant="body2" sx={{ mt: 1 }}>
                  Yazılımımızı değiştirmek, türevlerini oluşturmak, kaynak koda
                  dönüştürmek veya başka bir şekilde bizden kaynak kod çekmeye
                  çalışmak için öncelikle bizden yazılı izin veya bir açık
                  kaynak lisansı almanız gerekir
                </Typography>
                <Typography variant="body2" sx={{ mt: 2, fontWeight: "500" }}>
                  Sözleşmemiz ve Anlaşmazlık Durumunda Gerçekleşecekler
                </Typography>
                <Typography variant="body2" sx={{ mt: 1 }}>
                  Bu sözleşmenin herhangi bir kısmı uygulanamaz nitelikteyse
                  sözleşmenin geri kalanı geçerliliğini koruyacaktır.
                </Typography>
                <Typography variant="body2" sx={{ mt: 1 }}>
                  Sözleşmemiz üzerinde yapılacak herhangi bir değişiklik veya
                  feragat, tarafımızca yazılı olarak yapılmalı ve
                  imzalanmalıdır. Bu sözleşmenin herhangi bir hükmünü uygulama
                  konusunda başarısız olmamız halinde bu durum bir feragat
                  olarak kabul edilmeyecektir.
                </Typography>
                <Typography variant="body2" sx={{ mt: 1 }}>
                  Size açık bir şekilde verilmemiş tüm haklar tarafımızca saklı
                  tutulmaktadır. Herhangi MegaMerchant Hizmeti veya herhangi bir
                  MegaMerchant Hizmeti'nin herhangi bir kısmı açıkça verilmiş
                  yazılı onayımız olmadan herhangi bir ticari amaçla
                  çoğaltılamaz, kopyalanamaz, satılamaz, yeniden satılamaz,
                  ziyaret edilemez veya diğer bir şekilde faydalanılamaz.
                </Typography>
                <Typography variant="body2" sx={{ mt: 1 }}>
                  Bu sözleşme, herhangi bir üçüncü tarafa herhangi bir hak
                  tanımamaktadır.
                </Typography>
                <Typography variant="body2" sx={{ mt: 1 }}>
                  Bu sözleşme kapsamındaki hak veya yükümlülüklerinizi, bizim
                  onayımız olmadan başkalarına devredemezsiniz.
                </Typography>
                <Typography variant="body2" sx={{ mt: 1 }}>
                  Sahip olduğumuz hak ve yükümlülüklerimizi başkalarına
                  devredebiliriz. Örneğin, şirketimizin sahibinin değişmesi
                  (şirket birleşmesi, şirket satışı veya varlıkların satışı) ya
                  da yasalar gereği böyle bir durum yaşanabilir.
                </Typography>
                <Typography variant="body2" sx={{ mt: 1 }}>
                  Hizmetimiz "olduğu gibi" sunulur. Hizmetimizin her zaman
                  emniyetli ve güvenli olacağını ve kusursuz bir şekilde
                  çalışacağını garanti edemeyiz. YASALARIN İZİN VERDİĞİ ÖLÇÜDE,
                  TİCARİ ELVERİŞLİLİĞE İLİŞKİN ZIMNİ HERHANGİ BİR GARANTİ, ÖZEL
                  BİR AMACA UYGUNLUK, ZAPTA KARŞI TEMİNAT VE İHLAL ETMEME
                  GARANTİLERİ DAHİL, AÇIK VEYA ZIMNİ HER TÜR GARANTİYİ
                  REDDEDİYORUZ.
                </Typography>
                <Typography variant="body2" sx={{ mt: 1 }}>
                  Hizmette gerçekleşen herhangi bir şey için sorumluluğumuz
                  ("yükümlülük" olarak da adlandırılır), yasaların izin verdiği
                  asgari sorumlulukla sınırlıdır. Hizmetimizle ilgili herhangi
                  bir sorun meydana gelirse bu sorunun yol açabileceği tüm olası
                  etkileri bilmemiz mümkün olmayacaktır. Muhtemel olduğunu
                  bilsek dahi bu Koşullardan kaynaklanan veya bu Koşullarla
                  ilgili herhangi bir kâr, gelir, bilgi veya veri kaybı ile
                  bağlı, özel, dolaylı, emsal niteliğinde, cezai veya arızi
                  zarardan dolayı sorumlu ("yükümlü") tutulmayacağımızı kabul
                  edersiniz. İçerik, bilgi veya hesabınızı sildiğimiz durumlar
                  da buna dahildir.
                </Typography>
                <Typography variant="body2" sx={{ mt: 1 }}>
                  Bu Koşullar nedeniyle veya bu Koşullarla ilişkili olarak
                  bizimle aranızdaki her türlü iddia, dava nedeni ya da
                  ihtilafta ("iddia"), Türkiye Cumhuriyeti yasaları geçerli
                  olacaktır. İddianızı münhasıran İstanbul Çağlayan
                  mahkemelerinde ve icra dairelerinde çözümleyebilirsiniz, bu
                  mahkemelerin münhasıran yetkili kılınmasını engelleyen
                  hallerde bu mahkemeler de yetkili sayılır.
                </Typography>
                <Typography variant="body2" sx={{ mt: 2, fontWeight: "500" }}>
                  İstenmeden Verilen Materyaller
                </Typography>
                <Typography variant="body2" sx={{ mt: 1 }}>
                  Görüşleriniz veya diğer önerileriniz bizim için her zaman çok
                  önemlidir, ancak bunları herhangi bir sınırlama olmadan ya da
                  bunlara ilişkin olarak tarafınızı tazmin etme yükümlülüğüne
                  tabi olmaksızın kullanabileceğimizi ve görüşlerinizi gizli
                  tutma yükümlülüğümüz olmayacağını kabul edersiniz.
                </Typography>
                <Typography variant="body2" sx={{ mt: 2, fontWeight: "500" }}>
                  Bu Koşulların Güncellenmesi
                </Typography>
                <Typography variant="body2" sx={{ mt: 1 }}>
                  Hizmetimizi ve ilkelerimizi değiştirebiliriz. Ayrıca,
                  Hizmetimizi ve ilkelerimizi doğru olarak yansıtması için bu
                  Koşulları da değiştirmemiz gerekebilir. Yasalar uyarınca aksi
                  gerekmediği sürece, bu Koşullarda değişiklik yapmadan önce
                  sizi bilgilendirecek ve Koşullar yürürlüğe girmeden önce size
                  inceleme fırsatı vereceğiz. Sonrasında Hizmeti kullanmaya
                  devam ederseniz güncellenen Koşullara tabi olacaksınız. Bu
                  veya güncellenen Koşulları kabul etmek istemiyorsanız
                  hesabınızı silebilirsiniz.
                </Typography>
                <Typography variant="body2" sx={{ mt: 1 }}>
                  Hizmet vermeyi reddetme veya hesapları sona erdirme hakkını
                  saklı tutmaktayız. Uygulanan kanunları, geçerli sözleşme
                  hükümlerini, kılavuz ilkelerimizi veya politikalarımızı ihlal
                  etmeniz halinde, bu durum özellikle söz konusu olacaktır.
                </Typography>
                <Typography variant="body2" sx={{ mt: 2, fontWeight: "500" }}>
                  GİZLİLİK İLKESİ
                </Typography>
                <Typography variant="body2" sx={{ mt: 1 }}>
                  Hizmetimizi sağlamak için bilgilerinizi toplamamız ve
                  kullanmamız gerekmektedir. Gizlilik İlkesi ve Çerezler İlkesi
                  bilgileri nasıl topladığımızı, kullandığımızı ve MegaMerchant
                  Ürünleri genelinde nasıl paylaştığımızı açıklamaktadır.
                  MegaMerchant Hizmetlerini kullanmak için Gizlilik İlkesini
                  kabul etmeniz gerekir.
                </Typography>
                <Typography variant="body2" sx={{ mt: 1 }}>
                  Herhangi bir MegaMerchant Hizmeti'ni kullandığınızda veya
                  masaüstünüzden veya mobil cihazınızdan bize e-postalar,
                  sms'ler veya diğer iletişimler gönderdiğinizde bizimle
                  elektronik olarak iletişim kurmuş olursunuz. Sizinle, örneğin
                  e-posta, sms, uygulama içi anlık iletişimler gibi çeşitli
                  şekillerde veya internet sayfasında e-posta mesajları veya
                  iletişimler göndererek veya yayınlayarak veya Mesaj Merkezimiz
                  gibi diğer MegaMerchant Hizmetleri aracılığıyla elektronik
                  olarak iletişim kuracağız. Sözleşmesel amaçlı olarak, bizden
                  elektronik olarak iletişim almaya onay vermektesiniz ve size
                  elektronik olarak temin ettiğimiz tüm sözleşmelerin,
                  bildirimlerin, açıklamaların ve diğer iletişimlerin, uygulanan
                  kanunlar farklı bir iletişim şeklini öngörmediği sürece, söz
                  konusu iletişimlerin yazılı olmasına ilişkin her türlü yasal
                  gerekliliğe uygunluk gösterdiğini kabul etmektesiniz.
                </Typography>
                <Typography variant="body2" sx={{ mt: 1 }}>
                  Herhangi bir MegaMerchant Hizmeti'ni kullanıyorsanız,
                  hesabınızın ve şifrenizin gizliliğini korumaktan ve
                  bilgisayarınıza ve mobil cihazlarınıza erişimi kısıtlamaktan
                  sorumlu olacaksınız ve uygulanan kanunların izin verdiği
                  ölçüde hesabınız ve şifreniz altında gerçekleşen tüm
                  faaliyetler için sorumluluğu kabul ediyor olacaksınız.
                  Şifrenin gizli ve güvenli tutulmasını sağlamak için gerekli
                  tüm tedbirleri almanız ve şifrenizin başkası tarafından
                  öğrenildiğine kanaat getirmeniz veya şifrenizin yetkisiz
                  şekilde kullanıldığına veya kullanılabileceğine kanaat
                  getirmeniz için herhangi bir gerekçenizin bulunması halinde
                  bizi derhal bilgilendirmeniz gerekmektedir. Bize temin
                  ettiğiniz bilgilerin doğru ve tam olduğundan emin olmaktan ve
                  temin ettiğiniz bilgilerden herhangi bir değişiklik olursa
                  bizi bilgilendirmekten sorumlu olacaksınız.
                </Typography>
                <Typography variant="body2" sx={{ mt: 1 }}>
                  Kullanıcı Gizlilik İlkesi veya burada atıf yapılan diğer
                  herhangi bir işlem veya sözleşmenin ifası ile bağlantılı
                  olarak MegaMerchant’tan edinilen veya MegaMerchant ile ilgili
                  olan veya kullanıcının mülkiyeti veya idaresi ve MegaMerchant
                  ürünleri vasıtasıyla elde edilen tüm bilgiyi büyük bir
                  gizlilik içerisinde tutmayı kabul etmektedir. Kullanıcı bir
                  önceki cümlede belirtilmiş olan bilgiyi, Gizlilik İlkeleri
                  içerisinde açık bir şekilde aksi belirtilmediği sürece veya
                  MegaMerchant’ın önceden yazılı onayı olmaksızın yayınlamamayı,
                  dağıtmamayı, açıklamamayı, ifşa etmemeyi veya kullanmamayı
                  kabul etmektedir
                </Typography>
                <Typography variant="body2" sx={{ mt: 1 }}>
                  MegaMerchant®
                </Typography>
                <Typography variant="body2" sx={{ mt: 1 }}>
                  Burhaniye Mahallesi Nagehan {t("street")} No:A4A{" "}
                  {t("interiorDoor")} No:1 Üsküdar İstanbul
                </Typography>
                <Typography variant="body2" sx={{ mt: 1 }}>
                  <a href="mailto:megamerchant@hs03.kep.tr">
                    {" "}
                    megamerchant@hs03.kep.tr{" "}
                  </a>
                </Typography>
                <Typography variant="body2" sx={{ mt: 1 }}>
                  Mersis No: 0614156725800001
                </Typography>
              </Box>
            </DialogContent>
          </Dialog>
        </Box>
        <Box className="cookie">
          <Dialog
            maxWidth="md"
            fullWidth={true}
            open={openCookie}
            onClose={handleCloseCookie}
          >
            <DialogTitle
              sx={{ m: 0, p: 2, pl: 2, color: theme.palette.primary.main }}
              id="customized-dialog-title"
            >
              {t("megaMerchantsCookiePolicies")}
            </DialogTitle>
            <IconButton
              aria-label="close"
              onClick={handleCloseCookie}
              sx={{
                position: "absolute",
                right: 8,
                top: 8,
                color: (theme) => theme.palette.grey[500],
              }}
            >
              <CloseIcon />
            </IconButton>
            <Divider />
            <DialogContent>
              <Box p={2} pt={0} pb={0}>
                <Typography variant="body1" sx={{ mt: 2, fontWeight: "500" }}>
                  {t("whatAreCookies")}
                </Typography>
                <Typography variant="body2" sx={{ mt: 1 }}>
                  Günümüzde neredeyse her web sitesi çerez kullanmakta olup,
                  size daha iyi, hızlı ve güvenli bir deneyim sağlamak ve bu
                  deneyimi geliştirmek için, çoğu web sitesi gibi MegaMerchant
                  da çerez kullanmaktadır. Bu teknolojilerin kullanımı başta
                  6698 sayılı Kişisel Verilerin Korunması Kanunu (“6698 sayılı
                  Kanun”) olmak üzere tabi olduğumuz kanunlara ve diğer mevzuata
                  uygun şekilde gerçekleştirilmektedir, ayrıca mevmzuattaki
                  gelişmeler uyarınca güncellenmektedir.
                </Typography>
                <Typography variant="body2" sx={{ mt: 1 }}>
                  Çerezler, web'de gezinirken veya web özellikli bir uygulamayı
                  kullanırken bilgisayarınıza yerleştirilen küçük dosyalardır.
                  Hizmetlerimizi yürütmek, insanların hizmetlerimizi nasıl
                  kullandığını keşfetmek, bunların nasıl daha iyi çalışmasını
                  sağlayacağımızı anlamak ve daha birçok şey için çerezleri
                  kullanıyoruz. Çerezler, internet sitesi tekrar ziyaret
                  edildiğinde cihazınızın tanınmasını sağlar. Çerezler, internet
                  sitesinin ziyaretinizle ilgili tercih ettiğiniz dil ve ayarlar
                  gibi bilgileri hatırlamasına yardımcı olur. Bir sonraki
                  ziyaretinizde kolaylık sağlayabilir ve siteyi daha kullanışlı
                  hale getirebilir. Çerezler, hizmetlerimizin (örneğin web
                  siteleri, uygulamalar, API'ler, pikseller, gömülü öğeler ve
                  e-posta bildirimleri) çalışmasını sağlamak için de kullanılır.
                  Pikseller, web sayfalarına, web özellikli uygulamalara veya
                  e-postalara yerleştirilen küçük kodlardır. Pek çok hizmette
                  olduğu gibi, belirli web veya e-posta içeriğiyle etkileşime
                  girip girmediğinizi öğrenmek için reklamverenlere, web
                  varlıklarına yerleştirmeleri için sağladığımız pikselleri
                  kullanırız. Bu bize, gördüğünüz reklamlar ve içerikler dahil
                  olmak üzere hizmetlerimizi ölçme ve geliştirmenin yanı sıra
                  deneyiminizi kişiselleştirme olanağı da sunar. Verileri,
                  bilgisayarınıza veya mobil cihazınıza kaydetmek için yerel
                  depolama kullanırız. Web'de gezinmeyi açmak, video oynatıcı
                  tercihlerini saklamak, geçmişte hizmetlerimizle kurduğunuz
                  etkileşimleri esas alarak size göstereceğimiz içeriği
                  özelleştirmek, tercihlerinizi hatırlamak ve reklam
                  etkililiğini ölçmek için yerel depolamadan alınan verileri
                  kullanıyoruz. Ayrıca, gerektiğinde size belirli özellikleri
                  sağlamak ve bu sitelere yaptığınız ziyaretler hakkında bilgi
                  almak için diğer web sitelerinde yerel depolamayı
                  kullanabiliriz.
                </Typography>
                <Typography variant="body2" sx={{ mt: 1 }}>
                  Bu MegaMerchant Çerez (Cookie) Politikası’nın amacı,
                  tarafımızca işletilmekte olan{" "}
                  <a
                    style={{
                      color: theme.palette.primary.main,
                      textDecoration: "none",
                    }}
                    href="https://www.themegamerchant.com/"
                  >
                    www.themegamerchant.com
                  </a>{" "}
                  adresinin (“Site”) işletilmesi sırasında müşteri, site
                  kullanıcıları, site üyeleri ve site ziyaretçileri (“İlgili
                  kişi”) tarafından çerezlerin kullanımı sırasında elde edilen
                  kişisel verilerin işlenmesine ilişkin olarak siz kullanıcılara
                  bilgi vermektir. İşbu MegaMerchant Çerez (Cookie)
                  Politikası’nda sitemizde hangi amaçlarla hangi tür çerezleri
                  kullandığımızı ve bu çerezleri nasıl kontrol edebileceğinizi
                  size açıklamak isteriz. 
                </Typography>
                <Typography variant="body1" sx={{ mt: 2, fontWeight: "500" }}>
                  {t("megaMerchantsUseOfCookies")}
                </Typography>
                <Typography variant="body2" sx={{ mt: 1 }}>
                  <a
                    style={{
                      color: theme.palette.primary.main,
                      textDecoration: "none",
                    }}
                    href="https://www.themegamerchant.com/"
                  >
                    www.themegamerchant.com
                  </a>{" "}
                  sitesini ziyaret ederek MegaMerchant’ın web sitesinin
                  kullanımı için gerekli olan çerezlerin cihazınıza
                  yerleştirmesini kabul edersiniz.
                </Typography>
                <Typography variant="body2" sx={{ mt: 1 }}>
                  MegaMerchant, çerezler sayesinde topladığı bilgileri 6698
                  sayılı Kanun’a uygun olarak kullanır.
                </Typography>
                <Typography variant="body2" sx={{ mt: 1 }}>
                  MegaMerchant’ın cihazınıza çerezler yerleştirmesini
                  istemiyorsanız tarayıcınızın ayarları veya seçenekleri
                  vasıtasıyla çerezlerin kullanımını reddedebilirsiniz. Bu
                  durumda Site’nin bazı alanlarını tam ve düzgün biçimde
                  kullanamayabilirsiniz.
                </Typography>
                <Typography variant="body2" sx={{ mt: 1 }}>
                  MegaMerchant, gerekli gördüğü durumlarda, kullandığı çerezleri
                  kullanmaktan vazgeçebilir, bunların türlerini veya
                  fonksiyonlarını değiştirebilir veya Site’ye yeni çerezler
                  ekleyebilir. Dolayısıyla bu MegaMerchant Çerez (Cookie)
                  Politikası’nı her zaman değiştirebilir. MegaMerchant Çerez
                  (Cookie) Politikası’nın değiştirildiği hallerde sayfanın üst
                  kısmındaki güncelleme tarihinden itibaren geçerli olur.
                  MegaMerchant Çerez (Cookie) Politikası’nı belirli zamanlarda
                  inceleyerek, çerez kullanımı ile ilgili bilgi edinmeniz
                  tavsiye edilmektedir.
                </Typography>
                <Typography variant="body1" sx={{ mt: 2, fontWeight: "500" }}>
                  MegaMerchant Tarafından Hangi Çerezler Hangi Amaçlarla
                  Kullanılmaktadır?
                </Typography>
                <Typography variant="body2" sx={{ mt: 2, fontWeight: "500" }}>
                  {t("generally")}
                </Typography>
                <Typography variant="body2" sx={{ mt: 1 }}>
                  MegaMerchant olarak{" "}
                  <a
                    style={{
                      color: theme.palette.primary.main,
                      textDecoration: "none",
                    }}
                    href="https://www.themegamerchant.com"
                  >
                    www.themegamerchant.com
                  </a>{" "}
                  sitemizde çeşitli amaçlarla çerezler kullanmakta ve bu
                  çerezler vasıtasıyla kişisel verilerinizi işlemekteyiz. Bu
                  amaçlar başlıca şunlardır:
                </Typography>
                <Typography variant="body2" sx={{ mt: 1, fontWeight: "500" }}>
                  Site’ye giriş yaptıktan sonra oturumunuzun açık kalmasını
                  sağlamak. Site’nin işlevselliğini arttırmak ve kullanım
                  kolaylığı sağlamak.
                </Typography>
                <Typography variant="body2" sx={{ mt: 1, fontWeight: "500" }}>
                  Bu sayede tekrar giriş yapılmasına gerek kalmadan Site’ye
                  giriş yapabilirsiniz. Örneğin, Site’yi ziyaret eden
                  ziyaretçinin daha sonraki ziyaretinde kullanıcı adı bilgisinin
                  ya da arama sorgularının hatırlanması, oturum açan üyelerin
                  Site’de farklı sayfaları ziyaret ederken tekrar şifre
                  girmelerine gerek kalmaması.
                </Typography>
                <Typography variant="body2" sx={{ mt: 1, fontWeight: "500" }}>
                  Sizi spam ve tacizlerden korumak.
                </Typography>
                <Typography variant="body2" sx={{ mt: 1, fontWeight: "500" }}>
                  Site’yi analiz etmek ve Site’nin performansını arttırmak. 
                </Typography>
                <Typography variant="body2" sx={{ mt: 1 }}>
                  Örneğin, Site’nin üzerinde çalıştığı farklı sunucuların
                  entegrasyonu, Site’yi ziyaret edenlerin sayısının tespit
                  edilmesi, Site’nin kullanım istatistiklerinin raporlanması ve
                  buna göre performans ayarlarının yapılması ya da
                  ziyaretçilerin aradıklarını bulmalarının kolaylaştırılması.
                  Hizmetlerimizin nasıl performans gösterdiği dahil olmak üzere
                  hizmetlerimizi nasıl kullandığınız hakkında veri toplama. Bu,
                  sorunları tespit etmemize, daha iyi hale getirebileceğimiz
                  noktaları anlamamıza veya yeni ürünler ya da hizmetler
                  geliştirmemize yardımcı olur.
                </Typography>
                <Typography variant="body2" sx={{ mt: 1, fontWeight: "500" }}>
                  Reklam kampanyalarının etkinliğini ölçmek ve verimliliğini
                  artırmak. 
                </Typography>
                <Typography variant="body2" sx={{ mt: 1 }}>
                  Örneğin, Site’de gördüğünüz bir reklama tıklayıp
                  tıklamadığınızın, eğer reklam ilginizi çektikten sonra o
                  reklamın yönlendirdiği sitedeki hizmetten faydalanıp
                  faydalanmadığınızın tespit edilmesi. Bu kapsamda tarafınıza
                  ilgi alanlarınıza yönelik reklamların çıkarılması ve
                  gösterilen reklam sayısının sınırlandırılmasına yarar.
                </Typography>
                <Typography variant="body2" sx={{ mt: 1 }}>
                  6698 sayılı Kanun’un 5. ve 8. maddeleri uyarınca gerekli olan
                  hallerde rızanız doğrultusunda, aksi hallerde rızanız
                  alınmaksızın Kanun’un 5. ve 8. maddelerinde belirtilen
                  istisnalar kapsamında yukarıdaki amaçlar doğrultusunda
                  işlenebilecektir. MegaMerchant olarak, MegaMerchant Çerez
                  (Cookie) Politikası kapsamındaki kişisel verilerinizi yukarıda
                  belirtilen amaçların gerçekleştirilebilmesi ile sınırlı olarak
                  ve mevzuata uygun şekilde şirketimizin hizmetlerinden
                  faydalandığı üçüncü kişilerle, bağlı ortaklıklarımızla, iş
                  ortaklarımızla ve grup şirketlerimiz ile paylaşabiliriz.
                  Verilerin aktarıldığı tarafların kişisel verilerinizi dünyanın
                  her yerindeki sunucularında saklayabileceğini bilginize
                  sunarız. 
                </Typography>
                <Typography
                  variant="body2"
                  sx={{ mt: 1, textDecoration: "underline" }}
                >
                  {t("megaMerchantUsesFourTypesOfCookies")}:
                </Typography>
                <TableContainer
                  component={Paper}
                  sx={{
                    mt: 1,
                    boxShadow: "none",
                  }}
                >
                  <Table>
                    <TableHead
                      sx={{
                        borderBottom: "1px solid #f0f0f0",
                      }}
                    >
                      <TableRow>
                        <TableCell>{t("cookieType")}</TableCell>
                        <TableCell>{t("description")}</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      <TableRow>
                        <TableCell>
                          {t("mandatoryAndStrictlyNecessaryCookies")}
                        </TableCell>
                        <TableCell>
                          {t("toVisitors")}{" "}
                          <a
                            style={{
                              color: theme.palette.primary.main,
                              textDecoration: "none",
                            }}
                            href="https://www.themegamerchant.com/"
                          >
                            https://www.themegamerchant.com/
                          </a>{" "}
                          gezinme olanağı sağlayan isimsiz çerezler,
                          özelliklerini kullanarak güvenli alanlara erişirler.
                          Bu çerezler tarafından toplanan bilgiler pazarlama
                          amaçlı kullanılamaz. Bu tür çerezlerin kullanımına
                          izin verilmezse{" "}
                          <a
                            style={{
                              color: theme.palette.primary.main,
                              textDecoration: "none",
                            }}
                            href="https://www.themegamerchant.com/"
                          >
                            https://www.themegamerchant.com/
                          </a>{" "}
                          ‘nin çeşitli bölümleri kullanılamaz. Örneğin, Site’de
                          oturum açtığınızda devreye giren kimlik doğrulama
                          çerezleri, Site içerisinde farklı sayfalara
                          geçtiğinizde oturumunuzun devam etmesini sağlar.
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>Performansa ilişkin çerezler</TableCell>
                        <TableCell>
                          MegaMerchant’ın web sitesini geliştirmesine yardımcı
                          olan isimsiz çerezlerdir. Bu tür çerezler,
                          ziyaretçilerin{" "}
                          <a
                            style={{
                              color: theme.palette.primary.main,
                              textDecoration: "none",
                            }}
                            href="https://www.themegamerchant.com/"
                          >
                            https://www.themegamerchant.com/
                          </a>{" "}
                          ‘u nasıl kullandıkları hakkında bilgiler toplayarak,
                          en çok ziyaret edilen sayfalar, Site’nin gerektiği
                          gibi çalışıp çalışmadığının veya alınan hataların
                          tespitinde kullanılır. Bu türe bir örnek WebAnalytics
                          çerezlerdir. Bu çerezler tarafından toplanan bilgiler
                          pazarlama amaçlı kullanılamaz ve üçüncü şahıslara
                          verilemez.
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>{t("functionCookies")}</TableCell>
                        <TableCell>
                          MegaMerchant’ın ziyaretçi özellik ve tercihlerini
                          hatırlamasına olanak sağlayan isimsiz çerezlerdir. Bu
                          bilgilere dayanarak{" "}
                          <a
                            style={{
                              color: theme.palette.primary.main,
                              textDecoration: "none",
                            }}
                            href="https://www.themegamerchant.com/"
                          >
                            https://www.themegamerchant.com/
                          </a>
                          , her ziyaretçiye toplanan bilgiler doğrultusunda
                          içerik gösterebilir, dil seçimini veya bir metin
                          okurken seçilen font boyutunun hatırlanmasını sağlar.
                          Bu tür çerezlerin kullanımına izin verilmemesi
                          durumunda;
                          <ul>
                            <li>
                              <a
                                style={{
                                  color: theme.palette.primary.main,
                                  textDecoration: "none",
                                }}
                                href="https://www.themegamerchant.com/"
                              >
                                https://www.themegamerchant.com/
                              </a>{" "}
                              ‘nin bazı bölümleri kullanılamayabilir,
                            </li>
                            <li>
                              <a
                                style={{
                                  color: theme.palette.primary.main,
                                  textDecoration: "none",
                                }}
                                href="https://www.themegamerchant.com/"
                              >
                                https://www.themegamerchant.com/
                              </a>{" "}
                              ’nin sağlayabileceği desteğin seviyesini
                              düşürebilir,
                            </li>
                            <li>
                              <a
                                style={{
                                  color: theme.palette.primary.main,
                                  textDecoration: "none",
                                }}
                                href="https://www.themegamerchant.com/"
                              >
                                https://www.themegamerchant.com/
                              </a>{" "}
                              ‘nin tercihlerinizi hatırlamaması, size özel bir
                              özelliğin kullanılmaması ya da gösterilmemesine
                              neden olabilir. Tercihlerinizi hatırlamasını
                              engeller. 
                            </li>
                          </ul>
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>
                          {t("targetingCookiesOrAdvertisingCookies")}
                        </TableCell>
                        <TableCell>
                          Bu tür çerezler, MegaMerchant’ın bilgisi doğrultusunda
                          çoklukla{" "}
                          <a
                            style={{
                              color: theme.palette.primary.main,
                              textDecoration: "none",
                            }}
                            href="https://www.themegamerchant.com/"
                          >
                            https://www.themegamerchant.com/
                          </a>{" "}
                          ‘un reklam ağları tarafından yerleştirilir. Söz konusu
                          çerezlerin kullanım amaçları aşağıdaki gibidir;
                          <ul>
                            <li>
                              İlgili ve kişiselleştirilmiş reklamları göstermek
                            </li>
                            <li>Gösterilen reklam sayısını sınırlamak</li>
                            <li>Reklam kampanyasının etkinliğini ölçmek</li>
                            <li>Ziyaret tercihlerinizi hatırlamak </li>
                          </ul>
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </TableContainer>
                <Typography variant="body1" sx={{ mt: 2, fontWeight: "500" }}>
                  Çerez Kullanımı Nasıl Kontrol Edilebilir?
                </Typography>
                <Typography variant="body2" sx={{ mt: 1 }}>
                  Tarayıcınızın ayarlarını değiştirerek çerezlere ilişkin
                  tercihlerinizi kişiselleştirme imkanına sahipsiniz. Çerezlere
                  ilişkin tercihlerinizi kişiselleştirebileceğiniz bağlantılar
                  aşağıdadır:
                </Typography>
                <TableContainer
                  component={Paper}
                  sx={{
                    mt: 1,
                    boxShadow: "none",
                  }}
                >
                  <Table>
                    <TableBody>
                      <TableRow>
                        <TableCell>{t("adobeAnalytics")}</TableCell>
                        <TableCell>
                          <a
                            style={{
                              color: theme.palette.primary.main,
                              textDecoration: "none",
                            }}
                            href="https://www.adobe.com/privacy/opt-out.html"
                          >
                            https://www.adobe.com/privacy/opt-out.html
                          </a>
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>{t("googleAnalytics")}</TableCell>
                        <TableCell>
                          <a
                            style={{
                              color: theme.palette.primary.main,
                              textDecoration: "none",
                            }}
                            href="https://tools.google.com/dlpage/gaoptout"
                          >
                            https://tools.google.com/dlpage/gaoptout
                          </a>
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>{t("googleAdwords")}</TableCell>
                        <TableCell>
                          <a
                            style={{
                              color: theme.palette.primary.main,
                              textDecoration: "none",
                            }}
                            href="https://support.google.com/ads/answer/2662922?hl=en"
                          >
                            https://support.google.com/ads/answer/2662922?hl=en
                          </a>
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>Google Chrome</TableCell>
                        <TableCell>
                          <a
                            style={{
                              color: theme.palette.primary.main,
                              textDecoration: "none",
                            }}
                            href="​http://www.google.com/support/chrome/bin/answer.py?hl=en&answer=95647"
                          >
                            ​http://www.google.com/support/chrome/bin/answer.py?hl=en&answer=95647
                          </a>
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>Internet Explorer</TableCell>
                        <TableCell>
                          <a
                            style={{
                              color: theme.palette.primary.main,
                              textDecoration: "none",
                            }}
                            href="​https://support.microsoft.com/en-us/help/17442/windows-internet-explorer-delete-manage-cookies"
                          >
                            ​https://support.microsoft.com/en-us/help/17442/windows-internet-explorer-delete-manage-cookies
                          </a>
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>​Mozilla Firefox</TableCell>
                        <TableCell>
                          <a
                            style={{
                              color: theme.palette.primary.main,
                              textDecoration: "none",
                            }}
                            href="​http://support.mozilla.com/en-US/kb/Cookies"
                          >
                            ​http://support.mozilla.com/en-US/kb/Cookies
                          </a>
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>​Opera</TableCell>
                        <TableCell>
                          <a
                            style={{
                              color: theme.palette.primary.main,
                              textDecoration: "none",
                            }}
                            href="​http://www.opera.com/browser/tutorials/security/privacy/"
                          >
                            ​http://www.opera.com/browser/tutorials/security/privacy/
                          </a>
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>​Safari</TableCell>
                        <TableCell>
                          <a
                            style={{
                              color: theme.palette.primary.main,
                              textDecoration: "none",
                            }}
                            href="​https://support.apple.com/kb/ph19214?locale=tr_TR "
                          >
                            ​https://support.apple.com/kb/ph19214?locale=tr_TR 
                          </a>
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </TableContainer>
                <Typography variant="body1" sx={{ mt: 2, fontWeight: "500" }}>
                  İlgili Kişi Olarak Yasal Haklarınız Nelerdir? 
                </Typography>
                <Typography variant="body2" sx={{ mt: 1 }}>
                  6698 sayılı Kanun’un 11. maddesi uyarınca, kişisel verileriniz
                  ile ilgili olarak aşağıdaki haklara sahipsiniz:
                </Typography>

                <Typography variant="body2" sx={{ mt: 1 }}>
                  <ul>
                    <li>
                      Kişisel verilerinizin işlenip işlenmediğini öğrenme,
                    </li>
                    <li>
                      Kişisel verileriniz işlenmişse buna ilişkin bilgi talep
                      etme,
                    </li>
                    <li>
                      Kişisel verilerinizin işlenme amacını ve bunların amacına
                      uygun kullanılıp kullanılmadığını öğrenme,
                    </li>
                    <li>
                      Kişisel verilerinizin yurt içinde veya yurt dışında
                      aktarıldığı üçüncü kişileri bilme,
                    </li>
                    <li>
                      Kişisel verilerinizin eksik veya yanlış işlenmiş olması
                      hâlinde bunların düzeltilmesini isteme ve bu kapsamda
                      yapılan işlemin kişisel verilerin aktarıldığı üçüncü
                      kişilere bildirilmesini isteme, 
                    </li>
                    <li>
                      6698 sayılı Kanun ve ilgili diğer kanun hükümlerine uygun
                      olarak işlenmiş olmasına rağmen, işlenmesini gerektiren
                      sebeplerin ortadan kalkması hâlinde kişisel verilerin
                      silinmesini veya yok edilmesini isteme ve bu kapsamda
                      yapılan işlemin kişisel verilerin aktarıldığı üçüncü
                      kişilere bildirilmesini isteme,
                    </li>
                    <li>
                      İşlenen verilerin münhasıran otomatik sistemler
                      vasıtasıyla analiz edilmesi suretiyle kişinin kendisi
                      aleyhine bir sonucun ortaya çıkmasına itiraz etme,
                    </li>
                    <li>
                      Kişisel verilerin kanuna aykırı olarak işlenmesi sebebiyle
                      zarara uğraması hâlinde zararın giderilmesini talep etme
                      haklarına sahipsiniz.
                    </li>
                  </ul>
                </Typography>
                <Typography variant="body2" sx={{ mt: 1 }}>
                  Söz konusu haklarınıza ilişkin taleplerinizi aşağıda
                  düzenlenen yöntemlerle şirketimize iletmeniz durumunda
                  şirketimiz niteliğine göre talebi en kısa sürede ve en geç
                  otuz gün içinde ücretsiz olarak sonuçlandırır.
                </Typography>
                <Typography variant="body2" sx={{ mt: "1" }}>
                  Ancak, işlemin ayrıca bir maliyet doğurması halinde,
                  şirketimiz tarafından Kişisel Verileri Koruma Kurulu’nca
                  belirlenen tarifedeki ücret alınacaktır. Bu kapsamda 6698
                  sayılı Kanun’un 13. maddesinin 1. fıkrası gereğince, yukarıda
                  belirtilen haklarınızı kullanmak ile ilgili talebinizi, yazılı
                  olarak veya Kişisel Verileri Koruma Kurulu’nun ileride
                  belirleyeceği diğer yöntemlerle şirketimize iletebilirsiniz.
                  Bu çerçevede şirketimize 6698 sayılı Kanun’un 11. maddesi
                  kapsamında yapacağınız başvurularda yazılı olarak başvurunuzu
                  ileteceğiniz kanallar ve usuller aşağıda açıklanmaktadır.
                  Yukarıda belirtilen haklarınızı kullanmak için kaleme
                  aldığınız ıslak imzalı dilekçenizi;
                </Typography>
                <Typography variant="body2" sx={{ mt: 1 }}>
                  <ul>
                    <li>
                      Burhaniye Mah. Nagehan Sk. A Blok No:4a İç Kapı No:1
                      Üsküdar/İstanbul adresine kimliğini tespit edici belgeler
                      ile elden teslim edebilir,
                    </li>
                    <li>
                      veya noter kanalıyla “Burhaniye Mah. Nagehan Sk. A Blok
                      No:4a İç Kapı No:1 Üsküdar/İstanbul” adresine
                      gönderebilir,
                    </li>
                    <li>
                      veya{" "}
                      <a
                        style={{
                          color: theme.palette.primary.main,
                          textDecoration: "none",
                        }}
                        href="mailto:megamerchant@hs03.kep.tr"
                      >
                        megamerchant@hs03.kep.tr
                      </a>{" "}
                      adresine güvenli elektronik imzalı olarak
                      iletebilirsiniz. 
                    </li>
                  </ul>
                </Typography>
              </Box>
            </DialogContent>
          </Dialog>
        </Box>

        <Box className="emailConsent">
          <Dialog
            maxWidth="md"
            fullWidth={true}
            open={openEmailConsentText}
            onClose={handleCloseEmailConsentText}
          >
            <DialogTitle
              sx={{ m: 0, p: 2, pl: 2, color: theme.palette.primary.main }}
              id="customized-dialog-title"
            >
              Açık rıza metni
            </DialogTitle>
            <IconButton
              aria-label="close"
              onClick={handleCloseEmailConsentText}
              sx={{
                position: "absolute",
                right: 8,
                top: 8,
                color: (theme) => theme.palette.grey[500],
              }}
            >
              <CloseIcon />
            </IconButton>
            <Divider />
            <DialogContent>
              <Box p={2} pt={0} pb={0}>
                <Typography variant="body1" sx={{ mt: 2, fontWeight: "500" }}>
                  OMEGA KULLANICILARI SMS VE E-POSTA İLETİSİ GÖNDERİMİNE İLİŞKİN
                  AÇIK RIZA METNİ
                </Typography>
                <Typography variant="body2" sx={{ mt: 1 }}>
                  Mega Merchant, SMS ve e-posta iletilerinin gönderimi sürecinde
                  kişisel veriler bakımından 6698 sayılı Kişisel Verilerin
                  Korunması Kanunu (“Kanun") kapsamında “veri sorumlusu"
                  sıfatını haiz olup, işbu rıza metni ile Kanun uyarınca SMS ve
                  e-posta iletilerinin gönderimi sürecinde aşağıda belirtilen
                  kişisel verilerinin hukuka ve dürüstlük kurallarına uygun,
                  doğru ve gerektiğinde güncel, belirli, açık ve meşru amaçlar
                  için ve Mega Merchant’ın tanıtım, bilgilendirme, reklam
                  faaliyetlerinin yapılabilmesi ve müşteriler ile iletişim
                  kurulabilmesi amacıyla, işlendikleri amaçla bağlantılı,
                  sınırlı ve ölçülü, ilgili mevzuatta öngörülen veya
                  işlendikleri amaç için gerekli olan süre kadar muhafaza edilme
                  ilkelerine uygun olarak işlendiğine ve Ticari İletişim ve
                  Ticari Elektronik İletiler Hakkında Yönetmelik’te yer alan
                  usul ve esaslar uyarınca online alışveriş sürecinde
                  müşterilere e-posta ve SMS iletilerinin ulaştırılacağına
                  ilişkin Bilgilendirme ve Aydınlatma Metni’ni okudum, anladım.
                </Typography>
                <Typography variant="body2" sx={{ mt: 1 }}>
                  Mega Merchant’ın şahsıma ait ad ve soyad, telefon numarası,
                  e-posta adresi verilerini topladığı, işlediği, kaydettiği,
                  depoladığı; işlenen verilerin yurt dışında herhangi bir kurum
                  ve kuruluşa aktarılmadığı, 6698 sayılı Kişisel Verilen
                  Korunması Kanunu’nun 9’uncu maddesi gereğince bu konularda
                  açık rızam olması gerektiği ve açık rızamı istediğim zaman
                  çekebileceğim konusunda; ayrıca e-posta ve SMS iletilerinin
                  ulaştırılmasına ilişkin Ticari Elektronik İletiler Hakkında
                  Yönetmelik uyarınca onayım olması gerektiği ve reddetme hakkı
                  kapsamında onayımı istediğim zaman çekebileceğim konusunda da
                  bilgilendirildim. Bu kapsamda ad ve soyad, telefon numarası,
                  e-posta adresi verilerinden ibaret olan kişisel verilerimin
                  yukarıda sayılan sebep ve koşullarla işlenmesine,
                  kaydedilmesine ve depolanmasına açık rızam bulunmaktadır.
                  İlaveten, tarafıma e-posta ve SMS iletilerinin ulaştırılmasına
                  onay veriyorum.
                </Typography>
              </Box>
            </DialogContent>
          </Dialog>
        </Box>
      </Dialog>
    </div>
  );
};

export default Signup;
