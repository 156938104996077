import React, { useState } from "react";
import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import { styled } from "@mui/material/styles";
import { TabContext, TabPanel } from "@mui/lab";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { useTheme } from "@mui/material";
import { useTranslation } from "react-i18next";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    color: theme.palette.primary.main,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
    borderRight: `1px solid ${theme.palette.primary.bordersColor}}`,
    borderRadius: "2px",
    "&:last-child": {
      borderRight: "none",
    },
  },
}));

const AntTabs = styled(Tabs)({
  "& .MuiTabs-indicator": {
    backgroundColor: "transparent",
  },
});

const AntTab = styled((props) => <Tab disableRipple {...props} />)(
  ({ theme }) => ({
    marginTop: "10px",
    padding: "0px 20px 0 20px",
    border: `1px solid ${theme.palette.primary.bordersColor}`,
    fontSize: "14px",
    textTransform: "none",
    minWidth: 0,
    minHeight: "36px",
    [theme.breakpoints.up("sm")]: {
      minWidth: 0,
    },
    color: theme.palette.primary.fontColor,
    "&:hover": {
      color: theme.palette.secondary.main,
      opacity: 1,
    },
    "&.Mui-selected": {
      color: theme.palette.secondary.main,
    },
    "&.Mui-focusVisible": {
      backgroundColor: "#d1eaff",
    },
    // "&:nth-of-type(1)": {
    //   borderRight: "none",
    // },
    // "&:nth-of-type(2)": {
    //   borderRight: "none",
    // },
    "&:nth-of-type(3)": {
      borderRight: "none",
    },
  })
);

const FbmmDetailTab = ({ order }) => {
  const [value, setValue] = useState("1");

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const theme = useTheme();
  const { t } = useTranslation();

  const tableProductTitles = [
    t("sku"),
    t("barcode"),
    t("productName"),
    // "Ürün Fiyatı",
    t("quantity"),
  ];
  // const tableOrdersPricesTitles = ["Gider Türü", "Fiyat", "Fatura Tarihi"];
  // const tableOrdersPricesDetailsTitles = [
  //   "Paket No.",
  //   "Takip No",
  //   "Ürün Boyutları (cm)",
  //   "Ürün Ağırlığı (gr)",
  //   "Ürün Bilgisi",
  //   "Adet",
  //   "Paket kodu",
  //   "Paket Kategorisi",
  // ];
  // const tableOrdersHistoryTitles = [
  //   "Kullanıcı",
  //   "Kayıt",
  //   "Not",
  //   "İşlem Tarihi",
  // ];

  // const rows = [];

  return (
    <Box sx={{ width: "100%" }}>
      <Box sx={{ bgcolor: "#fff" }}>
        <TabContext value={value}>
          <AntTabs
            sx={{
              borderBottom: `1px solid ${theme.palette.primary.bordersColor}`,
            }}
            value={value}
            onChange={handleChange}
          >
            <AntTab value="1" label={t("products")} />
            {/* <AntTab value="2" label="Sipariş Ücreti" /> */}
            {/* <AntTab value="3" label="Sipariş Paket Detayı" />
            <AntTab value="4" label="Sipariş Geçmişi" /> */}
          </AntTabs>
          <TabPanel sx={{ p: 0, m: 0 }} value="1">
            <TableContainer
              component={Paper}
              sx={{
                mb: 5,
                border: `1px solid ${theme.palette.primary.bordersColor}`,
                borderBottom: "none!important",
                boxShadow: "none",
              }}
            >
              <Table aria-label="simple table">
                <TableHead>
                  <TableRow>
                    {tableProductTitles.map((title) => (
                      <TableCell>{title}</TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {order.orderProducts.map((row) => (
                    <TableRow
                      key={row.name}
                      style={{
                        borderTop: `1px solid ${theme.palette.primary.bordersColor}`,
                        borderBottom: "none!important",
                      }}
                    >
                      <StyledTableCell component="th" scope="row">
                        {row.productCode === null ? "null" : row.productCode}
                      </StyledTableCell>
                      <StyledTableCell align="left">
                        {row.barcode}
                      </StyledTableCell>
                      <StyledTableCell align="left">
                        {row.productName}
                      </StyledTableCell>
                      {/* <StyledTableCell align="left">
                        {row.price}
                      </StyledTableCell> */}
                      <StyledTableCell align="left">
                        {row.quantity}
                      </StyledTableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </TabPanel>
          {/* <TabPanel sx={{ p: 0, m: 0 }} value="2">
            <TableContainer
              component={Paper}
              sx={{
                mb: 5,
                border: `1px solid ${theme.palette.primary.bordersColor}`,
                boxShadow: "none",
              }}
            >
              <Table aria-label="simple table">
                <TableHead>
                  <TableRow>
                    {tableOrdersPricesTitles.map(title => (
                      <TableCell>{title}</TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {rows.map(row => (
                    <TableRow key={row.name}>
                      <StyledTableCell component="th" scope="row">
                        {row.name}
                      </StyledTableCell>
                      <StyledTableCell align="left">
                        {row.calories}
                      </StyledTableCell>
                      <StyledTableCell align="left">{row.fat}</StyledTableCell>
                      <StyledTableCell align="left">
                        {row.carbs}
                      </StyledTableCell>
                      <StyledTableCell align="left">
                        {row.protein}
                      </StyledTableCell>
                      <StyledTableCell align="left">
                        {row.protein}
                      </StyledTableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </TabPanel> */}
          {/* <TabPanel sx={{ p: 0, m: 0 }} value="3">
            <TableContainer
              component={Paper}
              sx={{
                mb: 5,
                border: `1px solid ${theme.palette.primary.bordersColor}`,
                boxShadow: "none",
              }}
            >
              <Table aria-label="simple table">
                <TableHead>
                  <TableRow>
                    {tableOrdersPricesDetailsTitles.map(title => (
                      <TableCell>{title}</TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {rows.map(row => (
                    <TableRow key={row.name}>
                      <StyledTableCell component="th" scope="row">
                        {row.name}
                      </StyledTableCell>
                      <StyledTableCell align="left">
                        {row.calories}
                      </StyledTableCell>
                      <StyledTableCell align="left">{row.fat}</StyledTableCell>
                      <StyledTableCell align="left">
                        {row.carbs}
                      </StyledTableCell>
                      <StyledTableCell align="left">
                        {row.protein}
                      </StyledTableCell>
                      <StyledTableCell align="left">
                        {row.protein}
                      </StyledTableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </TabPanel>
          <TabPanel sx={{ p: 0, m: 0 }} value="4">
            <TableContainer
              component={Paper}
              sx={{
                mb: 5,
                border: `1px solid ${theme.palette.primary.bordersColor}`,
                boxShadow: "none",
              }}
            >
              <Table aria-label="simple table">
                <TableHead>
                  <TableRow>
                    {tableOrdersHistoryTitles.map(title => (
                      <TableCell>{title}</TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {rows.map(row => (
                    <TableRow key={row.name}>
                      <StyledTableCell component="th" scope="row">
                        {row.name}
                      </StyledTableCell>
                      <StyledTableCell align="left">
                        {row.calories}
                      </StyledTableCell>
                      <StyledTableCell align="left">{row.fat}</StyledTableCell>
                      <StyledTableCell align="left">
                        {row.carbs}
                      </StyledTableCell>
                      <StyledTableCell align="left">
                        {row.protein}
                      </StyledTableCell>
                      <StyledTableCell align="left">
                        {row.protein}
                      </StyledTableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </TabPanel> */}
        </TabContext>
      </Box>
    </Box>
  );
};

export default FbmmDetailTab;
