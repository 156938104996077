import React from "react";
import Button from "@mui/material/Button";
import { styled, useTheme } from "@mui/material/styles";
import { Box } from "@mui/material";

const CustomSubmitButton = ({ buttonText, onSubmitEvent }) => {
  const theme = useTheme();
  const BootstrapButton = styled(Button)({
    boxShadow: "none",
    textTransform: "none",
    fontSize: 14,
    padding: "6px 12px",
    marginTop: "3px",
    border: "1px solid",
    lineHeight: 1.5,
    borderRadius: "2px",
    backgroundColor: theme.palette.primary.main,
    borderColor: theme.palette.primary.main,
    "&:hover": {
      backgroundColor: theme.palette.primary.main,
      borderColor: theme.palette.primary.main,
      boxShadow: "none",
    },
    "&:active": {
      boxShadow: "none",
      backgroundColor: theme.palette.primary.main,
      borderColor: theme.palette.primary.main,
    },
    "&:focus": {
      boxShadow: "0 0 0 0.2rem rgba(0,123,255,.5)",
    },
  });

  return (
    <Box display="flex" justifyContent="flex-end">
      <BootstrapButton onClick={onSubmitEvent} variant="contained">{buttonText}</BootstrapButton>
    </Box>
  );
};

export default CustomSubmitButton;
