import React, { useEffect, useState } from "react";
import { Box, useTheme, Typography, Button } from "@mui/material";

import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import axios from "axios";
import CircularProgress from "@mui/material/CircularProgress";

import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

const Faqs = () => {
  const navigate = useNavigate();
  const [faqs, setFaqs] = useState([]);
  const theme = useTheme();
  const { t } = useTranslation();
  const [loading, setLoading] = useState(true);
  const [expanded, setExpanded] = React.useState(false);

  const handleChange = panel => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  const handleBack = () => {
    navigate(-1);
  };

  let data = "";
  let config = {
    method: "get",
    maxBodyLength: Infinity,
    url: `${process.env.REACT_APP_BASE_URL}/Faq/GetFaqS`,
    data: data,
  };

  const fetchFaqs = async () => {
    try {
      const response = await axios(config);
      setFaqs(response.data.items);
      setLoading(false);
    } catch (error) {
      console.log("error", error);
    }
  };

  useEffect(() => {
    fetchFaqs();
  }, []);

  if (loading)
    return (
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "90vh",
        }}
      >
        <CircularProgress />
      </Box>
    );
  return (
    <Box m={"20px"}>
      <Box
        sx={{
          display: "grid",
          gridTemplateColumns: "1fr 1fr 1fr",
          placeItems: "start",
          padding: "20px",
          paddingX: "35px",
        }}
      >
        <Box
          sx={{
            width: "100%",
          }}
        >
          <Button
            onClick={handleBack}
            color="text"
            startIcon={<ArrowBackIcon />}
          >
            {t("goBack")}
          </Button>
        </Box>
        <Box
          sx={{
            width: "100%",
            textAlign: "center",
          }}
        >
          <Typography
            sx={{
              fontSize: 24,
              fontWeight: 600,
              color: theme.palette.primary.main,
            }}
          >
            {t("frequentlyAskedQuestions")}
          </Typography>
        </Box>

        <Box></Box>
      </Box>
      <Box sx={{ background: theme.palette.primary.pageBackground }} p={5}>
        {faqs.map((faq, index) => (
          <Accordion
            expanded={expanded === `panel${index + 1}`}
            onChange={handleChange(`panel${index + 1}`)}
            key={index}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls={`panel${index + 1}bh-content`}
              id={`panel${index + 1}bh-header`}
            >
              <Typography
                sx={{
                  fontSize: 16,
                  fontWeight: 600,
                }}
              >
                {faq.question}
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography
                sx={{
                  pb: 2,
                }}
              >
                <div
                  dangerouslySetInnerHTML={{
                    __html: faq.answer,
                  }}
                ></div>
              </Typography>
            </AccordionDetails>
          </Accordion>
        ))}
      </Box>
    </Box>
  );
};

export default Faqs;
