import React from "react";
import { Box } from "@mui/material";
import Topbar from "./global/Topbar";
import { useTranslation } from "react-i18next";
const NotFound = () => {
  const { t } = useTranslation();
  return (
    <Box m={"20px"}>
      <Topbar title={ "404 " - t("pageNotFound")}></Topbar>
      <div>
        <p>{t("sorryThePageYouAreLookingForIsNotavailable")}</p>
        {/* Ek bilgiler veya geri dönüş butonları eklenebilir */}
      </div>
    </Box>
  );
};

export default NotFound;
