import React from "react";

const AddMMUserIcon = () => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_2478_4058)">
        <path
          d="M7.52148 11.36C7.52148 12.3785 7.92606 13.3552 8.64619 14.0753C9.36633 14.7954 10.3431 15.2 11.3615 15.2C12.3799 15.2 13.3566 14.7954 14.0768 14.0753C14.7969 13.3552 15.2015 12.3785 15.2015 11.36C15.2015 10.3416 14.7969 9.36487 14.0768 8.64473C13.3566 7.92459 12.3799 7.52002 11.3615 7.52002C10.3431 7.52002 9.36633 7.92459 8.64619 8.64473C7.92606 9.36487 7.52148 10.3416 7.52148 11.36Z"
          stroke="#453834"
          strokeWidth="1.2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M11.3613 9.44006V13.2801"
          stroke="#453834"
          strokeWidth="1.2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M9.44141 11.3601H13.2814"
          stroke="#453834"
          strokeWidth="1.2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M0.800781 11.3601C0.801268 10.6459 0.978729 9.94301 1.3173 9.31418C1.65587 8.68535 2.14499 8.15023 2.74094 7.75665C3.33688 7.36307 4.02107 7.12331 4.73233 7.0588C5.44359 6.9943 6.15975 7.10706 6.81678 7.387"
          stroke="#453834"
          strokeWidth="1.2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M2.48047 3.44005C2.48047 4.14022 2.75861 4.81171 3.25371 5.30681C3.7488 5.80191 4.4203 6.08005 5.12047 6.08005C5.82064 6.08005 6.49213 5.80191 6.98723 5.30681C7.48233 4.81171 7.76047 4.14022 7.76047 3.44005C7.76047 2.73988 7.48233 2.06838 6.98723 1.57329C6.49213 1.07819 5.82064 0.800049 5.12047 0.800049C4.4203 0.800049 3.7488 1.07819 3.25371 1.57329C2.75861 2.06838 2.48047 2.73988 2.48047 3.44005Z"
          stroke="#453834"
          strokeWidth="1.2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_2478_4058">
          <rect width="20" height="20" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default AddMMUserIcon;
