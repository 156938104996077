import React, { useEffect, useState } from "react";
import {
  createIyzicoPackage,
  getIyzicoPackage,
  updateIyzicoPackage,
} from "../api";
import {
  Box,
  Button,
  Container,
  Grid,
  TextField,
  Typography,
} from "@mui/material";
import { styled } from "@mui/system";
import { Modal } from "../common";
import { useTranslation } from "react-i18next";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CancelIcon from "@mui/icons-material/Cancel";
import { useNavigate, useParams } from "react-router-dom";

const Label = styled(Typography)({
  fontWeight: 500,
  fontSize: "14px",
  lineHeight: "16px",
  marginBottom: "6px",
  width: "fit-content",
});

const StyledTextField = styled(TextField)({
  height: "32px",
  mxWidth: "279px",
  width: "100%",
  "& .MuiInputBase-root": {
    height: "32px",
  },
  "& input": {
    padding: "8px 12px",
  },
});

const Package = () => {
  const { t } = useTranslation();
  const { id: userId } = useParams();
  const navigate = useNavigate();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [formData, setFormData] = useState({
    packageId: "",
    advantages: "",
    price: "",
    title: "",
  });

  const [sumbitResult, setSubmitResult] = useState({
    success: null,
    message: "",
  });

  const onCleanHandler = () => {
    setFormData({
      packageId: "",
      advantages: "",
      price: "",
      title: "",
    });
  };

  const onSubmitHandler = () => {
    if (userId) {
      updateIyzicoPackage({ id: userId, body: formData })
        .then((response) => {
          setSubmitResult({
            success: true,
            message: t("packageUpdated"),
          });
        })
        .catch(() => {
          setSubmitResult({
            success: false,
            message: t("packageNotUpdated"),
          });
        })
        .finally(() => setIsModalOpen(true));
    } else {
      createIyzicoPackage({
        body: { ...formData, price: Number(formData.price) },
      })
        .then((response) => {
          setSubmitResult({
            success: true,
            message: t("newPackageSubmitted"),
          });
        })
        .catch(() => {
          setSubmitResult({
            success: false,
            message: t("newPackageNotSubmitted"),
          });
        })
        .finally(() => setIsModalOpen(true));
    }
  };

  const onFormDataChanged = (name, value) => {
    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  useEffect(() => {
    if (userId) {
      getIyzicoPackage({ id: userId }).then((res) => {
        const { packageId, advantages, price, title } = res;
        setFormData({
          packageId,
          advantages,
          price,
          title,
        });
      });
    }
  }, [userId]);

  return (
    <Container maxWidth="100%">
      <Grid
        spacing={3}
        xs={12}
        sx={{ backgroundColor: "#fff", width: "100%", padding: "24px" }}
      >
        <Grid xs={6} maxWidth={"50%"} item>
          <Grid item xs={12} marginBottom="12px">
            <>
              <Typography
                variant="h6"
                margin={0}
                fontSize="18px"
                fontWeight="700"
                gutterBottom
              >
                {t("newPackage")}
              </Typography>
              <Box
                component="form"
                noValidate
                autoComplete="off"
                sx={{ mt: "12px" }}
                border="1px solid #45383426"
                padding="12px"
                borderRadius="2px"
              >
                <Typography
                  variant="subtitle1"
                  gutterBottom
                  color="primary"
                  fontWeight="700"
                  fontSize="14px"
                  marginBottom="12px"
                  paddingBottom="7px"
                  borderBottom={"1px solid  #D9D9D9"}
                >
                  {t("addNewPackage")}
                </Typography>
                <Grid container spacing={2} xs={8} md={12}>
                  <Grid item xs={12}></Grid>
                  <Grid item xs={5}>
                    <Label>
                      {t("packageId")}
                      <span style={{ color: "#D7283E" }}> *</span>
                    </Label>
                    <StyledTextField
                      type="text"
                      onChange={(e) => {
                        const numericValue = Number(
                          e.target.value.replace(/\D/g, "")
                        );
                        onFormDataChanged("packageId", numericValue);
                      }}
                      value={formData.packageId}
                      required
                      placeholder="4241213"
                      variant="outlined"
                      inputProps={{ maxLength: 4 }}
                    />
                  </Grid>

                  <Grid item xs={5}>
                    <Label>
                      {t("price")}
                      <span style={{ color: "#D7283E" }}> *</span>
                    </Label>
                    <StyledTextField
                      onChange={(e) => {
                        const numericValue = e.target.value.replace(/\D/g, "");
                        onFormDataChanged("price", numericValue);
                      }}
                      value={formData.price}
                      required
                      placeholder="200"
                    />
                  </Grid>

                  <Grid item xs={5}>
                    <Label>
                      {t("title")}
                      <span style={{ color: "#D7283E" }}> *</span>
                    </Label>
                    <StyledTextField
                      onChange={(e) => {
                        const lettersOnly = e.target.value.replace(
                          /[^a-zA-ZĞğÜüŞşİıÖöÇç\s]/g,
                          ""
                        );

                        onFormDataChanged("title", lettersOnly);
                      }}
                      value={formData.title}
                      required
                      placeholder="Zebra paketi"
                    />
                  </Grid>
                  <Grid item xs={10}>
                    <Label>
                      {t("advantages")}
                      <span style={{ color: "#D7283E" }}> *</span>
                    </Label>
                    <TextField
                      value={formData.advantages}
                      onChange={(e) =>
                        onFormDataChanged("advantages", e.target.value)
                      }
                      placeholder="Çok verimli bir ürün ve.."
                      multiline
                      rows={4}
                      variant="outlined"
                      fullWidth
                    />
                  </Grid>
                </Grid>
              </Box>
            </>
          </Grid>
          <Grid
            item
            xs={12}
            sx={{ display: "flex", justifyContent: "space-between" }}
          >
            <Button
              onClick={onCleanHandler}
              color="secondary"
              style={{
                backgroundColor: "#F7F6F5",
                color: "#453834",
                fontWeight: "500",
                fontSize: "14px",
                border: "1px solid #45383426",
              }}
            >
              {t("clear")}
            </Button>
            <Button
              onClick={onSubmitHandler}
              variant="contained"
              style={{
                backgroundColor: "#EB603A",
                fontSize: "14px",
                padding: "8px",
                cursor: `${
                  Object.values(formData).every(Boolean)
                    ? "pointer"
                    : "not-allowed"
                }`,
                opacity: `${
                  Object.values(formData).every(Boolean) ? "1" : "0.8"
                }`,
              }}
            >
              {t("save")}
            </Button>
          </Grid>
          <Grid item xs={12}>
            <Box></Box>
          </Grid>
        </Grid>
      </Grid>
      <Modal
        onClose={() => {
          setIsModalOpen(false);
          navigate("/packages");
        }}
        open={isModalOpen}
        title={t("packageInformation")}
      >
        <Box
          p={2}
          color={`${sumbitResult.success ? "#1eb51e" : "red"}`}
          display="flex"
          alignItems="center"
          justifyContent="flex-start"
        >
          {sumbitResult.success ? <CheckCircleIcon /> : <CancelIcon />}
          <Typography
            variant="body1"
            sx={{
              ml: 1,
              fontWeight: "500",

              color: `${sumbitResult.success ? "#1eb51e" : "red"}`,
            }}
          >
            {sumbitResult.message}
          </Typography>
        </Box>
      </Modal>
    </Container>
  );
};

export default Package;
