import React, { useState, useEffect } from "react";
import Cookies from "js-cookie";
import RadioGroup from "@mui/material/RadioGroup";
import Radio from "@mui/material/Radio";
import FormControlLabel from "@mui/material/FormControlLabel";
import Box from "@mui/material/Box";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import FormControl from "@mui/material/FormControl";
import CustomLabel from "./form-elements/CustomLabel";
import BoxTitle from "./form-elements/BoxTitle";
import FormGroup from "./form-elements/FormGroup";
import { useTranslation } from "react-i18next";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import axios from "axios";
import { alpha, styled } from "@mui/material/styles";
import InputBase from "@mui/material/InputBase";
import { Form } from "react-bootstrap";
import {
  Grid,
  MenuItem,
  Select,
  useTheme,
  Checkbox,
  ListItemText,
  Divider,
  Tooltip,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import { AddCircle } from "@mui/icons-material";
const options = [
  {
    id: "42d05c20-d106-4919-94e7-bfb345b874a3",
    title: "Elektrikli Mutfak Aletleri",
    value: "42d05c20-d106-4919-94e7-bfb345b874a3",
  },
  {
    id: "2a2b4892-7907-44db-8b10-79b03dc1a8b8",
    title: "Elektronik-Bilgisayar",
    value: "2a2b4892-7907-44db-8b10-79b03dc1a8b8",
  },
  {
    id: "33be39bb-7d71-4823-8c48-a04f1d3ef576",
    title: "Ev Tekstili",
    value: "33be39bb-7d71-4823-8c48-a04f1d3ef576",
  },
  {
    id: "86d8e1bb-1e5b-41e2-83d7-dabf1ab54b62",
    title: "Giyim",
    value: "86d8e1bb-1e5b-41e2-83d7-dabf1ab54b62",
  },
  {
    id: "f781c9d2-d352-4524-81c2-152d023c6a1c",
    title: "Gıda Ürünleri ve Takviyeler",
    value: "f781c9d2-d352-4524-81c2-152d023c6a1c",
  },
  {
    id: "48b024ee-9d1e-4d1e-83c9-05acf11ac5df",
    title: "Hızlı Tüketim Ürünleri",
    value: "48b024ee-9d1e-4d1e-83c9-05acf11ac5df",
  },
  {
    id: "bf4980df-c62b-49d3-a17a-bb1c70cbf434",
    title: "Hobiler ve Eğlence Ürünleri",
    value: "bf4980df-c62b-49d3-a17a-bb1c70cbf434",
  },
  {
    id: "d6303117-f198-47f9-af83-4af99458d42d",
    title: "Kozmetik / Sağlık",
    value: "d6303117-f198-47f9-af83-4af99458d42d",
  },
  {
    id: "8c9cb67e-7b4b-49aa-9f39-f8d3af05cfec",
    title: "Makine Parçaları",
    value: "8c9cb67e-7b4b-49aa-9f39-f8d3af05cfec",
  },
  {
    id: "fb841e94-22f6-4512-9f22-fec93b6e8616",
    title: "Mobilya & Dekorasyon",
    value: "fb841e94-22f6-4512-9f22-fec93b6e8616",
  },
  {
    id: "e0b3d64e-042e-4009-9efa-1ffc85c205ef",
    title: "Mücevher ve Aksesuarlar",
    value: "e0b3d64e-042e-4009-9efa-1ffc85c205ef",
  },
  {
    id: "bc29cc49-978f-4dab-bd14-aac14490356d",
    title: "Ofis Malzemeleri ve Kırtasiye",
    value: "bc29cc49-978f-4dab-bd14-aac14490356d",
  },
  {
    id: "b6e70ae3-c6ea-4ebf-82dc-d26b95668ee7",
    title: "Otomotiv",
    value: "b6e70ae3-c6ea-4ebf-82dc-d26b95668ee7",
  },
  {
    id: "ac6039b8-4b55-447e-b968-edfe2ac13448",
    title: "Oyuncak",
    value: "ac6039b8-4b55-447e-b968-edfe2ac13448",
  },
  {
    id: "09432459-b407-4018-8f4f-80da23070a22",
    title: "Pet Shop",
    value: "09432459-b407-4018-8f4f-80da23070a22",
  },
  {
    id: "5ece11ae-a8b1-448e-ae28-6baef40cf6fe",
    title: "Spor & Outdoor",
    value: "5ece11ae-a8b1-448e-ae28-6baef40cf6fe",
  },
  {
    id: "5d78da72-a035-4a69-a564-fb95d4d6bdfd",
    title: "Yapı Market",
    value: "5d78da72-a035-4a69-a564-fb95d4d6bdfd",
  },
  {
    id: "788435f7-2508-412c-8f22-f56946e6dcd2",
    title: "Züccaciye",
    value: "788435f7-2508-412c-8f22-f56946e6dcd2",
  },
  {
    id: "2e39504a-594d-4b41-bcf7-72b653616b06",
    title: "Diğer",
    value: "2e39504a-594d-4b41-bcf7-72b653616b06",
  },
  {
    id: "493fd4bc-7736-48c3-a279-c3a9002b247c",
    title: "Anne Bebek Ürünleri",
    value: "493fd4bc-7736-48c3-a279-c3a9002b247c",
  },
  {
    id: "ef61882f-4e65-49c0-a674-abb5bd06877c",
    title: "Ayakkabı ve Çanta",
    value: "ef61882f-4e65-49c0-a674-abb5bd06877c",
  },
  {
    id: "30766d72-0e74-4a3e-af15-f5cfcd043432",
    title: "Bahçe",
    value: "30766d72-0e74-4a3e-af15-f5cfcd043432",
  },
];

const steps = ["1", "2", "3", "4"];

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const BootstrapSelect = styled(InputBase)(({ theme }) => ({
  "label + &": {
    marginTop: theme.spacing(3),
    color: `${alpha(theme.palette.text.main, 0.25)} 0 0 0 0.2rem`,
  },
  "& .MuiInputBase-input": {
    borderRadius: 2,
    position: "relative",
    border: "1px solid",
    fontSize: 14,
    fontStyle: "italic",
    height: "4px!important",
    width: "100%",
    padding: "10px 12px",
    lineHeight: "1",
    marginTop: "0.5rem",
    borderColor: theme.palette.primary.bordersColor,
    transition: theme.transitions.create([
      "border-color",
      "background-color",
      "box-shadow",
    ]),
    minHeight: " 0.8rem!important",
    "&:focus": {
      boxShadow: `${alpha(theme.palette.primary.main, 0.25)} 0 0 0 0.2rem`,
      borderColor: theme.palette.primary.main,
      color: theme.palette.text.main,
    },
  },
}));
const BootstrapInput = styled(InputBase)(({ theme }) => ({
  "label + &": {
    marginTop: theme.spacing(3),
    color: `${alpha(theme.palette.text.main, 0.25)} 0 0 0 0.2rem`,
  },
  "& .MuiInputBase-input": {
    borderRadius: 2,
    position: "relative",
    border: "1px solid",
    fontSize: 14,
    fontStyle: "italic",
    height: "12px",
    width: "100%",
    padding: "10px 12px",
    marginTop: "0.5rem",
    borderColor: theme.palette.primary.bordersColor,
    transition: theme.transitions.create([
      "border-color",
      "background-color",
      "box-shadow",
    ]),
    "&:focus": {
      boxShadow: `${alpha(theme.palette.primary.main, 0.25)} 0 0 0 0.2rem`,
      borderColor: theme.palette.primary.main,
      color: theme.palette.text.main,
    },
  },
}));

const currencies = [
  {
    id: "914228f0-2483-461d-a473-ab2c975f8760",
    name: "TRY",
    symbol: "₺",
  },
  {
    id: "447cb078-b48a-48c4-bdb6-e7ffc5ddf51e",
    name: "EEK",
    symbol: "kr",
  },
  {
    id: "30bf82ac-5add-4345-b5d2-b841e002e328",
    name: "USD",
    symbol: "$",
  },
  {
    id: "89e774ed-b6e2-40e2-a4bf-5c418d57fff1",
    name: "AUD",
    symbol: "$",
  },
  {
    id: "2b207dbc-7ff3-4b36-be83-231672622957",
    name: "EUR",
    symbol: "€",
  },
  {
    id: "16c46384-0864-4b77-9f03-8fd2461ec0f1",
    name: "GBP",
    symbol: "£",
  },
  {
    id: "31b8bc45-2659-41be-96fd-c15e25d76bfa",
    name: "CHF",
    symbol: "CHF",
  },
  {
    id: "afd00d04-9c4c-465a-9a57-c373f5a2c59d",
    name: "SEK",
    symbol: "kr",
  },
  {
    id: "3cfcb4a4-04b1-4c22-9dd3-09936f733802",
    name: "CAD",
    symbol: "$",
  },
  {
    id: "d518297f-bf03-4417-b3cd-3734ce69d8df",
    name: "SAR",
    symbol: "﷼",
  },
  {
    id: "372e4436-9526-4a6d-9d07-f459d46a3cbf",
    name: "RUB",
    symbol: "₽",
  },
  {
    id: "b945bd24-319b-4b6d-b994-96c0d2fb7ef4",
    name: "AED",
    symbol: "د.إ",
  },
  {
    id: "0e866ced-4a20-469d-a186-93b44f753397",
    name: "INR",
    symbol: "₹",
  },
  {
    id: "28fa4b2e-a878-44bc-a5be-59dabc18b916",
    name: "PLN",
    symbol: "zł",
  },
];

const ProductForm = () => {
  const theme = useTheme();
  const { t } = useTranslation();
  const [haveVariant, setHaveVariant] = useState(false);
  const [submitDisabled, setSubmitDisabled] = useState(false);
  const [activeStep, setActiveStep] = useState(0);
  const [skipped, setSkipped] = useState(new Set());
  const [warehouses, setWarehouses] = useState([]);
  const [categories, setCategories] = useState([]);
  const [sku, setSku] = useState("");
  const [barcode, setBarcode] = useState("");
  const [category, setCategory] = useState([]);
  const [productName, setProductName] = useState("");
  const [productDescription, setProductDescription] = useState("");
  const [brand, setBrand] = useState("");
  const [gtip, setGtip] = useState("");
  const [productLink, setProductLink] = useState("");
  const [width, setWidth] = useState();
  const [length, setLength] = useState();
  const [height, setHeight] = useState();
  const [weight, setWeight] = useState();
  const [isFragile, setIsFragile] = useState(false);
  const [isLiquid, setIsLiquid] = useState(false);
  const [isFlammableOrExplosive, setIsFlammableOrExplosive] = useState(false);
  const [currency, setCurrency] = useState("");
  const [isExpirationDate, setIsExpirationDate] = useState(false);
  const [productUnitPrice, setProductUnitPrice] = useState(null);
  const [productWarehouse, setProductWarehouse] = useState("");
  const [selectedCurrency, setSelectedCurrency] = useState("");
  const [expanded, setExpanded] = useState("panel0");
  const [variant, setVariant] = useState([]);
  const [formErrors, setFormErrors] = useState({});
  const [selectedCategoryTitles, setSelectedCategoryTitles] = useState("");

  const handleChangeAccordion = panel => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  const validateFields = (fieldName, value) => {
    const newErrors = {
      ...formErrors,
    };

    if (fieldName === "sku") {
      // Giriş değerindeki sayıları temizle
      if (value.length < 3) {
        newErrors.sku = t("modelCodeMustBeAtLeastThreeCharacters");
      } else {
        newErrors.sku = "";
      }
    }

    if (fieldName === "barcode") {
      // Giriş değerindeki sayıları temizle
      if (value.length < 12) {
        newErrors.barcode = t("theBarcodeMustBeAtLeastTwelveCharacters");
      } else {
        newErrors.barcode = "";
      }
    }

    if (fieldName === "productName") {
      // Giriş değerindeki sayıları temizle
      if (value.length < 3) {
        newErrors.productName = t("theProductNameMustBeAtLeastThreeCharacters");
      } else {
        newErrors.productName = "";
      }
    }

    if (fieldName === category) {
      if (value.length < 1) {
        newErrors.category = t("selectCategory");
      } else {
        newErrors.category = "";
      }
    }

    if (fieldName === "brand") {
      if (value.length < 3) {
        newErrors.brand = t("theBrandNameMustBeAtLeastThreeCharacters");
      } else {
        newErrors.brand = "";
      }
    }

    if (fieldName === "gtip") {
      if (value.length < 3) {
        newErrors.gtip = t("GTIPMustBeAtLeastThreeCharacters");
      } else {
        newErrors.gtip = "";
      }
    }

    // if (fieldName === "productLink") {
    //   if (value.length < 1) {
    //     newErrors.productLink = "Ürün linki giriniz.";
    //   } else {
    //     newErrors.productLink = "";
    //   }
    // }

    if (fieldName === "width") {
      if (value.length < 1) {
        newErrors.width = t("mustBeAtLeastOneCharacter");
      } else if (!/^[0-9.]+$/.test(value)) {
        newErrors.width = t("itShouldOnlyConsistOfNumbers");
      } else if (value < 0) {
        newErrors.width = t("theWidthCannotBeLessThanZero");
      } else {
        newErrors.width = "";
      }
    }

    if (fieldName === "length") {
      if (value.length < 1) {
        newErrors.length = t("mustBeAtLeastOneCharacter");
      } else if (!/^[0-9.]+$/.test(value)) {
        newErrors.length = t("itShouldOnlyConsistOfNumbers");
      } else if (value < 0) {
        newErrors.length = t("theLengthCannotBeLessThanZero");
      } else {
        newErrors.length = "";
      }
    }

    if (fieldName === "height") {
      if (value.length < 1) {
        newErrors.height = t("mustBeAtLeastOneCharacter");
      } else if (!/^[0-9.]+$/.test(value)) {
        newErrors.height = t("itShouldOnlyConsistOfNumbers");
      } else if (value < 0) {
        newErrors.height = t("heightInformationCannotBeLessThanZero");
      } else {
        newErrors.height = "";
      }
    }

    if (fieldName === "weight") {
      if (value.length < 1) {
        newErrors.weight = t("mustBeAtLeastOneCharacter");
      } else if (!/^[0-9.]+$/.test(value)) {
        newErrors.weight = t("itShouldOnlyConsistOfNumbers");
      } else if (value < 0) {
        newErrors.weight = t("weightInformationCannotBeLessThanZero");
      } else {
        newErrors.weight = "";
      }
    }

    if (fieldName === "currency") {
      if (value.length === "") {
        newErrors.currency = t("selectCurrency");
      } else {
        newErrors.currency = "";
      }
    }

    if (fieldName === "mpn") {
      if (value.length < 3) {
        newErrors.mpn = t("modelCodeMustBeAtLeastThreeCharacters");
      } else {
        newErrors.mpn = "";
      }
    }

    if (fieldName === "productUnitPrice") {
      if (value.length < 1) {
        newErrors.productUnitPrice = t("enterPriceInformation");
      } else if (!/^[0-9]+$/.test(value)) {
        newErrors.productUnitPrice = t("itShouldOnlyConsistOfNumbers");
      } else if (value < 0) {
        newErrors.productUnitPrice = t("priceInformationCannotBeLessThanZero");
      } else {
        newErrors.productUnitPrice = "";
      }
    }

    if (fieldName === "productWarehouse") {
      if (value.length === 1) {
        newErrors.productWarehouse = t("selectWarehouse");
      } else {
        newErrors.productWarehouse = "";
      }
    }

    setFormErrors(newErrors);
  };

  const [responseMessageCode, setResponseMessageCode] = useState(null);

  useEffect(() => {
    currencies.find(opt =>
      opt.id === currency ? setSelectedCurrency(opt.symbol) : ""
    );
  }, [currency]);

  const addVariant = () => {
    const newData = [
      ...variant,
      {
        barcode: "",
        mpn: "",
        title: "",
        description: "",
        link: "",
        width: "",
        length: "",
        height: "",
        weight: "",
        warehouseList: [
          {
            productId: "",
            productVariantId: "",
            warehouseId: "a3eedbcb-7e1e-4c70-8592-792e2bcf620b",
            price: null,
            amount: 0,
            currencyId: "",
          },
        ],
      },
    ];
    setVariant(newData);
    setExpanded(`panel${newData.length - 1}`);
  };

  const removeVariant = index => {
    const newData = [...variant];
    newData.splice(index, 1);
    setVariant(newData);
    setExpanded(`panel${newData.length - 1}`);
  };

  // const handleVariantChangeMpn = (index, event) => {
  //   const newData = [...variant];
  //   newData[index].mpn = event.target.value;
  //   setVariant(newData);
  // };

  const handleVariantChangeBarcode = (index, event) => {
    const newData = [...variant];
    newData[index].barcode = event.target.value;
    setVariant(newData);
    validateFields("barcode", event.target.value);
  };

  const handleVariantChangeTitle = (index, event) => {
    const newData = [...variant];
    newData[index].title = event.target.value;
    setVariant(newData);
    validateFields("productName", event.target.value);
  };

  const handleVariantChangeDescription = (index, event) => {
    const newData = [...variant];
    newData[index].description = event.target.value;
    setVariant(newData);
  };

  const handleVariantChangeLink = (index, event) => {
    const newData = [...variant];
    newData[index].link = event.target.value;
    setVariant(newData);
    validateFields("productLink", event.target.value);
  };

  const handleVariantChangeWidth = (index, event) => {
    const newData = [...variant];
    newData[index].width = event.target.value;
    setVariant(newData);
    validateFields("width", event.target.value);
  };

  const handleVariantChangeLength = (index, event) => {
    const newData = [...variant];
    newData[index].length = event.target.value;
    setVariant(newData);
    validateFields("length", event.target.value);
  };

  const handleVariantChangeHeight = (index, event) => {
    const newData = [...variant];
    newData[index].height = event.target.value;
    setVariant(newData);
    validateFields("height", event.target.value);
  };

  const handleVariantChangeWeight = (index, event) => {
    const newData = [...variant];
    newData[index].weight = event.target.value;
    setVariant(newData);
    validateFields("weight", event.target.value);
  };

  const handleVariantChangeCurrency = (index, event) => {
    const newData = [...variant];
    newData[index].warehouseList[0].currencyId = event.target.value;
    setVariant(newData);
    validateFields("currency", event.target.value);
  };

  const handleVariantChangeSku = (index, event) => {
    const newData = [...variant];
    newData[index].mpn = event.target.value;
    setVariant(newData);
    validateFields("mpn", event.target.value);
  };

  const handleVariantChangePrice = (index, event) => {
    const newData = [...variant];
    newData[index].warehouseList[0].price = Number(event.target.value);
    setVariant(newData);
    validateFields("productUnitPrice", event.target.value);
  };

  const skuHandler = event => {
    setSku(event.target.value);
    validateFields("sku", event.target.value);
  };

  const barcodeHandler = e => {
    setBarcode(e.target.value);
    validateFields("barcode", e.target.value);
  };

  const handleCategoryChange = event => {
    const _matchingItem = [];
    setCategory(event.target.value);
    validateFields("category", event.target.value);
    options.forEach(item1 => {
      event.target.value.find((item2, index) => {
        if (item2 === item1.id) {
          _matchingItem.push(
            index !== event.target.value.length - 1
              ? ", " + item1.title
              : item1.title
          );
        }
      });

      setSelectedCategoryTitles(_matchingItem);
    });
  };

  const productNameHandler = e => {
    setProductName(e.target.value);
    validateFields("productName", e.target.value);
  };

  const productDescriptionHandler = e => {
    setProductDescription(e.target.value);
    validateFields("productDescription", e.target.value);
  };

  const brandHandler = e => {
    setBrand(e.target.value);
    validateFields("brand", e.target.value);
  };

  const gtipHandler = e => {
    setGtip(e.target.value);
    validateFields("gtip", e.target.value);
  };

  const productLinkHandler = e => {
    setProductLink(e.target.value);
    validateFields("productLink", e.target.value);
  };

  const widthHandler = e => {
    const _width = e.target.value;
    setWidth(_width);
    validateFields("width", e.target.value);
  };

  const lengthHandler = e => {
    const _length = e.target.value;
    setLength(_length);
    validateFields("length", e.target.value);
  };

  const heightHandler = e => {
    const _height = e.target.value;
    setHeight(_height);
    validateFields("height", e.target.value);
  };

  const weightHandler = e => {
    const _weight = e.target.value;
    setWeight(_weight);
    validateFields("weight", e.target.value);
  };

  const isFragileHandler = () => {
    setIsFragile(!isFragile);
  };

  const isFlammableOrExplosiveHandler = () => {
    setIsFlammableOrExplosive(!isFlammableOrExplosive);
  };

  const haveVariantHandler = () => {
    setHaveVariant(!haveVariant);
    setSku("");
    setBarcode("");
    setProductName("");
    setProductDescription("");
    setBrand("");
    setGtip("");
    setCategory([]);
    setProductLink("");
    if (haveVariant === false) {
      setVariant([
        {
          barcode: "",
          mpn: "",
          title: "",
          description: "",
          link: "",
          width: "",
          length: "",
          height: "",
          weight: "",
          warehouseList: [
            {
              productId: "",
              productVariantId: "",
              warehouseId: "a3eedbcb-7e1e-4c70-8592-792e2bcf620b",
              price: null,
              amount: 0,
              currencyId: "",
            },
          ],
        },
      ]);
    } else {
      setVariant([]);
    }
  };

  const isLiquidHandler = () => {
    setIsLiquid(!isLiquid);
  };

  const isExpirationDateHandler = () => {
    setIsExpirationDate(!isExpirationDate);
  };

  const warehouseHandler = e => {
    setProductWarehouse(e.target.value);
    validateFields("productWarehouse", e.target.value);
  };

  const productUnitPriceHandler = e => {
    const _productUnitPrice = e.target.value;
    setProductUnitPrice(Number(_productUnitPrice));
    validateFields("productUnitPrice", e.target.value);
  };

  const isStepSkipped = step => {
    return skipped.has(step);
  };

  const handleNext = () => {
    let newSkipped = skipped;
    if (isStepSkipped(activeStep)) {
      newSkipped = new Set(newSkipped.values());
      newSkipped.delete(activeStep);
    }

    setActiveStep(prevActiveStep => prevActiveStep + 1);
    setSkipped(newSkipped);
  };

  const handleBack = () => {
    setActiveStep(prevActiveStep => prevActiveStep - 1);
  };

  const submitHandler = e => {
    e.preventDefault();
    setSubmitDisabled(true);
    const formData = {
      sku: sku,
      barcode: haveVariant === true ? variant[0].barcode : barcode,
      title: productName,
      width: haveVariant === true ? Number(variant[0].width) : Number(width),
      length: haveVariant === true ? Number(variant[0].length) : Number(length),
      height: haveVariant === true ? Number(variant[0].height) : Number(height),
      weight: haveVariant === true ? Number(variant[0].weight) : Number(weight),
      link: haveVariant === true ? variant[0].link : productLink,
      categories: category,
      isBreakable: isFragile,
      isLiquid: isLiquid,
      hasExpirationDate: isExpirationDate,
      variants: variant,
      brand: brand,
      currencyId:
        haveVariant === true
          ? variant[0].warehouseList[0].currencyId
          : currency,
      description: haveVariant === true ? variant[0].description : "",
      warehouseId: productWarehouse,
      price:
        haveVariant === true
          ? Number(variant[0].warehouseList[0].productUnitPrice)
          : Number(productUnitPrice),
      gTypeCode: gtip,
      isFlammableOrExplosive: isFlammableOrExplosive,
    };

    axios
      .post(`${process.env.REACT_APP_BASE_URL}/Product`, formData, {
        headers: {
          Authorization: `Bearer ${Cookies.get("_auth")}`,
        },
      })
      .then(res => {
        if (res.status === 200) {
          setResponseMessageCode(res.status);
        }
        setActiveStep(prevActiveStep => prevActiveStep + 1);

        setTimeout(() => {
          window.location.reload();
        }, 2000);
      })
      .catch(err => {
        setActiveStep(prevActiveStep => prevActiveStep + 1);
        console.log(err);
      });
  };

  let data = "";
  let data2 = "";

  let configWarehouses = {
    method: "get",
    maxBodyLength: Infinity,
    url: `${process.env.REACT_APP_BASE_URL}/Warehouse?IsCountry=true`,
    headers: {
      Authorization: `Bearer ${Cookies.get("_auth")}`,
    },
    data: data,
  };
  let configCategories = {
    method: "get",
    maxBodyLength: Infinity,
    url: `${process.env.REACT_APP_BASE_URL}/ProductCategory?Pagination.Page=0&Pagination.Size=10`,
    headers: {
      Authorization: `Bearer ${Cookies.get("_auth")}`,
    },
    data: data,
  };

  useEffect(() => {
    const fetchWarehouses = async () => {
      const responseWarehouses = await axios.request(configWarehouses);
      const responseCategories = await axios.request(configCategories);
      const responseWarehousesData = await responseWarehouses.data.items;
      const responseCategoriesData = await responseCategories.data.items;
      setWarehouses(responseWarehousesData);
      setCategories(responseCategoriesData);
    };
    fetchWarehouses();
  }, []);

  return (
    <Box sx={{ width: "100%", background: "#fff", mt: 3, p: 2, pt: 5 }}>
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          alignItems: "end",
          marginLeft: "1.5em",
        }}
      >
        {activeStep !== 4 && (
          <Typography variant="h2" color="primary">
            {t("addNewProduct")}
          </Typography>
        )}
        <Stepper
          activeStep={activeStep}
          sx={{ marginLeft: "1rem", width: "250px" }}
        >
          {steps.map((label, index) => {
            const stepProps = {};
            const labelProps = {};
            if (isStepSkipped(index)) {
              stepProps.completed = false;
            }
            return (
              <Step key={label} {...stepProps} sx={{ color: "red" }}>
                <StepLabel {...labelProps}></StepLabel>
              </Step>
            );
          })}
        </Stepper>
      </Box>
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          alignItems: "end",
          marginLeft: "1.5em",
        }}
      >
        {activeStep === 0 ? (
          <Typography
            sx={{
              fontSize: "14px",
              color: theme.palette.text.main,
              mt: 1,
            }}
          >
            {t("pleaseEnterTheVariantInformationBlaBla")}
          </Typography>
        ) : (
          ""
        )}
        {activeStep === 2 ? (
          <Typography
            sx={{
              fontSize: "14px",
              color: theme.palette.text.main,
              mt: 1,
            }}
          >
           {t("pleaseEnterTheSpecificationsAndWarehouseInformationForYourProduct")}
          </Typography>
        ) : (
          ""
        )}
      </Box>
      <Form onSubmit={submitHandler} method="post">
        {activeStep === steps.length ? (
          <React.Fragment>
            <Box sx={{ display: "flex", flexDirection: "row", pt: 2 }}>
              {responseMessageCode === 200 ? (
                <Typography sx={{ mt: 2, mb: 1, color: "green" }}>
                  {t("productSuccessfullyAdded")}
                </Typography>
              ) : (
                <Typography sx={{ mt: 2, mb: 1, color: "red" }}>
                  {t("anErrorOccurredWhileAddingAProduct")}
                </Typography>
              )}
            </Box>
          </React.Fragment>
        ) : (
          <React.Fragment>
            <Box sx={{ mt: 0, mb: 1 }}>
              {activeStep === 0 && (
                <Box
                  sx={{
                    backgroundColor: "#fff",
                    p: 2,
                    "& > :not(style)": { m: 1 },
                  }}
                  noValidate
                  autoComplete="off"
                >
                  <FormGroup>
                    <BoxTitle title="Varyant Seçeneği" />
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "row",
                        gap: "10px",
                        flexWrap: "wrap",
                      }}
                    >
                      <Box sx={{ display: "flex", flexDirection: "row" }}>
                        <FormControl variant="standard" key="haveVariant">
                          <CustomLabel title={t("doesTheProductHaveAVariant")} />
                          <RadioGroup
                            sx={{ pt: "35px", ml: "10px" }}
                            row
                            aria-labelledby="demo-row-radio-buttons-group-label"
                            name="row-radio-buttons-group"
                            value={haveVariant}
                            onChange={haveVariantHandler}
                          >
                            <FormControlLabel
                              color="success"
                              className={
                                haveVariant === true
                                  ? "custom-radio-checked"
                                  : "custom-radio"
                              }
                              value={true}
                              control={
                                <Radio
                                  sx={{
                                    color: "#45383426",
                                    "&.Mui-checked": {
                                      color: "#34AE1A",
                                    },
                                  }}
                                />
                              }
                              label={
                                <Typography
                                  fontWeight="500"
                                  color={
                                    haveVariant === true
                                      ? theme.palette.success.main
                                      : "#453834"
                                  }
                                  fontSize="14px"
                                >
                                  {t("yes")}
                                </Typography>
                              }
                            />
                            <FormControlLabel
                              color="success"
                              className={
                                haveVariant === false
                                  ? "custom-radio-checked"
                                  : "custom-radio"
                              }
                              value={false}
                              control={
                                <Radio
                                  sx={{
                                    color: "#45383426",
                                    "&.Mui-checked": {
                                      color: "#34AE1A",
                                    },
                                  }}
                                />
                              }
                              label={
                                <Typography
                                  fontWeight="500"
                                  color={
                                    haveVariant === false
                                      ? theme.palette.success.main
                                      : "#453834"
                                  }
                                  fontSize="14px"
                                >
                                  {t("no")}
                                </Typography>
                              }
                            />
                          </RadioGroup>
                        </FormControl>
                      </Box>
                    </Box>
                  </FormGroup>
                  {
                    // Varyant varsa
                    haveVariant === true && (
                      <FormGroup>
                        <BoxTitle title={t("productGroupInformation")} />
                        {/* Grup SKU */}
                        <FormControl variant="standard" key={"sku"}>
                          <CustomLabel title="Model Kodu" isRequired />
                          <BootstrapInput
                            placeholder="Ör: 123456789"
                            required
                            name="sku"
                            value={sku}
                            onChange={skuHandler}
                            className={formErrors.sku ? "error" : "  "}
                          />
                          {
                            formErrors.sku && (
                              <span className="error-message">
                                {formErrors.sku}
                              </span>
                            ) // Varyant SKU
                          }
                        </FormControl>
                        {/* Grup Barkod */}
                        {/* <FormControl variant="standard" key={"barcode"}>
                        <CustomLabel title="Grup Barkod" isRequired />
                        <BootstrapInput
                          placeholder="Ör: 123456789"
                          onChange={barcodeHandler}
                          value={barcode}
                          className={formErrors.barcode ? "error" : "  "}
                        />
                        {formErrors.barcode && (
                          <span className="error-message">
                            {formErrors.barcode}
                          </span>
                        )}
                      </FormControl> */}
                        {/* Grup Ürün Adı */}
                        <FormControl variant="standard" key="productName">
                          <CustomLabel title={t("modelName")} isRequired />
                          <BootstrapInput
                            placeholder={t("exRedPen")}
                            onChange={productNameHandler}
                            value={productName}
                            className={formErrors.productName ? "error" : "  "}
                          />
                          {formErrors.productName && (
                            <span className="error-message">
                              {formErrors.productName}
                            </span>
                          )}
                        </FormControl>
                        {/* Kategori */}
                        <FormControl className="signup-fc">
                          <Typography
                            sx={{
                              fontSize: "13px",
                              fontWeight: "500",
                              color: theme.palette.text.main,
                              mt: "4px",
                            }}
                          >
                            {t("theCategory")}
                            <span
                              style={{
                                color: "rgb(215, 40, 62)",
                                marginLeft: "0.5rem",
                              }}
                            >
                              *
                            </span>
                          </Typography>
                          <Select
                            labelId="demo-multiple-checkbox-label"
                            id="demo-multiple-checkbox"
                            multiple
                            required
                            displayEmpty
                            className={formErrors.category ? "error" : "  "}
                            value={category}
                            input={<BootstrapSelect />}
                            onChange={handleCategoryChange}
                            renderValue={selected => (
                              <div>
                                {selected.length === 0 ? (
                                  <em
                                    style={{
                                      color: "currentColor",
                                      opacity: "0.42",
                                    }}
                                  >
                                    {t("selectCategory")}
                                  </em>
                                ) : (
                                  selected.map((id, index) => {
                                    const selectedItem = options.find(
                                      item => item.id === id
                                    );
                                    return (
                                      <span key={selectedItem.id}>
                                        {selectedItem.title}
                                        {index !== selected.length - 1
                                          ? ", "
                                          : ""}
                                      </span>
                                    );
                                  })
                                )}
                              </div>
                            )}
                          >
                            {options
                              .sort((a, b) => {
                                const titleA = a.title.toLowerCase();
                                const titleB = b.title.toLowerCase();
                                return titleA.localeCompare(titleB);
                              })
                              .map(opt => (
                                <MenuItem
                                  key={opt.value}
                                  value={opt.value}
                                  sx={{
                                    paddignTop: "0px!important",
                                    paddingBottom: "0px!important",
                                  }}
                                >
                                  <Checkbox
                                    size="small"
                                    checked={category.includes(opt.value)}
                                  />
                                  <ListItemText primary={opt.title} />
                                </MenuItem>
                              ))}
                          </Select>
                          {formErrors.category && (
                            <span className="error-message">
                              {formErrors.category}
                            </span>
                          )}
                        </FormControl>
                        {/* Marka */}
                        <FormControl variant="standard" key="brand">
                          <CustomLabel title={t("brand")} isRequired />
                          <BootstrapInput
                            placeholder="Ör: Kalem Dünyası"
                            onChange={brandHandler}
                            value={brand}
                            className={formErrors.brand ? "error" : "  "}
                          />
                          {formErrors.brand && (
                            <span className="error-message">
                              {formErrors.brand}
                            </span>
                          )}
                        </FormControl>
                        {/* Gtip */}
                        <FormControl variant="standard" key="gtip">
                          <CustomLabel title="Gtip Kodu" isRequired />
                          <BootstrapInput
                            placeholder="Ör: 1234.56.78.19.20"
                            onChange={gtipHandler}
                            value={gtip}
                            className={formErrors.gtip ? "error" : "  "}
                          />
                          {formErrors.gtip && (
                            <span className="error-message">
                              {formErrors.gtip}
                            </span>
                          )}
                        </FormControl>
                      </FormGroup>
                    )
                  }
                </Box>
              )}
              {activeStep === 1 && (
                <Box>
                  <Box>
                    {haveVariant === true ? (
                      <Box
                        sx={{
                          backgroundColor: "#fff",
                          p: 2,
                          "& > :not(style)": { m: 1 },
                        }}
                        noValidate
                        autoComplete="off"
                      >
                        {variant.map((item, nameIndex) => (
                          <Box className="dynamicVariant" key={nameIndex}>
                            <Accordion
                              expanded={expanded === `panel${nameIndex}`}
                              onChange={handleChangeAccordion(
                                `panel${nameIndex}`
                              )}
                              sx={{
                                boxShadow: "none",
                                border: "1px solid rgba(85, 56, 48, 0.15)",
                                borderRadius: "2px",
                              }}
                            >
                              <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls={`panel${nameIndex}bh-content`}
                                id={`panel${nameIndex}bh-header`}
                                sx={{
                                  minHeight: "36px!important",
                                  maxHeight: "36px!important",
                                  height: "36px!important",
                                  mt: 0.5,
                                  mb: 0.5,
                                }}
                              >
                                <Typography
                                  sx={{
                                    width: "100%",
                                    flexShrink: 0,
                                    fontSize: "16px",
                                    color: theme.palette.primary.main,
                                    fontWeight: "500",
                                  }}
                                >
                                  {t("productVariant")}
                                </Typography>
                              </AccordionSummary>
                              <AccordionDetails
                                sx={{
                                  width: "100%!important",
                                  padding: "0px!important",
                                  borderTop: "1px solid rgba(85, 56, 48, 0.15)",
                                }}
                              >
                                <FormGroup className="border-none mt-0 pt-0">
                                  {/* Varyant SKU */}
                                  <FormControl
                                    sx={{ mt: 1 }}
                                    variant="standard"
                                    key="sku"
                                  >
                                    <CustomLabel title="SKU" isRequired />
                                    <BootstrapInput
                                      placeholder="Ör: 123456789"
                                      value={item.mpn}
                                      onChange={e =>
                                        handleVariantChangeSku(nameIndex, e)
                                      }
                                      className={
                                        variant.length - 1 === nameIndex
                                          ? formErrors.mpn
                                            ? "error"
                                            : "  "
                                          : "  "
                                      }
                                    />
                                    {variant.length - 1 === nameIndex
                                      ? formErrors.mpn && (
                                          <span className="error-message">
                                            {formErrors.mpn}
                                          </span>
                                        )
                                      : ""}
                                  </FormControl>
                                  {/* Varyant Barkod */}
                                  <FormControl
                                    sx={{ mt: 1 }}
                                    variant="standard"
                                    key="productBarcode"
                                  >
                                    <CustomLabel title={t("barcode")} isRequired />
                                    <BootstrapInput
                                      placeholder="Ör: 123456789"
                                      required
                                      value={item.barcode}
                                      onChange={e =>
                                        handleVariantChangeBarcode(nameIndex, e)
                                      }
                                      className={
                                        variant.length - 1 === nameIndex
                                          ? formErrors.barcode
                                            ? "error"
                                            : "  "
                                          : "  "
                                      }
                                    />
                                    {variant.length - 1 === nameIndex
                                      ? formErrors.barcode && (
                                          <span className="error-message">
                                            {formErrors.barcode}
                                          </span>
                                        )
                                      : ""}
                                  </FormControl>
                                  {/* Varyant Adı */}
                                  <FormControl
                                    sx={{ mt: 1 }}
                                    variant="standard"
                                    key="variantProductName"
                                  >
                                    <CustomLabel title={t("productName")} isRequired />
                                    <BootstrapInput
                                      placeholder={t("exRedPen")}
                                      required
                                      value={item.title}
                                      onChange={e =>
                                        handleVariantChangeTitle(nameIndex, e)
                                      }
                                      className={
                                        variant.length - 1 === nameIndex
                                          ? formErrors.productName
                                            ? "error"
                                            : "  "
                                          : "  "
                                      }
                                    />
                                    {variant.length - 1 === nameIndex
                                      ? formErrors.productName && (
                                          <span className="error-message">
                                            {formErrors.productName}
                                          </span>
                                        )
                                      : ""}
                                  </FormControl>
                                  {/* Varyant Aciklama */}
                                  <FormControl
                                    sx={{ mt: 1 }}
                                    variant="standard"
                                    key="productDescription"
                                  >
                                    <CustomLabel title={t("description")} />
                                    <BootstrapInput
                                      placeholder="Ör: Kırmızı renkli uzun kurşun kalem "
                                      onChange={e =>
                                        handleVariantChangeDescription(
                                          nameIndex,
                                          e
                                        )
                                      }
                                      value={item.description}
                                      multiline
                                    />
                                  </FormControl>
                                  {/* Varyan Link */}
                                  <FormControl
                                    variant="standard"
                                    key="productName"
                                  >
                                    <CustomLabel title={t("productLink")} />
                                    <BootstrapInput
                                      placeholder="www.ornekurunlinki.com"
                                      required
                                      value={item.link}
                                      onChange={e =>
                                        handleVariantChangeLink(nameIndex, e)
                                      }
                                      // className={
                                      //   variant.length - 1 === nameIndex
                                      //     ? formErrors.productLink
                                      //       ? "error"
                                      //       : "  "
                                      //     : "  "
                                      // }
                                    />
                                    {/* {variant.length - 1 === nameIndex
                                      ? formErrors.productLink && (
                                          <span className="error-message">
                                            {formErrors.productLink}
                                          </span>
                                        )
                                      : ""} */}
                                  </FormControl>
                                  {/* Boyut ve Agirlik */}
                                  <Box
                                    sx={{
                                      display: "flex",
                                      flexDirection: "row",
                                      gap: "10px",
                                      mt: 1,
                                    }}
                                  >
                                    <FormControl
                                      variant="standard"
                                      key="dimensions"
                                    >
                                      <CustomLabel
                                        title={t("dimensions")+"(cm)"}
                                        isRequired
                                      />
                                      <BootstrapInput
                                        placeholder={t("width")}
                                        type="text"
                                        onChange={e =>
                                          handleVariantChangeWidth(nameIndex, e)
                                        }
                                        value={item.width}
                                        className={
                                          variant.length - 1 === nameIndex
                                            ? formErrors.width
                                              ? "error"
                                              : "  "
                                            : "  "
                                        }
                                      />
                                      {variant.length - 1 === nameIndex
                                        ? formErrors.width && (
                                            <span className="error-message">
                                              {formErrors.width}
                                            </span>
                                          )
                                        : ""}
                                    </FormControl>
                                    <FormControl
                                      variant="standard"
                                      key="length"
                                    >
                                      <CustomLabel title="" />
                                      <BootstrapInput
                                        placeholder={"Boy"}
                                        type="text"
                                        onChange={e =>
                                          handleVariantChangeLength(
                                            nameIndex,
                                            e
                                          )
                                        }
                                        value={item.length}
                                        className={
                                          variant.length - 1 === nameIndex
                                            ? formErrors.length
                                              ? "error"
                                              : "  "
                                            : "  "
                                        }
                                      />
                                      {variant.length - 1 === nameIndex
                                        ? formErrors.length && (
                                            <span className="error-message">
                                              {formErrors.length}
                                            </span>
                                          )
                                        : ""}
                                    </FormControl>
                                    <FormControl
                                      variant="standard"
                                      key="height"
                                    >
                                      <CustomLabel title="" />
                                      <BootstrapInput
                                        placeholder={t("height")}
                                        type="text"
                                        onChange={e =>
                                          handleVariantChangeHeight(
                                            nameIndex,
                                            e
                                          )
                                        }
                                        value={item.height}
                                        className={
                                          variant.length - 1 === nameIndex
                                            ? formErrors.height
                                              ? "error"
                                              : "  "
                                            : "  "
                                        }
                                      />
                                      {variant.length - 1 === nameIndex
                                        ? formErrors.height && (
                                            <span className="error-message">
                                              {formErrors.height}
                                            </span>
                                          )
                                        : ""}
                                    </FormControl>
                                    <FormControl
                                      variant="standard"
                                      key="weight"
                                    >
                                      <CustomLabel
                                        title={t("weightGr")}
                                        isRequired
                                      />
                                      <BootstrapInput
                                        type="text"
                                        placeholder={t("weight")}
                                        onChange={e =>
                                          handleVariantChangeWeight(
                                            nameIndex,
                                            e
                                          )
                                        }
                                        value={item.weight}
                                        className={
                                          variant.length - 1 === nameIndex
                                            ? formErrors.weight
                                              ? "error"
                                              : "  "
                                            : "  "
                                        }
                                      />
                                      {variant.length - 1 === nameIndex
                                        ? formErrors.weight && (
                                            <span className="error-message">
                                              {formErrors.weight}
                                            </span>
                                          )
                                        : ""}
                                    </FormControl>
                                  </Box>
                                  {/* Curreny and Price */}
                                  <Grid container spacing={1} sx={{ mt: 1 }}>
                                    <Grid item xs={4}>
                                      <FormControl
                                        sx={{ flex: 1 }}
                                        size="small"
                                        className="filter-select"
                                      >
                                        <Typography
                                          sx={{
                                            fontSize: "13px",
                                            fontWeight: "500",
                                            color: theme.palette.text.main,
                                            mt: "6px",
                                            mb: "-2px",
                                          }}
                                        >
                                          {t("productUnitCost")}{" "}
                                          <span
                                            style={{
                                              color: "rgb(215, 40, 62)",
                                            }}
                                          >
                                            *
                                          </span>
                                        </Typography>
                                        <Select
                                          labelId="demo-multiple-name-label"
                                          id="currencyId"
                                          value={
                                            item.warehouseList[0].currencyId
                                          }
                                          input={
                                            <BootstrapInput label="Currency" />
                                          }
                                          onChange={e =>
                                            handleVariantChangeCurrency(
                                              nameIndex,
                                              e
                                            )
                                          }
                                          className={
                                            variant.length - 1 === nameIndex
                                              ? formErrors.currency
                                                ? "error"
                                                : "  "
                                              : "  "
                                          }
                                          size="small"
                                          displayEmpty
                                          MenuProps={MenuProps}
                                          renderValue={() => (
                                            <div>
                                              {item.warehouseList[0]
                                                .currencyId === "" ? (
                                                <em
                                                  style={{
                                                    color: "currentColor",
                                                    opacity: "0.42",
                                                  }}
                                                >
                                                  {t("select")}
                                                </em>
                                              ) : (
                                                currencies.find(
                                                  option =>
                                                    option.id ===
                                                    item.warehouseList[0]
                                                      .currencyId
                                                ).name
                                              )}
                                            </div>
                                          )}
                                        >
                                          {currencies.map(opt => (
                                            <MenuItem
                                              key={opt.id}
                                              value={opt.id}
                                            >
                                              {opt.name}
                                            </MenuItem>
                                          ))}
                                        </Select>
                                        {variant.length - 1 === nameIndex
                                          ? formErrors.currency && (
                                              <span className="error-message">
                                                {formErrors.currency}
                                              </span>
                                            )
                                          : ""}
                                      </FormControl>
                                    </Grid>
                                    <Grid item xs={6}>
                                      <FormControl
                                        sx={{
                                          flex: 1,
                                          width: "100%!important ",
                                        }}
                                        variant="standard"
                                        key="productUnitPrice"
                                      >
                                        <CustomLabel title="" />
                                        <BootstrapInput
                                          // className="signup-fc"
                                          placeholder={
                                            item.warehouseList[0].currencyId ===
                                            ""
                                              ? ""
                                              : currencies.find(
                                                  option =>
                                                    option.id ===
                                                    item.warehouseList[0]
                                                      .currencyId
                                                ).symbol
                                          }
                                          value={item.warehouseList[0].price}
                                          onChange={e =>
                                            handleVariantChangePrice(
                                              nameIndex,
                                              e
                                            )
                                          }
                                          className={
                                            variant.length - 1 === nameIndex
                                              ? formErrors.productUnitPrice
                                                ? "error"
                                                : "  "
                                              : "  "
                                          }
                                        />
                                        {variant.length - 1 === nameIndex
                                          ? formErrors.productUnitPrice && (
                                              <span className="error-message">
                                                {formErrors.productUnitPrice}
                                              </span>
                                            )
                                          : ""}
                                      </FormControl>
                                    </Grid>
                                  </Grid>
                                </FormGroup>
                              </AccordionDetails>
                              {nameIndex !== 0 && (
                                <Box sx={{ position: "relative" }}>
                                  <Button
                                    type="button"
                                    variant="outlined"
                                    sx={{
                                      textTransform: "capitalize",
                                      width: "100%",
                                      color: "#D7283A",
                                      borderColor: "#D7283A",
                                      borderRadius: "0px 0px 2px 2px",
                                    }}
                                    onClick={() => removeVariant(nameIndex)}
                                    startIcon={<DeleteIcon />}
                                  >
                                    {t("deleteVariant")}
                                  </Button>
                                </Box>
                              )}
                            </Accordion>
                          </Box>
                        ))}
                        <Box sx={{ paddingLeft: 0.1, paddingRight: 0.1 }}>
                          <Tooltip
                            title={
                              t("fillInAllRequiredFieldsToAddANewVariant")
                            }
                          >
                            <span>
                              <Button
                                type="button"
                                variant="outlined"
                                color="secondary"
                                sx={{
                                  textTransform: "capitalize",
                                  width: "100%",
                                }}
                                component="div"
                                placement="bottom"
                                onClick={addVariant}
                                startIcon={<AddCircle />}
                                disabled={
                                  variant.length > 1
                                    ? variant[variant.length - 1].barcode ===
                                        "" ||
                                      variant[variant.length - 1].mpn === "" ||
                                      variant[variant.length - 1].title ===
                                        "" ||
                                      variant[variant.length - 1].width === 0 ||
                                      variant[variant.length - 1].width ===
                                        null ||
                                      variant[variant.length - 1].width ===
                                        undefined ||
                                      variant[variant.length - 1].width ===
                                        "" ||
                                      variant[variant.length - 1].length ===
                                        0 ||
                                      variant[variant.length - 1].length ===
                                        null ||
                                      variant[variant.length - 1].length ===
                                        undefined ||
                                      variant[variant.length - 1].length ===
                                        "" ||
                                      variant[variant.length - 1].height ===
                                        0 ||
                                      variant[variant.length - 1].height ===
                                        null ||
                                      variant[variant.length - 1].height ===
                                        undefined ||
                                      variant[variant.length - 1].height ===
                                        "" ||
                                      variant[variant.length - 1].weight ===
                                        0 ||
                                      variant[variant.length - 1].weight ===
                                        null ||
                                      variant[variant.length - 1].weight ===
                                        undefined ||
                                      variant[variant.length - 1].weight ===
                                        "" ||
                                      variant[variant.length - 1]
                                        .warehouseList[0].price === 0 ||
                                      variant[variant.length - 1]
                                        .warehouseList[0].price === null ||
                                      variant[variant.length - 1]
                                        .warehouseList[0].price === undefined ||
                                      variant[variant.length - 1]
                                        .warehouseList[0].currencyId === ""
                                      ? true
                                      : false
                                    : variant[0].barcode === "" ||
                                      variant[0].mpn === "" ||
                                      variant[0].title === "" ||
                                      variant[0].width === 0 ||
                                      variant[0].width === null ||
                                      variant[0].width === undefined ||
                                      variant[0].width === "" ||
                                      variant[0].length === 0 ||
                                      variant[0].length === null ||
                                      variant[0].length === undefined ||
                                      variant[0].length === "" ||
                                      variant[0].height === 0 ||
                                      variant[0].height === null ||
                                      variant[0].height === undefined ||
                                      variant[0].height === "" ||
                                      variant[0].weight === 0 ||
                                      variant[0].weight === null ||
                                      variant[0].weight === undefined ||
                                      variant[0].weight === "" ||
                                      variant[0].warehouseList[0].price === 0 ||
                                      variant[0].warehouseList[0].price ===
                                        null ||
                                      variant[0].warehouseList[0].price ===
                                        undefined ||
                                      variant[0].warehouseList[0].currencyId ===
                                        ""
                                    ? true
                                    : false
                                }
                              >
                                {t("addAnotherVariant")}
                              </Button>
                            </span>
                          </Tooltip>
                        </Box>
                      </Box>
                    ) : (
                      <> </>
                    )}
                  </Box>
                  <Box>
                    <Box
                      sx={{
                        backgroundColor: "#fff",
                        p: 2,
                        "& > :not(style)": { m: 1 },
                      }}
                      noValidate
                      autoComplete="off"
                    >
                      {haveVariant === true ? (
                        <></>
                      ) : (
                        <>
                          <FormGroup>
                            <BoxTitle title={t("productInformation")} />
                            {/* SKU */}
                            <FormControl variant="standard" key={"sku"}>
                              <CustomLabel title={t("modelCode")} isRequired />
                              <BootstrapInput
                                placeholder="Ör: 123456789"
                                required
                                value={sku}
                                onChange={skuHandler}
                                className={formErrors.sku ? "error" : "  "}
                              />
                              {formErrors.sku && (
                                <span className="error-message">
                                  {formErrors.sku}
                                </span>
                              )}
                            </FormControl>
                            {/* Barcode */}
                            <FormControl variant="standard" key={"barcode"}>
                              <CustomLabel title={t("barcode")} isRequired />
                              <BootstrapInput
                                placeholder="Ör: 123456789"
                                onChange={barcodeHandler}
                                value={barcode}
                                className={formErrors.barcode ? "error" : "  "}
                              />
                              {formErrors.barcode && (
                                <span className="error-message">
                                  {formErrors.barcode}
                                </span>
                              )}
                            </FormControl>
                            {/* Ürün Adı */}
                            <FormControl variant="standard" key="productName">
                              <CustomLabel
                                title={t("productName")}
                                isRequired
                              />
                              <BootstrapInput
                                placeholder={t("exRedPen")}
                                onChange={productNameHandler}
                                value={productName}
                                className={
                                  formErrors.productName ? "error" : "  "
                                }
                              />
                              {formErrors.productName && (
                                <span className="error-message">
                                  {formErrors.productName}
                                </span>
                              )}
                            </FormControl>
                            {/* Ürün Açıklaması */}
                            <FormControl
                              variant="standard"
                              key="productDescription"
                            >
                              <CustomLabel title={t("productDescription")} />
                              <BootstrapInput
                                placeholder="Ör: Kırmızı renkli uzun kurşun kalem "
                                onChange={productDescriptionHandler}
                                value={productDescription}
                                multiline
                              />
                            </FormControl>
                            {/* Kategori */}
                            <FormControl className="signup-fc">
                              <Typography
                                sx={{
                                  fontSize: "13px",
                                  fontWeight: "500",
                                  color: theme.palette.text.main,
                                  mt: "4px",
                                }}
                              >
                                {t("theCategory")}
                                <span
                                  style={{
                                    color: "rgb(215, 40, 62)",
                                    marginLeft: "0.5rem",
                                  }}
                                >
                                  *
                                </span>
                              </Typography>
                              <Select
                                labelId="demo-multiple-checkbox-label"
                                id="demo-multiple-checkbox"
                                multiple
                                required
                                displayEmpty
                                value={category}
                                input={<BootstrapSelect />}
                                onChange={handleCategoryChange}
                                renderValue={selected => (
                                  <div>
                                    {selected.length === 0 ? (
                                      <em
                                        style={{
                                          color: "currentColor",
                                          opacity: "0.42",
                                        }}
                                      >
                                        {t("selectCategory")}
                                      </em>
                                    ) : (
                                      selected.map((id, index) => {
                                        const selectedItem = options.find(
                                          item => item.id === id
                                        );
                                        return (
                                          <span key={selectedItem.id}>
                                            {selectedItem.title}
                                            {index !== selected.length - 1
                                              ? ", "
                                              : ""}
                                          </span>
                                        );
                                      })
                                    )}
                                  </div>
                                )}
                              >
                                {options
                                  .sort((a, b) => {
                                    const titleA = a.title.toLowerCase();
                                    const titleB = b.title.toLowerCase();
                                    return titleA.localeCompare(titleB);
                                  })
                                  .map(opt => (
                                    <MenuItem
                                      key={opt.value}
                                      value={opt.value}
                                      sx={{
                                        paddignTop: "0px!important",
                                        paddingBottom: "0px!important",
                                      }}
                                    >
                                      <Checkbox
                                        size="small"
                                        checked={category.includes(opt.value)}
                                      />
                                      <ListItemText primary={opt.title} />
                                    </MenuItem>
                                  ))}
                              </Select>
                              {formErrors.category && (
                                <span className="error-message">
                                  {formErrors.category}
                                </span>
                              )}
                            </FormControl>
                            {/* Marka */}
                            <FormControl variant="standard" key="brand">
                              <CustomLabel title={t("brand")} isRequired />
                              <BootstrapInput
                                placeholder="Ör: Kalem Dünyası"
                                onChange={brandHandler}
                                value={brand}
                                className={formErrors.brand ? "error" : "  "}
                              />
                              {formErrors.brand && (
                                <span className="error-message">
                                  {formErrors.brand}
                                </span>
                              )}
                            </FormControl>
                            {/* Gtip */}
                            <FormControl variant="standard" key="gtip">
                              <CustomLabel title={t("gtipCode")} isRequired />
                              <BootstrapInput
                                placeholder="Ör: 1234.56.78.19.20"
                                onChange={gtipHandler}
                                value={gtip}
                                className={formErrors.gtip ? "error" : "  "}
                              />
                              {formErrors.gtip && (
                                <span className="error-message">
                                  {formErrors.gtip}
                                </span>
                              )}
                            </FormControl>
                            {/* Ürün Linki */}
                            <FormControl variant="standard" key="productLink">
                              <CustomLabel title={t("productLink")} />
                              <BootstrapInput
                                placeholder="https://www.kirmizikursunkalem.com"
                                onChange={productLinkHandler}
                                value={productLink}
                                // className={
                                //   formErrors.productLink ? "error" : "  "
                                // }
                              />
                              {/* {formErrors.productLink && (
                                <span className="error-message">
                                  {formErrors.productLink}
                                </span>
                              )} */}
                            </FormControl>
                            {/* Curreny and Price */}
                            <Grid container spacing={1}>
                              <Grid item xs={4}>
                                <FormControl
                                  sx={{ flex: 1 }}
                                  size="small"
                                  className="filter-select"
                                >
                                  <Typography
                                    sx={{
                                      fontSize: "13px",
                                      fontWeight: "500",
                                      color: theme.palette.text.main,
                                      mt: "6px",
                                      mb: "-2px",
                                    }}
                                  >
                                    {t("productUnitCost")}
                                    <span
                                      style={{
                                        color: "rgb(215, 40, 62)",
                                      }}
                                    >
                                      *
                                    </span>
                                  </Typography>
                                  <Select
                                    labelId="demo-multiple-name-label"
                                    id="currencyId"
                                    value={currency}
                                    input={<BootstrapInput label="Currency" />}
                                    onChange={e => setCurrency(e.target.value)}
                                    size="small"
                                    displayEmpty
                                    MenuProps={MenuProps}
                                    renderValue={() => (
                                      <div>
                                        {currency === "" ? (
                                          <em
                                            style={{
                                              color: "currentColor",
                                              opacity: "0.42",
                                            }}
                                          >
                                            {t("select")}
                                          </em>
                                        ) : (
                                          currencies.find(
                                            option => option.id === currency
                                          ).name
                                        )}
                                      </div>
                                    )}
                                  >
                                    {currencies.map(opt => (
                                      <MenuItem key={opt.id} value={opt.id}>
                                        {opt.name}
                                      </MenuItem>
                                    ))}
                                  </Select>
                                  {formErrors.currency && (
                                    <span className="error-message">
                                      {formErrors.currency}
                                    </span>
                                  )}
                                </FormControl>
                              </Grid>
                              <Grid item xs={6}>
                                <FormControl
                                  sx={{ flex: 1, width: "100%!important " }}
                                  variant="standard"
                                  key="productUnitPrice"
                                >
                                  <CustomLabel title="" />
                                  <BootstrapInput
                                    classname={
                                      formErrors.productUnitPrice
                                        ? "error"
                                        : "  "
                                    }
                                    placeholder={selectedCurrency}
                                    value={productUnitPrice}
                                    onChange={productUnitPriceHandler}
                                  />
                                  {formErrors.productUnitPrice && (
                                    <span className="error-message">
                                      {formErrors.productUnitPrice}
                                    </span>
                                  )}
                                </FormControl>
                              </Grid>
                            </Grid>
                            {/* Boyut ve Agirlik */}
                            <Box
                              sx={{
                                display: "flex",
                                flexDirection: "row",
                                gap: "10px",
                              }}
                            >
                              <FormControl variant="standard" key="dimensions">
                                <CustomLabel
                                  title={t("dimensions")}
                                  isRequired
                                />
                                <BootstrapInput
                                  type={"number"}
                                  placeholder={t("width")}
                                  onChange={widthHandler}
                                  value={width}
                                  classname={formErrors.width ? "error" : "  "}
                                />
                                {formErrors.width && (
                                  <span className="error-message">
                                    {formErrors.width}
                                  </span>
                                )}
                              </FormControl>
                              <FormControl variant="standard" key="length">
                                <CustomLabel title="" />
                                <BootstrapInput
                                  type={"number"}
                                  placeholder={"Boy"}
                                  onChange={lengthHandler}
                                  value={length}
                                  classname={formErrors.length ? "error" : "  "}
                                />
                                {formErrors.length && (
                                  <span className="error-message">
                                    {formErrors.length}
                                  </span>
                                )}
                              </FormControl>
                              <FormControl variant="standard" key="height">
                                <CustomLabel title="" />
                                <BootstrapInput
                                  type={"number"}
                                  placeholder={t("height")}
                                  onChange={heightHandler}
                                  value={height}
                                  classname={formErrors.height ? "error" : "  "}
                                />
                                {formErrors.height && (
                                  <span className="error-message">
                                    {formErrors.height}
                                  </span>
                                )}
                              </FormControl>
                              <FormControl variant="standard" key="weight">
                                <CustomLabel title={t("weightGr")} isRequired />
                                <BootstrapInput
                                  type={"number"}
                                  placeholder={t("weight")}
                                  onChange={weightHandler}
                                  value={weight}
                                  classname={formErrors.weight ? "error" : "  "}
                                />
                                {formErrors.weight && (
                                  <span className="error-message">
                                    {formErrors.weight}
                                  </span>
                                )}
                              </FormControl>
                            </Box>
                          </FormGroup>
                        </>
                      )}
                    </Box>
                  </Box>
                </Box>
              )}
              {activeStep === 2 && (
                <Box
                  sx={{
                    backgroundColor: "#fff",
                    p: 2,
                    "& > :not(style)": { m: 1 },
                  }}
                  noValidate
                  autoComplete="off"
                >
                  <FormGroup>
                    <BoxTitle title={t("productProperties")} />
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "row",
                        gap: "10px",
                        flexWrap: "wrap",
                      }}
                    >
                      <Box sx={{ display: "flex", flexDirection: "row" }}>
                        <FormControl variant="standard" key="isFragile">
                          <CustomLabel title={t("fragileProduct")} isRequired />
                          <RadioGroup
                            sx={{ pt: "35px", ml: "10px" }}
                            row
                            aria-labelledby="demo-row-radio-buttons-group-label"
                            name="row-radio-buttons-group"
                            value={isFragile}
                            onChange={isFragileHandler}
                          >
                            <FormControlLabel
                              color="success"
                              className={
                                isFragile === true
                                  ? "custom-radio-checked"
                                  : "custom-radio"
                              }
                              value={true}
                              control={
                                <Radio
                                  sx={{
                                    color: "#45383426",
                                    "&.Mui-checked": {
                                      color: "#34AE1A",
                                    },
                                  }}
                                />
                              }
                              label={
                                <Typography
                                  fontWeight="500"
                                  color={
                                    isFragile === true
                                      ? theme.palette.success.main
                                      : "#453834"
                                  }
                                  fontSize="14px"
                                >
                                  {t("yes")}
                                </Typography>
                              }
                            />
                            <FormControlLabel
                              color="success"
                              className={
                                isFragile === false
                                  ? "custom-radio-checked"
                                  : "custom-radio"
                              }
                              value={false}
                              control={
                                <Radio
                                  sx={{
                                    color: "#45383426",
                                    "&.Mui-checked": {
                                      color: "#34AE1A",
                                    },
                                  }}
                                />
                              }
                              label={
                                <Typography
                                  fontWeight="500"
                                  color={
                                    isFragile === false
                                      ? theme.palette.success.main
                                      : "#453834"
                                  }
                                  fontSize="14px"
                                >
                                  {t("no")}
                                </Typography>
                              }
                            />
                          </RadioGroup>
                        </FormControl>
                        <FormControl variant="standard" key="isLiquid">
                          <CustomLabel title={t("liquidProduct")} isRequired />
                          <RadioGroup
                            sx={{ pt: "35px", ml: "10px" }}
                            row
                            aria-labelledby="demo-row-radio-buttons-group-label"
                            name="row-radio-buttons-group"
                            value={isLiquid}
                            onChange={isLiquidHandler}
                          >
                            <FormControlLabel
                              color="success"
                              className={
                                isLiquid === true
                                  ? "custom-radio-checked"
                                  : "custom-radio"
                              }
                              value={true}
                              control={
                                <Radio
                                  sx={{
                                    color: "#45383426",
                                    "&.Mui-checked": {
                                      color: "#34AE1A",
                                    },
                                  }}
                                />
                              }
                              label={
                                <Typography
                                  fontWeight="500"
                                  color={
                                    isLiquid === true
                                      ? theme.palette.success.main
                                      : "#453834"
                                  }
                                  fontSize="14px"
                                >
                                  {t("yes")}
                                </Typography>
                              }
                            />
                            <FormControlLabel
                              color="success"
                              className={
                                isLiquid === false
                                  ? "custom-radio-checked"
                                  : "custom-radio"
                              }
                              value={false}
                              control={
                                <Radio
                                  sx={{
                                    color: "#45383426",
                                    "&.Mui-checked": {
                                      color: "#34AE1A",
                                    },
                                  }}
                                />
                              }
                              label={
                                <Typography
                                  fontWeight="500"
                                  color={
                                    isLiquid === false
                                      ? theme.palette.success.main
                                      : "#453834"
                                  }
                                  fontSize="14px"
                                >
                                  {t("no")}
                                </Typography>
                              }
                            />
                          </RadioGroup>
                        </FormControl>
                      </Box>
                      <Box sx={{ display: "flex", flexDirection: "row" }}>
                        <FormControl variant="standard" key="isExpirationDate">
                          <CustomLabel
                            title={t("doesIthaveAnExpirationDate")}
                            isRequired
                          />
                          <RadioGroup
                            sx={{ pt: "35px", ml: "10px" }}
                            row
                            aria-labelledby="demo-row-radio-buttons-group-label"
                            name="row-radio-buttons-group"
                            value={isExpirationDate}
                            onChange={isExpirationDateHandler}
                          >
                            <FormControlLabel
                              color="success"
                              className={
                                isExpirationDate === true
                                  ? "custom-radio-checked"
                                  : "custom-radio"
                              }
                              value={true}
                              control={
                                <Radio
                                  sx={{
                                    color: "#45383426",
                                    "&.Mui-checked": {
                                      color: "#34AE1A",
                                    },
                                  }}
                                />
                              }
                              label={
                                <Typography
                                  fontWeight="500"
                                  color={
                                    isExpirationDate === true
                                      ? theme.palette.success.main
                                      : "#453834"
                                  }
                                  fontSize="14px"
                                >
                                  {t("yes")}
                                </Typography>
                              }
                            />
                            <FormControlLabel
                              color="success"
                              className={
                                isExpirationDate === false
                                  ? "custom-radio-checked"
                                  : "custom-radio"
                              }
                              value={false}
                              control={
                                <Radio
                                  sx={{
                                    color: "#45383426",
                                    "&.Mui-checked": {
                                      color: "#34AE1A",
                                    },
                                  }}
                                />
                              }
                              label={
                                <Typography
                                  fontWeight="500"
                                  color={
                                    isExpirationDate === false
                                      ? theme.palette.success.main
                                      : "#453834"
                                  }
                                  fontSize="14px"
                                >
                                  {t("no")}
                                </Typography>
                              }
                            />
                          </RadioGroup>
                        </FormControl>
                        <FormControl
                          variant="standard"
                          key="isFlammableOrExplosive"
                        >
                          <CustomLabel
                            title={t("flammableOrExplosiveProduct")}
                            isRequired
                          />
                          <RadioGroup
                            sx={{ pt: "35px", ml: "10px" }}
                            row
                            aria-labelledby="demo-row-radio-buttons-group-label"
                            name="row-radio-buttons-group"
                            value={isFlammableOrExplosive}
                            onChange={isFlammableOrExplosiveHandler}
                          >
                            <FormControlLabel
                              color="success"
                              className={
                                isFlammableOrExplosive === true
                                  ? "custom-radio-checked"
                                  : "custom-radio"
                              }
                              value={true}
                              control={
                                <Radio
                                  sx={{
                                    color: "#45383426",
                                    "&.Mui-checked": {
                                      color: "#34AE1A",
                                    },
                                  }}
                                />
                              }
                              label={
                                <Typography
                                  fontWeight="500"
                                  color={
                                    isFlammableOrExplosive === true
                                      ? theme.palette.success.main
                                      : "#453834"
                                  }
                                  fontSize="14px"
                                >
                                  {t("yes")}
                                </Typography>
                              }
                            />
                            <FormControlLabel
                              color="success"
                              className={
                                isFlammableOrExplosive === false
                                  ? "custom-radio-checked"
                                  : "custom-radio"
                              }
                              value={false}
                              control={
                                <Radio
                                  sx={{
                                    color: "#45383426",
                                    "&.Mui-checked": {
                                      color: "#34AE1A",
                                    },
                                  }}
                                />
                              }
                              label={
                                <Typography
                                  fontWeight="500"
                                  color={
                                    isFlammableOrExplosive === false
                                      ? theme.palette.success.main
                                      : "#453834"
                                  }
                                  fontSize="14px"
                                >
                                  {t("no")}
                                </Typography>
                              }
                            />
                          </RadioGroup>
                        </FormControl>
                      </Box>
                    </Box>
                  </FormGroup>
                  <FormGroup>
                    <BoxTitle title={t("warehouse")} isDivider={false} />
                    <Typography
                      sx={{
                        fontSize: "14px",
                        color: theme.palette.text.main,
                        mt: 1,
                      }}
                    >
                      {t("pleaseSelectTheWarehouseInWhichCountryYourProductWillBeDelivered")}
                    </Typography>
                    <Divider sx={{ mt: 1 }} />
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "row",
                        gap: "10px",
                        flexWrap: "wrap",
                      }}
                    >
                      <FormControl>
                        <Typography
                          sx={{
                            fontSize: "13px",
                            fontWeight: "500",
                            color: theme.palette.text.main,
                            mt: "6px",
                            mb: "-2px",
                          }}
                        >
                          {t("warehouse")}
                          <span
                            style={{
                              color: "rgb(215, 40, 62)",
                            }}
                          >
                            *
                          </span>
                        </Typography>
                        <Select
                          labelId="demo-multiple-name-label"
                          id="warehouseId"
                          value={productWarehouse}
                          input={<BootstrapInput label="Warehouse" />}
                          onChange={warehouseHandler}
                          size="small"
                          sx={{ width: "100%!important" }}
                          displayEmpty
                          MenuProps={MenuProps}
                          renderValue={() => (
                            <div>
                              {productWarehouse === "" ? (
                                <em
                                  style={{
                                    color: "currentColor",
                                    opacity: "0.42",
                                  }}
                                >
                                  {t("select")}
                                </em>
                              ) : (
                                warehouses.find(
                                  option => option.id === productWarehouse
                                ).title
                              )}
                            </div>
                          )}
                        >
                          {warehouses.map(opt => (
                            <MenuItem key={opt.id} value={opt.id}>
                              {opt.title}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </Box>
                  </FormGroup>
                </Box>
              )}
              {activeStep === 3 &&
                (haveVariant === true ? (
                  <Box>
                    <Box
                      sx={{
                        backgroundColor: "#fff",
                        p: 2,
                        pb: 0,
                        "& > :not(style)": { m: 1 },
                      }}
                    >
                      <FormGroup className="productPreviewGroup">
                        <BoxTitle title="Ürün Grubu Bilgileri" />
                        <FormControl variant="standard" key={"sku"}>
                          {/* SKU */}
                          <Grid
                            container
                            spacing={2}
                            sx={{
                              mt: 0.3,
                              mb: 1,
                              lineBreak: "anywhere!important",
                            }}
                          >
                            <Grid item xs={6}>
                              <Typography sx={{ fontSize: "14px" }}>
                                {t("modelCode")}
                              </Typography>
                            </Grid>
                            <Grid item xs={6}>
                              <Typography
                                sx={{ fontWeight: "500", fontSize: "14px" }}
                              >
                                {sku}
                              </Typography>
                            </Grid>
                          </Grid>
                          <Divider />
                          {/* Ürün Adı */}
                          <Grid
                            container
                            spacing={2}
                            sx={{
                              mt: "4px",
                              mb: 1,
                              pt: 0,
                              lineBreak: "anywhere!important",
                            }}
                          >
                            <Grid className="productPreviewGrid" item xs={6}>
                              <Typography sx={{ fontSize: "14px" }}>
                                {t("productName")}
                              </Typography>
                            </Grid>
                            <Grid className="productPreviewGrid" item xs={6}>
                              <Typography
                                sx={{ fontWeight: "500", fontSize: "14px" }}
                              >
                                {productName}
                              </Typography>
                            </Grid>
                          </Grid>
                          <Divider />
                          {/* Kategori */}
                          <Grid
                            container
                            spacing={2}
                            sx={{
                              mt: "4px",
                              mb: 1,
                              pt: 0,
                              lineBreak: "anywhere!important",
                            }}
                          >
                            <Grid className="productPreviewGrid" item xs={6}>
                              <Typography sx={{ fontSize: "14px" }}>
                                {t("category")}
                              </Typography>
                            </Grid>
                            <Grid className="productPreviewGrid" item xs={6}>
                              <Typography
                                sx={{ fontWeight: "500", fontSize: "14px" }}
                              >
                                {selectedCategoryTitles}
                              </Typography>
                            </Grid>
                          </Grid>
                          <Divider />
                          {/* Marka */}
                          <Grid
                            container
                            spacing={2}
                            sx={{
                              mt: "4px",
                              mb: 1,
                              pt: 0,
                              lineBreak: "anywhere!important",
                            }}
                          >
                            <Grid className="productPreviewGrid" item xs={6}>
                              <Typography sx={{ fontSize: "14px" }}>
                                {t("brand")}
                              </Typography>
                            </Grid>
                            <Grid className="productPreviewGrid" item xs={6}>
                              <Typography
                                sx={{ fontWeight: "500", fontSize: "14px" }}
                              >
                                {brand}
                              </Typography>
                            </Grid>
                          </Grid>
                          <Divider />
                          {/* Gtip */}
                          <Grid
                            container
                            spacing={2}
                            sx={{
                              mt: "4px",
                              mb: 1,
                              pt: 0,
                              lineBreak: "anywhere!important",
                            }}
                          >
                            <Grid className="productPreviewGrid" item xs={6}>
                              <Typography sx={{ fontSize: "14px" }}>
                                {t("gtipCode")}
                              </Typography>
                            </Grid>
                            <Grid className="productPreviewGrid" item xs={6}>
                              <Typography
                                sx={{ fontWeight: "500", fontSize: "14px" }}
                              >
                                {gtip}
                              </Typography>
                            </Grid>
                          </Grid>
                        </FormControl>
                      </FormGroup>
                    </Box>
                    <Box
                      sx={{
                        backgroundColor: "#fff",
                        p: 2,
                        pb: 0,
                        "& > :not(style)": { m: 1 },
                      }}
                    >
                      {variant.map((item, nameIndex) => (
                        <Box className="dynamicVariant" key={nameIndex}>
                          <Accordion
                            expanded={expanded === `panel${nameIndex}`}
                            onChange={handleChangeAccordion(
                              `panel${nameIndex}`
                            )}
                            sx={{
                              boxShadow: "none",
                              border: "1px solid rgba(85, 56, 48, 0.15)",
                              borderRadius: "2px",
                            }}
                          >
                            <AccordionSummary
                              expandIcon={<ExpandMoreIcon />}
                              aria-controls={`panel${nameIndex}bh-content`}
                              id={`panel${nameIndex}bh-header`}
                              sx={{
                                minHeight: "36px!important",
                                maxHeight: "36px!important",
                                height: "36px!important",
                                mt: 0.5,
                                mb: 0.5,
                              }}
                            >
                              <Typography
                                sx={{
                                  width: "100%",
                                  flexShrink: 0,
                                  fontSize: "16px",
                                  color: theme.palette.primary.main,
                                  fontWeight: "500",
                                  lineBreak: "anywhere!important",
                                }}
                              >
                                {nameIndex + 1}. {t("variant")} ({item.mpn})
                              </Typography>
                            </AccordionSummary>
                            <AccordionDetails
                              sx={{
                                width: "100%!important",
                                padding: "0px!important",
                                borderTop: "1px solid rgba(85, 56, 48, 0.15)",
                              }}
                            >
                              <FormGroup className="border-none mt-0 pt-0">
                                {/* Varyant MPN */}
                                <FormControl
                                  sx={{ mt: 1 }}
                                  variant="standard"
                                  key="mpn"
                                >
                                  <Grid
                                    container
                                    spacing={2}
                                    sx={{
                                      mt: "4px",
                                      mb: 1,
                                      pt: 0,
                                      lineBreak: "anywhere!important",
                                    }}
                                  >
                                    <Grid
                                      className="productPreviewGrid"
                                      item
                                      xs={6}
                                    >
                                      <Typography sx={{ fontSize: "14px" }}>
                                        {t("modelCode")}
                                      </Typography>
                                    </Grid>
                                    <Grid
                                      className="productPreviewGrid"
                                      item
                                      xs={6}
                                    >
                                      <Typography
                                        sx={{
                                          fontWeight: "500",
                                          fontSize: "14px",
                                        }}
                                      >
                                        {item.mpn}
                                      </Typography>
                                    </Grid>
                                  </Grid>
                                  <Divider />
                                  <Grid
                                    container
                                    spacing={2}
                                    sx={{
                                      mt: "4px",
                                      mb: 1,
                                      pt: 0,
                                      lineBreak: "anywhere!important",
                                    }}
                                  >
                                    <Grid
                                      className="productPreviewGrid"
                                      item
                                      xs={6}
                                    >
                                      <Typography sx={{ fontSize: "14px" }}>
                                        {t("barcode")}
                                      </Typography>
                                    </Grid>
                                    <Grid
                                      className="productPreviewGrid"
                                      item
                                      xs={6}
                                    >
                                      <Typography
                                        sx={{
                                          fontWeight: "500",
                                          fontSize: "14px",
                                        }}
                                      >
                                        {item.barcode}
                                      </Typography>
                                    </Grid>
                                  </Grid>
                                  <Divider />
                                  <Grid
                                    container
                                    spacing={2}
                                    sx={{
                                      mt: "4px",
                                      mb: 1,
                                      pt: 0,
                                      lineBreak: "anywhere!important",
                                    }}
                                  >
                                    <Grid
                                      className="productPreviewGrid"
                                      item
                                      xs={6}
                                    >
                                      <Typography sx={{ fontSize: "14px" }}>
                                        {t("productName")}
                                      </Typography>
                                    </Grid>
                                    <Grid
                                      className="productPreviewGrid"
                                      item
                                      xs={6}
                                    >
                                      <Typography
                                        sx={{
                                          fontWeight: "500",
                                          fontSize: "14px",
                                        }}
                                      >
                                        {item.title}
                                      </Typography>
                                    </Grid>
                                  </Grid>
                                  <Divider />
                                  <Grid
                                    container
                                    spacing={2}
                                    sx={{
                                      mt: "4px",
                                      mb: 1,
                                      pt: 0,
                                      lineBreak: "anywhere!important",
                                    }}
                                  >
                                    <Grid
                                      className="productPreviewGrid"
                                      item
                                      xs={6}
                                    >
                                      <Typography sx={{ fontSize: "14px" }}>
                                        {t("productDescription")}
                                      </Typography>
                                    </Grid>
                                    <Grid
                                      className="productPreviewGrid"
                                      item
                                      xs={6}
                                    >
                                      <Typography
                                        sx={{
                                          fontWeight: "500",
                                          fontSize: "14px",
                                        }}
                                      >
                                        {item.description}
                                      </Typography>
                                    </Grid>
                                  </Grid>
                                  <Divider />
                                  <Grid
                                    container
                                    spacing={2}
                                    sx={{
                                      mt: "4px",
                                      mb: 1,
                                      pt: 0,
                                      lineBreak: "anywhere!important",
                                    }}
                                  >
                                    <Grid
                                      className="productPreviewGrid"
                                      item
                                      xs={6}
                                    >
                                      <Typography sx={{ fontSize: "14px" }}>
                                        {t("productLink")}
                                      </Typography>
                                    </Grid>
                                    <Grid
                                      className="productPreviewGrid"
                                      item
                                      xs={6}
                                    >
                                      <Typography
                                        sx={{
                                          fontWeight: "500",
                                          fontSize: "14px",
                                        }}
                                      >
                                        {item.link}
                                      </Typography>
                                    </Grid>
                                  </Grid>
                                  <Divider />
                                  <Grid
                                    container
                                    spacing={2}
                                    sx={{
                                      mt: "4px",
                                      mb: 1,
                                      pt: 0,
                                      lineBreak: "anywhere!important",
                                    }}
                                  >
                                    <Grid
                                      className="productPreviewGrid"
                                      item
                                      xs={6}
                                    >
                                      <Typography sx={{ fontSize: "14px" }}>
                                        {t("productUnitCost")}
                                      </Typography>
                                    </Grid>
                                    <Grid
                                      className="productPreviewGrid"
                                      item
                                      xs={6}
                                    >
                                      <Typography
                                        sx={{
                                          fontWeight: "500",
                                          fontSize: "14px",
                                        }}
                                      >
                                        {item.warehouseList[0].currencyId === ""
                                          ? ""
                                          : currencies.find(
                                              option =>
                                                option.id ===
                                                item.warehouseList[0].currencyId
                                            ).symbol}{" "}
                                        {item.warehouseList[0].price}
                                      </Typography>
                                    </Grid>
                                  </Grid>
                                  <Divider />
                                  <Grid
                                    container
                                    spacing={2}
                                    sx={{
                                      mt: "4px",
                                      mb: 1,
                                      pt: 0,
                                      lineBreak: "anywhere!important",
                                    }}
                                  >
                                    <Grid
                                      item
                                      xs={6}
                                      className="productPreviewGrid"
                                    >
                                      <Typography sx={{ fontSize: "14px" }}>
                                        {t("dimensions")}
                                      </Typography>
                                    </Grid>
                                    <Grid
                                      item
                                      xs={6}
                                      className="productPreviewGrid"
                                    >
                                      <Typography
                                        sx={{
                                          fontWeight: "500",
                                          fontSize: "14px",
                                        }}
                                      >
                                        {item.width} ({t("width")}) x {item.length} ({t("lenght")})
                                        x {item.height} ({t("height")})
                                      </Typography>
                                    </Grid>
                                  </Grid>
                                  <Divider />
                                  <Grid
                                    container
                                    spacing={2}
                                    sx={{
                                      mt: "4px",
                                      mb: 1,
                                      pt: 0,
                                      lineBreak: "anywhere!important",
                                    }}
                                  >
                                    <Grid
                                      item
                                      xs={6}
                                      className="productPreviewGrid"
                                    >
                                      <Typography sx={{ fontSize: "14px" }}>
                                        {t("weightOf")}
                                      </Typography>
                                    </Grid>
                                    <Grid
                                      item
                                      xs={6}
                                      className="productPreviewGrid"
                                    >
                                      <Typography
                                        sx={{
                                          fontWeight: "500",
                                          fontSize: "14px",
                                        }}
                                      >
                                        {item.weight} gr
                                      </Typography>
                                    </Grid>
                                  </Grid>
                                </FormControl>
                              </FormGroup>
                            </AccordionDetails>
                          </Accordion>
                        </Box>
                      ))}
                    </Box>
                    <Box
                      sx={{
                        backgroundColor: "#fff",
                        p: 2,
                        pb: 0,
                        "& > :not(style)": { m: 1 },
                      }}
                    >
                      {/* Urun Ozellıkleri */}
                      <FormGroup className="productPreviewGroup">
                        <BoxTitle title="Ürün Özellikleri" />
                        <FormControl variant="standard" key={"sku"}>
                          {/* Kirilgan ve Likit Ürünler */}
                          <Grid
                            container
                            spacing={2}
                            sx={{ mt: "4px", mb: 1, pt: 0 }}
                          >
                            <Grid
                              item
                              xs={6}
                              className="productPreviewGrid"
                              sx={{
                                borderRight: "1px solid rgba(85, 56, 48, 0.15)",
                              }}
                            >
                              <Grid container>
                                <Grid item xs={6}>
                                  <Typography sx={{ fontSize: "14px" }}>
                                    {t("isFragile")}:
                                  </Typography>
                                </Grid>
                                <Grid item xs={6}>
                                  <Typography
                                    sx={{ fontWeight: "500", fontSize: "14px" }}
                                  >
                                    {isFragile ? t("yes") : t("no")}
                                  </Typography>
                                </Grid>
                              </Grid>
                            </Grid>
                            <Grid item xs={6} className="productPreviewGrid">
                              <Grid container>
                                <Grid item xs={6}>
                                  <Typography sx={{ fontSize: "14px" }}>
                                    {t("isLiquid")}:
                                  </Typography>
                                </Grid>
                                <Grid item xs={6}>
                                  <Typography
                                    sx={{ fontWeight: "500", fontSize: "14px" }}
                                  >
                                    {isLiquid ? t("yes") : t("no")}
                                  </Typography>
                                </Grid>
                              </Grid>
                            </Grid>
                          </Grid>
                          <Divider />
                          <Grid
                            container
                            spacing={2}
                            sx={{ mt: "4px", mb: 1, pt: 0 }}
                          >
                            <Grid
                              item
                              xs={6}
                              className="productPreviewGrid"
                              sx={{
                                borderRight: "1px solid rgba(85, 56, 48, 0.15)",
                              }}
                            >
                              <Grid container>
                                <Grid item xs={6}>
                                  <Typography sx={{ fontSize: "14px" }}>
                                    {t("expirationDate")}:
                                  </Typography>
                                </Grid>
                                <Grid item xs={6}>
                                  <Typography
                                    sx={{ fontWeight: "500", fontSize: "14px" }}
                                  >
                                    {isExpirationDate ? t("yes") : t("no")}
                                  </Typography>
                                </Grid>
                              </Grid>
                            </Grid>
                            <Grid item xs={6} className="productPreviewGrid">
                              <Grid container>
                                <Grid item xs={6}>
                                  <Typography sx={{ fontSize: "14px" }}>
                                    {t("flammableOrExplosive")}:
                                  </Typography>
                                </Grid>
                                <Grid item xs={6}>
                                  <Typography
                                    sx={{ fontWeight: "500", fontSize: "14px" }}
                                  >
                                    {isFlammableOrExplosive ? t("yes") : t("no")}
                                  </Typography>
                                </Grid>
                              </Grid>
                            </Grid>
                          </Grid>
                        </FormControl>
                      </FormGroup>
                      {/* Depo */}
                      <FormGroup className="productPreviewGroup">
                        <BoxTitle title="TanÄ±mlÄ± Depolar" />
                        <FormControl variant="standard" key={"sku"}>
                          <Grid container spacing={2} sx={{ mt: 0.3, mb: 1 }}>
                            <Grid item className="productPreviewGrid" xs={6}>
                              <Typography sx={{ fontSize: "14px" }}>
                                {t("warehouse")}
                              </Typography>
                            </Grid>
                            <Grid item className="productPreviewGrid" xs={6}>
                              <Typography
                                sx={{ fontWeight: "500", fontSize: "14px" }}
                              >
                                {
                                  warehouses.find(
                                    option => option.id === productWarehouse
                                  ).title
                                }
                              </Typography>
                            </Grid>
                          </Grid>
                        </FormControl>
                      </FormGroup>
                    </Box>
                  </Box>
                ) : (
                  <Box
                    sx={{
                      backgroundColor: "#fff",
                      p: 2,
                      pb: 0,
                      "& > :not(style)": { m: 1 },
                    }}
                  >
                    <FormGroup className="productPreviewGroup">
                      <BoxTitle title={t("productInformation")} />
                      <FormControl variant="standard" key={"sku"}>
                        {/* SKU */}
                        <Grid
                          container
                          spacing={2}
                          sx={{
                            mt: 0.3,
                            mb: 1,
                            lineBreak: "anywhere!important",
                          }}
                        >
                          <Grid item xs={6}>
                            <Typography sx={{ fontSize: "14px" }}>
                              {t("modelCode")}
                            </Typography>
                          </Grid>
                          <Grid item xs={6}>
                            <Typography
                              sx={{ fontWeight: "500", fontSize: "14px" }}
                            >
                              {sku}
                            </Typography>
                          </Grid>
                        </Grid>
                        <Divider />
                        {/* Barkod */}
                        <Grid
                          container
                          spacing={2}
                          sx={{
                            mt: "4px",
                            mb: 1,
                            pt: 0,
                            lineBreak: "anywhere!important",
                          }}
                        >
                          <Grid className="productPreviewGrid" item xs={6}>
                            <Typography sx={{ fontSize: "14px" }}>
                              {t("barcode")}
                            </Typography>
                          </Grid>
                          <Grid className="productPreviewGrid" item xs={6}>
                            <Typography
                              sx={{ fontWeight: "500", fontSize: "14px" }}
                            >
                              {barcode}
                            </Typography>
                          </Grid>
                        </Grid>
                        <Divider />
                        {/* Ürün Adı */}
                        <Grid
                          container
                          spacing={2}
                          sx={{
                            mt: "4px",
                            mb: 1,
                            pt: 0,
                            lineBreak: "anywhere!important",
                          }}
                        >
                          <Grid className="productPreviewGrid" item xs={6}>
                            <Typography sx={{ fontSize: "14px" }}>
                              {t("productName")}
                            </Typography>
                          </Grid>
                          <Grid className="productPreviewGrid" item xs={6}>
                            <Typography
                              sx={{ fontWeight: "500", fontSize: "14px" }}
                            >
                              {productName}
                            </Typography>
                          </Grid>
                        </Grid>
                        <Divider />
                        {/* Ürün Açıklaması */}
                        <Grid
                          container
                          spacing={2}
                          sx={{
                            mt: "4px",
                            mb: 1,
                            pt: 0,
                            lineBreak: "anywhere!important",
                          }}
                        >
                          <Grid className="productPreviewGrid" item xs={6}>
                            <Typography sx={{ fontSize: "14px" }}>
                              {t("productDescription")}
                            </Typography>
                          </Grid>
                          <Grid className="productPreviewGrid" item xs={6}>
                            <Typography
                              sx={{ fontWeight: "500", fontSize: "14px" }}
                            >
                              {productDescription}
                            </Typography>
                          </Grid>
                        </Grid>
                        <Divider />
                        {/* Kategori */}
                        <Grid
                          container
                          spacing={2}
                          sx={{
                            mt: "4px",
                            mb: 1,
                            pt: 0,
                            lineBreak: "anywhere!important",
                          }}
                        >
                          <Grid className="productPreviewGrid" item xs={6}>
                            <Typography sx={{ fontSize: "14px" }}>
                              {t("category")}
                            </Typography>
                          </Grid>
                          <Grid className="productPreviewGrid" item xs={6}>
                            <Typography
                              sx={{ fontWeight: "500", fontSize: "14px" }}
                            >
                              {selectedCategoryTitles}
                            </Typography>
                          </Grid>
                        </Grid>
                        <Divider />
                        {/* Marka */}
                        <Grid
                          container
                          spacing={2}
                          sx={{
                            mt: "4px",
                            mb: 1,
                            pt: 0,
                            lineBreak: "anywhere!important",
                          }}
                        >
                          <Grid className="productPreviewGrid" item xs={6}>
                            <Typography sx={{ fontSize: "14px" }}>
                              {t("brand")}
                            </Typography>
                          </Grid>
                          <Grid className="productPreviewGrid" item xs={6}>
                            <Typography
                              sx={{ fontWeight: "500", fontSize: "14px" }}
                            >
                              {brand}
                            </Typography>
                          </Grid>
                        </Grid>
                        <Divider />
                        {/* Gtip */}
                        <Grid
                          container
                          spacing={2}
                          sx={{
                            mt: "4px",
                            mb: 1,
                            pt: 0,
                            lineBreak: "anywhere!important",
                          }}
                        >
                          <Grid className="productPreviewGrid" item xs={6}>
                            <Typography sx={{ fontSize: "14px" }}>
                              {t("gtipCode")}
                            </Typography>
                          </Grid>
                          <Grid className="productPreviewGrid" item xs={6}>
                            <Typography
                              sx={{ fontWeight: "500", fontSize: "14px" }}
                            >
                              {gtip}
                            </Typography>
                          </Grid>
                        </Grid>
                        <Divider />
                        {/* Ürün Linki */}
                        <Grid
                          container
                          spacing={2}
                          sx={{
                            mt: "4px",
                            mb: 1,
                            pt: 0,
                            lineBreak: "anywhere!important",
                          }}
                        >
                          <Grid className="productPreviewGrid" item xs={6}>
                            <Typography sx={{ fontSize: "14px" }}>
                              {t("productLink")}
                            </Typography>
                          </Grid>
                          <Grid className="productPreviewGrid" item xs={6}>
                            <Typography
                              sx={{ fontWeight: "500", fontSize: "14px" }}
                            >
                              {productLink}
                            </Typography>
                          </Grid>
                        </Grid>
                        <Divider />
                        {/* maliyet */}
                        <Grid
                          container
                          spacing={2}
                          sx={{
                            mt: "4px",
                            mb: 1,
                            pt: 0,
                            lineBreak: "anywhere!important",
                          }}
                        >
                          <Grid className="productPreviewGrid" item xs={6}>
                            <Typography sx={{ fontSize: "14px" }}>
                              {t("productUnitCost")}
                            </Typography>
                          </Grid>
                          <Grid className="productPreviewGrid" item xs={6}>
                            <Typography
                              sx={{ fontWeight: "500", fontSize: "14px" }}
                            >
                              {productUnitPrice} {selectedCurrency}
                            </Typography>
                          </Grid>
                        </Grid>
                        <Divider />
                        {/* Boyut ve Agirlik */}
                        <Grid
                          container
                          spacing={2}
                          sx={{
                            mt: "4px",
                            mb: 1,
                            pt: 0,
                            lineBreak: "anywhere!important",
                          }}
                        >
                          <Grid item className="productPreviewGrid" xs={6}>
                            <Typography sx={{ fontSize: "14px" }}>
                              {t("dimensions")} (cm)
                            </Typography>
                          </Grid>
                          <Grid item className="productPreviewGrid" xs={6}>
                            <Typography
                              sx={{ fontWeight: "500", fontSize: "14px" }}
                            >
                              {width} ({t("width")}) x {length} ({t("length")}) x {height} ({t("height")})
                            </Typography>
                          </Grid>
                        </Grid>
                        <Divider />
                        {/* Agirlik */}
                        <Grid
                          container
                          spacing={2}
                          sx={{
                            mt: "4px",
                            mb: 1,
                            pt: 0,
                            lineBreak: "anywhere!important",
                          }}
                        >
                          <Grid className="productPreviewGrid" item xs={6}>
                            <Typography sx={{ fontSize: "14px" }}>
                              {t("weightOf")}
                            </Typography>
                          </Grid>
                          <Grid className="productPreviewGrid" item xs={6}>
                            <Typography
                              sx={{ fontWeight: "500", fontSize: "14px" }}
                            >
                              {weight} gr
                            </Typography>
                          </Grid>
                        </Grid>
                      </FormControl>
                    </FormGroup>
                    {/* Urun Ozellıkleri */}
                    <FormGroup className="productPreviewGroup">
                      <BoxTitle title={t("productProperties")} />
                      <FormControl variant="standard" key={"sku"}>
                        {/* Kirilgan ve Likit Ürünler */}
                        <Grid
                          container
                          spacing={2}
                          sx={{
                            mt: "4px",
                            mb: 1,
                            pt: 0,
                            lineBreak: "anywhere!important",
                          }}
                        >
                          <Grid
                            item
                            xs={6}
                            className="productPreviewGrid"
                            sx={{
                              borderRight: "1px solid rgba(85, 56, 48, 0.15)",
                            }}
                          >
                            <Grid container>
                              <Grid item xs={6}>
                                <Typography sx={{ fontSize: "14px" }}>
                                  {t("isFragile")}:
                                </Typography>
                              </Grid>
                              <Grid item xs={6}>
                                <Typography
                                  sx={{ fontWeight: "500", fontSize: "14px" }}
                                >
                                  {isFragile ? t("yes") : t("no")}
                                </Typography>
                              </Grid>
                            </Grid>
                          </Grid>
                          <Grid item xs={6} className="productPreviewGrid">
                            <Grid container>
                              <Grid item xs={6}>
                                <Typography sx={{ fontSize: "14px" }}>
                                  {t("isLiquid")}:
                                </Typography>
                              </Grid>
                              <Grid item xs={6}>
                                <Typography
                                  sx={{ fontWeight: "500", fontSize: "14px" }}
                                >
                                  {isLiquid ? t("yes") : t("no")}
                                </Typography>
                              </Grid>
                            </Grid>
                          </Grid>
                        </Grid>
                        <Divider />
                        <Grid
                          container
                          spacing={2}
                          sx={{
                            mt: "4px",
                            mb: 1,
                            pt: 0,
                            lineBreak: "anywhere!important",
                          }}
                        >
                          <Grid
                            item
                            xs={6}
                            className="productPreviewGrid"
                            sx={{
                              borderRight: "1px solid rgba(85, 56, 48, 0.15)",
                            }}
                          >
                            <Grid container>
                              <Grid item xs={6}>
                                <Typography sx={{ fontSize: "14px" }}>
                                  {t("expirationDate")}:
                                </Typography>
                              </Grid>
                              <Grid item xs={6}>
                                <Typography
                                  sx={{ fontWeight: "500", fontSize: "14px" }}
                                >
                                  {isExpirationDate ? t("yes") : t("no")}
                                </Typography>
                              </Grid>
                            </Grid>
                          </Grid>
                          <Grid item xs={6} className="productPreviewGrid">
                            <Grid container>
                              <Grid item xs={6}>
                                <Typography sx={{ fontSize: "14px" }}>
                                  {t("flammableOrExplosive")}:
                                </Typography>
                              </Grid>
                              <Grid item xs={6}>
                                <Typography
                                  sx={{ fontWeight: "500", fontSize: "14px" }}
                                >
                                  {isFlammableOrExplosive ? t("yes") : t("no")}
                                </Typography>
                              </Grid>
                            </Grid>
                          </Grid>
                        </Grid>
                      </FormControl>
                    </FormGroup>
                    {/* Depo */}
                    <FormGroup className="productPreviewGroup">
                      <BoxTitle title={t("definedWarehouses")} />
                      <FormControl variant="standard" key={"sku"}>
                        <Grid container spacing={2} sx={{ mt: 0.3, mb: 1 }}>
                          <Grid item className="productPreviewGrid" xs={6}>
                            <Typography sx={{ fontSize: "14px" }}>
                              {t("warehouse")}
                            </Typography>
                          </Grid>
                          <Grid item className="productPreviewGrid" xs={6}>
                            <Typography
                              sx={{ fontWeight: "500", fontSize: "14px" }}
                            >
                              {
                                warehouses.find(
                                  option => option.id === productWarehouse
                                ).title
                              }
                            </Typography>
                          </Grid>
                        </Grid>
                      </FormControl>
                    </FormGroup>
                    {/* {variant[0].sku !== "" ? (
                      <FormGroup className="productPreviewGroup">
                        <Box>
                          <Typography
                            sx={{
                              fontSize: "16px",
                              color: theme.palette.primary.main,
                              fontWeight: "500",
                              mb: 1,
                            }}
                          >
                            Varyantlar
                          </Typography>
                        </Box>
                        <Box sx={{ maxWidth: 440 }}>
                          <TableContainer
                            sx={{ maxWidth: "440px", overflowX: "auto" }}
                          >
                            <Table
                              sx={{
                                minWidth: 420,
                                maxWidth: 440,
                                width: "440px",
                                marginBottom: "10px",
                                overflow: "auto",
                              }}
                              aria-label="simple table"
                            >
                              <TableHead sx={{ border: "1px solid #e0e0e0" }}>
                                <TableRow>
                                  <TableCell align="left">MPN/Barkod</TableCell>
                          <TableCell align="left">Ürün Adı</TableCell>
                                  <TableCell align="left">Depo</TableCell>
                                  <TableCell align="left">
                                    Ürün Fiyatı
                                  </TableCell>
                                </TableRow>
                              </TableHead>
                              <TableBody>
                                {variant.map((item, index) => (
                                  <TableRow className="stock-table" key={index}>
                                    <TableCell>
                                      {item.mpn}
                                      <br />
                                      {item.barcode}
                                    </TableCell>
                                    <TableCell align="left">
                                      {item.title}
                                    </TableCell>
                                    <TableCell align="left">
                                      {item.warehouseList.map(
                                        (warehouse, warehouseIndex) => {
                                          return (
                                            <Box key={warehouseIndex}>
                                              <span>
                                                {
                                                  warehouses.find(
                                                    (option) =>
                                                      option.id ===
                                                      warehouse.warehouseId
                                                  ).title
                                                }
                                              </span>
                                              {warehouseIndex ===
                                              item.warehouseList.length - 1 ? (
                                                ""
                                              ) : (
                                                <Divider />
                                              )}
                                            </Box>
                                          );
                                        }
                                      )}
                                    </TableCell>
                                    <TableCell align="left">
                                      {item.warehouseList.map(
                                        (warehouse, warehouseIndex) => {
                                          return (
                                            <Box key={warehouseIndex}>
                                              <span>
                                                {warehouse.price}
                                                {selectedCurrency}
                                              </span>
                                              {warehouseIndex ===
                                              item.warehouseList.length - 1 ? (
                                                ""
                                              ) : (
                                                <Divider />
                                              )}
                                            </Box>
                                          );
                                        }
                                      )}
                                    </TableCell>
                                  </TableRow>
                                ))}
                              </TableBody>
                            </Table>
                          </TableContainer>
                        </Box>
                      </FormGroup>
                    ) : (
                      ""
                    )} */}
                  </Box>
                ))}
            </Box>
            <Box sx={{ display: "flex", flexDirection: "row", pt: 0 }}>
              {activeStep === 0 ? (
                ""
              ) : (
                <Button
                  color="inherit"
                  className="filterReset"
                  disabled={activeStep === 0}
                  onClick={handleBack}
                  sx={{ mr: 1 }}
                >
                  {t("back")}
                </Button>
              )}
              <Box sx={{ flex: "1 1 auto" }} />
              {activeStep < 3 && (
                <Button
                  className="filterApply"
                  onClick={handleNext}
                  type="button"
                  disabled={
                    (activeStep === 0 &&
                    haveVariant === true &&
                    (sku === "" ||
                      productName === "" ||
                      category.length === 0 ||
                      brand === "" ||
                      gtip === "" ||
                      formErrors.sku ||
                      formErrors.productName ||
                      formErrors.barcode ||
                      formErrors.brand ||
                      formErrors.gtip ||
                      formErrors.category)
                      ? true
                      : false) ||
                    (activeStep === 1 &&
                      haveVariant === true &&
                      variant.length === 0 &&
                      (variant[0].mpn === "" ||
                      variant[0].barcode === "" ||
                      variant[0].title === "" ||
                      variant[0].link === "" ||
                      variant[0].width === 0 ||
                      variant[0].width === null ||
                      variant[0].width === undefined ||
                      variant[0].width === "" ||
                      variant[0].height === 0 ||
                      variant[0].height === null ||
                      variant[0].height === undefined ||
                      variant[0].height === "" ||
                      variant[0].length === 0 ||
                      variant[0].length === null ||
                      variant[0].length === undefined ||
                      variant[0].length === "" ||
                      variant[0].weight === 0 ||
                      variant[0].weight === null ||
                      variant[0].weight === undefined ||
                      variant[0].weight === "" ||
                      variant[0].warehouseList[0].price === 0 ||
                      variant[0].warehouseList[0].price === null ||
                      variant[0].warehouseList[0].price === undefined ||
                      variant[0].warehouseList[0].currencyId === ""
                        ? true
                        : false)) ||
                    (activeStep === 1 &&
                      haveVariant === true &&
                      variant.length > 0 &&
                      (variant[variant.length - 1].mpn === "" ||
                      variant[variant.length - 1].barcode === "" ||
                      variant[variant.length - 1].title === "" ||
                      variant[variant.length - 1].width === 0 ||
                      variant[variant.length - 1].width === null ||
                      variant[variant.length - 1].width === undefined ||
                      variant[variant.length - 1].height === 0 ||
                      variant[variant.length - 1].height === null ||
                      variant[variant.length - 1].height === undefined ||
                      variant[variant.length - 1].length === 0 ||
                      variant[variant.length - 1].length === null ||
                      variant[variant.length - 1].length === undefined ||
                      variant[variant.length - 1].weight === 0 ||
                      variant[variant.length - 1].weight === null ||
                      variant[variant.length - 1].weight === 0 ||
                      variant[variant.length - 1].warehouseList[0].price ===
                        0 ||
                      variant[variant.length - 1].warehouseList[0].price ===
                        null ||
                      variant[variant.length - 1].warehouseList[0].price ===
                        undefined ||
                      variant[variant.length - 1].warehouseList[0]
                        .currencyId === ""
                        ? true
                        : false)) ||
                    (activeStep === 1 &&
                      haveVariant === false &&
                      (sku === "" ||
                      barcode === "" ||
                      productName === "" ||
                      category.length === 0 ||
                      brand === "" ||
                      gtip === "" ||
                      productUnitPrice === "" ||
                      width === 0 ||
                      width === null ||
                      width === undefined ||
                      height === 0 ||
                      height === null ||
                      height === undefined ||
                      length === 0 ||
                      length === null ||
                      length === undefined ||
                      weight === 0 ||
                      weight === null ||
                      weight === undefined ||
                      currency === "" ||
                      currency === null ||
                      currency === undefined ||
                      formErrors.productName ||
                      formErrors.barcode ||
                      formErrors.brand ||
                      formErrors.gtip ||
                      formErrors.category ||
                      formErrors.productUnitPrice ||
                      formErrors.width ||
                      formErrors.height ||
                      formErrors.length ||
                      formErrors.weight ||
                      formErrors.currency
                        ? true
                        : false)) ||
                    (activeStep === 2 && productWarehouse === "")
                  }
                >
                  {t("continue")}
                </Button>
              )}
              {activeStep >= 3 && (
                <Button
                  disabled={submitDisabled}
                  className="filterApply"
                  type="submit"
                >
                  {t("send")}
                </Button>
              )}
            </Box>
          </React.Fragment>
        )}
      </Form>
    </Box>
  );
};

export default ProductForm;
