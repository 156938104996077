import { Typography } from "@mui/material";
import React from "react";

function getCustomer(params) {
  return (
    <div>
      <Typography>
        {params.row.customer.fullName !== null
          ? params.row.customer.fullName
          : null}
      </Typography>
    </div>
  );
}

export default getCustomer;
