import React from "react";

const SendPassWordIcon = () => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9.33259 6.66661L7.33259 8.66661M13.5246 2.02061C13.5879 1.99878 13.656 1.99519 13.7212 2.01024C13.7864 2.0253 13.846 2.05839 13.8933 2.10575C13.9406 2.1531 13.9736 2.21281 13.9886 2.27803C14.0035 2.34326 13.9998 2.41138 13.9779 2.47461L10.0286 13.7613C10.005 13.8287 9.9616 13.8876 9.90413 13.9301C9.84666 13.9726 9.77775 13.9969 9.70632 13.9997C9.63489 14.0026 9.56424 13.984 9.50354 13.9462C9.44285 13.9084 9.3949 13.8533 9.36592 13.7879L7.21992 8.95994C7.18408 8.87948 7.11972 8.81512 7.03926 8.77928L2.21126 6.63261C2.14612 6.60353 2.0912 6.55558 2.05359 6.49497C2.01599 6.43435 1.99742 6.36385 2.00029 6.29257C2.00316 6.2213 2.02734 6.15252 2.0697 6.09513C2.11206 6.03773 2.17066 5.99436 2.23792 5.97061L13.5246 2.02061Z"
        stroke="#333333"
        strokeWidth="1.2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default SendPassWordIcon;
