import {
  Box,
  Divider,
  Grid,
  Link,
  Paper,
  Typography,
  useTheme,
  Button,
} from "@mui/material";
import axios from "axios";
import React, { useEffect, useState } from "react";
import Cookies from "js-cookie";
import { useNavigate, useParams } from "react-router-dom";
import { styled } from "@mui/material/styles";
import Topbar from "./global/Topbar";
import { useTranslation } from "react-i18next";
import CircularProgress from "@mui/material/CircularProgress";
import DetailTab from "../components/partials/DetailTab";

const ProductDetail = () => {
  const { t } = useTranslation();
  const theme = useTheme();

  const Item = styled(Paper)(() => ({
    backgroundColor: "transparent",
    paddingLeft: "10px",
    paddingRight: "10px",
    paddingTop: "10px",
    boxShadow: "none",
    border: `1px solid ${theme.palette.primary.bordersColor}}`,
    borderRadius: "2px",
  }));

  let [product, setProduct] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState("");
  const productId = useParams().id;
  const navigate = useNavigate();
  let data = "";
  let config = {
    method: "get",
    maxBodyLength: Infinity,
    url: `${process.env.REACT_APP_BASE_URL}/ProductVariant/${productId}`,
    headers: {
      Authorization: `Bearer ${Cookies.get("_auth")}`,
    },
    data: data,
  };

  const handleBack = () => {
    navigate("/products");
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios(config);
        setLoading(false);
        setProduct(response.data);
      } catch (error) {
        console.log(error);
        console.error(error);
        setError(error);
      }
    };
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  if (loading)
    return (
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
        }}
      >
        <CircularProgress />
      </Box>
    );
  if (error) return <div>Error...</div>;
  return (
    <Box m={"20px"}>
      <Topbar title={t("productDetail")}></Topbar>
      <Button onClick={handleBack} className="filterApply">
        {t("goBack")}
      </Button>
      <Box>
        <Grid
          container
          spacing={0}
          sx={{
            background: theme.palette.primary.white,
            mt: 1,
            paddingLeft: 1,
            paddingRight: 1,
            borderRadius: 2,
            lineBreak: "anywhere!important",
          }}
        >
          <Grid
            item
            xs={12}
            sm={12}
            md={5}
            sx={{ p: "10px 3px", lineBreak: "anywhere!important" }}
          >
            <Item>
              <Typography
                variant="text"
                color="primary"
                sx={{ fontWeight: "bold" }}
              >
                {t("skuAndBarcode")}
              </Typography>
              <Divider
                variant="middle"
                sx={{
                  margin: "10px 0px",
                  borderColor: `rgba(85, 56, 48, 0.09)`,
                }}
              />
              <Box>
                <Grid container>
                  <Grid item sm={6}>
                    {t("sku")}
                  </Grid>
                  <Grid item sm={6}>
                    {product.productVariantInfo.mpn}
                  </Grid>
                </Grid>
              </Box>
              <Divider
                variant="middle"
                sx={{
                  margin: "10px 0px",
                  borderColor: `rgba(85, 56, 48, 0.09)`,
                }}
              />
              <Box>
                <Grid container sx={{ pb: 1 }}>
                  <Grid item sm={6}>
                    {t("barcode")}
                  </Grid>
                  <Grid item sm={6}>
                    {product.productVariantInfo.barcode}
                  </Grid>
                </Grid>
              </Box>
            </Item>
            <Item className="mt10" style={{ marginTop: "10px!important" }}>
              <Typography
                variant="text"
                color="primary"
                sx={{ fontWeight: "bold" }}
              >
               {t("productInfo")}
              </Typography>
              <Divider
                variant="middle"
                sx={{
                  margin: "10px 0px",
                  borderColor: `rgba(85, 56, 48, 0.09)`,
                }}
              />
              <Box>
                <Grid container>
                  <Grid item sm={6}>
                   {t("category")}
                  </Grid>
                  <Grid item sm={6}>
                    {product.productVariantInfo.product.productCategories.map(
                      (category, index) => {
                        return (
                          <span key={index}>
                            {category.title}
                            {index !==
                              product.productVariantInfo.product
                                .productCategories.length -
                                1 && ", "}
                          </span>
                        );
                      }
                    )}
                  </Grid>
                </Grid>
              </Box>
              <Divider
                variant="middle"
                sx={{
                  margin: "10px 0px",
                  borderColor: `rgba(85, 56, 48, 0.09)`,
                }}
              />
              <Box>
                <Grid container>
                  <Grid item sm={6}>
                   {t("productName")}
                  </Grid>
                  <Grid item sm={6}>
                    {product.productVariantInfo.title}
                  </Grid>
                </Grid>
              </Box>
              <Divider
                variant="middle"
                sx={{
                  margin: "10px 0px",
                  borderColor: `rgba(85, 56, 48, 0.09)`,
                }}
              />
              <Box>
                <Grid container sx={{ pb: 1 }}>
                  <Grid item sm={6}>
                    {t("productDescription")}
                  </Grid>
                  <Grid item sm={6}>
                    {product.productVariantInfo.description}
                  </Grid>
                </Grid>
              </Box>
              <Divider
                variant="middle"
                sx={{
                  margin: "10px 0px",
                  borderColor: `rgba(85, 56, 48, 0.09)`,
                }}
              />
              <Box>
                <Grid container>
                  <Grid item sm={6}>
                    {t("brand")}
                  </Grid>
                  <Grid item sm={6}>
                    {product.productVariantInfo.product.productBrand.title}
                  </Grid>
                </Grid>
              </Box>
              <Divider
                variant="middle"
                sx={{
                  margin: "10px 0px",
                  borderColor: `rgba(85, 56, 48, 0.09)`,
                }}
              />
              <Box>
                <Grid container sx={{ pb: 1 }}>
                  <Grid item sm={6}>
                    {t("productLink")}
                  </Grid>
                  <Grid
                    item
                    sm={6}
                    sx={{
                      webkitLineClamp: 2,
                      overflow: "hidden",
                      overflowWrap: "break-word",
                    }}
                  >
                    <Link
                      href={product.productVariantInfo.product.link}
                      target="_blank"
                    >
                      {product.productVariantInfo.product.link}
                    </Link>
                  </Grid>
                </Grid>
              </Box>
            </Item>
            <Item className="mt10" style={{ marginTop: "10px!important" }}>
              <Typography
                variant="text"
                color="primary"
                sx={{ fontWeight: "bold" }}
              >
               {t("dimensionsAndWeight")}
              </Typography>
              <Divider
                variant="middle"
                sx={{
                  margin: "10px 0px",
                  borderColor: `rgba(85, 56, 48, 0.09)`,
                }}
              />
              <Box>
                <Grid container>
                  <Grid item sm={6}>
                   {t("estimatedDimensions")}
                  </Grid>
                  <Grid item sm={6}>
                    <Typography sx={{ fontWeight: "500" }}>
                      {product.productVariantInfo.width +
                        " (en) " +
                        " x " +
                        product.productVariantInfo.height +
                        " (boy) " +
                        "x" +
                        product.productVariantInfo.length +
                        " (yük) "}
                    </Typography>
                  </Grid>
                </Grid>
              </Box>
              <Divider
                variant="middle"
                sx={{
                  margin: "10px 0px",
                  borderColor: `rgba(85, 56, 48, 0.09)`,
                }}
              />
              <Box>
                <Grid container>
                  <Grid item sm={6}>
                    {t("estimatedWeight")}
                  </Grid>
                  <Grid item sm={6}>
                    <Typography sx={{ fontWeight: "500" }}>
                      {product.productVariantInfo.weight + " gram"}
                    </Typography>
                  </Grid>
                </Grid>
              </Box>
              <Divider
                variant="middle"
                sx={{
                  margin: "10px 0px",
                  borderColor: `rgba(85, 56, 48, 0.09)`,
                }}
              />
              <Box>
                <Grid container sx={{ pb: 1 }}>
                  <Grid item sm={6}>
                    {t("dimensionsMeasuredInWarehouse")}
                  </Grid>
                  <Grid item sm={6}>
                    {/* {depoda olculen} */}
                  </Grid>
                </Grid>
              </Box>
              <Divider
                variant="middle"
                sx={{
                  margin: "10px 0px",
                  borderColor: `rgba(85, 56, 48, 0.09)`,
                }}
              />
              <Box>
                <Grid container>
                  <Grid item sm={6}>
                    {t("weightMeasuredInWarehouse")}
                  </Grid>
                  <Grid item sm={6}>
                    {/* {depoda olculen} */}
                  </Grid>
                </Grid>
              </Box>
            </Item>
            <Item className="mt10" style={{ marginTop: "10px!important" }}>
              <Typography
                variant="text"
                color="primary"
                sx={{ fontWeight: "bold" }}
              >
                {t("definedWarehouses")}
              </Typography>
              <Divider
                variant="middle"
                sx={{
                  margin: "10px 0px",
                  borderColor: `rgba(85, 56, 48, 0.09)`,
                }}
              />
              <Box>
                <Grid container>
                  <Grid item sm={6}>
                    <Typography variant="text">
                      {t("warehouse")}:{" "}
                      <span style={{ fontWeight: "500", fontSize: "16px" }}>
                        {product.productVariantInfo.productWarehouses.map(
                          item => item.warehouse.title
                        )}
                      </span>
                    </Typography>
                  </Grid>
                  <Grid item sm={6}>
                    <Typography variant="text">
                     {t("productPrice")}:{" "}
                      <span style={{ fontWeight: "500" }}>
                        {product.productVariantInfo.productWarehouses.map(
                          item =>
                            item.price
                              ? item.price +
                                " " +
                                (item.currency ? item.currency.title : "")
                              : item.price +
                                " " +
                                (item.currency ? item.currency.title : "")
                        )}
                      </span>
                    </Typography>
                  </Grid>
                </Grid>
              </Box>
            </Item>
            <Item className="mt10" style={{ marginTop: "10px!important" }}>
              <Typography
                variant="text"
                color="primary"
                sx={{ fontWeight: "bold" }}
              >
                {t("productProperties")}
              </Typography>
              <Divider
                variant="middle"
                sx={{
                  margin: "10px 0px",
                  borderColor: `rgba(85, 56, 48, 0.09)`,
                }}
              />
              <Box>
                <Grid container>
                  <Grid item sm={6}>
                    {t("isFragile")}
                  </Grid>
                  <Grid item sm={6}>
                    <Typography sx={{ fontWeight: "500" }}>
                      {product.productVariantInfo.isBrakeable === true
                        ? "Evet"
                        : "Hayır"}
                    </Typography>
                  </Grid>
                </Grid>
              </Box>
              <Divider
                variant="middle"
                sx={{
                  margin: "10px 0px",
                  borderColor: `rgba(85, 56, 48, 0.09)`,
                }}
              />
              <Box>
                <Grid container>
                  <Grid item sm={6}>
                    {t("isLiquid")}
                  </Grid>
                  <Grid item sm={6}>
                    <Typography sx={{ fontWeight: "500" }}>
                      {product.productVariantInfo.isLiquid === true
                        ? "Evet"
                        : "Hayır"}
                    </Typography>
                  </Grid>
                </Grid>
              </Box>
              <Divider
                variant="middle"
                sx={{
                  margin: "10px 0px",
                  borderColor: `rgba(85, 56, 48, 0.09)`,
                }}
              />
              <Box>
                <Grid container sx={{ pb: 1 }}>
                  <Grid item sm={6}>
                    {t("expirationDate")}
                  </Grid>
                  <Grid item sm={6}>
                    <Typography sx={{ fontWeight: "500" }}>
                      {product.productVariantInfo.hasExpirationDate === true
                        ? "Evet"
                        : "Hayır"}
                    </Typography>
                  </Grid>
                </Grid>
              </Box>
              <Divider
                variant="middle"
                sx={{
                  margin: "10px 0px",
                  borderColor: `rgba(85, 56, 48, 0.09)`,
                }}
              />
              <Box>
                <Grid container sx={{ pb: 1 }}>
                  <Grid item sm={6}>
                  {t("flammableOrExplosiveProduct")}
                  </Grid>
                  <Grid item sm={6}>
                    <Typography sx={{ fontWeight: "500" }}>
                      {product.productVariantInfo.isFlammableOrExplosive ===
                      true
                        ? "Evet"
                        : "Hayır"}
                    </Typography>
                  </Grid>
                </Grid>
              </Box>
            </Item>
          </Grid>
          <Grid item xs={12} sm={12} md={7} sx={{ p: "10px 3px" }}>
            <Item>
              <Typography
                variant="text"
                color="primary"
                sx={{ fontWeight: "bold" }}
              >
                {t("processHistory")}
              </Typography>
              <DetailTab />
            </Item>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};

export default ProductDetail;
