import React from "react";
import {
  DataGrid,
  GridToolbarContainer,
  GridToolbarColumnsButton,
  GridToolbarDensitySelector,
  trTR,
  enUS,
} from "@mui/x-data-grid";
import { useTheme } from "@mui/material";
import { useTranslation } from "react-i18next";

function CustomToolbar() {
  return (
    <GridToolbarContainer>
      <GridToolbarColumnsButton
        value="
        grid-toolbar-columns-button
      "
      />
      <GridToolbarDensitySelector />
    </GridToolbarContainer>
  );
}
const TableList = ({
  className,
  rows,
  columns,
  pageState,
  setPageState,
  rowHeight,
  isToolbar,
  defaultPageSize,
  style,
  selection,
  onSelectionModelChange,
  selectionModel,
}) => {
  const { i18n } = useTranslation();
  const theme = useTheme();
  return (
    <DataGrid
      className={className}
      sx={{
        borderColor: theme.palette.primary.bordersColor,
        height: "100%",
        "& .Mui-selected": {
          background: `${theme.palette.primary.tableEvenRows}!important`,
        },
        "& .MuiDataGrid-row:hover": {
          background: `${theme.palette.primary.tableEvenRows}!important`,
        },
        "& .MuiDataGrid-cell:focus": {
          outline: `transparent!important`,
        },
        "& .MuiDataGrid-cell:focus-within": {
          outline: `transparent!important`,
        },
        "& .MuiDataGrid-columnHeader:focus": {
          outline: `transparent!important`,
        },
        "& .MuiDataGrid-columnHeader:focus-within": {
          outline: `transparent!important`,
        },
      }}
      style={style}
      autoHeight
      rows={rows}
      showCellRightBorder={true}
      rowCount={pageState.total}
      loading={pageState.isLoading}
      rowsPerPageOptions={defaultPageSize ? [defaultPageSize] : [5, 10, 20, 50]}
      pagination
      checkboxSelection={selection}
      page={pageState.page}
      pageSize={pageState.pageSize}
      paginationMode="server"
      onPageChange={newPage => {
        setPageState(old => ({ ...old, page: newPage }));
      }}
      onSelectionModelChange={onSelectionModelChange}
      onPageSizeChange={newPageSize =>
        setPageState(old => ({ ...old, pageSize: newPageSize }))
      }
      columns={columns}
      components={isToolbar === false ? null : { Toolbar: CustomToolbar }}
      localeText={
        i18n.language === "tr"
          ? trTR.components.MuiDataGrid.defaultProps.localeText
          : enUS.components.MuiDataGrid.defaultProps.localeText
      }
      rowHeight={rowHeight}
      sortingOrder={["desc", "asc"]}
    />
  );
};

export default TableList;
