import React from "react";
import Topbar from "./global/Topbar";
import { Box, Typography, Grid } from "@mui/material";

import LogsTable from "../components/Logs/LogsTable";
import { useTranslation } from "react-i18next";

const Logs = () => {
  const { t } = useTranslation();
  return (
    <Box m={"20px"}>
      <Topbar title={t("Logs")}>
        {/* <Button
          startIcon={<NewProductIcon />}
          color="text"
          sx={{
            color: `${theme.palette.primary.fontColor}!important`,
            ml: 2,
            textTransform: "capitalize",
            border: "none",
            borderRadius: 0,
          }}
          onClick={() => setOpen(true)}
        >
          Promosyon Kodu ekle
        </Button>
         */}
      </Topbar>

      <Box mt={2} sx={{ height: "100%", backgroundColor: "#fff", p: 3 }}>
        <Grid container spacing="20px">
          <Grid item xs={12} sm={12}>
            <LogsTable />
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};

export default Logs;
