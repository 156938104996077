import React from "react";

const FAQIcon = () => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_2830_2745)">
        <path
          d="M19.9305 10C19.9305 11.9641 19.348 13.8841 18.2569 15.5171C17.1657 17.1502 15.6147 18.423 13.8002 19.1747C11.9856 19.9263 9.98888 20.1229 8.06254 19.7398C6.1362 19.3566 4.36675 18.4108 2.97793 17.022C1.58912 15.6332 0.643326 13.8637 0.260153 11.9374C-0.123019 10.011 0.0736387 8.01433 0.825259 6.19976C1.57688 4.38518 2.8497 2.83424 4.48277 1.74306C6.11585 0.651875 8.03582 0.069458 9.9999 0.069458C12.6328 0.0722384 15.157 1.11938 17.0188 2.98112C18.8805 4.84286 19.9277 7.36712 19.9305 10ZM18.4027 10C18.4027 8.3381 17.9099 6.71351 16.9866 5.33168C16.0632 3.94985 14.7509 2.87285 13.2155 2.23686C11.6801 1.60087 9.99057 1.43447 8.3606 1.75869C6.73062 2.08292 5.23338 2.8832 4.05823 4.05835C2.88309 5.2335 2.0828 6.73073 1.75858 8.36071C1.43435 9.99069 1.60076 11.6802 2.23674 13.2156C2.87273 14.751 3.94973 16.0634 5.33156 16.9867C6.71339 17.91 8.33798 18.4028 9.9999 18.4028C12.2277 18.4003 14.3635 17.5142 15.9388 15.9389C17.514 14.3636 18.4001 12.2278 18.4027 10Z"
          fill="#453834"
        />
        <path
          d="M10.4609 12.7969H9.00781C9.01302 12.2969 9.05729 11.888 9.14062 11.5703C9.22917 11.2474 9.3724 10.9531 9.57031 10.6875C9.76823 10.4219 10.0312 10.1198 10.3594 9.78125C10.599 9.53646 10.8177 9.30729 11.0156 9.09375C11.2188 8.875 11.3828 8.64062 11.5078 8.39062C11.6328 8.13542 11.6953 7.83073 11.6953 7.47656C11.6953 7.11719 11.6302 6.80729 11.5 6.54688C11.375 6.28646 11.1875 6.08594 10.9375 5.94531C10.6927 5.80469 10.388 5.73438 10.0234 5.73438C9.72135 5.73438 9.4349 5.78906 9.16406 5.89844C8.89323 6.00781 8.67448 6.17708 8.50781 6.40625C8.34115 6.63021 8.25521 6.92448 8.25 7.28906H6.80469C6.8151 6.70052 6.96094 6.19531 7.24219 5.77344C7.52865 5.35156 7.91406 5.02865 8.39844 4.80469C8.88281 4.58073 9.42448 4.46875 10.0234 4.46875C10.6849 4.46875 11.2474 4.58854 11.7109 4.82812C12.1797 5.06771 12.5365 5.41146 12.7812 5.85938C13.026 6.30208 13.1484 6.82812 13.1484 7.4375C13.1484 7.90625 13.0521 8.33854 12.8594 8.73438C12.6719 9.125 12.4297 9.49219 12.1328 9.83594C11.8359 10.1797 11.5208 10.5078 11.1875 10.8203C10.901 11.0859 10.7083 11.3854 10.6094 11.7188C10.5104 12.0521 10.4609 12.4115 10.4609 12.7969ZM8.94531 15.2734C8.94531 15.0391 9.01823 14.8411 9.16406 14.6797C9.3099 14.5182 9.52083 14.4375 9.79688 14.4375C10.0781 14.4375 10.2917 14.5182 10.4375 14.6797C10.5833 14.8411 10.6562 15.0391 10.6562 15.2734C10.6562 15.4974 10.5833 15.6901 10.4375 15.8516C10.2917 16.013 10.0781 16.0938 9.79688 16.0938C9.52083 16.0938 9.3099 16.013 9.16406 15.8516C9.01823 15.6901 8.94531 15.4974 8.94531 15.2734Z"
          fill="#453834"
        />
      </g>
      <defs>
        <clipPath id="clip0_2830_2745">
          <rect width="20" height="20" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default FAQIcon;
