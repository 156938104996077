import React from "react";
import { Switch, Typography, Box } from "@mui/material";
import { makeStyles } from "@material-ui/styles";
const useStyles = makeStyles(() => ({
  container: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    width: "100%",
    padding: "12px",
    border: "1px solid #45383426",
    borderRadius: "2px",
  },
  header: {
    fontWeight: "700 !important",
    fontSize: "14px",
    color: "#EB603A",
  },
  description: {
    marginTop: "10px",
    fontSize: "14px",
    fontWeight: "400 !important",
    color: "##453834",
  },
  switchBase: {
    "& .MuiSwitch-switchBase": {
      padding: 0,
      top: "52%",
      right: "20px",
      transform: "translateY(-50%)",
      "&:hover": {
        backgroundColor: "transparent",
      },
      "&.Mui-checked": {
        transform: "translateY(-50%) translateX(13px)",
        "& + .MuiSwitch-track": {
          opacity: 1,
          backgroundColor: "#F7F6F5 !important",
        },
      },
      "&.Mui-focusVisible": {
        backgroundColor: "transparent",
      },
    },

    "& .MuiSwitch-thumb": {
      width: 12,
      height: 12,
      boxShadow: "none",
    },
    "& .MuiSwitch-track": {
      borderRadius: 8,
      border: "1px solid #45383426",
      backgroundColor: "silver",
      opacity: 1,
      width: 28,
      height: 16,
      transition: "background-color 0.3s",
    },
    width: 28,
    height: 16,
    padding: 0,
  },
}));

const HeaderSwitchCard = ({ header, description, checked, onToggle }) => {
  const classes = useStyles();

  return (
    <Box className={classes.container}>
      <Box>
        <Typography className={classes.header}>{header}</Typography>
        <Typography className={classes.description}>{description}</Typography>
      </Box>
      <Switch
        checked={checked}
        onChange={onToggle}
        classes={{
          root: classes.switchBase,
        }}
      />
    </Box>
  );
};

export default HeaderSwitchCard;
