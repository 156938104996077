import React, { useEffect, useState, useContext } from "react";
import { Alert, AlertTitle, Box } from "@mui/material";
import { useNavigate, Navigate } from "react-router-dom";
import { useAuthUser } from "react-auth-kit";
import axios from "axios";
import Cookies from "js-cookie";
import { useTranslation } from "react-i18next";

const Return = () => {
  const auth = useAuthUser();
  const { t } = useTranslation();
  const [status, setStatus] = useState(null);
  const [customerEmail, setCustomerEmail] = useState("");
  const [countdown, setCountdown] = useState(5);
  const navigate = useNavigate();
  const merchantId = auth().merchantId;

  const getCookies = Cookies.get("selectedPackage");

  const saveOrder = async () => {
    let config = {
      method: "post",
      maxBodyLength: Infinity,
      url: `${process.env.REACT_APP_BASE_URL}/Merchant/CreateMerchantPayment/${merchantId}`,
      headers: {
        Authorization: `Bearer ${Cookies.get("_auth")}`,
      },
      data: {
        amount: JSON.parse(getCookies).amount,
        currency: "usd",
        merchantId: merchantId,
        paymentStatus: true,
        description: JSON.parse(getCookies).name,
        yearly: JSON.parse(getCookies).yearly,
      },
    };

    try {
      console.log("Creating payment...");
      console.log(config.data);
      const response = await axios(config);
      console.log(response.data);
    } catch (error) {
      console.error("Error creating payment:", error);
    }
  };

  useEffect(() => {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const sessionId = urlParams.get("session_id");
    fetch(`${process.env.REACT_APP_BASE_URL}/Stripe?session_id=${sessionId}`)
      .then(res => res.json())
      .then(data => {
        saveOrder();
        localStorage.removeItem("selectedPackage");
        setStatus(data.status);
        setCustomerEmail(data.customer_email);
        if (data.status === "complete") {
          const countdownInterval = setInterval(() => {
            setCountdown(prevCountdown => prevCountdown - 1);
          }, 1000);

          setTimeout(() => {
            clearInterval(countdownInterval);
            navigate("/payments");
          }, 5000);
        }
      });
  }, []);

  if (status === "open") {
    return <Navigate to="/payment" />;
  }

  if (status === "complete") {
    return (
      <section id="success">
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100vh",
            overflow: "hidden",
          }}
        >
          <Alert severity="success">
            <AlertTitle>{t("success")}</AlertTitle>
            {t("page")} {countdown} {t("willBeRedirectedToThePaymentPageWithinSeconds")}
          </Alert>
        </Box>
      </section>
    );
  }

  return null;
};

export default Return;
