import React, { useState, useEffect } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Badge from "@mui/material/Badge";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Box from "@mui/material/Box";
import MessageFIleIcon from "../icons/MessageFIleIcon";
import AddIcon from "@mui/icons-material/Add";
import { alpha, styled } from "@mui/material/styles";
import InputBase from "@mui/material/InputBase";
import FormControl from "@mui/material/FormControl";
import Divider from "@mui/material/Divider";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import CloseIconCustom from "../icons/CloseIconCustom";
import { useAuthUser } from "react-auth-kit";
import CircularProgress from "@mui/material/CircularProgress";
import axios from "axios";
import Cookies from "js-cookie";
import Snackbar from "@mui/material/Snackbar";

const BootstrapInput = styled(InputBase)(({ theme }) => ({
  "label + &": {
    marginTop: theme.spacing(3),
    color: `${alpha(theme.palette.text.main, 0.25)} 0 0 0 0.2rem`,
  },
  "& .MuiInputBase-input": {
    borderRadius: 2,
    position: "relative",
    border: "1px solid",
    fontSize: 14,
    fontStyle: "italic",
    height: "12px",
    width: "100%",
    padding: "10px 12px",
    marginTop: "0.5rem",
    borderColor: theme.palette.primary.bordersColor,
    transition: theme.transitions.create([
      "border-color",
      "background-color",
      "box-shadow",
    ]),
    "&:focus": {
      boxShadow: `${alpha(theme.palette.primary.main, 0.25)} 0 0 0 0.2rem`,
      borderColor: theme.palette.primary.main,
      color: theme.palette.text.main,
    },
  },
}));

// get merchant id

const SendMessage = ({ open, onClose, merchant, role, sendMessage }) => {
  let user;
  const [submitButtonDisabled, setSubmitButtonDisabled] = useState(false);
  const getAuthUser = useAuthUser();
  const [successMessage, setSuccessMessage] = useState("");
  const [isSnackbarOpen, setSnackbarOpen] = useState(false);
  const [senderName, setSenderName] = useState("");
  const [senderEmail, setSenderEmail] = useState("");
  const [loading, setLoading] = useState(false);
  const [files, setFiles] = useState([]);
  const [message, setNewMessage] = useState("");
  const handleFileChange = event => {
    const newFiles = Array.from(event.target.files);
    setFiles(prevFiles => [...prevFiles, ...newFiles]);
  };

  const handleUndo = index => {
    setFiles(prevFiles => {
      const newFiles = [...prevFiles];
      newFiles.splice(index, 1);
      return newFiles;
    });
  };
  const handleClearAll = () => {
    setFiles([]);
  };

  useEffect(() => {
    setLoading(true);
    user = getAuthUser();
    setSenderName(user.nameSurname);
    setSenderEmail(user.email);
    setLoading(false);
  }, []);

  const handleSend = async e => {
    e.preventDefault();
    setSubmitButtonDisabled(true);
    // const formData = new FormData();
    // formData.append("MessageBody", message);
    // formData.append("MerchantId", merchant.id);
    // files.forEach(file => {
    //   formData.append("Files", file);
    // });

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_BASE_URL}/Merchant/CreateMerchantMessage/${merchant.id}`,
        {
          MessageBody: message,
          Files: files,
          MerchantId: merchant.id,
        },
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${Cookies.get("_auth")}`,
          },
        }
      );

      if (response.status === 200) {
        console.log({
          MessageBody: message,
          Files: files,
          MerchantId: merchant.id,
        });
        console.log(response);
        onClose();
        setSuccessMessage("Mesajınız başarıyla gönderildi.");
        setSnackbarOpen(true);
        sendMessage();
        setFiles([]);
        setNewMessage("");
      }
    } catch (error) {
      setSuccessMessage("Mesajınız gönderilirken bir hata oluştu.");
      setSnackbarOpen(true);
      onClose();
    }
    setSubmitButtonDisabled(false);
  };

  const handleCloseSnackbar = () => {
    setSnackbarOpen(false);
  };
  if (loading)
    return (
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
          width: "100vw",
          backgroundColor: "rgba(0, 0, 0, 0.5)",
        }}
      >
        <CircularProgress />
      </Box>
    );
  return (
    <Box>
      <Dialog open={open} onClose={onClose} fullWidth maxWidth="sm">
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "center",
            pr: 2,
          }}
        >
          <DialogTitle
            sx={{
              color: "#eb603a",
              fontWeight: "bold",
              fontSize: "1.5rem",
            }}
          >
            Mesaj Gönder
          </DialogTitle>
          <IconButton onClick={onClose}>
            <CloseIconCustom />
          </IconButton>
        </Box>

        <DialogContent
          sx={{
            paddingTop: "0px!important",
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: 2,
              mt: 0,
              pt: 0,
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                border: "1px solid",
                borderColor: "#45383426",
                borderRadius: "2px",
                paddingX: 1,
                paddingBottom: 1,
                mt: 0,
                pt: 0,
              }}
            >
              <Typography
                color="primary"
                variant="subtitle1"
                sx={{ fontWeight: "700", mt: 1, mb: 1 }}
              >
                Gönderi Bilgileri
              </Typography>

              <Divider
                variant="middle"
                sx={{
                  m: 0,
                  p: 0,
                  borderColor: `rgba(85, 56, 48, 0.09)`,
                }}
              />
              <Grid container spacing={2}>
                <Grid
                  item
                  sx={{
                    mt: 1,
                    pb: 0.6,
                  }}
                  xs={12}
                  md={6}
                >
                  <Typography color="text" variant="body1">
                    Gönderilen Firma
                  </Typography>
                </Grid>
                <Grid
                  item
                  sx={{
                    mt: 1,
                    pb: 0.6,
                  }}
                  xs={12}
                  md={6}
                >
                  <Typography
                    color="text"
                    variant="subtitle1"
                    sx={{ fontWeight: "500" }}
                  >
                    {role === "merchant"
                      ? "MegaMerchant"
                      : merchant && merchant.title}
                  </Typography>
                </Grid>
              </Grid>
              {/* <Divider
                variant="middle"
                sx={{
                  m: 0,
                  p: 0,
                  borderColor: `rgba(85, 56, 48, 0.09)`,
                }}
              />
              <Grid container spacing={2}>
                <Grid
                  item
                  sx={{
                    mt: 1,
                    pb: 0.6,
                  }}
                  xs={12}
                  md={6}
                >
                  <Typography color="text" variant="body1">
                    Gönderilen Sorumlu
                  </Typography>
                </Grid>
                <Grid
                  item
                  sx={{
                    mt: 1,
                    pb: 0.6,
                  }}
                  xs={12}
                  md={6}
                >
                  <Typography
                    color="text"
                    variant="subtitle1"
                    sx={{ fontWeight: "500" }}
                  >
                    {merchant.users[0].nameSurname}
                  </Typography>
                </Grid>
              </Grid> */}
              {/* <Divider
                variant="middle"
                sx={{
                  m: 0,
                  p: 0,
                  borderColor: `rgba(85, 56, 48, 0.09)`,
                }}
              />
              <Grid container spacing={2}>
                <Grid
                  item
                  sx={{
                    mt: 1,
                    pb: 0.6,
                  }}
                  xs={12}
                  md={6}
                >
                  <Typography color="text" variant="body1">
                    Gönderilen E-posta
                  </Typography>
                </Grid>
                <Grid
                  item
                  sx={{
                    mt: 1,
                    pb: 0.6,
                  }}
                  xs={12}
                  md={6}
                >
                  <Typography
                    color="text"
                    variant="subtitle1"
                    sx={{ fontWeight: "500" }}
                  >
                    {senderEmail}
                  </Typography>
                </Grid>
              </Grid> */}
              {/* <Divider
                variant="middle"
                sx={{
                  m: 0,
                  p: 0,
                  borderColor: `rgba(85, 56, 48, 0.09)`,
                }}
              />
              <Grid container spacing={2}>
                <Grid
                  item
                  sx={{
                    mt: 1,
                  }}
                  xs={12}
                  md={6}
                >
                  <Typography color="text" variant="body1">
                    Gönderen Sorumlu
                  </Typography>
                </Grid>
                <Grid
                  item
                  sx={{
                    mt: 1,
                  }}
                  xs={12}
                  md={6}
                >
                  <Typography
                    color="text"
                    variant="subtitle1"
                    sx={{ fontWeight: "500" }}
                  >
                    {senderName}
                  </Typography>
                </Grid>
              </Grid> */}
            </Box>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                border: "1px solid",
                borderColor: "#45383426",
                borderRadius: "2px",
                paddingX: 1,
                paddingBottom: 1,
              }}
            >
              <Typography
                color="primary"
                variant="subtitle1"
                sx={{ fontWeight: "700", mt: 1, mb: 1 }}
              >
                Mesaj
              </Typography>
              <Divider
                variant="middle"
                sx={{
                  m: 0,
                  p: 0,
                  borderColor: `rgba(85, 56, 48, 0.09)`,
                }}
              />
              <Box>
                <FormControl variant="standard" className="signup-fc" key={"1"}>
                  <BootstrapInput
                    type="textarea"
                    name="title"
                    multiline
                    rows={4}
                    placeholder={"Mesajınızı yazabilirsiniz..."}
                    onChange={e => setNewMessage(e.target.value)}
                    value={message}
                  />
                </FormControl>
              </Box>
              <Box>
                <div>
                  <input
                    type="file"
                    multiple
                    onChange={handleFileChange}
                    style={{ display: "none" }}
                    id="file-input"
                  />
                  <label htmlFor="file-input">
                    <Button
                      variant="text"
                      color="secondary"
                      component="span"
                      sx={{
                        textTransform: "capitalize",
                      }}
                      startIcon={<AddIcon />}
                    >
                      Dosya Ekle
                    </Button>
                  </label>

                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      flexWrap: "wrap",
                      gap: 2,
                      mt: 2,
                    }}
                  >
                    {files.map((file, index) => (
                      <Badge
                        key={index}
                        badgeContent={
                          <IconButton
                            onClick={() => handleUndo(index)}
                            color="error"
                            size="small"
                          >
                            <CloseIcon
                              color="text"
                              sx={{
                                background: "#F4F2F1!important",
                                borderRadius: "50%!important",
                              }}
                            />
                          </IconButton>
                        }
                      >
                        <Button
                          className="messageUploadFileButton"
                          variant="outlined"
                          startIcon={<MessageFIleIcon />}
                        >
                          {file.name}
                        </Button>
                      </Badge>
                    ))}
                  </Box>

                  {/* {files.length > 0 && (
                <Button variant="outlined" onClick={handleClearAll}>
                  Tümünü Temizle
                </Button>
              )} */}
                </div>
              </Box>
            </Box>
          </Box>
        </DialogContent>
        <DialogActions
          sx={{
            display: "flex",
            justifyContent: "space-between",
            pl: 3,
            pr: 3,
            pt: 0,
            mt: 0,
            mb: 2,
          }}
        >
          <Button className="filterReset" onClick={onClose}>
            Kapat
          </Button>
          <Button
            className="filterApply"
            disabled={submitButtonDisabled}
            onClick={handleSend}
          >
            Gönder
          </Button>
        </DialogActions>
      </Dialog>
      <Snackbar
        open={isSnackbarOpen}
        autoHideDuration={6000}
        onClose={handleCloseSnackbar}
        message={successMessage}
      />
    </Box>
  );
};

export default SendMessage;
