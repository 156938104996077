import { Box, Divider, Typography } from "@mui/material";
import React from "react";
import { useTheme } from "@mui/material";

const BoxTitle = ({ title, style, isDivider }) => {
  const theme = useTheme();
  return (
    <Box>
      <Typography
        sx={{
          fontSize: "16px",
          color: theme.palette.primary.main,
          fontWeight: "500",
        }}
      >
        {title}
      </Typography>
      {isDivider === false ? null : <Divider sx={{ mt: 1 }} />}
    </Box>
  );
};

export default BoxTitle;
