import {
  Box,
  useTheme,
  Button,
  IconButton,
  InputBase,
  Drawer,
  Typography,
  Stack,
} from "@mui/material";
import React, { useContext, useState, useEffect } from "react";
import Cookies from "js-cookie";

import FilterIcon from "../icons/FilterIcon";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import CloseIcon from "@mui/icons-material/Close";
import { alpha, styled } from "@mui/material/styles";
import axios from "axios";
import { StockFilterContext } from "../../context/StockFilterContext";
import CustomStockMultiSelect from "../forms/form-elements/CustomStockMultiSelect";
import CustomStockWareHouseSelect from "../forms/form-elements/CustomStockWareHouseSelect";
import DownloadStockExcel from "./DownloadStockExcel";
import { useTranslation } from "react-i18next";

const BootstrapInput = styled(InputBase)(({ theme }) => ({
  "label + &": {
    color: `${alpha(theme.palette.text.main, 0.25)} 0 0 0 0.2rem`,
  },
  "& .MuiInputBase-input": {
    borderRadius: 2,
    position: "relative",
    border: "1px solid",
    fontSize: 14,
    fontStyle: "italic",
    height: "12px",
    width: "100%",
    padding: "10px 12px",
    marginTop: "0.5rem",
    borderColor: theme.palette.primary.bordersColor,
    transition: theme.transitions.create([
      "border-color",
      "background-color",
      "box-shadow",
    ]),
    "&:focus": {
      boxShadow: `${alpha(theme.palette.primary.main, 0.25)} 0 0 0 0.2rem`,
      borderColor: theme.palette.primary.main,
      color: theme.palette.text.main,
    },
  },
}));

const StockFilter = () => {
  const theme = useTheme();
  const { t } = useTranslation();
  const [state, setState] = useState({
    left: false,
  });

  const { stockFilters, setStockFilters } = useContext(StockFilterContext);
  const [filters, setFilters] = useState({});

  const [warehouses, setWarehouses] = useState([]);
  const [categories, setCategories] = useState([]);

  const handleChange = event => {
    const name = event.target.name;
    const value = event.target.value;
    setFilters(values => ({ ...values, [name]: value }));
  };

  const handleSubmit = event => {
    event.preventDefault();
    setStockFilters({ ...stockFilters, ...filters });
    setState({ ...state, left: false });
  };

  const handleFilterReset = event => {
    event.preventDefault();
    setStockFilters({
      sku: null,
      barcode: null,
      stockName: null,
      status: null,
      category: [],
      warehouse: [],
      search: "",
      stockAmountStart: null,
      stockAmountEnd: null,
    });

    setFilters({
      sku: null,
      barcode: null,
      stockName: null,
      status: null,
      category: [],
      warehouse: [],
      search: "",
      stockAmountStart: null,
      stockAmountEnd: null,
    });

    setState({ ...state, left: false });
  };

  const toggleDrawer = (anchor, open) => event => {
    if (
      event &&
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setState({ ...state, [anchor]: open });
  };

  useEffect(() => {
    let wareHouseData = "";
    const configWarehouses = {
      method: "get",
      maxBodyLength: Infinity,
      url: `${process.env.REACT_APP_BASE_URL}/Warehouse?Pagination.Page=0&Pagination.Size=100&isCountry=true`,
      headers: {
        Authorization: `Bearer ${Cookies.get("_auth")}`,
      },
      wareHouseData: wareHouseData,
    };

    const fetchWarehouses = async () => {
      const responseWarehouses = await axios.request(configWarehouses);
      const responseWarehousesData = await responseWarehouses.data.items;
      setWarehouses(responseWarehousesData);
    };

    fetchWarehouses();
  }, []);

  useEffect(() => {
    let categoryData = "";
    const configCategories = {
      method: "get",
      maxBodyLength: Infinity,
      url: `${process.env.REACT_APP_BASE_URL}/ProductCategory?Pagination.Page=0&Pagination.Size=100`,
      headers: {
        Authorization: `Bearer ${Cookies.get("_auth")}`,
      },
      categoryData: categoryData,
    };

    const fetchCategories = async () => {
      const responseCategories = await axios.request(configCategories);
      const responseCategoriesData = await responseCategories.data.items;
      setCategories(responseCategoriesData);
    };

    fetchCategories();
  }, []);

  const list = anchor => (
    <Box
      sx={{ width: anchor === "top" || anchor === "bottom" ? "auto" : 368 }}
      role="presentation"
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Typography variant="h1" m={2}>
          {t("filters")}
        </Typography>
        <IconButton onClick={toggleDrawer(anchor, false)}>
          <CloseIcon sx={{ mr: 2 }} />
        </IconButton>
      </Box>
      <Box mt={2} ml={1}>
        <Accordion
          sx={{
            boxShadow: "none!important",
            border: "none!important",
            position: "unset",
          }}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
            className="panella-header"
          >
            <Typography
              sx={{
                fontWeight: "500",
                color: theme.palette.text.main,
                fontSize: "14px",
              }}
            >
              {t("sku")}
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <BootstrapInput
              name="sku"
              value={filters.sku}
              onChange={handleChange}
            />
          </AccordionDetails>
        </Accordion>
        <Accordion
          sx={{
            boxShadow: "none!important",
            border: "none!important",
            position: "unset",
          }}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel2a-content"
            id="panel2a-header"
            className="panella-header"
          >
            <Typography
              sx={{
                fontWeight: "500",
                color: theme.palette.text.main,
                fontSize: "14px",
              }}
            >
              {t("barcode")}
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <BootstrapInput
              name="barcode"
              value={filters.barcode}
              onChange={handleChange}
            />
          </AccordionDetails>
        </Accordion>
        <Accordion
          sx={{
            boxShadow: "none!important",
            border: "none!important",
            position: "unset",
          }}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel2a-content"
            id="panel2a-header"
            className="panella-header"
          >
            <Typography
              sx={{
                fontWeight: "500",
                color: theme.palette.text.main,
                fontSize: "14px",
              }}
            >
              {t("productName")}
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <BootstrapInput
              name="stockName"
              value={filters.stockName}
              onChange={handleChange}
            />
          </AccordionDetails>
        </Accordion>
        <Accordion
          sx={{
            boxShadow: "none!important",
            border: "none!important",
            position: "unset",
          }}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel2a-content"
            id="panel2a-header"
            className="panella-header"
          >
            <Typography
              sx={{
                fontWeight: "500",
                color: theme.palette.text.main,
                fontSize: "14px",
              }}
            >
              {t("warehouse")}
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <CustomStockWareHouseSelect
              className={"filter-select"}
              options={warehouses}
            />
          </AccordionDetails>
        </Accordion>
        <Accordion
          sx={{
            boxShadow: "none!important",
            border: "none!important",
            position: "unset",
          }}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel2a-content"
            id="panel2a-header"
            className="panella-header"
          >
            <Typography
              sx={{
                fontWeight: "500",
                color: theme.palette.text.main,
                fontSize: "14px",
              }}
            >
              {t("category")}
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <CustomStockMultiSelect
              className={"filter-select"}
              options={categories}
            />
          </AccordionDetails>
        </Accordion>
        <Accordion
          sx={{
            boxShadow: "none!important",
            border: "none!important",
            position: "unset",
          }}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel2a-content"
            id="panel2a-header"
            className="panella-header"
          >
            <Typography
              sx={{
                fontWeight: "500",
                color: theme.palette.text.main,
                fontSize: "14px",
              }}
            >
              {t("stockQuantity")}
            </Typography>
          </AccordionSummary>
          <AccordionDetails
            sx={{
              display: "flex",
              justifyContent: "space-between",
              gap: 2,
              alignItems: "center",
            }}
          >
            <BootstrapInput
              name="stockAmountStart"
              value={filters.stockAmountStart}
              onChange={handleChange}
              placeholder={t("beginning")}
            />
            <Typography
              sx={{
                fontWeight: "500",
                color: theme.palette.text.main,
                fontSize: "14px",
                mt: 1,
              }}
            >
              -
            </Typography>
            <BootstrapInput
              name="stockAmountEnd"
              value={filters.stockAmountEnd}
              onChange={handleChange}
              placeholder={t("end")}
            />
          </AccordionDetails>
        </Accordion>
      </Box>
      <Box ml={2} position="absolute" bottom="20px">
        <Stack direction="row" spacing={16}>
          <Button onClick={handleFilterReset} className="filterReset">
            {t("reset")}
          </Button>
          <Button onClick={handleSubmit} className="filterApply">
            {t("applyFilters")}
          </Button>
        </Stack>
      </Box>
    </Box>
  );

  const resetFilterSku = () => {
    setStockFilters({ ...stockFilters, sku: null });
    setFilters({ ...filters, sku: null });
  };

  const resetFilterBarcode = () => {
    setStockFilters({ ...stockFilters, barcode: null });
    setFilters({ ...filters, barcode: null });
  };

  const resetFilterStockName = () => {
    setStockFilters({ ...stockFilters, stockName: null });
    setFilters({ ...filters, stockName: null });
  };

  const resetFilterWarehouse = () => {
    setStockFilters({ ...stockFilters, warehouse: [] });
    setFilters({ ...filters, warehouse: [] });
  };

  const resetFilterCategory = () => {
    setStockFilters({ ...stockFilters, category: [] });
    setFilters({ ...filters, category: [] });
  };

  const resetFilterStockAmounts = () => {
    setStockFilters({ ...stockFilters, stockAmountStart: null });
    setStockFilters({ ...stockFilters, stockAmountEnd: null });
    setFilters({ ...filters, stockAmountStart: null });
    setFilters({ ...filters, stockAmountEnd: null });
  };

  return (
    <Box>
      <Box>
        <Box
          sx={{
            display: "flex",
            justifyContent: "flex-end",
            alignItems: "center",
            gap: 2,
          }}
        >
          <DownloadStockExcel />
        </Box>
        <Box
          sx={{
            background: theme.palette.primary.filtersBackground,
            mt: 2,
            display: "flex",
            alignItems: "center",
            gap: "10px",
            justifyContent: "flex-start",
          }}
        >
          <Button
            onClick={toggleDrawer("left", true)}
            startIcon={<FilterIcon />}
            endIcon={<ExpandMoreIcon />}
            color="text"
            sx={{
              mt: 1,
              mb: 1,
              p: 1,
              pl: 2,
              textTransform: "capitalize",
              border: "none",
              borderRight: "1px solid",
              borderRadius: 0,
              borderColor: theme.palette.primary.bordersColor,
            }}
          >
            {t("filters")}
          </Button>
          <Box sx={{ flexGrow: 1, display: "contents" }}>
            {stockFilters.sku !== null && (
              <Button
                onClick={resetFilterSku}
                endIcon={<CloseIcon />}
                color="text"
                sx={{
                  mt: 1,
                  mb: 1,
                  pl: 2,
                  textTransform: "capitalize",
                  border: "1px solid",
                  borderRadius: 0,
                  background: "#fff",
                  borderColor: theme.palette.primary.bordersColor,
                }}
              >
                SKU: {stockFilters.sku}
              </Button>
            )}
          </Box>
          <Box sx={{ flexGrow: 1, display: "contents" }}>
            {stockFilters.barcode !== null && (
              <Button
                onClick={resetFilterBarcode}
                endIcon={<CloseIcon />}
                color="text"
                sx={{
                  mt: 1,
                  mb: 1,
                  pl: 2,
                  textTransform: "capitalize",
                  border: "1px solid",
                  borderRadius: 0,
                  background: "#fff",
                  borderColor: theme.palette.primary.bordersColor,
                }}
              >
                {t("barcode")}: {stockFilters.barcode}
              </Button>
            )}
          </Box>
          <Box sx={{ flexGrow: 1, display: "contents" }}>
            {stockFilters.stockName !== null && (
              <Button
                onClick={resetFilterStockName}
                endIcon={<CloseIcon />}
                color="text"
                sx={{
                  mt: 1,
                  mb: 1,
                  pl: 2,
                  textTransform: "capitalize",
                  border: "1px solid",
                  borderRadius: 0,
                  background: "#fff",
                  borderColor: theme.palette.primary.bordersColor,
                }}
              >
                {t("productName")}: {stockFilters.stockName}
              </Button>
            )}
          </Box>

          <Box sx={{ flexGrow: 1, display: "contents" }}>
            {stockFilters.warehouse.length > 0 && (
              <Button
                onClick={resetFilterWarehouse}
                endIcon={<CloseIcon />}
                color="text"
                sx={{
                  mt: 1,
                  mb: 1,
                  pl: 2,
                  textTransform: "capitalize",
                  border: "1px solid",
                  borderRadius: 0,
                  background: "#fff",
                  borderColor: theme.palette.primary.bordersColor,
                }}
              >
                {t("warehouse")}: {stockFilters.warehouse.length} Adet
              </Button>
            )}
          </Box>

          <Box sx={{ flexGrow: 1, display: "contents" }}>
            {stockFilters.category.length > 0 && (
              <Button
                onClick={resetFilterCategory}
                endIcon={<CloseIcon />}
                color="text"
                sx={{
                  mt: 1,
                  mb: 1,
                  pl: 2,
                  textTransform: "capitalize",
                  border: "1px solid",
                  borderRadius: 0,
                  background: "#fff",
                  borderColor: theme.palette.primary.bordersColor,
                }}
              >
                {t("category")}: {stockFilters.category.length} Adet
              </Button>
            )}
          </Box>
          <Box sx={{ flexGrow: 1, display: "contents" }}>
            {stockFilters.stockAmountStart !== null &&
              stockFilters.stockAmountEnd && (
                <Button
                  onClick={resetFilterStockAmounts}
                  endIcon={<CloseIcon />}
                  color="text"
                  sx={{
                    mt: 1,
                    mb: 1,
                    pl: 2,
                    textTransform: "capitalize",
                    border: "1px solid",
                    borderRadius: 0,
                    background: "#fff",
                    borderColor: theme.palette.primary.bordersColor,
                  }}
                >
                  {t("stockQuantity")}: {stockFilters.stockAmountStart} -{" "}
                  {stockFilters.stockAmountEnd}
                </Button>
              )}
          </Box>
        </Box>
      </Box>
      <Drawer
        anchor="left"
        open={state["left"]}
        onClose={toggleDrawer("left", false)}
      >
        {list("left")}
      </Drawer>
    </Box>
  );
};

export default StockFilter;
