import React, { useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import MenuItem from "@mui/material/MenuItem";
import axios from "axios";
import Cookies from "js-cookie";
import Box from "@mui/material/Box";
import BoxTitle from "./form-elements/BoxTitle";
import FormGroup from "./form-elements/FormGroup";
import CustomSubmitButton from "./form-elements/CustomSubmitButton";
import { useTranslation } from "react-i18next";
import FormControl from "@mui/material/FormControl";

import { alpha, styled } from "@mui/material/styles";
import InputBase from "@mui/material/InputBase";
import { Typography, useTheme } from "@mui/material";

const BootstrapInput = styled(InputBase)(({ theme }) => ({
  "label + &": {
    marginTop: theme.spacing(3),
    color: `${alpha(theme.palette.text.main, 0.25)} 0 0 0 0.2rem`,
  },
  "& .MuiInputBase-input": {
    borderRadius: 2,
    position: "relative",
    border: "1px solid",
    fontSize: 14,
    fontStyle: "italic",
    height: "12px",
    width: "100%",
    padding: "10px 12px",
    marginTop: "0.5rem",
    borderColor: theme.palette.primary.bordersColor,
    transition: theme.transitions.create([
      "border-color",
      "background-color",
      "box-shadow",
    ]),
    "&:focus": {
      boxShadow: `${alpha(theme.palette.primary.main, 0.25)} 0 0 0 0.2rem`,
      borderColor: theme.palette.primary.main,
      color: theme.palette.text.main,
    },
  },
}));

const FbmmCargoForm = ({ order }) => {
  const orderId = useParams().id;
  const [cargoNumber, setCargoNumber] = useState(order.cargoNumber);
  const theme = useTheme();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [responseUpdateStatusInfo, setResponseUpdateStatusInfo] = useState(
    null
  );

  const handleChange = event => {
    setCargoNumber(event.target.value);
  };

  const handleSubmit = event => {
    event.preventDefault();
    const orderInfo = {
      id: orderId,
      cargoNumber: cargoNumber,
    };

    let updateConfig = {
      method: "put",
      maxBodyLength: Infinity,
      url: `${process.env.REACT_APP_BASE_URL}/Order/UpdateOrderCargo/${order.id}`,
      headers: {
        Authorization: `Bearer ${Cookies.get("_auth")}`,
      },
      data: orderInfo,
    };

    const updateOrderInfo = async () => {
      const responseUpdateStatusInfo = await axios.request(updateConfig);
      setResponseUpdateStatusInfo(responseUpdateStatusInfo);
      navigate("/fbmm");
    };
    updateOrderInfo();
  };

  return (
    <Box
      component="form"
      sx={{
        backgroundColor: "#fff",
        p: 2,
        mt: 2,
        "& > :not(style)": { m: 1 },
      }}
      noValidate
      autoComplete="off"
    >
      <FormGroup>
        <BoxTitle title={t("orderShippingNumber")} />
        <FormControl variant="standard">
          <FormControl sx={{ m: 0, flex: 1 }} size="small">
            <BootstrapInput
              // placeholder={placeholder}
              onChange={e => handleChange(e)}
              type="text"
              value={cargoNumber}
            />
          </FormControl>
        </FormControl>
      </FormGroup>

      <CustomSubmitButton buttonText={t("save")} onSubmitEvent={handleSubmit} />
    </Box>
  );
};

export default FbmmCargoForm;
