import React from "react";

const ErrorIcon = () => {
  return (
    <svg
      width="15"
      height="15"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clip-path="url(#clip0_3100_5889)">
        <path
          d="M7.99999 12.3751C7.75838 12.3751 7.5625 12.1792 7.5625 11.9376C7.5625 11.696 7.75838 11.5001 7.99999 11.5001"
          fill="#34AE1A"
        />
        <path
          d="M7.99999 12.3751C7.75838 12.3751 7.5625 12.1792 7.5625 11.9376C7.5625 11.696 7.75838 11.5001 7.99999 11.5001"
          stroke="#D7283E"
          stroke-width="1.6"
        />
        <path
          d="M8 12.3751C8.24161 12.3751 8.4375 12.1792 8.4375 11.9376C8.4375 11.696 8.24161 11.5001 8 11.5001"
          stroke="#D7283E"
          stroke-width="1.6"
        />
        <path
          d="M8 8.87508V5.37512"
          stroke="#D7283E"
          stroke-width="1.6"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M14.7818 12.0053C14.9395 12.3208 15.0138 12.6714 14.998 13.0239C14.9821 13.3762 14.8763 13.7187 14.6908 14.0188C14.5053 14.3187 14.2462 14.5664 13.9381 14.7381C13.6299 14.91 13.2831 15.0001 12.9303 15.0001H3.06976C2.71696 15.0001 2.37011 14.91 2.062 14.7381C1.75389 14.5664 1.49478 14.3187 1.30928 14.0188C1.12378 13.7187 1.01796 13.3762 1.0021 13.0239C0.986232 12.6714 1.06055 12.3208 1.21828 12.0053L6.14855 2.14472C6.3204 1.80079 6.58464 1.51146 6.91166 1.30928C7.23867 1.1071 7.6155 1 8.00003 1C8.38455 1 8.76138 1.1071 9.0884 1.30928C9.41541 1.51146 9.67966 1.80079 9.8515 2.14472L14.7818 12.0053Z"
          stroke="#D7283E"
          stroke-width="1.6"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_3100_5889">
          <rect width="16" height="16" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default ErrorIcon;
