import axios from "axios";
import React, { useEffect, useState, useCallback } from "react";
import Cookies from "js-cookie";
import { useParams } from "react-router-dom";
import { Box, useTheme, Paper, Button, Snackbar, Grid } from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";
import Topbar from "./global/Topbar";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import Checkbox from "@mui/material/Checkbox";
import ListItemText from "@mui/material/ListItemText";
import FormGroup from "../components/forms/form-elements/FormGroup";
import FormControl from "@mui/material/FormControl";
import InputBase from "@mui/material/InputBase";
import { alpha, styled } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import InputLabel from "@mui/material/InputLabel";
import CustomLabel from "../components/forms/form-elements/CustomLabel";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import { set } from "date-fns";
import { useTranslation } from "react-i18next";
const BootstrapSelect = styled(InputBase)(({ theme }) => ({
  "label + &": {
    marginTop: theme.spacing(3),
    color: `${alpha(theme.palette.text.main, 0.25)} 0 0 0 0.2rem`,
  },
  "& .MuiInputBase-input": {
    borderRadius: 2,
    position: "relative",
    border: "1px solid",
    fontSize: 14,
    fontStyle: "italic",
    height: "4px!important",
    width: "100%",
    padding: "10px 12px",
    lineHeight: "1",
    marginTop: "0.5rem",
    borderColor: theme.palette.primary.bordersColor,
    transition: theme.transitions.create([
      "border-color",
      "background-color",
      "box-shadow",
    ]),
    minHeight: " 0.8rem!important",
    "&:focus": {
      boxShadow: `${alpha(theme.palette.primary.main, 0.25)} 0 0 0 0.2rem`,
      borderColor: theme.palette.primary.main,
      color: theme.palette.text.main,
    },
  },
}));

const BootstrapInput = styled(InputBase)(({ theme }) => ({
  "label + &": {
    marginTop: theme.spacing(3),
    color: `${alpha(theme.palette.text.main, 0.25)} 0 0 0 0.2rem`,
  },
  "& .MuiInputBase-input": {
    borderRadius: 2,
    position: "relative",
    border: "1px solid",
    fontSize: 14,
    fontStyle: "italic",
    height: "12px",
    width: "100%",
    padding: "10px 12px",
    marginTop: "0.5rem",
    borderColor: theme.palette.primary.bordersColor,
    transition: theme.transitions.create([
      "border-color",
      "background-color",
      "box-shadow",
    ]),
    "&:focus": {
      boxShadow: `${alpha(theme.palette.primary.main, 0.25)} 0 0 0 0.2rem`,
      borderColor: theme.palette.primary.main,
      color: theme.palette.text.main,
    },
  },
}));

const options = [
  {
    id: "42d05c20-d106-4919-94e7-bfb345b874a3",
    title: "Elektrikli Mutfak Aletleri",
    value: "42d05c20-d106-4919-94e7-bfb345b874a3",
  },
  {
    id: "2a2b4892-7907-44db-8b10-79b03dc1a8b8",
    title: "Elektronik-Bilgisayar",
    value: "2a2b4892-7907-44db-8b10-79b03dc1a8b8",
  },
  {
    id: "33be39bb-7d71-4823-8c48-a04f1d3ef576",
    title: "Ev Tekstili",
    value: "33be39bb-7d71-4823-8c48-a04f1d3ef576",
  },
  {
    id: "86d8e1bb-1e5b-41e2-83d7-dabf1ab54b62",
    title: "Giyim",
    value: "86d8e1bb-1e5b-41e2-83d7-dabf1ab54b62",
  },
  {
    id: "f781c9d2-d352-4524-81c2-152d023c6a1c",
    title: "Gıda Ürünleri ve Takviyeler",
    value: "f781c9d2-d352-4524-81c2-152d023c6a1c",
  },
  {
    id: "48b024ee-9d1e-4d1e-83c9-05acf11ac5df",
    title: "Hızlı Tüketim Ürünleri",
    value: "48b024ee-9d1e-4d1e-83c9-05acf11ac5df",
  },
  {
    id: "bf4980df-c62b-49d3-a17a-bb1c70cbf434",
    title: "Hobiler ve Eğlence Ürünleri",
    value: "bf4980df-c62b-49d3-a17a-bb1c70cbf434",
  },
  {
    id: "d6303117-f198-47f9-af83-4af99458d42d",
    title: "Kozmetik / Sağlık",
    value: "d6303117-f198-47f9-af83-4af99458d42d",
  },
  {
    id: "8c9cb67e-7b4b-49aa-9f39-f8d3af05cfec",
    title: "Makine Parçaları",
    value: "8c9cb67e-7b4b-49aa-9f39-f8d3af05cfec",
  },
  {
    id: "fb841e94-22f6-4512-9f22-fec93b6e8616",
    title: "Mobilya & Dekorasyon",
    value: "fb841e94-22f6-4512-9f22-fec93b6e8616",
  },
  {
    id: "e0b3d64e-042e-4009-9efa-1ffc85c205ef",
    title: "Mücevher ve Aksesuarlar",
    value: "e0b3d64e-042e-4009-9efa-1ffc85c205ef",
  },
  {
    id: "bc29cc49-978f-4dab-bd14-aac14490356d",
    title: "Ofis Malzemeleri ve Kırtasiye",
    value: "bc29cc49-978f-4dab-bd14-aac14490356d",
  },
  {
    id: "b6e70ae3-c6ea-4ebf-82dc-d26b95668ee7",
    title: "Otomotiv",
    value: "b6e70ae3-c6ea-4ebf-82dc-d26b95668ee7",
  },
  {
    id: "ac6039b8-4b55-447e-b968-edfe2ac13448",
    title: "Oyuncak",
    value: "ac6039b8-4b55-447e-b968-edfe2ac13448",
  },
  {
    id: "09432459-b407-4018-8f4f-80da23070a22",
    title: "Pet Shop",
    value: "09432459-b407-4018-8f4f-80da23070a22",
  },
  {
    id: "5ece11ae-a8b1-448e-ae28-6baef40cf6fe",
    title: "Spor & Outdoor",
    value: "5ece11ae-a8b1-448e-ae28-6baef40cf6fe",
  },
  {
    id: "5d78da72-a035-4a69-a564-fb95d4d6bdfd",
    title: "Yapı Market",
    value: "5d78da72-a035-4a69-a564-fb95d4d6bdfd",
  },
  {
    id: "788435f7-2508-412c-8f22-f56946e6dcd2",
    title: "Züccaciye",
    value: "788435f7-2508-412c-8f22-f56946e6dcd2",
  },
  {
    id: "2e39504a-594d-4b41-bcf7-72b653616b06",
    title: "Diğer",
    value: "2e39504a-594d-4b41-bcf7-72b653616b06",
  },
  {
    id: "493fd4bc-7736-48c3-a279-c3a9002b247c",
    title: "Anne Bebek Ürünleri",
    value: "493fd4bc-7736-48c3-a279-c3a9002b247c",
  },
  {
    id: "ef61882f-4e65-49c0-a674-abb5bd06877c",
    title: "Ayakkabı ve Çanta",
    value: "ef61882f-4e65-49c0-a674-abb5bd06877c",
  },
  {
    id: "30766d72-0e74-4a3e-af15-f5cfcd043432",
    title: "Bahçe",
    value: "30766d72-0e74-4a3e-af15-f5cfcd043432",
  },
];

const EditMerchant = () => {
  const merchantID = useParams().id;
  const theme = useTheme();
  const [countryData, setCountryData] = useState([]);
  const [error, setError] = useState(false);
  const { t } = useTranslation();
  const [merchant, setMerchant] = useState({});
  const [loading, setLoading] = useState(false);
  const [point, setPoint] = useState(0);
  const [nameSurname, setNameSurname] = useState("");
  const [email, setEmail] = useState("");
  const [title, setTitle] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [webAddress, setWebAddress] = useState("");
  const [merchantCategories, setMerchantCategories] = useState([]);
  const [merchantTypeId, setMerchantTypeId] = useState("");
  const [merchantOwnerOfBrandId, setMerchantOwnerOfBrandId] = useState("");
  const [merchantProductCountId, setMerchantProductCountId] = useState("");
  const [isEcommerce, setIsEcommerce] = useState(false);
  const [isExport, setIsExport] = useState(false);
  const [isIntegration, setIsIntegration] = useState(false);
  const [merchnantEcommerceUrls, setMerchnantEcommerceUrls] = useState([]);
  const [productOrigins, setProductOrigins] = useState([]);
  const [successMessage, setSuccessMessage] = useState("");
  const [isSnackbarOpen, setSnackbarOpen] = useState(false);
  const handleCloseSnackbar = () => {
    setSnackbarOpen(false);
  };

  let addEcommerceFields = e => {
    e.preventDefault();
    if (e.key === "Enter") {
      return false;
    }
    if (merchnantEcommerceUrls.every(url => url.trim() !== "")) {
      setMerchnantEcommerceUrls([...merchnantEcommerceUrls, ""]);
    }
  };

  let removeEcommerceFields = index => {
    if (merchnantEcommerceUrls.length < 2) {
      return false;
    } else if (merchnantEcommerceUrls.length > 75) {
      // set error message
      return false;
    } else {
      const values = [...merchnantEcommerceUrls];
      values.splice(index, 1);
      setMerchnantEcommerceUrls(values);
    }
  };

  function sortCountryDataWithTurkeyFirst(countryData) {
    const turkeyId = "f30b42ee-edb6-41b8-a819-565e867041d7";
    const sortedCountryData = [...countryData];
    const turkeyIndex = sortedCountryData.findIndex(
      item => item.id === turkeyId
    );

    if (turkeyIndex !== -1) {
      const turkey = sortedCountryData.splice(turkeyIndex, 1)[0];
      sortedCountryData.unshift(turkey);
    }

    return sortedCountryData;
  }

  let data = "";
  let config = {
    method: "get",
    maxBodyLength: Infinity,
    url: `${process.env.REACT_APP_BASE_URL}/Merchant/${merchantID}`,
    headers: {
      Authorization: `Bearer ${Cookies.get("_auth")}`,
    },
    data: data,
  };

  const fetchMerchantData = useCallback(async () => {
    try {
      const response = await axios(config);
      setPoint(response.data.merchantInfo.point || 0);
      setMerchant(response.data.merchantInfo);
      setNameSurname(response.data.merchantInfo.users[0].nameSurname);
      setEmail(response.data.merchantInfo.users[0].email);
      setTitle(response.data.merchantInfo.title);
      setPhoneNumber(response.data.merchantInfo.phoneNumber);
      setWebAddress(response.data.merchantInfo.webAddress);
      setMerchantCategories(
        response.data.merchantInfo.merchantCategories.map(c => c.id)
      );
      setMerchantTypeId(response.data.merchantInfo.merchantTypeId);
      setMerchantOwnerOfBrandId(
        response.data.merchantInfo.merchantOwnerOfBrandId
      );
      setMerchantProductCountId(
        response.data.merchantInfo.merchantProductCountId
      );
      setIsEcommerce(response.data.merchantInfo.isEcommerce);
      setIsExport(response.data.merchantInfo.isExport);
      setIsIntegration(response.data.merchantInfo.isIntegration);
      setMerchnantEcommerceUrls(
        response.data.merchantInfo.merchnantEcommerceUrls
      );
      setProductOrigins(response.data.merchantInfo.productOrigins);
      setLoading(false);
    } catch (error) {
      setError(true);
    }
  }, []);

  const getCountryData = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BASE_URL}/Country?Pagination.Page=0&Pagination.Size=100`
      );
      setCountryData(response.data.items);
    } catch (error) {}
  };

  useEffect(() => {
    setLoading(true);
    fetchMerchantData();
    getCountryData();
  }, [fetchMerchantData]);

  const handleCategoryChange = event => {
    setMerchantCategories(event.target.value);
  };

  const handleProductOriginChange = event => {
    const selectedCountryIds = event.target.value; // Seçilen ülke ID'lerini al
    const updatedProductOrigins = selectedCountryIds.map(id => ({
      country: countryData.find(country => country.id === id), // ID'ye göre
    }));
    setProductOrigins(updatedProductOrigins); // Ülke seçimlerini güncelle
  };

  let updateConfig = {
    method: "put",
    maxBodyLength: Infinity,
    url: `${process.env.REACT_APP_BASE_URL}/Merchant/UpdateMerchant/${merchantID}`,
    headers: {
      Authorization: `Bearer ${Cookies.get("_auth")}`,
    },
    data: {
      id: merchantID,
      point: Number(point),
      nameSurname: nameSurname,
      email: email,
      title: title,
      phoneNumber: phoneNumber,
      webAddress: webAddress,
      merchantCategoryList: merchantCategories,
      merchantTypeId: merchantTypeId,
      merchantOwnerOfBrandId: merchantOwnerOfBrandId,
      merchantProductCountId: merchantProductCountId,
      isEcommerce: Boolean(isEcommerce),
      isExport: Boolean(isExport),
      isIntegration: Boolean(isIntegration),
      merchnantEcommerceUrlList: merchnantEcommerceUrls,
      productOriginList: productOrigins.map(origin => origin.country.id),
    },
  };

  const handleSubmit = async event => {
    event.preventDefault();
    try {
      const response = await axios.request(updateConfig);
      setSuccessMessage(t("theRecordHasBeenUpdatedSuccessfully"));
      setSnackbarOpen(true);
    } catch (error) {
      setSuccessMessage(t("anErrorOccurredWhileUpdatingTheRecord"));
      setSnackbarOpen(true);
    }
  };

  if (loading)
    return (
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "90vh",
        }}
      >
        <CircularProgress />
      </Box>
    );

  if (error) return <div>Error...</div>;

  return (
    <Box m={"20px"}>
      <Topbar title="Kayıt Detayı"></Topbar>
      <Button onClick={() => window.history.back()} className="filterApply">
        {t("goBack")}
      </Button>
      <Box sx={{ maxWidth: "700px", mt: 1, background: "#fff" }}>
        <FormGroup>
          <form onSubmit={handleSubmit}>
            <Grid container spacing={2} sx={{ mb: 1 }}>
              <Grid item xs={12}>
                <FormControl
                  variant="standard"
                  key={"title"}
                  className="signup-fc"
                >
                  <Typography
                    sx={{
                      fontSize: "13px",
                      fontWeight: "500",
                      color: theme.palette.text.main,
                      mt: "4px",
                    }}
                  >
                    {t("score")}
                  </Typography>
                  <BootstrapInput
                    placeholder={t("companyName")}
                    value={point}
                    onChange={e => setPoint(e.target.value)}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12}>
                <FormControl
                  variant="standard"
                  key={"title"}
                  className="signup-fc"
                >
                  <Typography
                    sx={{
                      fontSize: "13px",
                      fontWeight: "500",
                      color: theme.palette.text.main,
                      mt: "4px",
                    }}
                  >
                    {t("companyName")}
                  </Typography>
                  <BootstrapInput
                    placeholder={t("score")}
                    value={title}
                    onChange={e => setTitle(e.target.value)}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12}>
                <FormControl
                  variant="standard"
                  key={"fullName"}
                  className="signup-fc"
                >
                  <Typography
                    sx={{
                      fontSize: "13px",
                      fontWeight: "500",
                      color: theme.palette.text.main,
                      mt: "4px",
                    }}
                  >
                    {t("nameSurname")}
                  </Typography>
                  <BootstrapInput
                    placeholder={t("nameSurname")}
                    value={nameSurname}
                    onChange={e => setNameSurname(e.target.value)}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12}>
                <FormControl
                  variant="standard"
                  key={"email"}
                  className="signup-fc"
                >
                  <Typography
                    sx={{
                      fontSize: "13px",
                      fontWeight: "500",
                      color: theme.palette.text.main,
                      mt: "4px",
                    }}
                  >
                    {t("email")}
                  </Typography>
                  <BootstrapInput
                    placeholder= {t("email")}
                    value={email}
                    onChange={e => setEmail(e.target.value)}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12}>
                <FormControl
                  variant="standard"
                  key={"phoneNumber"}
                  className="signup-fc"
                >
                  <BootstrapInput
                    placeholder={t("phoneNumber")}
                    value={phoneNumber}
                    onChange={e => setPhoneNumber(e.target.value)}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12}>
                <FormControl
                  variant="standard"
                  key={"webAddress"}
                  className="signup-fc"
                >
                  <BootstrapInput
                    placeholder={t("webAddress")}
                    value={webAddress}
                    onChange={e => setWebAddress(e.target.value)}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12}>
                <FormControl className="signup-fc">
                  <Typography
                    sx={{
                      fontSize: "13px",
                      fontWeight: "500",
                      color: theme.palette.text.main,
                      mt: "4px",
                    }}
                  >
                    {t("theCategory")} 
                  </Typography>
                  <Select
                    labelId="demo-multiple-checkbox-label"
                    id="demo-multiple-checkbox"
                    multiple
                    displayEmpty
                    value={merchantCategories}
                    onChange={handleCategoryChange}
                    input={<BootstrapSelect />}
                    renderValue={selected => (
                      <div>
                        {selected.length === 0 ? (
                          <em
                            style={{ color: "currentColor", opacity: "0.42" }}
                          >
                            {t("selectCategory")}
                          </em>
                        ) : (
                          selected.map((id, index) => {
                            const selectedItem = options.find(
                              item => item.id === id
                            );
                            return (
                              <span key={index}>
                                {selectedItem && (
                                  <span>
                                    {selectedItem.title}
                                    {index !== selected.length - 1 ? ", " : ""}
                                  </span>
                                )}
                              </span>
                            );
                          })
                        )}
                      </div>
                    )}
                  >
                    {options.map(opt => (
                      <MenuItem
                        key={opt.value}
                        value={opt.value}
                        sx={{
                          paddignTop: "0px!important",
                          paddingBottom: "0px!important",
                        }}
                      >
                        <Checkbox
                          size="small"
                          checked={merchantCategories.includes(opt.value)}
                        />
                        <ListItemText primary={opt.title} />
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12}>
                <FormControl
                  variant="standard"
                  key={"productOrigin"}
                  className="signup-fc"
                  sx={{ mt: 1 }}
                >
                  <Typography
                    sx={{
                      fontSize: "13px",
                      fontWeight: "500",
                      color: theme.palette.text.main,
                      mt: "4px",
                    }}
                  >
                    {t("originOfProducts")}
                  </Typography>
                  <Select
                    labelId="demo-multiple-2-checkbox-label"
                    id="demo-multiple-2-checkbox"
                    required
                    displayEmpty
                    multiple
                    value={productOrigins.map(origin => origin.country.id)}
                    input={<BootstrapSelect />}
                    onChange={handleProductOriginChange}
                    renderValue={selected => (
                      <div>
                        {selected.length === 0 ? (
                          <em
                            style={{ color: "currentColor", opacity: "0.42" }}
                          >
                            {t("selectProductOrigin")}
                          </em>
                        ) : (
                          selected.map((id, index) => {
                            const selectedCountry = countryData.find(
                              country => country.id === id
                            );
                            if (selectedCountry) {
                              return (
                                <span key={selectedCountry.id}>
                                  {selectedCountry.title}{" "}
                                  {index !== selected.length - 1 ? ", " : ""}
                                </span>
                              );
                            } else {
                              return null;
                            }
                          })
                        )}
                      </div>
                    )}
                  >
                    {countryData.map(country => (
                      <MenuItem
                        key={country.id}
                        value={country.id}
                        sx={{
                          paddignTop: "0px!important",
                          paddingBottom: "0px!important",
                        }}
                      >
                        <Checkbox
                          size="small"
                          checked={productOrigins.some(
                            origin => origin.country.id === country.id
                          )}
                        />
                        <ListItemText primary={country.title} />
                      </MenuItem>
                    ))}
                  </Select>

                  {/* <Select
                    labelId="demo-multiple-2-checkbox-label"
                    id="demo-multiple-2-checkbox"
                    required
                    displayEmpty
                    multiple
                    value={productOrigin}
                    input={<BootstrapSelect />}
                    onChange={handleProductOriginChange}
                    renderValue={selected => (
                      <div>
                        {selected.length === 0 ? (
                          <em
                            style={{ color: "currentColor", opacity: "0.42" }}
                          >
                            Ürün Menşei Seçiniz
                          </em>
                        ) : (
                          selected.map((id, index) => {
                            const selectedCountry = countryData.find(
                              country => country.id === id
                            );
                            if (selectedCountry) {
                              return (
                                <span key={selectedCountry.id}>
                                  {selectedCountry.title}{" "}
                                  {index !== selected.length - 1 ? ", " : ""}
                                </span>
                              );
                            } else {
                              return null;
                            }
                          })
                        )}
                      </div>
                    )}
                  >
                    {countryData.map(country => (
                      <MenuItem
                        key={country.id}
                        value={country.id}
                        sx={{
                          paddignTop: "0px!important",
                          paddingBottom: "0px!important",
                        }}
                      >
                        <Checkbox
                          size="small"
                          checked={productOrigin.indexOf(country.id) > -1}
                          onChange={handleProductOriginChange(country.id)}
                        />
                        <ListItemText primary={country.title} />
                      </MenuItem>
                    ))}
                  </Select> */}
                </FormControl>
              </Grid>
              <Grid item xs={12}>
                <FormControl
                  sx={{ mt: 1 }}
                  variant="standard"
                  key="merchantType"
                >
                  <CustomLabel title={t("numberOfProducts")} isRequired />
                  <RadioGroup
                    sx={{ pt: "35px", ml: "10px" }}
                    row
                    aria-labelledby="demo-row-radio-buttons-group-label"
                    name="row-radio-buttons-group"
                    value={merchantTypeId}
                    onChange={e => setMerchantTypeId(e.target.value)}
                  >
                    <FormControlLabel
                      color="success"
                      value={"2b355710-3b9e-43e5-a394-40ef09b85a86"}
                      control={
                        <Radio
                          sx={{
                            color: "#45383426",
                            "&.Mui-checked": {
                              color: "#34AE1A",
                            },
                          }}
                        />
                      }
                      className={
                        merchantTypeId ===
                        "2b355710-3b9e-43e5-a394-40ef09b85a86"
                          ? "custom-radio-checked"
                          : "custom-radio"
                      }
                      label={
                        <Typography
                          fontWeight="500"
                          color={
                            merchantTypeId ===
                            "2b355710-3b9e-43e5-a394-40ef09b85a86"
                              ? theme.palette.success.main
                              : "#453834"
                          }
                          fontSize="14px"
                        >
                          {t("producer")}
                        </Typography>
                      }
                    />
                    <FormControlLabel
                      value={"3ed05c84-dbac-4d05-a9b5-494c006baeca"}
                      control={
                        <Radio
                          sx={{
                            color: "#45383426",
                            "&.Mui-checked": {
                              color: "#34AE1A",
                            },
                          }}
                        />
                      }
                      label={
                        <Typography
                          fontWeight="500"
                          color={
                            merchantTypeId ===
                            "3ed05c84-dbac-4d05-a9b5-494c006baeca"
                              ? theme.palette.success.main
                              : "#453834"
                          }
                          fontSize="14px"
                        >
                          {t("distributor")}
                        </Typography>
                      }
                      className={
                        merchantTypeId ===
                        "3ed05c84-dbac-4d05-a9b5-494c006baeca"
                          ? "custom-radio-checked"
                          : "custom-radio"
                      }
                    />
                    <FormControlLabel
                      value={"0184a67c-b078-44e1-93a9-fb7cada8eb1f"}
                      control={
                        <Radio
                          sx={{
                            color: "#45383426",
                            "&.Mui-checked": {
                              color: "#34AE1A",
                            },
                          }}
                        />
                      }
                      label={
                        <Typography
                          fontWeight="500"
                          color={
                            merchantTypeId ===
                            "0184a67c-b078-44e1-93a9-fb7cada8eb1f"
                              ? theme.palette.success.main
                              : "#453834"
                          }
                          fontSize="14px"
                        >
                          {t("none")}
                        </Typography>
                      }
                      className={
                        merchantTypeId ===
                        "0184a67c-b078-44e1-93a9-fb7cada8eb1f"
                          ? "custom-radio-checked"
                          : "custom-radio"
                      }
                    />
                  </RadioGroup>
                </FormControl>
              </Grid>
              <Grid item xs={12}>
                <FormControl sx={{ mt: 1 }} variant="standard" key="ourBrand">
                  <CustomLabel title="Marka Sahipliğiniz" isRequired />
                  <RadioGroup
                    sx={{ pt: "35px", ml: "10px" }}
                    row
                    aria-labelledby="demo-row-radio-buttons-group-label"
                    name="row-radio-buttons-group"
                    value={merchantOwnerOfBrandId}
                    onChange={e => setMerchantOwnerOfBrandId(e.target.value)}
                  >
                    <FormControlLabel
                      value={"54e45b3b-7092-4804-99e5-763f84b96c01"}
                      control={
                        <Radio
                          sx={{
                            color: "#45383426",
                            "&.Mui-checked": {
                              color: "#34AE1A",
                            },
                          }}
                        />
                      }
                      className={
                        merchantOwnerOfBrandId ===
                        "54e45b3b-7092-4804-99e5-763f84b96c01"
                          ? "custom-radio-checked"
                          : "custom-radio"
                      }
                      label={
                        <Typography
                          fontWeight="500"
                          color={
                            merchantOwnerOfBrandId ===
                            "54e45b3b-7092-4804-99e5-763f84b96c01"
                              ? theme.palette.success.main
                              : "#453834"
                          }
                          fontSize="14px"
                        >
                          {t("registered")}
                        </Typography>
                      }
                    />
                    <FormControlLabel
                      value={"91ed6ce2-5b46-4d14-9a6e-53f687963ede"}
                      className={
                        merchantOwnerOfBrandId ===
                        "91ed6ce2-5b46-4d14-9a6e-53f687963ede"
                          ? "custom-radio-checked"
                          : "custom-radio"
                      }
                      control={
                        <Radio
                          sx={{
                            color: "#45383426",
                            "&.Mui-checked": {
                              color: "#34AE1A",
                            },
                          }}
                        />
                      }
                      label={
                        <Typography
                          fontWeight="500"
                          color={
                            merchantOwnerOfBrandId ===
                            "91ed6ce2-5b46-4d14-9a6e-53f687963ede"
                              ? theme.palette.success.main
                              : "#453834"
                          }
                          fontSize="14px"
                        >
                          {t("inApplication")}
                        </Typography>
                      }
                    />
                    <FormControlLabel
                      value={"203bcd82-2402-4a14-9932-45b8ac421ff3"}
                      className={
                        merchantOwnerOfBrandId ===
                        "203bcd82-2402-4a14-9932-45b8ac421ff3"
                          ? "custom-radio-checked"
                          : "custom-radio"
                      }
                      control={
                        <Radio
                          sx={{
                            color: "#45383426",
                            "&.Mui-checked": {
                              color: "#34AE1A",
                            },
                          }}
                        />
                      }
                      label={
                        <Typography
                          fontWeight="500"
                          color={
                            merchantOwnerOfBrandId ===
                            "03bcd82-2402-4a14-9932-45b8ac421ff3"
                              ? theme.palette.success.main
                              : "#453834"
                          }
                          fontSize="14px"
                        >
                          {t("none2")}
                        </Typography>
                      }
                    />
                  </RadioGroup>
                </FormControl>
              </Grid>
              <Grid item xs={12}>
                <FormControl variant="standard" key="ourBrand">
                  <CustomLabel title="Ürün Sayısı" isRequired />
                  <RadioGroup
                    sx={{ pt: "35px", ml: "10px" }}
                    row
                    aria-labelledby="demo-row-radio-buttons-group-label"
                    name="row-radio-buttons-group"
                    value={merchantProductCountId}
                    onChange={e => setMerchantProductCountId(e.target.value)}
                  >
                    <FormControlLabel
                      color="success"
                      className={
                        merchantProductCountId ===
                        "608cc678-ce70-44c3-ab68-475df4de7602"
                          ? "custom-radio-checked"
                          : "custom-radio"
                      }
                      value={"608cc678-ce70-44c3-ab68-475df4de7602"}
                      control={
                        <Radio
                          sx={{
                            color: "#45383426",
                            "&.Mui-checked": {
                              color: "#34AE1A",
                            },
                          }}
                        />
                      }
                      label={
                        <Typography
                          fontWeight="500"
                          color={
                            merchantProductCountId ===
                            "608cc678-ce70-44c3-ab68-475df4de7602"
                              ? theme.palette.success.main
                              : "#453834"
                          }
                          fontSize="14px"
                        >
                          1-10
                        </Typography>
                      }
                    />
                    <FormControlLabel
                      className={
                        merchantProductCountId ===
                        "2273b998-14b2-4ec4-9514-f8c3ec2c29c5"
                          ? "custom-radio-checked"
                          : "custom-radio"
                      }
                      value={"2273b998-14b2-4ec4-9514-f8c3ec2c29c5"}
                      control={
                        <Radio
                          sx={{
                            color: "#45383426",
                            "&.Mui-checked": {
                              color: "#34AE1A",
                            },
                          }}
                        />
                      }
                      label={
                        <Typography
                          fontWeight="500"
                          color={
                            merchantProductCountId ===
                            "2273b998-14b2-4ec4-9514-f8c3ec2c29c5"
                              ? theme.palette.success.main
                              : "#453834"
                          }
                          fontSize="14px"
                        >
                          10-100
                        </Typography>
                      }
                    />
                    <FormControlLabel
                      className={
                        merchantProductCountId ===
                        "b6bfe65b-8889-4de3-a162-7250c34027d3"
                          ? "custom-radio-checked"
                          : "custom-radio"
                      }
                      value={"b6bfe65b-8889-4de3-a162-7250c34027d3"}
                      control={
                        <Radio
                          sx={{
                            color: "#45383426",
                            "&.Mui-checked": {
                              color: "#34AE1A",
                            },
                          }}
                        />
                      }
                      label={
                        <Typography
                          fontWeight="500"
                          color={
                            merchantProductCountId ===
                            "b6bfe65b-8889-4de3-a162-7250c34027d3"
                              ? theme.palette.success.main
                              : "#453834"
                          }
                          fontSize="14px"
                        >
                          100-1000
                        </Typography>
                      }
                    />
                    <FormControlLabel
                      className={
                        merchantProductCountId ===
                        "4eef6c70-2d34-4b82-94c9-4d3d171ef4e7"
                          ? "custom-radio-checked"
                          : "custom-radio"
                      }
                      value={"4eef6c70-2d34-4b82-94c9-4d3d171ef4e7"}
                      control={
                        <Radio
                          sx={{
                            color: "#45383426",
                            "&.Mui-checked": {
                              color: "#34AE1A",
                            },
                          }}
                        />
                      }
                      label={
                        <Typography
                          fontWeight="500"
                          color={
                            merchantProductCountId ===
                            "4eef6c70-2d34-4b82-94c9-4d3d171ef4e7"
                              ? theme.palette.success.main
                              : "#453834"
                          }
                          fontSize="14px"
                        >
                          1000+
                        </Typography>
                      }
                    />
                  </RadioGroup>
                </FormControl>
              </Grid>
              <Grid item xs={12} className="last-grid-signup">
                <Grid container>
                  <Grid item xs={12} sm={4}>
                    <FormControl variant="standard" key="isEcommerce">
                      <CustomLabel
                        title={t("doYouDoECommerce")}
                        isRequired
                      />
                      <RadioGroup
                        sx={{ pt: "35px", ml: "10px" }}
                        row
                        aria-labelledby="demo-row-radio-buttons-group-label"
                        name="row-radio-buttons-group"
                        value={isEcommerce}
                        onChange={() => setIsEcommerce(!isEcommerce)}
                      >
                        <FormControlLabel
                          value={true}
                          control={
                            <Radio
                              sx={{
                                color: "#45383426",
                                "&.Mui-checked": {
                                  color: "#34AE1A",
                                },
                              }}
                            />
                          }
                          label={
                            <Typography
                              fontWeight="500"
                              color={
                                isEcommerce === true
                                  ? theme.palette.success.main
                                  : "#453834"
                              }
                              fontSize="14px"
                            >
                              {t("yes")}
                            </Typography>
                          }
                          className={
                            isEcommerce === true
                              ? "custom-radio-checked"
                              : "custom-radio"
                          }
                        />
                        <FormControlLabel
                          value={false}
                          control={
                            <Radio
                              sx={{
                                color: "#45383426",
                                "&.Mui-checked": {
                                  color: "#34AE1A",
                                },
                              }}
                            />
                          }
                          label={
                            <Typography
                              fontWeight="500"
                              color={
                                isEcommerce === false
                                  ? theme.palette.success.main
                                  : "#453834"
                              }
                              fontSize="14px"
                            >
                              {t("no")}
                            </Typography>
                          }
                          className={
                            isEcommerce === false
                              ? "custom-radio-checked"
                              : "custom-radio"
                          }
                        />
                      </RadioGroup>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} sm={4}>
                    <FormControl variant="standard" key="isExport">
                      <CustomLabel
                        title={t("doYouExport")}
                        isRequired
                      />
                      <RadioGroup
                        sx={{ pt: "35px", ml: "10px" }}
                        row
                        aria-labelledby="demo-row-radio-buttons-group-label"
                        name="row-radio-buttons-group"
                        value={isExport}
                        onChange={e => setIsExport(Boolean(!isExport))}
                      >
                        <FormControlLabel
                          value={true}
                          control={
                            <Radio
                              sx={{
                                color: "#45383426",
                                "&.Mui-checked": {
                                  color: "#34AE1A",
                                },
                              }}
                            />
                          }
                          label={
                            <Typography
                              fontWeight="500"
                              color={
                                isExport === true
                                  ? theme.palette.success.main
                                  : "#453834"
                              }
                              fontSize="14px"
                            >
                              {t("yes")}
                            </Typography>
                          }
                          className={
                            isExport === true
                              ? "custom-radio-checked"
                              : "custom-radio"
                          }
                        />
                        <FormControlLabel
                          value={false}
                          control={
                            <Radio
                              sx={{
                                color: "#45383426",
                                "&.Mui-checked": {
                                  color: "#34AE1A",
                                },
                              }}
                            />
                          }
                          label={
                            <Typography
                              fontWeight="500"
                              color={
                                isExport === false
                                  ? theme.palette.success.main
                                  : "#453834"
                              }
                              fontSize="14px"
                            >
                              {t("no")}
                            </Typography>
                          }
                          className={
                            isExport === false
                              ? "custom-radio-checked"
                              : "custom-radio"
                          }
                        />
                      </RadioGroup>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} sm={4}>
                    <FormControl variant="standard" key="ourBrand">
                      <CustomLabel title={t("integrationUsage")} isRequired />
                      <RadioGroup
                        sx={{ pt: "35px", ml: "10px" }}
                        row
                        aria-labelledby="demo-row-radio-buttons-group-label"
                        name="row-radio-buttons-group"
                        value={isIntegration}
                        onChange={e =>
                          setIsIntegration(Boolean(!isIntegration))
                        }
                      >
                        <FormControlLabel
                          value={true}
                          control={
                            <Radio
                              sx={{
                                color: "#45383426",
                                "&.Mui-checked": {
                                  color: "#34AE1A",
                                },
                              }}
                            />
                          }
                          label={
                            <Typography
                              fontWeight="500"
                              color={
                                isIntegration === true
                                  ? theme.palette.success.main
                                  : "#453834"
                              }
                              fontSize="14px"
                            >
                              {t("yes")}
                            </Typography>
                          }
                          className={
                            isIntegration === true
                              ? "custom-radio-checked"
                              : "custom-radio"
                          }
                        />
                        <FormControlLabel
                          value={false}
                          control={
                            <Radio
                              sx={{
                                color: "#45383426",
                                "&.Mui-checked": {
                                  color: "#34AE1A",
                                },
                              }}
                            />
                          }
                          label={
                            <Typography
                              fontWeight="500"
                              color={
                                isIntegration === false
                                  ? theme.palette.success.main
                                  : "#453834"
                              }
                              fontSize="14px"
                            >
                               {t("no")}
                            </Typography>
                          }
                          className={
                            isIntegration === false
                              ? "custom-radio-checked"
                              : "custom-radio"
                          }
                        />
                      </RadioGroup>
                    </FormControl>
                  </Grid>
                  {isEcommerce && (
                    <Grid item xs={12} sm={6}>
                      <Typography
                        sx={{
                          fontSize: "13px",
                          fontWeight: "500",
                          color: theme.palette.text.main,
                        }}
                      >
                        {t("eCommerceStoreLinks")}
                      </Typography>

                      {merchnantEcommerceUrls.map((ecommerceUrl, index) => (
                        <FormControl
                          variant="standard"
                          key={`eCommerceLinks-${index}}`}
                          className="signup-fc"
                        >
                          <div
                            key={`ecommerceUrl-${index}`}
                            className="ecommerce-url-div"
                          >
                            <BootstrapInput
                              className="signup-input"
                              placeholder={t("eCommerceStoreLink")}
                              value={ecommerceUrl.title}
                              onChange={e => {
                                let values = [...merchnantEcommerceUrls];
                                values[index] = e.target.value;
                                setMerchnantEcommerceUrls(values);
                              }}
                            />
                            {index !== 0 && (
                              <IconButton
                                aria-label="delete"
                                onClick={() => removeEcommerceFields(index)}
                              >
                                <CloseIcon />
                              </IconButton>
                            )}
                          </div>
                        </FormControl>
                      ))}

                      <Button
                        onClick={addEcommerceFields}
                        size="small"
                        sx={{
                          textTransform: "capitalize",
                          fontSize: "12px",
                        }}
                        color="secondary"
                      >
                        +{t("addLink")}
                      </Button>
                    </Grid>
                  )}
                </Grid>
              </Grid>
            </Grid>
            <Button
              type="submit"
              className="filterApply"
              variant="contained"
              color="primary"
              sx={{ mt: 2 }}
            >
              {t("save")}
            </Button>
          </form>
        </FormGroup>
      </Box>
      <Snackbar
        open={isSnackbarOpen}
        autoHideDuration={6000}
        onClose={handleCloseSnackbar}
        message={successMessage}
      />
    </Box>
  );
};

export default EditMerchant;
