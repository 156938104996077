import React from "react";

const NewNotificationIcon = () => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8.40039 17.7998C8.502 18.146 8.713 18.4499 9.00179 18.6661C9.29058 18.8823 9.64163 18.9991 10.0024 18.9991C10.3631 18.9991 10.7142 18.8823 11.003 18.6661C11.2918 18.4499 11.5028 18.146 11.6044 17.7998"
        stroke="#453834"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10 2.8V1"
        stroke="#453834"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9.99979 2.7998C11.5911 2.7998 13.1172 3.43194 14.2424 4.55716C15.3676 5.68238 15.9998 7.2085 15.9998 8.79979C15.9998 14.4366 17.1998 15.3998 17.1998 15.3998H2.7998C2.7998 15.3998 3.9998 13.867 3.9998 8.79979C3.9998 7.2085 4.63194 5.68238 5.75716 4.55716C6.88237 3.43194 8.40849 2.7998 9.99979 2.7998V2.7998Z"
        stroke="#453834"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default NewNotificationIcon;
