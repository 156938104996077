import React from "react";
import { TextField, Box, Typography, Grid, Input } from "@mui/material";

import { styled } from "@mui/system";
import { useTranslation } from "react-i18next";

const Label = styled(Typography)({
  fontWeight: 500,
  fontSize: "14px",
  lineHeight: "16px",
  marginBottom: "6px",
  width: "fit-content",
});

const StyledTextField = styled(TextField)({
  height: "32px",
  mxWidth: "279px",
  width: "100%",
  "& .MuiInputBase-root": {
    height: "32px",
  },
  "& input": {
    padding: "8px 12px",
  },
});

const AddressInfo = ({ paymentDetailData, setPaymentDetailData }) => {
  const { t } = useTranslation();

  const onFormDataChanged = (name, value) => {
    setPaymentDetailData((prevState) => ({
      ...prevState,
      [name]: value.target.value,
    }));
  };

  return (
    <Box
      component="form"
      noValidate
      autoComplete="off"
      border="1px solid #45383426"
      padding="12px"
      borderRadius="2px"
      marginBottom="12px"
    >
      <Typography
        variant="subtitle1"
        gutterBottom
        color="primary"
        fontWeight="700"
        fontSize="14px"
        marginBottom="12px"
        paddingBottom="7px"
        borderBottom={"1px solid  #D9D9D9"}
      >
        {t("addressInformations")}
      </Typography>
      <Grid container spacing={2} xs={8} md={12}>
        <Grid item xs={10}>
          <Box>
            <Label>
              {t("billingAddress")}
              <span style={{ color: "#D7283E" }}> *</span>
            </Label>
            <StyledTextField
              onChange={(e) => onFormDataChanged("address", e)}
              value={paymentDetailData.address}
              required
              placeholder="Cumhuriyet Mah. Atatürk Cad. No:123 D:5, 34000, Şişli/İstanbul, Türkiye"
              variant="outlined"
            />
          </Box>
        </Grid>
        <Grid item xs={5}>
          <Label>
            {t("country")}
            <span style={{ color: "#D7283E" }}> *</span>
          </Label>
          <StyledTextField
            onChange={(e) => onFormDataChanged("country", e)}
            value={paymentDetailData.country}
            required
            placeholder="Türkiye"
            variant="outlined"
          />
        </Grid>
        <Grid item xs={5}>
          <Label>
            {t("city")}
            <span style={{ color: "#D7283E" }}> *</span>
          </Label>
          <StyledTextField
            onChange={(e) => onFormDataChanged("city", e)}
            value={paymentDetailData.city}
            required
            placeholder="Istanbul"
            variant="outlined"
          />
        </Grid>
      </Grid>
    </Box>
  );
};

export default AddressInfo;
