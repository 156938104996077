import React from "react";

const ContractIcon = () => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_1151_4394)">
        <path
          d="M5.90445 15.7496L1.60171 11.4477C1.49021 11.3362 1.40176 11.2039 1.34141 11.0584C1.28106 10.9127 1.25 10.7566 1.25 10.599C1.25 10.4414 1.28106 10.2852 1.34141 10.1397C1.40176 9.99399 1.49021 9.86168 1.60171 9.75026L10.0008 1.35116C10.1123 1.23976 10.2447 1.15141 10.3902 1.09116C10.5359 1.0309 10.692 0.999928 10.8495 1H15.1515C15.4697 1 15.7749 1.12641 15.9999 1.35143C16.2249 1.57646 16.3514 1.88165 16.3514 2.19987V6.50262"
          stroke="#453834"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M13.3507 4.29964C13.1851 4.29964 13.0508 4.16535 13.0508 3.99968C13.0508 3.83401 13.1851 3.69971 13.3507 3.69971"
          stroke="#453834"
          strokeWidth="1.5"
        />
        <path
          d="M13.3516 4.29964C13.5172 4.29964 13.6515 4.16535 13.6515 3.99968C13.6515 3.83401 13.5172 3.69971 13.3516 3.69971"
          stroke="#453834"
          strokeWidth="1.5"
        />
        <path
          d="M18.2562 12.2944L12.152 18.3994L9.15234 18.9993L9.75228 15.9996L15.8564 9.89549C16.1737 9.57825 16.604 9.40002 17.0527 9.40002C17.5014 9.40002 17.9317 9.57825 18.249 9.89549L18.2562 9.90269C18.5732 10.2199 18.7512 10.6501 18.7512 11.0986C18.7512 11.5471 18.5732 11.9772 18.2562 12.2944Z"
          stroke="#453834"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_1151_4394">
          <rect width="20" height="20" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default ContractIcon;
