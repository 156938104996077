import React, { useContext, useEffect } from "react";
import Button from "@mui/material/Button";
import Cookies from "js-cookie";
import axios from "axios";
import Xls from "../icons/Xls";
import { Box, CircularProgress } from "@mui/material";
import { StockFilterContext } from "../../context/StockFilterContext";
import Snackbar from "@mui/material/Snackbar";
import Alert from "@mui/material/Alert";
import { useTranslation } from "react-i18next";

function CircularIndeterminate() {
  return (
    <Box sx={{ display: "flex" }}>
      <CircularProgress
        sx={{
          color: "#282828",
          width: "14px",
          height: "14px",
          marginRight: "5px",
        }}
        color="inherit"
        size={20}
        thickness={5}
      />
    </Box>
  );
}
// :()
const DownloadStockExcel = () => {
  const [isDownloading, setIsDownloading] = React.useState(false);
  const { stockFilters } = useContext(StockFilterContext);
  const [snackbarMessage, setSnackbarMessage] = React.useState("");
  const [open, setOpen] = React.useState(false);
  const { t } = useTranslation();

  const handleClose = () => {
    setOpen(false);
  };

  let config = {
    method: "get",
    url: `${process.env.REACT_APP_BASE_URL}/ProductVariant/ExporttoExcelAllStocks?Pagination.Page=0&Pagination.Size=10000`,
    responseType: "blob",
    headers: {
      Authorization: `Bearer ${Cookies.get("_auth")}`,
      "Content-Type":
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    },
  };
  useEffect(() => {
    if (stockFilters) {
      if (stockFilters.sku) {
        config.url = config.url + "&Sku=" + stockFilters.sku;
      }
      if (stockFilters.barcode) {
        config.url = config.url + "&Barcode=" + stockFilters.barcode;
      }
      if (stockFilters.status) {
        config.url = config.url + "&Status=" + stockFilters.status;
      }
      if (stockFilters.category) {
        config.url = config.url + "&Category=" + stockFilters.category;
      }
      if (stockFilters.warehouse) {
        config.url = config.url + "&Warehouse=" + stockFilters.warehouse;
      }
      if (stockFilters.stockName) {
        config.url = config.url + "&query=" + stockFilters.stockName;
      }
      if (stockFilters.search) {
        config.url = config.url + "&query=" + stockFilters.search;
      }
      if (stockFilters.stockAmountStart) {
        config.url =
          config.url + "&StockAmountStart=" + stockFilters.stockAmountStart;
      }
      if (stockFilters.stockAmountEnd) {
        config.url =
          config.url + "&StockAmountEnd=" + stockFilters.stockAmountEnd;
      }
    }
  }, [stockFilters]);
  const handleDownloadClick = async () => {
    if (stockFilters) {
      if (stockFilters.sku) {
        config.url = config.url + "&Sku=" + stockFilters.sku;
      }
      if (stockFilters.barcode) {
        config.url = config.url + "&Barcode=" + stockFilters.barcode;
      }
      if (stockFilters.status) {
        config.url = config.url + "&Status=" + stockFilters.status;
      }
      if (stockFilters.category) {
        config.url = config.url + "&Category=" + stockFilters.category;
      }
      if (stockFilters.warehouse) {
        config.url = config.url + "&Warehouse=" + stockFilters.warehouse;
      }
      if (stockFilters.stockName) {
        config.url = config.url + "&query=" + stockFilters.stockName;
      }
      if (stockFilters.search) {
        config.url = config.url + "&query=" + stockFilters.search;
      }
      if (stockFilters.stockAmountStart) {
        config.url =
          config.url + "&StockAmountStart=" + stockFilters.stockAmountStart;
      }
      if (stockFilters.stockAmountEnd) {
        config.url =
          config.url + "&StockAmountEnd=" + stockFilters.stockAmountEnd;
      }
    }

    setIsDownloading(true);
    try {
      const response = await axios(config);
      if (response.status === 200) {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", "Stoklar.xlsx"); // İndirilen dosyanın adı
        document.body.appendChild(link);
        link.click();
      } else {
        setOpen(true);
        setSnackbarMessage("İstek sırasında bir hata oluştu");
      }
    } catch (error) {
      setOpen(true);
      setSnackbarMessage("İstek sırasında bir hata oluştu");
    }
    setIsDownloading(false);
  };

  return (
    <div>
      <div>
        <Snackbar
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
          open={open}
          autoHideDuration={6000}
          onClose={handleClose}
        >
          <Alert
            onClose={handleClose}
            severity="error"
            variant="filled"
            sx={{ width: "100%" }}
          >
            {snackbarMessage}
          </Alert>
        </Snackbar>
      </div>
      <Button
        className="filterReset"
        startIcon={<Xls />}
        variant="contained"
        disabled={isDownloading}
        sx={{
          boxShadow: "none",
          border: "1px solid #45383426",
          width: "140px",
        }}
        onClick={handleDownloadClick}
      >
        {isDownloading ? <CircularIndeterminate /> : t("export")}
      </Button>
    </div>
  );
};

export default DownloadStockExcel;
