import { Typography } from "@mui/material";
import React from "react";

function getWarehouse(params) {
  return (
    <div>
      {params.row.orderProducts.map((product, index) => (
        <div key={index} className="fbmmProdList">
          <Typography key={product.proparsId}>
            {product.warehouse.title}
          </Typography>
        </div>
      ))}
    </div>
  );
}

export default getWarehouse;
