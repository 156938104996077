import React, { useState, useEffect, useContext } from "react";
import Topbar from "./global/Topbar";
import {
  Box,
  Typography,
  Grid,
  Button,
  Divider,
  MenuItem,
  Select,
  IconButton,
} from "@mui/material";
import axios from "axios";
import Cookies from "js-cookie";
import { useAuthUser } from "react-auth-kit";
import { useTheme } from "@emotion/react";
import { useTranslation } from "react-i18next";
import { alpha, styled } from "@mui/material/styles";
import InputBase from "@mui/material/InputBase";
import Dialog from "@mui/material/Dialog";
import ProductFeatures from "./ProductFeatures";
import PriceCalculationToolTable from "../components/PriceCalculationTool/PriceCalculationToolTable";
import Delivery from "../components/PriceCalculationTool/Delivery";

const BootstrapInput = styled(InputBase)(({ theme }) => ({
  "label + &": {
    marginTop: theme.spacing(3),
    color: `${alpha(theme.palette.text.main, 0.25)} 0 0 0 0.2rem`,
  },
  "& .MuiInputBase-input": {
    borderRadius: 2,
    position: "relative",
    border: "1px solid",
    fontSize: 14,
    fontStyle: "italic",
    height: "12px",
    width: "100%",
    padding: "10px 12px",
    marginTop: "0.5rem",
    borderColor: theme.palette.primary.bordersColor,
    transition: theme.transitions.create([
      "border-color",
      "background-color",
      "box-shadow",
    ]),
    // "&:focus": {
    //   boxShadow: `${alpha(theme.palette.primary.main, 0.25)} 0 0 0 0.2rem`,
    //   borderColor: theme.palette.primary.main,
    //   color: theme.palette.text.main,
    // },
    // "& .MuiFormControlLabel-root": {
    //   fontFamily: "Roboto, sans-serif",
    //   fontWeight: "500",
    // },
  },
}));
const ChangeStatusDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

const MyButton = styled(Button)({
  fontFamily: "Roboto, sans-serif",
  fontWeight: "500",
});

const PriceCalculationTool = () => {
  const theme = useTheme();
  const { t } = useTranslation();

  const [open, setOpen] = React.useState(false);
  const [data, setData] = useState([]);
  const [total, setTotal] = useState(0);
  const [willBeDelivered, setWillBeDelivered] = useState("");
  const [showWillBeSent, setShowWillBeSent] = useState(false);
  const [showCountryWithWarehouseList, setShowCountryWithWarehouseList] =
    useState(false);
  const [showSuccessState1, setShowSuccessState1] = useState(false);
  const [showSuccessState2, setShowSuccessState2] = useState(false);
  const [showErrorState1, setShowErrorState1] = useState(false);
  const [showErrorState2, setShowErrorState2] = useState(false);
  const [showProductFeatures, setShowProductFeatures] = useState(false);
  const [showProductFeaturesForCountry, setShowProductFeaturesForCountry] =
    useState(false);
  const [willBeSent, setWillBeSent] = useState("");
  const [willBeSentCountry, setWillBeSentCountry] = useState("");
  const [toBeDelivered, setToBeDelivered] = useState([]);
  const [countryOfDistributionList, setCountryOfDistributionList] = useState(
    []
  );
  const [countryWithWareHouseList, setCountryWithWareHouseList] = useState([]);

  const [length, setLength] = useState("");
  const [height, setHeight] = useState("");
  const [weight, setWeight] = useState("");
  const [width, setWidth] = useState("");
  const [isFlammableOrExplosive, setIsFlammableOrExplosive] = useState(false);
  const [isShippingMethod, setIsShippingMethod] = useState(false);

  const handleLength = (e) => {
    setLength(e.target.value);
  };

  const handleHeight = (e) => {
    setHeight(e.target.value);
  };

  const handleWeight = (e) => {
    setWeight(e.target.value);
  };
  const handleWidth = (e) => {
    setWidth(e.target.value);
  };
  const handleIsFlammableOrExplosive = (e) => {
    ///setIsFlammableOrExplosive(e.target.value);
    setIsFlammableOrExplosive(!isFlammableOrExplosive);
  };
  const handleIsShippingMethod = (e) => {
    ///setIsFlammableOrExplosive(e.target.value);
    setIsShippingMethod(!isShippingMethod);
  };

  const handleChange = (e) => {
    //"Müşteriye Teslim" value değeri 3
    // "Ülke Deposuna Teslim" value değeri 2
    setShowProductFeatures(true);
    setShowProductFeaturesForCountry(true);
    if (e.target.value === 3) {
      setShowWillBeSent(true);
      setShowProductFeatures(false);
      setShowCountryWithWarehouseList(false);
    } else if (e.target.value === 2) {
      setWillBeSentCountry(true);
      setShowCountryWithWarehouseList(true);
      setShowProductFeatures(false);
      setShowProductFeaturesForCountry(false);
      setShowWillBeSent(false);
    } else {
      setShowWillBeSent(false);
      setShowCountryWithWarehouseList(false);
      setWillBeSent("");
      setWillBeSentCountry("");
    }
    setWillBeDelivered(e.target.value);
  };

  const handleChangeWillBeSent = (e) => {
    setWillBeSent(e.target.value);
    setShowProductFeatures(true);
    setShowProductFeaturesForCountry(false);
  };
  const handleChangeWillBeSentCountry = (e) => {
    setWillBeSentCountry(e.target.value);
    setShowProductFeatures(false);
    setShowProductFeaturesForCountry(true);
  };
  const handleReset = () => {
    setShowSuccessState1(false);
    setShowSuccessState2(false);
    setData([]);
    setLength("");
    setHeight("");
    setWeight("");
    setWidth("");
    setWillBeDelivered("");
    setWillBeSent("");
    setWillBeSentCountry("");
    setCountryOfDistributionList("");
    setShowProductFeatures(false);
    setShowProductFeaturesForCountry(false);
    setShowWillBeSent(false);
    setShowCountryWithWarehouseList(false);
    fetchCountryWithWareHouseList();
    fetchToBeDelivered2();
    fetchToBeDelivered();
  };

  let configToBeDelivered = {
    method: "get",
    maxBodyLength: Infinity,
    url: `${process.env.REACT_APP_BASE_URL}/Shipment/GetWhereToDeliverList`,
    headers: {
      Authorization: `Bearer ${Cookies.get("_auth")}`,
    },
    data: toBeDelivered,
  };
  const fetchToBeDelivered = async () => {
    const responseToBeDelivered = await axios.request(configToBeDelivered);
    const responseToBeDeliveredData = await responseToBeDelivered.data;
    setToBeDelivered(responseToBeDeliveredData);
  };

  useEffect(() => {
    fetchToBeDelivered();
  }, []);

  let configGetCountryOfDistributionList = {
    method: "get",
    maxBodyLength: Infinity,
    url: `${process.env.REACT_APP_BASE_URL}/Shipment/GetCountryOfDistributionList`,
    headers: {
      Authorization: `Bearer ${Cookies.get("_auth")}`,
    },
    data: countryOfDistributionList,
  };
  const fetchToBeDelivered2 = async () => {
    const CountryOfDistributionList = await axios.request(
      configGetCountryOfDistributionList
    );
    const responseTCountryOfDistributionListData =
      await CountryOfDistributionList.data.countryList;
    setCountryOfDistributionList(responseTCountryOfDistributionListData);
  };

  useEffect(() => {
    fetchToBeDelivered2();
  }, []);

  let configGetCountryWithWareHouseList = {
    method: "get",
    maxBodyLength: Infinity,
    url: `${process.env.REACT_APP_BASE_URL}/Shipment/GetCountryWithWareHouseList`,
    headers: {
      Authorization: `Bearer ${Cookies.get("_auth")}`,
    },
    data: countryWithWareHouseList,
  };

  const fetchCountryWithWareHouseList = async () => {
    const CountryWithWareHouseList = await axios.request(
      configGetCountryWithWareHouseList
    );
    const responseCountryWithWareHouseListData = await CountryWithWareHouseList
      .data.countryList;
    setCountryWithWareHouseList(responseCountryWithWareHouseListData);
  };

  useEffect(() => {
    fetchCountryWithWareHouseList();
  }, []);

  const handleGenerate = async () => {
    if (
      length.length === 0 ||
      height.length === 0 ||
      height.weight === 0 ||
      height.width === 0
    ) {
      alert(t("pleaseFillInTheProductSpecificationsStrictly"));
      return;
    }
    if (willBeDelivered === 3 && isFlammableOrExplosive === true) {
      alert(t("sorryYourProductCannotBeShippedByPlane"));
      return;
    }

    let newUrl = "";

    if (willBeDelivered === 1) {
      newUrl = `${
        process.env.REACT_APP_BASE_URL
      }/Shipment/Calculate?WhereToDeliver=${willBeDelivered}&D1=${width}&D2=${length}&D3=${height}&Weight=${weight}&ShipmentContainer=${
        isShippingMethod
          ? "dbf0ed28-29fd-482b-b8cf-74602aec3b7c"
          : "4d10651c-1525-41fb-ae20-d0780003de11"
      }`;
    }
    if (willBeDelivered === 2) {
      newUrl = `${
        process.env.REACT_APP_BASE_URL
      }/Shipment/Calculate?WhereToDeliver=${willBeDelivered}&CountryID=${willBeSentCountry}&D1=${width}&D2=${length}&D3=${height}&Weight=${weight}&ShipmentContainer=${
        isShippingMethod
          ? "dbf0ed28-29fd-482b-b8cf-74602aec3b7c"
          : "4d10651c-1525-41fb-ae20-d0780003de11"
      }`;
    }
    if (willBeDelivered === 3) {
      newUrl = `${
        process.env.REACT_APP_BASE_URL
      }/Shipment/Calculate?WhereToDeliver=${willBeDelivered}&CountryID=${willBeSent}&D1=${width}&D2=${length}&D3=${height}&Weight=${weight}&ShipmentContainer=${
        isShippingMethod
          ? "ccdbfbf0-d337-42f3-85e2-4fd6bd260d6b"
          : "ccdbfbf0-d337-42f3-85e2-4fd6bd260d6b"
      }`;
    }

    let config = {
      method: "get",
      maxBodyLength: Infinity,
      url: newUrl,
      headers: {
        Authorization: `Bearer ${Cookies.get("_auth")}`,
      },
      data: data,
    };

    const response = await axios.request(config);
    let newData = [];

    newData = response.data.priceList.map((item, index) => ({
      ...item,
      id: index + 1,
    }));

    if (response.status === 200) {
      if (willBeDelivered === 1 || willBeDelivered === 2) {
        setShowSuccessState1(true);
      }
      if (willBeDelivered === 3) {
        setShowSuccessState1(false);
        setShowSuccessState2(true);
      }
      setData(newData);
    } else if (response.status === 500) {
      if (willBeDelivered === 1 || willBeDelivered === 2) {
        setShowErrorState1(true);
        setShowErrorState2(false);
      } else if (willBeDelivered === 3) {
        setShowErrorState1(false);
        setShowErrorState2(true);
      }
    } else {
      alert(
        "İstek sırasında bir hata oluştu.Lütfen daha sonra tekrar deneyiniz !!!..."
      );
    }
  };

  return (
    <Box m={"20px"}>
      <Topbar title={t("priceCalculationTool")}></Topbar>

      <Box mt={2} sx={{ height: "100%", backgroundColor: "#fff", p: 3 }}>
        <Grid container spacing="20px">
          <Grid item xs={12} sm={4}>
            <Box
              sx={{
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <Box
                sx={{
                  p: 2,
                  border: "1px solid rgba(69, 56, 52, 0.15)",
                  borderRadius: "2px",
                }}
              >
                <Typography
                  variant="text"
                  sx={{
                    fontSize: "11px",
                    fontFamily: "Roboto",
                  }}
                >
                  {/* Sed posuere consectetur est at lobortis. Nullam id dolor id
                  nibh ultricies vehicula ut id elit. Sociis natoque penatibus
                  et magnis dis parturient montes, Nascetur Ridiculus mus. */}
                </Typography>

                <Delivery
                  handleChangeWillBeSent={handleChangeWillBeSent}
                  willBeSent={willBeSent}
                  countryOfDistributionList={countryOfDistributionList}
                  handleChange={handleChange}
                  willBeDelivered={willBeDelivered}
                  showWillBeSent={showWillBeSent}
                  toBeDelivered={toBeDelivered}
                  showCountryWithWarehouseList={showCountryWithWarehouseList}
                  countryWithWareHouseList={countryWithWareHouseList}
                  handleChangeWillBeSentCountry={handleChangeWillBeSentCountry}
                  willBeSentCountry={willBeSentCountry}
                  setIsShippingMethod={setIsShippingMethod}
                  isShippingMethod={isShippingMethod}
                  handleIsShippingMethod={handleIsShippingMethod}
                />

                {willBeSent ? (
                  <ProductFeatures
                    handleWidth={handleWidth}
                    handleWeight={handleWeight}
                    handleHeight={handleHeight}
                    handleLength={handleLength}
                    length={length}
                    height={height}
                    weight={weight}
                    width={width}
                    isFlammableOrExplosive={isFlammableOrExplosive}
                    setIsFlammableOrExplosive={setIsFlammableOrExplosive}
                    handleIsFlammableOrExplosive={handleIsFlammableOrExplosive}
                  />
                ) : showProductFeatures ? (
                  <ProductFeatures
                    handleWidth={handleWidth}
                    handleWeight={handleWeight}
                    handleHeight={handleHeight}
                    handleLength={handleLength}
                    length={length}
                    height={height}
                    weight={weight}
                    width={width}
                    isFlammableOrExplosive={isFlammableOrExplosive}
                    setIsFlammableOrExplosive={setIsFlammableOrExplosive}
                    handleIsFlammableOrExplosive={handleIsFlammableOrExplosive}
                  />
                ) : willBeSentCountry && showProductFeaturesForCountry ? (
                  <ProductFeatures
                    handleWidth={handleWidth}
                    handleWeight={handleWeight}
                    handleHeight={handleHeight}
                    handleLength={handleLength}
                    length={length}
                    height={height}
                    weight={weight}
                    width={width}
                    isFlammableOrExplosive={isFlammableOrExplosive}
                    setIsFlammableOrExplosive={setIsFlammableOrExplosive}
                    handleIsFlammableOrExplosive={handleIsFlammableOrExplosive}
                  />
                ) : (
                  ""
                )}

                <Box sx={{ marginTop: "-20px" }}>
                  <Grid
                    container
                    spacing={4}
                    justifyContent="space-between"
                    marginTop="1px"
                  >
                    <Grid item>
                      <Button className="filterReset" onClick={handleReset}>
                        {t("clear")}
                      </Button>
                    </Grid>
                    <Grid item>
                      <Button
                        sx={{
                          float: "right",
                        }}
                        className="filterApply"
                        onClick={handleGenerate}
                      >
                        {t("calculate")}
                      </Button>
                    </Grid>
                  </Grid>
                </Box>
              </Box>
            </Box>
          </Grid>
          <Grid item xs={12} sm={8}>
            <PriceCalculationToolTable
              showSuccessState2={showSuccessState2}
              showSuccessState1={showSuccessState1}
              data={data}
              total={total}
              open={open}
              showErrorState1={showErrorState1}
              showErrorState2={showErrorState2}
            />
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};

export default PriceCalculationTool;
