import React from "react";

const FbmmIconActive = () => {
  return (
    <svg
      width="36"
      height="36"
      viewBox="0 0 36 36"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="36" height="36" rx="5" fill="#FEF7F5" />
      <g clipPath="url(#clip0_797_3494)" >
        <path
          d="M11.9297 25.7743H21.9659C22.3243 25.7744 22.6712 25.6436 22.9451 25.405C23.2189 25.1666 23.4021 24.8359 23.462 24.4714L26.1297 8.30191C26.1898 7.93768 26.373 7.60715 26.6469 7.36887C26.9207 7.13059 27.2675 6.99992 27.6258 7H28.6212"
          stroke="#EB603A"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M13.8266 28.904C13.6171 28.904 13.4473 28.7288 13.4473 28.5128C13.4473 28.2967 13.6171 28.1216 13.8266 28.1216"
          stroke="#EB603A"
          strokeWidth="1.5"
        />
        <path
          d="M13.8262 28.904C14.0357 28.904 14.2055 28.7288 14.2055 28.5128C14.2055 28.2967 14.0357 28.1216 13.8262 28.1216"
          stroke="#EB603A"
          strokeWidth="1.5"
        />
        <path
          d="M21.4126 28.904C21.2031 28.904 21.0332 28.7288 21.0332 28.5128C21.0332 28.2967 21.2031 28.1216 21.4126 28.1216"
          stroke="#EB603A"
          strokeWidth="1.5"
        />
        <path
          d="M21.4141 28.904C21.6236 28.904 21.7934 28.7288 21.7934 28.5128C21.7934 28.2967 21.6236 28.1216 21.4141 28.1216"
          stroke="#EB603A"
          strokeWidth="1.5"
        />
        <path
          d="M24.0214 21.0802H11.8102C11.1336 21.0801 10.4763 20.8468 9.94294 20.4173C9.40957 19.988 9.03067 19.387 8.86646 18.7101L7.39963 12.6595C7.37164 12.5441 7.36954 12.4237 7.39346 12.3073C7.41739 12.191 7.46674 12.0818 7.53775 11.988C7.60874 11.8943 7.69953 11.8185 7.80322 11.7664C7.9069 11.7142 8.02074 11.6872 8.13608 11.6873H25.5701"
          fill="#EB603A"
          fillOpacity="0.3"
          
        />
        <path
          d="M24.0214 21.0802H11.8102C11.1336 21.0801 10.4763 20.8468 9.94294 20.4173C9.40957 19.988 9.03067 19.387 8.86646 18.7101L7.39963 12.6595C7.37164 12.5441 7.36954 12.4237 7.39346 12.3073C7.41739 12.191 7.46674 12.0818 7.53775 11.988C7.60874 11.8943 7.69953 11.8185 7.80322 11.7664C7.9069 11.7142 8.02074 11.6872 8.13608 11.6873H25.5701"
          stroke="#EB603A"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M11.9297 14.8209V17.1681"
          stroke="#EB603A"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M16.4824 14.8209V17.1681"
          stroke="#EB603A"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M21.0332 14.8209V17.1681"
          stroke="#EB603A"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_797_3494">
          <rect
            width="24"
            height="24"
            fill="white"
            transform="translate(6 6)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export default FbmmIconActive;
