import React from "react";
import { TextField, Box, Typography, Grid } from "@mui/material";
import { styled } from "@mui/system";
import { useTranslation } from "react-i18next";

const Label = styled(Typography)({
  fontWeight: 500,
  fontSize: "14px",
  lineHeight: "16px",
  marginBottom: "6px",
  width: "fit-content",
});

const StyledTextField = styled(TextField)({
  height: "32px",
  mxWidth: "279px",
  width: "100%",
  "& .MuiInputBase-root": {
    height: "32px",
  },
  "& input": {
    padding: "8px 12px",
  },
});

const BillingInfo = ({ paymentDetailData, setPaymentDetailData }) => {
  const { t } = useTranslation();

  const onFormDataChanged = (name, value) => {
    setPaymentDetailData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  return (
    <>
      <Typography
        variant="h6"
        margin={0}
        fontSize="18px"
        fontWeight="700"
        gutterBottom
      >
        {t("billingInformations")}
      </Typography>
      <Box
        component="form"
        noValidate
        autoComplete="off"
        sx={{ mt: "12px" }}
        border="1px solid #45383426"
        padding="12px"
        borderRadius="2px"
      >
        <Typography
          variant="subtitle1"
          gutterBottom
          color="primary"
          fontWeight="700"
          fontSize="14px"
          marginBottom="12px"
          paddingBottom="7px"
          borderBottom={"1px solid  #D9D9D9"}
        >
          {t("receiverInformations")}
        </Typography>
        <Grid container spacing={2} xs={8} md={12}>
          <Grid item xs={12}>
            <Grid item xs={5}>
              <Label>
                {t("billingContactName")}
                <span style={{ color: "#D7283E" }}> *</span>
              </Label>

              <StyledTextField
                type="text"
                onChange={(e) => {
                  const lettersOnly = e.target.value.replace(
                    /[^a-zA-ZĞğÜüŞşİıÖöÇç\s]/g,
                    ""
                  );
                  onFormDataChanged("contactFullName", lettersOnly);
                }}
                value={paymentDetailData.contactFullName}
                required
                placeholder="Ali Boz"
                variant="outlined"
              />
            </Grid>
          </Grid>
          <Grid item xs={5}>
            <Label>
              {t("name")}
              <span style={{ color: "#D7283E" }}> *</span>
            </Label>
            <StyledTextField
              type="text"
              onChange={(e) => {
                const lettersOnly = e.target.value.replace(
                  /[^a-zA-ZĞğÜüŞşİıÖöÇç\s]/g,
                  ""
                );

                onFormDataChanged("buyerName", lettersOnly);
              }}
              value={paymentDetailData.buyerName}
              required
              placeholder="Mert"
              variant="outlined"
            />
          </Grid>
          <Grid item xs={5}>
            <Label>
              {t("surname")}
              <span style={{ color: "#D7283E" }}> *</span>
            </Label>
            <StyledTextField
              type="text"
              onChange={(e) => {
                const lettersOnly = e.target.value.replace(
                  /[^a-zA-ZĞğÜüŞşİıÖöÇç\s]/g,
                  ""
                );

                onFormDataChanged("buyerSurname", lettersOnly);
              }}
              value={paymentDetailData.buyerSurname}
              required
              placeholder="Özdemir"
              variant="outlined"
            />
          </Grid>
          <Grid item xs={5}>
            <Label>
              {t("email")}
              <span style={{ color: "#D7283E" }}> *</span>
            </Label>
            <StyledTextField
              onChange={(e) => onFormDataChanged("email", e.target.value)}
              value={paymentDetailData.email}
              required
              placeholder="name@example.com"
              variant="outlined"
            />
          </Grid>
          <Grid item xs={5}>
            <Label>
              TC {t("idCardNumber")}
              <span style={{ color: "#D7283E" }}> *</span>
            </Label>
            <StyledTextField
              inputProps={{ maxLength: 11 }}
              onChange={(e) => {
                const numericValue = e.target.value.replace(/\D/g, "");
                onFormDataChanged("tckn", numericValue);
              }}
              value={paymentDetailData.tckn}
              required
              placeholder="64223035386"
            />
          </Grid>
        </Grid>
      </Box>
    </>
  );
};

export default BillingInfo;
