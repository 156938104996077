const UPDATE_IYZICO_PACKAGE = "/Payment/UpdateIyzicoPackage/{id}";
const DELETE_IYZICO_PACKAGE = "/Payment/DeleteIyzicoPackage/{id}";
const CREATE_IYZICO_PACKAGE = "/Payment/CreateIyzicoPackage";
const GET_IYZICO_PACKAGES = "/Payment/GetIyzicoPackages";
const GET_IYZICO_PACKAGE = "/Payment/GetIyzicoPackage/{id}";
const PUT_CONTROLLER_UNSUBSCRIBE = "/controller/Unsubscribe";
const POST_IYZIPAY = "/Iyzipay";
const POST_CREATE_MERCHANT_PAYMENT = "/Merchant/CreateMerchantPayment/{id}";
const POST_IYZIPAY_CHECKOUT_DETAIL =
  "/payment/iyzipos/checkoutform/auth/ecom/detail";

export {
  POST_IYZIPAY,
  UPDATE_IYZICO_PACKAGE,
  DELETE_IYZICO_PACKAGE,
  CREATE_IYZICO_PACKAGE,
  GET_IYZICO_PACKAGES,
  GET_IYZICO_PACKAGE,
  PUT_CONTROLLER_UNSUBSCRIBE,
  POST_IYZIPAY_CHECKOUT_DETAIL,
  POST_CREATE_MERCHANT_PAYMENT,
};
