import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useLanguage } from "../../context/LanguageContext";
import {
  ProSidebar,
  Menu,
  MenuItem,
  SidebarFooter,
  SidebarContent,
  SidebarHeader,
} from "react-pro-sidebar";
import { Box, Typography } from "@mui/material";
import { Link, useNavigate } from "react-router-dom";
import "react-pro-sidebar/dist/css/styles.css";
import MegaLogo from "../../components/icons/MegaLogo";
import DashboardIcon from "../../components/icons/DashboardIcon";
import ProductIcon from "../../components/icons/ProductIcon";
import StockIcon from "../../components/icons/StockIcon";
import FbmmIcon from "../../components/icons/FbmmIcon";
import { useIsAuthenticated, useAuthUser } from "react-auth-kit";
import Flag from "react-world-flags";
import FAQIcon from "../../components/icons/FAQIcon";
import ReportIcons from "../../components/icons/ReportIcons";
import ProductIconActive from "../../components/icons/ProductIconActive";
import DashboardIconActive from "../../components/icons/DashboardIconActive";
import FbmmIconActive from "../../components/icons/FbmmIconActive";
import ReportsIconActive from "../../components/icons/ReportsIconActive";
import StockIconActive from "../../components/icons/StockIconActive";
import styled from "styled-components";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import { useTheme } from "@emotion/react";
import PaymentsIconActive from "../../components/icons/PaymentsIconActive";
import PaymentsIcon from "../../components/icons/PaymentsIcon";
import FAQIconActive from "../../components/icons/FAQIconActive";
import PromotionCodeIcon from "../../components/icons/PromotionCodeIcon";
import PromotionCodeIconActive from "../../components/icons/PromotionCodeIconActive";
import LogsIconActive from "../../components/icons/LogsIconActive";
import LogsIcon from "../../components/icons/LogsIcon";
import PriceCalculationToolIcon from "../../components/icons/PriceCalculationToolIcon";
import PriceCalculationToolIconActice from "../../components/icons/PriceCalculationToolIconActice";

const Sidebar = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const { i18n } = useTranslation();
  const { language, changeLanguage } = useLanguage();
  const [selected, setSelected] = useState(
    window.location.pathname.split("/")[1] || "dashboard"
  );
  const isAuthenticated = useIsAuthenticated();
  const user = useAuthUser();
  const [lang, setLang] = useState("tr");
  const { t } = useTranslation();
  const LightTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} />
  ))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: "rgba(255, 255, 255, 0.9)",
      color: "rgba(0, 0, 0, 0.87)",
      boxShadow: "0px 2px 3px rgba(0, 0, 0, 0.1)",
      fontSize: 14,
      padding: "10px 15px",
      //arrow color
      "& .MuiTooltip-arrow": {
        color: "rgba(255, 255, 255, 0.9)",
      },
    },
  }));

  const Item = ({ title, to, icon, isActive, onClick }) => {
    return (
      <LightTooltip title={title} arrow placement="right">
        <MenuItem
          isActive={isActive}
          // onClick={e =>
          //   setSelected(
          //     e.target
          //       .closest("div")
          //       .querySelector("a")
          //       .getAttribute("href")
          //       .split("/")[1]
          //   )
          // }
          onClick={onClick}
          icon={icon}
        >
          <Typography>{title}</Typography>
          <Link to={to} />
        </MenuItem>
      </LightTooltip>
    );
  };

  const handleItemClick = (itemName) => {
    setSelected(itemName);
  };

  const changeLanguageHandler = () => {
    if (lang === "tr") {
      setLang("en");
      changeLanguage("en");
      i18n.changeLanguage("en");
    } else {
      setLang("tr");
      changeLanguage("tr");
      i18n.changeLanguage("tr");
    }
  };

  if (isAuthenticated()) {
    return (
      <Box
        sx={{
          position: "fixed",
          height: "100%",
          "& .pro-sidebar-inner": {
            background: `#fff !important`,
          },
          "& .pro-icon-wrapper": {
            backgroundColor: "transparent !important",
          },
          "& .pro-inner-item": {
            padding: "10px 35px 5px 15px !important",
          },
        }}
      >
        <ProSidebar collapsed="true">
          <SidebarHeader style={{ listStyle: "none", marginTop: "10px" }}>
            <MenuItem
              icon={<MegaLogo />}
              to="/"
              style={{
                margin: "10px 0 20px 0",
              }}
            ></MenuItem>
          </SidebarHeader>
          <SidebarContent>
            <Box
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
                height: "100%",
              }}
            >
              <Menu iconShape="square">
                {/* LOGO AND MENU ICON 1 */}
                <Box>
                  <Item
                    title="Dashboard"
                    to="/"
                    icon={
                      selected === "dashboard" ? (
                        <DashboardIconActive />
                      ) : (
                        <DashboardIcon />
                      )
                    }
                    isActive={selected === "dashboard"}
                    onClick={() => handleItemClick("dashboard")}
                  />

                  <Item
                    title={t("products")}
                    to="/products"
                    icon={
                      selected === "products" ? (
                        <ProductIconActive />
                      ) : (
                        <ProductIcon />
                      )
                    }
                    isActive={selected === "products"}
                    onClick={() => handleItemClick("products")}
                  />
                  {/* Bu kisimlar simdilik gerekli degil yorum satiri yaptim */}
                  {/* <Item
                title="Categories"
                to="/categories"
                icon={<ProductIcon />}
                selected={selected}
                setSelected={setSelected}
              />
              <Item
                title="Warehouses"
                to="/warehouses"
                icon={<ProductIcon />}
                selected={selected}
                setSelected={setSelected}
              /> */}

                  <Item
                    title={t("stocks")}
                    to="/stocks"
                    icon={
                      selected === "stocks" ? (
                        <StockIconActive />
                      ) : (
                        <StockIcon />
                      )
                    }
                    isActive={selected === "stocks"}
                    onClick={() => handleItemClick("stocks")}
                  />

                  <Item
                    title={t("orders")}
                    to="/fbmm"
                    icon={
                      selected === "fbmm" ? <FbmmIconActive /> : <FbmmIcon />
                    }
                    isActive={selected === "fbmm"}
                    onClick={() => handleItemClick("fbmm")}
                  />
                  {user().role === "b2790c4b-b4a8-4254-9ab6-84c67160b0e0" ? (
                    <Item
                      title={t("reports")}
                      to="/reports"
                      icon={
                        selected === "reports" ? (
                          <ReportsIconActive />
                        ) : (
                          <ReportIcons />
                        )
                      }
                      isActive={selected === "reports"}
                      onClick={() => handleItemClick("reports")}
                    />
                  ) : null}
                  {user().role !== "b2790c4b-b4a8-4254-9ab6-84c67160b0e0" ? (
                    <Item
                      title={t("reports")}
                      to="/merchant-reports"
                      icon={
                        selected === "reports" ? (
                          <ReportsIconActive />
                        ) : (
                          <ReportIcons />
                        )
                      }
                      isActive={selected === "merchant-reports"}
                      onClick={() => handleItemClick("merchant-reports")}
                    />
                  ) : null}
                  {user().role === "b2790c4b-b4a8-4254-9ab6-84c67160b0e0" ? (
                    <Item
                      title={t("packages")}
                      to="/packages"
                      icon={
                        selected === "packages" ? (
                          <PaymentsIconActive />
                        ) : (
                          <PaymentsIcon />
                        )
                      }
                      isActive={selected === "packages"}
                      onClick={() => handleItemClick("packages")}
                    />
                  ) : (
                    <Item
                      title={t("paymentsAndPlan")}
                      to="/payments"
                      icon={
                        selected === "payments" ? (
                          <PaymentsIconActive />
                        ) : (
                          <PaymentsIcon />
                        )
                      }
                      isActive={selected === "payments"}
                      onClick={() => handleItemClick("payments")}
                    />
                  )}

                  {user().role === "b2790c4b-b4a8-4254-9ab6-84c67160b0e0" ? (
                    <Item
                      title={t("promoCodes")}
                      to="/promotion-code"
                      icon={
                        selected === "promotion-code" ? (
                          <PromotionCodeIconActive />
                        ) : (
                          <PromotionCodeIcon />
                        )
                      }
                      isActive={selected === "promotion-code"}
                      onClick={() => handleItemClick("promotion-code")}
                    />
                  ) : null}
                  {user().role === "b2790c4b-b4a8-4254-9ab6-84c67160b0e0" ? (
                    <Item
                      title={t("logs")}
                      to="/logs"
                      icon={
                        selected === "logs" ? <LogsIconActive /> : <LogsIcon />
                      }
                      isActive={selected === "logs"}
                      onClick={() => handleItemClick("logs")}
                    />
                  ) : null}

                  <Item
                    title={t("priceCalculationTool")}
                    to="/pct"
                    icon={
                      selected === "pct" ? (
                        <PriceCalculationToolIconActice />
                      ) : (
                        <PriceCalculationToolIcon />
                      )
                    }
                    isActive={selected === "pct"}
                    onClick={() => handleItemClick("pct")}
                  />
                  <Item
                    title={t("frequentlyAskedQuestions")}
                    to="/faq"
                    icon={selected === "faq" ? <FAQIconActive /> : <FAQIcon />}
                    isActive={selected === "faq"}
                    onClick={() => handleItemClick("faq")}
                  />
                </Box>
              </Menu>
              {/* <Menu iconShape="square">
                <Box></Box>
              </Menu> */}
            </Box>
          </SidebarContent>
          <SidebarFooter style={{ listStyle: "none", padding: 15 }}>
            <Link onClick={changeLanguageHandler}>
              <Flag
                code={lang === "tr" ? "tur" : "us"}
                style={{ borderRadius: "3px" }}
              />
            </Link>
          </SidebarFooter>
        </ProSidebar>
      </Box>
    );
  }
};

export default Sidebar;
