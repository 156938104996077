import {
  Box,
  Typography,
  useTheme,
  Button,
  Grid,
  Divider,
  ListItemIcon,
  ListItemText,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Chip,
  Dialog,
  DialogTitle,
  DialogContent,
  LinearProgress,
  Snackbar,
  IconButton,
  CircularProgress,
  Tooltip,
  FormGroup,
  FormControl,
  InputBase,
  Tabs,
  Tab,
  Avatar,
  Alert,
  AlertTitle,
  FormControlLabel,
  Checkbox,
  Select as MuiSelect,
  MenuItem,
  DialogActions,
  Menu,
  ListItem,
  ListItemAvatar,
  InputLabel,
} from "@mui/material";
import React, { useEffect, useState, useCallback, useContext } from "react";
import Cookies from "js-cookie";
import TableList from "../components/partials/TableList";
import Topbar from "./global/Topbar";
import axios from "axios";
import { useAuthUser } from "react-auth-kit";
import NewProductIcon from "../components/icons/NewProductIcon";
import { alpha, styled } from "@mui/material/styles";
import { useDropzone } from "react-dropzone";
import CloseIcon from "@mui/icons-material/Close";
import { MerchantFilterContext } from "../context/MerchantFilterContext";
import MerchantToggler from "../components/partials/MerchantToggler";
import MerchantFilterBar from "../components/partials/MerchantFilterBar";
import CustomLabel from "../components/forms/form-elements/CustomLabel";

import MessageFIleIcon from "../components/icons/MessageFIleIcon";
import PropTypes from "prop-types";
import { GridActionsCellItem } from "@mui/x-data-grid";
import DeleteIcon from "@mui/icons-material/Delete";
import { useNavigate } from "react-router-dom";
import { useSignIn } from "react-auth-kit";
import { EditNote } from "@mui/icons-material";
import { CalendarIcon } from "@mui/x-date-pickers";
import { use } from "i18next";
import SendMessageIcon from "../components/icons/SendMessageIcon";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";

import SendMessage from "../components/partials/SendMessage";
import { da, el } from "date-fns/locale";
import { useTranslation } from "react-i18next";
import PaymentInformationIcon from "../components/icons/PaymentInformationIcon";
const typoCssProps = {
  fontWeight: "500",
  fontSize: "14px",
};

const BootstrapSelect = styled(InputBase)(({ theme }) => ({
  "label + &": {
    marginTop: theme.spacing(3),
    color: `${alpha(theme.palette.text.main, 0.25)} 0 0 0 0.2rem`,
  },
  "& .MuiInputBase-input": {
    borderRadius: 2,
    position: "relative",
    border: "1px solid",
    fontSize: 14,
    fontStyle: "italic",
    height: "4px!important",
    width: "100%",
    padding: "10px 12px",
    lineHeight: "1",
    marginTop: "0.5rem",
    borderColor: theme.palette.primary.bordersColor,
    transition: theme.transitions.create([
      "border-color",
      "background-color",
      "box-shadow",
    ]),
    minHeight: " 0.8rem!important",
    "&:focus": {
      boxShadow: `${alpha(theme.palette.primary.main, 0.25)} 0 0 0 0.2rem`,
      borderColor: theme.palette.primary.main,
      color: theme.palette.text.main,
    },
  },
}));

const ChangeStatusDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

const BootstrapInput = styled(InputBase)(({ theme }) => ({
  "label + &": {
    marginTop: theme.spacing(3),
    color: `${alpha(theme.palette.text.main, 0.25)} 0 0 0 0.2rem`,
  },
  "& .MuiInputBase-input": {
    borderRadius: 2,
    position: "relative",
    border: "1px solid",
    fontSize: 14,
    fontStyle: "italic",
    height: "12px",
    width: "100%",
    padding: "10px 12px",
    marginTop: "0.5rem",
    borderColor: theme.palette.primary.bordersColor,
    transition: theme.transitions.create([
      "border-color",
      "background-color",
      "box-shadow",
    ]),
    "&:focus": {
      boxShadow: `${alpha(theme.palette.primary.main, 0.25)} 0 0 0 0.2rem`,
      borderColor: theme.palette.primary.main,
      color: theme.palette.text.main,
    },
  },
}));

// added validations
const Dashboard = () => {
  const auth = useAuthUser();

  const signIn = useSignIn();
  const merchantID = auth().merchantId;
  const theme = useTheme();
  const { t } = useTranslation();
  const [error, setError] = useState(null);
  const { merchantFilters, setMerchantFilters } = useContext(
    MerchantFilterContext
  );
  const [pageState, setPageState] = useState({
    isLoading: false,
    data: [],
    total: 0,
    page: 0,
    pageSize: 10,
  });
  const navigate = useNavigate();
  const [selectedCalendlyUrl, setSelectedCalendlyUrl] = useState("");
  const [openSendMessageDialog, setOpenSendMessageDialog] = useState(false);
  const [openNewCalendlyDialog, setOpenNewCalendlyDialog] = useState(false);
  const [userFetchData, setUserFetchData] = useState(null);
  const [openFileInsert, setOpenFileInsert] = useState(false);
  const [progress, setProgress] = useState(0);
  const [fileRows, setFileRows] = useState([]);
  const [uploadedFiles, setUploadedFiles] = useState([]);
  const [uploadError, setUploadError] = useState(null);
  const [successMessage, setSuccessMessage] = useState("");
  const [isSnackbarOpen, setSnackbarOpen] = useState(false);
  const [statusList, setStatusList] = useState([]);
  const [openAddNewPersonelDialog, setOpenAddNewPersonelDialog] =
    useState(false);
  const [links, setLinks] = useState([]);
  const [addNewPersonelLoading, setAddNewPersonelLoading] = useState(false);
  const [messages, setMessages] = useState([]);
  const [personelNameSurname, setPersonelNameSurname] = useState("");
  const [personelEmail, setPersonelEmail] = useState("");
  const [personelPassword, setPersonelPassword] = useState("ff6Ydf99bn");
  const [personelPasswordConfirm, setPersonelPasswordConfirm] =
    useState("ff6Ydf99bn");
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [getMerchantUsers, setMerchantUsers] = useState([]);

  const [formErrors, setFormErrors] = useState({});
  const [adminFormErrors, setAdminFormErrors] = useState({});

  const [errorMessage, setErrorMessage] = useState(null);

  const handleCloseNewCalendlyDialog = () => {
    setOpenNewCalendlyDialog(false);
    handleClose();
  };

  const handleOpenNewCalendlyDialog = () => {
    setOpenNewCalendlyDialog(true);
    handleClose();
  };

  const handleChangeCalendlyUrl = (e) => {
    setSelectedCalendlyUrl(e.target.value);
  };

  const validateField = (fieldName, value) => {
    const newErrors = { ...formErrors };
    if (fieldName === "newUserNameSurname") {
      if (value.length < 3) {
        newErrors[fieldName] = t("nameAndSurnameMustBeAtLeastThreeCharacters");
      } else {
        delete newErrors[fieldName];
      }
    }

    if (fieldName === "newUserEmail") {
      if (value.length < 3) {
        newErrors[fieldName] = t("EmailMustBeAtLeastThreeCharacters");
      } else {
        delete newErrors[fieldName];
      }
    }

    if (fieldName === "newUserEmail") {
      if (!value.includes("@")) {
        newErrors[fieldName] = t("pleaseEnterAValidEmailAddress");
      } else {
        delete newErrors[fieldName];
      }
    }

    setFormErrors(newErrors);
  };

  const validateAdminField = (fieldName, value) => {
    const newErrors = { ...adminFormErrors };
    if (fieldName === "nameSurname") {
      if (value.length < 3) {
        newErrors[fieldName] = t("nameAndSurnameMustBeAtLeastThreeCharacters");
      } else {
        delete newErrors[fieldName];
      }
    }

    if (fieldName === "email") {
      if (value.length < 3) {
        newErrors[fieldName] = t("EmailMustBeAtLeastThreeCharacters");
      } else {
        delete newErrors[fieldName];
      }
    }

    if (fieldName === "email") {
      if (!value.includes("@")) {
        newErrors[fieldName] = t("pleaseEnterAValidEmailAddress");
      } else {
        delete newErrors[fieldName];
      }
    }

    if (fieldName === "password") {
      if (value.length < 6) {
        newErrors[fieldName] = t("passwordMustBeAtLeastSixCharacters");
      } else {
        delete newErrors[fieldName];
      }
    }

    if (fieldName === "passwordConfirm") {
      if (value !== adminData.password) {
        newErrors[fieldName] = t("passwordsDoNotMatch");
      } else {
        delete newErrors[fieldName];
      }
    }

    setAdminFormErrors(newErrors);
  };

  const Item = styled(Paper)(() => ({
    backgroundColor: "transparent",
    paddingLeft: "10px",
    paddingRight: "10px",
    paddingTop: "10px",
    boxShadow: "none",
    border: `1px solid ${theme.palette.primary.bordersColor}}`,
    borderRadius: "2px",
  }));
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [testUserIsSubmerchant, setTestUserIsSubmerchant] = useState(false);
  const [testUserSelectedMerchant, setTestUserSelectedMerchant] = useState("");
  const [submitLoading, setSubmitLoading] = useState(false);
  const [submitSuccess, setSubmitSuccess] = useState(false);
  const [userName] = useState("mmuser");
  const [loginDialog, setLoginDialog] = useState(false);
  const [testUserDialog, setTestUserDialog] = useState(false);
  const [openAddNewAdminDialog, setOpenAddNewAdminDialog] = useState(false);
  const [userBrands, setUserBrands] = useState([]);
  const [addNewAdminLoading, setAddNewAdminLoading] = useState(false);
  const [adminData, setAdminData] = useState({
    nameSurname: "",
    email: "",
    password: "",
    passwordConfirm: "",
  });
  const open = Boolean(anchorEl);
  const [
    merchantsFromOnlySellAndAcceptance,
    setMerchantsFromOnlySellAndAcceptance,
  ] = useState([]);
  const onDrop = useCallback(async (acceptedFiles) => {
    const validFiles = [];

    for (const file of acceptedFiles) {
      validFiles.push({
        name: file.name,
        data: file,
      });
    }

    setUploadedFiles((prevFiles) => [...prevFiles, ...validFiles]);

    setUploadError(null);
  }, []);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleTestUserOpen = () => {
    setTestUserDialog(true);
  };

  const handleTestUserClose = () => {
    setTestUserDialog(false);
  };

  const handleTestUserIsSubmerchant = (e) => {
    setTestUserIsSubmerchant(e.target.checked);
  };

  const handleChangeAdminData = (e) => {
    validateAdminField(e.target.name, e.target.value);
    setAdminData({ ...adminData, [e.target.name]: e.target.value });
  };

  const handleTestUserSubmit = () => {
    setSubmitLoading(true);
    let data = {
      password: "ff6Ydf99bn",
      isSubMerchant: testUserIsSubmerchant,
      merchantId: testUserSelectedMerchant,
    };

    let config = {
      method: "put",
      maxBodyLength: Infinity,
      url: `${process.env.REACT_APP_BASE_URL}/controller/UpdateTestUser/`,
      headers: {
        Authorization: `Bearer ${Cookies.get("_auth")}`,
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios(config)
      .then((response) => {
        setSubmitLoading(false);
        setSubmitSuccess(true);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleChangeUser = async (event) => {
    event.preventDefault();
    // delete cookies manually
    Cookies.remove("_auth");
    Cookies.remove("_auth_state");
    Cookies.remove("_auth_storage");
    Cookies.remove("_auth_type");

    //

    try {
      setLoginDialog(true);
      const response = await axios.post(
        `${process.env.REACT_APP_BASE_URL}/controller/Login`,
        {
          usernameOrEmail: userName,
          password: "ff6Ydf99bn",
        },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      if (
        signIn({
          token: response.data.userInfo.token.accessToken,
          expiresIn: 899,
          tokenType: "Bearer",
          authState: {
            email: userName,
            role: response.data.userInfo.roleId,
            refreshToken: response.data.userInfo.token.refreshToken,
            merchantId: response.data.userInfo.merchantId,
            companyId: response.data.userInfo.companyId,
            nameSurname: response.data.userInfo.nameSurname,
          },
        })
      ) {
        navigate("/products");
      }
    } catch (err) {
      if (err) {
        if (err.response) {
          setError(t("usernameOrPasswordIsIncorrect"));
        }
      } else if (err && err instanceof Error) {
        setError(t("usernameOrPasswordIsIncorrect"));
      } else {
        console.log("Error: ", err);
      }
    }
  };

  const handleOpenAddNewPersonelDialog = () => {
    setOpenAddNewPersonelDialog(true);
  };

  const handleCloseAddNewPersonelDialog = () => {
    setOpenAddNewPersonelDialog(false);
    handleClose();
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleOpenSendMessageDialog = () => {
    setOpenSendMessageDialog(true);
    handleClose();
  };

  const handleCloseSendMessageDialog = () => {
    setOpenSendMessageDialog(false);
  };

  const handleAddNewAdminDialog = () => {
    setOpenAddNewAdminDialog(true);
    handleClose();
  };

  const handleCloseAddNewAdminDialog = () => {
    setOpenAddNewAdminDialog(false);
    handleClose();
  };

  const handleAddNewPersonel = async (e) => {
    setAddNewPersonelLoading(true);
    e.preventDefault();
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_BASE_URL}/controller?NameSurname=${personelNameSurname}&UserName=${personelEmail}&Email=${personelEmail}&Password=${personelPassword}&PasswordConfirm=${personelPasswordConfirm}
`,
        {
          NameSurname: personelNameSurname,
          UserName: personelEmail,
          Email: personelEmail,
          Password: "ff6Ydf99bn",
          PasswordConfirm: "ff6Ydf99bn",
        },
        {
          headers: {
            Authorization: `Bearer ${Cookies.get("_auth")}`,
          },
        }
      );
      if (response.status === 200) {
        setAddNewPersonelLoading(false);
        setSuccessMessage(t("userAddedSuccessfully"));
        fetchUserData();
        setSnackbarOpen(true);
        setOpenAddNewPersonelDialog(false);
        setPersonelNameSurname("");
        setPersonelEmail("");
        setPersonelPassword("ff6Ydf99bn");
        setPersonelPasswordConfirm("ff6Ydf99bn");
      } else {
        setSnackbarOpen(true);
      }
    } catch (error) {
      console.log(error);
    }
  };
  const handleFileInsertClose = () => {
    setOpenFileInsert(false);
    handleClose();
  };
  const handleFileInsertOpen = () => {
    setOpenFileInsert(true);
    handleClose();
  };

  const handleDeleteFile = (fileName) => {
    setUploadedFiles((prevFiles) =>
      prevFiles.filter((file) => file.name !== fileName)
    );
  };

  const handleTabChange = (event, newValue) => {
    setSelectedIndex(newValue);
  };
  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
    };
  }

  function CustomTabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && <Box sx={{ pt: 2 }}>{children}</Box>}
      </div>
    );
  }

  const handleAdminSubmit = async (e) => {
    e.preventDefault();
    try {
      let config = {
        method: "post",
        maxBodyLength: Infinity,
        url: `${process.env.REACT_APP_BASE_URL}/controller/create-mmadmin?NameSurname=${adminData.nameSurname}&UserName=${adminData.email}&Email=${adminData.email}&Password=${adminData.password}&PasswordConfirm=${adminData.passwordConfirm}`,
        headers: {
          Authorization: `Bearer ${Cookies.get("_auth")}`,
          ContentType: "application/json",
        },
        data: {
          NameSurname: adminData.nameSurname,
          UserName: adminData.email,
          Email: adminData.email,
          Password: adminData.password,
          PasswordConfirm: adminData.passwordConfirm,
        },
      };
      setAddNewAdminLoading(true);

      const response = await axios(config);
      console.log(response);
      setAddNewAdminLoading(false);
      if (response.status === 200) {
        setSuccessMessage(t("adminHasBeenAddedSuccessfully"));
        setSnackbarOpen(true);
        setOpenAddNewAdminDialog(false);
        setAddNewAdminLoading(false);
        setAdminData({
          NameSurname: "",
          Email: "",
          Password: "",
          PasswordConfirm: "",
        });
      }
    } catch (error) {
      console.log(error);
      setAddNewAdminLoading(false);
    }
  };

  // const messages = [
  //   // {
  //   //   sender: "Mike",
  //   //   merchantName: "Papatya",
  //   //   date: "12.12.2021 15:30",
  //   //   avatar: "https://picsum.photos/200",
  //   //   message:
  //   //     "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
  //   // },
  //   // {
  //   //   sender: "Sinem",
  //   //   merchantName: "Mega Merchant",
  //   //   date: "12.12.2021 15:30",
  //   //   avatar: "https://picsum.photos/200",
  //   //   message:
  //   //     "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
  //   //   files: [
  //   //     {
  //   //       name: "İster Dokümanı.pdf",
  //   //       url: "https://picsum.photos/200",
  //   //     },
  //   //     {
  //   //       name: "İster Dokümanı.pdf",
  //   //       url: "https://picsum.photos/200",
  //   //     },
  //   //   ],
  //   // },
  //   // {
  //   //   sender: "Mike",
  //   //   merchantName: "Papatya",
  //   //   date: "12.12.2021 15:30",
  //   //   avatar: "https://picsum.photos/200",
  //   //   message:
  //   //     "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
  //   // },
  // ];
  CustomTabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
  };

  const getLinksFromAPI = async () => {
    let config = {
      method: "get",
      maxBodyLength: Infinity,
      url: `${process.env.REACT_APP_BASE_URL}/Merchant/GetMerchantLinkByMerchantId/${merchantID}`,
      headers: {
        Authorization: `Bearer ${Cookies.get("_auth")}`,
      },
    };
    const response = await axios(config);
    setLinks(response.data);
  };

  const handleCloseSnackbar = () => {
    setSnackbarOpen(false);
  };

  const { getRootProps, getInputProps, isDragAccept, isDragReject } =
    useDropzone({
      onDrop,
    });

  useEffect(() => {
    getUserBrandsFromApi();
    getUserMessages();
  }, []);

  const getMerchantsFromOnlySellAndAcceptance = useCallback(async () => {
    let config = {
      method: "get",
      maxBodyLength: Infinity,
      url: `${process.env.REACT_APP_BASE_URL}/Merchant/GetList`,
      headers: {
        Authorization: `Bearer ${Cookies.get("_auth")}`,
      },
    };
    const response = await axios(config);
    // sort a-z by title turkish and set state
    response.data.items.sort((a, b) =>
      a.title.localeCompare(b.title, "tr-TR", { sensitivity: "base" })
    );

    setMerchantsFromOnlySellAndAcceptance(response.data.items);
  }, []);

  const userChangeHandler = (e) => {
    // set user values to state sta
  };

  const getUserBrandsFromApi = async () => {
    let config = {
      method: "get",
      maxBodyLength: Infinity,
      url: `${process.env.REACT_APP_BASE_URL}/Merchant/GetSubMerchantBrandByMerchantId/${merchantID}`,
      headers: {
        Authorization: `Bearer ${Cookies.get("_auth")}`,
      },
    };
    const response = await axios(config);
    setUserBrands(response.data.items);
  };

  const handleSubmitFile = async (e) => {
    e.preventDefault();

    setProgress(0);

    const formData = new FormData();
    uploadedFiles.forEach((file) => {
      formData.append("files", file.data, file.name);
    });

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_BASE_URL}/Merchant/Upload?Id=${
          auth().merchantId
        }`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${Cookies.get("_auth")}`,
          },
          onUploadProgress: (progressEvent) => {
            const percentCompleted = Math.round(
              (progressEvent.loaded * 100) / progressEvent.total
            );
            setProgress(percentCompleted);
          },
        }
      );

      if (response.status === 200) {
        // fetchFileData();
        setSuccessMessage(t("filesUploadedSuccessfully"));
        setSnackbarOpen(true);
        setUploadedFiles([]);
        setOpenFileInsert(false);
        setProgress(0);
      } else {
        console.error(t("anErrorOccurredWhileUploadingFiles"));
      }
    } catch (error) {
      console.error(t("anErrorOccurredWhileUploadingFiles"), error);
    }
  };

  const getUserMessages = async () => {
    let config = {
      method: "get",
      maxBodyLength: Infinity,
      url: `${
        process.env.REACT_APP_BASE_URL
      }/Merchant/GetMerchantMessageByMerchantId/${auth().merchantId}`,
      headers: {
        Authorization: `Bearer ${Cookies.get("_auth")}`,
      },
    };
    try {
      const response = await axios(config);
      setMessages(response.data.items);
    } catch (error) {
      console.log(error);
    }
  };

  const sendMessage = () => {
    // if this function is called ? re render the messages}

    getUserMessages();
  };

  const createCalendlyEvent = () => {
    // redirect to selectedCalendlyUrl
    window.open(selectedCalendlyUrl, "_blank");
  };

  let data = "";
  let config = {
    method: "get",
    maxBodyLength: Infinity,
    url: `${process.env.REACT_APP_BASE_URL}/Merchant?Pagination.Page=${pageState.page}&Pagination.Size=${pageState.pageSize}`,
    headers: {
      Authorization: `Bearer ${Cookies.get("_auth")}`,
    },
    data: data,
  };

  let userData = "";
  let configUser = {
    method: "get",
    maxBodyLength: Infinity,
    url: `${process.env.REACT_APP_BASE_URL}/Merchant/${auth().merchantId}`,
    headers: {
      Authorization: `Bearer ${Cookies.get("_auth")}`,
    },
    data: userData,
  };

  let configFile = {
    method: "get",
    maxBodyLength: Infinity,
    url: `${process.env.REACT_APP_BASE_URL}/Merchant/GetMerchantDocuments/${
      auth().merchantId
    }`,
    headers: {
      Authorization: `Bearer ${Cookies.get("_auth")}`,
    },
    data: userData,
  };

  const fetchUserData = async () => {
    try {
      const response = await axios.request(configUser);
      const responseData = await response.data.merchantInfo;
      console.log("userFetchData", responseData);
      setUserFetchData(responseData);
      setMerchantUsers(responseData.users);
    } catch (error) {}
  };

  const getStatusListFromAPI = async () => {
    let config = {
      method: "get",
      maxBodyLength: Infinity,
      url: `${process.env.REACT_APP_BASE_URL}/MerchantStatus/count`,
      headers: {
        Authorization: `Bearer ${Cookies.get("_auth")}`,
      },
    };
    try {
      const response = await axios(config);
      setStatusList(response.data.items);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    fetchFileData();
  }, [successMessage]);

  const fetchFileData = async () => {
    const response = await axios.request(configFile);
    const responseData = await response.data;
    setFileRows(responseData);
  };

  useEffect(() => {
    // isLoaded &&
    if (auth().role === "b2790c4b-b4a8-4254-9ab6-84c67160b0e0") {
      getStatusListFromAPI();
    }
    if (auth().role === "342267ab-f007-4928-8568-40c896951afa") {
      getLinksFromAPI();
      fetchUserData();
      fetchFileData();
    }
  }, []);

  // validation for name surname min 3 char and set state
  const newUserNameSurnameChangeHandler = (event) => {
    const validValue = event.target.value.replace(
      /[^A-Za-zğüşıöçĞÜŞİÖÇ\s]/g,
      ""
    );

    // max 75 karakter
    if (validValue.length === 75) {
      // set error message
      validateField("newUserNameSurname", event.target.value);
      return false;
    }
    setPersonelNameSurname(validValue);
    validateField("newUserNameSurname", event.target.value);
  };

  const newUserEmailChangeHandler = (event) => {
    // max 75 karakter
    if (event.target.value.length === 75) {
      // set error message
      validateField("newUserEmail", event.target.value);
      return false;
    }
    setPersonelEmail(event.target.value);
    validateField("newUserEmail", event.target.value);
  };

  // const deleteDocument = async id => {
  //   let status = window.confirm("Silmek istediğinize emin misiniz?");

  //   let data = {
  //     id: id,
  //   };
  //   if (status) {
  //     let updateConfig = {
  //       method: "put",
  //       maxBodyLength: Infinity,
  //       url: `${process.env.REACT_APP_BASE_URL}/Merchant/DeleteMerchantDocument/${id}`,
  //       headers: {
  //         Authorization: `Bearer ${Cookies.get("_auth")}`,
  //       },
  //       data: data,
  //     };
  //     const response = await axios(updateConfig);
  //     console.log(response);
  //     setSuccessMessage("Dosya başarıyla silindi.");
  //     fetchFileData();
  //   }
  // };

  useEffect(() => {
    if (auth().role === "b2790c4b-b4a8-4254-9ab6-84c67160b0e0") {
      if (merchantFilters) {
        if (merchantFilters.search) {
          config.url = config.url + "&Query=" + merchantFilters.search;
        }
        if (merchantFilters.status) {
          config.url = config.url + "&Status=" + merchantFilters.status;
        }
        if (merchantFilters.MMUserId) {
          config.url = config.url + "&MMUserId=" + merchantFilters.MMUserId;
        }
        if (merchantFilters.accountManager) {
          config.url =
            config.url + "&AccountManagerId=" + merchantFilters.accountManager;
        }
        if (merchantFilters.isSubmerchant) {
          config.url =
            config.url + "&IsSubmerchant=" + merchantFilters.isSubmerchant;
        } else {
          config.url = config.url + "&IsSubmerchant=false";
        }
      }
      // asdasd
      const fetchData = async () => {
        try {
          setPageState((old) => ({ ...old, isLoading: true }));
          const response = await axios.request(config);
          const responseData = await response.data.items;
          const totalCount = await response.data.totalCount;
          setPageState((old) => ({
            ...old,
            isLoading: false,
            data: responseData,
            total: totalCount,
          }));
        } catch (error) {}
      };
      fetchData();
    }
  }, [pageState.page, pageState.pageSize, merchantFilters]);

  // reset filter when leaving the page

  if (auth().role === "b2790c4b-b4a8-4254-9ab6-84c67160b0e0") {
    useEffect(() => {
      getMerchantsFromOnlySellAndAcceptance();
    }, []);
  }

  useEffect(() => {
    merchantFilters.search = "";
    config.url = `${process.env.REACT_APP_BASE_URL}/Merchant?Pagination.Page=${pageState.page}&Pagination.Size=${pageState.pageSize}`;
    return () => {
      setPageState({
        isLoading: false,
        data: [],
        total: 0,
        page: 0,
        pageSize: 10,
      });
    };
  }, []);

  // if user leaves the page, reset the filter and the page state to initial state and reset config url

  useEffect(() => {
    const resetState = {
      isLoading: false,
      data: [],
      total: 0,
      page: 0,
      pageSize: 10,
    };

    const resetFilters = { ...merchantFilters, search: "" };

    config.url = `${process.env.REACT_APP_BASE_URL}/Merchant?Pagination.Page=${resetState.page}&Pagination.Size=${resetState.pageSize}`;
    setPageState(resetState);
    setMerchantFilters(resetFilters);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []); // Boş bağımlılık dizisi, bu effect'in sadece bir kez çalışmasını sağlar

  const columns = [
    {
      field: "title",
      headerName: t("companyName"),
      flex: 2,
      renderCell: getBrandName,
    },
    // {
    //   field: "merchantCategories",
    //   headerName: "Kategori",
    //   flex: 1,
    //   renderCell: getBrandCategory,
    // },
    {
      field: "nameSurname",
      headerName: t("responsible"),
      flex: 1,
      valueGetter: getUser,
      // valueFormatter: (params) => params.row.users[0].nameSurname,
    },
    {
      headerName: t("mmResponsiblePerson"),
      flex: 0.7,
      renderCell: getMMUser,
    },
    {
      field: "accountManager",
      headerName: t("accountManager"),
      valueGetter: getAccountManager,
      flex: 0.7,
    },
    {
      field: "phoneNumber",
      headerName: t("phone"),
      flex: 0.6,
      valueGetter: getUserPhone,
    },
    {
      field: "email",
      headerName: t("email"),
      flex: 1,
      valueGetter: getUserMail,
    },
    {
      field: "merchantStatusId",
      headerName: t("theStatus"),
      flex: 0.7,
      valueGetter: getBrandStatus,
    },
    {
      field: "dateCreated",
      headerName: t("applicationDate"),
      flex: 1,
      renderCell: (params) => (
        <Typography>
          {new Date(params.row.createdAt).toLocaleDateString("tr-TR", {
            year: "numeric",
            month: "long",
            day: "2-digit",
          }) +
            " - " +
            new Date(params.row.createdAt).toLocaleTimeString("tr-TR", {
              hour: "2-digit",
              minute: "2-digit",
            })}
        </Typography>
      ),
    },
    {
      field: "guncellenme",
      headerName: t("updatedDate"),
      flex: 1,
      renderCell: (params) => (
        <Typography>
          {params.row.updatedAt === "0001-01-01T00:00:00"
            ? t("notUpdated")
            : new Date(params.row.updatedAt).toLocaleDateString("tr-TR", {
                year: "numeric",
                month: "long",
                day: "2-digit",
              }) +
              " - " +
              new Date(params.row.updatedAt).toLocaleTimeString("tr-TR", {
                hour: "2-digit",
                minute: "2-digit",
              })}
        </Typography>
      ),
    },
    {
      field: "actions",
      type: "actions",
      headerName: "",
      flex: 0.5,
      getActions: (params) => [
        <GridActionsCellItem
          icon={<EditNote />}
          label={t("edit")}
          onClick={() => navigate(`/edit-merchant/${params.row.id}`)}
          showInMenu
        />,
        <GridActionsCellItem
          icon={<DeleteIcon />}
          label={t("delete")}
          onClick={() => handleDeleteMerchant(params.row.id)}
          showInMenu
        />,
      ],
    },
  ];

  //merchant delete confirmation

  const deleteMerchant = async (id) => {
    let config = {
      method: "delete",
      maxBodyLength: Infinity,
      url: `${process.env.REACT_APP_BASE_URL}/Merchant/DeleteMerchant/${id}`,
      headers: {
        Authorization: `Bearer ${Cookies.get("_auth")}`,
      },
      data: {
        id: id,
      },
    };

    try {
      const response = await axios(config);
      if (response.status === 200) {
        setSuccessMessage(t("theCompanyHasBeenSuccessfullyDeleted"));
        setSnackbarOpen(true);
        // fetch data again
        setMerchantFilters({ ...merchantFilters, search: "" });
      } else {
        setSnackbarOpen(true);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleDeleteMerchant = (id) => {
    const confirm = window.confirm(t("areYouSureYouWantToDeleteTheCompany"));
    if (confirm) {
      deleteMerchant(id);
    }
  };

  function getBrandName(params) {
    return (
      <Tooltip title={params.row.title} placement="top">
        <Typography>
          <a
            href={`/merchants/${params.row.id}`}
            style={{
              color: theme.palette.secondary.main,
              textDecoration: "none",
              fontWeight: "500",
            }}
          >
            {params.row.title}
          </a>
        </Typography>
      </Tooltip>
    );
  }

  function getUser(params) {
    if (params.row.users.length > 0) {
      return params.row.users[0].nameSurname;
    } else {
      return t("noUser");
    }
  }

  function getMMUser(params) {
    if (params.row.mmUser) {
      if (params.row.mmUser.calendlyUrl) {
        return params.row.mmUser.nameSurname;
      }
      return params.row.mmUser.nameSurname;
    } else {
      return t("noUser");
    }
  }

  function getAccountManager(params) {
    if (params.row.mmAccountManager) {
      return params.row.mmAccountManager.nameSurname;
    } else {
      return t("noUser");
    }
  }

  function getUserPhone(params) {
    return params.row.phoneNumber;
  }

  function getUserMail(params) {
    if (params.row.users.length > 0) {
      return params.row.users[0].email;
    } else {
      return t("noEmail");
    }
  }
  function getBrandStatus(params) {
    return params.row.merchantStatus.title;
  }

  if (2 > 3) {
    return (
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "90vh",
        }}
      >
        <CircularProgress />
      </Box>
    );
  } else if (error) {
    return <div>Error: {error.message}</div>;
  } else {
    if (auth().role === "b2790c4b-b4a8-4254-9ab6-84c67160b0e0") {
      return (
        <Box m={"20px"}>
          <Topbar title={t("registrationForms")} />

          <Box sx={{ background: theme.palette.primary.white }} p={5}>
            <MerchantToggler
              counts={pageState}
              options={statusList}
              userLoginModal={setTestUserDialog}
              createAdminModal={setOpenAddNewAdminDialog}
            />
            <MerchantFilterBar />
            <Box mt={2} sx={{ height: "100%" }}>
              <TableList
                key={pageState.data.id}
                rows={pageState.data}
                columns={columns}
                pageState={pageState}
                setPageState={setPageState}
                rowHeight={50}
              />
            </Box>
            <Box className="statusDialog">
              <ChangeStatusDialog
                fullScreen
                fullWidth
                aria-labelledby="customized-dialog-title"
                open={loginDialog}
              >
                <DialogContent dividers>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      flexDirection: "column",
                      alignItems: "center",
                      height: "100%",
                    }}
                  >
                    <Alert severity="success">
                      <AlertTitle> {t("pleaseWait")}</AlertTitle>
                      {t("loggingInToMerchantAccount")}...
                    </Alert>
                    <LinearProgress
                      sx={{
                        marginTop: "20px",
                        width: "100%",
                      }}
                      color="success"
                    />
                  </Box>
                </DialogContent>
              </ChangeStatusDialog>
            </Box>
            <Box className="adminDialog">
              <ChangeStatusDialog
                onClose={handleCloseAddNewAdminDialog}
                aria-labelledby="customized-dialog-title"
                open={openAddNewAdminDialog}
                maxWidth="sm"
                fullWidth
              >
                <DialogTitle
                  sx={{ m: 0, p: 2, color: theme.palette.primary.main }}
                  id="customized-dialog-title"
                >
                  {t("addMMAdmin")}
                </DialogTitle>
                <IconButton
                  aria-label="close"
                  onClick={handleCloseAddNewAdminDialog}
                  sx={{
                    position: "absolute",
                    right: 8,
                    top: 8,
                    color: (theme) => theme.palette.grey[500],
                  }}
                >
                  <CloseIcon />
                </IconButton>
                <DialogContent dividers>
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      marginX: "10px",
                      marginBottom: "15px",
                    }}
                  >
                    <Typography
                      sx={{
                        mt: 1,
                        mb: 0,
                      }}
                      variant="body2"
                      fontWeight="500"
                      color="text"
                    >
                      {t("nameSurname")}
                    </Typography>
                    <BootstrapInput
                      label={t("nameSurname")}
                      placeholder={t("nameSurname")}
                      value={
                        adminData.nameSurname
                          ? adminData.nameSurname
                          : adminData.nameSurname
                      }
                      name="nameSurname"
                      onChange={handleChangeAdminData}
                      error={adminFormErrors.nameSurname}
                      helperText={adminFormErrors.nameSurname}
                    />
                    {adminFormErrors.nameSurname && (
                      <Typography
                        variant="body2"
                        color="error"
                        sx={{ fontSize: "12px", mt: 1 }}
                      >
                        {adminFormErrors.nameSurname}
                      </Typography>
                    )}
                    <Typography
                      sx={{
                        mt: 1,
                        mb: 0,
                      }}
                      variant="body2"
                      fontWeight="500"
                      color="text"
                    >
                      {t("email")}
                    </Typography>
                    <BootstrapInput
                      placeholder={t("email")}
                      value={
                        adminData.email ? adminData.email : adminData.email
                      }
                      name="email"
                      onChange={handleChangeAdminData}
                      error={adminFormErrors.email}
                      helperText={adminFormErrors.email}
                    />
                    {adminFormErrors.email && (
                      <Typography
                        variant="body2"
                        color="error"
                        sx={{ fontSize: "12px", mt: 1 }}
                      >
                        {adminFormErrors.email}
                      </Typography>
                    )}
                    <Typography
                      sx={{
                        mt: 1,
                        mb: 0,
                      }}
                      variant="body2"
                      fontWeight="500"
                      color="text"
                    >
                      {t("password")}
                    </Typography>
                    <BootstrapInput
                      placeholder={t("password")}
                      value={
                        adminData.password
                          ? adminData.password
                          : adminData.password
                      }
                      name="password"
                      onChange={handleChangeAdminData}
                      error={adminFormErrors.password}
                      helperText={adminFormErrors.password}
                    />
                    {adminFormErrors.password && (
                      <Typography
                        variant="body2"
                        color="error"
                        sx={{ fontSize: "12px", mt: 1 }}
                      >
                        {adminFormErrors.password}
                      </Typography>
                    )}
                    <Typography
                      sx={{
                        mt: 1,
                        mb: 0,
                      }}
                      variant="body2"
                      fontWeight="500"
                      color="text"
                    >
                      {t("passwordRepeat")}
                    </Typography>
                    <BootstrapInput
                      placeholder={t("passwordRepeat")}
                      value={
                        adminData.passwordConfirm
                          ? adminData.passwordConfirm
                          : adminData.passwordConfirm
                      }
                      name="passwordConfirm"
                      onChange={handleChangeAdminData}
                      error={adminFormErrors.passwordConfirm}
                      helperText={adminFormErrors.passwordConfirm}
                    />
                    {adminFormErrors.passwordConfirm && (
                      <Typography
                        variant="body2"
                        color="error"
                        sx={{ fontSize: "12px", mt: 1 }}
                      >
                        {adminFormErrors.passwordConfirm}
                      </Typography>
                    )}
                  </Box>
                </DialogContent>
                <DialogActions
                  sx={{ display: "flex", justifyContent: "space-end" }}
                >
                  <Button
                    className="filterApply"
                    onClick={handleAdminSubmit}
                    autoFocus
                    disabled={
                      adminFormErrors.nameSurname ||
                      adminFormErrors.email ||
                      adminFormErrors.password ||
                      adminFormErrors.passwordConfirm ||
                      adminData.nameSurname === "" ||
                      adminData.email === "" ||
                      adminData.password === "" ||
                      adminData.passwordConfirm === "" ||
                      addNewAdminLoading
                    }
                  >
                    {t("add")}
                  </Button>
                </DialogActions>
              </ChangeStatusDialog>
            </Box>
            <Box
              className="linkDialog"
              sx={{
                width: "470px!important",
                overflowX: "hidden",
              }}
            >
              <ChangeStatusDialog
                onClose={handleTestUserClose}
                aria-labelledby="customized-dialog-title"
                open={testUserDialog}
              >
                <DialogTitle
                  sx={{ m: 0, p: 2, color: theme.palette.primary.main }}
                  id="customized-dialog-title"
                >
                  {t("companyUser")}
                </DialogTitle>
                <IconButton
                  aria-label="close"
                  onClick={handleTestUserClose}
                  sx={{
                    position: "absolute",
                    right: 8,
                    top: 8,
                    color: (theme) => theme.palette.grey[500],
                  }}
                >
                  <CloseIcon />
                </IconButton>
                <DialogContent dividers>
                  <Box
                    sx={{
                      border: "1px solid #e0e0e0",
                      borderRadius: "4px",
                      pt: 1,
                    }}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        marginX: "10px",
                        marginBottom: "15px",
                      }}
                    >
                      <Typography
                        variant="body"
                        fontWeight="500"
                        color="primary"
                      >
                        {t("company")}
                      </Typography>
                      <FormControl sx={{ width: "100%" }} size="small">
                        {/* Single select */}
                        <MuiSelect
                          labelId="demo-multiple-2-checkbox-label"
                          id="demo-multiple-2-checkbox"
                          displayEmpty
                          value={testUserSelectedMerchant}
                          input={<BootstrapSelect />}
                          onChange={(e) =>
                            setTestUserSelectedMerchant(e.target.value)
                          }
                          renderValue={() => (
                            <div>
                              {testUserSelectedMerchant === "" ? (
                                <em
                                  style={{
                                    color: "currentColor",
                                    opacity: "0.42",
                                  }}
                                >
                                  {t("company")}
                                </em>
                              ) : (
                                merchantsFromOnlySellAndAcceptance.find(
                                  (option) =>
                                    option.id === testUserSelectedMerchant
                                ).title
                              )}
                            </div>
                          )}
                        >
                          {merchantsFromOnlySellAndAcceptance.map((opt) => (
                            <MenuItem key={opt.id} value={opt.id}>
                              {opt.title}
                            </MenuItem>
                          ))}
                        </MuiSelect>
                      </FormControl>
                      <FormControl
                        sx={{ width: "100%" }}
                        size="small"
                      ></FormControl>
                    </Box>
                    <Divider
                      sx={{
                        width: "95%",
                        marginX: "auto",
                        marginY: "10px",
                      }}
                    />
                  </Box>
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      marginX: "10px",
                      width: "420px",
                      mt: 1,
                    }}
                  >
                    <FormControl sx={{ width: "100%" }} size="small">
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={testUserIsSubmerchant}
                            onChange={handleTestUserIsSubmerchant}
                            color="success"
                          />
                        }
                        label="Submerchant"
                      />
                    </FormControl>
                  </Box>
                </DialogContent>
                <DialogActions
                  sx={{ display: "flex", justifyContent: "space-end" }}
                >
                  {submitSuccess ? (
                    <Button
                      className="filterApply"
                      onClick={handleChangeUser}
                      autoFocus
                    >
                      {t("signIn")}
                    </Button>
                  ) : (
                    <Button
                      className="filterApply"
                      onClick={handleTestUserSubmit}
                      autoFocus
                      disabled={submitLoading}
                    >
                      {t("select")}
                    </Button>
                  )}
                </DialogActions>
              </ChangeStatusDialog>
            </Box>
            <Snackbar
              open={isSnackbarOpen}
              autoHideDuration={6000}
              onClose={handleCloseSnackbar}
              message={successMessage}
            />
          </Box>
        </Box>
      );
    } else if (auth().role === "342267ab-f007-4928-8568-40c896951afa") {
      return (
        <Box m={"20px"}>
          <Topbar title="Kayıt Detayı">
            <Box>
              <Button
                id="basic-button"
                aria-controls={open ? "basic-menu" : undefined}
                aria-haspopup="true"
                aria-expanded={open ? "true" : undefined}
                onClick={handleClick}
                endIcon={<KeyboardArrowDownIcon />}
                className="filterReset"
                sx={{
                  ml: 2,
                  color: `${theme.palette.primary.fontColor}!important`,
                  textTransform: "capitalize",
                  border: `1px solid ${theme.palette.primary.bordersColor}`,
                  borderRadius: 0,
                  backgroundColor: `${theme.palette.primary.white}!important`,
                }}
              >
                {t("allActions")}
              </Button>
              <Menu
                id="basic-menu"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                MenuListProps={{
                  "aria-labelledby": "basic-button",
                }}
              >
                <MenuItem
                  onClick={handleFileInsertOpen}
                  sx={{
                    color: `${theme.palette.primary.fontColor}!important`,
                    pl: 2,
                    textTransform: "capitalize",
                    border: "none",
                    borderRadius: 0,
                  }}
                >
                  <ListItemIcon>
                    <NewProductIcon />
                  </ListItemIcon>
                  <ListItemText> {t("addFile")}</ListItemText>
                </MenuItem>
                <MenuItem
                  onClick={handleOpenAddNewPersonelDialog}
                  sx={{
                    color: `${theme.palette.primary.fontColor}!important`,
                    pl: 2,
                    textTransform: "capitalize",
                    border: "none",
                    borderRadius: 0,
                  }}
                >
                  <ListItemIcon>
                    <NewProductIcon />
                  </ListItemIcon>
                  <ListItemText> {t("addUser")}</ListItemText>
                </MenuItem>
                <MenuItem
                  onClick={handleOpenSendMessageDialog}
                  sx={{
                    color: `${theme.palette.primary.fontColor}!important`,
                    pl: 2,
                    textTransform: "capitalize",
                    border: "none",
                    borderRadius: 0,
                  }}
                >
                  <ListItemIcon>
                    <SendMessageIcon />
                  </ListItemIcon>
                  <ListItemText> {t("sendMessage")}</ListItemText>
                </MenuItem>
                <MenuItem
                  onClick={() => navigate("/payment-information")}
                  sx={{
                    color: `${theme.palette.primary.fontColor}!important`,
                    pl: 2,
                    textTransform: "capitalize",
                    border: "none",
                    borderRadius: 0,
                  }}
                >
                  <ListItemIcon>
                    <PaymentInformationIcon width="20" height="20" />
                  </ListItemIcon>
                  <ListItemText> {t("paymentInformations")}</ListItemText>
                </MenuItem>
                {(userFetchData && userFetchData.mmUser) ||
                (userFetchData && userFetchData.mmAccountManager) ? (
                  <MenuItem
                    onClick={handleOpenNewCalendlyDialog}
                    sx={{
                      color: `${theme.palette.primary.fontColor}!important`,
                      pl: 2,
                      textTransform: "capitalize",
                      border: "none",
                      borderRadius: 0,
                    }}
                  >
                    <ListItemIcon>
                      <CalendarIcon />
                    </ListItemIcon>
                    <ListItemText>{t("createAppointment")}</ListItemText>
                  </MenuItem>
                ) : null}
              </Menu>
            </Box>
          </Topbar>
          <Box>
            <Grid
              container
              spacing={0}
              sx={{
                background: theme.palette.primary.white,
                mt: 1,
                paddingLeft: 1,
                paddingRight: 1,
                borderRadius: 2,
              }}
            >
              <Grid item xs={12} sm={12} md={5} sx={{ p: "10px 3px" }}>
                <Item>
                  <Typography
                    variant="text"
                    color="primary"
                    sx={{ fontWeight: "bold" }}
                  >
                    {t("companyInfo")}
                  </Typography>
                  <Divider
                    variant="middle"
                    sx={{
                      margin: "10px 0px",
                      borderColor: `rgba(85, 56, 48, 0.09)`,
                    }}
                  />
                  <Box>
                    <Grid container>
                      <Grid item sm={6}>
                        <Typography>{t("companyName")}</Typography>
                      </Grid>
                      <Grid item sm={6}>
                        <Typography sx={typoCssProps}>
                          {userFetchData && userFetchData.title}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Box>
                  <Divider
                    variant="middle"
                    sx={{
                      margin: "10px 0px",
                      borderColor: `rgba(85, 56, 48, 0.09)`,
                    }}
                  />
                  <Box>
                    <Grid container>
                      <Grid item sm={6}>
                        <Typography>{t("website")}</Typography>
                      </Grid>
                      <Grid item sm={6}>
                        <Typography sx={typoCssProps}>
                          {userFetchData && userFetchData.webAddress}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Box>
                  <Divider
                    variant="middle"
                    sx={{
                      margin: "10px 0px",
                      borderColor: `rgba(85, 56, 48, 0.09)`,
                    }}
                  />
                  <Box>
                    <Grid container>
                      <Grid item sm={6}>
                        <Typography>{t("theCategory")}</Typography>
                      </Grid>
                      <Grid item sm={6}>
                        <Typography sx={typoCssProps}>
                          {userFetchData &&
                            userFetchData.merchantCategories.map(
                              (item, index) => (
                                <span key={index}>
                                  {index ===
                                  userFetchData.merchantCategories.length - 1
                                    ? item.title
                                    : item.title + ", "}
                                </span>
                              )
                            )}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Box>
                  <Divider
                    variant="middle"
                    sx={{
                      margin: "10px 0px",
                      borderColor: `rgba(85, 56, 48, 0.09)`,
                    }}
                  />
                  <Box>
                    <Grid container>
                      <Grid item sm={6}>
                        <Typography> {t("yourCompany")}</Typography>
                      </Grid>
                      <Grid item sm={6}>
                        <Typography sx={typoCssProps}>
                          {userFetchData && userFetchData.merchantType.title}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Box>
                  <Divider
                    variant="middle"
                    sx={{
                      margin: "10px 0px",
                      borderColor: `rgba(85, 56, 48, 0.09)`,
                    }}
                  />
                  <Box>
                    <Grid container sx={{ pb: 1 }}>
                      <Grid item sm={6}>
                        <Typography>{t("yourBrandOwnership")}</Typography>
                      </Grid>
                      <Grid item sm={6}>
                        <Typography sx={typoCssProps}>
                          {userFetchData &&
                            userFetchData.merchantOwnerOfBrand.title}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Box>
                </Item>
                <Item style={{ marginTop: "10px" }}>
                  <Typography
                    variant="text"
                    color="primary"
                    sx={{ fontWeight: "bold" }}
                  >
                    {t("registrationStatus")}
                  </Typography>
                  <Divider
                    variant="middle"
                    sx={{
                      margin: "10px 0px",
                      borderColor: `rgba(85, 56, 48, 0.09)`,
                    }}
                  />
                  <Box>
                    <Grid container>
                      <Grid item sm={6}>
                        <Typography>{t("status")}</Typography>
                      </Grid>
                      <Grid item sm={6} sx={{ pb: 1 }}>
                        <Chip
                          sx={{ borderRadius: "5px", color: "#fff" }}
                          size="small"
                          label={
                            userFetchData && userFetchData.merchantStatus.title
                          }
                          color={
                            userFetchData &&
                            userFetchData.merchantStatus.id ===
                              "00be09db-05e4-4b98-b1d4-3b81d40be94d"
                              ? "success"
                              : "error"
                          }
                        />
                      </Grid>
                    </Grid>
                  </Box>
                </Item>
                <Item style={{ marginTop: "10px" }}>
                  <Typography
                    variant="text"
                    color="primary"
                    sx={{ fontWeight: "bold" }}
                  >
                    {t("contactInformation")}
                  </Typography>
                  <Divider
                    variant="middle"
                    sx={{
                      margin: "10px 0px",
                      borderColor: `rgba(85, 56, 48, 0.09)`,
                    }}
                  />
                  <Box>
                    <Grid container>
                      <Grid item sm={6}>
                        <Typography>{t("nameSurname")}</Typography>
                      </Grid>
                      <Grid item sm={6}>
                        <Typography sx={typoCssProps}>
                          {userFetchData && userFetchData.users[0].nameSurname}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Box>
                  <Divider
                    variant="middle"
                    sx={{
                      margin: "10px 0px",
                      borderColor: `rgba(85, 56, 48, 0.09)`,
                    }}
                  />
                  <Box>
                    <Grid container>
                      <Grid item sm={6}>
                        <Typography>{t("phone")}</Typography>
                      </Grid>
                      <Grid item sm={6}>
                        <Typography sx={typoCssProps}>
                          {userFetchData && userFetchData.phoneNumber}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Box>
                  <Divider
                    variant="middle"
                    sx={{
                      margin: "10px 0px",
                      borderColor: `rgba(85, 56, 48, 0.09)`,
                    }}
                  />
                  <Box>
                    <Grid container>
                      <Grid item sm={6}>
                        <Typography>{t("email")}</Typography>
                      </Grid>
                      <Grid item sm={6} sx={{ pb: 1 }}>
                        <Typography sx={typoCssProps}>
                          {userFetchData && userFetchData.users[0].email}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Box>
                </Item>
                <Item style={{ marginTop: "10px" }}>
                  <Typography
                    variant="text"
                    color="primary"
                    sx={{ fontWeight: "bold" }}
                  >
                    {t("details")}
                  </Typography>
                  <Divider
                    variant="middle"
                    sx={{
                      margin: "10px 0px",
                      borderColor: `rgba(85, 56, 48, 0.09)`,
                    }}
                  />
                  <Box>
                    <Grid container>
                      <Grid item sm={6}>
                        <Typography>{t("originOfProducts")}</Typography>
                      </Grid>
                      <Grid item sm={6}>
                        <Typography sx={typoCssProps}>
                          {userFetchData &&
                            userFetchData.productOrigins.map((item, index) => (
                              <span key={index}>
                                {index ===
                                userFetchData.productOrigins.length - 1
                                  ? item.country.title
                                  : item.country.title + ", "}
                              </span>
                            ))}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Box>
                  <Divider
                    variant="middle"
                    sx={{
                      margin: "10px 0px",
                      borderColor: `rgba(85, 56, 48, 0.09)`,
                    }}
                  />
                  <Box>
                    <Grid container>
                      <Grid item sm={6}>
                        <Typography>{t("numberOfProducts")}</Typography>
                      </Grid>
                      <Grid item sm={6}>
                        <Typography sx={typoCssProps}>
                          {userFetchData &&
                            userFetchData.merchantProductCount.title}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Box>
                  <Divider
                    variant="middle"
                    sx={{
                      margin: "10px 0px",
                      borderColor: `rgba(85, 56, 48, 0.09)`,
                    }}
                  />
                  <Box>
                    <Grid container>
                      <Grid item sm={6}>
                        <Typography>{t("doYouDoECommerce")}</Typography>
                      </Grid>
                      <Grid item sm={6}>
                        <Typography sx={typoCssProps}>
                          {userFetchData && userFetchData.isEcommerce === true
                            ? "Evet"
                            : "Hayır"}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Box>

                  {userFetchData && userFetchData.isEcommerce === true ? (
                    <Box>
                      {" "}
                      <Divider
                        variant="middle"
                        sx={{
                          margin: "10px 0px",
                          borderColor: `rgba(85, 56, 48, 0.09)`,
                        }}
                      />
                      <Box>
                        <Grid
                          container
                          style={{ lineBreak: "anywhere!important" }}
                        >
                          <Grid item sm={6}>
                            <Typography>{t("eCommerceStoreLink")}</Typography>
                          </Grid>
                          <Grid
                            item
                            sm={6}
                            style={{ lineBreak: "anywhere!important" }}
                          >
                            <Typography
                              sx={(typoCssProps, { wordWrap: "break-word" })}
                            >
                              {userFetchData &&
                                userFetchData.merchnantEcommerceUrls.map(
                                  (item, index) => (
                                    <a
                                      href={`${item.title}`}
                                      target="_blank"
                                      key={index}
                                      rel="noopener noreferrer"
                                      style={{
                                        textDecoration: "none",
                                        color: `${theme.palette.primary.main}
                              `,
                                      }}
                                    >
                                      {
                                        item.title
                                          .replace("www.", "")
                                          .replace(
                                            /(http(s)?:\/\/)?(www\.)?/g,
                                            ""
                                          )
                                          .split("/")[0]
                                          .split(".")[0]
                                      }
                                    </a>
                                  )
                                )}
                            </Typography>
                          </Grid>
                        </Grid>
                      </Box>
                    </Box>
                  ) : (
                    ""
                  )}
                  <Divider
                    variant="middle"
                    sx={{
                      margin: "10px 0px",
                      borderColor: `rgba(85, 56, 48, 0.09)`,
                    }}
                  />
                  <Box>
                    <Grid container>
                      <Grid item sm={6}>
                        <Typography>{t("doYouExport")}</Typography>
                      </Grid>
                      <Grid item sm={6}>
                        <Typography sx={typoCssProps}>
                          {userFetchData && userFetchData.isExport === true
                            ? "Evet"
                            : "Hayır"}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Box>
                  <Divider
                    variant="middle"
                    sx={{
                      margin: "10px 0px",
                      borderColor: `rgba(85, 56, 48, 0.09)`,
                    }}
                  />
                  <Box>
                    <Grid container>
                      <Grid item sm={6}>
                        <Typography>{t("integrationUsage")}</Typography>
                      </Grid>
                      <Grid item sm={6} sx={{ pb: 1 }}>
                        <Typography sx={typoCssProps}>
                          {userFetchData && userFetchData.isIntegration === true
                            ? "Evet"
                            : "Hayır"}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Box>
                </Item>
                {/* Sozlesme detaylari */}
                <Item style={{ marginTop: "10px" }}>
                  <Typography
                    variant="text"
                    color="primary"
                    sx={{ fontWeight: "bold" }}
                  >
                    {t("contractDetails")}
                  </Typography>
                  <Divider
                    variant="middle"
                    sx={{
                      margin: "10px 0px",
                      marginBottom: "20px",
                      borderColor: `rgba(85, 56, 48, 0.09)`,
                    }}
                  />
                  <Typography color="primary" sx={{ fontSize: "16px" }}>
                    {t("businessModel")}
                  </Typography>

                  <Divider
                    variant="middle"
                    sx={{
                      margin: "10px 0px",
                      borderColor: `rgba(85, 56, 48, 0.09)`,
                    }}
                  />
                  <Box>
                    <Grid container>
                      <Grid item sm={6}>
                        <Typography>{t("businessModel")}</Typography>
                      </Grid>
                      <Grid item sm={6}>
                        {userFetchData &&
                        userFetchData.merchantBusinessTypeId ? (
                          <Typography sx={typoCssProps}>
                            {userFetchData.merchantBusinessType.id &&
                              userFetchData.merchantBusinessType.title}{" "}
                            <span>
                              {userFetchData.merchantBusinessType.id ===
                              "3552203d-03a5-4d39-92b8-410d3e581b40"
                                ? userFetchData.merchantMarketPlaces.map(
                                    (item, index) => (
                                      <span key={index}>
                                        (
                                        {index ===
                                        userFetchData.merchantMarketPlaces
                                          .length -
                                          1
                                          ? item.title
                                          : item.title}
                                        ) {""}
                                      </span>
                                    )
                                  )
                                : ""}
                            </span>
                          </Typography>
                        ) : (
                          ""
                        )}
                      </Grid>
                    </Grid>
                  </Box>
                  <Divider
                    variant="middle"
                    sx={{
                      margin: "10px 0px",
                      borderColor: `rgba(85, 56, 48, 0.09)`,
                    }}
                  />
                  <Box>
                    <Grid container>
                      <Grid item sm={6}>
                        <Typography>{t("exporter")}</Typography>
                      </Grid>
                      <Grid item sm={6}>
                        {userFetchData && userFetchData.merchantExporter ? (
                          <Typography sx={typoCssProps}>
                            {userFetchData.merchantExporter.id &&
                              userFetchData.merchantExporter.title}
                          </Typography>
                        ) : (
                          ""
                        )}
                      </Grid>
                    </Grid>
                  </Box>
                  <Divider
                    variant="middle"
                    sx={{
                      margin: "10px 0px",
                      borderColor: `rgba(85, 56, 48, 0.09)`,
                    }}
                  />
                  <Box>
                    <Grid container>
                      <Grid item sm={6}>
                        <Typography>{t("platforms")}</Typography>
                      </Grid>
                      <Grid item sm={6}>
                        <Typography sx={typoCssProps}>
                          {userFetchData &&
                            userFetchData.platforms.length > 0 &&
                            userFetchData.platforms.map((item, index) => (
                              <span key={index}>
                                {index === userFetchData.platforms.length - 1
                                  ? item.title
                                  : item.title + ", "}
                              </span>
                            ))}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Box>
                  <Divider
                    variant="middle"
                    sx={{
                      margin: "10px 0px",
                      borderColor: `rgba(85, 56, 48, 0.09)`,
                    }}
                  />
                  <Box>
                    <Grid container>
                      <Grid item sm={6}>
                        <Typography>{t("countries")}</Typography>
                      </Grid>
                      <Grid item sm={6}>
                        <Typography sx={typoCssProps}>
                          {userFetchData &&
                            userFetchData.countries.length > 0 &&
                            userFetchData.countries.map((item, index) => (
                              <span key={index}>
                                {index === userFetchData.countries.length - 1
                                  ? item.title
                                  : item.title + ", "}
                              </span>
                            ))}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Box>
                  {/* ... */}
                  <Divider
                    variant="middle"
                    sx={{
                      margin: "10px 0px",
                      marginBottom: "20px",
                      borderColor: `rgba(85, 56, 48, 0.09)`,
                    }}
                  />
                  <Typography color="primary" sx={{ fontSize: "16px" }}>
                    {t("financialDetails")}
                  </Typography>
                  <Divider
                    variant="middle"
                    sx={{
                      margin: "10px 0px",
                      borderColor: `rgba(85, 56, 48, 0.09)`,
                    }}
                  />
                  <Box>
                    <Grid container>
                      <Grid item sm={10}>
                        <Typography>{t("advertisingBudget")}</Typography>
                      </Grid>
                      <Grid item sm={2}>
                        {userFetchData && userFetchData.annualServiceFee ? (
                          <Typography sx={typoCssProps}>
                            {userFetchData.annualServiceFee &&
                              userFetchData.annualServiceFee}{" "}
                            {userFetchData.currency &&
                              userFetchData.currency.title}
                          </Typography>
                        ) : (
                          ""
                        )}
                      </Grid>
                    </Grid>
                  </Box>
                  <Divider
                    variant="middle"
                    sx={{
                      margin: "10px 0px",
                      borderColor: `rgba(85, 56, 48, 0.09)`,
                    }}
                  />
                  <Box>
                    <Grid container>
                      <Grid item sm={6}>
                        <Typography>{t("salesCommission")}</Typography>
                      </Grid>
                      <Grid item sm={6}>
                        <Typography sx={typoCssProps}>
                          {userFetchData && userFetchData.salesCommission ? (
                            <Typography sx={typoCssProps}>
                              %
                              {userFetchData.salesCommission &&
                                userFetchData.salesCommission}{" "}
                              {userFetchData.currency &&
                                userFetchData.currency.name}
                            </Typography>
                          ) : (
                            ""
                          )}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Box>
                  <Divider
                    variant="middle"
                    sx={{
                      margin: "10px 0px",
                      borderColor: `rgba(85, 56, 48, 0.09)`,
                    }}
                  />
                  <Box>
                    <Grid container>
                      <Grid item sm={6}>
                        <Typography>{t("advertisingCommission")}</Typography>
                      </Grid>
                      <Grid item sm={6}>
                        <Typography sx={typoCssProps}>
                          {userFetchData &&
                          userFetchData.advertisingCommission ? (
                            <Typography sx={typoCssProps}>
                              %
                              {userFetchData.advertisingCommission &&
                                userFetchData.advertisingCommission}{" "}
                              {userFetchData.currency &&
                                userFetchData.currency.name}
                            </Typography>
                          ) : (
                            ""
                          )}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Box>
                  <Divider
                    variant="middle"
                    sx={{
                      margin: "10px 0px",
                      borderColor: `rgba(85, 56, 48, 0.09)`,
                    }}
                  />
                  <Box>
                    <Grid container>
                      <Grid item sm={6}>
                        <Typography>{t("incentiveCommission")}</Typography>
                      </Grid>
                      <Grid item sm={6}>
                        <Typography sx={typoCssProps}>
                          {/* {userFetchData && userFetchData.incentiveCommission} */}
                          {userFetchData &&
                          userFetchData.incentiveCommission ? (
                            <Typography sx={typoCssProps}>
                              %
                              {userFetchData.incentiveCommission &&
                                userFetchData.incentiveCommission}{" "}
                            </Typography>
                          ) : (
                            ""
                          )}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Box>
                  <Divider
                    variant="middle"
                    sx={{
                      margin: "10px 0px",
                      borderColor: `rgba(85, 56, 48, 0.09)`,
                    }}
                  />
                  <Box>
                    <Grid container>
                      <Grid item sm={6}>
                        <Typography>{t("maturity")}</Typography>
                      </Grid>
                      <Grid item sm={6}>
                        {userFetchData && userFetchData.paymentTerm ? (
                          <Typography sx={typoCssProps}>
                            {userFetchData.paymentTerm &&
                              userFetchData.paymentTerm}{" "}
                            {t("day")}
                          </Typography>
                        ) : (
                          ""
                        )}
                      </Grid>
                    </Grid>
                  </Box>
                  <Divider
                    variant="middle"
                    sx={{
                      margin: "10px 0px",
                      borderColor: `rgba(85, 56, 48, 0.09)`,
                    }}
                  />
                  <Box>
                    <Grid container>
                      <Grid item sm={6}>
                        <Typography>{t("paymentMethod")}</Typography>
                      </Grid>
                      <Grid item sm={6} sx={{ pb: 1 }}>
                        {userFetchData && userFetchData.paymentMethod ? (
                          <Typography sx={typoCssProps}>
                            {userFetchData.paymentMethod.title &&
                              userFetchData.paymentMethod.title}
                          </Typography>
                        ) : (
                          ""
                        )}
                      </Grid>
                    </Grid>
                  </Box>
                </Item>
              </Grid>
              <Grid item xs={12} sm={12} md={7} sx={{ p: "10px 3px" }}>
                <Item>
                  <Typography
                    variant="text"
                    color="primary"
                    sx={{ fontWeight: "bold" }}
                  >
                    {t("attachments")}
                  </Typography>
                  <Box>
                    <Box>
                      <Tabs
                        value={selectedIndex}
                        onChange={handleTabChange}
                        aria-label="basic tabs example"
                        className="merchantDetailTabContainer"
                        sx={{
                          mt: 1,
                        }}
                      >
                        <Tab
                          sx={{
                            textTransform: "capitalize",
                            color: theme.palette.text.primary,
                            border: "1px solid #45383426",
                            pt: 2,
                          }}
                          className="merchantDetailTab"
                          label={t("files")}
                          {...a11yProps(0)}
                          icon={
                            fileRows.length > 0 ? (
                              <Box
                                sx={{
                                  width: "10px",
                                  height: "10px",
                                  borderRadius: "50%",
                                  backgroundColor: "#EB603A",
                                }}
                              ></Box>
                            ) : (
                              ""
                            )
                          }
                          iconPosition="start"
                        />
                        <Tab
                          sx={{
                            textTransform: "capitalize",
                            color: theme.palette.text.primary,
                            border: "1px solid #45383426",
                            borderLeft: "none",
                            pt: 2,
                          }}
                          className="merchantDetailTab"
                          label={t("links")}
                          {...a11yProps(1)}
                          icon={
                            links.length > 0 ? (
                              <Box
                                sx={{
                                  width: "10px",
                                  height: "10px",
                                  borderRadius: "50%",
                                  backgroundColor: "#EB603A",
                                }}
                              ></Box>
                            ) : (
                              ""
                            )
                          }
                          iconPosition="start"
                        />
                        <Tab
                          sx={{
                            textTransform: "capitalize",
                            color: theme.palette.text.primary,
                            border: "1px solid #45383426",
                            borderLeft: "none",
                            pt: 2,
                          }}
                          className="merchantDetailTab"
                          label={t("messages")}
                          {...a11yProps(2)}
                          icon={
                            messages && messages.length > 0 ? (
                              <Box
                                sx={{
                                  width: "10px",
                                  height: "10px",
                                  borderRadius: "50%",
                                  backgroundColor: "#EB603A",
                                }}
                              ></Box>
                            ) : (
                              ""
                            )
                          }
                          iconPosition="start"
                        />
                        <Tab
                          sx={{
                            textTransform: "capitalize",
                            color: theme.palette.text.primary,
                            border: "1px solid #45383426",
                            borderLeft: "none",
                            pt: 2,
                          }}
                          className="merchantDetailTab"
                          label={t("users")}
                          {...a11yProps(3)}
                          icon={
                            userFetchData && userFetchData.users.length > 0 ? (
                              <Box
                                sx={{
                                  width: "10px",
                                  height: "10px",
                                  borderRadius: "50%",
                                  backgroundColor: "#EB603A",
                                }}
                              ></Box>
                            ) : (
                              ""
                            )
                          }
                          iconPosition="start"
                        />
                        <Tab
                          sx={{
                            textTransform: "capitalize",
                            color: theme.palette.text.primary,
                            border: "1px solid #45383426",
                            borderLeft: "none",
                            pt: 2,
                          }}
                          className="merchantDetailTab"
                          label={t("myBrands")}
                          {...a11yProps(4)}
                          icon={
                            userBrands && userBrands.length > 0 ? (
                              <Box
                                sx={{
                                  width: "10px",
                                  height: "10px",
                                  borderRadius: "50%",
                                  backgroundColor: "#EB603A",
                                }}
                              ></Box>
                            ) : (
                              ""
                            )
                          }
                          iconPosition="start"
                        />
                      </Tabs>
                    </Box>
                    <CustomTabPanel value={selectedIndex} index={0}>
                      <TableContainer
                        sx={{
                          boxShadow: "none!important",
                          borderBottom: "none!important",
                          borderRadius: "0!important",
                          paddingBottom: "10px",
                        }}
                        component={Paper}
                      >
                        <Table sx={{ minWidth: 650 }} aria-label="simple table">
                          <TableHead sx={{ border: "1px solid #e0e0e0" }}>
                            <TableRow>
                              <TableCell align="left">
                                {t("fileName")}
                              </TableCell>
                              {/* <TableCell align="left">YÃ¼klenme Tarihi</TableCell> */}
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {/* TODO: map edilecek */}
                            {fileRows.map((row) => (
                              <TableRow className="stock-table" key={row.id}>
                                <TableCell
                                  sx={{
                                    paddingTop: "5px!important",
                                    paddingBottom: "5px!important",
                                  }}
                                  width="70%"
                                  align="left"
                                >
                                  <Typography>
                                    <a
                                      href={row.path + "/" + row.fileName}
                                      target="_blank"
                                      style={{
                                        color: theme.palette.secondary.main,
                                        textDecoration: "none",
                                        fontWeight: "500",
                                      }}
                                    >
                                      {row.fileOrginalName}
                                    </a>
                                  </Typography>
                                </TableCell>
                                {/* <TableCell
                          sx={{
                            paddingTop: "5px!important",
                            paddingBottom: "5px!important",
                          }}
                          width="20%"
                          align="left"
                        >
                          Date bekleniyor
                        </TableCell>
*/}
                                {/* <TableCell
                                  sx={{
                                    paddingTop: "5px!important",
                                    paddingBottom: "5px!important",
                                  }}
                                  width="5%"
                                  align="center"
                                >
                                  <IconButton
                                    onClick={() => deleteDocument(row.id)}
                                    aria-label="delete"
                                    size="small"
                                  >
                                    <DeleteIcon />
                                  </IconButton>
                                </TableCell> */}
                              </TableRow>
                            ))}
                          </TableBody>
                        </Table>
                      </TableContainer>
                    </CustomTabPanel>
                    <CustomTabPanel value={selectedIndex} index={1}>
                      <TableContainer
                        sx={{
                          boxShadow: "none!important",
                          borderBottom: "none!important",
                          borderRadius: "0!important",
                          paddingBottom: "10px",
                        }}
                        component={Paper}
                      >
                        <Table sx={{ minWidth: 650 }} aria-label="simple table">
                          <TableHead sx={{ border: "1px solid #e0e0e0" }}>
                            <TableRow>
                              <TableCell align="left">
                                {t("linkName")}
                              </TableCell>
                              {/* <TableCell align="left">YÃ¼klenme Tarihi</TableCell> */}
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {/* TODO: map edilecek */}
                            {links.map((row) => (
                              <TableRow className="stock-table" key={row.id}>
                                <TableCell
                                  sx={{
                                    paddingTop: "5px!important",
                                    paddingBottom: "5px!important",
                                  }}
                                  width="70%"
                                  align="left"
                                >
                                  <Typography>
                                    <a
                                      href={row.path}
                                      target="_blank"
                                      rel="noopener noreferrer"
                                      style={{
                                        color: theme.palette.secondary.main,
                                        textDecoration: "none",
                                        fontWeight: "500",
                                      }}
                                    >
                                      {row.title}
                                    </a>
                                  </Typography>
                                </TableCell>
                              </TableRow>
                            ))}
                          </TableBody>
                        </Table>
                      </TableContainer>
                    </CustomTabPanel>
                    <CustomTabPanel value={selectedIndex} index={2}>
                      <TableContainer
                        sx={{
                          boxShadow: "none!important",
                          borderBottom: "none!important",
                          borderRadius: "0!important",
                          paddingBottom: "10px",
                        }}
                        component={Paper}
                      >
                        <Table sx={{ minWidth: 650 }} aria-label="simple table">
                          <TableHead sx={{ border: "1px solid #e0e0e0" }}>
                            <TableRow>
                              <TableCell align="left">
                                {t("messages")}
                              </TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {/* TODO: map edilecek */}
                            {messages &&
                              messages.map((row) => (
                                <TableRow className="stock-table" key={row.id}>
                                  <TableCell
                                    sx={{
                                      paddingTop: "5px!important",
                                      paddingBottom: "5px!important",
                                    }}
                                    width="100%"
                                    align="left"
                                  >
                                    <Box>
                                      <Box
                                        sx={{
                                          display: "flex",
                                          flexDirection: "row",
                                          justifyContent: "space-between",
                                          alignItems: "center",
                                          paddingBottom: "10px",
                                          paddingTop: "10px",
                                        }}
                                      >
                                        <Box
                                          sx={{
                                            display: "flex",
                                            flexDirection: "row",
                                            alignItems: "center",
                                          }}
                                        >
                                          <Avatar
                                            src={`${row.avatar}`}
                                            sx={
                                              row.createdUser.roleName ===
                                              "MM Admin"
                                                ? {
                                                    backgroundColor:
                                                      "#EB603A1e",
                                                    color: "#EB603A",
                                                    width: 36,
                                                    height: 36,
                                                  }
                                                : {
                                                    backgroundColor:
                                                      "#486ABD1e",
                                                    color: "#486ABD",
                                                    width: 36,
                                                    height: 36,
                                                  }
                                            }
                                          >
                                            <Typography
                                              sx={{
                                                fontSize: "1.3rem",
                                              }}
                                            >
                                              {row.createdUser.nameSurname[0].toUpperCase()}
                                            </Typography>
                                          </Avatar>
                                          <Typography
                                            sx={{
                                              ml: 1,
                                              display: "inline-block",
                                              fontWeight: "500",
                                              fontSize: "14px",
                                            }}
                                          >
                                            {row.createdUser.nameSurname}
                                          </Typography>
                                          <Typography
                                            sx={{
                                              display: "inline-block",
                                              fontStyle: "italic",
                                              fontSize: "14px",
                                              ml: 1,
                                              mr: 1,
                                              color: `#453834 `,
                                              opacity: "0.6",
                                            }}
                                          >
                                            {" - "}
                                            {row.createdUser.roleName ===
                                            "MM Admin"
                                              ? "MegaMerchant"
                                              : userFetchData &&
                                                userFetchData.title}
                                          </Typography>
                                        </Box>
                                        <Typography>
                                          {row.createdAt
                                            ? new Date(
                                                row.createdAt
                                              ).toLocaleString("tr-TR")
                                            : ""}
                                        </Typography>
                                      </Box>
                                      <Typography>{row.messageBody}</Typography>
                                      <Box
                                        sx={{
                                          display: "flex",
                                          flexDirection: "row",
                                          justifyContent: "flex-start",
                                          gap: "10px",
                                          alignItems: "center",
                                          paddingTop: "20px",
                                          paddingBottom: "10px",
                                        }}
                                      >
                                        {row.attachments !== null &&
                                        row.attachments !== undefined
                                          ? row.attachments.map(
                                              (item, index) => (
                                                <Box
                                                  sx={{
                                                    display: "flex",
                                                    flexDirection: "row",
                                                    alignItems: "center",
                                                    border:
                                                      "1px solid #45383426",
                                                    padding: "10px 5px",
                                                    marginBottom: "10px",
                                                    borderRadius: "4px",
                                                    width: "180px",
                                                    gap: "5px",
                                                    justifyContent: "center",
                                                  }}
                                                  key={index}
                                                >
                                                  <MessageFIleIcon />
                                                  <a
                                                    href={`https://mmfilestorageaccount.blob.core.windows.net/${item.path}/${item.title}`}
                                                    target="_blank"
                                                    rel="noopener noreferrer"
                                                    style={{
                                                      color:
                                                        theme.palette.secondary
                                                          .main,
                                                      textDecoration: "none",
                                                      fontWeight: "500",
                                                      cursor: "pointer",
                                                    }}
                                                  >
                                                    {item.fileOrginalName}
                                                  </a>
                                                </Box>
                                              )
                                            )
                                          : null}
                                      </Box>
                                    </Box>
                                  </TableCell>
                                </TableRow>
                              ))}
                          </TableBody>
                        </Table>
                      </TableContainer>
                    </CustomTabPanel>
                    <CustomTabPanel value={selectedIndex} index={3}>
                      <TableContainer
                        sx={{
                          boxShadow: "none!important",
                          borderBottom: "none!important",
                          borderRadius: "0!important",
                          paddingBottom: "10px",
                        }}
                        component={Paper}
                      >
                        <Table sx={{ minWidth: 650 }} aria-label="simple table">
                          <TableHead sx={{ border: "1px solid #e0e0e0" }}>
                            <TableRow>
                              <TableCell align="left">
                                {t("nameSurname")}
                              </TableCell>
                              <TableCell align="left">{t("email")}</TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {/* TODO: map edilecek */}
                            {getMerchantUsers.map((row) => (
                              <TableRow className="stock-table" key={row.id}>
                                <TableCell
                                  sx={{
                                    paddingTop: "10px!important",
                                    paddingBottom: "10px!important",
                                  }}
                                  width="50%"
                                  align="left"
                                >
                                  <Typography>{row.nameSurname}</Typography>
                                </TableCell>
                                <TableCell
                                  sx={{
                                    paddingTop: "10px!important",
                                    paddingBottom: "10px!important",
                                  }}
                                  width="50%"
                                  align="left"
                                >
                                  <Typography>{row.email}</Typography>
                                </TableCell>
                              </TableRow>
                            ))}
                          </TableBody>
                        </Table>
                      </TableContainer>
                    </CustomTabPanel>
                    <CustomTabPanel value={selectedIndex} index={4}>
                      <TableContainer
                        sx={{
                          boxShadow: "none!important",
                          borderBottom: "none!important",
                          borderRadius: "0!important",
                          paddingBottom: "10px",
                        }}
                        component={Paper}
                      >
                        <Table sx={{ minWidth: 650 }} aria-label="simple table">
                          <TableHead sx={{ border: "1px solid #e0e0e0" }}>
                            <TableRow>
                              <TableCell align="left">{t("brand")}</TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {/* TODO: map edilecek */}
                            {userBrands.map((row) => (
                              <TableRow className="stock-table" key={row.id}>
                                <TableCell
                                  sx={{
                                    paddingTop: "10px!important",
                                    paddingBottom: "10px!important",
                                  }}
                                  width="50%"
                                  align="left"
                                >
                                  <Typography>{row.title}</Typography>
                                </TableCell>
                              </TableRow>
                            ))}
                          </TableBody>
                        </Table>
                      </TableContainer>
                    </CustomTabPanel>
                  </Box>
                </Item>
              </Grid>
            </Grid>
          </Box>
          <Box className="fileDialog">
            <Dialog
              maxWidth="md"
              fullWidth={true}
              open={openFileInsert}
              onClose={handleFileInsertClose}
            >
              <DialogTitle
                sx={{ m: 0, p: 2, color: theme.palette.primary.main }}
                id="customized-dialog-title"
              >
                {t("addFile")}
              </DialogTitle>
              <IconButton
                aria-label="close"
                onClick={handleFileInsertClose}
                sx={{
                  position: "absolute",
                  right: 8,
                  top: 8,
                  color: (theme) => theme.palette.grey[500],
                }}
              >
                <CloseIcon />
              </IconButton>
              <Divider />
              <DialogContent>
                <Box p={3} pt={0} pb={0}>
                  <ul style={{ paddingInlineStart: "10px" }}>
                    <li>
                      {t("taxCertificate")}{" "}
                      <span style={{ color: "#D7283E" }}>*</span>
                    </li>
                    <li>
                      {t("signatureCircular")}{" "}
                      <span style={{ color: "#D7283E" }}>*</span>
                    </li>
                    <li>
                      {t("tradeRegistryGazette")}
                      <span style={{ color: "#D7283E" }}> *</span>
                    </li>
                    <li>{t("trademarkRegistrationCertificate")}</li>
                    <li>{t("foreignRegistrationCertificate")}</li>
                  </ul>
                </Box>
                <Box p={3}>
                  <Paper
                    elevation={3}
                    sx={{
                      padding: 3,
                      textAlign: "center",
                      border: "2px dashed #ddd",
                      borderRadius: "8px",
                    }}
                  >
                    <div
                      {...getRootProps()}
                      className={`dropzone ${isDragAccept ? "active" : ""} ${
                        isDragReject ? "reject" : ""
                      }`}
                    >
                      <input {...getInputProps()} />
                      <Typography variant="h6" gutterBottom>
                        {t("fileUpload")}
                      </Typography>
                      <Typography variant="body2" color="textSecondary">
                        {t("dragYourFilesHereOrClickToSelectThem")}
                      </Typography>
                    </div>
                    {uploadError && <div>{uploadError}</div>}
                    {uploadedFiles.length > 0 && (
                      <div className="uploaded-files">
                        {uploadedFiles.map((file, index) => (
                          <Chip
                            key={index}
                            label={file.name}
                            onDelete={() => handleDeleteFile(file.name)}
                            sx={{ margin: "0.2rem" }}
                          />
                        ))}
                      </div>
                    )}
                    {progress > 0 && (
                      <LinearProgress
                        variant="determinate"
                        value={progress}
                        sx={{ marginTop: 2 }}
                      />
                    )}
                    <Button
                      variant="contained"
                      color="primary"
                      type="submit"
                      disabled={uploadedFiles.length === 0}
                      onClick={handleSubmitFile}
                      sx={{ marginTop: 2 }}
                    >
                      {t("send")}
                    </Button>
                  </Paper>
                </Box>
              </DialogContent>
            </Dialog>
            <Snackbar
              open={isSnackbarOpen}
              autoHideDuration={6000}
              onClose={handleCloseSnackbar}
              message={successMessage}
            />
          </Box>
          <Box className="personelDialog">
            <Dialog
              maxWidth="sm"
              fullWidth={true}
              open={openAddNewPersonelDialog}
              onClose={handleCloseAddNewPersonelDialog}
            >
              <DialogTitle
                sx={{ m: 0, p: 2, color: theme.palette.primary.main }}
                id="customized-dialog-title"
              >
                {t("addUser")}
              </DialogTitle>
              <IconButton
                aria-label="close"
                onClick={handleCloseAddNewPersonelDialog}
                sx={{
                  position: "absolute",
                  right: 8,
                  top: 8,
                  color: (theme) => theme.palette.grey[500],
                }}
              >
                <CloseIcon />
              </IconButton>
              <Divider />
              <DialogContent>
                {/* nameSurname
                Username
                email
                password */}
                <Box
                  component="form"
                  sx={{
                    backgroundColor: "#fff",
                    p: 2,
                    pt: 0,
                    "& > :not(style)": { m: 1 },
                  }}
                  noValidate
                  autoComplete="off"
                >
                  <FormGroup>
                    <FormControl variant="standard" key="nameSurname">
                      <CustomLabel title="İsim Soyisim" isRequired />
                      <BootstrapInput
                        placeholder={t("exNameSurname")}
                        onChange={newUserNameSurnameChangeHandler}
                        value={personelNameSurname}
                        name="newUserNameSurname"
                        className={formErrors.newUserNameSurname ? "error" : ""}
                      />
                      {formErrors.newUserNameSurname && (
                        <span className="error-message">
                          {formErrors.newUserNameSurname}
                        </span>
                      )}
                    </FormControl>
                    <FormControl
                      sx={{
                        mb: 2,
                      }}
                      variant="standard"
                      key="email"
                    >
                      <CustomLabel title="Email" isRequired />
                      <BootstrapInput
                        placeholder="Ör: email@example.com"
                        onChange={newUserEmailChangeHandler}
                        value={personelEmail}
                        name="newUserEmail"
                        className={formErrors.newUserEmail ? "error" : ""}
                      />
                      {formErrors.newUserEmail && (
                        <span className="error-message">
                          {formErrors.newUserEmail}
                        </span>
                      )}
                    </FormControl>
                  </FormGroup>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      mt: 2,
                    }}
                  >
                    <Button
                      className="filterReset"
                      variant="contained"
                      onClick={handleCloseAddNewPersonelDialog}
                    >
                      {t("close")}
                    </Button>
                    <Button
                      onClick={handleAddNewPersonel}
                      className="filterApply"
                      variant="contained"
                      color="primary"
                      type="submit"
                      disabled={
                        personelNameSurname === "" ||
                        personelEmail === "" ||
                        formErrors.newUserNameSurname ||
                        formErrors.newUserEmail ||
                        addNewPersonelLoading
                      }
                    >
                      {t("save")}
                    </Button>
                  </Box>
                </Box>
              </DialogContent>
            </Dialog>
            <Snackbar
              open={isSnackbarOpen}
              autoHideDuration={6000}
              onClose={handleCloseSnackbar}
              message={successMessage}
            />
          </Box>
          <Box className="calendlyDialog">
            <Dialog
              maxWidth="sm"
              fullWidth={true}
              open={openNewCalendlyDialog}
              onClose={handleCloseNewCalendlyDialog}
            >
              <DialogTitle
                sx={{ m: 0, p: 2, color: theme.palette.primary.main }}
                id="customized-dialog-title"
              >
                {t("createAppointment")}
              </DialogTitle>
              <IconButton
                aria-label="close"
                onClick={handleCloseNewCalendlyDialog}
                sx={{
                  position: "absolute",
                  right: 8,
                  top: 8,
                  color: (theme) => theme.palette.grey[500],
                }}
              >
                <CloseIcon />
              </IconButton>
              <Divider />
              <DialogContent>
                {/* nameSurname
                Username
                email
                password */}
                <Box
                  component="form"
                  sx={{
                    backgroundColor: "#fff",
                    p: 2,
                    pt: 0,
                    "& > :not(style)": { m: 1 },
                  }}
                  noValidate
                  autoComplete="off"
                >
                  {userFetchData &&
                    (userFetchData.mmUser ||
                      userFetchData.mmAccountManager) && (
                      <FormGroup>
                        <CustomLabel
                          title={t("requestedAppointment")}
                          isRequired
                        />
                        <FormControl
                          variant="standard"
                          className="personelSelect"
                          sx={{ width: "100%", marginBottom: "15px" }}
                        >
                          <MuiSelect
                            labelId="demo-multiple-2-checkbox-label"
                            id="demo-multiple-2-checkbox"
                            displayEmpty
                            value={selectedCalendlyUrl}
                            input={<BootstrapSelect />}
                            onChange={handleChangeCalendlyUrl}
                            renderValue={() => (
                              <div>
                                {selectedCalendlyUrl === "" ? (
                                  <em
                                    style={{
                                      color: "currentColor",
                                      opacity: "0.42",
                                    }}
                                  >
                                    {t("select")}
                                  </em>
                                ) : (
                                  // get name
                                  <span>
                                    {userFetchData.mmUser &&
                                    userFetchData.mmUser.calendlyUrl ===
                                      selectedCalendlyUrl
                                      ? t("mmResponsible")
                                      : t("accountManager")}
                                  </span>
                                )}
                              </div>
                            )}
                          >
                            {userFetchData.mmUser && (
                              <MenuItem
                                key={"1"}
                                value={userFetchData.mmUser.calendlyUrl}
                              >
                                {t("mmResponsible")}
                              </MenuItem>
                            )}
                            {userFetchData.mmAccountManager && (
                              <MenuItem
                                key={"2"}
                                value={
                                  userFetchData.mmAccountManager.calendlyUrl
                                }
                              >
                                {t("accountManager")}
                              </MenuItem>
                            )}
                          </MuiSelect>
                        </FormControl>
                      </FormGroup>
                    )}

                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      mt: 4,
                    }}
                  >
                    <Button
                      className="filterReset"
                      variant="contained"
                      onClick={handleCloseNewCalendlyDialog}
                    >
                      {t("close")}
                    </Button>
                    <Button
                      onClick={createCalendlyEvent}
                      className="filterApply"
                      variant="contained"
                      color="primary"
                      type="submit"
                      disabled={selectedCalendlyUrl === ""}
                    >
                      {t("createAppointment")}
                    </Button>
                  </Box>
                </Box>
              </DialogContent>
            </Dialog>
            <Snackbar
              open={isSnackbarOpen}
              autoHideDuration={6000}
              onClose={handleCloseSnackbar}
              message={successMessage}
            />
          </Box>
          <Box>
            <SendMessage
              action={sendMessage}
              role="merchant"
              merchant={userFetchData}
              open={openSendMessageDialog}
              onClose={handleCloseSendMessageDialog}
            />
          </Box>
        </Box>
      );
    }
  }
};

export default Dashboard;
