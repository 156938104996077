import React from "react";

const NewProductIcon = () => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_3517_2947)">
        <path
          d="M14 12V16"
          stroke="#453834"
          stroke-width="1.5"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M12 14H16"
          stroke="#453834"
          stroke-width="1.5"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M14 19C16.7614 19 19 16.7614 19 14C19 11.2386 16.7614 9 14 9C11.2386 9 9 11.2386 9 14C9 16.7614 11.2386 19 14 19Z"
          stroke="#453834"
          stroke-width="1.5"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M15.3774 7.3309L15.9063 6.80207C16.0933 6.61507 16.1983 6.36144 16.1983 6.09697C16.1983 5.83251 16.0933 5.57888 15.9063 5.39187L15.0249 4.51049C14.9781 4.46374 14.9147 4.43748 14.8486 4.43748C14.7825 4.43748 14.7191 4.46374 14.6723 4.51049C14.3685 4.81438 13.9563 4.9851 13.5265 4.9851C13.0968 4.9851 12.6846 4.81438 12.3808 4.51049C12.0769 4.20661 11.9062 3.79446 11.9062 3.36471C11.9062 2.93495 12.0769 2.5228 12.3808 2.21892C12.4275 2.17217 12.4538 2.10876 12.4538 2.04264C12.4538 1.97653 12.4275 1.91312 12.3808 1.86637L11.499 0.980899V0.980899C11.3042 0.786935 10.9909 0.788377 10.7965 0.982742L7.62132 4.15795L4.44837 7.3309L2.59748 9.18179L0.921567 10.8577C0.728537 11.0507 0.727582 11.3662 0.920611 11.5593V11.5593L1.80424 12.4429C1.85099 12.4896 1.9144 12.5159 1.98052 12.5159C2.04663 12.5159 2.11004 12.4896 2.15679 12.4429C2.46067 12.139 2.87283 11.9683 3.30258 11.9683C3.73234 11.9683 4.14449 12.139 4.44837 12.4429C4.75225 12.7468 4.92297 13.1589 4.92297 13.5887C4.92297 14.0184 4.75225 14.4306 4.44837 14.7345C4.40162 14.7812 4.37536 14.8446 4.37536 14.9107C4.37536 14.9769 4.40162 15.0403 4.44837 15.087L5.32975 15.9684C5.51675 16.1554 5.77038 16.2605 6.03485 16.2605C6.29931 16.2605 6.55295 16.1554 6.73995 15.9684L7.5 15.2083"
          stroke="#453834"
          stroke-width="1.5"
          stroke-linecap="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_3517_2947">
          <rect width="20" height="20" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default NewProductIcon;
