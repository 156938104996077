import React, { useState, useEffect } from "react";
import Cookies from "js-cookie";
import Toggler from "../components/partials/Toggler";
import Topbar from "./global/Topbar";
import { useTranslation } from "react-i18next";
import { Box, useTheme } from "@mui/material";
import TableList from "../components/partials/TableList";
import axios from "axios";
import WareHouseDialog from "../components/dialogs/WareHouseDialog";

const Warehouses = () => {
  const theme = useTheme();
  const { t } = useTranslation();
  const [pageState, setPageState] = useState({
    isLoading: false,
    data: [],
    total: 0,
    page: 1,
    pageSize: 10,
  });
  const [error, setError] = useState(null);

  const options = [{ title: t("all"), value: 2 }];

  function getCountry(params) {
    let value = params.row.country;
    if (value === null) {
      return "null";
    } else {
      return params.row.country;
    }
  }
  const columns = [
    { field: "proparsId", headerName: t("proparsId"), flex: 1 },
    {
      field: "title",
      headerName: t("warehouseName"),
      flex: 3,
      cellClassName: "name-column--cell",
    },
    {
      field: "country",
      headerName: t("country"),
      flex: 1,
      valueGetter: getCountry,
    },
  ];

  const searchPlaceholder = t("warehouse_search");

  let data = "";
  let config = {
    method: "get",
    maxBodyLength: Infinity,
    url: `${process.env.REACT_APP_BASE_URL}/Warehouse?Pagination.Page=${
      pageState.page - 1
    }&Pagination.Size=${pageState.pageSize}`,
    headers: {
      Authorization: `Bearer ${Cookies.get("_auth")}`,
    },
    data: data,
  };

  useEffect(() => {
    const fetchData = async () => {
      setPageState(old => ({ ...old, isLoading: true }));
      const response = await axios.request(config);
      const responseData = await response.data.items;
      const totalCount = await response.data.totalCount;
      setPageState(old => ({
        ...old,
        isLoading: false,
        data: responseData,
        total: totalCount,
      }));
    };
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageState.page, pageState.pageSize]);

  if (error) {
    setError(error);
    return <div>Error: {error.message}</div>;
  } else {
    return (
      <Box m={"20px"}>
        <Topbar title={t("warehouses")}>
          <WareHouseDialog />
        </Topbar>
        <Box sx={{ background: theme.palette.primary.white }} p={5}>
          <Toggler options={options} placeholder={searchPlaceholder} />
          <Box mt={2} sx={{ height: "100%" }}>
            <TableList
              rows={pageState.data}
              columns={columns}
              pageState={pageState}
              setPageState={setPageState}
              rowHeight={50}
            />
          </Box>
        </Box>
      </Box>
    );
  }
};

export default Warehouses;
