import { Typography, Box } from "@mui/material";
import React from "react";
import { format } from "date-fns";

function getProgress(params) {
  const createdDate = format(
    new Date(params.row.createTime),
    "dd/MM/yyyy, h:mm:ss"
  );
  const updatedDate = format(
    new Date(params.row.updateTime),
    "dd/MM/yyyy, h:mm:ss"
  );
  return (
    <Box>
      <Typography key={params.row.proparsId}>
        Oluşturma: {createdDate}
      </Typography>
      <Typography key={updatedDate}>İşlem: {updatedDate}</Typography>
      <Typography>Kargo: </Typography>
    </Box>
  );
}

export default getProgress;
