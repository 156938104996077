import React, { useEffect, useContext, useState } from "react";
import Box from "@mui/material/Box";
import axios from "axios";
import Cookies from "js-cookie";
import { MerchantFilterContext } from "../../context/MerchantFilterContext";
import Select, { components } from "react-select";
import { FormControlLabel, FormGroup, Checkbox, useTheme } from "@mui/material";

import Drawer from "@mui/material/Drawer";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Stack from "@mui/material/Stack";
import { useTranslation } from "react-i18next";
import Button from "@mui/material/Button";
import FilterIcon from "@mui/icons-material/FilterList";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

export default function MerchantFilterBar() {
  const { merchantFilters, setMerchantFilters } = useContext(
    MerchantFilterContext
  );
  const theme = useTheme();
  const [state, setState] = useState({
    left: false,
  });
  const { t } = useTranslation();
  const [filters, setFilters] = useState({});

  const handleSubmit = event => {
    event.preventDefault();
    setMerchantFilters({ ...merchantFilters, ...filters });
    setState({ ...state, left: false });
  };

  const handleFilterReset = event => {
    event.preventDefault();
    setMerchantFilters({});

    setFilters({});

    setState({ ...state, left: false });
  };

  const [merchants, setMerchants] = useState([]);
  const [mmUsers, setMMUsers] = useState([]);
  const [selectedMerchant, setSelectedMerchant] = useState(null);
  const [selectedMMUser, setSelectedMMUser] = useState(null);
  const [selectedAccountManager, setSelectedAccountManager] = useState(null);

  const list = anchor => (
    <Box
      sx={{ width: anchor === "top" || anchor === "bottom" ? "auto" : 368 }}
      role="presentation"
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Typography variant="h1" m={2}>
          {t("filters")}
        </Typography>
        <IconButton onClick={toggleDrawer(anchor, false)}>
          <CloseIcon sx={{ mr: 2 }} />
        </IconButton>
      </Box>
      <Box mt={2} ml={1}>
        <Accordion
          sx={{
            boxShadow: "none!important",
            border: "none!important",
            position: "unset",
          }}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
            className="panella-header"
            sx={{
              mb: 1,
            }}
          >
            <Typography
              sx={{
                fontWeight: "500",
                color: theme.palette.text.main,
                fontSize: "14px",
              }}
            >
              {t("companies")}
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Select
              className="merchant-select"
              styles={{
                control: provided => ({
                  ...provided,
                  width: "320px",
                  border: "1px solid #E5E5E5",
                  boxShadow: "none",
                  borderRadius: "0px",
                  "&:hover": {
                    border: "1px solid #E5E5E5",
                  },
                }),
              }}
              components={{ Placeholder }}
              placeholder={t("exCompaniesAll")}
              onChange={handleMerchantChange}
              options={options}
              isClearable={true}
              value={filters.search}
            />
          </AccordionDetails>
        </Accordion>
        <Accordion
          sx={{
            boxShadow: "none!important",
            border: "none!important",
            position: "unset",
          }}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel2a-content"
            id="panel2a-header"
            className="panella-header"
            sx={{
              mb: 1,
            }}
          >
            <Typography
              sx={{
                fontWeight: "500",
                color: theme.palette.text.main,
                fontSize: "14px",
              }}
            >
              {t("responsiblePerson")}
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Select
              styles={{
                control: provided => ({
                  ...provided,
                  width: "320px",
                  border: "1px solid #E5E5E5",
                  boxShadow: "none",
                  borderRadius: "0px",
                  "&:hover": {
                    border: "1px solid #E5E5E5",
                  },
                }),
              }}
              components={{ Placeholder }}
              placeholder={t("responsiblePersonAll")}
              onChange={handleMMUserChange}
              options={mmUserOptions}
              isClearable={true}
              value={filters.MMUserId}
            />
          </AccordionDetails>
        </Accordion>
        <Accordion
          sx={{
            boxShadow: "none!important",
            border: "none!important",
            position: "unset",
          }}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel2a-content"
            id="panel2a-header"
            className="panella-header"
            sx={{
              mb: 1,
            }}
          >
            <Typography
              sx={{
                fontWeight: "500",
                color: theme.palette.text.main,
                fontSize: "14px",
              }}
            >
              Account Manager
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Select
              styles={{
                control: provided => ({
                  ...provided,
                  width: "320px",
                  border: "1px solid #E5E5E5",
                  boxShadow: "none",
                  borderRadius: "0px",
                  "&:hover": {
                    border: "1px solid #E5E5E5",
                  },
                }),
              }}
              components={{ Placeholder }}
              placeholder={t("responsiblePersonAll")}
              onChange={handleAccountManagerChange}
              options={mmUserOptions}
              isClearable={true}
              value={filters.AccountManagerId}
            />
          </AccordionDetails>
        </Accordion>
      </Box>
      <Box ml={2} position="absolute" bottom="20px">
        <Stack direction="row" spacing={16}>
          <Button onClick={handleFilterReset} className="filterReset">
            {t("reset")}
          </Button>
          <Button onClick={handleSubmit} className="filterApply">
           {t("applyFilters")}
          </Button>
        </Stack>
      </Box>
    </Box>
  );

  const options = merchants.map(option => ({
    value: option.title,
    label: (
      <div>
        {option.title.substring(0, 27)} {option.title.length > 26 ? "..." : ""}
      </div>
    ),
  }));

  const handleChangeSubMerchant = e => {
    setMerchantFilters({
      ...merchantFilters,
      isSubmerchant: e.target.checked,
    });
  };

  const mmUserOptions = mmUsers.map(option => ({
    value: option.id,
    label: option.nameSurname,
    id: option.id,
  }));

  options.sort((a, b) => {
    const turkishAlphabet = "abcçdefgğhıijklmnoöprsştuüvyz"; // Türkçe alfabenin düzenlenmiş hali
    const nameA = a.value
      .toLowerCase()
      .replace(/[^a-zçğıiöşü]/g, "")
      .replace(/ç/g, "c")
      .replace(/ğ/g, "g")
      .replace(/ı/g, "i")
      .replace(/ö/g, "o")
      .replace(/ş/g, "s")
      .replace(/ü/g, "u");
    const nameB = b.value
      .toLowerCase()
      .replace(/[^a-zçğıiöşü]/g, "")
      .replace(/ç/g, "c")
      .replace(/ğ/g, "g")
      .replace(/ı/g, "i")
      .replace(/ö/g, "o")
      .replace(/ş/g, "s")
      .replace(/ü/g, "u");

    for (let i = 0; i < Math.min(nameA.length, nameB.length); i++) {
      const indexA = turkishAlphabet.indexOf(nameA[i]);
      const indexB = turkishAlphabet.indexOf(nameB[i]);

      if (indexA < indexB) {
        return -1;
      } else if (indexA > indexB) {
        return 1;
      }
    }

    if (nameA.length < nameB.length) {
      return -1;
    } else if (nameA.length > nameB.length) {
      return 1;
    } else {
      return 0;
    }
  });

  const resetFilterMerchants = () => {
    setMerchantFilters({
      ...merchantFilters,
      search: null,
    });
  };

  const resetFilterMMUserId = () => {
    setMerchantFilters({
      ...merchantFilters,
      MMUserId: null,
    });
  };

  const resetFilterAccountManagerId = () => {
    setMerchantFilters({
      ...merchantFilters,
      AccountManagerId: null,
    });
  };

  useEffect(() => {
    const getMerchantsFromAPI = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_BASE_URL}/Merchant/GetList`,
          {
            headers: {
              Authorization: `Bearer ${Cookies.get("_auth")}`,
            },
          }
        );
        setMerchants(response.data.items);
      } catch (error) {
        console.error("Error fetching merchants:", error);
      }
    };

    const getMMUsersFromAPI = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_BASE_URL}/controller/GetMMUsers`,
          {
            headers: {
              Authorization: `Bearer ${Cookies.get("_auth")}`,
            },
          }
        );
        setMMUsers(response.data.users);
        mmUsers.sort((a, b) => {
          const turkishAlphabet = "abcçdefgğhıijklmnoöprsştuüvyz"; // Türkçe alfabenin düzenlenmiş hali
          const nameA = a.value
            .toLowerCase()
            .replace(/[^a-zçğıiöşü]/g, "")
            .replace(/ç/g, "c")
            .replace(/ğ/g, "g")
            .replace(/ı/g, "i")
            .replace(/ö/g, "o")
            .replace(/ş/g, "s")
            .replace(/ü/g, "u");
          const nameB = b.value
            .toLowerCase()
            .replace(/[^a-zçğıiöşü]/g, "")
            .replace(/ç/g, "c")
            .replace(/ğ/g, "g")
            .replace(/ı/g, "i")
            .replace(/ö/g, "o")
            .replace(/ş/g, "s")
            .replace(/ü/g, "u");

          for (let i = 0; i < Math.min(nameA.length, nameB.length); i++) {
            const indexA = turkishAlphabet.indexOf(nameA[i]);
            const indexB = turkishAlphabet.indexOf(nameB[i]);

            if (indexA < indexB) {
              return -1;
            } else if (indexA > indexB) {
              return 1;
            }
          }

          if (nameA.length < nameB.length) {
            return -1;
          } else if (nameA.length > nameB.length) {
            return 1;
          } else {
            return 0;
          }
        });
      } catch (error) {
        console.error("Error fetching MM users:", error);
      }
    };

    getMerchantsFromAPI();
    getMMUsersFromAPI();
  }, []);

  const Placeholder = props => {
    return <components.Placeholder {...props} />;
  };

  const handleMerchantChange = e => {
    setSelectedMerchant(e);
    setMerchantFilters({
      ...merchantFilters,
      search: e ? e.value : "",
    });
  };

  const handleMMUserChange = e => {
    setSelectedMMUser(e);
    setMerchantFilters({
      ...merchantFilters,
      MMUserId: e ? e.id : null,
    });
  };

  const handleAccountManagerChange = e => {
    setSelectedAccountManager(e);
    setMerchantFilters({
      ...merchantFilters,
      AccountManagerId: e ? e.id : null,
    });
  };

  const toggleDrawer = (anchor, open) => event => {
    if (
      event &&
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setState({ ...state, [anchor]: open });
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        alignItems: "center",
        backgroundColor: "#F4F2F1",
        marginTop: "20px",
        paddingX: "10px",
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          gap: "20px",
        }}
      >
        <Button
          onClick={toggleDrawer("left", true)}
          startIcon={<FilterIcon />}
          endIcon={<ExpandMoreIcon />}
          color="text"
          sx={{
            mt: 1,
            mb: 1,
            p: 1,
            pl: 2,
            textTransform: "capitalize",
            border: "none",
            borderRight: "1px solid",
            borderRadius: 0,
            borderColor: theme.palette.primary.bordersColor,
          }}
        >
          {t("filters")}
        </Button>
        <Box sx={{ flexGrow: 1, display: "contents" }}>
          {merchantFilters.search && (
            <Button
              onClick={resetFilterMerchants}
              endIcon={<CloseIcon />}
              color="text"
              sx={{
                mt: 1,
                mb: 1,
                pl: 2,
                textTransform: "capitalize",
                border: "1px solid",
                borderRadius: 0,
                background: "#fff",
                borderColor: theme.palette.primary.bordersColor,
              }}
            >
              {t("company")} : {merchantFilters.search}
            </Button>
          )}
        </Box>
        <Box sx={{ flexGrow: 1, display: "contents" }}>
          {merchantFilters.MMUserId && (
            <Button
              onClick={resetFilterMMUserId}
              endIcon={<CloseIcon />}
              color="text"
              sx={{
                mt: 1,
                mb: 1,
                pl: 2,
                textTransform: "capitalize",
                border: "1px solid",
                borderRadius: 0,
                background: "#fff",
                borderColor: theme.palette.primary.bordersColor,
              }}
            >
              {t("responsiblePerson")} :{" "}
              {
                mmUsers.find(user => user.id === merchantFilters.MMUserId)
                  .nameSurname
              }
            </Button>
          )}
        </Box>
        <Box sx={{ flexGrow: 1, display: "contents" }}>
          {merchantFilters.AccountManagerId && (
            <Button
              onClick={resetFilterAccountManagerId}
              endIcon={<CloseIcon />}
              color="text"
              sx={{
                mt: 1,
                mb: 1,
                pl: 2,
                textTransform: "capitalize",
                border: "1px solid",
                borderRadius: 0,
                background: "#fff",
                borderColor: theme.palette.primary.bordersColor,
              }}
            >
              Account Manager :{" "}
              {
                mmUsers.find(
                  user => user.id === merchantFilters.AccountManagerId
                ).nameSurname
              }
            </Button>
          )}
        </Box>
      </Box>
      <Box>
        <FormGroup>
          <FormControlLabel
            control={<Checkbox />}
            label={t("showSubMerchants")}
            onChange={handleChangeSubMerchant}
          />
        </FormGroup>
      </Box>
      <Drawer
        anchor="left"
        open={state["left"]}
        onClose={toggleDrawer("left", false)}
      >
        {list("left")}
      </Drawer>
    </Box>
  );
}
